<div class="auth-page-wrapper pt-5">
  <!-- auth page bg -->
  <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
      </div>
  </div>

  <!-- auth page content -->
  <div class="auth-page-content">
      <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                        <!-- <h1 class="icon-text">Direct to Carrier</h1> -->
                        <img src="../../../assets/logo-blue-background-login.png" />
                    </div>
                </div>
            </div>
        </div>
          <!-- end row -->

          <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-6">
                  <div class="card mt-4">

                      <div class="card-body p-4">
                          <div class="text-center mt-2">
                              <h5 class="text-primary">Forgot Password?</h5>
                          </div>
                          <div class="p-2">
                            <div class="alert alert-danger" role="alert" *ngIf="error != ''">
                                {{error}}
                            </div>
                              <form [formGroup]="passresetForm">
                                  <div class="mb-4">
                                      <label class="form-label">Email</label>
                                      <input type="email" class="form-control" id="email" placeholder="Enter Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                                      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="f['email'].errors['required']">Email is required</div>
                                      </div>
                                  </div>

                                  <div class="text-center mt-4">
                                    <button type="button" class="btn btn-primary btn-label w-100" data-nexttab="pills-success-tab" (click)="onSubmit()">
                                        <span class="flex-grow-1 me-2">
                                            Reset Password
                                          </span>
                                          <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                              <span class="visually-hidden">Loading...</span>
                                          </span>
                                    </button>
                                  </div>
                              </form><!-- end form -->
                          </div>
                      </div>
                      <!-- end card body -->
                  </div>
                  <!-- end card -->

                  <div class="mt-4 text-center">
                      <p class="mb-0">Already have an account? <a routerLink="/" class="fw-semibold text-primary text-decoration-underline">Login</a> </p>
                  </div>

              </div>
          </div>
          <!-- end row -->
      </div>
      <!-- end container -->
  </div>
  <!-- end auth page content -->

  <!-- footer -->
  <footer class="footer">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="text-center">
                      <p class="mb-0 text-muted">  ©  {{year}} Direct To Carrier - <a class="text-muted privacy-link" routerLink="/privacy"><u>Privacy Policy</u></a></p>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  <!-- end Footer -->
</div>
<!-- end auth-page-wrapper -->
