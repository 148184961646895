<ng-template #stripeCheckoutModal let-modal>
    <div class="modal-body text-center p-5">
        <div class="mt-4">
            <h4 class="mb-3">Stripe Checkout</h4>
            <p class="text-muted mb-4"> Put something here</p>
            <div class="hstack gap-2 justify-content-center">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>