import { AccountType } from "./account.model";

export type InvoiceDisplay = Invoice & {totalString: string};

export interface Invoice {
    accountId: string;
    dateCreated: string;
    dateDue: number;
    datePaid: string;
    discount: number;
    discountCode: string;
    invoiceId: string;
    invoiceNumber: string;
    provider: string;
    providerId: string;
    providerUrl: string;
    subtotal: number;
    tax: number;
    total: number;
    status: string;
    lineItems: InvoiceLineItem[];
}

export interface InvoiceLineItem {
    lineId: string;
    invoiceId: string;
    productId: string;
    quantity: number;
}

export interface InvoicePayload {
    lineItems: InvoiceLineItemPayload[];
}

export interface InvoiceLineItemPayload {
    productId: string;
    quantity: number;
}

export function getProductId(accountType: AccountType): string | null {
    switch(accountType) {
        case 'carrier-account':
            return 'e7fcd948-d3d8-4ae2-840f-565ef9044276';
        case 'shipper-account':
            return '5cc5012a-ac37-4a61-855e-de569e51fa0e';
        case 'broker-account':
            return '4937346c-0f97-4743-a0fb-dd34140c0f8c';
        default:
            return null;
    }
}