import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, combineLatest, concat, forkJoin } from 'rxjs';
import { Document } from 'src/app/core/services/models/document.model';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { Account } from 'src/app/core/services/models/account.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { DocumentFlow, DocumentFlowRequest } from 'src/app/core/services/models/document-flow.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { HttpService } from 'src/app/core/services/http-service';
import Swal from 'sweetalert2';
import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DocumentModel, SuccessApiResponse } from 'src/app/core/services/models/models';
import { ServiceType } from 'src/app/core/services/models/service-type.model';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import { DocumentFlowStep } from 'src/app/core/services/models/document-flow-step.model';
import { toArray } from 'rxjs/operators';
import { PrettyTechName } from 'src/app/core/services/models/pretty-technical-name';
import { DOCUMENT_TYPE_OPTIONS } from '../contract-templates/contract-templates.component';

type Step = {
  direction?: 'send' | 'receive',
  type?: string;
  documentId?: string | null;
  serviceTypes?: string[];
  documents?: DocumentModel[];
};

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'app-document-groups',
  templateUrl: './document-groups.component.html',
  styleUrls: ['./document-groups.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class DocumentGroupsComponent implements OnInit {
  readonly SEND_OR_RECEIVE_OPTIONS = [
    {
      prettyName: 'Send',
      techName: 'send'
    },
    {
      prettyName: 'Receive',
      techName: 'receive'
    }
  ];

  DOCUMENT_TYPE_OPTIONS = [...DOCUMENT_TYPE_OPTIONS];

  Number = Number;

  readonly ALL_SERVICE_TYPE: PrettyTechName  = {
    prettyName: 'All',
    techName: ''
  };

  serviceTypes: PrettyTechName[] = [this.ALL_SERVICE_TYPE];

  submitted = false;
  isLoading = false;

  ListJsList$: Observable<Document[]>;
  total$: Observable<number>;

  activeModal?: NgbModalRef;

  documentForm!: FormGroup;

  subscriptions: Subscription[] = [];
  selectedMode: Mode | null;
  selectedBranch: Branch | null;
  selectedAccount: Account | null;

  steps: Step[] = [];
  documentFlows: DocumentFlow[] = [];

  orginalDocumentFlow?:DocumentFlow;
  orginalDocumentFlowSteps?:DocumentFlowStep[];

  showData = false;

  expirationDate?: Date;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private httpService: HttpService
  ) {
  }

  ngOnInit(): void {
    console.log('[DocumentGroupsComponent][ngOnInit]');
    
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch)){
            return;
          }

          if(this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.loadData();
          this.loadDocumentFlows();
        }
      )
    );
  }

  sendOrReceiveOptionChanged(ndx: number){
    const step = this.steps[ndx];
    const direction = step.direction;

    if(direction === 'receive'){
      this.steps[ndx].documentId = null;
    }
  }

  typeOptionChanged(ndx: number){
    const step = this.steps[ndx];
    const type = step.type!;

    this.steps[ndx].documents = [];

    this.httpService.getAllDocuments(
      this.selectedBranch?.branchId!,
      this.selectedMode?.modeId!,
      type
    ).subscribe(
      res => {
        const succesRes = <SuccessApiResponse<DocumentModel[]>>res;
        const documents = succesRes.data;
        this.steps[ndx].documents = documents;
        const currentDocId = this.steps[ndx].documentId;
        if(currentDocId && !documents.map(doc=>doc.documentId).includes(currentDocId)){
          this.steps[ndx].documentId = null;
        }
      },
      error=>{
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch documents: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    )
  }

  private loadDocumentFlows(){
    this.showData = false;
    this.httpService.listDocumentFlows(
      this.selectedMode?.modeId,
      this.selectedBranch?.branchId
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<DocumentFlow[]>> res;
        this.documentFlows = successRes.data;
        this.showData = true;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch document groups: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  serviceTypeSelected(ndx: number){
    const serviceTypes = this.steps[ndx].serviceTypes;
    console.log('[serviceTypeSelected][serviceTypes]', JSON.stringify(serviceTypes));

    if(serviceTypes?.includes('')){
      this.steps[ndx].serviceTypes = [''];
    }
  }

  private loadData(){
    this.httpService.listServiceTypes(this.selectedMode?.modeId!).subscribe(
      res => {
        const succesRes = <SuccessApiResponse<ServiceType[]>> res;
        this.serviceTypes = [
          this.ALL_SERVICE_TYPE,
          ...succesRes.data.map(
            serviceType => (
              {
                prettyName: serviceType.name,
                techName: serviceType.serviceTypeId
              }
            )
          )
        ];
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service types: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  get documentFormControls(): { [key: string]: AbstractControl } {
    return this.documentForm.controls;
  }

  openSaveModal(content: any, documentFlow?: DocumentFlow){
    this.initForm(documentFlow);
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      modalDialogClass: 'full-page-modal-container'
    });
  }

  private initForm(documentFlow?:DocumentFlow){
    this.expirationDate = undefined;
    this.orginalDocumentFlow = documentFlow;
    this.orginalDocumentFlowSteps = [];
    this.submitted = false;
    this.steps = [];
    this.documentForm = this.formBuilder.group({
      name: [documentFlow?.name, [Validators.required]],
      expirationDate: [
        documentFlow?.expirationDate 
          && new Date(Number(documentFlow?.expirationDate) * 1000)
            .toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'}),
        [Validators.required]
      ]
    });

    const expirationDate = this.documentForm.controls['expirationDate'].value;

    setTimeout(
      ()=>{
        this.expirationDate = expirationDate && new Date(expirationDate);
      }
    );

    if(documentFlow){
      this.httpService.listDocumentFlowSteps(documentFlow.documentFlowId).subscribe(
        res => {
          const successRes = <SuccessApiResponse<DocumentFlowStep[]>> res;
          this.orginalDocumentFlowSteps = successRes.data ?? [];
          this.steps = this.orginalDocumentFlowSteps
            .sort((a, b) => a.exchangeOrder - b.exchangeOrder)
            .map(
              documentFlowStep => ({
                direction: <'send' | 'receive'>documentFlowStep.direction,
                type: documentFlowStep.type,
                documentId: documentFlowStep.documentId,
                serviceTypes: documentFlowStep.serviceTypes 
                  ? documentFlowStep.serviceTypes.map(serviceType => serviceType.serviceTypeId)
                  : [''],
                documents: []
              }
            )
          );
          this.steps.forEach(
            (step, ndx) => this.typeOptionChanged(ndx)
          );
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch document flow steps: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      ); 
    }
    
  }

  deleteStep(ndx: number){
    this.steps.splice(ndx, 1);
  }

  submitDocumentFlows(){
    this.submitted = true;
    if(this.documentForm.invalid || !this.steps.length || this.hasInvalidStep()){
      return;
    }

    const {expirationDate} = this.documentForm.value
    const documentFlowRequest: DocumentFlowRequest = {
      ...this.documentForm.value,
      expirationDate: String(new Date(expirationDate).getTime() / 1000),
      modeId: this.selectedMode?.modeId!,
      branchId: this.selectedBranch?.branchId
    }

    this.isLoading = true;

    let httpCall;

    if(this.orginalDocumentFlow){
      httpCall = this.httpService.updateDocumentFlow(
        this.orginalDocumentFlow?.documentFlowId!, documentFlowRequest
      );
    }else{
      httpCall = this.httpService.addDocumentFlow(documentFlowRequest);
    }

    httpCall.subscribe(
      res => {
        const succesRes = <SuccessApiResponse<DocumentFlow>>res;
        const documentFlow = succesRes.data;
        const {documentFlowId: documentFlowId} = documentFlow;
        
        if(isArrayEmpty(this.orginalDocumentFlowSteps)){
          this.addStepCalls(documentFlowId);
        }else{
          const deleteStepCalls = this.orginalDocumentFlowSteps?.map(
            step => this.httpService.deleteDocumentFlowStep(step.documentFlowStepId)
          ) ?? [];

          if(isArrayEmpty(deleteStepCalls)){
            this.addStepCalls(documentFlowId);
          }else{
            forkJoin(deleteStepCalls).subscribe(
              res => this.addStepCalls(documentFlowId),
              error => {
                this.isLoading = false;
                Swal.fire({
                  title: 'Error',
                  text: 'Failed to delete steps: ' + error.error.reason,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: 'rgb(60,76,128)',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  //do nothing
                });
              }
            );
          }
        }
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete lane: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private addStepCalls(documentFlowId: string){
    const calls = this.steps
      .map(
        (step, ndx)=> this.httpService.addDocumentFlowStep(
          documentFlowId,
          {
            documentFlowId: documentFlowId,
            direction: step.direction!,
            serviceTypes: step.serviceTypes?.includes('') ? null : step.serviceTypes!,
            type: step.type!,
            documentId: step.documentId!,
            exchangeOrder: ndx,
            name: `${ndx}`
          }
        )
      );

    concat(...calls)
      .pipe(toArray())
      .subscribe(
        res => {
          this.isLoading = false;
          this.activeModal?.close();
          this.loadDocumentFlows();
          Swal.fire({
            title: 'Success',
            text: 'Successfully saved document group.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
          });
        },
        error => {
          this.isLoading = false;
          Swal.fire({
            title: 'Error',
            text: 'Failed to save document flow steps: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  private hasInvalidStep(): boolean{
    return this.steps.some(
      step => this.isNullish(step.direction) || this.isNullish(step.type) 
        || (
          step.direction !== 'receive' && this.isNullish(step.documentId)
        )
        || this.isNullish(step.serviceTypes) || !step.serviceTypes?.length
    );
  }

  drop(event: CdkDragDrop<Step[]>) {
    moveItemInArray(this.steps, event.previousIndex, event.currentIndex);
  }

  isNullish(data: any){
    return data === null || data === undefined;
  }

  delete(){
    this.isLoading = true;
    this.httpService.deleteDocumentFlow(this.orginalDocumentFlow?.documentFlowId!)
      .subscribe(
        res => {
          this.isLoading = false;
          this.activeModal?.close();
          this.loadDocumentFlows();

          Swal.fire({
            title: 'Success',
            text: 'Successfully deleted document group.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
          });
        },
        error => {
          this.isLoading = false;

          if(error.error.reason === "update or delete on table \"document_flows\" violates foreign key constraint \"loads_document_flow_id_fkey\" on table \"loads\"") {
            Swal.fire({
              title: 'Error',
              text: "The Document Group can't be deleted because it's used by an active Load Board Post.",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
            return;
          }

          Swal.fire({
            title: 'Error',
            text: 'Failed to delete document group: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }
}
