import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-service-areas-and-zones',
  templateUrl: './service-areas-and-zones.component.html',
  styleUrls: ['./service-areas-and-zones.component.scss']
})
export class ServiceAreasAndZonesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Service Areas & Zones'
    }
  ];

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit() { }
}
