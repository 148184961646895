import { Component, OnInit, Input } from '@angular/core';

export interface BreadCrumbItem {
  label: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

/**
 * Bread Crumbs Component
 */
export class BreadcrumbsComponent implements OnInit {
  @Input() showDefault = true;
  @Input() breadCrumbItems: BreadCrumbItem[] = [];

  ngOnInit(): void {
  }

}
