import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { ContractSignersService } from 'src/app/core/services/contract-signers-service/contract-signers.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';


@Component({
  selector: 'app-contract-signers',
  templateUrl: './contract-signers.component.html',
  styleUrls: ['./contract-signers.component.scss']
})
export class ContractSignersComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Contract Signers'
    }
  ];
  activeModal?: NgbModalRef;
  isLoading = false;
  contractSignersForm!: FormGroup;
  accountUsers: any = [];
  contractSignerUsers: any = [];

  selectedAccountSubscription: Subscription;

  constructor(
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    private service: ContractSignersService,
    private authService: AuthService,
    public permissionsService: PermissionsService
  ) {
    this.selectedAccountSubscription = this.authService.subscribeToSelectedAccount(async (account) => {
      // await this.permissionsService.checkUrlPermission('_modes');
      return this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('Contract Signers');
    this.loadData();
  }

  loadData() {
    this.getUsers();
    this.initForm();
  }

  initForm() {
    this.contractSignersForm = this.formBuilder.group({
      users: ['', [Validators.required]],
    });
  }

  getUsers() {
    this.httpRequest.getAccountUsers().subscribe((data: any) => {
      if(data.data.length != 0) {
        this.accountUsers = this.service.addFullNameKey(data.data);
        this.getAccountSigners();
      }
    })
    
  }

  getAccountSigners() {
    this.httpRequest.getAccountSigners().subscribe((data: any) => {
      if(data.data.length != 0) {
        this.contractSignerUsers = this.service.getContractSignerUsers(this.accountUsers, data.data);
      }
    })
  }

  submit() {
    this.isLoading = true;
    if(this.checkIfAlready(this.contractSignersForm.controls['users'].value, this.contractSignerUsers)) {
      Swal.fire({
        title: "Can't Add Signer",
        text: "Selected user is already a signer",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        
      });
    } else {
      this.httpRequest.updateAccountSigners({
        users: this.service.getPayload(this.contractSignerUsers, this.contractSignersForm.controls['users'].value)
      }).subscribe((data: any) => {
        this.isLoading = false;
        this.getUsers();
        this.initForm();
      }, error => {
        this.isLoading = false;
        console.warn(error);
      })
    }
  }

  deleteContractSigner(users: any) {
    this.httpRequest.deleteUserSigner(users.userId).subscribe((data: any) => {
      this.getUsers();
      this.initForm();
    })
  }

  checkIfAlready(userId: any, usersArr: any) {
    for(let x = 0; x < usersArr.length; x++) {
      if(userId === usersArr[x].userId) {
        return true;
      }
    }
    return false;
  }



}
