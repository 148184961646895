import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { TreeNode } from 'primeng/api/treenode';
import { User } from 'src/app/core/services/models/user.model';
import { User as AuthUser } from 'src/app/core/services/models/auth.models';
import { ApiResponse, SuccessApiResponse } from 'src/app/core/services/models/models';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import Swal from 'sweetalert2';
import { Observable, of, Subject } from 'rxjs';
import { Channel } from 'src/app/core/services/models/channel.model';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { debounceTime, map } from 'rxjs/operators';
import { isArrayEmpty } from 'src/app/core/utils/commons';

type FormValue = {name: string, accountId: string, coworkers: TreeNode<User>[]};

@Component({
  selector: 'ngbd-modal-new-channel',
  templateUrl: './save-channel.component.html',
  styleUrls: ['./save-channel.component.scss'],

})
export class NgbdSaveChannelModal implements OnInit{
    @Input()
    initialChannel: Channel;

    submitted = false;
    isLoading = false;
    channelForm!: FormGroup;
    accountOptions:{accountId: string, name: string}[] = [];
    userNodeOptions: TreeNode<User>[] = [];
    currentUser: AuthUser;

    carrierPageHelper: TablePaginationHelper<Account>;
    carrierOptionsSearch$ = new Subject<string>();
    carrierOptionsSearch = '';

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private httpRequest: HttpService,
        public authService: AuthService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.initForms();
        this.currentUser = this.authService.currentUserValue;
        
        if(!this.initialChannel){
            this.carrierOptionsSearch$.pipe(
                debounceTime(500),
                map(searchText => {
                    this.carrierOptionsSearch = searchText;
                    this.carrierPageHelper.loadRecords().then(() => {
                        this.accountOptions = this.carrierPageHelper.searchResult?.map(
                            account => ({accountId: account.accountId!, name: account.name!})
                        ) ?? [];
                    });
                })
            ).subscribe(() => {

            });
        }
        // let search: Observable<SuccessApiResponse<Account[]>> = of({data: [], error: false});
        let endpoint = '';

        if(this.authService.currentAccountSelected.accountType == 'shipper-account' || this.authService.currentAccountSelected.accountType == 'broker-account'){
            // search = this.httpRequest.carrierSearch('hasChatters=true');
            endpoint = '/carriers';
        }else if(this.authService.currentAccountSelected.accountType == 'carrier-account'){
            // search = this.httpRequest.shipperSearch('hasChatters=true');
            endpoint = '/shippers'
        }

        this.carrierPageHelper = new TablePaginationHelper({
            loadRecords: (pageRequest) => {
                const params: Partial<{name: string; hasChatters: boolean}> = {
                    hasChatters: true
                };
                if (this.carrierOptionsSearch) {
                    params.name = this.carrierOptionsSearch;
                }
                return this.httpRequest.paginatedGetRequest<Account>(
                    endpoint
                    , {
                      ...params as any,
                      ...pageRequest
                    }
                  ).toPromise()
            }
        });

        if(!this.initialChannel){
            this.carrierPageHelper.loadRecords().then(() => {
                this.accountOptions = this.carrierPageHelper.searchResult?.map(
                    account => ({accountId: account.accountId!, name: account.name!})
                ) ?? [];
            });
        }

        this.isLoading = true;
        const users = ((await this.httpRequest.getUsers().toPromise()) as SuccessApiResponse<User[]>).data;
        this.isLoading = false;
        this.userNodeOptions = users.map(this.convertUserToNode);
        this.setForms(this.userNodeOptions);
    }

    onScrollToEndCarriers(): void {
        if(!this.initialChannel){
            this.carrierPageHelper.loadNextRecords().then((hasNextRecords) => {
                if (hasNextRecords) {
                    this.accountOptions = [...this.accountOptions, ...this.carrierPageHelper.searchResult]?.map(
                        account => ({accountId: account.accountId!, name: account.name!})
                    ) ?? [];
                }
            });
        }
    }

    onSearchCarriers(event: {term: string, items: Account[]}): void {
        this.carrierOptionsSearch$.next(event.term);
    }
    
    private convertUserToNode(user: User): TreeNode<User> {
        return {
          data: user,
          key: user.userId,
          label: [user.firstName, user.lastName].join(' ')
        };
    }

    initForms() {
        this.channelForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            coworkers: [[], [Validators.required]],
            accountId: ['']
        });
    }

    setForms(coworkers: TreeNode<User>[]) {
        this.initForms();
        if(this.initialChannel) {
            const {
                name, carrierId, carrierName, carrierMembers, 
                shipperId, shipperMembers, shipperName
            } = this.initialChannel;
            let formValue: FormValue = {name} as FormValue;

            if(this.authService.currentAccountSelected.accountType == 'shipper-account' || this.authService.currentAccountSelected.accountType == 'broker-account') {
                const shipperIds = shipperMembers?.map(shipper => shipper.userId) || [];
                this.accountOptions = [...this.accountOptions, {accountId: carrierId, name: carrierName}];
                formValue.accountId = carrierId;
                formValue.coworkers = coworkers.filter(coworker => shipperIds.includes(coworker.key!));
            }else if(this.authService.currentAccountSelected.accountType == 'carrier-account'){
                const carrierIds = carrierMembers?.map(carrier => carrier.userId) || [];
                this.accountOptions = [...this.accountOptions, {accountId: shipperId, name: shipperName}];
                formValue.accountId = shipperId;
                formValue.coworkers = coworkers.filter(coworker => carrierIds.includes(coworker.key!));
            }

            this.channelForm.patchValue({
                ...formValue
            });
        }else {
            const currentUser = coworkers.find(coworker => coworker.key === this.currentUser.userId);
            this.channelForm.controls['coworkers'].setValue(
                currentUser ? [currentUser]: []
            );
        }

    }

    get f() {
        return this.channelForm.controls;
      }

    close() {
        this.activeModal?.close();
    }

    async submit() {
        this.submitted = true;
        this.isLoading = true;

        if(this.channelForm.invalid) {
            this.isLoading = false;
            return;
        }

        const currentAccountId = this.authService.currentAccountSelected.accountId!;
        const {coworkers, accountId} = this.channelForm.value;
        const userIdObjs = accountId 
            ? (await this.httpRequest.getOtherAccountChatters(accountId).toPromise()).data
            : [];
        const nullableAccountId = accountId || null;
        const payload = {
            ...this.channelForm.value,
            accountId: nullableAccountId
        };
        if(this.authService.currentAccountSelected.accountType == 'shipper-account' || this.authService.currentAccountSelected.accountType == 'broker-account'){
            payload.shipperId = currentAccountId;
            payload.carrierId = nullableAccountId;
            payload.shipperMembers = coworkers?.map((shipper: TreeNode<User>) => shipper.key) ?? [];
            payload.carrierMembers = isArrayEmpty(this.initialChannel?.carrierMembers) 
                ? (
                    userIdObjs?.map(userIdObj => userIdObj.userId) ?? []
                )
                : this.initialChannel?.carrierMembers?.map(user => user.userId) ?? [];
        }else if(this.authService.currentAccountSelected.accountType == 'carrier-account'){
            payload.shipperId = nullableAccountId;
            payload.carrierId = currentAccountId;
            payload.carrierMembers = coworkers?.map((carrier: TreeNode<User>) => carrier.key) ?? [];
            payload.shipperMembers = isArrayEmpty(this.initialChannel?.shipperMembers) 
                ? (
                    userIdObjs?.map(userIdObj => userIdObj.userId) ?? []
                )
                : this.initialChannel?.shipperMembers?.map(user => user.userId) ?? [];
        }

        let saveChannel: Observable<ApiResponse<Channel[]>> = of({data: [], error: false});

        if(this.initialChannel){
            const {channelId} = this.initialChannel;
            saveChannel = this.httpRequest.updateChannel(channelId, payload);
        }else{
            saveChannel = this.httpRequest.newChannel(payload);
        }

        saveChannel.subscribe((data: any) => {
            this.close();
            this.isLoading = false;
            this.submitted = false;
            Swal.fire({
                title: 'Success',
                text: 'Succesfully saved channel.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
                }).then((result) => {
                //do nothing
                });
        }, error => {
            this.isLoading = false;
            Swal.fire({
                title: 'Error',
                text: 'Failed to save channel: ' + error.error.reason,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
                }).then((result) => {
                //do nothing
                });
        });
        
    }
}