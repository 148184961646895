import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpService } from 'src/app/core/services/http-service';
import { RawNylasSchedulingPageResponse, NylasSchedulingPage } from 'src/app/core/services/models/nylasSchedulingPage.model';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { User } from 'src/app/core/services/models/auth.models';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';

declare const nylas:any;

@Component({
  selector: 'app-calendar-settings',
  templateUrl: './calendar-settings.component.html',
  styleUrls: ['./calendar-settings.component.scss']
})
export class CalendarSettingsComponent implements OnInit {
  meetingLink?:string;
  meetingLinkSaveLoading = false;
  nylasSchedulingPageResponse?: RawNylasSchedulingPageResponse;
  scheduleLink!: string;
  schedulingPageId: string;

  constructor(
    private clipboard: Clipboard,
    private httpService: HttpService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.initAsync();
  }

  async initAsync(){
    const userNylasSchedulingPages = (<SuccessApiResponse<NylasSchedulingPage[]>> await this.httpService.getUserNylasSchedulingPage().toPromise()).data;
    this.schedulingPageId = userNylasSchedulingPages[0].schedulingPageId;
    this.nylasSchedulingPageResponse = await this.httpService
      .getRawNylasSchedulingPageById(this.schedulingPageId).toPromise();

    const userProfile = (<SuccessApiResponse<UserProfile>>await this.httpService.getUsersByIdOrEmail(this.auth.currentUserValue.userId!).toPromise()).data;
    this.scheduleLink = `${window.location.origin}/users/${userProfile.profileSlug}/schedule`;

    const location = this.nylasSchedulingPageResponse.data.config.event.location;
    this.meetingLink = location ? location.trim(): undefined;
  }

  async saveMeetingLink(){
    this.meetingLinkSaveLoading = true;
    
    //refetch config before updating meeting link
    this.nylasSchedulingPageResponse = await this.httpService
      .getRawNylasSchedulingPageById(this.schedulingPageId).toPromise();

    this.nylasSchedulingPageResponse!.data.config.event.location = this.meetingLink || ' ';
    this.httpService.updateRawNylasSchedulingPages(
      this.schedulingPageId,
      this.nylasSchedulingPageResponse!.data.config
    ).pipe(
      finalize(()=>this.meetingLinkSaveLoading = false)
    ).subscribe(
      success => {
        Swal.fire({
          title: 'Success',
          text: 'Meeting link has been saved.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save meeting link.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
          }).then(result => {
            //do nothing
          });
      }
    );
  }

  edit(){
    nylas.scheduler.show({
      auth: {
        pageEditToken: this.nylasSchedulingPageResponse?.data.edit_token
      },
      behavior: {
        displayOnly: ["event-info", "opening-hours"],
        disableSlugChanges: true,
        disableEditing: ["slug", "available_days_in_future"],
      },
      style: {
        // Style the Schedule Editor
        tintColor: "#32325d",
        backgroundColor: "white",
      },
      pageDomain: 'https://schedule.directtocarrier.io'
    });
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
}
