import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TreeNode } from "primeng/api";
import { combineLatest, Subscription } from "rxjs";
import { AuthService } from "src/app/core/services/authentication/auth.service";
import { HttpService } from "src/app/core/services/http-service";
import { Mode } from "src/app/core/services/models/mode.model";
import { ServiceArea, ServiceAreaZoneTreeNode } from "src/app/core/services/models/service-areas.model";
import { ServiceAreasService } from "src/app/core/services/service-areas/service-areas.service";
import { AbstractPagesComponent } from "../../AbstractPagesComponent";
import Swal from 'sweetalert2';
import { finalize } from "rxjs/operators";
import { Zone } from "src/app/core/services/models/zone.model";
import { MapboxZipcodePickerComponent } from "src/app/shared/mapbox-zipcode-picker/mapbox-zipcode-picker.component";
import { Branch } from "src/app/core/services/models/branch.model";
import { Account } from "src/app/core/services/models/account.model";
import { TabEnum } from "./modals/service-area-modal/service-area-modal.component";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

@Component({
  selector: 'app-service-areas',
  templateUrl: './service-areas.component.html',
  styleUrls: ['./service-areas.component.scss'],
  providers: [ServiceAreasService],
})
export class ServiceAreasComponent extends AbstractPagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  onlyTable = false;
  saveLoading = false;
  submitted = false;
  // serviceAreaForm!: FormGroup;
  zoneForm!: FormGroup;
  subscriptions: Subscription[] = [];
  activeModal?: NgbModalRef;
  // modes: Mode[] = [];
  // branches: TreeNode<TreeNodeBranch>[] = [];
  // selectedBranches: TreeNode<TreeNodeBranch>[] = [];
  cols = [
    { field: 'name' }
  ];
  cols2 = [
    { field: 'name', header: 'Name' }
  ];
  files2: TreeNode<ServiceAreaZoneTreeNode>[] = [];
  serviceAreaZoneTreeNodeInModal ?: ServiceAreaZoneTreeNode;
  refServiceAreas: ServiceArea[];

  mode = '';
  
  selectedMode: (Mode | null);
  selectedAccount: (Account | null);
  selectedBranch: (Branch | null);

  activeTab: TabEnum;
  activeZoneId?: string;

  @ViewChild('mapboxZipcodePicker') mapboxZipcodePicker: MapboxZipcodePickerComponent;

  constructor(
    private authService: AuthService,
    private httpRequest: HttpService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.loadData();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      // this.createMap();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    // this.httpRequest.getModes().subscribe((res) => (this.modes = res.data));
    // this.httpRequest.getBranchesByHierarchy()
    //   .subscribe(res => this.branches = BranchesService.getRecursiveTreeNodeBranch(res.data));
    this.httpRequest.getServiceAreas(
      this.selectedMode?.modeId!, 
      this.selectedBranch?.branchId!, 
      this.selectedAccount?.accountId
    ).subscribe(res => {
      this.refServiceAreas = res.data;
      this.files2 = ServiceAreasService.convertToTreeNode(res.data);
    });
  }

  getEventValue($event:any) :string {
    return $event.target.value;
  }

  openModal(serviceAreaContent:any, zoneContent: any, serviceAreaZoneTreeNode: ServiceAreaZoneTreeNode){
    // if(serviceAreaZoneTreeNode.type === 'Zone'){
    //   this.openZoneModal(zoneContent, serviceAreaZoneTreeNode);
    // }else if(serviceAreaZoneTreeNode.type === 'ServiceArea'){
    //   this.openServiceAreaModal(serviceAreaContent, serviceAreaZoneTreeNode)
    // }
    if(serviceAreaZoneTreeNode.type === 'Zone'){
      this.activeTab = TabEnum.ZONES;
      this.activeZoneId = serviceAreaZoneTreeNode.zone!.zoneId
    }else{
      this.activeTab = TabEnum.SERVICE_AREA;
      this.activeZoneId = undefined;
    }
    this.openServiceAreaModal(serviceAreaContent, serviceAreaZoneTreeNode)
  }

  openServiceAreaModal(content: any, serviceAreaZoneTreeNode?: ServiceAreaZoneTreeNode) {
    // this.initServiceAreaForm();
    if(serviceAreaZoneTreeNode){
      this.serviceAreaZoneTreeNodeInModal = serviceAreaZoneTreeNode;
    }else {
      this.activeTab = TabEnum.SERVICE_AREA;
      this.activeZoneId = undefined;
      this.serviceAreaZoneTreeNodeInModal = undefined;
    }
    
    this.modalServiceOpen(content);
  }
  
  // private initServiceAreaForm() {
  //   this.submitted = false;
  //   this.serviceAreaForm = this.formBuilder.group({
  //     name: ['', [Validators.required]],
  //     modes: [[], [Validators.required]],
  //     postalCodes: [[], [Validators.required]],
  //   });
  //   this.selectedBranches = [];
  //   this.branches.forEach((branch) => this.expandChildren(branch));
  // }

  // private expandChildren(node:TreeNode){
  //   if(node.children){
  //     node.expanded=true;
  //     for(let cn of node.children){
  //       this.expandChildren(cn);
  //     }
  //   }
  // }

  openZoneModal(content: any,  serviceAreaZoneTreeNode: ServiceAreaZoneTreeNode){
    this.submitted = false;
    this.serviceAreaZoneTreeNodeInModal = serviceAreaZoneTreeNode;

    this.zoneForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      modes: [[], [Validators.required]],
      postalCodes: [[], [Validators.required]],
    });

    if(serviceAreaZoneTreeNode.type === 'Zone'){
      const {zoneId} = serviceAreaZoneTreeNode.zone!;
      this.fetchZoneViaId(zoneId, serviceAreaZoneTreeNode.serviceArea, content);
    }else if(serviceAreaZoneTreeNode.type === 'ServiceArea'){
      this.modalServiceOpen(content);
    }
  }

  private fetchZoneViaId(zoneId: string, serviceArea: ServiceArea, content: any){
    this.httpRequest.getZonesViaId(zoneId).subscribe(
      res => {
        const zone:Zone = res.data as Zone;
        this.serviceAreaZoneTreeNodeInModal!.zone = zone;
        const {name, modes, postalCodes} = zone;

        const serviceAreaModeIds = serviceArea?.modes?.map(mode => mode.modeId) || [];
        const serviceAreaPostalCodes = serviceArea?.postalCodes || [];

        this.zoneForm.patchValue({
          name,
          modes: modes?.map(mode => mode.modeId).filter(modeId => serviceAreaModeIds.includes(modeId)),
          postalCodes: postalCodes?.filter(postalCode => serviceAreaPostalCodes.includes(postalCode))
        });
        this.modalServiceOpen(content);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch zone: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private modalServiceOpen(content: any){
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      modalDialogClass: 'full-page-modal-container'
    });
    this.activeModal.result.then(
      (response) => {
        if (response !== 'CANCEL') {
          this.loadData();
        }   
      }
    );
  }

  // get serviceAreaFormControls() {
  //   return this.serviceAreaForm.controls;
  // }

  get zoneFormControls() {
    return this.zoneForm.controls;
  }

  zoneSubmit(){
    this.submitted = true;
    if(this.zoneForm.invalid){
      return;
    }

    const zone = this.serviceAreaZoneTreeNodeInModal?.zone as Zone;
    if(this.serviceAreaZoneTreeNodeInModal?.zone){
      this.updateZone(zone);
    }else {
      this.addZone(this.serviceAreaZoneTreeNodeInModal?.serviceArea!);
    }
  }

  private addZone(serviceArea: ServiceArea){
    const {accountId, serviceAreaId } = serviceArea
    this.saveLoading = true;
    this.httpRequest.addZones(
      {
        accountId,
        serviceAreaId,
        ...this.zoneForm.value
      }
    ).pipe(
      finalize(()=>this.saveLoading = false)
    ).subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully saved zone.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save zone: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private updateZone(zone: Zone){
    this.saveLoading = true;
    this.httpRequest.updateZones(
      zone.zoneId,
      {
        ...zone,
        ...this.zoneForm.value
      }
    ).pipe(
      finalize(()=>this.saveLoading = false)
    ).subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully updated zone.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update zone: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  // submitServiceArea() {
  //   this.submitted = true;
  //   if(this.serviceAreaForm.invalid || this.selectedBranches.length === 0){
  //     return;
  //   }
  //   this.saveLoading = true;

  //   if(this.serviceAreaZoneTreeNodeInModal){
  //     this.updateServiceArea(this.serviceAreaZoneTreeNodeInModal.serviceArea);
  //   }else{
  //     this.addServiceArea();
  //   }
  // }

  // private updateServiceArea(serviceArea: ServiceArea){
  //   this.httpRequest.updateServiceArea(
  //     serviceArea.serviceAreaId!,
  //     {
  //       ...this.serviceAreaForm.value,
  //       accountId: this.authService.currentAccountSelected.accountId,
  //       postalCodes: this.serviceAreaForm.value.postalCodes.map((postalCode:string) => postalCode.trim()),
  //       branches: this.selectedBranches.map(branch => branch.data!.branchId),
  //       zones: serviceArea.zones
  //     }
  //   ).pipe(
  //     finalize(()=>this.saveLoading = false)
  //   ).subscribe(
  //     res => {
  //       this.loadData();
  //       this.activeModal?.close();
  //       Swal.fire({
  //         title: 'Success',
  //         text: 'Successfully updated service area.',
  //         icon: 'success',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });
  //     },
  //     error => {
  //       Swal.fire({
  //         title: 'Error',
  //         text: 'Failed to update service area: ' + error.error.reason,
  //         icon: 'warning',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });
  //     }
  //   );   
  // }

  // private addServiceArea():void{
  //   this.httpRequest.addServiceArea(
  //     {
  //       ...this.serviceAreaForm.value,
  //       accountId: this.authService.currentAccountSelected.accountId,
  //       postalCodes: this.serviceAreaForm.value.postalCodes.map((postalCode:string) => postalCode.trim()),
  //       branches: this.selectedBranches.map(branch => branch.data!.branchId)
  //     }
  //   ).pipe(
  //     finalize(()=>this.saveLoading = false)
  //   ).subscribe(
  //     res => {
  //       this.loadData();
  //       this.activeModal?.close();
  //       Swal.fire({
  //         title: 'Success',
  //         text: 'Successfully saved service area.',
  //         icon: 'success',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });
  //     },
  //     error => {
  //       Swal.fire({
  //         title: 'Error',
  //         text: 'Failed to save service area: ' + error.error.reason,
  //         icon: 'warning',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });
  //     }
  //   );
  // }

  deleteZone(){
    this.saveLoading = true;
    this.httpRequest.deleteZones(this.serviceAreaZoneTreeNodeInModal?.zone?.zoneId!)
    .pipe(
      finalize(()=>this.saveLoading = false)
    ).subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted zone.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete zone: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });       
      }
    );
  }

  // deleteServiceArea(){
  //   this.saveLoading = true;
  //   this.httpRequest.deleteServiceArea(this.serviceAreaZoneTreeNodeInModal?.serviceArea.serviceAreaId!)
  //   .pipe(
  //     finalize(()=>this.saveLoading = false)
  //   ).subscribe(
  //     res => {
  //       this.loadData();
  //       this.activeModal?.close();
  //       Swal.fire({
  //         title: 'Success',
  //         text: 'Successfully deleted service area.',
  //         icon: 'success',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });
  //     },
  //     error => {
  //       Swal.fire({
  //         title: 'Error',
  //         text: 'Failed to delete service area: ' + error.error.reason,
  //         icon: 'warning',
  //         showCancelButton: false,
  //         confirmButtonColor: 'rgb(60,76,128)',
  //         confirmButtonText: 'Ok',
  //       }).then((result) => {
  //         //do nothing
  //       });       
  //     }
  //   );
  // }

  // onAddZipCode(event: {value: string}): void {
  //   // this.mapboxZipcodePicker.addSelectedZipCodes([event.value]);
  // }

  // onRemoveZipCode(event: {value: string}): void {
  //   // this.mapboxZipcodePicker.removeSelectedZipCodes([event.value]);
  // }

  // onSelectedZipCodesChange(zipCodes: string[]): void {
  //   this.serviceAreaFormControls['postalCodes']?.setValue(zipCodes);
  // }
}