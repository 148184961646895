import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { ToastService } from 'src/app/services/toast-service';
import { AccountsPayableModel } from 'src/app/core/services/models/accounts-payable.model';
import { Observable } from 'rxjs';
import { AccountsPayableService } from 'src/app/core/services/accounts-payable-service/accounts-payable.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-accounts-payable',
  templateUrl: './accounts-payable.component.html',
  styleUrls: ['./accounts-payable.component.scss'],
  providers: [AccountsPayableService, DecimalPipe]

})
export class AccountsPayableComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  modalType = '';
  error = '';
  submitted = false;

  activeModal?: NgbModalRef;
  accountsPayableForm!: FormGroup;
  accountsPayable: any;
  selectedAccount: any;

  ListJsList$: Observable<AccountsPayableModel[]>;
  total$: Observable<number>;

  statesJson: any = [];
  zipcodesJson: any = [];
  constructor(
    private auth: AuthService,
  	private modalService: NgbModal,
  	private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    public toastService: ToastService,
    public service: AccountsPayableService,
    private router: Router
  ) {
    this.ListJsList$ = service.accountsPayable$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.initForms();
    this.getAccountsPayable();
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });
    // this.showSuccessToast();
  }

  initForms() {
  	this.accountsPayableForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      isDefault: [false, [Validators.required]],
      billingCompanyName: ['', [Validators.required]],
      billingCompanyStreet: ['', [Validators.required]],
      billingCompanyCity: ['', [Validators.required]],
      billingCompanyState: ['', [Validators.required]],
      billingCompanyZipCode: ['', [Validators.required]],
      billingContactFirstName: ['', [Validators.required]],
      billingContactLastName: ['', [Validators.required]],
      billingContactEmail: ['', [Validators.required]],
      billingContactPhone: ['', [Validators.required]],
      billingContactEmailCC: [''],
    });
  }

  customSearchFn(term: string, item: any) {
    item.techName = item.techName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.techName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.techName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  getAccountsPayable() {
    setTimeout(() =>{
      this.httpRequest.getAccountsPayable().subscribe((data) => {
        if(data) {
          this.accountsPayable = data.data;
        } else {
          this.accountsPayable = ['1'];
        }
      })
    }, 1500);
  }

  addAccountsPayable() {
    this.submitted = true;
    if(this.accountsPayableForm.invalid) {
      return;
    } else {
      let dataToSend = {
        "accountId": this.auth.currentAccountSelected.accountId,
        "name": this.accountsPayableForm.controls['name'].value,
        "isDefault": this.accountsPayableForm.controls['isDefault'].value
      }
      this.httpRequest.addAccountsPayable(dataToSend).subscribe((data) => {
        this.initForms();
        this.getAccountsPayable();
        this.submitted = false;
        this.activeModal?.close();
      }, error => {
        this.submitted = false;
      })
    }
  }

  editAccountsPayable() {
    this.submitted = true;
    if(this.accountsPayableForm.invalid) {
      return;
    } else {
      let dataToSend = {
        "accountPayableId": this.selectedAccount.accountPayableId,
        "name": this.accountsPayableForm.controls['name'].value,
        "isDefault": this.accountsPayableForm.controls['isDefault'].value
      }
      this.httpRequest.updateAccountsPayable(this.selectedAccount.accountPayableId, dataToSend).subscribe((data) => {
        this.initForms();
        this.getAccountsPayable();
        this.submitted = false;
        this.activeModal?.close();
      }, error => {
        this.submitted = false;
      })
    }
  }

  deleteAccountsPayable(data: any) {
    if(data.isDefault) {
      Swal.fire({
        title: "Can't delete the default account",
        text: "Please change the default account and try again.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        
      });
    } else {
      Swal.fire({
        title: 'Are you Sure?',
        text: 'Are you sure you want to delete ' + data.name + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      }).then(result => {
        if (result.value) {
          this.httpRequest.deleteAccountsPayable(data.accountPayableId).subscribe((data) => {
            this.getAccountsPayable();
            this.service.updateTable();
          }, error => {
      
          })
        }
      });
      
    }
  }

  openModal(content: any, type: string, data: any) {
    if(type == 'add') {
      this.modalType = 'Add';
    } else {
      this.modalType = 'Edit';
      this.selectedAccount = data;
      this.accountsPayableForm.controls['name'].setValue(data.name);
      this.accountsPayableForm.controls['isDefault'].setValue(data.isDefault);
    }
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
      this.service.updateTable();
    });
    
  }

  get form() {
    return this.accountsPayableForm.controls;
  }

  showSuccessToast() {
    this.toastService.show('Account has been successfully deleted.', { classname: 'bg-success text-center text-white', delay: 5000, autohide: true });
  }

}
