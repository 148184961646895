<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #zonesMenu="ngbNav" [activeId]="activeTab"class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink>
                        Service Areas
                    </a>
                    <ng-template ngbNavContent>
                        <app-service-areas></app-service-areas>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item" 
                    (click)="changeTab(3)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Rates</span>
                    </a>
                    <ng-template ngbNavContent>

                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="nav-item" 
                    (click)="changeTab(4)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Routes</span>
                    </a>
                    <ng-template ngbNavContent>
                      <app-zones-routes></app-zones-routes>
                    </ng-template>
                </li>
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content text-muted">
              <div [ngbNavOutlet]="zonesMenu"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->
