<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li *ngFor="let tab of TabKeys" [ngbNavItem]="TABS[tab]" class="nav-item" (click)="setActiveTab(TABS[tab]);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            {{tab}}
                        </a>
                        <ng-template ngbNavContent>
                            <app-vehicle-trucks-tab *ngIf="TABS[tab] === TABS.Trucks"></app-vehicle-trucks-tab>
                            <app-vehicle-trailers-tab *ngIf="TABS[tab] === TABS.Trailers"></app-vehicle-trailers-tab>
                            <app-vehicle-combos-tab *ngIf="TABS[tab] === TABS.Combos"></app-vehicle-combos-tab>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="card-body p-4">
                    <div class="tab-content">
                        <div [ngbNavOutlet]="customNav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>