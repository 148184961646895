import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('[LoginGuard] [canActivate]', state.url);
    const currentUser = this.auth.currentUserValue;
    if (currentUser) {
      // already logged in, so redirect to dashboard
      return this.router.navigate(['/pages/dashboard'])
        .then(() => true);
    }
    return true;
  }

}
