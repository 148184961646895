import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../core/services/http-service';
import { SuccessApiResponse } from '../core/services/models/models';
import { UserProfile } from '../core/services/models/user-profile.model';

@Component({
  selector: 'app-demo-public-schedule',
  templateUrl: './demo-public-schedule.component.html',
  styleUrls: ['./demo-public-schedule.component.scss']
})
export class DemoPublicScheduleComponent implements OnInit {
  slug: string;
  urlSafe: SafeResourceUrl;

  constructor(
    private httpRequest: HttpService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params['slug'];
    if(this.slug) {
      this.getUserDetails();
    }
  }

  getUserDetails() {
    this.httpRequest.getUserByIdentifier(this.slug)
    .subscribe(
      res => {
        const successRes = <SuccessApiResponse<UserProfile>> res;
        const userProfile = successRes.data;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://schedule.nylas.com/${userProfile.userId}`);
    })
  }
}
