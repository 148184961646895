<div class="row">
    <div class="col-lg-2">
        <button *ngIf="permissionsService.permissions.Users.Create" type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal" (click)="openModal(content, 'add', '')">
            <i class="ri-add-line align-bottom me-1"></i> Add </button>
    </div>
    <div class="col-lg-6"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th class="sort" data-sort="firstName">First Name</th>
            <th class="sort" data-sort="lastName">Last Name</th>
            <th class="sort" data-sort="title">Title</th>
            <th class="sort" data-sort="email">Email</th>
            <th class="sort" data-sort="phone">Phone</th>
            <th class="sort">Action</th>
        </tr>
    </thead>
    <tbody *ngIf="!showData">
        <tr>
            <td class="placeholder-glow first-name-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="placeholder-glow last-name-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="placeholder-glow title-col">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow email-col">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow phone-col">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow role-col">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow">
                <span class="placeholder col-6"></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="showData">
        <tr *ngFor="let data of ListJsList$ | async">
            <td class="first-name-col">
                <ngb-highlight class="clickable-column" (click)="openModal(content, 'edit', data)" [result]="data.firstName" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="last-name-col">
                <ngb-highlight class="clickable-column" (click)="openModal(content, 'edit', data)" [result]="data.lastName" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="title-col">
                <ngb-highlight [result]="data.title" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="email-col">
                <ngb-highlight [result]="data.email" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="phone-col">
                <ngb-highlight [result]="data.phone" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="role-col" *ngIf="data.role == 'carrier-owner' || data.role == 'shipper-owner'"> Owner </td>
            <td class="role-col" *ngIf="data.role == 'carrier-driver'"> Driver </td>
            <td class="role-col" *ngIf="data.role == 'carrier-helper'"> Helper </td>
            <td class="role-col" *ngIf="data.role == 'carrier-dispatcher'"> Dispatcher </td>
            <td class="role-col" *ngIf="data.role == 'carrier-office-manager'"> Office Manager </td>
            <td class="role-col" *ngIf="data.role == 'shipper-logistics-coordinator'"> Logistics Coordinator </td>
            <td class="role-col" *ngIf="data.role == 'shipper-operations-manager'"> Operations Manager </td>
            <td>
                <div class="d-flex gap-2"></div>
            </td>
        </tr>
    </tbody>
</table>
<div class="row justify-content-md-between align-items-md-center" *ngIf="(total$ | async) as total">
    <div class="col col-sm-6">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite"> Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries </div>
    </div>
    <div class="col col-sm-6">
        <div class="text-sm-right float-sm-end listjs-pagination">
            <ngb-pagination [collectionSize]="total" [(page)]="service.page" [pageSize]="service.pageSize"></ngb-pagination>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="step-container" ngbAutofocus>
        <p-steps [model]="items" [readonly]="false" [(activeIndex)]="activeItemIndex" (activeIndexChange)="activeIndexChange()"></p-steps>
        <div class="step-content user-modal" [ngSwitch]="activeItemIndex">
            <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
                {{ error }}
            </div>
            <div *ngSwitchCase="0">
                <form [formGroup]="emailForm">
                    <div class="modal-body info-content">
                        <div class="row mb-3">
                            <div class="col-lg-3 user-form-label text-align-right">
                                <label for="email-field" class="form-label">Email: <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="email" id="email-field" class="form-control" required formControlName="email" [ngClass]="{ 'is-invalid': formEmail['email'].dirty && formEmail['email'].touched && formEmail['email'].errors }" />
                                <div *ngIf="formEmail['email'].dirty && formEmail['email'].touched && formEmail['email'].errors" class="invalid-feedback">
                                    <div *ngIf="formEmail['email'].errors['required']">Enter Email</div>
                                    <div *ngIf="formEmail['email'].errors['email']">Email must be a valid email address </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngSwitchCase="1">
                <div *ngIf="emailExists == true" class="text-align-center">
                    <h1>TBD</h1>
                </div>
                <form [formGroup]="userForm" *ngIf="modalType=='Edit' || emailExists == false">
                    <div class="modal-body info-content">
                        <div class="row mb-3">
                            <div class="col-lg-3 user-form-label text-align-right">
                                <label for="firtname-field" class="form-label">First Name: <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text" id="firtname-field" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': form['firstName'].dirty && form['firstName'].touched && form['firstName'].errors }" />
                                <div *ngIf="form['firstName'].dirty && form['firstName'].touched && form['firstName'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['firstName'].errors['required']">Enter First Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-3 user-form-label text-align-right">
                                <label for="lastname-field" class="form-label">Last Name: <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text" id="lastname-field" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': form['lastName'].dirty && form['lastName'].touched  && form['lastName'].errors }" />
                                <div *ngIf="form['lastName'].dirty && form['lastName'].touched && form['lastName'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['lastName'].errors['required']">Enter Last Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-3 user-form-label text-align-right">
                                <label for="title-field" class="form-label">Title: <span class="opacity-0">*</span>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text" id="title-field" class="form-control" formControlName="title" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-3 user-form-label text-align-right">
                                <label for="phone-field" class="form-label">Phone: <span class="text-danger">*</span>
                                </label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text" id="phone-field" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': form['phone'].dirty && form['phone'].touched && form['phone'].errors }" />
                                <div *ngIf="form['phone'].dirty && form['phone'].touched && form['phone'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['phone'].errors['required']">Enter Phone</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngSwitchCase="2">
                <div class="d-flex justify-content-center">
                    <div class="modes-container">
                        <div class="field-checkbox" *ngFor="let mode of modes">
                            <p-checkbox [name]="mode.name" [value]="mode.modeId" [(ngModel)]="selectedModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                            {{mode.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="3">
                <div class="d-flex justify-content-center">
                    <div class="branches-container">
                        <app-tree-checkbox [options]="branches" [(selected)]="selectedBranches"></app-tree-checkbox>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <div class="branches-container text-muted">
                        <small>* Hold Shift Key while clicking checkbox to prevent propagation.</small>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="4">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card-header">
                            <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 justify-content-center" role="tablist">
                                <li [ngbNavItem]="0" class="nav-item" (click)="activeTab = 0">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                        <i class="fas fa-home"></i> Current Roles </a>
                                    <ng-template ngbNavContent>
                                        <div class="d-flex align-items-center flex-column">
                                            <div>
                                                <div class="field-checkbox" *ngFor="let role of permissionsTabModalObj.currentRoles; let i=index" (mouseenter)="permissionsTabModalObj.onCurrentRoleMouseEnter(role)" (mouseleave)="permissionsTabModalObj.onCurrentRoleMouseLeave()">
                                                    <p-checkbox name="currentRoles" (onChange)="permissionsTabModalObj.onCurrentRoleChange()" [(ngModel)]="role.selected" ngDefaultControl [ngModelOptions]="{standalone: true}" [binary]="true"></p-checkbox>
                                                    {{role.name}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="1" class="nav-item" (click)="activeTab = 1">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                        <i class="fas fa-home"></i> Current Permissions </a>
                                    <ng-template ngbNavContent>
                                        <div class="row mb-3">
                                            <div class="col-lg-3"></div>
                                            <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
                                                {{permissionGetter === 'Read' ? 'View' : permissionGetter}}
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let feature of features">
                                            <div class="row mb-3">
                                                <div class="col-lg-3 user-form-label text-align-right">
                                                    <label for="type-field" class="form-label">{{feature === '*' ? 'All' : feature}}
                                                        <span class="opacity-0">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
                                                    <p-checkbox [name]="feature" [value]="permissionGetter" [(ngModel)]="permissionsTabModalObj.currentPermissions.featuresCheckboxValues[feature]" (onChange)="permissionsTabModalObj.onCurrentPermissionsChange()"></p-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body mt-4">
                            <div [ngbNavOutlet]="customNav"></div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-header">
                            <ul ngbNav #customNav2="ngbNav" [activeId]="0" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 justify-content-center" role="tablist">
                                <li [ngbNavItem]="0" class="nav-item" (click)="activeTab = 0">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                        <i class="fas fa-home"></i> New Permissions </a>
                                    <ng-template ngbNavContent>
                                        <div class="row mb-3">
                                            <div class="col-lg-3"></div>
                                            <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
                                                {{permissionGetter === 'Read' ? 'View' : permissionGetter}}
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let feature of features">
                                            <div class="row mb-3">
                                                <div class="col-lg-3 user-form-label text-align-right">
                                                    <label for="type-field" class="form-label">{{feature === '*' ? 'All' : feature}}
                                                        <span class="opacity-0">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
                                                    <p-checkbox [name]="feature" [value]="permissionGetter" [disabled]="true" [ngClass]="{'selected-highlight': permissionsTabModalObj.focusedRole && permissionsTabModalObj.focusedRole.permissions[feature].getNoAll(permissionGetter)}" [(ngModel)]="permissionsTabModalObj.previewPermissions.featuresCheckboxValues[feature]"></p-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body mt-4">
                            <div [ngbNavOutlet]="customNav2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !(modalType=='Edit' && usersPermissions && usersPermissions.Delete)}">
            <span *ngIf="modalType=='Edit' && usersPermissions && usersPermissions.Delete" class="clickable-column me-auto d-flex align-items-center text-danger" (click)="delete()">
                <div *ngIf="!saveLoading" class="inline-block"> Delete </div>
                <div *ngIf="saveLoading" class="spinner-border inline-block user-save-loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <div>
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            </div>
            <div>
                <button type="button" class="btn btn-primary action-btn" id="next-btn" [disabled]="(activeItemIndex == 0 && formEmail['email'].errors)
                            || (activeItemIndex == 1 && !emailExists && (form['firstName'].errors || form['lastName'].errors || form['phone'].errors))
                            || saveLoading" *ngIf="activeItemIndex != (items.length-1)" (click)="handleNext()">
                    <div *ngIf="!saveLoading" class="inline-block"> Next </div>
                    <div *ngIf="saveLoading" class="spinner-border inline-block user-save-loading" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
                <button type="button" class="btn btn-primary" [disabled]="saveLoading" [ngClass]="{'action-btn': !emailExists, 'l-action-btn': emailExists}" id="add-btn" (click)="saveModal()" *ngIf="activeItemIndex == (items.length-1)">
                    <div *ngIf="!saveLoading" class="inline-block">
                        {{emailExists? 'Send Invite': 'Save'}}
                    </div>
                    <div *ngIf="saveLoading" class="spinner-border inline-block user-save-loading" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-template>