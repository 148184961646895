import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { finalize, last, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Mode } from 'src/app/core/services/models/mode.model';
import { ApiResponse, SuccessApiResponse } from 'src/app/core/services/models/models';
import { ShipperIntegration, ShipperIntegrationPayload } from 'src/app/core/services/models/shipper-integration.model';
import { ShipperIntegrationService } from 'src/app/core/services/shipper-integration-service/shipper-integration-service';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { NgbdModalAddAPIIntegrations } from './modals/add-api-integrations/add-api-integrations.component';
import { environment } from 'src/environments/environment';
declare var Calendly: any;

@Component({
  selector: 'app-api-integrations',
  templateUrl: './api-integrations.component.html',
  styleUrls: ['./api-integrations.component.scss'],
  providers: [ShipperIntegrationService],
})
export class APIIntegrationComponent implements OnInit, OnDestroy {

  activeModal?: NgbModalRef;
  selectedFiles: File[] = [];
  fileNames: string[] = [];
  window = window;
  currentShipperIntegration?: ShipperIntegration;
  shipperIntegrations: ShipperIntegration[];
  shipperIntegrationForm!: FormGroup;
  subscriptions: Subscription[] = [];
  selectedMode: (Mode | null);
  submitted = false;
  loading = false;
  ListJsList$: Observable<ShipperIntegration[]> = of([]);
  total$: Observable<number> = of(0);
  showData = false;
  readonly SOFTWARE_OPTIONS = [
    '3GTMS',
    '3PL Warehouse Manager',
    'Acumatica',
    'Airhouse',
    'Alpega TMS',
    'Alvys',
    'Ascend',
    'Astro WMS',
    'Axon Trucking Software',
    'Bassam Infotech',
    'Bizowie',
    'BluJay Transportation Management',
    'Brahmin Solutions',
    'Brightpearl',
    'Bringg',
    'ChiefEx',
    'Circuit for Teams',
    'Compiere',
    'Datatrac',
    'Descartes Aljex',
    'Descartes Transportation Management System',
    'DispatchTrack',
    'Ecom Express',
    'Epicor',
    'Extensiv 3pl Warehouse Manager',
    'FarEye',
    'Fishbowl Inventory',
    'Flectra',
    'Flowspace',
    'FreightPOP',
    'Helium V',
    'HighJump',
    'Infor SCM',
    'JDA Transportation Management System',
    'Kechie',
    'Latitude WMS',
    'LLamasoft Supply Chain Design',
    'LogiNext Mile',
    'Logiwa WMS',
    'Magaya Supply Chain',
    'Manhattan Associates',
    'Maropost',
    'McLeod Software',
    'MercuryGate TMS',
    'Method:CRM',
    'Microsoft Dynamics 365',
    'NetSuite',
    'NetSuite ERP',
    'NetSuite WMS',
    'Nextbillion.ai',
    'Odoo ',
    'Onfleet',
    'Oracle Cloud Enterprise Resource Planning',
    'Oracle Transportation Management',
    'PCS TMS',
    'project44',
    'Rose Rocket',
    'Rossum',
    'Route4Me',
    'Sage Intacct',
    'Salesforce for Transportation & Logistics',
    'SAP Business One',
    'SAP ERP',
    'SAP S/4HANA',
    'SAP Transportation Management',
    'Scoro',
    'Scurri',
    'ShipBob',
    'ShipHero',
    'ShipMonk',
    'ShipStation',
    'Shipsy',
    'Softeon',
    'Sortly',
    'Striven',
    'SYSPRO ERP',
    'TallyPrime',
    'Techstation',
    'TECSYS WMS',
    'TIA',
    'Tipalti',
    'Tookan',
    'Track-POD',
    'Transplace TMS',
    'Trimble Transportation',
    'Turvo',
    'Z Suite',
    'Other'
  ];
  readonly PROTOCOL_OPTIONS = ['HTTPS', 'AS2', 'FTPS', 'SFTP', 'Other'];
  readonly FORMAT_OPTIONS = ['JSON', 'XML', 'x12', 'Excel', 'CSV', 'Other'];

  @Input() isModalOpen: any;
  @ViewChild('content') content : any;
  constructor(
    public service: ShipperIntegrationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private authService: AuthService,
  ) {
    this.subscriptions.push(
      this.authService.selectedModeSubject.subscribe(
        mode => {
          this.selectedMode = mode;
          this.loadData();
        }
      )
    );
  }

  ngOnInit(): void {
    this.ListJsList$ = this.service.shipperIntegration$;
    this.total$ = this.service.total$;
    this.initForm();
    setTimeout(() => {
      this.showData = true;
    }, 2000);
    if(this.isModalOpen) {
      this.openModals();
    }
  }

  private loadData(){
    this.service.updateTable(this.selectedMode?.modeId!);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initForm(): void {
    const {software, protocol, format} = this.currentShipperIntegration ?? {};
    const matchingSoftwareOption = this.SOFTWARE_OPTIONS
      .find(opt => opt === software);
    const matchingProtocolOption = this.PROTOCOL_OPTIONS
      .find(opt => opt === protocol);
    const matchingFormatOption = this.FORMAT_OPTIONS
      .find(opt => opt === format);
    this.shipperIntegrationForm = this.formBuilder.group({
      name: [this.currentShipperIntegration?.name, [Validators.required]],
      software: [this.getNonOther(matchingSoftwareOption), [Validators.required]],
      otherSoftware: [this.getOther(matchingSoftwareOption, software),[]],
      protocol: [this.getNonOther(matchingProtocolOption), [Validators.required]],
      otherProtocol: [this.getOther(matchingProtocolOption, protocol),[]],
      format: [this.getNonOther(matchingFormatOption), [Validators.required]],
      otherFormat: [this.getOther(matchingFormatOption, format),[]],
      notes: [this.currentShipperIntegration?.notes, []],
    });
    this.selectedFiles = [];
    this.fileNames = [];
    if(this.currentShipperIntegration){
      this.fileNames = this.currentShipperIntegration.integrationFiles?.map(file => file.filename) ?? [];
    }
  }

  private getNonOther(matchingOption?:string){
    if(matchingOption){
      return matchingOption;
    }
    
    if(this.currentShipperIntegration){
      return 'Other';
    }
    
    return '';
  }

  private getOther(matchingOption?:string, value?: string){
    if(matchingOption){
      return '';
    }
    return value;
  }

  getEventValue($event:any) :string {
    return $event.target.value;
  }

  get form(): { [key: string]: AbstractControl } {
    return this.shipperIntegrationForm.controls;
  }

  openModals() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalAddAPIIntegrations, { centered: true, size: 'lg', });
    modalRef.result.then((result: any) => { 
      if(result) {
        
      }
     })
  }

  openModal(content: any, shipperIntegration?: ShipperIntegration) {
    this.currentShipperIntegration = shipperIntegration;
    this.submitted = false;
    this.loading = false;
    this.initForm();
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
    });
  }

  selectFiles(event:any): void {
    const currentFiles: File[] = Array.from(event.target.files);
    const currentFileNames = new Set<string>(currentFiles.map(file=>file.name));
    this.selectedFiles = this.selectedFiles.filter(file => !currentFileNames.has(file.name));
    this.selectedFiles = [
      ...this.selectedFiles,
      ...currentFiles
    ];
    const selectedFileNames = new Set<string>(this.selectedFiles.map(file=>file.name));
    this.fileNames = [
      ...this.fileNames.filter(name => !selectedFileNames.has(name)),
      ...this.selectedFiles.map(file => file.name)
    ];
  }

  unselectFile(fileName: string): void{
    this.selectedFiles = this.selectedFiles.filter(file => file.name !== fileName);
    this.fileNames = this.fileNames.filter(name => name !== fileName);
  }

  deleteMode(){
    this.loading = true;
    this.httpService.deleteShipperIntegration(
      this.currentShipperIntegration?.shipperIntegrationId!
    ).pipe(finalize(()=>this.loading = false))
    .subscribe(
      res => {
        this.activeModal?.close();
        this.loadData();
        Swal.fire({
          title: 'Success',
          text: 'Integration has been deleted.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete integrations: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  save(redirectLink?:string){
    this.submitted = true;
    const {
      software, otherSoftware, 
      protocol, otherProtocol, 
      format, otherFormat
    } = this.shipperIntegrationForm.value;
    if(
      this.shipperIntegrationForm.invalid
      || (software === 'Other' && !otherSoftware)
      || (protocol === 'Other' && !otherProtocol)
      || (format === 'Other' && !otherFormat)
    ){
      return;
    }
    const {name, notes} = this.shipperIntegrationForm.value;
    const payload: ShipperIntegrationPayload = {
      modeId: this.currentShipperIntegration?.modeId ?? this.selectedMode?.modeId!,
      name,
      software: software === 'Other' ? otherSoftware: software,
      protocol: protocol === 'Other' ? otherProtocol: protocol,
      format: format === 'Other' ? otherFormat: format,
      notes,
      integrationFiles: this.fileNames
    };
    this.loading = true;
    let httpCall: Observable<ApiResponse<ShipperIntegration>>;
    if(this.currentShipperIntegration){
      const {shipperIntegrationId} = this.currentShipperIntegration;
      httpCall = this.httpService.updateShipperIntegration(shipperIntegrationId, payload);
    }else{
      httpCall = this.httpService.addShipperIntegration(payload);
    }
    httpCall
      .subscribe(
        res => {
          const successRes = <SuccessApiResponse<ShipperIntegration>> res;
          const shipperIntegration = successRes.data;
          if(this.currentShipperIntegration){
            const originalFileNames = this.currentShipperIntegration.integrationFiles?.map(intFile => intFile.filename) ?? [];
            const toDeleteCalls = originalFileNames.filter(filename => !this.fileNames.includes(filename))
              .map(filename => this.httpService.deleteShipperIntegrationFile(shipperIntegration.shipperIntegrationId, filename));
            if(isArrayEmpty(toDeleteCalls)){
              this.addFiles(shipperIntegration, environment.calendly_url);
              return;
            }
            combineLatest(toDeleteCalls).pipe(last())
              .subscribe(
                res => {
                  this.addFiles(shipperIntegration, environment.calendly_url);
                },
                error => {
                  this.loading = false;
                  Swal.fire({
                    title: 'Error',
                    text: 'Failed to delete file: ' + error.error.reason,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                  }).then((result) => {
                    //do nothing
                  });
                }
              );
          }else{
            this.addFiles(shipperIntegration, environment.calendly_url);
          }
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to save integrations: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  private async addFiles(shipperIntegration: ShipperIntegration, redirectLink?: string): Promise<void>{
    const addCalls = [];
    for(const file of this.selectedFiles){
      addCalls.push(
        this.httpService
          .addShipperIntegrationFile(shipperIntegration.shipperIntegrationId, file)
          .pipe(
            switchMap(res => {
              const successRes = <SuccessApiResponse<{uploadUrl: string}>>res;
              const uploadUrl = successRes.data.uploadUrl;
              return this.httpService.uploadfileAWSS3(uploadUrl, file)
            })
          )
      );
    }
    if(isArrayEmpty(addCalls)){
      this.endSuccessSave(redirectLink);
      return;
    }
    combineLatest(addCalls).pipe(last())
      .subscribe(
        res => {
          this.endSuccessSave(redirectLink);
        },
        error => {
          console.error(error);
          this.loading = false;
          Swal.fire({
            title: 'Error',
            text: 'Failed to upload file: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  private endSuccessSave(redirectLink?:string){
    this.loading = false;
    this.activeModal?.close();
    this.loadData();
    Swal.fire({
      title: 'Success',
      text: 'Integration has been saved.',
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: redirectLink? 'Schedule Call': 'Ok',
    }).then((result) => {
      const { isConfirmed } = result;
      if(isConfirmed && redirectLink){
        // window.location.href=redirectLink;
        const calendlyUrl = environment.calendly_url;
        Calendly.initPopupWidget({ url: calendlyUrl });
      }
    });
  }
}