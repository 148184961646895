import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { BasicComponent } from "./basic/basic.component";
import { NewPasswordComponent } from './new-password/new-password.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { PasswordSuccessComponent } from './password-success/password-success.component';

const routes: Routes = [
  {
    path: "",
    component: BasicComponent
  },
  {
    path: ":code/:email",
    component: NewPasswordComponent
  },
  {
    path: "success",
    component: PasswordSuccessComponent
  },
  {
    path: "email-success",
    component: PasswordResetSuccessComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PassResetRoutingModule { }
