import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { NgbdModalContactInformation } from '../extrapages/company-setting/modals/contact-information/contact-information.component';
import { NgbdModalGenaralCompanyInformation } from '../extrapages/company-setting/modals/general-company-information/general-company-information.component';
import { NgbdModalTransportationCompanyInformation } from '../extrapages/company-setting/modals/transportation-company-information/transportation-company-information.component';

@Component({
  selector: 'app-truckload-tms',
  templateUrl: './truckload-tms.component.html',
  styleUrls: ['./truckload-tms.component.scss']
})
export class TruckloadTMSComponent implements OnInit {
  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Truckload TMS'}
  ];
  constructor() { }

  ngOnInit(): void { }

  redirectToLogin(){
    window.open('https://roserocket.com/#/login', '_blank');
  }

}
