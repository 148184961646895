<div class="row">
    <div class="col-lg-2">
        <button type="button"
            class="btn btn-primary add-btn"
            data-bs-toggle="modal"
            id="create-btn"
            data-bs-target="#showModal"
            (click)="openModal(content)">
            <i class="ri-add-line align-bottom me-1"></i> Add
        </button>
    </div>
    <div class="col-lg-10"></div>
</div>

<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th class="name-col" data-sort="name">Name</th>
        </tr>
    </thead>
    <tbody *ngIf="showData">
        <tr *ngFor="let role of roles">
            <td class="name-col">
                <u class="clickable-column" (click)="openModal(content, role)">{{role.name}}</u>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="!showData">
        <tr class="loader-body">
            <td class="placeholder-glow name-td">
                <span class="placeholder col-6"></span>
            </td>
        </tr>
    </tbody>
</table>

<!-- Add Modes Data -->
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{roleModalObj?.mode}} Role</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');"></button>
    </div>
    <div class="modal-body" *ngIf="roleModalObj">
        <div class="row mb-3">
            <div class="col-lg-3 user-form-label text-align-right">
                <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <input type="text" id="name" class="form-control" name="name" [(ngModel)]="roleModalObj.role.name" />
                <div *ngIf="roleModalObj.errors && roleModalObj.errors.name" class="invalid-feedback" style="display: block;" align="left">
                    <div *ngIf="roleModalObj.errors.name['required']">Enter name</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3">
            </div>
            <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
              {{permissionGetter === 'Read' ? 'View' : permissionGetter}}
            </div>
        </div>
        <ng-container *ngFor="let feature of features">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="type-field" class="form-label">{{feature === '*' ? 'All' : feature}}<span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-2 text-align-center" *ngFor="let permissionGetter of permissionGetters">
                    <p-checkbox [name]="feature" [value]="permissionGetter" [(ngModel)]="roleModalObj.featuresCheckboxValues[feature]"></p-checkbox>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !(roleModalObj?.mode === 'Edit' && rolesPermissions && rolesPermissions.Delete)}">
        <span *ngIf="roleModalObj?.mode === 'Edit' && rolesPermissions && rolesPermissions.Delete"
                class="clickable-column me-auto d-flex align-items-center text-danger"
                (click)="deleteRole()">
            <div *ngIf="!saveLoading"
                class="inline-block">
                Delete
            </div>
            <div *ngIf="saveLoading"
                class="spinner-border inline-block role-save-loading"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </span>
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
        <button type="submit" class="btn btn-primary action-btn" id="add-btn" (click)="saveRole()">
            <div *ngIf="!saveLoading"
                class="inline-block">
                Save
            </div>
            <div *ngIf="saveLoading"
                class="spinner-border inline-block role-save-loading"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
    </div>
</ng-template>
