import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { Route } from 'src/app/core/services/models/route.model';
import { StringNullUndefined } from 'src/app/core/utils/commons';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { NgbdModalSaveCapacity } from '../zones-and-routes/modals/save-capacity/modal-save-capacity.component';

@Component({
  selector: 'app-capacity-and-rates',
  templateUrl: './route-capacity-and-rates.component.html',
  styleUrls: ['./route-capacity-and-rates.component.scss']
})
export class RouteCapacityAndRatesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Capacity'
    }
  ];
  activeModal?: NgbModalRef;
  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  selectedAccount: (Account | null);
  showData = false;
  routes: Route[];
  subscriptions: Subscription[] = [];
  selectedUpdateCnt = 0;

  constructor(
    private httpRequest: HttpService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch)){
            this.setSelectedAndFetchRoutes(account, mode, branch);
            return;
          }

          if(this.allSelectedSame(account, mode, branch)){
            this.showData = true;
            return;
          }

          this.setSelectedAndFetchRoutes(account, mode, branch);
        }
      )
    );
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private setSelectedAndFetchRoutes(account: Account | null, mode: Mode | null, branch: Branch | null): void {
    this.selectedAccount = account;
    this.selectedMode = mode;
    this.selectedBranch = branch;

    this.fetchRoutes(branch?.branchId, mode?.modeId, account?.accountId, ++this.selectedUpdateCnt);
  }

  private async fetchRoutes(
    branchId: StringNullUndefined, 
    modeId: StringNullUndefined, 
    accountId: StringNullUndefined,
    selectedUpdateCount: number
  ):Promise<void> {
    this.showData = false;
    this.routes = [];

    if(branchId || modeId) {
      const res = await this.httpRequest.getRoutes(branchId, modeId, accountId).toPromise(); 
      
      if(selectedUpdateCount < this.selectedUpdateCnt){
        console.warn(`[fetchRoutes] not updating routes since selectedUpdateCount is delayed ${selectedUpdateCount} < ${this.selectedUpdateCnt}`);
      }else{
        this.routes = res.data;
      }
    }

    this.showData = true;
  }

  openCapacityEditModal(route: Route): void {    
    this.activeModal?.close();
    const modalRef = this.modalService.open(
      NgbdModalSaveCapacity, 
      {
        size: 'lg',
        centered: true,
        modalDialogClass: 'full-page-modal-container'
      }
    );
    modalRef.componentInstance.zipCodes = [...route.postalCodes];
    //TODO: from https://github.com/project-dtc/issues/issues/1203#issuecomment-1422576434
    // modalRef.componentInstance.serviceTypes = [];
    // modalRef.componentInstance.truckTrailerCombo = this.id_truckTrailerCombosMap[truckTrailerCombo];
  }
}
