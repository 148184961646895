import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GeoLocateResponse } from "./models/map.model";

import ZIP_LNG_LAT from '../../../assets/jsons/zip-lng-lat.json';

@Injectable({ providedIn: 'root' })
export class MapService {
    readonly API_KEY = 'AIzaSyBn0jTCeGH80YMU_sX9mfMNjtJHpmB3yiU';
    readonly CHICAGO = { lat: 41.850033, lng: -87.6500523 };

    private readonly GEO_LOCATE_API = `https://www.googleapis.com/geolocation/v1/geolocate?key=${this.API_KEY}`;
    
    constructor(private http: HttpClient) {
    }

    geoLocateIP(): Observable<GeoLocateResponse> {
        return this.http.post<GeoLocateResponse>(this.GEO_LOCATE_API, {considerIp: true});
    }
}