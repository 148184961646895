import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
// Service
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { GeneralMessageService } from 'src/app/services/general-message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  submitted = false;
  fieldTextType!: boolean;
  error = '';
  returnUrl!: string;

  toast!: false;

  year: number = new Date().getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private generalMessageService: GeneralMessageService,
    private httpRequest: HttpService
    ) {
      if (this.auth.currentUserValue) {
        this.router.navigate(['/pages/dashboard']);
      }
     }

  ngOnInit(): void {
     this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
   onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    } else {
      this.auth.login(this.f['email'].value, this.f['password'].value).pipe(first()).subscribe(data => {
        this.httpRequest.setDefaultAccount(data.data.token).subscribe((data: any) => {
          // sessionStorage.setItem('selectedAccount', JSON.stringify(data.data[0]));
          this.auth.setSelectedAccount(data.data[0]);
          window.location.reload();
        })
      }, error => {
        this.submitted = false;
        this.error = error ? error : '';
        this.handleError(error);
      });
    }
  }

  redirectPasswordReset() {
  }

  handleError(response: HttpErrorResponse): void {
    if (response.status === HttpStatusCode.Forbidden) {
      this.generalMessageService.icon = {
        containerCls: 'avatar-title bg-light text-primary display-5 rounded-circle',
        cls: 'ri-mail-line'
      };
      this.generalMessageService.text = {
        header: 'Your email is not verified',
        message: 'Please click the verification link in your email and login again.'
      };
      this.generalMessageService.goToLink = {
        label: 'Back to Login',
        url: '/login'
      };
      this.router.navigate(['/general-message']);
    }
  }

  /**
   * Password Hide/Show
   */
   toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
