import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { AuthService } from "./authentication/auth.service";
 
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    
  constructor(private auth: AuthService) {
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
          } else {
              switch (error.status) {
                  case 401:      //login
                      // this.auth.logout();
                      // window.location.reload();
                  case 400: {
                    // console.log(error);
                    break;
                  }
                    
              }
          } 
      } else {
          console.error("some thing else happened", error);
      }
      return throwError(error);
      })
    )
  }
}