import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';

import { NgbdModalAddZone } from '../add-zone/add-zone.component'

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './add-service-area.component.html',
  styleUrls: ['./add-service-area.component.scss'],

})
export class NgbdModalAddServiceArea implements OnInit{
  @Input() defaultZones: any = [];

  serviceAreaForm!: FormGroup;
  submitted = false;
  branches: any;
  serviceZones: any = [];
  hasZone = true;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef
   ) {}

  ngOnInit(): void {
    this.getBranches();
    this.initForms();
  }

  initForms() {
    this.serviceAreaForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      zones: ['', [Validators.required]],
    });
  }

  get form() {
    return this.serviceAreaForm.controls;
  }

  getBranches() {
      this.httpRequest.getBranches().subscribe((data:any) => {
        this.branches = data.data;
      }, error => {

      })
  }

  openModalZone() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalAddZone, { size: 'lg', centered: true });
    modalRef.componentInstance.zones = this.defaultZones;
  }

  close() {
    this.activeModal?.close();
  }

  addServiceArea() {
    this.submitted = true;
    if(this.defaultZones.length == 0) {
      this.hasZone = false;
      return;
    } else {
      this.serviceAreaForm.controls['zones'].setValue(this.defaultZones);
      if(this.serviceAreaForm.invalid) {
        return;
      } else {
      }
    }
    
  }
}