<div id="table">
    <div class="tr">
        <div class="td text-align-right">Meeting Link:</div>
        <div class="td">
            <input type="text" class="meeting-link-input me-3 form-control" [(ngModel)]="meetingLink" placeholder="Zoom, Google Meet, Microsoft Teams, or a phone number" />
            <button type="button" class="btn btn-primary action-btn" (click)="saveMeetingLink()" [disabled]="meetingLinkSaveLoading">
                <div *ngIf="!meetingLinkSaveLoading" class="inline-block"> Save </div>
                <div *ngIf="meetingLinkSaveLoading" class="spinner-border inline-block meeting-link-save-loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </div>
    <div class="tr">
        <div class="td text-align-right">Availability:</div>
        <div class="td">
            <button type="button" class="btn btn-primary action-btn" (click)="edit()"> Edit </button>
        </div>
    </div>
    <div class="tr">
        <div class="td text-align-right">Scheduling Link:</div>
        <div class="td">
            <a [href]="scheduleLink" target="_blank">
                <u>{{scheduleLink}}</u>
            </a> &nbsp; <button type="button" class="btn btn-primary action-btn" (click)="copyText(scheduleLink)"> Copy </button>
        </div>
    </div>
</div>