import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TreeNode } from 'primeng/api';
import { HttpService } from '../core/services/http-service';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  html: string;
  place: object = {};
  preserveSpace = true;

  toggle = true;
  optionOne = true;
  optionTwo = false;
  status = 'Enable';
  orgChartData!: TreeNode[];
  orgChartData2!: TreeNode[];
  orgChartSelectedNodes: TreeNode[] = [];
  orgChartSelectedNodes2: TreeNode[] = [];
  orgChartMaxLevel = 5;

  dataSource = [
    { id: 1, name: "Angular", price: "45.00" },
    { id: 2, name: "React Js", price: "30.00" },
    { id: 3, name: "Vue Js", price: "20.00" }
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
  }

  private _formData: any = window.sessionStorage.getItem('pdfForm') 
    ? JSON.parse(window.sessionStorage.getItem('pdfForm')!)
    :{};

  constructor(
    private cdr: ChangeDetectorRef,
    private httpService: HttpService
  ) {
  }

  get formData(): any {
    return this._formData;
  }

  set formData(data: any) {
    console.log('[formData] data', data);
    this._formData = data;
    window.sessionStorage.setItem('pdfForm', JSON.stringify(data));
  }

  ngOnInit(): void {
    this.initOrgChart();
  }

  async redirectToStripeSessionUrl(){
    const stripSessionUrl = (await this.httpService.createStripeCheckoutSession().toPromise()).data.url;
    window.location.href = stripSessionUrl;
  }

  onPlaceChanged(event: object): void {
    this.place = event;
    this.cdr.detectChanges();
  }

  select(selected: any) {
    this.toggle = !this.toggle;
    if(selected == 'one') {
      this.optionOne = true;
      this.optionTwo = false;
    } else {
      this.optionOne = false;
      this.optionTwo = true;
    }
  }

  onOrgChartCheckBoxChange(event: any): void {
    console.log('onOrgChartCheckBoxChange', event);
    console.log('selected OrgChart nodes:', this.orgChartSelectedNodes);
  }

  onOrgChartCheckBoxChange2(event: any): void {
    console.log('onOrgChartCheckBoxChange2', event);
    console.log('selected OrgChart2 nodes:', this.orgChartSelectedNodes2);
  }

  randomInt(min = 0, max = 10): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  randomName(prefix = 'test'): string {
    return prefix + '-' + this.randomInt(10000, 100000)
  };

  private generateRandomTreeNode(maxLevel: number, level: number): TreeNode {
    const key = this.randomName(`level-${level}`);
    console.log(`Generating OrgChart Level ${level} out of ${maxLevel} for key '${key}'`);
    const numberOfChildren = level >= maxLevel ? 0 : this.randomInt(1, 5);
    return {
      label: key,
      key,
      expanded: true,
      children: numberOfChildren > 0 ? [...Array(numberOfChildren).keys()].map(() => this.generateRandomTreeNode(maxLevel, level + 1)) : undefined,
    };
  }

  initOrgChart(): void {
    this.orgChartData = [this.generateRandomTreeNode(this.orgChartMaxLevel, 0)];
    this.orgChartData2 = [
      {
        label: 'Guitar Center Headquarters',
        key: 'Guitar Center Headquarters',
        expanded: true,
        children: [
          {
            label: 'Full Truck Load',
            key: 'Full Truck Load',
            expanded: true,
            children: [
              {
                label: 'DC1 MKE Guitar Center',
                key: 'DC1 MKE Guitar Center',
                expanded: true,
                children: []
              },
              {
                label: 'DC4 MSP Guitar Center',
                key: 'DC4 MSP Guitar Center',
                expanded: true,
                children: []
              }
            ]
          },
          {
            label: 'LTL',
            key: 'LTL',
            expanded: true,
            children: [
              {
                label: 'DC4 MSP Guitar Center',
                key: 'DC4 MSP Guitar Center',
                expanded: true,
                children: [
                  {
                    label: 'Store 87',
                    key: 'Store 87',
                    expanded: true,
                    children: []
                  },
                  {
                    label: 'Store 46',
                    key: 'Store 46',
                    expanded: true,
                    children: []
                  },
                  {
                    label: 'Store 72',
                    key: 'Store 72',
                    expanded: true,
                    children: []
                  }
                ]
              }
            ]
          },
          {
            label: 'Final Mile',
            key: 'Final Mile',
            expanded: true,
            children: [
              {
                label: 'Midwest Region',
                key: 'Midwest Region',
                expanded: true,
                children: [
                  {
                    label: 'DC4 MSP Guitar Center',
                    key: 'DC4 MSP Guitar Center',
                    expanded: true,
                    children: [
                      {
                        label: 'Store 87',
                        key: 'Store 87',
                        expanded: true,
                        children: []
                      },
                      {
                        label: 'Store 46',
                        key: 'Store 46',
                        expanded: true,
                        children: []
                      },
                      {
                        label: 'Store 72',
                        key: 'Store 72',
                        expanded: true,
                        children: []
                      }
                    ]
                  }
                ]
              },
              {
                label: 'East Region',
                key: 'East Region',
                expanded: true,
                children: [
                  {
                    label: 'Store 22',
                    key: 'Store 22',
                    expanded: true,
                    children: []
                  },
                  {
                    label: 'Store 55',
                    key: 'Store 55',
                    expanded: true,
                    children: []
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
