<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" *ngIf="auth.currentAccountSelected.accountType == 'shipper-account' || auth.currentAccountSelected.accountType == 'broker-account'">
                            <i class="fas fa-home"></i> My Carriers </a>
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" *ngIf="auth.currentAccountSelected.accountType == 'carrier-account'">
                            <i class="fas fa-home"></i> My Shippers </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" *ngIf="auth.currentAccountSelected.accountType == 'shipper-account' || auth.currentAccountSelected.accountType == 'broker-account'">Carrier</th>
                                            <th scope="col" *ngIf="auth.currentAccountSelected.accountType == 'carrier-account'">Shipper</th>
                                            <th scope="col">Route</th>
                                            <th scope="col">Documents</th>
                                            <th scope="col">Signed</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="myCarriers.length != 0">
                                        <tr *ngFor="let carrier of myCarriers">
                                            <td class="carrier-cell">{{carrier.carrierName}}</td>
                                            <td>{{carrier.routeName}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="myCarriers.length == 0">
                                        <tr class="loader-body">
                                            <td class="placeholder-glow name-td carrier-cell">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Search </a>
                        <ng-template ngbNavContent>
                            <app-search [showCard]="false" *ngIf="auth.currentAccountSelected.accountType === 'shipper-account'"></app-search>
                            <app-shipper-search [showCard]="false" *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'"></app-shipper-search>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Pending </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">Carrier</th>
                                            <th scope="col">Route</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="pending.length != 0">
                                        <tr *ngFor="let pending of pending">
                                            <td class="carrier-cell">{{pending.carrierName}}</td>
                                            <td>{{pending.routeName}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="pending.length == 0">
                                        <tr class="loader-body">
                                            <td class="placeholder-glow name-td carrier-cell">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-2"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Completed </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">Carrier</th>
                                            <th scope="col">Route</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="completed.length != 0">
                                        <tr *ngFor="let complete of completed">
                                            <td class="carrier-cell">{{complete.carrierName}}</td>
                                            <td>{{complete.routeName}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="completed.length == 0">
                                        <tr class="loader-body">
                                            <td class="placeholder-glow name-td carrier-cell">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-2"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Blocked </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">Carrier</th>
                                            <th scope="col">Route</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="blocked.length != 0">
                                        <tr *ngFor="let block of blocked">
                                            <td class="carrier-cell">{{block.carrierName}}</td>
                                            <td>{{block.routeName}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="blocked.length == 0">
                                        <tr class="loader-body">
                                            <td class="placeholder-glow name-td carrier-cell">
                                                <span class="placeholder col-6"></span>
                                            </td>
                                            <td class="placeholder-glow">
                                                <span class="placeholder col-2"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>