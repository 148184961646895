<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Edit Capacity for {{vehicleName}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="activeModal.close('Close click');"></button>
</div>
<div class="modal-body">
    <div class="row capacity-table-row">
        <div class="col-12">
            <ul ngbNav #capacityTableNav="ngbNav" [activeId]="activeTab2" class="d-none nav nav-tabs-custom rounded card-header-tabs border-bottom-0 justify-content-center" role="tablist">
                <li [ngbNavItem]="0" class="nav-item" (click)="activeTab2 = 0">
                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Used Capacity </a>
                    <ng-template ngbNavContent>
                        <div class="text-align-center">
                            <div class="capacity-table">
                                <p-table [value]="[capacityTable.total]" styleClass="p-datatable-gridlines">
                                    <ng-template pTemplate="caption"> Total </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>u</th>
                                            <th>w</th>
                                            <th>v</th>
                                            <th>s</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-uvws>
                                        <tr>
                                            <td>{{uvws.u | number:'1.0-0'}}</td>
                                            <td>{{uvws.w | number:'1.0-0'}}</td>
                                            <td>{{uvws.v | number:'1.0-0'}}</td>
                                            <td>{{uvws.s | number:'1.0-0'}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div *ngFor="let day of dayOptions" class="capacity-table">
                                <p-table [value]="[capacityTable.used[day]]" styleClass="p-datatable-gridlines">
                                    <ng-template pTemplate="caption">
                                        {{day}}
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>u</th>
                                            <th>w</th>
                                            <th>v</th>
                                            <th>s</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-uvws>
                                        <tr>
                                            <td>{{uvws.u | number:'1.0-0'}}</td>
                                            <td>{{uvws.w | number:'1.0-0'}}</td>
                                            <td>{{uvws.v | number:'1.0-0'}}</td>
                                            <td>{{uvws.s | number:'1.0-0'}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1" class="nav-item" (click)="activeTab2 = 1">
                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Unused Capacity </a>
                    <ng-template ngbNavContent>
                        <div class="text-align-center">
                            <div class="capacity-table">
                                <p-table [value]="[capacityTable.total]" styleClass="p-datatable-gridlines">
                                    <ng-template pTemplate="caption"> Total </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>u</th>
                                            <th>w</th>
                                            <th>v</th>
                                            <th>s</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-uvws>
                                        <tr>
                                            <td>{{uvws.u}}</td>
                                            <td>{{uvws.w}}</td>
                                            <td>{{uvws.v}}</td>
                                            <td>{{uvws.s}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div *ngFor="let day of dayOptions" class="capacity-table">
                                <p-table [value]="[capacityTable.unused[day]]" styleClass="p-datatable-gridlines">
                                    <ng-template pTemplate="caption">
                                        {{day}}
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>u</th>
                                            <th>w</th>
                                            <th>v</th>
                                            <th>s</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-uvws>
                                        <tr>
                                            <td>{{uvws.u}}</td>
                                            <td>{{uvws.w}}</td>
                                            <td>{{uvws.v}}</td>
                                            <td>{{uvws.s}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="capacityTableNav"></div>
        </div>
    </div>
    <div class="row zips-row">
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex mt-1 mb-2">
                        <h4 class="me-auto pt-1">Zip Codes</h4>
                        <button class="btn btn-primary action-btn" (click)="addZipCodeGroups()"> Add </button>
                    </div>
                    <div class="zip-codes-section">
                        <p-table [value]="zipCodeGroups" selectionMode="single" [(selection)]="selectedZipCodeGroup" dataKey="id">
                            <ng-template pTemplate="body" let-zipCodeGroup let-rowIndex="rowIndex">
                                <tr [pSelectableRow]="zipCodeGroup">
                                    <td *ngIf="currentEditZipCodeIndex !== rowIndex">
                                        <i class="las la-edit fs-4" (click)="currentEditZipCodeIndex = rowIndex"></i> &nbsp;&nbsp; {{zipCodeGroup.id}}
                                    </td>
                                    <td *ngIf="currentEditZipCodeIndex === rowIndex" class="d-flex">
                                        <input #zipElement type="text" class="form-control" [value]="zipCodeGroup.id" />
                                        <button class="btn btn-primary action-btn ms-2" (click)="saveZipCodeGroup(zipCodeGroup.id, zipElement.value)"> Save </button>
                                    </td>
                                    <td>
                                        <div style="width:25px;height:25px" [style.background-color]="zipCodeGroup.color"></div>
                                    </td>
                                    <td class="delete-zip-code-container">
                                        <span class="clickable-column text-danger" (click)="deleteZipCodeGroup(zipCodeGroup.id)"> Delete </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <ul ngbNav #customNav1="ngbNav" [activeId]="activeTab" class="pt-0 nav nav-tabs-custom rounded card-header-tabs border-bottom-0 card-header" [destroyOnHide]="false" role="tablist">
                <li [ngbNavItem]="0" class="nav-item">
                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                        <i class="fas fa-home"></i> Map </a>
                    <ng-template ngbNavContent>
                        <div class="map-container">
                            <app-mapbox-zipcode-picker [zoom]="7" [selectableZipCodes]="zipCodes" [(zipCodeGroups)]="zipCodeGroups" [focusedGroupId]="focusedGroupId"></app-mapbox-zipcode-picker>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1" class="nav-item">
                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                        <i class="fas fa-home"></i> Capacity </a>
                    <ng-template ngbNavContent>
                        <ul ngbNav #customNav="ngbNav" [activeId]="activeTab1" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 justify-content-center" role="tablist">
                            <ng-container *ngFor="let serviceType of serviceTypes; let index=index;">
                                <li [ngbNavItem]="index" class="nav-item" (click)="activeTab1 = index">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                        {{serviceType.name}} - {{serviceType.serviceWindowName}}
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div *ngFor="let capacity of zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id); let i=index;" [ngClass]="{'mt-4': i!==0}">
                                            <div class="d-flex mb-2" [ngClass]="{'ps-0': currentEditCapacityIndex === i}">
                                                <h5 *ngIf="currentEditCapacityIndex !== i">
                                                    <i class="pointer las la-edit fs-4" (click)="currentEditCapacityIndex = i"></i> &nbsp;&nbsp; {{displayDaysInOrder(capacity.days)}}
                                                </h5>
                                                <div *ngIf="currentEditCapacityIndex === i" class="d-flex me-auto mb-1">
                                                    <div *ngFor="let dayOption of dayOptions; let j=index;" class="me-3">
                                                        <p-checkbox [name]="'days'+i" [value]="dayOption" class="me-1" (onChange)="computeCapacityTable()" [(ngModel)]="capacity.days" [inputId]="dayOption"></p-checkbox>
                                                        <label [htmlFor]="dayOption" class="field-checkbox-label">{{dayOption}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="currentSaveCapacityClicked && (isNullish(capacity.days) || capacity.days?.length === 0)" class="text-danger"> Select a day </div>
                                            <div class="d-flex me-2-rem">
                                                <div class="me-4 w-6-rem">
                                                    <label for="start-time">Start Time</label>
                                                    <ng-select for="start-time" [disabled]="currentEditCapacityIndex !== i" [(ngModel)]="capacity.startTime" [items]="hours" class="form-control" bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.startTime)" class="text-danger"> Select start time </div>
                                                </div>
                                                <div class="me-4 w-6-rem">
                                                    <label for="end-time">End Time</label>
                                                    <ng-select for="end-time" [disabled]="currentEditCapacityIndex !== i" [items]="hours" class="form-control" [(ngModel)]="capacity.endTime" bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.endTime)" class="text-danger"> Select end time </div>
                                                </div>
                                                <div class="me-4 w-6-rem">
                                                    <label>Units</label>
                                                    <input type="number" class="form-control" [disabled]="currentEditCapacityIndex !== i" [(ngModel)]="capacity.units" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode 
														<= 57" (keyup)="computeCapacityTable()" />
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.units)" class="text-danger"> Enter units </div>
                                                </div>
                                                <div class="me-4 w-9-rem">
                                                    <label>Total Weight (lb)</label>
                                                    <input type="text" class="form-control" [disabled]="currentEditCapacityIndex !== i" [(ngModel)]="capacity.totalWeight" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" (keyup)="computeCapacityTable()" />
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.totalWeight)" class="text-danger"> Enter total weight </div>
                                                </div>
                                                <div class="me-4 w-9-rem">
                                                    <label>Total Volume (cu in)</label>
                                                    <input type="text" class="form-control" [disabled]="currentEditCapacityIndex !== i" [(ngModel)]="capacity.totalVolume" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" (keyup)="computeCapacityTable()" />
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.totalVolume)" class="text-danger"> Enter total volume </div>
                                                </div>
                                                <div class="me-4 w-4-rem">
                                                    <label>Stops</label>
                                                    <input type="number" class="form-control" [disabled]="currentEditCapacityIndex !== i" [(ngModel)]="capacity.stops" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode 
															<= 57" (keyup)="computeCapacityTable()" />
                                                    <div *ngIf="currentSaveCapacityClicked && isNullish(capacity.stops)" class="text-danger"> Enter stops </div>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <button *ngIf="currentEditCapacityIndex === i" class="btn btn-primary action-btn me-4" (click)="saveCapacity()"> Save </button>
                                                <span *ngIf="currentEditCapacityIndex === i" class="clickable-column text-danger" (click)="deleteCapacity(i)">
                                                    <!-- To avoid white space
                                                    -->Delete
                                                </span>
                                            </div>
                                        </div>
                                        <button *ngIf="isNullish(currentEditCapacityIndex)" class="btn btn-primary mt-4" (click)="addCapacity()"> Add Capacity </button>
                                    </ng-template>
                                </li>
                            </ng-container>
                        </ul>
                        <div class="mt-5 capacity-days-container" [ngbNavOutlet]="customNav"></div>
                    </ng-template>
                </li>
            </ul>
            <div class="mt-2 ps-5 pe-5 zip-tab-container" [ngbNavOutlet]="customNav1"></div>
        </div>
        <div class="col-1">
            <div class="card">
                <div class="card-header text-center" #target>
                    {{selectedZipCodes?.length || 0}} Zips &nbsp; 
                    <i placement="top" 
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." 
                        container="body" 
                        [ngbPopover]="popContent" 
                        [attr.positionTarget]="target" 
                        popoverClass="modal-app-save-capacity-popoverClass" 
                        #popup="ngbPopover" 
                        class="pointer las la-copy fs-4 d-inline-block align-middle" 
                        [ngClass]="{'disabled': !isPaidPlan}"
                        (click)="isPaidPlan ? copyZipCodes(popup) : promptUpgrade()">
                        <ng-template #popContent>
                            <span class="text-success">
                                <i class="las la-check-circle"></i> Zips copied </span>
                        </ng-template>
                    </i>
                </div>
                <div class="zip-codes-section card-body text-center p-0">
                    <p-chips id="zip-chips" [(ngModel)]="selectedZipCodes" [styleClass]="'form-control p-0 border-0'" [inputStyleClass]="'form-control'" [separator]="$any(separatorExp)" (onAdd)="onAddZipCodes()">
                        <ng-template let-item pTemplate="item">
                            {{item}}
                            <br />
                        </ng-template>
                    </p-chips>
                    <div class="text-muted mt-n3" (click)="focusOnZipChipsInput()"> Enter Zips </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="d-flex gap-2 p-4 justify-content-end">
        <div>
            <button type="button" class="btn btn-light" (click)="activeModal.close('Close click');">
                <div class="inline-block"> Cancel </div>
            </button>
        </div>
        <div>
            <button type="submit" class="btn btn-primary action-btn" (click)="save()">
                <div *ngIf="!saveLoading" class="inline-block"> Save </div>
                <div *ngIf="saveLoading" class="spinner-border inline-block route-save-loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </div>
</div>