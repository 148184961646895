import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})


export class NewPasswordComponent implements OnInit {

   passresetForm!: FormGroup;
   submitted = false;
   isLoading = false;
   fieldTextType!: boolean;
   code: any;
   email: any;
   constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpRequest: HttpService
    ) { }
 
   ngOnInit(): void {
      this.passresetForm = this.formBuilder.group({
       password: ['', [Validators.required]],
       cpassword: ['', [Validators.required]]
     });

      this.code = this.route.snapshot.params['code'];
      this.email = this.route.snapshot.params['email'];
   }
 
   get form() { return this.passresetForm.controls; }
    onSubmit() {
        this.submitted = true;
        this.isLoading = true;
        if(this.passresetForm.invalid || this.getPasswordRequirmentErrors().length || this.getConfirmasswordRequirmentErrors().length) {
            console.log(this.getPasswordRequirmentErrors())
            this.isLoading = false;
            return;
        } else {
            let payload = {
                "verificationCode": this.code,
                "password": this.passresetForm.controls['password'].value,
                "email": this.email
              }
            this.httpRequest.forgotPassword(payload).subscribe((data: any) => {
                this.router.navigate(['/password-reset/success'])
            }, error => {
                this.isLoading = false;
            })
        }
   }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    getPasswordRequirmentErrors(): string[] {
        const pw: string = this.form['password'] ? this.form['password'].value : '';
        const passwordRequirmentErrors: string[] = [];
        if (pw.length < 10) {
        passwordRequirmentErrors.push('Must have at least 10 characters in length')
        }
        if (!/[a-z]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have lowercase letter')
        }
        if (!/[A-Z]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have uppercase letter')
        }
        if (!/[0-9]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have number')
        }
        if (!/[!@#&()\-/$=<>?]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have special character')
        }
        return passwordRequirmentErrors;
    }

    getConfirmasswordRequirmentErrors(): string[] {
        const pw: string = this.form['password'] ? this.form['password'].value : '';
        const cpw: string = this.form['cpassword'] ? this.form['cpassword'].value : '';
        const passwordRequirmentErrors: string[] = [];
        if (cpw != pw) {
        passwordRequirmentErrors.push('Password does not match')
        }
        return passwordRequirmentErrors;
    }

}
