import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/services/http-service';
import { LoadBoardModel } from 'src/app/core/services/models/load-board.model';
import { ProposalsModel, ProposalStatus } from 'src/app/core/services/models/proposals.model';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-load-board-list',
  templateUrl: './load-board-list.component.html',
  styleUrls: ['./load-board-list.component.scss']
})
export class LoadBoardListComponent implements OnInit {
  @Input()
  originalLoadBoard?: LoadBoardModel;
  @Input()
  proposalStatuses: ProposalStatus[] = [];

  activeNdx = 0;
  tableHelper: TablePaginationHelper<ProposalsModel>;
  currentProposal ?: ProposalsModel;
  loading = false;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.tableHelper = new TablePaginationHelper(
      {
        loadRecords: (pageRequest) =>{
          return this.httpService.paginatedGetFromList(
            ()=>this.httpService.getProposalsByStatuses(this.proposalStatuses, this.originalLoadBoard?.loadId),
            pageRequest
          ).toPromise()
        }
      }
    );
    this.loadRecords();
  }

  updateProposal(status: ProposalStatus){
    this.loading = true;
    this.httpService.updateProposal(
      this.currentProposal?.proposalId!, {status}
    ).subscribe(
      res => {
        this.loadRecords();
      },
      error=>{
        this.loading = false;
        Swal.fire({
            title: 'Error',
            text: 'Error occured: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
    }
    );
  }

  loadRecords(): void {
    this.loading = true;
    this.tableHelper.loadRecords().then(
      () => {
        const records = this.tableHelper.searchResult;
        if(!isArrayEmpty(records)){
          this.currentProposal = records[0];
        }else{
          this.currentProposal = undefined;
        }
        this.loading = false;
      }
    );
  }



}
