<p-treeTable [value]="options" [columns]="cols" selectionMode="checkbox" [(selection)]="selected">
    <!-- Reference: https://github.com/primefaces/primeng/issues/7387#issuecomment-580260816 -->
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr [ttRow]="rowNode">
            <td *ngFor="let col of columns; let i = index">
                <div>
                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                    <p-checkbox
                        [ngModelOptions]="{standalone: true}"
                        [binary]="true"
                        class="branch-checkbox"
                        [ngModel]="isRowSelected(rowNode)"
                        (click)="toggleRowSelection(rowNode, $event)">
                    </p-checkbox>
                    {{col.field === 'name' ? rowNode.node.label : rowData[col.field]}}
                </div>
            </td>
        </tr>
    </ng-template>
</p-treeTable>