<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #lanesMenu="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink href="/pages/settings/lanes/lanes">
                        Lanes
                    </a>
                    <ng-template ngbNavContent>
                        <app-lanes [showBreadCrumbs]="false"></app-lanes>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item" (click)="changeTab(2)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Setup</span>
                    </a>
                    <ng-template ngbNavContent>
                      
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item" (click)="changeTab(3)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Rates</span>
                    </a>
                    <ng-template ngbNavContent>
                      
                    </ng-template>
                </li>
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content text-muted">
              <div [ngbNavOutlet]="lanesMenu"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->