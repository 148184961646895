import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/services/http-service';
import { ServiceArea, ServiceAreaZone } from 'src/app/core/services/models/service-areas.model';
import { BranchesService, BranchTreeNode } from '../../origin-points/origin-point/branches.service';
import { Mode } from '../../../core/services/models/mode.model';
import { Zone } from 'src/app/core/services/models/zone.model';
import { PrettyTechName, PrettyTechnicalName } from 'src/app/core/services/models/pretty-technical-name';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ZonesAndRoutesService } from '../zones-and-routes.service';
import { Branch } from 'src/app/core/services/models/branch.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { CapacityGroupFormDto } from 'src/app/shared/capacity-form/capacity-form.component';
import { ApiResponse, SuccessApiResponse, TrailerTypeModel, TruckTypeModel } from 'src/app/core/services/models/models';
import { Route } from 'src/app/core/services/models/route.model';
import { combineLatest, forkJoin, of, Subscription } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { flatMap } from 'rxjs/internal/operators';
import { ServiceType } from 'src/app/core/services/models/service-type.model';
import { ServiceTypeSkill } from 'src/app/core/services/models/service-type-skill.model';
import { Location } from '@angular/common';
import { RouteCapacityGroup } from 'src/app/core/services/models/route-capacity-group.model';
import { RouteCapacityRule } from 'src/app/core/services/models/route-capacity-rule.model';
import { ServiceTypeTruckTrailerCombo, ServiceTypeTruckTrailerComboUtil } from 'src/app/core/services/models/service-type-truck-trailer-combo.model';
import { NgbdModalSaveCapacity } from '../modals/save-capacity/modal-save-capacity.component';
import { isArrayEmpty, timeToNumber } from 'src/app/core/utils/commons';
import { Account } from 'src/app/core/services/models/account.model';
import { ServiceWindow } from 'src/app/core/services/models/service-window.model';

@Component({
  selector: 'app-save-route',
  templateUrl: './save-route.component.html',
  styleUrls: ['./save-route.component.scss']
})
export class SaveRouteComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Routes',
      url: '/pages/routes'
    },
    {
      label: this.auth.currentAccountSelected.accountName!
    },
    {
      label: 'Add'
    }
  ];
  selectedServiceArea?: ServiceArea;
  selectedZone?: ServiceAreaZone;
  submitted = false;
  saveLoading = false;
  capacityModalClicked = false;
  mainBranchesOptions: BranchTreeNode[] = [];
  serviceAreaOptions: ServiceArea[] = [];
  zoneOptions: ServiceAreaZone[] = [];
  modeOptions: Mode[] = [];
  serviceableBranchOptions: Branch[] = [];
  postalCodeOptions: string[] = [];
  serviceTypeOptions: PrettyTechnicalName[] = [];
  serviceTypeIdMap: {[key: string]: ServiceType} = {};
  serviceTypeId_skillsMap: {[key: string]: ServiceTypeSkill[]} = {};
  selectedServiceTypeIds: string[] = []
  selectedServiceTypes: ServiceType[] = []
  skillOptions: PrettyTechnicalName[] = [];
  selectedSkillIds: string[] = [];
  selectedSkills: ServiceTypeSkill[] = [];
  skillIdMap: {[key: string]: ServiceTypeSkill} = {};
  truckTrailerComboOptions: PrettyTechnicalName[] = [];
  serviceTypeId_truckTrailerCombosMap: {[key: string]: ServiceTypeTruckTrailerCombo[]} = {};
  id_truckTrailerCombosMap: {[key: string]: ServiceTypeTruckTrailerCombo} = {};
  truckTypeIdMap: {[key: string]: TruckTypeModel} = {};
  trailerTypeIdMap: {[key: string]: TrailerTypeModel} = {};
  serviceWindowIdMap: {[key: string]: ServiceWindow} = {};

  capacityGroups: CapacityGroupFormDto[] = [];
  capDataDefault?: CapacityGroupFormDto;
  activeModal?: NgbModalRef;
  selectedRow: any;

  routeForm!: FormGroup;

  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  selectedAccount: (Account | null);

  originalRoute?: Route;
  originalCapacityGroups?: RouteCapacityGroup[];

  subscriptions: Subscription[] = [];

  hours:PrettyTechName[] = [];
  pickUpStartTimeOptions:PrettyTechName[] = [];
  pickUpEndTimeOptions:PrettyTechName[] = [];
  routeStartTimeOptions:PrettyTechName[] = [];
  routeEndTimeOptions:PrettyTechName[] = [];

  averageLoadOutTime = 0;

  constructor(
    private httpRequest: HttpService,
    private branchService: BranchesService,
    private modalService: NgbModal,
    private serv: ZonesAndRoutesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private location: Location
  ) { 
  }

  ngOnInit(): void {
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hours = [...new Set(jsonData)] as PrettyTechName[];
      this.pickUpStartTimeOptions = [...this.hours];
      this.pickUpEndTimeOptions = [...this.hours];
      this.routeStartTimeOptions = [...this.hours];
      this.routeEndTimeOptions = [...this.hours];
    });

    this.originalRoute = (this.location.getState() as {[key: string]: any})['route'];
    
    if(this.originalRoute){
      this.breadCrumbItems = [
        {
          label: 'Routes',
          url: '/pages/routes'
        },
        {
          label: this.originalRoute.name
        },
        {
          label: 'Edit'
        }
      ];

      this.httpRequest.getRouteCapacityGroupsByRouteId(this.originalRoute.routeId).subscribe(
        async res => {
          this.originalCapacityGroups = [...res.data];
  
          const capacityGroups = res.data;
          const groupId_formDto: {[key: string]: CapacityGroupFormDto} = {};
  
          for(const capacityGroup of capacityGroups){
            groupId_formDto[capacityGroup.routeCapacityGroupId] = await this.getCapacityGroupFormDto(capacityGroup);
          }
  
          this.capacityGroups = capacityGroups.map(
            capacityGroup => groupId_formDto[capacityGroup.routeCapacityGroupId]
          );
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Error fetching capacity: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    }else{ 
      if(window.location.href.includes('/edit-route')){
        this.router.navigateByUrl('/pages/routes');
        return;
      }
    }

    this.selectedMode = JSON.parse(sessionStorage.getItem('selectedMode')!);
    this.selectedBranch = JSON.parse(sessionStorage.getItem('selectedBranch')!);

    if(!this.selectedMode || !this.selectedBranch){
      this.router.navigate(['/pages/routes']);
    }

    this.initForm();

    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch)){
            return;
          }

          if(this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.loadBySelectedEntities();
        }
      )
    );
  }

  private loadBySelectedEntities(): void{
    this.clearSelectedEntitiesDependencies();
    this.getBranchesFromHierarchy();

    this.httpRequest.getServiceAreas(
      this.selectedMode!.modeId!,
      this.selectedBranch?.branchId!
    ).subscribe(
      res => {
        this.serviceAreaOptions = res.data;
        if(this.originalRoute){
          this.serviceAreaIdSelected(this.originalRoute.serviceAreaId);
        }
      }
    );

    this.httpRequest.listServiceTypes(
      this.selectedMode!.modeId!
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<ServiceType[]>>res;
        const serviceTypes = successRes.data;
        this.serviceTypeOptions = [];
        this.serviceTypeIdMap = serviceTypes.reduce(
          (map: {[key: string]: ServiceType} , serviceType)=> {
            this.serviceTypeOptions.push({
              prettyName: `${serviceType.name} (${serviceType.averageDeliveryTime} mins) - ${serviceType.serviceWindowName}`,
              technicalName: serviceType.serviceTypeId
            })
            map[serviceType.serviceTypeId] = serviceType;
            return map;
          }, 
          {}
        );
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service types: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private clearSelectedEntitiesDependencies(): void {
    this.mainBranchesOptions = [];
    this.serviceAreaOptions = [];
    this.postalCodeOptions = [];
    this.zoneOptions = [];
    this.serviceTypeOptions = [];
    this.serviceTypeIdMap = {};
    this.serviceWindowIdMap = {};
    this.serviceTypeId_skillsMap = {};
    this.selectedServiceTypeIds = [];
    this.selectedServiceTypes = [];
    this.skillOptions = [];
    this.selectedSkillIds = [];
    this.selectedSkills = [];
    this.skillIdMap = {};
    this.truckTrailerComboOptions = [];
    this.serviceTypeId_truckTrailerCombosMap = {};
    this.id_truckTrailerCombosMap = {};
    this.truckTypeIdMap = {};
    this.trailerTypeIdMap = {};
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private async getCapacityGroupFormDto(capacityGroup: RouteCapacityGroup): Promise<CapacityGroupFormDto>{
    const capacityRules: RouteCapacityRule[] = (await this.httpRequest.getRouteCapacityRulesByGroupId(capacityGroup.routeCapacityGroupId).toPromise()).data;
    return {
      ...capacityGroup,
      id: capacityGroup.routeCapacityGroupId,
      capacityRules: [...capacityRules],
      editted: false
    } as CapacityGroupFormDto;
  }

  skillChanged(){
    if(this.selectedSkillIds.length < this.selectedSkills.length){
      const unselectedSkills = this.selectedSkills.filter(
        skill => !this.selectedSkillIds.includes(skill.serviceTypeSkillId)
      );
      unselectedSkills.forEach(
        unselected => this.unselectSkill(unselected)
      );
    }else{
      const currentSkillIds = this.selectedSkills.map(
        skill => skill.serviceTypeSkillId
      );
      const newSkillIds = this.selectedSkillIds.filter(
        id => !currentSkillIds.includes(id)
      );
      newSkillIds.forEach(
        newId => this.skillSelected(newId)
      );
    }
  }

  skillSelected(skillId: string){
    const skillIds = new Set<string>(
      this.selectedSkills.map(skill => skill.serviceTypeSkillId)
    );
    if(!skillIds.has(skillId)){
      this.selectedSkills.push(this.skillIdMap[skillId]);
    }
  }

  serviceTypeChanged(){
    if(this.selectedServiceTypeIds.length < this.selectedServiceTypes.length){
      const unselectedServiceTypes = this.selectedServiceTypes.filter(
        serviceType => !this.selectedServiceTypeIds.includes(serviceType.serviceTypeId)
      );
      unselectedServiceTypes.forEach(
        unselected => this.unselectServiceType(unselected)
      );
    }else{
      const currentServiceTypeIds = this.selectedServiceTypes.map(
        serviceType => serviceType.serviceTypeId
      );
      const newServiceTypeIds = this.selectedServiceTypeIds.filter(
        id => !currentServiceTypeIds.includes(id)
      );
      newServiceTypeIds.forEach(
        newId => this.serviceTypeSelected(newId)
      );
    }
    this.computeAverageLoadOutTime();
  }

  serviceTypeSelected(serviceTypeId: string){
    const serviceType = this.serviceTypeIdMap[serviceTypeId];

    this.selectedServiceTypes.push(serviceType);

    this.httpRequest.listServiceTypeSkills(serviceTypeId).subscribe(
      res => {
        const successRes = <SuccessApiResponse<ServiceTypeSkill[]>> res;
        const skills = successRes.data;
        skills.forEach(
          skill => this.skillIdMap[skill.serviceTypeSkillId] = skill
        );
        this.serviceTypeId_skillsMap[serviceTypeId] = [...skills];
        const existingSkillOptionIds = this.skillOptions.map(
          option => option.technicalName
        );
        this.skillOptions = [
          ...this.skillOptions,
          ...skills.filter(skill=>!existingSkillOptionIds.includes(skill.serviceTypeSkillId))
            .map(skill => ({
              prettyName: skill.name,
              technicalName: skill.serviceTypeSkillId
            }))
        ];
        const existingSelectedSkillIds = new Set<string>(
          this.selectedSkills.map(skill=>skill.serviceTypeSkillId)
        );
        this.selectedSkills = [
          ...this.selectedSkills,
          ...skills.filter(skill=>!existingSelectedSkillIds.has(skill.serviceTypeSkillId))
        ];
        this.selectedSkillIds = this.selectedSkills.map(
          skill => skill.serviceTypeSkillId
        );
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service type skills: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
    
    this.httpRequest.listServiceTypeTruckTrailerCombos(serviceTypeId).subscribe(
      res => {
        const successRes = <SuccessApiResponse<ServiceTypeTruckTrailerCombo[]>> res;
        const truckTrailerCombos = successRes.data;
        this.serviceTypeId_truckTrailerCombosMap[serviceTypeId] = truckTrailerCombos;
        truckTrailerCombos.forEach(
          combo => this.id_truckTrailerCombosMap[combo.serviceTypeTruckTrailerComboId] = combo
        );
        this.fetchTruckTypesAndTrailerTypes(truckTrailerCombos);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service type truck trailer combos: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
    
    if(!this.serviceWindowIdMap[serviceType.serviceWindowId]){
      this.httpRequest.getServiceWindow(serviceType.serviceWindowId).subscribe(
        res => {
          const successRes = <SuccessApiResponse<ServiceWindow>> res;
          const serviceWindow = successRes.data;
          this.serviceWindowIdMap[serviceWindow.serviceWindowId] = serviceWindow;
          this.adjustTime();
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Error fetching service window: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    }else{
      this.adjustTime();
    }

    this.computeAverageLoadOutTime();
  }

  adjustTime(){
    const serviceWindows = this.selectedServiceTypes?.map(
      serviceType => this.serviceWindowIdMap[serviceType.serviceWindowId]
    ) ?? [];

    this.pickUpStartTimeOptions = [...this.hours];
    this.pickUpEndTimeOptions = [...this.hours];
    this.routeStartTimeOptions = [...this.hours];
    this.routeEndTimeOptions = [...this.hours];

    if(!isArrayEmpty(serviceWindows)){
      const earliestServiceWindowPickupStartTime = Math.min(
        ...serviceWindows.map(serviceWindow => timeToNumber(serviceWindow.pickupStartTime))
      );
      const latestServiceWindowPickupEndTime = Math.max(
        ...serviceWindows.map(serviceWindow => timeToNumber(serviceWindow.pickupEndTime))
      );
      const latestServiceWindowDeliveryBefore = Math.max(
        ...serviceWindows.map(serviceWindow => timeToNumber(serviceWindow.deliveryBefore))
      );

      this.pickUpStartTimeOptions = this.pickUpStartTimeOptions.filter(
        option => timeToNumber(option.techName) >= earliestServiceWindowPickupStartTime
      );
      this.setRouteFormControlTime(this.pickUpStartTimeOptions, 'pickupStartTime');

      this.pickUpStartTimeOptions = this.pickUpStartTimeOptions.filter(
        option => timeToNumber(option.techName) <= latestServiceWindowPickupEndTime
      );
      this.setRouteFormControlTime(this.pickUpStartTimeOptions, 'pickupStartTime');

      this.pickUpEndTimeOptions = this.pickUpEndTimeOptions.filter(
        option => timeToNumber(option.techName) <= latestServiceWindowPickupEndTime
      );
      this.setRouteFormControlTime(this.pickUpEndTimeOptions, 'pickupEndTime');
      
      this.routeEndTimeOptions = this.routeEndTimeOptions.filter(
        option => timeToNumber(option.techName) < latestServiceWindowDeliveryBefore
      );
      this.setRouteFormControlTime(this.routeEndTimeOptions, 'endTime');
    }

    let pickupStartTime = this.routeFormControls['pickupStartTime'].value;
    if(pickupStartTime){
      this.pickUpEndTimeOptions = this.pickUpEndTimeOptions.filter(
        option => timeToNumber(option.techName) > timeToNumber(pickupStartTime)
      );
      this.setRouteFormControlTime(this.pickUpEndTimeOptions, 'pickupEndTime');
    }
    
    let pickupEndTime = this.routeFormControls['pickupEndTime'].value;
    if(pickupEndTime){
      this.routeStartTimeOptions = this.routeStartTimeOptions.filter(
        option => timeToNumber(option.techName) >= timeToNumber(pickupEndTime)
      );
      this.setRouteFormControlTime(this.routeStartTimeOptions, 'startTime');
    }

    let routeStartTime = this.routeFormControls['startTime'].value;
    if(routeStartTime){
      this.routeEndTimeOptions = this.routeEndTimeOptions.filter(
        option => timeToNumber(option.techName) > timeToNumber(routeStartTime)
          && !this.isGreaterThan12Hours(timeToNumber(routeStartTime), timeToNumber(option.techName))
      );
      this.setRouteFormControlTime(this.routeEndTimeOptions, 'endTime');
    }
  }

  private setRouteFormControlTime(options: PrettyTechName[], key: string): void{
    const value = this.routeFormControls[key].value;
    if(!value){
      return;
    }
    const timeOptions = options.map(option => option.techName);
    if(!timeOptions.includes(value)){
      this.routeFormControls[key].setValue('');
    }
  }

  private isGreaterThan12Hours(timeNumber1: number, timeNumber2: number){
    return Math.abs(timeNumber1 - timeNumber2) > 12_00_00;
  }

  private fetchTruckTypesAndTrailerTypes(truckTrailerCombos: ServiceTypeTruckTrailerCombo[]): void{
    const truckTypeIds = new Set<string>(
      truckTrailerCombos.filter(combo => combo.truckType).map(combo => combo.truckType)
    );
    const trailerTypeIds = new Set<string>(
      truckTrailerCombos.filter(combo => combo.trailerType1).map(combo => combo.trailerType1)
    );

    const truckTypeIdCalls = [...truckTypeIds].map(truckTypeId => this.httpRequest.getTruckType(truckTypeId));
    const trailerTypeIdCalls = [...trailerTypeIds].map(trailerTypeId => this.httpRequest.getTrailerType(trailerTypeId));

    forkJoin(
      {
        truckTypeResponses: truckTypeIdCalls.length === 0 ? of([]): forkJoin(truckTypeIdCalls),
        trailerTypeResponses: trailerTypeIdCalls.length === 0 ? of([]): forkJoin(trailerTypeIdCalls)
      }
    ).subscribe(
      ({truckTypeResponses, trailerTypeResponses}) => {
        truckTypeResponses.forEach(
          truckTypeResponse => {
            const successRes = <SuccessApiResponse<TruckTypeModel>> truckTypeResponse;
            const truckType = successRes.data;
            this.truckTypeIdMap[truckType.truckTypeId] = truckType;
          }
        );
        trailerTypeResponses.forEach(
          trailerTypeResponse => {
            const successRes = <SuccessApiResponse<TrailerTypeModel>> trailerTypeResponse;
            const trailerType = successRes.data;
            this.trailerTypeIdMap[trailerType.trailerTypeId] = trailerType;
          }
        );
        const existingComboOptionIds = new Set<string>(
          this.truckTrailerComboOptions.map(option => option.technicalName)
        );
        this.truckTrailerComboOptions = [
          ...this.truckTrailerComboOptions,
          ...truckTrailerCombos.filter(
            combo => !existingComboOptionIds.has(combo.serviceTypeTruckTrailerComboId)
          ).map(
            combo => ({
              prettyName: ServiceTypeTruckTrailerComboUtil.getPrettyNameWithCapacity(
                combo, this.truckTypeIdMap, this.trailerTypeIdMap
              ),
              technicalName: combo.serviceTypeTruckTrailerComboId
            })
          )
        ];
      }
    );
  }

  unselectServiceType(serviceType: ServiceType) {
    const {serviceTypeId} = serviceType;
    this.selectedServiceTypes = this.selectedServiceTypes.filter(
      current => current.serviceTypeId !== serviceTypeId
    );
    const skills = this.serviceTypeId_skillsMap[serviceTypeId] ?? [];
    const skillIds = new Set<string>(
      skills.map(skill => skill.serviceTypeSkillId)
    );
    
    this.selectedSkills = this.selectedSkills
      .filter(skill => !skillIds.has(skill.serviceTypeSkillId));
    this.selectedSkillIds = this.selectedSkills.map(
      skill => skill.serviceTypeSkillId
    );

    const skillIdsFromSelectedServiceType = new Set<string>(
      this.getSkillsFromSelectedServiceType()
        .map(skill => skill.serviceTypeSkillId)
    );

    this.skillOptions = this.skillOptions.filter(
      skill => skillIdsFromSelectedServiceType.has(skill.technicalName)
    );
    
    const truckTrailerCombos = this.serviceTypeId_truckTrailerCombosMap[serviceTypeId] ?? [];;
    const truckTrailerComboIds = new Set<string>(
      truckTrailerCombos.map(combo => combo.serviceTypeTruckTrailerComboId)
    );
    this.truckTrailerComboOptions = this.truckTrailerComboOptions
      .filter(option => !truckTrailerComboIds.has(option.technicalName));

    this.adjustTime();
  }

  private getSkillsFromSelectedServiceType(): ServiceTypeSkill[]{
    return this.selectedServiceTypes.reduce((acc: ServiceTypeSkill[], cur) => {
      acc = [
        ...acc,
        ...this.serviceTypeId_skillsMap[cur.serviceTypeId] ?? []
      ]
      return acc;
    }, []);
  }

  unselectSkill(skill: ServiceTypeSkill){
    const {serviceTypeSkillId} = skill;
    this.selectedSkills = this.selectedSkills.filter(
      skill => skill.serviceTypeSkillId !== serviceTypeSkillId
    );
  }

  private initForm(){
    this.routeForm = this.formBuilder.group({
      name: [this.originalRoute?.name, [Validators.required]],
      serviceArea: [this.originalRoute?.serviceAreaId, [Validators.required]],
      zone: [this.originalRoute?.zoneId, [Validators.required]],
      serviceableBranches: [this.originalRoute?.serviceableBranches, []],
      hasHelper: [false, []],
      onSiteTruckParking: [false, []],
      serviceType: ['',[]],
      truckTrailerCombo: ['',[Validators.required]],
      pickupStartTime: [this.originalRoute?.pickupStartTime,[Validators.required]],
      pickupEndTime: [this.originalRoute?.pickupEndTime,[Validators.required]],
      startTime: [this.originalRoute?.startTime,[Validators.required]],
      endTime: [this.originalRoute?.endTime,[Validators.required]],
    });

    if(this.originalRoute){
      this.zoneOptions = [
        {
          zoneId: this.originalRoute.zoneId,
          zoneName: this.originalRoute.zoneName,
          postalCodes: this.originalRoute.postalCodes,
        } as ServiceAreaZone
      ];
      this.serviceAreaOptions = [
        {
          serviceAreaId: this.originalRoute.serviceAreaId,
          name: this.originalRoute.serviceAreaName
        } as ServiceArea
      ]
      this.postalCodeOptions = this.originalRoute.postalCodes;

      this.serviceAreaIdSelected(this.originalRoute.serviceAreaId);
      this.zoneIdSelected(this.originalRoute.zoneId);
    }
  }

  get routeFormControls(): { [key: string]: AbstractControl } {
    return this.routeForm.controls;
  }

  getBranchesFromHierarchy() {
    this.httpRequest.getBranchesByHierarchy().subscribe((data: any) => {
      let result = {
        "data": [{
          "children": data.data
        }]
      };
      this.mainBranchesOptions = this.branchService.manipulateDataFromTestJson(result.data[0]);
    })
  }

  serviceAreaSelected(event: any) {
    this.serviceAreaIdSelected(event.value);
  }

  serviceAreaIdSelected(serviceAreaId: string){
    this.selectedServiceArea = this.serviceAreaOptions.find(serviceArea => serviceArea.serviceAreaId === serviceAreaId);
    this.zoneOptions = this.selectedServiceArea?.zones || [];
  }

  zoneSelected(event: any){
    this.zoneIdSelected(event.value);
  }

  zoneIdSelected(zoneId: string){
    this.httpRequest.getZonesViaId(zoneId).subscribe(
      res => {
        this.selectedZone = (res.data as Zone);
        this.modeOptions = (res.data as Zone)?.modes || [];
        this.postalCodeOptions = this.selectedZone?.postalCodes || [];
        if(this.postalCodeOptions.length != 0) {
          this.selectedPostalCode();
        }
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch zones: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  async openCapacity(content: any, capacityGroup?: CapacityGroupFormDto) {
    if(capacityGroup){
      await this.initCapacityDefault(capacityGroup);
    }else {
      this.capDataDefault = undefined;
    }
  
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: false, windowClass: 'app-save-route-modal' });
  }

  private async initCapacityDefault(capacityGroup:  CapacityGroupFormDto){
    this.capDataDefault = capacityGroup;
  }

  deleteRoute(){
    this.saveLoading = true;
    this.httpRequest.deleteRoute(this.originalRoute?.routeId!)
      .subscribe(
        res => {
          this.saveLoading = false;
          Swal.fire({
            title: 'Success',
            text: 'Successfully deleted route.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.router.navigate(["/pages/routes"]);
          });
        },
        error => {
          this.saveLoading = false;
          Swal.fire({
            title: 'Error',
            text: 'Failed to delete route: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  deleteCap(data: any, index: any) {
    this.capacityGroups.splice(index, 1);
  }

  closeModal(event: any) {
    this.activeModal?.close();
  }

  formValue(capacityData: CapacityGroupFormDto) {
    this.capacityGroups.push(capacityData);
  }

  getDayPrettyName(numDay: any) {
    return this.serv.getDayPrettyName(numDay);
  }

  selectedPostalCode(){
    this.httpRequest.getBranchesViaPostalCodes(this.selectedMode!.modeId!, this.postalCodeOptions)
      .subscribe(
        res => this.serviceableBranchOptions = res.data,
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch branches: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  saveRoute(){
    this.submitted = true;

    this.adjustRouteFormValues();
    
    if(
      this.routeForm.invalid 
        || isArrayEmpty(this.selectedServiceTypes) 
        || isArrayEmpty(this.selectedSkills)
    ) {
      return;
    }

    if(this.originalRoute){
      this.updateRoute(this.originalRoute);
    }else{
      this.createRoute();
    }
  }

  private updateRoute(originalRoute: Route){
    const {zone, truckTrailerCombo} = this.routeForm.value;
    const {truckType: truckTypeId, trailerType1: trailerTypeId1} = this.id_truckTrailerCombosMap[truckTrailerCombo];
    const {routeId, modeId, branchId} = originalRoute;

    this.saveLoading = true;
    this.httpRequest.updateRoutes(
      routeId,
      {
        ...this.routeForm.value,
        zoneId: zone,
        modeId,
        branchId,
        postalCodes: this.postalCodeOptions,
        truckTypeId,
        trailerTypeId1,
        averageLoadOutTime: Math.ceil(this.averageLoadOutTime)
      }
    ).pipe(
      finalize(()=>this.saveLoading=false)
    ).subscribe(
      res => {
        const originalGroupIds = new Set(
          this.originalCapacityGroups?.map(ogGroup => ogGroup.routeCapacityGroupId)
        )
        const stillExistingGroupIds = new Set(
          this.capacityGroups.filter(capacityGroup => originalGroupIds.has(capacityGroup.id))
            .map(cg => cg.id)
        );
        const edittedGroupIds = new Set(
          this.capacityGroups.filter(capacityGroup => capacityGroup.editted)
            .map(cg => cg.id)
        );

        const deleteCapacityGroupCalls = this.originalCapacityGroups
        ?.filter(ogGroup => !stillExistingGroupIds.has(ogGroup.routeCapacityGroupId) || edittedGroupIds.has(ogGroup.routeCapacityGroupId))
        .map(
          capacityGroup =>  this.httpRequest.deleteRouteCapacityGroupByGroupId(
            capacityGroup.routeCapacityGroupId!
          )
        ) || [];

        const addCapacityGroupCalls = this.capacityGroups
        .filter(cg => !originalGroupIds.has(cg.id) || edittedGroupIds.has(cg.id))
        .map(
          capacityGroup => {
            this.saveLoading = true;
            return this.httpRequest
              .addRouteCapacityGroupByRouteId(
                routeId, capacityGroup
              ).pipe(
                flatMap(
                  res => {
                    let capacityGroupFormDto = capacityGroup as CapacityGroupFormDto;
    
                    const addCapacityRuleCalls = capacityGroupFormDto.capacityRules?.map(
                      capacityRule => this.httpRequest.addRouteCapacityRuleByGroupId(
                        res.data.routeCapacityGroupId!,
                        capacityRule
                      )
                    );
    
                    return of(addCapacityRuleCalls).pipe(
                      mergeMap(q => forkJoin(q))
                    );
                  }
                ),
                finalize(()=>this.saveLoading = false)
              )
          }
        );

        const groupCalls = [...deleteCapacityGroupCalls, ...addCapacityGroupCalls]

        if(groupCalls && groupCalls.length == 0){
          this.handleSuccessSave();
          return;
        }
        
        of(groupCalls).pipe(
          mergeMap(q => forkJoin(q))
        ).subscribe(
          res => {
            this.handleSuccessSave();
          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Error occured: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save route: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    )
  }

  private handleSuccessSave(){
    Swal.fire({
      title: 'Success',
      text: 'Successfully saved route.',
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Ok',
    }).then((result) => {
      this.router.navigateByUrl('/pages/routes');
    });
  }

  private createRoute(){
    const {zone, truckTrailerCombo} = this.routeForm.value;
    const {truckType: truckTypeId, trailerType1: trailerTypeId1} = this.id_truckTrailerCombosMap[truckTrailerCombo];
    const {branchId} = this.selectedBranch!;

    this.saveLoading = true;
    this.httpRequest.addRoutes(
      {
        ...this.routeForm.value,
        zoneId: zone,
        branchId,
        postalCodes: this.postalCodeOptions,
        truckTypeId,
        trailerTypeId1,
        averageLoadOutTime: Math.ceil(this.averageLoadOutTime)
      }
    ).pipe(
      finalize(()=>this.saveLoading = false)
    ).subscribe(
      (res:ApiResponse<Route>) => {
        const successRes = (res as SuccessApiResponse<Route>);

        const routeId = successRes.data.routeId;

        this.saveLoading = true;
        const addCapacityGroupCalls = this.capacityGroups
        .map(
          capacityGroup => {
            this.saveLoading = true;
            return this.httpRequest.addRouteCapacityGroupByRouteId(
              routeId, capacityGroup
            ).pipe(
              flatMap(
                res => {
                  const addCapacityRuleCalls = capacityGroup.capacityRules?.map(
                    capacityRule => this.httpRequest.addRouteCapacityRuleByGroupId(
                      res.data.routeCapacityGroupId!,
                      capacityRule
                    )
                  );
  
                  return of(addCapacityRuleCalls).pipe(
                    mergeMap(q => forkJoin(q))
                  );
                }
              ),
              finalize(()=>this.saveLoading = false)
            );
          }
        );

        this.saveLoading = true;

        if(isArrayEmpty(addCapacityGroupCalls)){
          Swal.fire({
            title: 'Success',
            text: 'Successfully saved route.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.router.navigateByUrl('/pages/routes');
          });
          return;
        }

        of(addCapacityGroupCalls).pipe(
          mergeMap(q => forkJoin(q)),
          finalize(()=>this.saveLoading=false)
        ).subscribe(
          res => {
            Swal.fire({
              title: 'Success',
              text: 'Successfully saved route.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              this.router.navigateByUrl('/pages/routes');
            });
          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Error occured: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save route: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  adjustRouteFormValues(){
    const {zone, serviceableBranches} = this.routeForm.value;
    
    const serviceableBranchIdOptions = this.serviceableBranchOptions.map(branch => branch.branchId);

    this.routeForm.patchValue(
      {
        zone: this.zoneOptions.find(option => option.zoneId == zone)?.zoneId,
        postalCodes: this.postalCodeOptions,
        serviceableBranches: serviceableBranches?.filter((serviceableBranch: string) => serviceableBranchIdOptions.includes(serviceableBranch))
      }
    );

  }

  openCapacityEditModal(): void {
    this.capacityModalClicked = true;
    const {truckTrailerCombo} = this.routeForm.value;
    if(this.selectedServiceTypes.length === 0 || !truckTrailerCombo){
      return;
    }
    
    this.activeModal?.close();
    
    const modalRef = this.modalService.open(
      NgbdModalSaveCapacity, 
      {
        size: 'lg',
        centered: true,
        modalDialogClass: 'full-page-modal-container'
      }
    );
    
    modalRef.componentInstance.serviceTypes = [...this.selectedServiceTypes];
    modalRef.componentInstance.zipCodes = [...this.postalCodeOptions];
    modalRef.componentInstance.truckTrailerCombo = this.id_truckTrailerCombosMap[truckTrailerCombo];
  }
  
  computeAverageLoadOutTime(): void{
    if(isArrayEmpty(this.selectedServiceTypes)){
      this.averageLoadOutTime = 0;
      return;
    }
    const total = this.selectedServiceTypes.reduce(
      (acc, cur) => {
        return acc + (cur?.averageLoadOutTime ?? 0)
      }, 0 
    );
    this.averageLoadOutTime = total / this.selectedServiceTypes.length;
  }
}
