<div class="row">
    <div class="col-lg-12">
        <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
            <div class="px-4">
                <div class="row">
                    <div class="col-xxl-5 align-self-center">
                        <div class="py-4">
                            <h4 class="display-6 coming-soon-text">Frequently asked {{ accountType }} questions</h4>
                            <p class="text-success fs-15 mt-3">If you can not find answer to your question in our FAQ, you can always contact us or email us. We will answer you shortly!</p>
                            <div class="hstack flex-wrap gap-2">
                                <button type="button" class="btn btn-primary btn-label rounded-pill">
                                    <i class="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us </button>
                                <button type="button" class="btn btn-info btn-label rounded-pill">
                                    <i class="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 ms-auto">
                        <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                            <img src="assets/images/faq-img.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-evenly">
            <div class="col-lg-4">
                <div class="mt-3">
                    <div class="d-flex align-items-center mb-2">
                        <div class="flex-shrink-0 me-1">
                            <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">General {{ accountType }} Questions</h5>
                        </div>
                    </div>
                    <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                        <ngb-panel id="static-1">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> What is Lorem Ipsum ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple their most common words. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Why do we use it ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where does it come from ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> he wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-4">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where can I get some ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis aliquam ultrices mauris. </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="mt-3">
                    <div class="d-flex align-items-center mb-2">
                        <div class="flex-shrink-0 me-1">
                            <i class="ri-user-settings-line fs-24 align-middle text-success me-1"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">Manage {{ accountType }} Account</h5>
                        </div>
                    </div>
                    <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                        <ngb-panel id="static-1">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where can I get some ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple their most common words. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where does it come from ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Why do we use it ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> he wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-4">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> What is Lorem Ipsum ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis aliquam ultrices mauris. </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="mt-3">
                    <div class="d-flex align-items-center mb-2">
                        <div class="flex-shrink-0 me-1">
                            <i class="ri-shield-keyhole-line fs-24 align-middle text-success me-1"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">{{ accountType }} Privacy &amp; Security</h5>
                        </div>
                    </div>
                    <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                        <ngb-panel id="static-1">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Why do we use it ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple their most common words. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where can I get some ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> What is Lorem Ipsum ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> he wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete. </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-4">
                            <ng-template ngbPanelTitle class="accordion-header">
                                <h2 class="accordion-header" id="gen-ques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne"> Where does it come from ? </button>
                                </h2>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="accordion-body"> Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis aliquam ultrices mauris. </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
</div>