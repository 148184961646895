<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>

        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <!--<img src="assets/images/logo-light.png" alt="" height="20">-->
                            </a>
                        </div>
                        <p class="mt-3 fs-1 fw-semibold">Direct To Carrier</p>
                    </div>
                </div>
            </div>
            <!-- end row -->
  
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-7">
                    <div class="card mt-4">
  
                        <div class="card-body p-4 form-steps">
                            <div class="text-center mt-2">
                                <!-- <h5 class="text-primary">blah</h5> -->
                                <!-- Ratio Video 16:9 -->
                                <div class="ratio ratio-16x9">
                                    <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="mt-4">
                                    <div class="d-flex flex-wrap gap-4 btn-container">
                                        <button (click)="openStripeCheckoutOverlay()" class="btn btn-success">Pay Now</button>
                                        <a routerLink="/payment-trial" class="btn btn-success">7 Day Free Trial</a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
  
                    <!-- <div class="mt-4 text-center">
                        <p class="mb-0">Already have an account ? <a routerLink="/auth/signin/basic" class="fw-semibold text-primary text-decoration-underline"> Signin </a> </p>
                    </div> -->
  
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->
  
    <!-- footer -->
    <app-signup-footer></app-signup-footer>
    <!-- end Footer -->
</div>
<!-- end auth-page-wrapper -->

<app-stripe-checkout-overlay #stripeCheckoutOverlay></app-stripe-checkout-overlay>