import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { AccountType } from 'src/app/core/services/models/account.model';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],

})
export class FAQComponent implements OnInit {
  accountType: string;
  constructor(
    private auth: AuthService,
  ) {
    
  }

  ngOnInit(): void {
    console.log(this.auth.currentUserValue);
    this.accountType = this.getAccountTypePrettyName(this.auth.currentAccountSelected.accountType);
  }

  getAccountTypePrettyName(accountType: AccountType):string  {
    switch(accountType) {
        case 'shipper-account':
            return 'Shipper'
        case 'carrier-account':
            return 'Shipper'
        case 'driver-account':
            return 'Driver'
        case 'helper-account':
            return 'Helper'
        default:
            return '';
    }
  }

}
