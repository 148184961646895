<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #tradingRelationshipsMenu="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink>
                        Documents
                    </a>
                    <ng-template ngbNavContent>
                        <!-- <app-contract-templates [showAddContractTemplate]="showAddContractTemplate" [showViewContractTemplate]="showViewContractTemplate" [contractDetailsData]="contractDetailsData" (breadcrumbItems)="breadcrumbItems($event)"></app-contract-templates> -->
                        <app-contract-templates></app-contract-templates>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" class="nav-item" (click)="changeTab(5)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        Document Groups
                    </a>
                    <ng-template ngbNavContent>
                        <app-document-groups></app-document-groups>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item" (click)="changeTab(2)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Contract Signers</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-contract-signers></app-contract-signers>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item" (click)="changeTab(3)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Pending</span>
                    </a>
                    <ng-template ngbNavContent>

                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="nav-item" (click)="changeTab(4)"
                    [disabled]="isTrial()">
                    <a ngbNavLink>
                        <span class="d-sm-block">Accounts Payable</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-accounts-payable></app-accounts-payable>
                    </ng-template>
              </li>
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content text-muted">
              <div [ngbNavOutlet]="tradingRelationshipsMenu"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->
