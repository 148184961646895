import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";
import { ServiceArea, ServiceAreaZoneTreeNode } from "../models/service-areas.model";

@Injectable({ providedIn: 'root' })
export class ServiceAreasService {
  static convertToTreeNode(serviceAreas: ServiceArea[]): TreeNode<ServiceAreaZoneTreeNode>[]{
    return serviceAreas.map(
        serviceArea => ({
            data: {
              id: serviceArea.serviceAreaId!,
              name: serviceArea.name,
              type: 'ServiceArea',
              serviceArea
            },
            expanded: false,
            children: serviceArea?.zones?.map(zone => ({
                data: {
                  id: zone.zoneId,
                  name: zone.name,
                  type: 'Zone',
                  serviceArea,
                  zone
                },
                expanded: false
            }))
        })
    );
  }
}