import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { NgbdModalBuyLicenses } from './modals/buy-licenses/buy-licenses.component';
import { NgbdModalContactSales } from './modals/contact-sales/contact-sales.component';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],

})
export class LicensesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  activeModal?: NgbModalRef;

  licenses: any = [];
  driverOnlyAvailable = 0;
  trucks = 0;
  driverWithHelperAvailable = 0;
  driverOnlyActiveLoadOnBoard = 0;
  driverWithHelperActiveLoadOnBoard = 0;
  availableForRefund = 0;
  driverOnlyActive = 0;
  driverWithHelperActive = 0;
  loadSpinner = false;
  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpRequest: HttpService,
  ) {
   
  }

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.getAvailableLicenses();
    this.getActiveOnLoadBoardLicenses();
    this.getActiveLicenses();
  }

  getAvailableLicenses() {
    this.driverWithHelperAvailable = 0;
    this.trucks = 0;
    this.httpRequest.getAvailableLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperAvailable += 1;
          } else {
            this.driverOnlyAvailable += 1;
            this.trucks += 1;
          }
        }
      }
    })
  }

  getActiveOnLoadBoardLicenses() {
    this.driverWithHelperActiveLoadOnBoard = 0;
    this.driverOnlyActiveLoadOnBoard = 0;
    this.httpRequest.getUsedLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperActiveLoadOnBoard += 1;
          } else {
            this.driverOnlyActiveLoadOnBoard += 1;
          }
        }
      }
    })
  }

  getActiveLicenses() {
    this.driverWithHelperActive = 0;
    this.driverOnlyActive = 0;
    this.httpRequest.getActiveLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperActive += 1;
          } else {
            this.driverOnlyActive += 1;
          }
        }
      }
    })
  }

  openModalBuyLicense() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
    modalRef.result.then((result: any) => { 
      if(result) {
        this.getAvailableLicenses();
        this.getActiveOnLoadBoardLicenses();
        this.getActiveLicenses();
      }
     })
  }

  openModalContactSales() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalContactSales, { size: 'xl', centered: true });
  }

  refresh() {
    this.driverOnlyAvailable = 0;
    this.trucks = 0;
    this.driverWithHelperAvailable = 0;
    this.driverOnlyActiveLoadOnBoard = 0;
    this.driverWithHelperActiveLoadOnBoard = 0;
    this.driverOnlyActive = 0;
    this.driverWithHelperActive = 0;
    this.loadSpinner = true;

    this.httpRequest.getAvailableLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperAvailable += 1;
          } else {
            this.trucks += 1;
          }
        }
      }
      this.httpRequest.getUsedLicenses().subscribe((data) => {
        if(data.data.length != 0) {
          for(let x = 0 ; x < data.data.length ; x++) {
            if(data.data[x].hasHelper) {
              this.driverWithHelperActiveLoadOnBoard += 1;
            } else {
              this.driverOnlyActiveLoadOnBoard += 1;
            }
          }
        }
        this.httpRequest.getActiveLicenses().subscribe((data) => {
          this.loadSpinner = false;
          if(data.data.length != 0) {
            for(let x = 0 ; x < data.data.length ; x++) {
              if(data.data[x].hasHelper) {
                this.driverWithHelperActive += 1;
              } else {
                this.driverOnlyAvailable += 1;
                this.driverOnlyActive += 1;
              }
            }
          }
        })
      })
    })
  }

}
