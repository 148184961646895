import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { Branch, BranchTypeAsset } from 'src/app/core/services/models/branch.model';
import { truthyFilter } from 'src/app/core/utils/commons';

export type TreeNodeBranch = { branchId: string, name: string };

export interface NodeData {
  label: string;
}
export interface CompanyNodeData extends NodeData {
  accountId: string;
}
export interface OrganizationNodeData extends NodeData {
  organizationId: string;
}
export interface BranchNodeData extends NodeData {
  branchId: string;
}
export type TreeNodeData = Branch; // CompanyNodeData | OrganizationNodeData | BranchNodeData;
export type BranchTreeNode = TreeNode<TreeNodeData>;

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  manipulateData(dataFromAPI: any) {
    let result = [];
    for(let x = 0; x < dataFromAPI.length; x++){
        let data = {
            name: dataFromAPI[x].name,
            type: dataFromAPI[x].type,
            branchId: dataFromAPI[x].branchId,
            city: dataFromAPI[x].city,
            accountId: dataFromAPI[x].accountId,
            contactEmail: dataFromAPI[x].contactEmail,
            contactName: dataFromAPI[x].contactName,
            contactPhone: dataFromAPI[x].contactPhone,
            number: dataFromAPI[x].number,
            parentId: dataFromAPI[x].parentId,
            state: dataFromAPI[x].state,
            street: dataFromAPI[x].street,
            zipCode: dataFromAPI[x].zipCode
        }
        if(dataFromAPI[x].type == 'store' || dataFromAPI[x].type == 'remote-store' || dataFromAPI[x].type == '3pl-cross-dock') {
          result.push({data: data, leaf: true})
        } else {
          result.push({data: data, leaf: false})
        }
    }
    return result;
  }

  convertDataToTreeNode(data: Branch): BranchTreeNode {
    const node: BranchTreeNode = {
      data,
      label: data.name,
      key: data.branchId,
      leaf: false,
      expanded: true
    };

    if (!data.children || data.children.length === 0) {
      node.leaf = true;
      return node;
    }

    node.children = data.children.map((d: any) => this.convertDataToTreeNode(d));
    return node;
  }

  getNestedCoordinates(branch: Branch): [number, number][] {
    return [
      this.getCoordinates(branch),
      ...(branch.children || []).map(c => this.getNestedCoordinates(c)).flat()
    ].filter(truthyFilter);
  }

  getNestedBranches(branch: Branch): Branch[] {
    return [
      branch,
      ...(branch.children || []).map(c => this.getNestedBranches(c)).flat()
    ]
  }

  getNestedBranchNodes(branch: BranchTreeNode): BranchTreeNode[] {
    return [
      branch,
      ...(branch.children || []).map(c => this.getNestedBranchNodes(c)).flat()
    ]
  }

  getCoordinates(branch: Branch): [number, number] | undefined {
    const lat = branch.address?.latitude;
    const long = branch.address?.longitude;
    if (lat !== undefined && long !== undefined) {
      return [long, lat];
    }
    return undefined;
  }

  manipulateDataFromTestJson(dataFromAPI: any): BranchTreeNode[] {
    let result: any[] = [];
    if(!dataFromAPI.data) {
      result = dataFromAPI.children.map((d: any) => this.convertDataToTreeNode(d));
    } else {
      result = dataFromAPI.data.children.map((d: any) => this.convertDataToTreeNode(d));
    }
    return result;
  }

  navigateCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  filterFnCarrierBranchType(branchType: BranchTypeAsset): boolean {
    switch(branchType.technicalName) {
      case 'subsidiary':
        return false;
      default:
        return true;
    }
  }

  filterFnBranchType(branchType: BranchTypeAsset): boolean {
    switch (this.authService.currentAccountSelected.accountType) {
      case 'carrier-account':
        return this.filterFnCarrierBranchType(branchType);
      default:
        return true;
    }
  }

  checkIfPrefix(type: string) {
    switch (type) {
      case "hq":
        return false;
        break;
      case "store":
        return true;
        break;
      case "remote-store":
        return true;
        break;
      case "dc":
        return true;
        break;
      case "hub":
        return true;
        break;
      case "region":
        return false;
        break;
      case "branch":
        return true;
        break;
      case "3pl-cross-dock":
        return true;
        break;
      case "cross-dock":
        return true;
        break;
      case "organization":
        return false;
        break;
      case "subsidiary":
        return false;
        break;
      default:
        return false;
        break;
    }
  }

  returnPrettyTypeName(name: string) {
    switch (name) {
      case "hq":
        return 'HQ';
        break;
      case "store":
        return 'Store';
        break;
      case "remote-store":
        return 'Remote Store';
        break;
      case "dc":
        return 'DC';
        break;
      case "hub":
        return 'Hub';
        break;
      case "region":
        return 'Region';
        break;
      case "branch":
        return 'Branch';
        break;
      case "3pl-cross-dock":
        return '3PL Cross Dock';
        break;
      case "cross-dock":
        return 'Cross Dock';
        break;
      case "organization":
        return 'Organization';
        break;
      case "subsidiary":
        return 'Subsidiary';
        break;
      default:
        return 'Store';
        break;
    }
  }

  getNode(key: string, refNode: BranchTreeNode[]): BranchTreeNode | undefined {
    for (const node of refNode) {
      if (node.key === key) {
        return node;
      }

      const theNode = this.getNode(key, node.children || []);
      if (theNode) {
        return theNode;
      }
    }

    return undefined;
  }

  checkIfValueInArray(arr: any, data: any) {
    let ret = false;
    for(let x = 0; x < arr.length; x++) {
      if(arr[x].data) {
        if(arr[x].data.label === data.label) { //need to change this to more specific
          ret = true;
        }
      }

    }
    return ret;
  }

  isBranchNodeData(data: TreeNodeData): data is Branch {
    return (data as Branch).branchId !== undefined;
  }

  isTypeShipperCarrier(branch: any) {
    if(branch.type === 'shipper-account' || branch.type === 'carrier-account' || branch.type === 'broker-account') {
      return true;
    } else {
      return false;
    }
  }

  static getRecursiveTreeNodeBranch(branches: Branch[]): TreeNode<TreeNodeBranch>[] {
    const recursiveTraverse = (branches: Branch[], accumulator: TreeNode<TreeNodeBranch>[]): TreeNode<TreeNodeBranch>[] => {
      if (!branches || branches.length == 0) {
        return accumulator;
      }

      branches.forEach(
        branch => accumulator.push(BranchesService.convertBranchToTreeNodeBranch(branch, recursiveTraverse))
      );

      return accumulator;
    };

    return branches.map(
      branch => BranchesService.convertBranchToTreeNodeBranch(branch, recursiveTraverse)
    );
  }

  private static convertBranchToTreeNodeBranch(
    branch: Branch, 
    recursiveTraverse: (arg1: Branch[], arg2: TreeNode<TreeNodeBranch>[])=>TreeNode<TreeNodeBranch>[]
  ): TreeNode<TreeNodeBranch> {
    const { branchId, name, type, children } = branch;
    return {
      data: { branchId: branchId!, name: name! },
      expanded: true,
      label: name,
      children: recursiveTraverse(children!, []),
    };
  }

  static getSelectedTreeNodeBranch(selectedBranchIds: string[], branches: TreeNode<TreeNodeBranch>[]): TreeNode<TreeNodeBranch>[]{
    const selectedTreeNodeBranches: TreeNode<TreeNodeBranch>[] = [];

    for(const branch of branches){
      if(selectedBranchIds.includes(branch.data!.branchId)){
        selectedTreeNodeBranches.push(branch);
      }

      BranchesService.getSelectedTreeNodeBranch(selectedBranchIds, branch.children!).forEach(
        treeNodeBranch => selectedTreeNodeBranches.push(treeNodeBranch)
      );
    }

    return selectedTreeNodeBranches
  }

}
