import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-final-mile-certified',
  templateUrl: './final-mile-certified.component.html',
  styleUrls: ['./final-mile-certified.component.scss']
})
export class FinalMileCertifiedComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Final Mile Certified'
    }
  ];
  activeModal?: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
  }

}
