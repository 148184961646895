<div class="row">
    <div class="col-lg-5">
        <form [formGroup]="addContractTemplateForm">
            <div class="modal-body">
                <div class="col-lg-12">
                    <div class="row mb-3">
                        <div class="col-lg-2 user-form-label text-align-right">
                            <label for="name-field" class="form-label">Name: <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-lg-10">
                            <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                            <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="form['name'].errors['required']">Enter Name</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 user-form-label text-align-right">
                            <label for="name-field" class="form-label">Type: <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-lg-10">
                            <ng-select name="data" class="custom form-control" [items]="DOCUMENT_TYPE_OPTIONS" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true"
                                [hideSelected]="false" [clearSearchOnAdd]="true" bindLabel="name" bindValue="name" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }">
                            </ng-select>
                            <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="form['type'].errors['required']">Enter Type</div>
                            </div>
                        </div>
                    </div>
                    <div class="container dnd-cont" appDnd (fileDropped)="onFileDropped($event)">
                        <input type="file" #fileDropRef id="fileDropRef" [multiple]="false" (change)="fileBrowseHandler($event)" accept=".doc, .docx, .pdf"/>
                        <i class="las la-file-alt" id="drag-drop-icon"></i>
                        <h3 id="dnd-text">Drag and drop file here</h3>
                        <h3>or</h3>
                        <label for="fileDropRef" id="browse-file">Browse for file</label>
                    </div>
                    <div class="files-list">
                        <div class="single-file" *ngFor="let file of files; let i = index">
                            <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
                            <div class="info">
                            <h4 class="name">
                                {{ file?.name }}
                            </h4>
                            <p class="size">
                                {{ formatBytes(file?.size) }}
                            </p>
                            <app-progress [progress]="file?.progress"></app-progress>
                            </div>
                            <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-light" data-bs-toggle="modal" (click)="cancel()">
                    Cancel
                </button>
                <button type="submit" class="btn btn-primary" data-bs-toggle="modal" (click)="submitApproval()" [disabled]="isLoading">
                    <span class="flex-grow-1 me-2">
                        Upload Document
                      </span>
                      <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                          <span class="visually-hidden">Loading...</span>
                      </span>
                </button>
            </div>
        </form>
    </div>
    <div class="col-lg-7">
        <editor
            *ngIf="html !== undefined"
            [(ngModel)]="html"
            apiKey="txbcanytoasuhpzdz7k4enu2mtowefxvuldixj4byfd4zh38"
            [init]="{
                selector: 'textarea',
                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            
                mergetags_list: TINYMCE_MERGETAGS_LIST,
                content_css: 'document',
                content_style: 'body { min-height: 1056.25px; margin-bottom: 1rem;}',
                height: '100vh',
                contextmenu: 'mergetags'
            }"
        ></editor>
    </div>
</div>

   