import { Component, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { CapacityGroup } from 'src/app/core/services/models/capacity-group.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-capacity',
  templateUrl: './capacity.component.html',
  styleUrls: ['./capacity.component.scss']
})
export class CapacityComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Capacities'
    }
  ];
  showData = false;
  capacityGroups: CapacityGroup[];

  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  constructor(
    private httpService: HttpService,
    private auth: AuthService,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.fetchCapacityGroupList();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }


  ngOnInit(): void {
    
  }

  private fetchCapacityGroupList(){
    this.showData = false;
    this.httpService.fetchCapacityGroupList(
      this.selectedMode?.modeId!,
      this.selectedBranch?.branchId!
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CapacityGroup[]>> res;
        this.capacityGroups = successRes.data;
        this.showData = true;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch capacity groups: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

}
