<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-xl-6">
        <div class="d-flex flex-column h-100">
            <div class="row">
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> On Time</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="36894" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="total_jobs" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="basicRadialbarChart.series" [chart]="basicRadialbarChart.chart" [dataLabels]="basicRadialbarChart.dataLabels" [plotOptions]="basicRadialbarChart.plotOptions" [colors]="basicRadialbarChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Exceptions</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="28410" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="apply_jobs" data-colors='["--vz-success"]' class="apex-charts" dir="ltr">
                                        <apx-chart [series]="applyjobChart.series" [chart]="applyjobChart.chart" [plotOptions]="applyjobChart.plotOptions" [labels]="applyjobChart.labels" [colors]="applyjobChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">Load Scan</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="4305" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="new_jobs_chart" data-colors='["--vz-success"]' class="apex-charts" dir="ltr">
                                        <apx-chart [series]="newjobChart.series" [chart]="newjobChart.chart" [plotOptions]="newjobChart.plotOptions" [labels]="newjobChart.labels" [colors]="newjobChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Delivery Scan</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="5021" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="interview_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="interviewChart.series" [chart]="interviewChart.chart" [plotOptions]="interviewChart.plotOptions" [labels]="interviewChart.labels" [colors]="interviewChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Surveys</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="3948" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="hired_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="hiredChart.series" [chart]="hiredChart.chart" [plotOptions]="hiredChart.plotOptions" [labels]="hiredChart.labels" [colors]="hiredChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">Capacity Utilizations</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="1340" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="rejected_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="rejectedChart.series" [chart]="rejectedChart.chart" [plotOptions]="rejectedChart.plotOptions" [labels]="rejectedChart.labels" [colors]="rejectedChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Top Performing Carriers</h4>
                <div class="flex-shrink-0">
                    <a herf="javascript:void(0);" class="btn btn-soft-primary btn-sm">View All Carriers <i class="ri-arrow-right-line align-bottom"></i>
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card">
                    <table class="table table-centered table-hover align-middle table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-secondary rounded">
                                                <img src="assets/images/companies/img-1.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Force Medicines</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Cullera, Spain
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-warning rounded">
                                                <img src="assets/images/companies/img-3.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Syntyce Solutions</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-primary rounded">
                                                <img src="assets/images/companies/img-2.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Moetic Fashion</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="assets/images/companies/img-4.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Meta4Systems</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Germany
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="assets/images/companies/img-5.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Themesbrand</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Limestone, US
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
                    <div class="col flex-shrink-0">
                        <div class="text-muted"> Showing <span class="fw-semibold">5</span> of <span class="fw-semibold">25</span> Results </div>
                    </div>
                    <ul class="pagination pagination-separated pagination-sm mb-0">
                        <li class="page-item disabled">
                            <a herf="javascript:void(0);" class="page-link">←</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">1</a>
                        </li>
                        <li class="page-item active">
                            <a herf="javascript:void(0);" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">→</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>