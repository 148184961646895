import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';

import { NgbdModalAddServiceArea } from '../add-service-area/add-service-area.component'


@Component({
  selector: 'ngbd-modal-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['./add-zone.component.scss'],

})
export class NgbdModalAddZone implements OnInit {
  @Input() zones: any = [];

  addZoneForm!: FormGroup;
  submittedZone = false;
  branches: any;
  zipcodesJson: any;
  constructor(
  	public activeModal: NgbActiveModal,
  	private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
  	) {}

	ngOnInit(): void {
    this.initForms();
    this.getBranches();

    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });
  }

	initForms() {
    this.addZoneForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        branch: ['', [Validators.required]],
        zipcode: ['', [Validators.required]],
    });
  }

  get formZone() {
    return this.addZoneForm.controls;
  }

  getBranches() {
    this.httpRequest.getBranches().subscribe((data:any) => {
      this.branches = data.data;
    }, error => {

    })
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  addZones() {
    this.submittedZone = true;
    if(this.addZoneForm.invalid) {
      return
    } else {
      this.zones.push(this.addZoneForm.value);
    	this.activeModal?.close(this.addZoneForm);
      let modalRef = this.modalService.open(NgbdModalAddServiceArea, { size: 'lg', centered: true });
      modalRef.componentInstance.defaultZones = this.zones;
    }

  }

  close() {
  	this.activeModal?.close();
  }
}