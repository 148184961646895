import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { AccountType } from 'src/app/core/services/models/account.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';
import { NgbdModalBuyLicenses } from '../../licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-company-menu',
  templateUrl: './company-menu.component.html',
  styleUrls: ['./company-menu.component.scss']
})
export class CompanyMenuComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Settings'
    }
  ];
  activeModal?: NgbModalRef;
  accountType: AccountType;

  // activeTab: any = 1;
  @Input() activeTab: any = 1;
  @Output() changeChildTab: EventEmitter<any> = new EventEmitter();

  currentAccountPlan: AccountPlan;
  subscriptions: Subscription[] = [];

  constructor(
    public auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  async ngOnInit() {
    if (this.router.url == ('/pages/settings/company/profile')) {
      this.activeTab = 1;
    } else if (this.router.url == ('/pages/settings/company/public-profile')) {
      this.activeTab = 2;
    } else if (this.router.url == ('/pages/settings/company/modes')) {
      this.activeTab = 3;
    } else if (this.router.url == ('/pages/settings/company/service-types')) {
      this.activeTab = 4;
    } else if (this.router.url == ('/pages/settings/company/service-types-add-service-window')) {
      this.activeTab = 4;
    } else {
      this.activeTab = 1;
    }
    this.accountType = this.auth.currentAccountSelected.accountType;
    this.subscriptions.push(
      this.auth.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
        }
      )
    );
  }

  changeTab(event: any) {
    if(event == 1) {
      this.router.navigateByUrl("pages/settings/company/profile");
    } else if(event == 2) {
      this.router.navigateByUrl("pages/settings/company/public-profile");
    } else if(event == 3) {
      this.router.navigateByUrl("pages/settings/company/modes");
    } else if(event == 4) {
      this.router.navigateByUrl("pages/settings/company/service-types");
    }
    this.changeChildTab.emit(event);
  }

  promptUpgrade(): void {
    if(this.auth.currentAccountPlanValue.status === 'paid'){
      return;
    }
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }
}
