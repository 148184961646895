<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>


<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                      <div>
                        <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" (click)="openModalServiceArea()">
                            <i class="ri-add-line align-bottom me-1"></i> Add
                        </button>
                      </div>
                    </div>
                    <div class="col-sm">
                        <div class="d-flex justify-content-sm-end">
                          <div class="search-box ms-2">
                            <input type="text" class="form-control search" placeholder="Search...">
                            <i class="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </div>
                </div>
                    <div class="table-responsive table-card mt-3 mb-1">
                        <table class="table align-middle table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Branch</th>
                                    <th scope="col">Zipcodes</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>William Elmore</td>
                                    <td>07 Oct, 2021</td>
                                    <td>
                                        ({{zipcodes.length}}) {{zipcodes | slice:0:2}}
                                        <span *ngIf="zipcodes.length > 2" [title]="zipcodes"> 
                                                ...
                                            <i class="ri-information-line me-1"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <div class="edit">
                                            <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                            </div>
                                            <div class="remove">
                                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Delete</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
     </div>
  </div>

  <!-- <ng-template #addServiceArea role="document" let-modal>
    <div class="modal-header bg-light p-3">
      <h5 class="modal-title" id="exampleModalLabel">Add Service Area</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <form [formGroup]="serviceAreaForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label">
                  <label for="name-field" class="form-label">Name</label>
                </div>
                <div class="col-lg-10">
                  <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                  <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['name'].errors['required']">Enter Name</div>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label">
                  <label for="zones-field" class="form-label">Zones</label>
                </div>
                <div class="col-lg-10 table-zone-column">
                    <button type="submit" class="btn btn-primary" id="add-zone" (click)="openModalZone()">Add Zone</button>  
                    <div class="table-responsive">
                        <table class="table table-hover align-middle table-nowrap mb-0 table-zone">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col" style="width: 25px;">
                                    </th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Branch</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="branches">
                                <tr *ngFor="let branch of branches">
                                    <th scope="row">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" checked>
                                        </div>
                                    </th>
                                    <td>{{ branch.contactName }}</td>
                                    <td>{{ branch.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" id="add-service-area-md-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Close</button>
              <button type="submit" class="btn btn-primary" id="add-btn">Add Service Area</button>
            </div>
        </div>
    </form>
  </ng-template> -->

  <!-- <ng-template #addZone role="document" let-modal>
    <div class="modal-header bg-light p-3">
      <h5 class="modal-title" id="exampleModalLabel">Add Zone</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <form [formGroup]="addZoneForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="name-field" class="form-label">Name</label>
                </div>
                <div class="col-lg-9">
                  <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submittedZone && formZone['name'].errors }" />
                  <div *ngIf="submittedZone && formZone['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="formZone['name'].errors['required']">Enter Name</div>
                  </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="zones-field" class="form-label">Branches</label>
                </div>
                <div class="col-lg-9">
                    <ng-select
                        class="custom form-control"
                        [items]="branches"
                        [multiple]="false"
                        bindLabel="name" 
                        [ngClass]="{ 'is-invalid': submittedZone && formZone['branch'].errors }"
                        formControlName="branch"
                    >
                    </ng-select>
                    <div *ngIf="submittedZone && formZone['branch'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formZone['branch'].errors['required']">Enter Branch</div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="zones-field" class="form-label">Zipcode</label>
                </div>
                <div class="col-lg-9">
                    <ng-select
                        class="custom form-control"
                        [ngClass]="{ 'is-invalid': submittedZone && formZone['zipcode'].errors }"
                        [items]="zipcodesJson"
                        [multiple]="true"
                        [virtualScroll]="true"
                        [searchFn]="searchFn"
                        [closeOnSelect]="false"
                        [hideSelected]="true"
                        formControlName="zipcode"
                    ></ng-select>
                    <div *ngIf="submittedZone && formZone['zipcode'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formZone['zipcode'].errors['required']">Enter Zipcode</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click'); openModalServiceArea(addServiceArea)">Close</button>
              <button type="submit" class="btn btn-primary" id="add-btn" (click)="addZones()">Add Zone</button>
            </div>
          </div>
    </form>
  </ng-template> -->
  