import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { HeatmapHelper } from 'src/app/core/services/models/heatmap-helper';
import { MapsLayer } from 'src/app/core/services/models/trimble-maps.helper';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Maps'
    }
  ];

  heatmapHelper = new HeatmapHelper();

  trimbleMapOptions: Omit<TrimbleMaps.MapOptions, 'container'> = {
    style: TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
    center: [-98.38, 38.69], // starting position
    zoom: 3 // starting zoom
  };
  mapsLayers: MapsLayer[] = [
    {
      layer: {
        id: 'trucks-heat',
        type: 'heatmap',
        source: 'truckstops',
        maxzoom: 15,
        //you can customize color, intensity, opacity, and radius, here we leave color default
        paint: {
            // increase intensity as zoom level increases
            'heatmap-intensity': {
                stops: [
                    [11, 1],
                    [15, 3]
                ]
            },
            // increase radius as zoom increases
            'heatmap-radius': {
                stops: [
                    [11, 15],
                    [15, 20]
                ]
            },
            // decrease opacity to transition into the circle layer
            'heatmap-opacity': {
                default: 1,
                stops: [
                    [14, 1],
                    [15, 0]
                ]
            },
        }
      }
    },
    {
      layer: {
        id: 'truck-point',
        type: 'circle',
        source: 'truckstops',
        minzoom: 14,
        paint: {
            // increase the radius of the circle as the zoom level and dbh value increases
            'circle-radius': {
                property: 'dbh',
                type: 'exponential',
                stops: [
                    [{ zoom: 15, value: 1 }, 5],
                    [{ zoom: 15, value: 62 }, 10],
                    [{ zoom: 22, value: 1 }, 20],
                    [{ zoom: 22, value: 62 }, 50],
                ]
            },
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1,
            'circle-opacity': {
                stops: [
                    [14, 0],
                    [15, 1]
                ]
            }
        }
      }
    }
  ];
  showHeader: boolean = false;

  constructor(
    private router: Router,
    // private mapService: MapService,
  ) {
    this.heatmapHelper.setServiceType(this.heatmapHelper.selectedMapTab);
  }

  async ngOnInit(): Promise<void> {
    // this.csvLibrary = await this.fetchZipLatLng();
    if(String(this.router.url) == '/pages/maps') {
      this.showHeader = true;
    } else {
      this.showHeader = false;
    }
  }

  convertFile(event: any) {
    void this.heatmapHelper.convertUploadedFile(event);
  }
}
