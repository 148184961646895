import { ChartOptions } from './projects.model';

/**
 * Stat Counder Data
 */
 const statDataShipper = [{
    title: 'Routes Not Completed',
    value: 0,
    icon: 'briefcase',
    persantage: '0',
    profit: 'down',
    month: 'Routes Today'
  }, {
    title: 'Total Routes',
    value: 0,
    icon: 'award',
    persantage: '0',
    profit: 'up',
    month: 'Routes Today'
  }, {
    title: 'Total Stops',
    value: 0,
    icon: 'clock',
    persantage: '0 ',
    profit: 'down',
    month: 'Stops Today'
  }
];

const statDataCarrier = [{
  title: 'Routes in Progress',
  value: 0,
  icon: 'briefcase',
  persantage: '0',
  profit: 'down',
  month: 'Routes Today'
}, {
  title: 'Total Routes',
  value: 0,
  icon: 'award',
  persantage: '0',
  profit: 'up',
  month: 'Routes Today'
}, {
  title: 'Total Stops',
  value: 0,
  icon: 'clock',
  persantage: '0 ',
  profit: 'down',
  month: 'Stops Today'
}
];


/**
 * Active Projects
 */
 const ActiveProjects = [
  {
    Pname: "Minneapolis",
    profile: 'assets/images/users/avatar-3.jpg',
    Uname: 'Donald Risher',
    progress: 0,
    assignee: [
        {
          profile: 'assets/images/users/avatar-1.jpg'
        },
        {
          profile: 'assets/images/users/avatar-2.jpg'
        }
      ],
    status: 'Progress',
    date: '06 Sep 2021'
  },
  {
    Pname: "Kansas City",
    profile: 'assets/images/users/avatar-7.jpg',
    Uname: 'Shawn William',
    progress: 0,
    assignee: [
        {
          profile: 'assets/images/users/avatar-5.jpg'
        },
        {
          profile: 'assets/images/users/avatar-6.jpg'
        }
      ],
    status: 'Pending',
    date: '13 Nov 2021'
  },
  {
    Pname: "Minneapolis to Chicago",
    profile: 'assets/images/users/avatar-8.jpg',
    Uname: 'Names Hoynas',
    progress: 0,
    assignee: [
        {
          profile: 'assets/images/users/avatar-1.jpg'
        },
        {
          profile: 'assets/images/users/avatar-5.jpg'
        }
      ],
    status: 'Pending',
    date: '26 Nov 2021'
  },
  {
    Pname: "Kansas City to St. Louis",
    profile: 'assets/images/users/avatar-5.jpg',
    Uname: 'Pauline Jones',
    progress: 0,
    assignee: [
        {
          profile: 'assets/images/users/avatar-2.jpg'
        }
      ],
    status: 'Progress',
    date: '15 Dec 2021'
  },
  {
    Pname: "Chicago to St. Louis",
    profile: 'assets/images/users/avatar-6.jpg',
    Uname: 'Charlie Richardson',
    progress: 0,
    assignee: [
        {
          profile: 'assets/images/users/avatar-1.jpg'
        },
        {
          profile: 'assets/images/users/avatar-4.jpg'
        }
      ],
    status: 'Progress',
    date: '21 Dec 2021'
  }
];

/**
 * My Task
 */
 const MyTask = [
  {
    name: "Create new Admin Template",
    dedline: '03 Nov 2021',
    status: 'Completed',
    assignee: 
    {
      name: 'Mary Stoner',
      profile: 'assets/images/users/avatar-2.jpg'
    }
  },
  {
    name: "Marketing Coordinator",
    dedline: '17 Nov 2021',
    status: 'Progress',
    assignee: 
    {
      name: 'Den Davis',
      profile: 'assets/images/users/avatar-7.jpg'
    }
  },
  {
    name: "Administrative Analyst",
    dedline: '26 Nov 2021',
    status: 'Completed',
    assignee: 
    {
      name: 'Alex Brown',
      profile: 'assets/images/users/avatar-6.jpg'
    }
  },
  {
    name: "E-commerce Landing Page",
    dedline: '10 Dec 2021',
    status: 'Pending',
    assignee: 
    {
      name: 'Prezy Morin',
      profile: 'assets/images/users/avatar-5.jpg'
    }
  },
  {
    name: "UI/UX Design",
    dedline: '22 Dec 2021',
    status: 'Progress',
    assignee: 
    {
      name: 'Stine Nielsen',
      profile: 'assets/images/users/avatar-1.jpg'
    }
  },
  {
    name: "Projects Design",
    dedline: '31 Dec 2021',
    status: 'Pending',
    assignee: 
    {
      name: 'Jansh William',
      profile: 'assets/images/users/avatar-4.jpg'
    }
  }
  
];

/**
 * Team Members
 */
 const TeamMembers = [
  {
    name: "Create new Admin Template",
    dedline: '03 Nov 2021',
    status: 'Completed',
    assignee: 
    {
      name: 'Mary Stoner',
      profile: 'assets/images/users/avatar-2.jpg'
    }
  },
  {
    name: "Marketing Coordinator",
    dedline: '17 Nov 2021',
    status: 'Progress',
    assignee: 
    {
      name: 'Den Davis',
      profile: 'assets/images/users/avatar-7.jpg'
    }
  },
  {
    name: "Administrative Analyst",
    dedline: '26 Nov 2021',
    status: 'Completed',
    assignee: 
    {
      name: 'Alex Brown',
      profile: 'assets/images/users/avatar-6.jpg'
    }
  },
  {
    name: "E-commerce Landing Page",
    dedline: '10 Dec 2021',
    status: 'Pending',
    assignee: 
    {
      name: 'Prezy Morin',
      profile: 'assets/images/users/avatar-5.jpg'
    }
  },
  {
    name: "UI/UX Design",
    dedline: '22 Dec 2021',
    status: 'Progress',
    assignee: 
    {
      name: 'Stine Nielsen',
      profile: 'assets/images/users/avatar-1.jpg'
    }
  },
  {
    name: "Projects Design",
    dedline: '31 Dec 2021',
    status: 'Pending',
    assignee: 
    {
      name: 'Jansh William',
      profile: 'assets/images/users/avatar-4.jpg'
    }
  }
];

export { statDataShipper, statDataCarrier, ActiveProjects, MyTask, TeamMembers };