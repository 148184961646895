import { Component, Input, OnInit } from '@angular/core';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {
  @Input() tableHelper: TablePaginationHelper<unknown>;

  constructor() { }

  ngOnInit(): void {
  }

}
