<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Update Business Information</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="companyInfoForm">
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">Legal Entity: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-6 form-values">
                <ng-select class="custom form-control" [items]="legalEntityOptions" [multiple]="false" bindLabel="name" bindValue="value" [ngClass]="{ 'is-invalid': submitted && companyInfo['legalEntity'].errors }" formControlName="legalEntity"></ng-select>
                <div *ngIf="submitted && companyInfo['legalEntity'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="companyInfo['legalEntity'].errors['required']">Enter Legal Entity</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">Legal Name: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-9 form-values">
                <input formControlName="legalName" type="text" class="form-control" id="legalName-input" placeholder="" required [ngClass]="{ 'is-invalid': submitted && companyInfo['legalName'].errors }">
                <div class="invalid-feedback"> Enter Legal Name </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">DBA Name: <span class="opacity-0">*</span>
                </label>
            </div>
            <div class="col-lg-9 form-values">
                <input formControlName="dbaName" type="text" class="form-control" id="dbaName-input" placeholder="" required [ngClass]="{ 'is-invalid': submitted && companyInfo['dbaName'].errors }">
                <div class="invalid-feedback"> Enter DBA Name </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">EIN Number: <span class="opacity-0">*</span>
                </label>
            </div>
            <div class="col-lg-4 form-values">
                <input formControlName="einNumber" type="text" class="form-control" id="einNumber-input" placeholder="">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">Street: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-8 form-values">
                <input formControlName="street" type="text" class="form-control" id="einNumber-input" placeholder="" required [ngClass]="{ 'is-invalid': submitted && companyInfo['street'].errors }">
                <div class="invalid-feedback"> Enter Street </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">City: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-6 form-values">
                <input formControlName="city" type="text" class="form-control" id="einNumber-input" placeholder="" required [ngClass]="{ 'is-invalid': submitted && companyInfo['city'].errors }">
                <div class="invalid-feedback"> Enter City </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">State: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-6 form-values">
                <ng-select class="custom form-control" [items]="statesJson" bindLabel="prettyName" bindValue="techName" formControlName="state" [searchFn]="customSearchFn" [ngClass]="{ 'is-invalid': submitted && companyInfo['state'].errors }"></ng-select>
                <div class="invalid-feedback"> Enter State </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">ZIP Code: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-4 form-values">
                <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && companyInfo['zipCode'].errors }" [items]="zipcodesJson" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="zipCode"></ng-select>
                <div class="invalid-feedback"> Enter ZIP Code </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 label-column padding-top text-align-right">
                <label class="form-label">SS-4 Document: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-9 form-values">
                <div class="container dnd-cont" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" #fileDropRef id="fileDropRef" [multiple]="false" (change)="fileBrowseHandler($event)" accept=".doc, .docx" />
                    <img src="assets/images/dnd/ic-upload-file.svg" alt="">
                    <h3 id="dnd-text">Drag and drop file here</h3>
                    <h3>or</h3>
                    <label for="fileDropRef" id="browse-file">Browse for file</label>
                </div>
                <div class="files-list">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                        <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
                        <div class="info">
                            <h4 class="name">
                                {{ file?.name }}
                            </h4>
                            <p class="size">
                                {{ formatBytes(file?.size) }}
                            </p>
                            <app-progress [progress]="file?.progress"></app-progress>
                        </div>
                        <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" style="margin-top: 2em;">
            <div class="col-lg-3 label-column padding-top text-align-right"></div>
            <div class="col-lg-9" style="text-align: right;">
                <button type="button" class="btn btn-primary add-btn" (click)="submit()">Submit </button>
            </div>
        </div>
    </form>
</div>