<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Custom Permission</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="permissionForm">
        <div class="row mb-3">
            <div class="col-lg-3 padding-top no-wrap">
            </div>
            <div class="col-lg-9">
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="Save As New Role" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                        Save As New Role
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-lg-3 padding-top no-wrap">
                <label class="form-label">Role Name: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <input formControlName="roleName" type="text" class="form-control" id="roleName-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && form['roleName'].errors }">
                <div class="invalid-feedback">
                    Enter Role
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-lg-3 user-form-label text-align-right">
                <label for="role-field" class="form-label">Permissions:</label>
            </div>
            <div class="col-lg-9">
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="Manage Organizations" (onChange)="selectPermission()"
                        [(ngModel)]="permissions" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                    Manage Organizations
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="Manage Users" (onChange)="selectPermission()"
                        [(ngModel)]="permissions" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                    Manage Users
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="Manage Licenses" (onChange)="selectPermission()"
                        [(ngModel)]="permissions" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                    Manage Licenses
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="Post To Load Board" (onChange)="selectPermission()"
                        [(ngModel)]="permissions" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                    Post To Load Board
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="groupname" value="View Load Board" (onChange)="selectPermission()"
                        [(ngModel)]="permissions" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                    View Load Board
                </div>
            </div>
        </div>

        <div class="row mb-3" style="margin-top: 2em;">
            <div class="col-lg-2 padding-top">
            </div>
            <div class="col-lg-10" style="text-align: right;">
                <button type="button" class="btn btn-primary add-btn" (click)="close()">Submit
                </button>
            </div>
        </div>
    </form>
</div>