<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<img src="/assets/images/equipment-screenshot.png" 
    class="w-100 mb-4" role="button"
    (click)="redirectToLogin()" *ngIf="!showIframe && !showPendingMessage"/>

    <h4 *ngIf="!showIframe && showPendingMessage">
        Your Equipment Compliance Request is currently being reviewed.
    </h4>


    <div class="row justify-content-center" *ngIf="showIframe">
        <div class="col-lg-10">
            <div class="card">
                <div class="bg-primary position-relative">
                    <div class="card-body p-5">
                        <div class="text-center">
                            <h3>Request Account</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="row">
                        <h4>STAYING COMPLIANT IS EASY!</h4>
                        <p class="text-muted">It's a full-time job trying to stay compliant with FMCSA regulations and & DOT rules. Direct To Carrier understands these challenges carriers face managing equipment. We provide each carrier that has an active account a software plan and simple! Just add your equipment and due dates for inspections, maintenance, registration renewals, insurance renewals and much more!</p>
                    </div>
                    <br>
                    <div class="row content-section">
                        <h4>VEHICLE FILE MANAGEMENT FEATURE LIST</h4>
                        <br>
                        <br>
                        <h5>INSPECTION, MAINTENANCE & REPAIR MANAGEMENT</h5>
                        <p class="text-muted">Easily track all the inspection, maintenance, and repair records for each piece of equipment. Our record management allows you to input important information as well as attach your own files. </p>
                        <p class="text-muted">There are no limits on the number of records, documents, or storage space. All documents are retained in accordance with FMCSA DOT regulations.</p>
                    </div>
                    <div class="row content-section">
                        <h5>ACCIDENT REGISTRY</h5>
                        <p class="text-muted">Sometimes the unfortunate occurs, and accidents happen. When they do, it is important to ensure proper documentation and retention. With Direct To Carrier, you can enter each accident, provide ample details and attach important documents such as insurance claims, police reports and any other associated documents.</p>
                    </div>
                    <div class="row content-section">
                        <h5>UPCOMING DUE/EXPIRATION ALERTS</h5>
                        <p class="text-muted">In order to maintain FMCSA DOT compliance, it is vital that all inspections, preventative maintenance, registrations, insurance policies, etc., remain in good standing. With so many dates to remember, our system will make sure you never miss another one!</p>
                        <p class="text-muted">Included with your subscription is automated email reminders for these important dates. You'll receive an email alert with plenty of time allotted to complete the upcoming tasks.</p>
                    </div>
                    <div class="row content-section">
                        <h5>VEHICLE RECORDS</h5>
                        <p class="text-muted">No system is complete without the ability to generate reports. Our system includes three different types of reports.</p>
                        <p class="text-muted">Equipment Summary: This is a report that provides you an overview of all equipment in your account highlighting important dates, such as the next inspection and preventative maintenance dates.</p>
                        <p class="text-muted">Upcoming Inspections: This is a report that provides you with a list of equipment that has upcoming inspections along with the type of inspection due.</p>
                        <p class="text-muted">Upcoming Preventative Maintenance: This is a report that provides you with a list of equipment that has upcoming preventative maintenance.</p>
                    </div>
                    <div class="row content-section">
                        <h5>LAW ENFORCEMENT LOGIN</h5>
                        <p class="text-muted">No need to gather and print all of your vehicle files when facing an audit! With Direct To Carrier you provide login access to your auditor/law enforcement officer and they will have read-only access to your vehicle files!</p>
                        <p class="text-muted">Our law enforcement feature also includes a detailed history log of the law enforcement user's actions and the ability to enable/disable access.</p>
                    </div>
                    <br>
                    <div class="row content-section">
                        <h5>Let's Get Started</h5>
                    </div>
                    <div class="row">
                        <form [formGroup]="requestForm" *ngIf="companyInfo && userProfle">
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">First Name</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="userFirstName" [ngClass]="{ 'is-invalid': submitted && form['userFirstName'].errors }">
                                    <div *ngIf="submitted && form['userFirstName'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['userFirstName'].errors['required']">Enter First Name</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Last Name</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="userLastName" [ngClass]="{ 'is-invalid': submitted && form['userLastName'].errors }">
                                    <div *ngIf="submitted && form['userLastName'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['userLastName'].errors['required']">Enter Last Name</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">User Role</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="userRole" [ngClass]="{ 'is-invalid': submitted && form['userRole'].errors }">
                                    <div *ngIf="submitted && form['userRole'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['userRole'].errors['required']">Enter User Role</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Company Name</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyName" [ngClass]="{ 'is-invalid': submitted && form['companyName'].errors }">
                                    <div *ngIf="submitted && form['companyName'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyName'].errors['required']">Enter Company Name</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Company Address</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyAddress"  [ngClass]="{ 'is-invalid': submitted && form['companyAddress'].errors }">
                                    <div *ngIf="submitted && form['companyAddress'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyAddress'].errors['required']">Enter Company Address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Company Phone Number</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyPhone" [ngClass]="{ 'is-invalid': submitted && form['companyPhone'].errors }">
                                    <div *ngIf="submitted && form['companyPhone'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyPhone'].errors['required']">Enter Company Phone</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">EIN #</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyEINNumber"  [ngClass]="{ 'is-invalid': submitted && form['companyEINNumber'].errors }">
                                    <div *ngIf="submitted && form['companyEINNumber'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyEINNumber'].errors['required']">Enter EIN Number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">DOT #</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyDOTNumber"  [ngClass]="{ 'is-invalid': submitted && form['companyDOTNumber'].errors }">
                                    <div *ngIf="submitted && form['companyDOTNumber'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyDOTNumber'].errors['required']">Enter DOT Number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">MC #</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyMCNumber" [ngClass]="{ 'is-invalid': submitted && form['companyMCNumber'].errors }">
                                    <div *ngIf="submitted && form['companyMCNumber'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyMCNumber'].errors['required']">Enter MC Number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Total Trucks</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyTotalTrucks" [ngClass]="{ 'is-invalid': submitted && form['companyTotalTrucks'].errors }">
                                    <div *ngIf="submitted && form['companyTotalTrucks'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyTotalTrucks'].errors['required']">Enter Total Trucks</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="nameInput" class="form-label">Total Trailers</label>
                                </div>
                                <div class="col-lg-10">
                                    <input type="text" class="form-control" id="nameInput" formControlName="companyTotalTrailers" [ngClass]="{ 'is-invalid': submitted && form['companyTotalTrailers'].errors }">
                                    <div *ngIf="submitted && form['companyTotalTrailers'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['companyTotalTrailers'].errors['required']">Enter Total Trailers</div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="meassageInput" class="form-label">Contact First Name</label>
                                </div>
                                <div class="col-lg-10">
                                    <input class="form-control" id="meassageInput"  formControlName="contactFirstName">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="meassageInput" class="form-label">Contact Last Name</label>
                                </div>
                                <div class="col-lg-10">
                                    <input class="form-control" id="meassageInput"  formControlName="contactLastName">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="meassageInput" class="form-label">Contact Phone Number</label>
                                </div>
                                <div class="col-lg-10">
                                    <input class="form-control" id="meassageInput" formControlName="contactPhone">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2">
                                    <label for="meassageInput" class="form-label">Contact Email</label>
                                </div>
                                <div class="col-lg-10">
                                    <input class="form-control" id="meassageInput" formControlName="contactEmail">
                                </div>
                            </div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary" (click)="submitRequest()">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!--end col-->
    </div>