<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #users="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink>
                        Users
                    </a>
                    <ng-template ngbNavContent>
                        <app-user type="regular"></app-user>
                    </ng-template>
                </li>
                <li *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'"
                    [ngbNavItem]="4" class="nav-item" (click)="changeTab(4)">
                    <a ngbNavLink>
                        Drivers
                    </a>
                    <ng-template ngbNavContent>
                        <app-user type="driver"></app-user>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item" (click)="changeTab(2)">
                    <a ngbNavLink>
                        <span class="d-sm-block">Roles</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-roles></app-roles>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item" (click)="changeTab(3)"
                    [disabled]="isTrial()"> 
                    <a ngbNavLink>
                        <span class="d-sm-block">Chat Requests</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-chat-request></app-chat-request>
                    </ng-template>
                </li>
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content text-muted">
              <div [ngbNavOutlet]="users"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->