<div *ngIf="toOpen == 'phoneInformation'">
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Update Contact Information</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="companyInfoForm">
            <div class="row mb-3">
                <div class="col-lg-2 padding-top no-wrap text-align-right">
                    <label class="form-label">Phone: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <input formControlName="phone" type="text" class="form-control" id="phone-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && companyInfo['phone'].errors }">
                    <div class="invalid-feedback"> Enter Phone Number </div>
                </div>
            </div>
            <div class="row mb-3" style="margin-top: 2em;">
                <div class="col-lg-2 padding-top"></div>
                <div class="col-lg-10" style="text-align: right;">
                    <button type="button" class="btn btn-primary add-btn" (click)="submit()">Submit </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="toOpen == 'emailInformation'">
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Update Contact Information</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="companyInfoForm">
            <div class="row mb-3">
                <div class="col-lg-2 padding-top no-wrap text-align-right">
                    <label class="form-label">Email: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <input formControlName="email" type="text" class="form-control" id="email-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && companyInfo['email'].errors }">
                    <div class="invalid-feedback"> Enter Email </div>
                </div>
            </div>
            <div class="row mb-3" style="margin-top: 2em;">
                <div class="col-lg-2 padding-top"></div>
                <div class="col-lg-10" style="text-align: right;">
                    <button type="button" class="btn btn-primary add-btn" (click)="submit()">Submit </button>
                </div>
            </div>
        </form>
    </div>
</div>