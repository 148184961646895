<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row" *ngIf="showForm">
                    <form [formGroup]="zipcodesForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row mb-3">
                                    <div class="col-lg-1 label-form">
                                        <label for="zipcodeInput" class="form-label">ZIP Codes</label>
                                    </div>
                                    <div class="col-lg-11">
                                        <app-zipcode-picker
                                            [name]="'data'"
                                            [customClass]="'custom form-control ' + (submitted && form['zipCodes'].errors ? 'is-invalid' : '')"
                                            [control]="$any(form['zipCodes'])"
                                            [isShowError]="!!(submitted && form['zipCodes'].errors)"
                                        ></app-zipcode-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" id="submit-zipcode">
                                <div class="row" *ngIf="!showSpinner">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <div class="alert alert-success" role="alert" *ngIf="showSuccess">
                                            ZIP Codes have been saved.
                                        </div>
                                        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showError">
                                            {{ errorMessage }}
                                            <button type="button" class="btn-close close-alert-error" data-bs-dismiss="alert" aria-label="Close" (click)="showError = false"></button>
                                        </div>
                                    </div>
                                    <div class="col-lg-1">
                                        <button type="submit" class="btn btn-primary" (click)="submitZipcodes()" style="float: right; width: 100%;">Save</button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showSpinner">
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-10">
                                        <div class="alert alert-success" role="alert" *ngIf="showSuccess">
                                            ZIP Codes have been saved.
                                        </div>
                                        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showError">
                                            {{ errorMessage }}
                                            <button type="button" class="btn-close close-alert-error" data-bs-dismiss="alert" aria-label="Close" (click)="showError = false"></button>
                                        </div>
                                    </div>
                                    <div class="col-lg-1">
                                        <button type="submit" class="btn btn-primary button-save">
                                            <span>
                                                <div class="spinner-border text-primary" role="status"></div>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>