import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MENU } from 'src/app/layouts/sidebar/menu';
import { MenuItem } from 'src/app/layouts/sidebar/menu.model';
import { AuthService } from '../authentication/auth.service';
import { HttpService } from '../http-service';
import { Account } from '../models/account.model';
import { Permissions, PermissionsGetter } from '../models/permissions.model';


export const PERMISSION_URL: Partial<{
  [key in string]: PermissionsGetter;
}> = {
  '/pages/branches': 'Branches',
  '_roles': 'Roles',
  '_users': 'Users',
  '_modes': 'Modes',
  '/pages/contracts': 'Contracts'
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private authedUserPermissions?: Permissions;
  onAuthUserPermissionsChange$ = new Subject<any>();

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.subscribeToCurrentUser((user) => {
      if (!user) {
        console.log('[PermissionsService] [subscribeToCurrentUser] - clearing loaded permissions since no user');
        this.authedUserPermissions = undefined;
      }
    });

    this.authService.subscribeToLoadPermissions(async (acc) => {
      const account = acc || this.authService.currentAccountSelected;
      await this.loadAuthedUserPermissions(account?.accountId);
      let hasPermission = true;
      if (!account) {
        console.log('[PermissionsService] [subscribeToLoadPermissions] - clearing loaded permissions since no account');
        this.authedUserPermissions = undefined;
      } else {
        hasPermission = await this.checkUrlPermission(undefined, false, account || undefined);
      }
      this.authService.selectedAccountSubject.next(account);
      if (!hasPermission) {
        await this.router.navigate(['/pages/dashboard']);
      }

      this.checkPlanTypePermission(this.router.url, true)
    }, 'PermissionsService');
  }

  /**
   * Check if the logged in user's user type has permissions to access the url
   * @param url - the url the user is trying to access
   * @param redirect - if will redirect if no access
   * @returns true if has access, false otherwise
   */
  async checkUserTypePermission(url?: string, redirect = true): Promise<boolean> {
    if (!url) {
      url = this.router.url;
    }

    const menus = MENU.filter(m => m.link === url);
    if (menus.length === 0) {
      return true;
    }
    if (menus.findIndex(m => this.checkMenuUserTypePermission(m)) < 0) {
      if (redirect) {
        await this.router.navigate(['/pages/dashboard']);
      }
      return false;
    }

    return true;
  }

  /**
   * Check if the logged in user's plan type has permissions to access the url
   * @param url - the url the user is trying to access
   * @param redirect - if will redirect if no access
   * @returns true if has access, false otherwise
   */
  async checkPlanTypePermission(url?: string, redirect = true): Promise<boolean> {
    if (!url) {
      url = this.router.url;
    }

    const menus = MENU.filter(m => m.link === url);
    if (menus.length === 0) {
      return true;
    }
    if (menus.findIndex(m => this.checkMenuPlanTypePermission(m)) < 0) {
      if (redirect) {
        await this.router.navigate(['/pages/quick-start-links']);
      }
      return false;
    }

    return true;
  }

  checkMenuUserTypePermission(menu: MenuItem): boolean {
    return menu.foAccountTypes === undefined || menu.foAccountTypes.includes(this.authService.currentAccountSelected.accountType);
  }

  checkMenuPlanTypePermission(menu: MenuItem): boolean {
    return menu.forPlanTypes === undefined || menu.forPlanTypes.includes(this.authService.currentAccountPlanValue.status);
  }

  async checkUrlPermission(url?: string, redirect = true, account?: Account): Promise<boolean> {
    if (!url) {
      url = this.router.url;
    }
    const permissionsKey = PERMISSION_URL[url];
    if (!permissionsKey) {
      return true;
    }

    if (!this.permissions[permissionsKey].Read) {
      console.warn(`User Account has no access to ${url}`, this.permissions['_permissions']);
      if (redirect) {
        await this.router.navigate(['/pages/quick-start-links']);
      }
      return false;
    }

    return true;
  }

  get permissions(): Permissions {
    return this.authedUserPermissions || new Permissions({});
  }

  async loadAuthedUserPermissions(accountId?: string): Promise<void> {
    if (accountId) {
      // clear loaded permissions
      console.log(`[PermissionsService] [loadAuthedUserPermissions] - clearing previously loaded permissions... New account selected '${accountId}'`);
      this.authedUserPermissions = undefined;
    }

    if (this.authedUserPermissions && (accountId || this.authService.currentAccountSelected?.accountId)) {
      console.log('[loadAuthedUserPermissions] - already loaded for ', (accountId || this.authService.currentAccountSelected?.accountId));
      return; // no need to load again
    }

    return this.httpService.resolvedPermissions(accountId)
      .toPromise()
      .then(resp => {
        if (resp.error) {
          console.error('[PermissionsService] - error in response. Clearing permissions', resp);
          this.authedUserPermissions = undefined;
          return;
        }

        this.authedUserPermissions = new Permissions(resp.data);
        this.onAuthUserPermissionsChange$.next();
        console.log('[PermissionsService] [loadAuthedUserPermissions] - set new permissions', this.authedUserPermissions.json);
      })
      .catch(e => {
        console.error('[PermissionsService] - error in response. Clearing permissions', e);
        this.authedUserPermissions = undefined;
      });
  }

  clearAuthedUserPermissions(): void {
    this.authedUserPermissions = undefined;
  }
}
