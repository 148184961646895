<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Update Transportation Information</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="companyInfoForm">
        <div class="row mb-3">
            <div class="col-lg-5 label-column padding-top text-align-right">
                <label class="form-label">DOT Number: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-4">
                <input formControlName="dotNumber" type="text" class="form-control" id="dotNumber-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && companyInfo['dotNumber'].errors }">
                <div class="invalid-feedback"> Enter DOT Number </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-5 label-column padding-top text-align-right">
                <label class="form-label">MC Number: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-4">
                <input formControlName="mcNumber" type="text" class="form-control" id="mcNumber-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && companyInfo['mcNumber'].errors }">
                <div class="invalid-feedback"> Enter MC Number </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 label-column padding-top text-align-right">
                <label class="form-label">Authority: <span class="opacity-0">*</span>
                </label>
            </div>
            <div class="col-lg-4 form-values">
                <span *ngIf="companyInfo['hasAuthority'].value" style="color: green;">Active</span>
                <span *ngIf="!companyInfo['hasAuthority'].value" style="color: red;">Inactive</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-5 label-column padding-top text-align-right">
                <label class="form-label">Authority Document: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-7">
                <div class="container dnd-cont" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" #fileDropRef id="fileDropRef" [multiple]="false" (change)="fileBrowseHandler($event)" accept=".doc, .docx" />
                    <img src="assets/images/dnd/ic-upload-file.svg" alt="">
                    <h3 id="dnd-text">Drag and drop file here</h3>
                    <h3>or</h3>
                    <label for="fileDropRef" id="browse-file">Browse for file</label>
                </div>
                <div class="files-list">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                        <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
                        <div class="info">
                            <h4 class="name">
                                {{ file?.name }}
                            </h4>
                            <p class="size">
                                {{ formatBytes(file?.size) }}
                            </p>
                            <app-progress [progress]="file?.progress"></app-progress>
                        </div>
                        <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" style="margin-top: 2em;">
            <div class="col-lg-5 label-column padding-top text-align-right"></div>
            <div class="col-lg-7" style="text-align: right;">
                <button type="button" class="btn btn-primary add-btn" (click)="submit()">Submit </button>
            </div>
        </div>
    </form>
</div>