<div class="modal-body">
    <div class="row g-3">
        <div class="col-lg-12">
            <div class="text-center">
                <div class="position-relative d-inline-block">
                    <div id="upload-icon" class="position-absolute">
                        <label for="company-logo-input" class="mb-0" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Select Image">
                            <div class="avatar-xs cursor-pointer">
                                <div
                                    class="avatar-title bg-light border rounded-circle text-muted">
                                    <i class="ri-image-fill"></i>
                                </div>
                            </div>
                        </label>
                        <input class="form-control d-none" id="company-logo-input" type="file"
                            name="file"
                            accept="image/png,image/jpeg"
                            (change)="fileChange($event)">
                    </div>
                    <div class="avatar-lg p-1">
                        <div class="avatar-title bg-light rounded-circle">
                            <img [src]="companyInfo?.logoUrl" id="companylogo-img"
                                class="object-cover" 
                                (error)="handleError($event)"/>
                        </div>
                    </div>
                </div>
                <h5 class="fs-13 mt-3">Company Logo</h5>
            </div>
        </div>
        <form *ngIf="companyInfoForm && defaultPlace" [formGroup]="companyInfoForm">
            <div class="row g-3">
                <div class="col-lg-12">
                    <div class="form-check form-switch form-switch-lg" dir="ltr">
                        <label class="form-check-label" for="customSwitchsizelg">Company Public Profile</label>
                        <input type="checkbox" class="form-check-input" id="customSwitchsizelg" formControlName="isPublic" [checked]="cInfoForm['isPublic'].value" (change)="save()">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Company Legal Name</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter company legal name" required formControlName="legalName"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['legalName'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['legalName'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['legalName'].errors['required']">Company Legal Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Legal Entity</label>
                        <ng-select class="form-control" [items]="LEGAL_ENTITY_OPTIONS" [multiple]="false" formControlName="legalEntity" [ngModelOptions]="{standalone: true}" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                        <!-- <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter company legal entity" required formControlName="legalEntity"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['legalEntity'].errors }"/> -->
                        <div *ngIf="submitted && cInfoForm['legalEntity'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['legalEntity'].errors['required']">Company Legal Entity is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Company DBA Name</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter company DBA name" required formControlName="dbaName"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['dbaName'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['dbaName'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['dbaName'].errors['required']">Company DBA Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Type</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="" required formControlName="subtype"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['subtype'].errors }"/>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="defaultPlace">
                    <div>
                        <label for="name-field" class="form-label">Address</label>
                        <app-google-maps-search-box 
                            #googleMapsInput
                            [defaultPlace]="defaultPlace"
                            [showMap]="false" 
                            [isSubmittedOnce]="submitted"
                            [isRequired]="true"
                            [isRequiredPostalCode]="true"
                            (onPlaceChanged)="onPlaceChanged($event)">
                        </app-google-maps-search-box>
                        <!-- <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Address" required formControlName="state"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['state'].errors }"/> -->
                        <div *ngIf="submitted && cInfoForm['state'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['state'].errors['required']">Address is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <label for="name-field" class="form-label">Email <i class="ri-alert-fill align-middle label-alert"></i><span class="alert-text">This email is shown in searches</span><i class="ri-alert-fill align-middle label-alert"></i></label> 
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Email" required formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['email'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['email'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['email'].errors['required']">Email is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <label for="name-field" class="form-label">Phone <i class="ri-alert-fill align-middle label-alert"></i><span class="alert-text">This phone is shown in searches</span><i class="ri-alert-fill align-middle label-alert"></i></label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Phone" required formControlName="phone"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['phone'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['phone'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['phone'].errors['required']">Phone is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <label for="name-field" class="form-label">Website</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Website" required formControlName="website"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['website'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['website'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['website'].errors['required']">Website is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Number of Employees</label>
                        <input type="number" id="customername-field" class="form-control"
                            placeholder="Enter Number of Employee" required formControlName="numberOfEmployees"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['numberOfEmployees'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['numberOfEmployees'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['numberOfEmployees'].errors['required']">Number of Employee is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="name-field" class="form-label">Year Founded</label>
                        <input type="number" id="customername-field" class="form-control"
                            placeholder="Enter Years Founded" required formControlName="foundingYear"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['foundingYear'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['foundingYear'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['foundingYear'].errors['required']">Years Founded is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" *ngIf="accountType === 'carrier-account'">
                    <div>
                        <label for="name-field" class="form-label">Authority</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Authority" required formControlName="hasAuthority"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['hasAuthority'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['hasAuthority'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['hasAuthority'].errors['required']">Authority is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" *ngIf="accountType === 'carrier-account'">
                    <div>
                        <label for="name-field" class="form-label">DOT Number</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter DOT Number" required formControlName="dotNumber"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['dotNumber'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['dotNumber'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['dotNumber'].errors['required']">DOT Number is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" *ngIf="accountType === 'carrier-account'">
                    <div>
                        <label for="name-field" class="form-label">MC Number</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter MC Number" required formControlName="mcNumber"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['mcNumber'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['mcNumber'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['mcNumber'].errors['required']">MC Number is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div>
                        <label for="name-field" class="form-label">Description</label>
                        <input type="text" id="customername-field" class="form-control"
                            placeholder="Enter Description" required formControlName="description"
                            [ngClass]="{ 'is-invalid': submitted && cInfoForm['description'].errors }"/>
                        <div *ngIf="submitted && cInfoForm['description'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="cInfoForm['description'].errors['required']">Description is required</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6">
                    <div>
                        <label for="owner-field" class="form-label">Branch Name</label>
                        <input type="text" id="owner-field" class="form-control"
                            placeholder="Enter branch name" required formControlName="branchName"
                            [ngClass]="{ 'is-invalid': submitted && form['owner'].errors }" />
                        <div *ngIf="submitted && form['branchName'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="form['branchName'].errors['required']">Branch Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="industry_type-field" class="form-label">Industry Type</label>
                        <select class="form-control" id="industry_type-field"
                            formControlName="industryType"
                            [ngClass]="{ 'is-invalid': submitted && form['industry_type'].errors }">
                            <option value="">Select industry type</option>
                            <option value="Computer Industry">Computer Industry</option>
                            <option value="Chemical Industries">Chemical Industries</option>
                            <option value="Health Services">Health Services</option>
                            <option value="Telecommunications Services">Telecommunications Services
                            </option>
                            <option value="Textiles: Clothing, Footwear">Textiles: Clothing, Footwear
                            </option>
                        </select>
                        <div *ngIf="submitted && form['industryType'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="form['industryType'].errors['required']">Industry Type is
                                required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div>
                        <label for="location-field" class="form-label">Location</label>
                        <app-google-maps-search-box 
                            #googleMapsInput
                            [defaultPlace]="defaultPlace"
                            [showMap]="false" 
                            [isSubmittedOnce]="submitted"
                            [isRequired]="true"
                            [isRequiredPostalCode]="true"
                            (onPlaceChanged)="onPlaceChanged($event)">
                        </app-google-maps-search-box>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <label for="employee-field" class="form-label">Location Hours</label>
                        <div class="row mb-2" *ngFor="let day of dayOptions">
                            <div class="col-4 d-flex">
                                <div class="align-self-center">
                                    <p-checkbox [formControl]="$any(form['openingHoursChecked'])"
                                        formControlName="openingHoursChecked"
                                        [value]="day"
                                    >
                                    </p-checkbox> &nbsp; {{day}}
                                </div>
                            </div>
                            <div class="col-8">
                                <ng-container *ngIf="form['openingHoursChecked'].value.includes(day); else elseOpeningHoursChecked">
                                    <ng-select for="start-time" 
                                        name="openingHoursChecked"
                                        [items]="hours" class="form-control w-47 d-inline-block"
                                        bindLabel="prettyName" bindValue="techName" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                    -
                                    <ng-select for="end-time" 
                                        [items]="hours" class="form-control w-47 d-inline-block"
                                        bindLabel="prettyName" bindValue="techName" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </ng-container>
                                <ng-template #elseOpeningHoursChecked>
                                    Closed
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <label for="contact_email-field" class="form-label">Shipping Schedule</label>
                        <div class="row mb-2" *ngFor="let day of dayOptions">
                            <div class="col-4 d-flex">
                                <div class="align-self-center">
                                    <p-checkbox [formControl]="$any(form['secondaryOpeningHoursChecked'])"
                                        formControlName="secondaryOpeningHoursChecked"
                                        [value]="day"
                                    >
                                    </p-checkbox> &nbsp; {{day}}
                                </div>
                            </div>
                            <div class="col-8">
                                <ng-container *ngIf="form['secondaryOpeningHoursChecked'].value.includes(day); else elseSecondaryOpeningHoursChecked">
                                    <ng-select for="start-time" 
                                        [items]="hours" class="form-control w-47 d-inline-block"
                                        bindLabel="prettyName" bindValue="techName" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                    -
                                    <ng-select for="end-time" 
                                        [items]="hours" class="form-control w-47 d-inline-block"
                                        bindLabel="prettyName" bindValue="techName" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </ng-container>
                                <ng-template #elseSecondaryOpeningHoursChecked>
                                    Closed
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="website-field" class="form-label">Website</label>
                        <input type="text" id="website-field" class="form-control"
                            placeholder="Enter website" required formControlName="website"
                            [ngClass]="{ 'is-invalid': submitted && form['website'].errors }" />
                        <div *ngIf="submitted && form['website'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="form['website'].errors['required']">Website Type is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <label for="since-field" class="form-label">Since</label>
                        <input type="text" id="since-field" class="form-control"
                            placeholder="Enter since" required formControlName="since"
                            [ngClass]="{ 'is-invalid': submitted && form['since'].errors }" />
                        <div *ngIf="submitted && form['since'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="form['since'].errors['required']">Since Type is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div>
                        <label for="since-field" class="form-label">Information</label>
                        <textarea class="form-control" placeholder="Information" formControlName="information" rows="3"></textarea>
                        <div *ngIf="submitted && form['since'].errors" class="invalid-feedback"
                            align="left">
                            <div *ngIf="form['since'].errors['required']">Since Type is required</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <div class="hstack gap-2 justify-content-end">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" id="add-btn" (click)="save()">
            <span class="flex-grow-1 me-2">
                Save
              </span>
              <span class="spinner-border save-loading" role="status" *ngIf="isLoading">
                  <span class="visually-hidden">Loading...</span>
              </span>
        </button>
    </div>
</div>