import {Component, OnInit} from '@angular/core';
// Sweet Alert
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { Router } from '@angular/router';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { User } from 'src/app/core/services/models/auth.models';

@Component({
  selector: 'app-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
  providers: []
})
export class CarrierSearchComponent implements OnInit {

  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Carrier Search'
    }
  ];
  currentUser: User;
  searchType = 'shipper';
  selectedAccount: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    
    ) {
      this.selectedAccount = JSON.parse(sessionStorage.getItem('selectedAccount')!);
    }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.auth.currentUserValue;
    if(this.selectedAccount.type === 'shipper-account'){
      this.breadCrumbItems = [
        {
          label: 'Carrier & Broker Search'
        }
      ];
    }else if(this.selectedAccount.type === 'carrier-account') {
      this.breadCrumbItems = [
        {
          label: 'Shipper & Broker Search'
        }
      ];
    } else if(this.selectedAccount.type === 'broker-account') {
      this.breadCrumbItems = [
        {
          label: 'Shipper & Carrier Search'
        }
      ];
    }
  }
}
