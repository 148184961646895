import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { User } from 'src/app/core/services/models/auth.models';
import { ModeService } from 'src/app/core/services/mode-service/mode.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { Role } from 'src/app/core/services/models/role.model';
import { FEATURES, Permission, PERMISSION_GETTERS, RoleModalObject } from 'src/app/core/services/models/permissions.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  providers: [ModeService],
})
export class RolesComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  features = FEATURES;
  roleModalObj?: RoleModalObject;
  permissionGetters = PERMISSION_GETTERS;
  roles: Role[] = [];
  saveLoading = false;
  currentUser!: User;
  activeModal?: NgbModalRef;
  showData: boolean = false;
  private selectedAccountSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private httpRequest: HttpService,
    private permissionsService: PermissionsService,
  ) {
    super();
    this.selectedAccountSubscription = this.authService.subscribeToSelectedAccount(async () => {
      await this.permissionsService.checkUrlPermission('_roles');
      return this.loadData();
    }, 'Roles');
    // this.setRoleModalContent();
  }

  override async loadData(): Promise<void> {
    return this.getRoles();
  }

  ngOnDestroy(): void {
    this.selectedAccountSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.loadData();
  }

  get rolesPermissions(): Permission {
    return this.permissionsService.permissions.Roles;
  }

  getRoles() {
    this.httpRequest.getRoles().subscribe((data: any) => {
      this.roles = data.data;
      this.showData = true;
    })
  }

  openModal(content: any, role?: Role) {
    this.roleModalObj = new RoleModalObject(role);
    this.activeModal = this.modalService.open(content, {
      size: 'md',
      centered: true,
    });
  }

  closeModel(): void {
    this.activeModal?.close();
    this.roleModalObj = undefined;
  }

  deleteRole() {
    if (!this.roleModalObj) {
      return;
    }
    if (this.roleModalObj.mode !== 'Edit') {
      return;
    }

    this.saveLoading = true;

    const { roleId } = this.roleModalObj.updatePayload;

    this.httpRequest.deleteRole(roleId!)
      .pipe(finalize(()=>this.saveLoading = false))
      .subscribe(
        res => {
          this.getRoles();
          this.closeModel();
          Swal.fire({
            title: 'Success',
            text: 'Role has been deleted.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          })
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to delete role.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          })
        }
      );
  }

  saveRole() {
    if (!this.roleModalObj) {
      return;
    }

    if (!this.roleModalObj.validate()) {
      return;
    }

    if (this.roleModalObj.mode === 'Edit') {
      this._editRole();
    } else {
      this._createRole();
    }
  }

  private _createRole(): void {
    if (!this.roleModalObj) {
      console.warn('No Role set');
      return;
    }

    const accountId = this.authService.currentAccountSelected.accountId;
    if (!accountId) {
      console.warn('No account ID selected');
      return;
    }

    this.saveLoading = true;

    const payload = this.roleModalObj.createRolePayload;

    this.httpRequest.createRole(accountId, payload)
    .pipe(finalize(()=>this.saveLoading = false))
    .subscribe((data: Role) => {
      this.roles = [...this.roles, data];
      this.closeModel();
      Swal.fire({
        title: 'Success',
        text: 'Role has been saved.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      })
    }, error => {
      Swal.fire({
        title: 'Error',
        text: 'Failed to save role.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
        })
    })
  }

  private _editRole(): void {
    if (!this.roleModalObj) {
      console.log('No Role set');
      return;
    }
    const { roleId, payload } = this.roleModalObj.updatePayload;

    this.saveLoading = true;

    this.httpRequest.updateRole(roleId!, payload)
    .pipe(finalize(()=>this.saveLoading = false))
    .subscribe((data: Role) => {
      const index = this.roles.findIndex(r => r.roleId === roleId);
      this.roles[index] = data;
      this.roles = [...this.roles];
      this.closeModel();
      Swal.fire({
        title: 'Success',
        text: 'Role has been saved.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      })
    }, error => {
      Swal.fire({
        title: 'Error',
        text: 'Failed to save role.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
        })
    });
  }
}
