<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div class="row project-wrapper">
    <div class="col-xl-7">
        <div class="row">
            <ng-container *ngFor="let stat of statData; let i = index">
                <div class="col-lg-4 stats-data" *ngIf="i != 2">
                    <app-projects-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [persantage]="stat.persantage" [profit]="stat.profit" [month]="stat.month"></app-projects-stat>
                </div>
                <div class="col-lg-4 no-pad-right" *ngIf="i == 2">
                    <app-projects-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [persantage]="stat.persantage" [profit]="stat.profit" [month]="stat.month"></app-projects-stat>
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-header d-flex align-items-center">
                    <h4 class="card-title flex-grow-1 mb-0">Active Routes</h4>
                    <div class="flex-shrink-0">
                        <a href="javascript:void(0);" class="btn btn-soft-info btn-sm">Export Report</a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-card">
                        <table class="table table-nowrap table-centered align-middle">
                            <thead class="bg-light text-muted">
                                <tr>
                                    <th scope="col">Route Name</th>
                                    <th scope="col" *ngIf="currentAccountSelected.type == 'carrier-account'">Driver Name</th>
                                    <th scope="col" *ngIf="currentAccountSelected.type == 'shipper-account'">Carrier</th>
                                    <th scope="col" *ngIf="currentAccountSelected.type == 'broker-account'">Carrier</th>
                                    <th scope="col">Progress</th>
                                    <th scope="col">Assignee</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of ActiveProjects">
                                    <td class="fw-medium">{{data.Pname}}</td>
                                    <td>
                                        <img src="{{data.profile}}" class="avatar-xxs rounded-circle me-1" alt="">
                                        <a href="javascript: void(0);" class="text-reset">{{data.Uname}}</a>
                                    </td>
                                    <td>
                                        <div class="align-items-center">
                                            <div class="flex-shrink-0 me-1 text-muted fs-13">{{data.progress}}%</div>
                                            <ngb-progressbar [value]="data.progress" [striped]="true" style="height: 6px;"></ngb-progressbar>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="avatar-group flex-nowrap">
                                            <div class="avatar-group-item" *ngFor="let user of data.assignee">
                                                <a href="javascript: void(0);" class="d-inline-block">
                                                    <img src="{{user.profile}}" alt="" class="rounded-circle avatar-xxs">
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="badge" [ngClass]=" {'badge-soft-warning': data.status === 'Progress', 'badge-soft-danger': data.status === 'Pending', 'badge-soft-success': data.status === 'Completed'}">{{data.status}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                        <div class="flex-shrink-0">
                            <div class="text-muted">Showing <span class="fw-semibold">5</span> of <span class="fw-semibold">25</span> Results </div>
                        </div>
                        <ul class="pagination pagination-separated pagination-sm mb-0">
                            <li class="page-item disabled">
                                <a href="javascript:void(0);" class="page-link">←</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">1</a>
                            </li>
                            <li class="page-item active">
                                <a href="javascript:void(0);" class="page-link">2</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">3</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">→</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-5 trimble-map-section">
        <app-trimble-map #trimbleMap class="col-md-6" [mapOptions]="trimbleMapOptions" [sources]="geoJsonDataSources" [layers]="mapLayers" [listeners]="mapListeners" [routeStops]="routeStops"></app-trimble-map>
    </div>
</div>