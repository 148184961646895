import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { ContractModel, ContractSignerModel } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit {
  contract: ContractModel | undefined;
  signUrl: SafeResourceUrl | undefined;

  /** the logged in user as a signer. undefined if logged in user is not a signer */
  userSigner?: ContractSignerModel; 
  downloadUrl: string | undefined;
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Contracts',
      url: '../'
    }
  ];

  get senderSigners(): ContractSignerModel[] {
    if (!this.contract) {
      return [];
    }

    return (this.contract.signers || []).filter(signer => `${signer.userType}-account` === this.contract?.senderAccountType);   
  }

  get receiverSigners(): ContractSignerModel[] {
    if (!this.contract) {
      return [];
    }

    return (this.contract.signers || []).filter(signer => `${signer.userType}-account` === this.contract?.receiverAccountType);  
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const contractId = this.route.snapshot.paramMap.get('contractId') as string;
    if (!contractId) {
      console.error('No contract Id in url path');
      this.goToDashboard();
      return;
    }

    this.fetchData(contractId)
      .then(() => {
        this.breadCrumbItems[1] = {label: this.contract?.name || '...'};
      });
  }

  async fetchData(contractId: string): Promise<void> {
    this.contract = await this.httpService.getContract(contractId).toPromise();
    this.userSigner = this.getUserSigner();
    this._extractSignUrl();
    this.httpService.getContractUrl(contractId).subscribe(response => {
      this.downloadUrl = response.url;
    });
  }

  private getUserSigner() : ContractSignerModel | undefined {
    return this.contract?.signers?.find(s => s.userId === this.authService.currentUserValue.userId);
  }

  isPendingSign(): boolean {
    if (!this.userSigner || this.getSigningStatus(this.userSigner) === 'signed') {
      return false;
    }

    if (this.userSigner.signingUrl) {
      return true;
    } else {
      return false;
    }
  }

  private _extractSignUrl(): void {
    if (this.isPendingSign()) {
      this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.userSigner?.signingUrl || '');
    } else {
      this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getSignedUrl());
    }
  }

  private getSignedUrl(): string {
    // if (window.location.host === 'localhost:4200') {
    //   return `https://dev.directtocarrier.io${window.location.pathname}/signed`;
    // }
    return `${window.location.href}/signed`;
  }

  goToDashboard(): void {
    this.router.navigate([`./dashboard`], {
      relativeTo: this.route.parent
    });
  }

  getSigningStatus(signer: ContractSignerModel): 'pending' | 'signed' {
    switch (signer.status) {
      case 'WAITING_FOR_MY_SIGNATURE':
        return 'pending';
      default:
        return 'signed';
    }
  }

  onIframeLoad(iframe: HTMLIFrameElement): void {
    if (!iframe.src) {
      return;
    }

    try {
      iframe.contentDocument || iframe.contentWindow?.document;
      console.log('[onIframeLoad] - user has signed');
    } catch (e) {
      console.log('[onIframeLoad] - user has not yet signed');
      return;
    } 

    // if (!(iframe.contentDocument || iframe.contentWindow?.document)?.querySelector('#dtc-signed-contract')) {
    //   console.log(`[onIframeLoad] - #dtc-signed-contract not found`);
    //   return; // app-signed-contract is our component for the redirected url after signing. if it is not in iframe, then this is not the redirected url
    // }

    const userSigner = this.contract?.signers?.find(signer => signer.userId === this.authService.currentUserValue.userId);
    if (!userSigner) {
      console.warn('[onIframeLoad]- user signer not found')
      return;
    }

    userSigner.status = 'WAITING_FOR_OTHERS';
    this.cdr.detectChanges();
  }
}
