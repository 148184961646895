import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralMessageService {
  icon?: {
    containerCls?: string;
    cls?: string;
  };
  text?: {
    header?: string;
    message?: string;
  };
  goToLink?: {
    label: string;
    url: string;
  };


  constructor() { }

  reset(): void {
    this.icon = undefined;
    this.text = undefined;
    this.goToLink = undefined;
  }
}
