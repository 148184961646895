import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})

/**
 * Pass-Reset Basic Component
 */
export class BasicComponent implements OnInit {

  // Login Form
  passresetForm!: FormGroup;
  submitted = false;
  fieldTextType!: boolean;
  error = '';
  returnUrl!: string;
  isLoading = false;
  // set the current year
  year: number = new Date().getFullYear();
  code: any;

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private router: Router
    ) { }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
     this.passresetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.passresetForm.controls; }

  /**
   * Form submit
   */
   onSubmit() {
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.passresetForm.invalid) {
      this.isLoading = false;
      return;
    } else {
      this.httpRequest.verifyEmailForgotPassword(this.passresetForm.controls['email'].value).subscribe((data : any) => {
        this.isLoading = false;
        this.router.navigate(['/password-reset/email-success']);
      }, error => {
        this.isLoading = false;
        this.error = error ? error.error.reason : '';
      })
    }
  }

}
