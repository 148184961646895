<div id="layout-wrapper" *ngIf="!isIframe">
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
    <!-- Side Bar -->
    <app-sidebar></app-sidebar>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
    </div>
</div>
<div id="layout-wrapper" *ngIf="isIframe">
    <router-outlet></router-outlet>
</div>
<!-- Right Side Bar -->
<!-- <app-rightsidebar (settingsButtonClicked)="onSettingsButtonClicked()"></app-rightsidebar> -->