import {Component, OnInit} from '@angular/core';
// Sweet Alert
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';

import zipcodes from '../../../assets/jsons/zipcodes.json';

import { NgbdModalAddServiceArea } from './modals/add-service-area/add-service-area.component';
import { NgbdModalAddZone } from './modals/add-zone/add-zone.component';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';


@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss'],
  providers: []
})
export class ServiceAreaComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'SERVICE AREA'}
  ];
  currentUser: any;
  zipcodes = ['10001', '10002', '10003', '10004', '10005'];

  activeModal?: NgbModalRef;

  constructor(
    private auth: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    ) { }

    ngOnInit(): void {
      this.currentUser = this.auth.currentUserValue;
    }

    openModalServiceArea() {
        this.activeModal?.close();
        let modalRef = this.modalService.open(NgbdModalAddServiceArea, { size: 'lg', centered: true });
    }
}
