import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-tree-checkbox',
  templateUrl: './tree-checkbox.component.html',
  styleUrls: ['./tree-checkbox.component.scss']
})
export class TreeCheckboxComponent implements OnInit {
  private _options: TreeNode[] = [];
  @Input()
  set options(x: TreeNode[]) {
    this._options = x;
    // console.log('[TreeCheckbox] [set options]', x);
  }

  get options(): TreeNode[] {
    return this._options;
  }
  @Input()
  selected: TreeNode[] = [];
  @Output()
  selectedChange = new EventEmitter<TreeNode[]>();

  cols = [
    { field: 'name' }
  ];
  submitted = false;

  constructor() { }

  ngOnInit(): void {
  }

  isRowSelected(rowNode: any): boolean {
    return this.selected.indexOf(rowNode.node) >= 0;
  }

  toggleRowSelection(rowNode: any, event: any): void {
    if (this.isRowSelected(rowNode)) {
      this.removeSelected(rowNode.node);
      if (!event.shiftKey) {
        this.propagateRemoveDown(rowNode.node);
        this.propagateRemoveUp(rowNode.node.parent);
      }
    } else {
      this.selected.push(rowNode.node);
      if (!event.shiftKey) {
        this.propagatePushDown(rowNode.node);
        this.propagatePushUp(rowNode.node.parent);
      }
    }

    this.selected = [...this.selected];
    this.selectedChange.emit(this.selected);
  }

  private removeSelected(node: TreeNode): void {
    this.selected = this.selected.filter(
      treeNode => treeNode != node
    );
  }

  private propagateRemoveDown(node: TreeNode): void {
    this.removeSelected(node);

    if (!node.children || node.children.length == 0) {
      return;
    }

    node.children.forEach(
      child => this.propagateRemoveDown(child)
    );
  }

  private propagateRemoveUp(parent: TreeNode | undefined): void {
    if (!parent) {
      return;
    }

    if (!this.selected.includes(parent)) {
      return;
    }

    this.removeSelected(parent);
    this.propagateRemoveUp(parent.parent);
  }

  private propagatePushDown(node: TreeNode): void {
    if (!this.selected.includes(node)) {
      this.selected.push(node);
    }

    if (!node.children || node.children.length == 0) {
      return;
    }

    node.children.forEach(
      child => this.propagatePushDown(child)
    );
  }

  private propagatePushUp(parent: TreeNode | undefined): void {
    if (!parent || !parent.children || parent.children.length == 0) {
      return;
    }

    const totalChildren = parent.children.length;
    const selectedChildren = parent.children.filter(
      child => this.selected.indexOf(child) != -1
    ).length;

    if (totalChildren == selectedChildren && !this.selected.includes(parent)) {
      this.selected.push(parent);
    }

    this.propagatePushUp(parent.parent);
  }

}
