<div class="row">
    <div class="col-lg-2">
        <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" (click)="openModal(content, 'add', '')">
            <i class="ri-add-line align-bottom me-1"></i> Add </button>
    </div>
    <div class="col-lg-6"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" class="name-td">Name</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody *ngIf="accountsPayable">
        <tr *ngFor="let accounts of ListJsList$ | async">
            <td class="name-td">
                <ngb-highlight [result]="accounts.name" [term]="service.searchTerm" *ngIf="accounts.name"></ngb-highlight>
                <span class="badge bg-success default-badge" id="default-badge" *ngIf="accounts.isDefault">Default</span>
            </td>
            <td>
                <div class="d-flex gap-2">
                    <div class="edit">
                        <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal" (click)="openModal(content, 'edit', accounts)">Edit</button>
                    </div>
                    <div class="remove">
                        <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteAccountsPayable(accounts)">Delete</button>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="!accountsPayable">
        <tr class="loader-body">
            <td class="placeholder-glow name-td">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow">
                <span class="placeholder col-4"></span>
            </td>
        </tr>
    </tbody>
</table>
<div class="row justify-content-md-between align-items-md-center" *ngIf="(total$ | async)! && accountsPayable.length != 0">
    <div class="col-sm-12 col-md-5">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite"> Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries </div>
    </div>
    <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end gridjs-pagination">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize"></ngb-pagination>
        </div>
    </div>
</div>
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{ modalType }} Acccounts Payable</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
        {{ error }}
    </div>
    <form [formGroup]="accountsPayableForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-4 user-form-label text-align-right">
                    <label for="name-field" class="form-label">Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['name'].errors['required']">Enter Name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 user-form-label text-align-right">
                    <label for="name-field" class="form-label">Default: <span class="opacity-0">*</span>
                    </label>
                </div>
                <div class="col-lg-8 user-form-label">
                    <input class="form-check-input" type="checkbox" formControlName="isDefault">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Company Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingCompanyName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingCompanyName'].errors }">
                    <div class="invalid-feedback"> Enter Company Name </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Street: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingCompanyStreet" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingCompanyStreet'].errors }">
                    <div class="invalid-feedback"> Enter Street </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">City: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingCompanyCity" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingCompanyCity'].errors }">
                    <div class="invalid-feedback"> Enter City </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">State: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <ng-select class="custom form-control" [items]="statesJson" bindLabel="prettyName" bindValue="techName" formControlName="billingCompanyState" [searchFn]="customSearchFn" [ngClass]="{ 'is-invalid': submitted && form['billingCompanyState'].errors }"></ng-select>
                    <div class="invalid-feedback"> Enter State </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">ZIP Code: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['billingCompanyZipCode'].errors }" [items]="zipcodesJson" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="billingCompanyZipCode"></ng-select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Contact First Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingContactFirstName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingContactFirstName'].errors }">
                    <div class="invalid-feedback"> Enter Contact First Name </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Contact Last Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingContactLastName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingContactLastName'].errors }">
                    <div class="invalid-feedback"> Enter Contact Last Name </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Contact Phone: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingContactPhone" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingContactPhone'].errors }">
                    <div class="invalid-feedback"> Enter Contact Phone </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">Contact Email: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <input formControlName="billingContactEmail" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && form['billingContactEmail'].errors }">
                    <div class="invalid-feedback"> Enter Contact Email </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-4 padding-top text-align-right">
                    <label class="form-label">CC Emails: <span class="opacity-0">*</span>
                    </label>
                </div>
                <div class="col-lg-8">
                    <textarea formControlName="billingContactEmailCC" type="text" class="form-control" placeholder=""></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Add'" (click)="addAccountsPayable()">Save</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Edit'" (click)="editAccountsPayable()">Save</button>
            </div>
        </div>
    </form>
</ng-template>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>