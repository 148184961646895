 <div class="modal-header bg-light p-3">
      <h5 class="modal-title" id="exampleModalLabel">Add Zone</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
    </div>
    <form [formGroup]="addZoneForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="name-field" class="form-label">Name</label>
                </div>
                <div class="col-lg-9">
                  <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submittedZone && formZone['name'].errors }" />
                  <div *ngIf="submittedZone && formZone['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="formZone['name'].errors['required']">Enter Name</div>
                  </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="zones-field" class="form-label">Branches</label>
                </div>
                <div class="col-lg-9">
                    <ng-select
                        class="custom form-control"
                        [items]="branches"
                        [multiple]="false"
                        bindLabel="name" 
                        [ngClass]="{ 'is-invalid': submittedZone && formZone['branch'].errors }"
                        formControlName="branch"
                    >
                    </ng-select>
                    <div *ngIf="submittedZone && formZone['branch'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formZone['branch'].errors['required']">Enter Branch</div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                  <label for="zones-field" class="form-label">Zipcode</label>
                </div>
                <div class="col-lg-9">
                    <ng-select
                        class="custom form-control"
                        [ngClass]="{ 'is-invalid': submittedZone && formZone['zipcode'].errors }"
                        [items]="zipcodesJson"
                        [multiple]="true"
                        [virtualScroll]="true"
                        [searchFn]="searchFn"
                        [closeOnSelect]="false"
                        [hideSelected]="true"
                        formControlName="zipcode"
                    ></ng-select>
                    <div *ngIf="submittedZone && formZone['zipcode'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formZone['zipcode'].errors['required']">Enter Zipcode</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close()">Close</button>
              <button type="submit" class="btn btn-primary" id="add-btn" (click)="addZones()">Add Zone</button>
            </div>
          </div>
    </form>