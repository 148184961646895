import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './authentication/auth.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Config,  NylasSchedulingPage, NylasSchedulingPagePayload, RawNylasSchedulingPageResponse } from './models/nylasSchedulingPage.model';
import { Mode } from './models/mode.model';
import { PermissionsJson } from './models/permissions.model';
import { DocumentWithDownloadUrl } from './models/document.model';
import {
    ApiResponse,
    ContractModel,
    CreateTrailerPayload,
    CreateTruckPayload,
    CreateTruckTrailerComboPayload,
    DocumentModel,
    PaginatedResults,
    SuccessApiResponse,
    TrailerModel,
    TrailerTypeModel,
    TruckModel,
    TruckTrailerCombModel,
    TruckTypeModel
} from './models/models';
import { Role } from './models/role.model';
import { Permission } from './models/permission.model';
import { UserTypeEmail, User, UserCreationModel } from './models/user.model';
import { Account } from './models/account.model';
import { CreateServiceAreaZonePayload, ServiceArea, ServiceAreaPayload, ServiceAreaZone, UpdateServiceAreaZonePayload } from './models/service-areas.model';
import { RoutePayload } from './models/route-payload.model';
import { Route } from './models/route.model';
import { RouteCapacityGroup, RouteCapacityGroupPayload } from './models/route-capacity-group.model';
import { RouteCapacityRule, RouteCapacityRulePayload } from './models/route-capacity-rule.model';
import { Channel, ChannelPayload } from './models/channel.model';
import { BillingProfile, BillingProfilePayload } from './models/billing-profile.model';
import { ServiceWindow, ServiceWindowPayload } from './models/service-window.model';
import { ServiceType, ServiceTypePayload } from './models/service-type.model';
import { ServiceTypeSkill, ServiceTypeSkillPayload } from './models/service-type-skill.model';
import { FMCSA } from './models/fmcsa.model';
import { ServiceTypeTruckTrailerCombo, ServiceTypeTruckTrailerComboPayload } from './models/service-type-truck-trailer-combo.model';
import { LoadBoardModel } from './models/load-board.model';
import { ShipperIntegration, ShipperIntegrationPayload } from './models/shipper-integration.model';
import { LatLng } from 'src/app/shared/trimble-map/trimble-map.component';
import { b64_to_utf8, convertBase64ToBlob, DOCX_TYPE, isArrayEmpty } from '../utils/commons';
import { Lane, LanePayload } from './models/lane.model';
import { LaneStop } from './models/lane-stop.model';
import { UserProfile } from './models/user-profile.model';
import { CompanyInfo } from './models/company-info.model';
import { Branch } from './models/branch.model';
import { AccountPlan } from './models/account-plan.model';
import { PageRequest } from './models/table-pagination.helper';
import { ELDModel } from './models/eld.model';
import { AssociationModel } from './models/association.model';
import { Invoice, InvoicePayload } from './models/invoice.model';
import { ProposalPayload, ProposalsModel, ProposalStatus } from './models/proposals.model';
import { DocumentPayload, DocumentWithUploadUrl } from './models/document.model';
import { TradingRelationship } from './models/trading-relationship.model';
import { CapacityGroup, CapacityGroupPayload } from './models/capacity-group.model';
import { CapacityRulePayload } from './models/capacity-rule.model';
import { DocumentFlow, DocumentFlowRequest } from './models/document-flow.model';
import { DocumentFlowStep, DocumentFlowStepRequest } from './models/document-flow-step.model';
import { Asset, AssetPayload } from './models/asset.model';
import { AssetDriver, AssetDriverPayload } from './models/asset-driver.model';
import { PostalCode } from './models/postal-code.model';
import { Plan } from 'src/app/sign-up-process/sign-up-process.component';
import { EquipmentComplianceRequest, EquipmentComplianceRequestPayload } from './models/equipment-compliance.model';

export type UrlObj = {url: string};
export type UserIdObj = {userId: string};
export type UpdateRolePayload = Pick<Role, 'permissionSet'> & Partial<Pick<Role, 'name'>>;
export type CreateRolePayload = Pick<Role, 'name' | 'permissionSet'>;
export type CreateContractPayload = {
    files: string[];
    shipperSigners: string[];
    carrierSigners: string[];
    senderAccountId: string;
    receiverAccountId: string;
} & Pick<ContractModel, 'name'>;
export type TrimbleMileageReportLine = {
    TMiles: string,
    THours: string
};
export type TrimbleMileage = {ReportLines: TrimbleMileageReportLine[]};

@Injectable({ providedIn: 'root' })
export class HttpService {
    baseUrl = environment.api;
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        ) {}

    get currentUser() {
        return this.auth.currentUserValue;
    }

    get currentAccount() {
      return this.auth.currentAccountSelected;
    }

    get newHeaders(): HttpHeaders {
        return this._buildHeaders();
    }

    get requestOptions() {
        return {
            headers: this.newHeaders
        };
    }

    private _buildHeaders(options: Partial<{withAccountId: boolean; accountId: string, contentType: string }> = {}): HttpHeaders {
        const theOptions = {
            withAccountId: false || !!options.accountId,
            ...options
        }

        const headersJson: Record<string, string> = {
            'Content-Type': options.contentType || 'application/json',
            'Authorization': `Bearer ${this.currentUser?.token}`,
        };
        if (theOptions.withAccountId) {
            headersJson['X-DTC-Account-Id'] = theOptions.accountId || this.currentAccount?.accountId || ''
        }

        return new HttpHeaders(headersJson);
    }

    lookupFMCSAnameAndDotNumber(name: string, dotNumber?:string, isBroker = false): Observable<ApiResponse<FMCSA[]>>{
        const params: {[key: string]: string} = {};

        params['isBroker'] = String(isBroker);

        if(dotNumber){
            params['dotNumber'] = dotNumber;
        }

        return this.http.get<ApiResponse<FMCSA[]>>(
            `${this.baseUrl}/fmcsa/name/${name}`,
            {params}
        );
    }

    lookupFMCSAdotNumber(dotNumber: string, isBroker = false): Observable<ApiResponse<FMCSA>>{
        return this.http.get<ApiResponse<FMCSA>>(
            `${this.baseUrl}/fmcsa/dotNumber/${dotNumber}`
        );
    }

    combineGetFromList<T> (gets: Observable<ApiResponse<T[]>>[]): Observable<ApiResponse<T[]>> {
        return forkJoin(gets)
            .pipe(
                map(
                    responses => {
                        const results = responses.map(
                            res => <SuccessApiResponse<T[]>> res
                        ).flatMap(
                            successRes => successRes.data
                        );
                        return {
                            error: false,
                            data: results
                        }
                    }
                )
          ); 
    }

    paginatedGetFromList<T>( get: ()=>Observable<ApiResponse<T[]>>, pageRequest: PageRequest ): Observable<PaginatedResults<T>> {
        return get().pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return PaginatedResults.from(response.data, pageRequest);
            })
        );
    }

    paginatedGetRequest<T>(endpoint: string, params?: Record<string, string | number | boolean>): Observable<PaginatedResults<T>> {
        return this.http.get<ApiResponse<PaginatedResults<T>>>(
            `${this.baseUrl}${endpoint}`,
            {
                ...this.requestOptions,
                params
            }
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    // User Invitations
    getUserInvitations(){
        return this.http.get<any>(this.baseUrl + '/user/userInvitations', this.requestOptions);
    }

    acceptUserInvitations(userInvitationId: string){
        const data = {
            status: 'accepted'
        };
        return this.http.post<any>(this.baseUrl + '/userInvitations/' + userInvitationId,
            data,
            this.requestOptions);
    }

    declineUserInvitations(userInvitationId: string){
        const data = {
            status: 'declined'
        };
        return this.http.post<any>(this.baseUrl + '/userInvitations/' + userInvitationId,
            data,
            this.requestOptions);
    }

    getOtherAccountChatters(accountId: string): Observable<SuccessApiResponse<UserIdObj[]>>{
        return this.http.get<SuccessApiResponse<UserIdObj[]>>(
            `${this.baseUrl}/chatters?accountId=${accountId}`,
            this.requestOptions
        );
    }

    // Users Page
    createUsers(payload: any): Observable<ApiResponse<UserCreationModel>> {
        return this.http.post<ApiResponse<UserCreationModel>>(this.baseUrl + '/users', payload, this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    getUsers(type?: 'regular' | 'driver', accountId?: string): Observable<ApiResponse<User[]>> {
        const params: {[key: string]: string} = {};

        if(type){
            params['type'] = type;
        }

        return this.http.get<ApiResponse<User[]>>(
            `${this.baseUrl}/accounts/${accountId || this.auth.currentAccountSelected.accountId}/users`,
            {...this.requestOptions, params}
        );
    }

    listAssetDrivers(assetId: string): Observable<ApiResponse<AssetDriver[]>> {
        return this.http.get<ApiResponse<AssetDriver[]>>(
            `${this.baseUrl}/assets/${assetId}/drivers`,
            this.requestOptions
        );
    }

    addAssetDriver(assetId: string, payload: AssetDriverPayload): Observable<ApiResponse<AssetDriver>>{
        return this.http.post<ApiResponse<AssetDriver>>(
            `${this.baseUrl}/assets/${assetId}/drivers`,
            payload,
            this.requestOptions
        );
    }

    deleteAssetDriver(assetDriverId: string){
        return this.http.delete(
            `${this.baseUrl}/assetDrivers/${assetDriverId}`,
            this.requestOptions
        );
    }

    getUsersHasShippers(accountId?: string): Observable<ApiResponse<User[]>> {
        // let requestOptions = { headers: this.headers };
        return this.http.get<ApiResponse<User[]>>(
            `${this.baseUrl}/accounts/${accountId || this.auth.currentAccountSelected.accountId}/users?hasSigners=true`,
            this.requestOptions
        );
    }

    getUsersByIdOrEmail(idOrEmail: string): Observable<ApiResponse<UserProfile>> {
        // let requestOptions = { headers: this.headers };
        return this.http.get<ApiResponse<UserProfile>>(this.baseUrl + '/users/' + idOrEmail, this.requestOptions);
    }

    updateUser(id: string, payload: Partial<UserProfile>): Observable<ApiResponse<UserProfile>> {
        return this.http.post<SuccessApiResponse<UserProfile>>(
            this.baseUrl + '/users/' + id,
            payload,
            this.requestOptions
        )
    }

    getUserInAccountByEmail(email: string){
        return this.http.get<any>(
            this.baseUrl + '/accounts/'
                + this.auth.currentAccountSelected.accountId
                +'/users?email='+encodeURIComponent(email),
            this.requestOptions
        );
    }

    getUserWorkExperience(userId: string){
        return this.http.get<any>(
            this.baseUrl + '/users/'
                + userId
                +'/workExperiences',
            this.requestOptions
        );
    }

    deleteUserWorkExperience(workExperienceId: string){
        return this.http.delete<any>(
            this.baseUrl + '/workExperiences/'
                + workExperienceId,
            this.requestOptions
        );
    }

    editUserWorkExperience(workExperienceId: string, payload: any){
        return this.http.post<any>(
            this.baseUrl + '/workExperiences/'
                + workExperienceId,
                payload,
            this.requestOptions
        );
    }

    getAccountPlan(accountId: string): Observable<ApiResponse<AccountPlan>>{
        return this.http.get<ApiResponse<AccountPlan>>(
            `${this.baseUrl}/accounts/${accountId}/plan`,
            this.requestOptions
        );
    }

    addUser(data: User): Observable<SuccessApiResponse<User>> {
        return this.http.post<SuccessApiResponse<User>>(
            this.baseUrl + '/accounts/' +this.auth.currentAccountSelected.accountId + '/users',
            data,
            this.requestOptions
        )
    }

    addAsset(payload: AssetPayload): Observable<ApiResponse<Asset>>{
        return this.http.post<ApiResponse<Asset>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/assets`,
            payload,
            this.requestOptions
        );
    }

    listAssets(): Observable<ApiResponse<Asset[]>>{
        return this.http.get<ApiResponse<Asset[]>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/assets`,
            this.requestOptions
        );
    }

    updateAsset(id: string, payload: AssetPayload): Observable<ApiResponse<Asset>>{
        return this.http.post<ApiResponse<Asset>>(
            `${this.baseUrl}/assets/${id}`,
            payload,
            this.requestOptions
        );
    }

    deleteAsset(id: string) {
        return this.http.delete<any>(this.baseUrl  + '/assets/' + id, this.requestOptions);
    }

    getCompanyInfo(branchId: string): Observable<ApiResponse<CompanyInfo>>{
        return this.http.get<ApiResponse<CompanyInfo>>(
            `${this.baseUrl}/branches/${branchId}/companyInfo`,
            this.requestOptions
        );
    }

    getCompanyInfos(companyInfoId: string): Observable<ApiResponse<CompanyInfo>>{
        return this.http.get<ApiResponse<CompanyInfo>>(
            `${this.baseUrl}/companyInfos/${companyInfoId}`,
            this.requestOptions
        );
    }

    addCompanyInfo(branchId: string, payload: CompanyInfo): Observable<ApiResponse<CompanyInfo>>{
        return this.http.post<ApiResponse<CompanyInfo>>(
            `${this.baseUrl}/branches/${branchId}/companyInfo`,
            payload,
            this.requestOptions
        );
    }

    editCompanyInfo(companyInfoId: string, payload: CompanyInfo): Observable<ApiResponse<CompanyInfo>>{
        return this.http.post<ApiResponse<CompanyInfo>>(
            `${this.baseUrl}/companyInfos/${companyInfoId}`,
            payload,
            this.requestOptions
        );
    }

    createCompanyInfo(accountId: string, payload: CompanyInfo): Observable<ApiResponse<CompanyInfo>>{
        return this.http.post<ApiResponse<CompanyInfo>>(
            `${this.baseUrl}/accounts/${accountId}/companyInfos`,
            payload,
            this.requestOptions
        );
    }

    addAccountPlan(payload: any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/plan',
                payload,
            this.requestOptions
        );
    }

    addAssociation(payload: AssociationModel) {
        return this.http.post<AssociationModel>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/associations',
                payload,
            this.requestOptions
        );
    }

    deleteUser(id: string){
        return this.http.delete<any>(this.baseUrl  + '/accounts/' +this.auth.currentAccountSelected.accountId + '/users/' + id, this.requestOptions);
    }

    getUserByIdentifier(identifier: string) : Observable<ApiResponse<UserProfile>> {
        return this.http.get<any>(
            this.baseUrl + '/users/'
                + identifier
        );
    }

    getWorkExperienceByUserId(userId: string): Observable<ApiResponse<UserProfile>> {
        return this.http.get<any>(
            this.baseUrl + '/users/'
                + userId + '/workExperiences',
            this.requestOptions
        );
    }

    inviteUser(data: {userId: string, type: 'regular' | 'driver'}): Observable<SuccessApiResponse<UserTypeEmail>>{
        return this.http.post<SuccessApiResponse<UserTypeEmail>>(
            this.baseUrl + '/accounts/' +this.auth.currentAccountSelected.accountId + '/userInvitations',
            data,
            this.requestOptions
        )
    }

    getSigners(accountId: string): Observable<Pick<User, 'userId'>[]> {
        return this.http.get<ApiResponse<Pick<User, 'userId'>[]>>(
            `${this.baseUrl}/signers`,
            {
                ...this.requestOptions,
                params: {
                    accountId
                }
            }
        ).pipe(
            map(resp => {
                if (resp.error) {
                    throw new Error(resp.reason);
                }
                return resp.data;
            })
        );
    }

    getUserNylasSchedulingPage(): Observable<ApiResponse<NylasSchedulingPage[]>>{
        return this.http.get<ApiResponse<NylasSchedulingPage[]>>(
            this.baseUrl + '/user/nylasSchedulingPages',
            this.requestOptions
        );
    }

    createNylasSchedulingPage(payload: NylasSchedulingPagePayload): Observable<ApiResponse<NylasSchedulingPage>>{
        return this.http.post<ApiResponse<NylasSchedulingPage>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/nylasSchedulingPages`,
            payload,
            this.requestOptions
        );
    }

    getNylasSchedulingPage(id: string): Observable<ApiResponse<NylasSchedulingPage>>{
        return this.http.get<ApiResponse<NylasSchedulingPage>>(
            `${this.baseUrl}/nylasSchedulingPages/${id}`,
            this.requestOptions
        );
    }

    updateNylasSchedulingPage(id: string, payload: NylasSchedulingPagePayload): Observable<ApiResponse<NylasSchedulingPage>>{
        return this.http.post<ApiResponse<NylasSchedulingPage>>(
            `${this.baseUrl}/nylasSchedulingPages/${id}`,
            payload,
            this.requestOptions
        );
    }

    deleteNylasSchedulingPage(id: string): Observable<ApiResponse<any>> {
        return this.http.delete<ApiResponse<any>>(
            `${this.baseUrl}/nylasSchedulingPages/${id}`,
            this.requestOptions
        );
    }

    getRawNylasSchedulingPageById(schedulePageId: string): Observable<RawNylasSchedulingPageResponse>{
        return this.http.get<any>(`${this.baseUrl}/nylasSchedulingPages/${schedulePageId}/raw`, this.requestOptions);
    }

    updateRawNylasSchedulingPages(schedulingPageId: string, nylasConfig: Config): Observable<{data: {personal: number}}>{
        return this.http.post<any>(
            this.baseUrl + '/nylasSchedulingPages/' + schedulingPageId + '/raw',
            nylasConfig,
            this.requestOptions
        )
    }

    cleanupServiceAreaZoneName(zone: ServiceAreaZone): void {
        if (zone.name && !zone.zoneName) {
            zone.zoneName = zone.name;
        }
        if (!zone.name && zone.zoneName) {
            zone.name = zone.zoneName;
        }
    }

    pipeCleanupServiceAreaZoneNames() {
        return map<SuccessApiResponse<ServiceArea[]>, SuccessApiResponse<ServiceArea[]>>(result => {
            result.data.forEach(serviceArea => {
                serviceArea.zones?.forEach(z => this.cleanupServiceAreaZoneName(z));
            });

            return result;
        });
    }

    pipeCleanupServiceAreaZoneName() {
        return map<SuccessApiResponse<ServiceArea>, SuccessApiResponse<ServiceArea>>(result => {
            result.data.zones?.forEach(z => this.cleanupServiceAreaZoneName(z));

            return result;
        });
    }

    getServiceAreas(modeId: string, branchId: string, accountId?: string): Observable<SuccessApiResponse<ServiceArea[]>> {
        return this.http.get<SuccessApiResponse<ServiceArea[]>>(
            this.baseUrl + '/accounts/'
            + (accountId ? accountId : this.currentAccount?.accountId)
            + `/serviceAreas?modeId=${modeId}&branchId=${branchId}`,
            this.requestOptions
        ).pipe(
            this.pipeCleanupServiceAreaZoneNames()
        );
    }

    addServiceArea(serviceAreaPayload: ServiceAreaPayload): Observable<ServiceArea> {
        return this.http.post<SuccessApiResponse<ServiceArea>>(
            this.baseUrl + '/accounts/' +this.auth.currentAccountSelected.accountId + '/serviceAreas',
            serviceAreaPayload,
            this.requestOptions
        ).pipe(
            this.pipeCleanupServiceAreaZoneName(),
            map(result => result.data)
        );
    }

    updateServiceArea(serviceAreaId:string, serviceAreaPayload: ServiceAreaPayload): Observable<ServiceArea> {
        return this.http.post<SuccessApiResponse<ServiceArea>>(
            this.baseUrl + '/serviceAreas/' + serviceAreaId,
            serviceAreaPayload,
            this.requestOptions
        ).pipe(
            this.pipeCleanupServiceAreaZoneName(),
            map(result => {
                return result.data;
            })
        );
    }

    getServiceArea(serviceAreaId: string): Observable<ApiResponse<ServiceArea>>{
        return this.http.get<ApiResponse<ServiceArea>>(
            this.baseUrl + '/serviceAreas/' + serviceAreaId,
            this.requestOptions
        );
    }

    deleteServiceArea(serviceAreaId: string){
        return this.http.delete<any>(
            this.baseUrl + '/serviceAreas/' + serviceAreaId,
            this.requestOptions
        );
    }

    getModes(accountId?: string) {
        return this.http.get<any>(this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/modes', this.requestOptions);
    }

    getTopbarModes(): Observable<ApiResponse<Mode[]>> {
        return this.http.get<ApiResponse<Mode[]>>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/user/modes', this.requestOptions);
    }

    getBranchModes() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/user/branches?hierarchy=true', this.requestOptions);
    }

    addMode(data: Mode, accountId?: string){
        return this.http.post<any>(
            this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/modes',
            data,
            this.requestOptions
        )
    }

    updateMode(modeId: string, data:Mode){
        return this.http.post<any>(
            this.baseUrl + '/modes/' + modeId,
            data,
            this.requestOptions
        )
    }

    getBranchesByMode(modeId: string) {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/user/branches?hierarchy=true' + '&modeId=' + modeId, this.requestOptions);
        // return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/user/branches?hierarchy=true' + '&modeId=' + modeId, this.requestOptions);
    }

    deleteMode(id: string) {
        return this.http.delete<any>(this.baseUrl + '/modes/' + id, this.requestOptions);
    }

    getAMode(modeId: string) {
        return this.http.get<any>(this.baseUrl + '/modes/' + modeId, this.requestOptions);
    }

    // Permissions
    resolvedPermissions(accountId?: string): Observable<ApiResponse<Partial<PermissionsJson>>> {
      if (!this.auth.currentAccountSelected?.accountId) {
        return of({
          error: false,
          data: {}
        });
      }
        let newHeaders = new HttpHeaders({
            'X-DTC-Account-Id': accountId || this.currentAccount?.accountId || '',
            'Authorization': `Bearer ${this.currentUser?.token}`
        });

        let newRequestOption = {
            headers: newHeaders
        }
        return this.http.get<ApiResponse<Partial<PermissionsJson>>>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/user/resolvedPermissions',
            newRequestOption
        );
    }

    getPermissions(userId?: string): Observable<SuccessApiResponse<Permission>> {
        return this.http.get<SuccessApiResponse<Permission>>(
            this.baseUrl + '/accounts/'+this.auth.currentAccountSelected.accountId+'/users/'
                + (userId ? userId : this.currentUser.userId) + '/permissions',
            this.requestOptions
        );
    }

    addPermission(data: Permission, userId?: string){
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+this.auth.currentAccountSelected.accountId
                + '/users/' + (userId ? userId : this.currentUser.userId) + '/permissions',
            data,
            this.requestOptions
        )
    }

    // Branches
    getBranchesByHierarchy(leaves?: boolean) {
        let headers = new HttpHeaders({
            'X-DTC-Account-Id': `${this.auth.currentAccountSelected.accountId}`,
            'Authorization': `Bearer ${this.currentUser?.token}`
        });

        const params: {
          [param: string]: string;
        } = {
          hierarchy: 'true'
        };
        if (leaves !== undefined) {
          params['leaves'] = leaves ? 'true' : 'false';
        }

        let requestOptions = {
            headers: headers,
            params
        };

        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/user/branches',
            requestOptions
        );
    }

    setDefaultAccount(token: string) {
        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });

        let requestOptions = {
            headers: headers
        }
        return this.http.get<SuccessApiResponse<any>>(this.baseUrl + '/user/accounts', requestOptions);
    }

    getAccounts() {
        let headers = new HttpHeaders({
            'Authorization': `Bearer ${this.currentUser?.token}`
        });

        let requestOptions = {
            headers: headers
        }
        return this.http.get<any>(this.baseUrl + '/user/accounts', requestOptions);
    }

    getBranchById(id: string): Observable<ApiResponse<Branch>>{
        return this.http.get<ApiResponse<Branch>>(
            this.baseUrl + '/branches/'+ id,
            this.requestOptions
        );
    }

    deleteBranch(id: string){
        return this.http.delete(
            `${this.baseUrl}/branches/${id}`,
            this.requestOptions
        );
    }

    getBranches() {
        // let requestOptions = { headers: this.headers };
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/branches',
            this.requestOptions
        );
    }

    getParentBranch() {
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/branches?parentId',
            this.requestOptions
        );
    }

    getBranchesById(id: string) {
        // let requestOptions = { headers: this.headers };

        // return this.http.get<any>(this.baseUrl + '/branches/' + id + '/branches', this.requestOptions);
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/branches?parentId=' + id,
            this.requestOptions
        );
    }

    addBranch(data: any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/branches',
            data,
            this.requestOptions
        ).pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    updateBranch(id: string, data: Partial<Branch>): Observable<ApiResponse<Branch>> {
        return this.http.post<ApiResponse<Branch>>(this.baseUrl + '/branches/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    // Serviceable Zipcodes
    getUsersZipcodes(id: string) {
        return this.http.get<any>(this.baseUrl + '/companies/' + id, this.requestOptions);
    }

    submitZipcodes(id: string, data: any) {
        return this.http.post<any>(this.baseUrl + '/companies/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    // Zones
    getBranchesViaZip(zipcodes: string) {
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/branches?zipCodes=' + zipcodes,
            this.requestOptions
        );
    }

    getBranchesViaPostalCodes(modeId: string, postalCodes: string[]) {
        return this.http.get<any>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/branches?modeId=${modeId}`
                + `&postalCodes=${postalCodes.map(code => code.trim()).join(',')}`,
            this.requestOptions
        );
    }

    getAccountsPayable() {
        return this.http.get<any>(this.baseUrl + '/accountsPayable', this.requestOptions);
    }

    pipeCleanupZoneNames() {
        return map<SuccessApiResponse<ServiceAreaZone[]>, SuccessApiResponse<ServiceAreaZone[]>>(result => {
            result.data.forEach(z => this.cleanupServiceAreaZoneName(z));

            return result;
        });
    }

    pipeCleanupZoneName() {
        return map<SuccessApiResponse<ServiceAreaZone>, SuccessApiResponse<ServiceAreaZone>>(result => {
            this.cleanupServiceAreaZoneName(result.data);
            return result;
        });
    }

    getServiceAreaZones(serviceAreaId: string): Observable<ServiceAreaZone[]> {
        return this.http.get<SuccessApiResponse<ServiceAreaZone[]>>(
            `${this.baseUrl}/serviceAreas/${serviceAreaId}/zones`,
            this.requestOptions
        ).pipe(
            this.pipeCleanupZoneNames(),
            map(result => result.data)
        );

    }

    getZones() {
        return this.http.get<any>(this.baseUrl + '/zones', this.requestOptions);
    }

    getZonesViaId(id:string) {
        return this.http.get<any>(this.baseUrl + '/zones/' + id, this.requestOptions);
    }

    addZones(data:any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/zones',
            data,
            this.requestOptions
        ).pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    addZone(data: CreateServiceAreaZonePayload): Observable<ServiceAreaZone> {
        return this.http.post<SuccessApiResponse<ServiceAreaZone>>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/zones',
            data,
            this.requestOptions
        ).pipe(
            this.pipeCleanupZoneName(),
            map(result => result.data)
        );
    }



    updateZones(id: string, data:any) {
        return this.http.post<any>(this.baseUrl + '/zones/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    updateZone(zoneId: string, data: UpdateServiceAreaZonePayload): Observable<ServiceAreaZone> {
        return this.http.post<SuccessApiResponse<ServiceAreaZone>>(this.baseUrl + '/zones/' + zoneId, data, this.requestOptions)
        .pipe(
            this.pipeCleanupZoneName(),
            map(result => result.data)
        );
    }

    deleteZones(id:string) {
        return this.http.delete<ApiResponse<string>>(this.baseUrl + '/zones/' + id, this.requestOptions);
    }

    addRoutes(routePayload: RoutePayload): Observable<SuccessApiResponse<Route>> {
        return this.http.post<any>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/routes',
            routePayload,
            this.requestOptions
        );
    }

    updateRoutes(routeId: string, routePayload: RoutePayload) {
        return this.http.post<any>(
            this.baseUrl + '/routes/' + routeId,
            routePayload,
            this.requestOptions
        );
    }

    createCapacitGroup(payload: CapacityGroupPayload): Observable<ApiResponse<CapacityGroup>> {
        return this.http.post<ApiResponse<CapacityGroup>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/capacityGroups`,
            payload,
            this.requestOptions
        );
    }

    fetchCapacityRuleList(groupId: string): Observable<any>{
        return this.http.get<Observable<any>>(
            this.baseUrl + '/capacityGroups/' + groupId + '/capacityRules',
            this.requestOptions
        );
    }

    addCapacityRuleByGroupId(groupId: string, capacityRulePayload: CapacityRulePayload): Observable<any>{
        return this.http.post<Observable<any>>(
            this.baseUrl + '/capacityGroups/' + groupId + '/capacityRules',
            capacityRulePayload,
            this.requestOptions
        );
    }

    updateCapacitGroup(id: string, payload: CapacityGroupPayload): Observable<ApiResponse<CapacityGroup>> {
        return this.http.post<ApiResponse<CapacityGroup>>(
            `${this.baseUrl}/capacityGroups/${id}`,
            payload,
            this.requestOptions
        );
    }

    deleteCapacityGroup(id: string){
        return this.http.delete<ApiResponse<CapacityGroup>>(
            `${this.baseUrl}/capacityGroups/${id}`,
            this.requestOptions
        );
    }

    fetchCapacityGroupList(modeId: string, branchId: string): Observable<ApiResponse<CapacityGroup[]>>{
        const params: {[key: string]: string} = {};
        if(modeId){
            params['modeId'] = modeId;
        }

        if(branchId){
            params['branchId'] = branchId;
        }

        return this.http.get<ApiResponse<CapacityGroup[]>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/capacityGroups`,
            {...this.requestOptions, params}
        )
    }

    getRouteCapacityGroupsByRouteId(routeId: string): Observable<SuccessApiResponse<RouteCapacityGroup[]>>{
        return this.http.get<SuccessApiResponse<RouteCapacityGroup[]>>(
            this.baseUrl + '/routes/' + routeId + '/capacityGroups',
            this.requestOptions
        );
    }

    addRouteCapacityGroupByRouteId(routeId: string, payload: RouteCapacityGroupPayload): Observable<SuccessApiResponse<RouteCapacityGroup>>{
        return this.http.post<any>(
            this.baseUrl + '/routes/' + routeId + '/capacityGroups',
            payload,
            this.requestOptions
        );
    }

    updateRouteCapacityGroupByGroupId(groupId: string, capacityGroupPayload: RouteCapacityGroupPayload){
        return this.http.post<any>(
            this.baseUrl + '/routeCapacityGroups/' + groupId,
            capacityGroupPayload,
            this.requestOptions
        );
    }

    deleteRouteCapacityGroupByGroupId(groupId: string){
        return this.http.delete<any>(this.baseUrl + '/routeCapacityGroups/' + groupId, this.requestOptions);
    }

    getRouteCapacityRulesByGroupId(groupId: string): Observable<SuccessApiResponse<RouteCapacityRule[]>>{
        return this.http.get<any>(
            this.baseUrl + '/routeCapacityGroups/' + groupId + '/capacityRules' ,
            this.requestOptions
        );
    }

    addRouteCapacityRuleByGroupId(groupId: string, capacityRulePayload: RouteCapacityRulePayload){
        capacityRulePayload = {
            ...capacityRulePayload,
            day: Number(capacityRulePayload.day),
            units: Number(capacityRulePayload.units),
            totalWeight: Number(capacityRulePayload.totalWeight),
            totalVolume: Number(capacityRulePayload.totalVolume),
            stops: Number(capacityRulePayload.stops),
        }
        return this.http.post<any>(
            this.baseUrl + '/routeCapacityGroups/' + groupId + '/capacityRules',
            capacityRulePayload,
            this.requestOptions
        );
    }

    getRoutes(branchId?: string | null, modeId?: string | null, accountId?: string | null): Observable<SuccessApiResponse<Route[]>>{
        const params: {[key: string]: string} = {};

        if(branchId){
            params['branchId'] = branchId;
        }

        if(modeId){
            params['modeId'] = modeId;
        }

        return this.http.get<SuccessApiResponse<Route[]>>(
            this.baseUrl + '/accounts/' + (accountId ?? this.auth.currentAccountSelected.accountId) + '/routes',
            {...this.requestOptions, params}
        );
    }

    getRoutesViaZoneId(id: string) {
        return this.http.get<any>(this.baseUrl + '/routes?zoneId=' + id, this.requestOptions);
    }

    // Accounts Payable
    addAccountsPayable(data:any) {
        return this.http.post<any>(this.baseUrl + '/accountsPayable', data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    updateAccountsPayable(id: string, data:any) {
        return this.http.post<any>(this.baseUrl + '/accountsPayable/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    deleteAccountsPayable(id: string) {
        return this.http.delete<any>(this.baseUrl + '/accountsPayable/' + id, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    fetchTradingRelationshipList(modeId: string, branchId: string): Observable<ApiResponse<TradingRelationship[]>>{
        const params: {[key: string]: string} = {};
        if(modeId){
            params['modeId'] = modeId;
        }

        if(branchId){
            params['branchId'] = branchId;
        }

        return this.http.get<ApiResponse<TradingRelationship[]>>(
            this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships', 
            {...this.requestOptions, params}
        );
    }

    // Carriers
    getMyCarriers() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=active', this.requestOptions);
    }
    getInvited() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=invited', this.requestOptions);
    }
    getPending() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=pending', this.requestOptions);
    }
    getDeclined() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=declined', this.requestOptions);
    }
    getCompleted() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=completed', this.requestOptions);
    }
    getBlocked() {
        return this.http.get<any>(this.baseUrl + '/accounts/' + this.auth.currentAccountSelected.accountId + '/tradingRelationships?status=blocked', this.requestOptions);
    }

    // Carriers Search
    carrierSearch(url: string): Observable<SuccessApiResponse<Account[]>> {
        return this.http.get<ApiResponse<Account[]>>(this.baseUrl + '/carriers?' + url, this.requestOptions)
            .pipe(
                map(response => {
                    if (response.error) {
                        throw new Error(response.reason);
                    }

                    return response;
                })
            );
    }
    getCarrier(carrierAccountId: string): Observable<Account> {
        return this.http.get<ApiResponse<Account[]>>(
            `${this.baseUrl}/carriers`,
            {
                ...this.requestOptions,
                params: {
                    accountId: carrierAccountId
                }
            }
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }
                if (response.data.length === 0) {
                    throw new Error(`No Carrier found with accountId '${carrierAccountId}'`);
                }
                if (response.data.length > 1) {
                    console.warn(`Multiple carriers found with accountId '${carrierAccountId}'`);
                }
                return response.data[0];
            })
        );
    }

    inviteCarrier(data: any) {
        return this.http.post<any>(this.baseUrl + '/tradingRelationships', data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    //Shipper Search
    shipperSearch(url: string) : Observable<SuccessApiResponse<Account[]>> {
        return this.http.get<any>(this.baseUrl + '/shippers?' + url, this.requestOptions);
    }

    // Contracts
    createContract(payload: CreateContractPayload): Observable<ContractModel> {
        return this.http.post<ApiResponse<ContractModel>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/contracts`,
            payload,
            this.requestOptions
        ).pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.reason);
                }
                return result.data;
            })
        );
    }

    getContract(contractId: string): Observable<ContractModel> {
        return this.http.get<ApiResponse<ContractModel>>(
            `${this.baseUrl}/contracts/${contractId}`,
            this.requestOptions
        ).pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.reason);
                }
                return result.data;
            })
        );
    }

    getContractList(): Observable<ContractModel[]> {
        return this.http.get<ApiResponse<ContractModel[]>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/contracts`,
            this.requestOptions
        ).pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.reason);
                }
                return result.data;
            })
        );
    }

    getContractBase64(contractId: string): Observable<{base64: string}> {
        return this.http.get<ApiResponse<{base64: string}>>(
            `${this.baseUrl}/contracts/${contractId}/base64`,
            this.requestOptions
        ).pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.reason);
                }
                return result.data;
            })
        );
    }

    getContractUrl(contractId: string): Observable<{url: string}> {
        return this.http.get<ApiResponse<{url: string}>>(
            `${this.baseUrl}/contracts/${contractId}/url`,
            this.requestOptions
        ).pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.reason);
                }
                return result.data;
            })
        );
    }

    // Vehicles
    addVehicles(data: any) {
        return this.http.post<any>(this.baseUrl + '/vehicles', data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    getAllVehicles() {
        return this.http.get<any>(this.baseUrl + '/vehicles', this.requestOptions);
    }

    updateVehicle(id: string, data: any) {
        return this.http.post<any>(this.baseUrl + '/vehicles/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    deleteVehicles(id:string) {
        return this.http.delete<any>(this.baseUrl + '/vehicles/' + id, this.requestOptions);
    }


    // Load Board
    getLoadBoard() {
        return this.http.get<any>(this.baseUrl + '/loads', this.requestOptions);
    }

    listLoads(branchId?: string | null, modeId?: string | null, accountId?: string | null): Observable<ApiResponse<LoadBoardModel>> {
        const params: {[key: string]: string} = {};

        if(branchId){
            params['branchId'] = branchId;
        }

        if(modeId){
            params['modeId'] = modeId;
        }

        return this.http.get<ApiResponse<LoadBoardModel>>(
            `${this.baseUrl}/accounts/${accountId ?? this.auth.currentAccountSelected.accountId}/loads`,
            {...this.requestOptions, params}
        );
    }

    addLoad(data: Partial<LoadBoardModel>, accountId?:string) {
        return this.http.post<any>(
            `${this.baseUrl}/accounts/${accountId ?? this.auth.currentAccountSelected.accountId}/loads`,
            data,
            this.requestOptions
        )
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    getLoadById(loadId: string){
        return this.http.get<any>(this.baseUrl + '/loads/' + loadId, this.requestOptions);
    }


    // Invitations
    getInvitations() {
        return this.http.get<any>(this.baseUrl + '/invitations', this.requestOptions);
    }

    inviteCarriers(data: any) {
        return this.http.post<any>(this.baseUrl + '/invitations', data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    // Proposals
    createProposal(payload: ProposalPayload , accountId?: string): Observable<ApiResponse<ProposalsModel>> {
        return this.http.post<ApiResponse<ProposalsModel>>(
            this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/proposals', payload, 
            this.requestOptions
        );
    }

    editProposals(payload: any , proposalId: string , accountId?: string,) {
        return this.http.post<any>(this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/proposals/' + proposalId, payload, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    getProposalsByStatuses(statuses: ProposalStatus[], loadId?: string): Observable<ApiResponse<ProposalsModel[]>> {
        return this.combineGetFromList(
            statuses.map(
                status => this.getProposals({status, loadId})
            )
        );
    }

    getProposals(args?: {status?: ProposalStatus, loadId?: string, carrierId?: string}): Observable<ApiResponse<ProposalsModel[]>> {
        const params: {[key: string]: string} = {};

        if(args?.status){
            params['status'] = args?.status!;
        }

        if(args?.loadId){
            params['loadId'] = args?.loadId;
        }

        if(args?.carrierId){
            params['carrierId'] = args?.carrierId;
        }

        return this.http.get<any>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/proposals`, 
            {...this.requestOptions, params}
        );
    }

    updateProposal(id: string, data: {status: ProposalStatus}): Observable<ApiResponse<ProposalsModel>> {
        return this.http.post<ApiResponse<ProposalsModel>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/proposals/${id}`,
            data, 
            this.requestOptions
        );
    }

    // Offers
    getOffers() {
        return this.http.get<any>(this.baseUrl + '/offers', this.requestOptions);
    }


    // Licenses
    getLicenses() {
        return this.http.get<any>(this.baseUrl + '/licenses', this.requestOptions);
    }

    getAvailableLicenses() {
        return this.http.get<any>(this.baseUrl + '/licenses?loadId&tradingRelationshipId', this.requestOptions);
    }

    getUsedLicenses() {
        return this.http.get<any>(this.baseUrl + '/licenses?loadId=*', this.requestOptions);
    }

    getActiveLicenses() {
        return this.http.get<any>(this.baseUrl + '/licenses?tradingRelationshipStatus=active', this.requestOptions);
    }

    buyLicense(data: any) {
        return this.http.post<any>(this.baseUrl + '/invoices', data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    //Settings
    getInvoices(billingProfileId: string):Observable<ApiResponse<Invoice[]>> {
        return this.http.get<any>(
            `${this.baseUrl}/billingProfiles/${billingProfileId}/invoices`,
            this.requestOptions
        );
    }

    downloadFilePDF(invoiceId: any){
        let sUrl:string = this.baseUrl + '/invoices/'+ invoiceId +'/invoice.pdf';
        let headers = new HttpHeaders({
            'Authorization':`Bearer ${this.currentUser?.token}`,
        })
        return this.http.get(sUrl, { headers:headers, responseType:"arraybuffer"});
    }

    sendInvoiceEmail(invoiceId: any) {
        return this.http.post<any>(this.baseUrl + '/invoices/' + invoiceId + '/resend', '', this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    // Roles
    getRoles(accountId?: string): Observable<SuccessApiResponse<Role[]>> {
        return this.http.get<any>(this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/roles', this.requestOptions);
    }

    postRoles(payload: any, accountId?: string) {
        return this.http.post<any>(this.baseUrl + '/accounts/' + (accountId ? accountId : this.currentAccount?.accountId) + '/roles', payload, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    deleteRole(id: string){
        return this.http.delete<any>(this.baseUrl + '/roles/' + id, this.requestOptions);
    }

    updateRole(roleId: string, payload: UpdateRolePayload): Observable<Role> {
      return this.http.post<ApiResponse<Role>>(
        `${this.baseUrl}/roles/${roleId}`,
        payload,
        this.requestOptions
      ).pipe(
        map(result => {
          if (result.error) {
            throw result.reason;
          }

          return result.data;
        })
      )
    }

    createRole(accountId: string, payload: CreateRolePayload): Observable<Role> {
      return this.http.post<ApiResponse<Role>>(
        `${this.baseUrl}/accounts/${accountId}/roles`,
        payload,
        this.requestOptions
      ).pipe(
        map(result => {
          if (result.error) {
            throw result.reason;
          }

          return result.data;
        })
      )
    }

    // Billing
    // GET Billing Details via /companies
    getCompanies(accountId: any) {
        return this.http.get<any>(this.baseUrl + '/companies/' + accountId, this.requestOptions);
    }

    postCompanies(id: any, data: any) {
        return this.http.post<any>(this.baseUrl + '/companies/' + id, data, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
        }));
    }

    verifyBillingAddress(data: any) {
        return this.http.get<any>(this.baseUrl + '/verifyAddress?line1=' + data.billingCompanyStreet + '&zip=' + data.billingCompanyZipCode
        + '&state=' + data.billingCompanyState + '&city=' + data.billingCompanyCity, this.requestOptions);
    }

    // SignUp

    verifyEmail(email: any, metaData: any, template: string) {
        let payload = {
            "email": email,
            "template": template,
            "redirectUrl": `${environment.verify_signup_redirect_url}`,
            "metadata": metaData
        }
        console.log(payload);
        return this.http.post<any>(this.baseUrl + '/verifyEmail', payload)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    verifyEmailForgotPassword(email: any) {
        let payload = {
            "email": email,
            "template": 'passwordReset',
            "redirectUrl": `${environment.domain}` + "/password-reset/%%CODE%%/%%EMAIL%%",
            "metadata": ''
        }
        return this.http.post<any>(this.baseUrl + '/verifyEmail', payload, this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    resetPassword(userId: string, payload: any) {
        return this.http.post<any>(this.baseUrl + '/users/' + userId, payload, this.requestOptions)
        .pipe(map(result => {
            return result;
        }, (error: any) => {
            return error
    }));
    }

    addAccount(payload: any, token: any): Observable<ApiResponse<Account>> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        });
        let requestOptions = {
            headers: headers
        }
        return this.http.post<ApiResponse<Account>>(this.baseUrl + '/accounts', payload, requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    listShipperIntegrations(modeId: string): Observable<ApiResponse<ShipperIntegration[]>>{
        return this.http.get<ApiResponse<ShipperIntegration[]>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/shipperIntegrations?modeId=${modeId}`,
            this.requestOptions
        );
    }

    addShipperIntegration(payload: ShipperIntegrationPayload): Observable<ApiResponse<ShipperIntegration>>{
        return this.http.post<ApiResponse<ShipperIntegration>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/shipperIntegrations`,
            payload,
            this.requestOptions
        );
    }

    updateShipperIntegration(shipperIntegrationId: string, payload: ShipperIntegrationPayload): Observable<ApiResponse<ShipperIntegration>>{
        return this.http.post<ApiResponse<ShipperIntegration>>(
            `${this.baseUrl}/shipperIntegrations/${shipperIntegrationId}`,
            payload,
            this.requestOptions
        );
    }

    deleteShipperIntegration(id: string, ){
        return this.http.delete(
            `${this.baseUrl}/shipperIntegrations/${id}`,
            this.requestOptions
        );
    }

    deleteShipperIntegrationFile(id: string, filename: string){
        return this.http.delete(
            `${this.baseUrl}/shipperIntegrations/${id}/files/${filename}`,
            this.requestOptions
        );
    }

    addShipperIntegrationFile(id: string, file: File): Observable<ApiResponse<{uploadUrl: string}>>{
        const formData = new FormData();
        formData.append("file", file);
        const headers: HttpHeaders = this.newHeaders.set('Content-Type', file.type);
        return this.http.post<ApiResponse<{uploadUrl: string}>>(
            `${this.baseUrl}/shipperIntegrations/${id}/files/${file.name}`,
            file,
            {
              headers: headers,
            }
        );
    }

    convertToHtml(file: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        //TODO: need to ask Jared for the token
        return this.http.post<{Files: {FileData: string}[]}>(`https://v2.convertapi.com/convert/${file.name.split('.').pop()}/to/html?Token=y6qOi5sz`, formData).pipe(
            map(
                res => b64_to_utf8(res.Files[0].FileData)
            )
        );
    }

    convertAndDownloadDocxFile(file: File, destinationFileName: string): void {
        const formData = new FormData();
        formData.append('file', file, file.name);
        //TODO: need to ask Jared for the token
        this.http.post<{Files: {FileData: string}[]}>(`https://v2.convertapi.com/convert/${file.name.split('.').pop()}/to/docx?Token=y6qOi5sz`, formData).pipe(
            tap(
                res => {
                    const blob = convertBase64ToBlob(res.Files[0].FileData, DOCX_TYPE);
                    this.downloadBlob(blob, destinationFileName);
                }
            )
        ).toPromise();
    }

    addLane(payload: LanePayload): Observable<ApiResponse<Lane>>{
        return this.http.post<ApiResponse<Lane>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/lanes`,
            payload,
            this.requestOptions
        );
    }

    updateLane(id: string, payload: LanePayload): Observable<ApiResponse<Lane>>{
        return this.http.post<ApiResponse<Lane>>(
            `${this.baseUrl}/lanes/${id}`,
            payload,
            this.requestOptions
        );
    }

    deleteLane(laneId: string){
        return this.http.delete(
            `${this.baseUrl}/lanes/${laneId}`,
            this.requestOptions
        );
    }

    deleteRoute(routeId: string){
        return this.http.delete(
            `${this.baseUrl}/routes/${routeId}`,
            this.requestOptions
        );
    }

    deleteLaneStop(laneStopId: string) {
        return this.http.delete(
            `${this.baseUrl}/laneStops/${laneStopId}`,
            this.requestOptions
        );
    }

    listLaneStops(laneId: string): Observable<ApiResponse<LaneStop[]>>{
        return this.http.get<ApiResponse<LaneStop[]>>(
            `${this.baseUrl}/lanes/${laneId}/stops`,
            this.requestOptions
        );
    }

    addLaneStop(laneId: string, payload: LaneStop): Observable<ApiResponse<LaneStop>>{
        return this.http.post<ApiResponse<LaneStop>>(
            `${this.baseUrl}/lanes/${laneId}/stops`,
            payload,
            this.requestOptions
        );
    }

    listDocumentFlows(modeId?: string, branchId?: string, accountId?: string): Observable<ApiResponse<DocumentFlow[]>>{
        const params: {[key: string]: string} = {};

        if(modeId){
            params['modeId'] = modeId;
        }

        if(branchId){
            params['branchId'] = branchId;
        }

        return this.http.get<ApiResponse<DocumentFlow[]>>(
            `${this.baseUrl}/accounts/${accountId ?? this.currentAccount?.accountId}/documentFlows`,
            {...this.requestOptions, params}
        );
    }

    addDocumentFlow(documentFlowRequest: DocumentFlowRequest): Observable<ApiResponse<DocumentFlow>>{
        return this.http.post<ApiResponse<DocumentFlow>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/documentFlows`,
            documentFlowRequest,
            this.requestOptions
        );
    }

    deleteDocumentFlow(documentFlowId: string){
        return this.http.delete(
            `${this.baseUrl}/documentFlows/${documentFlowId}`,
            this.requestOptions
        );
    }

    updateDocumentFlow(documentFlowId: string, payload: DocumentFlowRequest): Observable<ApiResponse<DocumentFlow>>{
        return this.http.post<ApiResponse<DocumentFlow>>(
            `${this.baseUrl}/documentFlows/${documentFlowId}`,
            payload,
            this.requestOptions
        );
    }

    deleteDocumentFlowStep(dcumentFlowStepId: string){
        return this.http.delete(
            `${this.baseUrl}/documentFlowSteps/${dcumentFlowStepId}`,
            this.requestOptions
        );
    }

    addDocumentFlowStep(documentFlowId: string, payload: DocumentFlowStepRequest): Observable<ApiResponse<DocumentFlowStep>>{
        return this.http.post<ApiResponse<DocumentFlowStep>>(
            `${this.baseUrl}/documentFlows/${documentFlowId}/documentFlowSteps`,
            payload,
            this.requestOptions
        );
    }

    listDocumentFlowSteps(documentFlowId: string): Observable<ApiResponse<DocumentFlowStep[]>>{
        return this.http.get<ApiResponse<DocumentFlowStep[]>>(
            `${this.baseUrl}/documentFlows/${documentFlowId}/documentFlowSteps`,
            this.requestOptions
        );
    }

    listLanes(modeId: string, branchId: string, accountId?: string): Observable<ApiResponse<Lane[]>>{
        return this.http.get<ApiResponse<Lane[]>>(
            `${this.baseUrl}/accounts/${accountId ? accountId : this.currentAccount?.accountId}`
            + `/lanes?modeId=${modeId}&branchId=${branchId}`,
            this.requestOptions
        );
    }

    getServiceWindow(serviceWindowId: string): Observable<ApiResponse<ServiceWindow>>{
        return this.http.get<ApiResponse<ServiceWindow>>(
            `${this.baseUrl}/serviceWindows/${serviceWindowId}`,
            this.requestOptions
        );
    }

    listServiceWindows(accountId: string, modeId: string): Observable<ApiResponse<ServiceWindow[]>> {
        return this.http.get<ApiResponse<ServiceWindow[]>>(
            `${this.baseUrl}/accounts/${accountId ? accountId : this.currentAccount?.accountId}`
            + `/serviceWindows?modeId=${modeId}`,
            this.requestOptions
        );
    }

    addServiceWindow(payload: ServiceWindowPayload): Observable<ApiResponse<ServiceWindow>> {
        return this.http.post<ApiResponse<ServiceWindow>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/serviceWindows`,
            payload,
            this.requestOptions
        );
    }

    updateServiceWindow(id: string, payload: ServiceWindowPayload): Observable<ApiResponse<ServiceWindow>> {
        return this.http.post<ApiResponse<ServiceWindow>>(
            `${this.baseUrl}/serviceWindows/${id}`,
            payload,
            this.requestOptions
        );
    }

    deleteServiceWindow(id: string){
        return this.http.delete(
            `${this.baseUrl}/serviceWindows/${id}`,
            this.requestOptions
        );
    }

    addServiceTypeSkill(
        deliveryServiceTypeId: string,
        payload: ServiceTypeSkillPayload
    ): Observable<ApiResponse<ServiceTypeSkill>>{
        return this.http.post<ApiResponse<ServiceTypeSkill>>(
            `${this.baseUrl}/serviceTypes/${deliveryServiceTypeId}/skills`,
            payload,
            this.requestOptions
        );
    }

    listServiceTypeSkills(deliveryServiceTypeId: string): Observable<ApiResponse<ServiceTypeSkill[]>> {
        return this.http.get<ApiResponse<ServiceTypeSkill[]>>(
            `${this.baseUrl}/serviceTypes/${deliveryServiceTypeId}/skills`,
            this.requestOptions
        );
    }

    listServiceTypeTruckTrailerCombos(deliveryServiceTypeId: string): Observable<ApiResponse<ServiceTypeTruckTrailerCombo[]>> {
        return this.http.get<ApiResponse<ServiceTypeTruckTrailerCombo[]>>(
            `${this.baseUrl}/serviceTypes/${deliveryServiceTypeId}/truckTrailerCombos`,
            this.requestOptions
        );
    }

    createServiceTypeTruckTrailerCombo(
        deliveryServiceTypeId: string,
        payload: ServiceTypeTruckTrailerComboPayload
    ): Observable<ApiResponse<ServiceTypeTruckTrailerCombo>>{
        return this.http.post<ApiResponse<ServiceTypeTruckTrailerCombo>>(
            `${this.baseUrl}/serviceTypes/${deliveryServiceTypeId}/truckTrailerCombos`,
            payload,
            this.requestOptions
        );
    }

    deleteServiceTypeTruckTrailerCombo(
        serviceTypeTruckTrailerComboId: string
    ){
        return this.http.delete(
            `${this.baseUrl}/serviceTypeTruckTrailerCombos/${serviceTypeTruckTrailerComboId}`,
            this.requestOptions
        );
    }

    deleteServiceTypeSkills(serviceTypeSkillId: string){
        return this.http.delete(
            `${this.baseUrl}/serviceTypeSkills/${serviceTypeSkillId}`,
            this.requestOptions
        );
    }

    addServiceType(payload: ServiceTypePayload): Observable<ApiResponse<ServiceType>> {
        return this.http.post<ApiResponse<ServiceType>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/serviceTypes`,
            payload,
            this.requestOptions
        );
    }

    listServiceTypes(modeId: string, accountId?: string): Observable<ApiResponse<ServiceType[]>> {
        return this.http.get<ApiResponse<ServiceType[]>>(
            `${this.baseUrl}/accounts/${accountId || this.auth.currentAccountSelected.accountId}/serviceTypes?modeId=${modeId}`,
            this.requestOptions
        );
    }

    getServiceType(serviceTypeId: string): Observable<ApiResponse<ServiceType>> {
        return this.http.get<ApiResponse<ServiceType>>(
            `${this.baseUrl}/serviceTypes/${serviceTypeId}`,
            this.requestOptions
        );
    }

    updateServiceType(serviceTypeId: string,payload: ServiceTypePayload): Observable<ApiResponse<ServiceType>> {
        return this.http.post<ApiResponse<ServiceType>>(
            `${this.baseUrl}/serviceTypes/${serviceTypeId}`,
            payload,
            this.requestOptions
        );
    }

    deleteServiceType(id: string){
        return this.http.delete(
            `${this.baseUrl}/serviceTypes/${id}`,
            this.requestOptions
        );
    }

    forgotPassword(payload: any) {
        return this.http.post<any>(this.baseUrl + '/forgotPassword', payload)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    leadGeneration(payload: any) {
        return this.http.post<any>(this.baseUrl + '/onboardingLead', payload)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    //Document
    addDocument(payload: DocumentPayload): Observable<ApiResponse<DocumentWithUploadUrl>> {
        return this.http.post<ApiResponse<DocumentWithUploadUrl>>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/documents',
            payload,
            this.requestOptions
        );
    }

    getDocument(documentId: string): Observable<ApiResponse<DocumentWithDownloadUrl>> {
        return this.http.get<ApiResponse<DocumentWithDownloadUrl>>(
            this.baseUrl + '/documents/' + documentId,
            this.requestOptions
        );
    }

    updateDocument(payload: DocumentPayload, documentId: string): Observable<ApiResponse<DocumentModel[]>> {
        return this.http.post<ApiResponse<DocumentModel[]>>(
            this.baseUrl + '/documents/'+ documentId ,
            payload,
            this.requestOptions
        );
    }

    getAllDocuments(branchId?: string, modeId?: string, documentType?: string): Observable<ApiResponse<DocumentModel[]>> {
        const params: {[key: string]: string} = {};

        if(branchId){
            params['branchId'] = branchId;
        }

        if(modeId){
            params['modeId'] = modeId;
        }

        if(documentType){
            params['documentType'] = documentType;
        }

        return this.http.get<ApiResponse<DocumentModel[]>>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/documents',
            {...this.requestOptions, params}
        );
    }

    downloadDocument(documentId: string) {
        let headers = new HttpHeaders({
            'Content-Type':'application/pdf',
            'Authorization': `Bearer ${this.currentUser?.token}`,
        });

        let requestOptions = {
            headers: headers
        }
        return this.http.get<any>(this.baseUrl + '/documents/' + documentId +'/download', requestOptions);
    }

    fetchBlob(url: string): Observable<Blob>{
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadFile(filename: string, url: string) {
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
            this.downloadBlob(blob, filename);
        });
    }

    private downloadBlob(blob: Blob, filename: string){
        // Create a blob URL for the downloaded data
        const url = window.URL.createObjectURL(blob);
    
        // Create a link element and click it to initiate the download
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    
        // Release the object URL
        window.URL.revokeObjectURL(url);
    }


    // Channels
    getChannels(loadId: string): Observable<ApiResponse<Channel[]>> {
        return this.http.get<ApiResponse<Channel[]>>(
            `${this.baseUrl}/accounts/${this.auth.currentAccountSelected.accountId}/channels?loadId=${loadId}`,
            this.requestOptions
        );
    }

    getUserChannels(): Observable<ApiResponse<Channel[]>> {
        return this.http.get<ApiResponse<Channel[]>>(
            this.baseUrl + '/user/channels',
            this.requestOptions
        );
    }

    newChannel(payload: ChannelPayload): Observable<ApiResponse<Channel[]>> {
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/channels',
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    updateChannel(channelId: string, payload: ChannelPayload): Observable<ApiResponse<Channel[]>> {
        return this.http.post<any>(
            this.baseUrl + '/channels/'+ channelId,
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    retrieveHistory(
      channelId: string,
      params: Partial<{
        resultCount: number;
        lastEvaluatedChannelId: string;
        lastEvaluatedSentTime: number;
      }> = {},
    ) {
      const theParams: any = {};
      for (const [key, value] of Object.entries(params)) {
        if (value !== undefined) {
          theParams[key] = value;
        }
      }
      if (!theParams.resultCount) {
        theParams.resultCount = 20;
      }
      return this.http.get<any>(`${this.baseUrl}/channels/${channelId}/messages`, {
        ...this.requestOptions,
        params: theParams,
      });
    }

    // Account Signers
    getAccountUsers() {
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/users',
            this.requestOptions
        );
    }

    getAccountSigners() {
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/signers',
            // this.baseUrl + '/signers?/accountId='+ this.auth.currentAccountSelected.accountId,
            this.requestOptions
        );
    }

    updateAccountSigners(payload: any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/signers',
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    deleteUserSigner(userId: string) {
        return this.http.delete<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/signers/' + userId,
            this.requestOptions
        );
    }

    // Chatters
    getAccountChatters() {
        return this.http.get<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/chatters',
            // this.baseUrl + '/signers?/accountId='+ this.auth.currentAccountSelected.accountId,
            this.requestOptions
        );
    }

    updateAccountChatters(payload: any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/chatters',
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    deleteUserChatters(userId: string) {
        return this.http.delete<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/chatters/' + userId,
            this.requestOptions
        );
    }

    // ELD
    createEld(payload: ELDModel) {
        return this.http.post<ApiResponse<ELDModel>>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/eldDevices',
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }

    postInvoice(billingProfileId: string, payload: InvoicePayload): Observable<ApiResponse<Invoice>>{
        return this.http.post<ApiResponse<Invoice>>(
            `${this.baseUrl}/billingProfiles/${billingProfileId}/invoices`,
            payload,
            this.requestOptions
        );
    }

    listBillingProfiles(): Observable<ApiResponse<BillingProfile[]>>{
        return this.http.get<ApiResponse<BillingProfile[]>>(
            `${this.baseUrl}/accounts/${this.currentAccount?.accountId}/billingProfiles`,
            this.requestOptions
        );
    }

    addAccountBillingProfiles(billingProfilePayload: BillingProfilePayload, accountId: string): Observable<ApiResponse<BillingProfile>>{
        return this.http.post<ApiResponse<BillingProfile>>(
            this.baseUrl + '/accounts/'+ accountId + '/billingProfiles',
            billingProfilePayload,
            this.requestOptions
        )
    }

    updateAccountBillingProfies(id: string, billingProfilePayload: BillingProfilePayload): Observable<ApiResponse<BillingProfile>>{
        return this.http.post<ApiResponse<BillingProfile>>(
            `${this.baseUrl}/billingProfiles/${id}`,
            billingProfilePayload,
            this.requestOptions
        )
    }

    deleteBillingProfile(id: string){
        return this.http.delete<any>(
            `${this.baseUrl}/billingProfiles/${id}`,
            this.requestOptions
        )
    }

    beginPaymentSetup(billingProfileId: string, plan?: Plan):Observable<ApiResponse<UrlObj>> {
        const successUrl = plan === 'paid'
            ? `${window.location.origin}/pages/dashboard?plan=${plan}&billingProfileId=${billingProfileId}`
            : window.location.href;
        return this.http.post<any>(
            this.baseUrl + '/stripe/beginPaymentSetup',
            {
                billingProfileId,
                successUrl,
                cancelUrl: window.location.href,
                redirect: false
            },
            this.requestOptions
        )
    }

    // Fuel Providers
    createFuelProvider(payload: any) {
        return this.http.post<any>(
            this.baseUrl + '/accounts/'+ this.auth.currentAccountSelected.accountId + '/fuelProviders',
            payload,
            this.requestOptions
        ).pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
        }));
    }


    //#region TRUCKS
    listTruckTypes(): Observable<TruckTypeModel[]> {
        return this.http.get<ApiResponse<TruckTypeModel[]>>(
            `${this.baseUrl}/truckTypes`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    getTruckType(truckTypeId: string): Observable<ApiResponse<TruckTypeModel>> {
        return this.http.get<ApiResponse<TruckTypeModel>>(
            `${this.baseUrl}/truckTypes/${truckTypeId}`,
            this.requestOptions
        );
    }

    listTrucks(): Observable<TruckModel[]> {
        return this.http.get<ApiResponse<TruckModel[]>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/trucks`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    createTruck(payload: CreateTruckPayload): Observable<TruckModel> {
        return this.http.post<ApiResponse<TruckModel>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/trucks`,
            payload,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    deleteTruck(truckId: string): Observable<string> {
        return this.http.delete<ApiResponse<string>>(
            `${this.baseUrl}/trucks/${truckId}`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }
    //#endregion TRUCKS

    //#region TRAILERS
    listTrailerTypes(): Observable<TrailerTypeModel[]> {
        return this.http.get<ApiResponse<TrailerTypeModel[]>>(
            `${this.baseUrl}/trailerTypes`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    getTrailerType(trailerTypeId: string): Observable<ApiResponse<TrailerTypeModel>> {
        return this.http.get<ApiResponse<TrailerTypeModel>>(
            `${this.baseUrl}/trailerTypes/${trailerTypeId}`,
            this.requestOptions
        );
    }

    listTrailers(): Observable<TrailerModel[]> {
        return this.http.get<ApiResponse<TrailerModel[]>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/trailers`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    createTrailer(payload: CreateTrailerPayload): Observable<TrailerModel> {
        return this.http.post<ApiResponse<TrailerModel>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/trailers`,
            payload,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    deleteTrailer(trailerId: string): Observable<string> {
        return this.http.delete<ApiResponse<string>>(
            `${this.baseUrl}/trailers/${trailerId}`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }
    //#endregion TRUCKS

    //#region TRUCK + TRAILER COMBOS
    listTruckTrailerCombos(): Observable<TruckTrailerCombModel[]> {
        return this.http.get<ApiResponse<TruckTrailerCombModel[]>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/truckTrailerCombos`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    createTruckTrailerCombo(payload: CreateTruckTrailerComboPayload): Observable<TruckTrailerCombModel> {
        return this.http.post<ApiResponse<TruckTrailerCombModel>>(
            `${this.baseUrl}/accounts/${this.currentAccount.accountId}/truckTrailerCombos`,
            payload,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }

    deleteTruckTrailerCombo(truckTrailerCombos: string): Observable<string> {
        return this.http.delete<ApiResponse<string>>(
            `${this.baseUrl}/truckTrailerCombos/${truckTrailerCombos}`,
            this.requestOptions
        ).pipe(
            map(response => {
                if (response.error) {
                    throw new Error(response.reason);
                }

                return response.data;
            })
        );
    }
    //#endregion TRUCKS

    //#region MISC
    getUSZipcodesByRadius(zipCode: string, miles: number) {
        return this.http.get<{zip_codes: string[]}>(
            `https://redline-redline-zipcode.p.rapidapi.com/rest/radius.json/${zipCode}/${miles}/miles?minimal`,
            {
                headers: {
                    'X-RapidAPI-Key': 'f72ae72690msh21d66514f6a342ep17a407jsn19d43361abc9',
                    'X-RapidAPI-Host': 'redline-redline-zipcode.p.rapidapi.com'
                }
            }
        );
    }

    //#endregion MISC

    getOauth(state: string, type: string) {
        return this.http.get<any>(`${this.baseUrl}/oauth/${type}?state=${state}`);
    }

    redeemOauth(payload: {token: string; code: string}, type: string) {
      return this.http.post(`${this.baseUrl}/oauth/${type}`, payload);
    }


    // Equipment Compliance Request
    listEquipmentComplianceRequest(companyInfoId: string) {
        return this.http.get<ApiResponse<EquipmentComplianceRequest>>(
            `${this.baseUrl}/companyInfos/${companyInfoId}/equipmentComplianceRequests`,
            this.requestOptions
        );
    }

    createEquipmentComplianceRequest(companyInfoId: string, payload: EquipmentComplianceRequestPayload): Observable<ApiResponse<EquipmentComplianceRequest>>{
        return this.http.post<ApiResponse<EquipmentComplianceRequest>>(
            this.baseUrl + '/companyInfos/'+ companyInfoId + '/equipmentComplianceRequests',
            payload,
            this.requestOptions
        )
    }



    // PUSHER
    // subscribeChannel(channelId: string) {

    //     return this.http.post<any>(this.baseUrl + '/hooks/pusher_channel_auth', payload, this.requestOptions)
    //         .pipe(map(result => {
    //             return result;
    //         }, (error: any) => {
    //             return error
    //     }));
    // }


    // Standard CRUD ENDPOINTS

    post(url: string, data: string) {
        // let requestOptions = { headers: this.headers };
        return this.http.post<any>(this.baseUrl + '/' + url, data, this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
            }));
    }

    get(url: string) {
        // let requestOptions = { headers: this.headers };
        return this.http.get<any>(this.baseUrl + '/' + url, this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
            }));
    }

    delete(url: string, id: any) {
        // let requestOptions = { headers: this.headers };
        return this.http.delete<any[]>(this.baseUrl + '/' + url + '/' + id, this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
            }));
    }

    put(url: string, id: any) {
        // let requestOptions = { headers: this.headers };
        return this.http.put<any>(this.baseUrl + '/' + url + '/' + id, '', this.requestOptions)
            .pipe(map(result => {
                return result;
            }, (error: any) => {
                return error
            }));
    }

    createStripeCheckoutSession(): Observable<SuccessApiResponse<UrlObj>> {
        return this.http.post<SuccessApiResponse<UrlObj>>(this.baseUrl + '/stripe/createCheckoutSession', null, this.requestOptions);
    }

    uploadfileAWSS3(fileuploadurl: string,file: File): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': file.type });
        return this.http.put(fileuploadurl, file, {headers});
    }

    getTrimbleMileage(dataVersion: string, latLngs: LatLng[]): Observable<TrimbleMileage[]>{
        if(isArrayEmpty(latLngs) || latLngs.length == 1){
            return of();
        }
        let headers = new HttpHeaders({
            'authorization': '1D346515D8570D4F8800369948421D5C'
        });
        let requestOption = {headers};
        const latLng = this.encodeLatLng(latLngs);
        return this.http.get<TrimbleMileage[]>(
            `https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/route/routeReports?stops=${latLng}&reports=Mileage&dataVersion=${dataVersion}`,
            requestOption
        );
    }

    private encodeLatLng(latLngs: LatLng[]): string {
        return encodeURIComponent(
            latLngs.map(latLng => `${latLng.lng},${latLng.lat}`).join(';')
        );
    }

    queryPostalCodes(query?: string, radius?: string): Observable<ApiResponse<PostalCode[]>>{

        const params: {[key: string]: string} = {};

        if(query){
            params['query'] = query;
        }

        if(radius){
            params['radius'] = radius;
        }



        return this.http.get<ApiResponse<PostalCode[]>>(
            `${this.baseUrl}/postalCodes`,
            {
                ...this.requestOptions,
                params
            }
        );
    }
}
