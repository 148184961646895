<div class="text-align-center">
    <pdf-viewer src="/assets/pdf/privacy.pdf" 
        [original-size]="false"
        [autoresize]="true" 
        [fit-to-page]="true"
        [zoom-scale]="'page-fit'"
        [zoom]="2.0"
        class="pdf-page">
    </pdf-viewer>
</div>
