<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="50">
          </span>
            <span class="logo-lg">
              <img src="../../../assets/logo-blue-background.png" alt="" height="70" width="250">
          </span>
        </a>
        <!-- Light Logo-->
        <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="../../../assets/images/logo-sm.png" alt="" height="50">
          </span>
            <span class="logo-lg">
              <img src="../../../assets/logo-blue-background.png" alt="" height="70" width="250">
          </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover" (click)="toggleMobileMenu($event)">
          <i class="ri-record-circle-line"></i>
      </button>
    </div>

    <div id="scrollbar">
        <div class="container-fluid">

            <div id="two-column-menu">
            </div>
            <ngx-simplebar class="sidebar-menu-scroll">
                <ul class="metismenu list-unstyled navbar-nav" id="navbar-nav">

                    <ng-container *ngFor="let item of menuItems">
                        <li *ngIf="!item.hidden"
                            class="nav-item"
                            [class.disabled]="item.disabled"
                            (click)="onClickMenuItem(item)"
                        >
                            <a [routerLink]="item.disabled ? null : item.link" class="side-nav-link-ref nav-link menu-link" routerLinkActive="active">
                                <i class="{{ item.icon }}" *ngIf="item.icon"></i>
                                <span class=""> {{ item.label | translate }} </span>
                                <span class="badge {{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text | translate}}</span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </ngx-simplebar>
        </div>
        <!-- Sidebar -->
    </div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay" (click)="SidebarHide()"></div>
