import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stripe-checkout-overlay',
  templateUrl: './stripe-checkout-overlay.component.html',
  styleUrls: ['./stripe-checkout-overlay.component.scss']
})
export class StripeCheckoutOverlayComponent implements OnInit {
  @ViewChild('stripeCheckoutModal') stripeCheckoutModal!: Element;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  openModal(): void {
    this.modalService.open(this.stripeCheckoutModal);
  }
}
