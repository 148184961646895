<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">First Name: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="firtname-field" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">Last Name: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="firtname-field" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">Title: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="firtname-field" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">Email: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="firtname-field" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">Phone: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="firtname-field" class="form-control"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-1 user-form-label text-align-right">
                        <label for="firtname-field" class="form-label">Permissions: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-8">
                        <table class="table align-middle table-bordered table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Branch</th>
                                    <th scope="col" class="role-column">Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableData">
                                    <th scope="row" *ngIf="data.branch">
                                        {{data.branch}}
                                    </th>
                                    <th scope="row" *ngIf="!data.branch">
                                        <p-treeSelect 
                                            [(ngModel)]="default" 
                                            [options]="nodes4" 
                                            [metaKeySelection]="false" 
                                            selectionMode="single" 
                                            placeholder="Select Item" 
                                            [filter]="true" 
                                            [filterInputAutoFocus]="true"
                                            display="comma"
                                            (onNodeSelect)="selectedNode($event)"
                                            [title]="selectedNodeString"
                                        ></p-treeSelect>
                                    </th>
                                    <th scope="row" *ngIf="data.role">
                                        {{data.role}}
                                    </th>
                                    <th scope="row" *ngIf="!data.role">
                                        <ng-select
                                        class="custom form-control"
                                        [items]="role"
                                        [multiple]="false"
                                        bindLabel="prettyName" 
                                        bindValue="technicalName"
                                        (change)="selectRole($event)"
                                    ></ng-select>
                                    </th>
                                </tr>
                                <!-- <tr>
                                    <th scope="row">
                                        <p-treeSelect 
                                            [(ngModel)]="default" 
                                            [options]="nodes4" 
                                            [metaKeySelection]="false" 
                                            selectionMode="single" 
                                            placeholder="Select Item" 
                                            [filter]="true" 
                                            [filterInputAutoFocus]="true"
                                            display="comma"
                                            (onNodeSelect)="selectedNode($event)"
                                            [title]="selectedNodeString"
                                        ></p-treeSelect>
                                    </th>
                                    <td>
                                        <ng-select
                                        class="custom form-control"
                                        [items]="role"
                                        [multiple]="false"
                                        bindLabel="prettyName" 
                                        bindValue="technicalName"
                                        (change)="selectRole($event)"
                                    ></ng-select>
                                    </td>
                                </tr> -->
                                <tr>
                                    <th scope="row">
                                        <button type="submit" class="btn btn-primary" id="add-btn" (click)="addPermission()">Add Permission</button>
                                    </th>
                                    <td>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" class="btn btn-primary" id="add-btn">Add User</button>
            </div>
        </div>
    </div>
</div>