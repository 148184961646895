import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-branch-scheduling',
  templateUrl: './branch-scheduling.component.html',
  styleUrls: ['./branch-scheduling.component.scss']
})
export class BranchSchedulingComponent implements OnInit {
  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Branch Scheduling'}
  ];
  constructor() { }

  ngOnInit(): void { }


}
