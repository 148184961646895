import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';
import { DatePipe } from '@angular/common';
import { UserProfile } from '../core/services/models/user-profile.model';
import { SuccessApiResponse } from '../core/services/models/models';
import { User } from '../core/services/models/auth.models';

@Component({
  selector: 'app-demo-public-profile',
  templateUrl: './demo-public-profile.component.html',
  styleUrls: ['./demo-public-profile.component.scss']
})
export class DemoComponent implements OnInit {
  slug: any;
  workExperience: any = [];
  profile?: UserProfile;
  statesJson: any;
  isProfilePublic: boolean = false;
  isLoading = true;
  origin = window.location.origin;

  constructor(
    private httpRequest: HttpService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });
    this.slug = this.route.snapshot.params['slug'];
    if(this.slug) {
      this.getUserDetails();
    }
  }

  getUserDetails() {
    this.httpRequest.getUserByIdentifier(this.slug).subscribe(res => {
      if(res) {
        const successRes = <SuccessApiResponse<UserProfile>> res;
        if(!successRes.data.isProfilePublic) {
          this.isProfilePublic = false;
          this.isLoading = false;
        } else {
          this.isProfilePublic = true;
          this.profile = successRes.data;
          this.getPublicWorkExperience(successRes.data.userId);
        }
      }
    })
  }

  getPublicWorkExperience(userId: string) {
    this.httpRequest.getWorkExperienceByUserId(userId).subscribe((data: any) => {
      this.workExperience = data.data;
      this.isLoading = false;
    })
  }

  getPrettyNameByTechName(type: any, techName: string): string | any {
    // implementation goes here
    if(type === 'state') {
      const state = this.statesJson?.find((s: any) => s.techName === techName) ?? [];
      return state ? state.prettyName : null;
    } else if(type === 'country') {
      const countries = [
        {
          "prettyName": "United States",
          "techName": "US"
        },
        {
          "prettyName": "Canada",
          "techName": "CA"
        }
      ];
    
      const country = countries.find(s => s.techName === techName);
      return country ? country.prettyName : null;
    }
  }

  getPrettyDate(date: string) {
    let dateString = date;
    return new DatePipe('en-US').transform(dateString, 'MMMM d, y');
  }

  getPrettySkills(skills: string) {
    return JSON.parse(skills);
  }

}
