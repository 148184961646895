<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label class="form-label">Contract Name: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" class="form-control" required [(ngModel)]="contractName" [ngClass]="{ 'is-invalid': isSubmittedFormOnce && formErrors.name }" (ngModelChange)="onChangeName()" />
                            <div *ngIf="formErrors.name && isSubmittedFormOnce" [ngSwitch]="formErrors.name" class="invalid-feedback" style="display: block;" align="left">
                                <div *ngSwitchCase="'required'">
                                    <span>Enter name</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label class="form-label">Carrier: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" bindLabel="name" bindValue="accountId" [items]="carrierOptions" [clearable]="false" [(ngModel)]="selectedCarrierAccountId" (change)="onChangeCarrier()"></ng-select>
                            <div *ngIf="formErrors.selectedCarrierAccountId && isSubmittedFormOnce" [ngSwitch]="formErrors.selectedCarrierAccountId" class="invalid-feedback" style="display: block;" align="left">
                                <div *ngSwitchCase="'required'">
                                    <span>Select carrier</span>
                                </div>
                                <div *ngSwitchCase="'noCarrierSigners'">
                                    <span>Selected carrier has no signers</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="select-document" class="form-label">Documents: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-multiSelect id="select-document" [(ngModel)]="selectedDocumentNodes" [options]="documentNodes" [scrollHeight]="dropdownScrollHeight" placeholder="Select Documents" display="chip" selectionMode="checkbox" (onChange)="onChangeDocuments()"></p-multiSelect>
                            <div *ngIf="formErrors.selectedDocuments && isSubmittedFormOnce" [ngSwitch]="formErrors.selectedDocuments" class="invalid-feedback" style="display: block;" align="left">
                                <div *ngSwitchCase="'required'">
                                    <span>Select at least one document</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="select-shipper" class="form-label">Shipper Signers: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-multiSelect id="select-shipper" [(ngModel)]="selectedShipperSigners" [options]="shipperNodes" [scrollHeight]="dropdownScrollHeight" placeholder="Select Shippers" display="chip" selectionMode="checkbox" (onChange)="onChangeShipperSigners()"></p-multiSelect>
                            <div *ngIf="formErrors.selectedShipperSigners && isSubmittedFormOnce" [ngSwitch]="formErrors.selectedShipperSigners" class="invalid-feedback" style="display: block;" align="left">
                                <div *ngSwitchCase="'required'">
                                    <span>Select at least one shipper signer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="gap-2 justify-content-end">
                        <button [disabled]="contract" type="button" class="btn btn-primary" id="submit-btn" (click)="createContract()">Submit</button>
                        <div *ngIf="contract" class="text-center mt-3">
                            <span [class.text-success]="contract.status !== 'error'" [class.text-danger]="contract.status === 'error'">
                                <i *ngIf="contract.status !== 'error'" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> {{contract.status}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="contract"><div>Status:</div><pre>{{contract | json}}</pre><div *ngIf="poll.countdownHandler">Polling after {{poll.countdown}} seconds...</div></div> -->