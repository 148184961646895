import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { User } from 'src/app/core/services/models/auth.models';
import { ModeService } from 'src/app/core/services/mode-service/mode.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { Role } from 'src/app/core/services/models/role.model';
import { FEATURES, Permission, PERMISSION_GETTERS, RoleModalObject } from 'src/app/core/services/models/permissions.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { Mode } from 'src/app/core/services/models/mode.model';

@Component({
  selector: 'app-zones-routes',
  templateUrl: './zones-routes-menu.component.html',
  styleUrls: ['./zones-routes-menu.component.scss'],
})
export class ZonesRoutesComponent implements OnInit {
  features = FEATURES;
  roleModalObj?: RoleModalObject;
  permissionGetters = PERMISSION_GETTERS;
  roles: Role[] = [];
  saveLoading = false;
  currentUser!: User;
  activeModal?: NgbModalRef;
  showData: boolean = false;
  private selectedAccountSubscription: Subscription;

  mode: any;
  zoneRouteAllowCapacityToBeSplitByPostalCodes: boolean;
  zoneRouteAllowCapacityToBeSplitByServiceTypes: boolean;
  zoneRouteAllowCapacityPostalCodesToOverlap: boolean;


  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private httpRequest: HttpService,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.mode = JSON.parse(sessionStorage.getItem('selectedMode')!);
    this.getMode();
  }

  getMode() {
    this.httpRequest.getAMode(this.mode.modeId).subscribe((data: any) => {
      this.zoneRouteAllowCapacityToBeSplitByPostalCodes = data.data.zoneRouteAllowCapacityToBeSplitByPostalCodes;
      this.zoneRouteAllowCapacityToBeSplitByServiceTypes = data.data.zoneRouteAllowCapacityToBeSplitByServiceTypes;
      this.zoneRouteAllowCapacityPostalCodesToOverlap = data.data.zoneRouteAllowCapacityPostalCodesToOverlap;
    })
  }

  toggleSwitch(event: any, type: string) {
    const payload: Mode = {
      [type]: event.target.checked,
      name: this.mode.name,
      type: this.mode.type,
      hasServiceAreas: this.mode.hasServiceAreas,
      hasLanes: this.mode.hasLanes
    };
    this.httpRequest.updateMode(this.mode.modeId, payload).subscribe((data: any) => {
      this.getMode();
    })
  }
}
