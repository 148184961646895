import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { AuthService } from '../core/services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private errorService: ErrorService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>>{
    return next.handle(request).pipe(
      tap(() => { }, (response) => {
        if (response instanceof HttpErrorResponse) {               
          if (response.status === 401) {

            sessionStorage.clear();
            window.location.reload();
            
            return;
          }                

          if (response.status === 400 && response.error) {
              this.errorService.addErrors(Array.isArray(response.error) ? response.error : [response.error]);
              return;
          }

          if (response.status >= 500 && response.status < 600 && response.error) {
            this.errorService.addErrors(Array.isArray(response.error) ? response.error : [response.error]);
            return;
          }

          this.errorService.addErrors(['General server error']);
        }

        return throwError(response);
    }));
  }
}
