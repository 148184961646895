<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col w-75">Load Board Name</th>
            <th scope="col w-75">Status</th>
        </tr>
    </thead>
    <tbody *ngIf="loading">
        <tr class="loader-body">
            <td class="placeholder-glow name-td">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow name-td">
                <span class="placeholder col-6"></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="!loading && tableHelper.searchResult.length != 0">
        <tr role="button" *ngFor="let res of tableHelper.searchResult; let i=index;" (click)="openModalDetails(res)">
            <td class="w-75">
                <span>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm bg-light rounded p-1">
                                <img src="assets/images/companies/img-{{ i%9 }}.png" alt="" class="img-fluid d-block">
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="fs-14 mb-1">
                                <span class="text-dark">{{ res.loadTitle }}</span>
                            </h5>
                        </div>
                    </div>
                </span>
            </td>
            <td *ngIf="res.status == 'shipper_withdrew'"> Shipper Withdrew </td>
            <td *ngIf="res.status == 'carrier_withdrew'"> Carrier Withdrew </td>
        </tr>
    </tbody>
</table>
<app-table-pagination *ngIf="!loading" [tableHelper]="tableHelper"></app-table-pagination>