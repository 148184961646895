<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>

        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <!-- <img src="../../../assets/logo-blue-background-login.png" alt=""> -->
                                <img src="../../../assets/logo-blue-background-login.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
                <div class="card sign-up-process-card">
                    <div class="card-body form-steps">
                        <pdf-viewer src="/assets/pdf/terms.pdf" appCustomScrollTerms #appScrollElement="appCustomScrollTerms"
                        [original-size]="false"
                        [autoresize]="true" 
                        [fit-to-page]="true"
                        [zoom-scale]="'page-fit'"
                        [zoom]="2.0"
                        style="width: 100%; height: 45em; display: block; overflow-x: hidden;">
                    </pdf-viewer>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0 text-muted"> © {{year}} Direct To Carrier</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>