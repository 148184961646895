import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';
import { CarrierCostingPlan, MonthlyPlan } from './data';
import { MonthlyPlanModel } from './pricing.model';
export type acceptedType = 'carrier-plan' | 'shipper-plan' | 'broker-plan';


@Component({
  selector: 'app-plans-and-pricing',
  templateUrl: './plans-and-pricing.component.html',
  styleUrls: ['./plans-and-pricing.component.scss']
})
export class PricingComponent implements OnInit {

    breadCrumbItems!: Array<{}>;
    MonthlyPlan!: MonthlyPlanModel[];

    @Output() selectedPlan: EventEmitter<any> = new EventEmitter();
    type: string;

    @Input()
    planFor: acceptedType; 

    constructor(
      private httpRequest: HttpService,
      private route: ActivatedRoute,
      private router: Router
    ) { }

    ngOnInit(): void {
      this._fetchData();
      this.type = this.router.url;
      
    }

    private _fetchData() {
      if(this.planFor === 'carrier-plan') {
        this.MonthlyPlan = CarrierCostingPlan;
      } else {
        this.MonthlyPlan = MonthlyPlan;
      }
    }

    selectPlan(planType: any) {
      this.selectedPlan.emit(planType);
    }

}
