 <!-- start page title -->
 <div class="row">
    <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <div class="row-title-container">
                <ng-container *ngIf="showDefault">
                    <ng-container *ngFor="let breandCrumbItem of breadCrumbItems; index as i">
                        <span class="title-separator" *ngIf="i > 0">&nbsp;>&nbsp;</span>
                        <ng-container *ngIf="breandCrumbItem.url; else link_content">
                            <a class="title-part title-url" [routerLink]="breandCrumbItem.url">{{breandCrumbItem.label}}</a>
                        </ng-container>
                        <ng-template #link_content>
                            <span class="title-part">{{breandCrumbItem.label}}</span>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
  </div>
  <!-- end page title -->
  