import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';

@Component({
  selector: 'ngbd-modal-content-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss'],

})
export class NgbdModalContactSales implements OnInit{
  @Input() defaultZones: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef
   ) {}

  ngOnInit(): void {
    this.initForms();
  }

  close() {
    this.activeModal?.close();
  }

  initForms() {
    // this.serviceAreaForm = this.formBuilder.group({
    //   name: ['', [Validators.required]],
    //   zones: ['', [Validators.required]],
    // });
  }

//   get form() {
//     return this.serviceAreaForm.controls;
//   }

}