import {Component, Input, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'ngbd-modal-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],

})
export class NgbdModalContactInformation implements OnInit{
    @Input() public generalCompanyInfoData: any;
    @Input() public toOpen: any;
    companyInfoForm!: FormGroup;
    files: any[] = [];
    submitted = false;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private router: Router
   ) {}

  ngOnInit(): void {
    this.initForms(this.generalCompanyInfoData);
  }

  initForms(data: any) {
    this.companyInfoForm = this.formBuilder.group({
      legalEntity: [data.legalEntity, [Validators.required]],
      type: [data.type, [Validators.required]],
      dotNumber: [data.dotNumber],
      phone: [data.phone, [Validators.required]],
      email: [data.email, [Validators.required, Validators.email]],
      einNumber: [data.einNumber],
      legalName: [data.legalName, [Validators.required]],
      dbaName: [data.dbaName],
      mcNumber: [data.mcNumber],
      street: [data.street, [Validators.required]],
      city: [data.city, [Validators.required]],
      state: [data.state, [Validators.required]],
      zipCode: [data.zipCode, [Validators.required]],
      hasAuthority: [data.hasAuthority],
      isBroker: [data.isBroker],
      haveDOTNumber: ['false']
    })
  }

  close() {
    this.activeModal?.close();
  }

  get companyInfo() {
    return this.companyInfoForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.companyInfoForm.invalid) {
        return;
    }
    this.activeModal?.close();
    Swal.fire({
        title: 'Thank you for submitting your new business information!',
        text: 'Once verified, your new information will be updated in your company profile.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
    }).then(result => {
    });
  }

}