import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';

// Auth Services
import { AuthService } from '../services/authentication/auth.service';
import { environment } from '../../../environments/environment';
import { PermissionsService } from '../services/permissions/permissions.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private auth: AuthService,
        private permissionsService: PermissionsService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.auth.currentUserValue;
        if (currentUser) {
            // logged in so return true
            // await this.permissionsService.loadAuthedUserPermissions();
            // return this.permissionsService.checkUrlPermission(state.url);
            return true;
        }
        // not logged in so redirect to login page with the return url
        return this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
          .then(() => false);
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      console.log('[AuthGuard] [canActivateChild]', state.url);
      const currentAccount = this.auth.currentAccountSelected;
      if(currentAccount.accountType == 'driver-account' || currentAccount.accountType == 'helper-account' || currentAccount.accountType == 'broker-account') {
        return true;
      } else {
        // check each permission rule
        if (! (await this.canActivate(childRoute, state))) {
          return false
        }

        await this.permissionsService.loadAuthedUserPermissions();
        if (!this.permissionsService.checkUrlPermission(state.url)) {
          return false;
        }

        if (!this.permissionsService.checkUserTypePermission(state.url)) {
          return false;
        }
        
        if (!this.permissionsService.checkPlanTypePermission(state.url)) {
          return false;
        }
      }
      return true;
  }
}
