import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-driver-qualification-files',
  templateUrl: './driver-qualification-files.component.html',
  styleUrls: ['./driver-qualification-files.component.scss']
})
export class DriverQualificationFilesComponent implements OnInit {
  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Driver Qualification Files'}
  ];
  constructor() { }

  ngOnInit(): void { }

  redirectToLogin(){
    window.open('https://directtocarrier.ourdqf.com/', '_blank');
  }

}
