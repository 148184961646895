import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/core/services/http-service';

export type CapacityGroupFormDto = {
  id: string;
  name: string;
  postalCodes: string[];
  capacityRules: CapacityRuleFormDto[];
  editted: boolean;
};

export type CapacityRuleFormDto = {
  day: number;
  startTime: string;
  endTime: string;
  units?: number;
  totalWeight?: number;
  totalVolume?: number;
  stops?: number
 }


@Component({
  selector: 'app-capacity-form',
  templateUrl: './capacity-form.component.html',
  styleUrls: ['./capacity-form.component.scss']
})
export class CapacityFormComponent implements OnInit {
  selectValue: any[] = [
    {
      prettyName: "Monday",
      techName: 0
    },
    {
      prettyName: "Tuesday",
      techName: 1
    },
    {
      prettyName: "Wednesday",
      techName: 2
    },
    {
      prettyName: "Thursday",
      techName: 3
    },
    {
      prettyName: "Friday",
      techName: 4
    },
    {
      prettyName: "Saturday",
      techName: 5
    },
    {
      prettyName: "Sunday",
      techName: 6
    },
  ];
  inputForms: number[] = [1];
  formsNumber = 1;

  skills = new FormArray([]);
  capacityForm!: FormGroup;
  nameZipCodeForm!: FormGroup;
  capForm: any;
  @Output() valueChange = new EventEmitter();
  @Output() formValue = new EventEmitter<CapacityGroupFormDto>();
  hours: any = [];
  @Input() defCapacityForm: any;
  @Input() type: any;
  @Input() public zipCodes: any;
  @Input() public defaultValue?: CapacityGroupFormDto;

  
  // defaultStartTime = "09:00:00";
  
  // defaultEndTime = "09:00:00";
  
  defaultDay = 0;
  
  zipcodesJson: any;

  activeModal?: NgbModalRef;
  // activeModal?: NgbModalRef;
  submitted = false;
  showForm = false;
  @Output() newItemEvent = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    ) {
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hours = [...new Set(jsonData)];
    });
    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    if(this.defaultValue) {
      this.zipCodes = this.defaultValue.postalCodes;
      this.nameZipCodeForm = this.formBuilder.group({
        name: [this.defaultValue.name, [Validators.required]],
        zipCodes: [this.defaultValue.postalCodes, [Validators.required]],
      });

      const capacityRules = this.defaultValue.capacityRules || [];
      if(capacityRules.length === 0){
        this.showForm = true;
        this.capacityForm = this.formBuilder.group({
          capForm: this.formBuilder.array([this.createForm()])
        });
        this.capForm = this.capacityForm.get('capForm') as FormArray;
      }else{
        for(let x = 0; x < capacityRules.length; x++) {
          if(x == 0) {
            this.capacityForm = this.formBuilder.group({
              capForm: this.formBuilder.array([this.createDefForm(capacityRules[x])])
            });
            this.capForm = this.capacityForm.get('capForm') as FormArray;
            this.showForm = true;
          } else {
            this.capForm = this.capacityForm.get('capForm') as FormArray;
            this.capForm.push(this.createDefForm(capacityRules[x]));
          }
        }
      }
    } else {
      this.showForm = true;
      this.capacityForm = this.formBuilder.group({
        capForm: this.formBuilder.array([this.createForm()])
      });
      this.capForm = this.capacityForm.get('capForm') as FormArray;
      this.nameZipCodeForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        zipCodes: [this.zipCodes, [Validators.required]],
      });
    }
  }

  createForm(): FormGroup {
    return new FormGroup({
      'day': new FormControl(0, Validators.required),
      'startTime': new FormControl('09:00:00', Validators.required),
      'endTime': new FormControl('21:00:00', Validators.required),
      'units': new FormControl(1, [Validators.required, Validators.min(1)]),
      'totalWeight': new FormControl(1, [Validators.required, Validators.min(1)]),
      'totalVolume': new FormControl(1,[Validators.required, Validators.min(1)]),
      'stops': new FormControl(1, [Validators.required, Validators.min(1)]),
    })
  }

  createDefForm(data: any): FormGroup {
    return new FormGroup({
      'day': new FormControl(data.day, Validators.required),
      'startTime': new FormControl(data.startTime, Validators.required),
      'endTime': new FormControl(data.endTime, Validators.required),
      'units': new FormControl(data.units, [Validators.required, Validators.min(1)]),
      'totalWeight': new FormControl(data.totalWeight, [Validators.required, Validators.min(1)]),
      'totalVolume': new FormControl(data.totalVolume, [Validators.required, Validators.min(1)]),
      'stops': new FormControl(data.stops, [Validators.required, Validators.min(1)]),
    })
  }

  addCapForm() {
    this.capForm = this.capacityForm.get('capForm') as FormArray
    this.capForm.push(this.createForm())
  }

  removeClearCapForm(i: number) {
    const capForm = this.capacityForm.get('capForm') as FormArray
    if (capForm.length > 1) {
      capForm.removeAt(i)
    } else {
      capForm.reset()
    }
  }

  get form() {
    return this.capacityForm.get('capForm') as FormArray;
  }

  add() {
    this.formsNumber += 1;
    this.inputForms.push(this.formsNumber);
  }

  delete(index: any) {
    this.inputForms.splice(index, 1);
  }

  selectStartTime(event: any, formIndex: any) {
    let index = 0;
    const timeAdder = 48;
    for(let x = 0 ; x < this.hours.length; x++) {
      if(this.hours[x].techName == event.techName) {
        index = x;
      }
    }
    if(index + timeAdder > 95) {
      (<FormArray>this.capacityForm.controls['capForm']).at(formIndex).get('endTime')?.setValue(this.hours[(index + timeAdder) - 96].techName);
    } else {
      (<FormArray>this.capacityForm.controls['capForm']).at(formIndex).get('endTime')?.setValue(this.hours[index + timeAdder].techName);
    }
  }

  save() {
    this.submitted = true;
    if(this.nameZipCodeForm.invalid) {
      return;
    } else {
      let formValue: CapacityGroupFormDto = {
        id: this.defaultValue?.id || this.randomId(),
        name: this.nameZipCodeForm.controls['name'].value,
        postalCodes: this.nameZipCodeForm.controls['zipCodes'].value,
        capacityRules: this.capForm.value,
        editted: true
      }
      this.formValue.emit(formValue)
      this.cancel();
    }
  }

  cancel() {
    this.valueChange.emit(true);
  }

  get formNameZipCode() {
    return this.nameZipCodeForm.controls;
  }

  private randomId(min = 0, max = 99999): string {
    min = Math.ceil(min);
    max = Math.floor(max);
    return "" + Math.floor(Math.random() * (max - min) + min);
  }
}
