import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChattersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  addFullNameKey(user: any) {
    let accountUsers: any = [];
    for(let x = 0 ; x < user.length; x++) {
        user[x]['fullName'] = user[x].firstName + ' ' + user[x].lastName;
        accountUsers.push(user[x]);
    }
    return accountUsers;
  }

  getContractSignerUsers(accountUsers: any, chattersId: any) {
    let chattersUsers: any = [];
    for(let x = 0 ; x < accountUsers.length ; x ++) {
        for(let y = 0; y < chattersId.length ; y++) {
          if(accountUsers[x].userId === chattersId[y].userId) {
            chattersUsers.push(accountUsers[x]);
          }
        }
    }
    return chattersUsers;
  }

  getPayload(chattersUser: any, selectedUserId: any) {
    let payload: any = [selectedUserId];
    for(let x = 0 ; x < chattersUser.length; x++) {
        payload.push(chattersUser[x].userId);
    }
    return payload;
  }

}
