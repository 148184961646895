import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
// import { DecimalPipe } from '@angular/common';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

// Sweet Alert
import Swal from 'sweetalert2';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import { ProposalsModel } from 'src/app/core/services/models/proposals.model';
import { NgbdModalLoadBoardDetails } from '../../../modal/load-board-details/load-board-details-modal.component';


@Component({
  selector: 'app-carrier-load-board-withdrew',
  templateUrl: './withdrew.component.html',
  styleUrls: ['./withdrew.component.scss']
})
export class CarrierLoadBoardWithdrewComponent implements OnInit {
  currentUser: any;
  activeModal?: NgbModalRef;

  tableHelper: TablePaginationHelper<ProposalsModel>;
  loading = false;
  currentProposal ?: ProposalsModel;

  constructor(
    private httpRequest: HttpService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.tableHelper = new TablePaginationHelper(
        {
          loadRecords: (pageRequest) =>{
            return this.httpRequest.paginatedGetFromList(
              ()=>this.httpRequest.getProposalsByStatuses(['shipper_withdrew', 'carrier_withdrew']),
              pageRequest
            ).toPromise()
          }
        }
      );
      this.loadRecords();
  }


  loadRecords(): void {
    this.loading = true;
    this.tableHelper.loadRecords().then(
      () => {
        const records = this.tableHelper.searchResult;
        if(!isArrayEmpty(records)){
          this.currentProposal = records[0];
        }else{
          this.currentProposal = undefined;
        }
        this.loading = false;
      }
    );
  }

  openModalDetails(event: any) {
    this.activeModal?.close();
    this.httpRequest.getLoadById(event.loadId).subscribe(data => {
      let modalRef = this.modalService.open(NgbdModalLoadBoardDetails, { size: 'xl', centered: true, backdrop: true, keyboard : false });
      modalRef.componentInstance.loadData = data.data;
    })
  }


}
