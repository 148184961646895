import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import TrimbleMaps, { GeoJSONSourceRaw } from '@trimblemaps/trimblemaps-js';
import {
  FeaturePointProperties,
  MapsDataSource,
  MapsEventListener,
  MapsLayer
} from 'src/app/core/services/models/trimble-maps.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

const CLASS = '[TrimbleMapComponent]';
const DEBUG = false;
function log(...args: any[]): void {
  if (!DEBUG) {
    return;
  }
  console.log(...args);
}

export type LatLng = {lat: number; lng: number;};

/**
 * This is a shared component for the trimble maps. Please be mindful that other components are using it whenever you edit this component.
 */
@Component({
  selector: 'app-trimble-map',
  templateUrl: './trimble-map.component.html',
  styleUrls: ['./trimble-map.component.scss']
})
export class TrimbleMapComponent implements OnInit, AfterViewInit, OnDestroy {
  isMapsLoaded = false;

  private _sources?: MapsDataSource[];
  private _layers?: MapsLayer[];
  private _listeners?: MapsEventListener[];
  private _routeStops: LatLng[] = [];
  private route?: TrimbleMaps.Route;
  private markers: TrimbleMaps.Marker[] = [];

  @ViewChild('mapEl', {static: true}) mapEl: ElementRef<HTMLDivElement>;

  maps: TrimbleMaps.Map;
  @Input() mapOptions?: Omit<TrimbleMaps.MapOptions, 'container'>;
  @Input() containerStyles: Record<string, unknown> | null = null;
  @Input() mapStyles: Record<string, unknown> | null = null;
  @Input() drawMarkers = false;

  @Input() set routeStops(latLngs: LatLng[] | undefined){
    this._routeStops = latLngs ?? [];
    if (!this.isMapsLoaded) {
      return;
    }
    this.addRoute();
  }

  @Input() set sources(sources: MapsDataSource[] | undefined) {
    const FN = `${CLASS} [set sources]`;
    log(FN, sources);
    this._sources = sources;
    if (this.isMapsLoaded) {
      log(FN, 'updating sources');
      this.addSources();
    } else {
      log(FN, 'map not yet loaded');
    }
  }
  @Input() set layers(layers: MapsLayer[] | undefined) {
    log(`${CLASS} [set layers]`, layers);
    this._layers = layers;
    if (!this.isMapsLoaded) {
      return;
    }

    this.addLayers();
  }
  @Input() set listeners(listeners: MapsEventListener[] | undefined) {
    log(`${CLASS} [set listeners]`, listeners);
    this._listeners = listeners;
    if (!this.isMapsLoaded) {
      return;
    }

    this.addEventListeners();
  }

  constructor() {
  }

  getMapOptions(mapOptions?: Omit<TrimbleMaps.MapOptions, 'container'>): TrimbleMaps.MapOptions {
    return {
      zoom: 3,  // starting zoom
      style: TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
      center: [-98.38, 38.69],  // starting position
      ...(mapOptions || {}),
      container: 'trimble-map'  // container html id
    };
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {   
    this.maps = new TrimbleMaps.Map(this.getMapOptions(this.mapOptions));
    this.maps.on('resize', ()=>{
      this.isMapsLoaded = true;
    });
    this.maps.on('load', () => {
      this.maps.resize();
      //customize your map once loaded
      this.addSources();
      this.addLayers();
      this.addEventListeners();
      this.addRoute();
    });
  }

  ngOnDestroy(): void {
    this.maps.remove();
    // document.getElementById('trimble-map')?.remove();
    this.mapEl?.nativeElement.remove();
  }

  private addEventListeners(): void {
    log(`${CLASS} [addEventListeners]`, this._listeners);
    if (!this._listeners) {
      return;
    }

    this._listeners.forEach(listener => {
      this.maps.on(listener.event, listener.layerId, listener.listenerFn)
    });
  }

  private addSources(): void {
    if (this._sources) {
      // Add GeoJSON data sources to the map
      this._sources.forEach(source => {
        // this.trimbleMap.addSource(source.name, source.data);
        this.addSource(source);
      });

      if (this.drawMarkers) {
        this.updateMarkers();
      }
    }
  }

  private updateMarkers() {
    this.markers.forEach(
      marker => marker.remove()
    );
    this.markers = [];
    this._sources?.forEach(source => {
      const geoJSONSourceRaw = source.data as GeoJSONSourceRaw;
      const featureCollection = geoJSONSourceRaw.data as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

      featureCollection.features.forEach(
        feature => {
          try {
            const properties = feature.properties as FeaturePointProperties;

            const {zipCode, cnt, lng, lat} = properties;
            if (!lng || !lat) {
              return; // do not add marker if no lat/lng
            }

            const svgContent = document.createElement('div');
            svgContent.title = `${zipCode} count`;
            svgContent.innerHTML = `${cnt}`;
            const marker = new TrimbleMaps.Marker({
                element: svgContent
            }).setLngLat({lng, lat})
            .addTo(this.maps);

            this.markers.push(marker);
          } catch (e) {
            console.error('[TrimbleMapsComponent] [updateMarkers] - unable to add marker', feature.properties, e);
          }
        }
      );
    });
  }
  
  private addLayers(): void {
    // Add a layer to draw circles for each point in the data source
    if (this._layers) {
      this._layers.forEach(layer => {
        this.addLayer(layer);
      });
    }
  }

  private addSource(source: MapsDataSource): void {
    const FN = `${CLASS} [addSource]`;
    const existingSource = this.maps.getSource(source.name);
    if (!existingSource) {
      log(FN, 'adding source', source);
      this.maps.addSource(source.name, source.data);
    } else if (existingSource.type === 'geojson') {
      const newData = (source.data as TrimbleMaps.GeoJSONSourceRaw).data;
      if (newData) {
        log(FN, 'updating source', source);
        existingSource.setData(newData);
      }
    }

    
  }

  private addLayer(layer: MapsLayer): void {
    const FN = `${CLASS} [addLayer]`;
    const existingLayer = this.maps.getLayer(layer.layer.id);
    if (existingLayer) {
      log(FN, 'removing layer', layer);
      this.maps.removeLayer(layer.layer.id);
    }

    log(FN, 'adding layer', layer);
    this.maps.addLayer(layer.layer, layer.before);
  }

  private addRoute(){
    this.route?.remove();
    if(!isArrayEmpty(this._routeStops)){
      this.route = new TrimbleMaps.Route({
        routeId: "myRoute",
        stops: this._routeStops
          .map(latLng => new TrimbleMaps.LngLat(latLng.lng, latLng.lat))
      });
      this.route.addTo(this.maps);
    }
  }
}

