import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Rates'
    }
  ];

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit() { }
}
