<app-breadcrumbs *ngIf="isRouted" [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" 
                    class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" 
                    [ngClass]="{'d-none': !isRouted}"
                    role="tablist">
                    <li [ngbNavItem]="0" class="nav-item" (click)="setActiveTab(0);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Info
                        </a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="branchForm">
                                <div class="modal-body">
                                    <div class="row mb-3" *ngIf="parentBranch">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="typeInput" class="form-label">Parent Branch: <span class="text-danger">*</span></label>
                                        </div>
                                        
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required [value]="parentBranch.name" [disabled]="true">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="typeInput" class="form-label">Type: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9" *ngIf="!disableType && branchData">
                                            <ng-select class="custom form-control" [items]="shipperCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'shipper-company' || branchData.type == 'shipper-account' || branchData.type == 'broker-account'"></ng-select>
                                            <ng-select class="custom form-control" [items]="carrierCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'carrier-company' || branchData.type == 'carrier-account'"></ng-select>
                                            <ng-select class="custom form-control" [items]="hqBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'hq'"></ng-select>
                                            <ng-select class="custom form-control" [items]="subsidiaryBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'subsidiary'"></ng-select>
                                            <ng-select class="custom form-control" [items]="organizationBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'organization'"></ng-select>
                                            <ng-select class="custom form-control" [items]="regionBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'region'"></ng-select>
                                            <ng-select class="custom form-control" [items]="branchBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'branch'"></ng-select>
                                            <ng-select class="custom form-control" [items]="storeBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'store'"></ng-select>
                                            <ng-select class="custom form-control" [items]="remoteStoreBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'remote-store'"></ng-select>
                                            <ng-select class="custom form-control" [items]="dcHubCrossDockBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="branchData.type == 'dc' || branchData.type == 'hub' || branchData.type == '3pl-cross-dock' || branchData.type == 'cross-dock'"></ng-select>
                                            <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['type'].errors['required']">Enter Type</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-9" *ngIf="disableType && branchData">
                                            <p id="name-value" *ngIf="branchData.type === 'shipper-account' || branchData.type === 'broker-account'">HQ</p>
                                            <p id="name-value" *ngIf="branchData.type === 'carrier-account'">HQ</p>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="nameInput" class="form-label">Name: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9" *ngIf="isPrefix">
                                            <div class="input-group">
                                                <span class="input-group-text prefix" id="inputGroup-sizing-default">{{ returnPrettyTypeName() }}</span>
                                                <input type="text" id="name-field" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                                            </div>
                                        </div>
                                        <div class="col-lg-9" *ngIf="!isPrefix">
                                            <input type="text" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                                            <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['name'].errors['required']">Enter Name</div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-4" *ngIf="modes.length != 0">
                                        <div class="col-lg-2 padding-top text-align-right label-column">
                                            <label class="form-label">Modes: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9" *ngIf="modesControl">
                                            <div class="field-checkbox padding-top" 
                                                [ngClass]="{ 'is-invalid': submitted && modesControl.value?.length === 0}" 
                                                *ngFor="let mode of modes">
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="groupname" 
                                                            [formControl]="modesControl"
                                                            [value]="mode.modeId"
                                                            (onChange)="modesChanged()"
                                                        >
                                                        </p-checkbox>
                                                            {{ mode.name }}
                                                    </div>
                                                </div>
                                                <div class="row mt-2 ps-4">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="domicileLocations"
                                                            [value]="mode.modeId"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="domicileLocationModeIds"
                                                            (onChange)="changeSelectedModes()">
                                                        </p-checkbox>
                                                        Domicile Location
                                                    </div>
                                                </div>
                                                <div class="row mt-2 ps-4">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="trailerPools"
                                                            [value]="mode.modeId"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="trailerPoolModeIds"
                                                            (onChange)="changeSelectedModes()">
                                                        </p-checkbox>
                                                        Trailer Pool
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && form['modes'].errors"  class="invalid-feedback" style="padding-bottom: 10px;">
                                                <div *ngIf="form['modes'].errors['required']">Pick at least 1 mode</div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label class="form-label">Address: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <app-google-maps-search-box
                                                #googleMapsInput
                                                [isSubmittedOnce]="submitted"
                                                [isRequired]="true"
                                                [isRequiredPostalCode]="true"
                                                (onPlaceChanged)="onAddressChange($event)"
                                                [defaultPlace]="defaultPlace"
                                            ></app-google-maps-search-box>
                                            <br/>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactNameInput" class="form-label">Contact Name: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactName-field" class="form-control" formControlName="contactName" />
                                        </div>
                                    </div>
                        
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactPhoneInput" class="form-label">Contact Phone: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactPhone-field" class="form-control" formControlName="contactPhone" />
                                        </div>
                                    </div>
                        
                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactEmailInput" class="form-label">Contact Email: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactEmail-field" class="form-control" formControlName="contactEmail" />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer" id="add-service-area-md-footer">
                                    <div class="d-flex gap-2">
                                        <span class="clickable-column me-auto d-flex align-items-center text-danger">
                                            <div *ngIf="!loading"
                                                (click)="deleteBranchConfirm()"  
                                                class="inline-block">
                                                Delete  
                                            </div>
                                            <div *ngIf="loading"  
                                                class="spinner-border inline-block save-loading" 
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </span>

                                        <button type="submit" class="btn btn-primary" (click)="proceedNextStep()">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item" (click)="setActiveTab(5);" *ngIf="currentAccount.type !== 'carrier-account'">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Company Information
                        </a>
                        <ng-template ngbNavContent>
                            <app-public-profile></app-public-profile>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1" class="nav-item" (click)="setActiveTab(1);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Services Areas
                        </a>
                        <ng-template ngbNavContent>
                            <app-service-areas [onlyTable]="true"></app-service-areas>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item" (click)="setActiveTab(6);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Lanes
                        </a>
                        <ng-template ngbNavContent>
                            <app-lanes [showBreadCrumbs]="false" [showButtons]="false"></app-lanes>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item" 
                        (click)="setActiveTab(2); promptUpgrade();"
                        [disabled]="isTrial()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Trading Relationships
                        </a>
                        <ng-template ngbNavContent>
                            <app-trading-relationships></app-trading-relationships>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item" 
                        (click)="setActiveTab(3); promptUpgrade();"
                        [disabled]="isTrial()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Instructions
                        </a>
                        <ng-template ngbNavContent>
                            <div *ngIf="!edittingInstructions" class="d-flex">
                                <p class="me-auto" [innerHTML]="instructions | lineBreaks"></p>
                                <i class="ri-edit-fill edit-btn" (click)="editInstructions()"></i>
                            </div>
                            <form *ngIf="edittingInstructions">
                                <div class="modal-body">
                                    <div class="row mb-3">
                                        <div class="col-lg-12 padding-top text-align-right">
                                            <textarea type="text" class="form-control instruction-text-area" placeholder="" [(ngModel)]="instructions"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" 
                                            class="btn btn-light" 
                                            data-bs-dismiss="modal"
                                            (click)="cancelEditInstructions()"
                                        >Cancel</button>
                                        <button type="submit" 
                                            class="btn btn-primary" 
                                            id="add-btn"
                                            (click)="updateInstructions()"
                                        >Save</button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item" 
                        (click)="setActiveTab(4); promptUpgrade();" 
                        [disabled]="isTrial()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Scheduling
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>