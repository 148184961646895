import {Component, Input, OnInit, ChangeDetectorRef, ElementRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import Swal from 'sweetalert2';


@Component({
  selector: 'ngbd-modal-transportation-company-information',
  templateUrl: './transportation-company-information.component.html',
  styleUrls: ['./transportation-company-information.component.scss'],

})
export class NgbdModalTransportationCompanyInformation implements OnInit{
    @Input() public generalCompanyInfoData: any;

  companyInfoForm!: FormGroup;
  submitted = false;
  files: any[] = [];
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef
   ) {}

    ngOnInit(): void {
        this.initForms(this.generalCompanyInfoData);
    }

    initForms(data: any) {
    this.companyInfoForm = this.formBuilder.group({
        legalEntity: [data.legalEntity, [Validators.required]],
        type: [data.type, [Validators.required]],
        dotNumber: [data.dotNumber, [Validators.required]],
        phone: [data.phone, [Validators.required]],
        email: [data.email, [Validators.required, Validators.email]],
        einNumber: [data.einNumber],
        legalName: [data.legalName, [Validators.required]],
        dbaName: [data.dbaName],
        mcNumber: [data.mcNumber, [Validators.required]],
        street: [data.street, [Validators.required]],
        city: [data.city, [Validators.required]],
        state: [data.state, [Validators.required]],
        zipCode: [data.zipCode, [Validators.required]],
        hasAuthority: [data.hasAuthority],
        isBroker: [data.isBroker],
        haveDOTNumber: ['false']
        })
    }

    get companyInfo() {
        return this.companyInfoForm.controls;
    }

    close() {
        this.activeModal?.close();
    }

    onFileDropped($event: any) {
        if(this.files.length > 0) {
            Swal.fire({
                title: 'Error',
                text: 'Cannot upload more than 1 file',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
                }).then(result => {
                
                });
        } else {
            this.prepareFilesList($event);
        }
    }

    fileBrowseHandler(files: any) {
        if(this.files.length > 0) {
            Swal.fire({
                title: 'Error',
                text: 'Cannot upload more than 1 file',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
                }).then(result => {
                
                });
        } else {
            this.prepareFilesList(files.target.files);
        }
    }

    deleteFile(index: number) {
        if (this.files[index].progress < 100) {
        console.log("Upload in progress.");
        return;
        }
        this.files.splice(index, 1);
    }

    uploadFilesSimulator(index: number) {
        setTimeout(() => {
        if (index === this.files.length) {
            return;
        } else {
            const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
                clearInterval(progressInterval);
                this.uploadFilesSimulator(index + 1);
            } else {
                this.files[index].progress += 5;
            }
            }, 200);
        }
        }, 1000);
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
        item.progress = 0;
        this.files.push(item);
        }
        this.fileDropEl.nativeElement.value = "";
        this.uploadFilesSimulator(0);
    }

    formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) {
        return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  
    submit() {
        this.submitted = true;
        if (this.companyInfoForm.invalid) {
            return;
        }
        this.close();
        Swal.fire({
            title: 'Thank you for submitting your new business information!',
            text: 'Once verified, your new information will be updated in your company profile.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
        }).then(result => {
        });
    }

}