<div class="container">
    <form [formGroup]="nameZipCodeForm">
        <div class="row mb-3">
            <div class="col-lg-2 text-align-right">
                <label for="nameInput" class="form-label">Name</label>
            </div>
            <div class="col-lg-9">
                <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && formNameZipCode['name'].errors }" />
                <div *ngIf="submitted && formNameZipCode['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="formNameZipCode['name'].errors['required']">Enter Name</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 text-align-right">
                <label for="nameInput" class="form-label">Zip Codes</label>
            </div>
            <div class="col-lg-9">
                <ng-select class="form-control" formControlName="zipCodes" [ngClass]="{ 'is-invalid': submitted && formNameZipCode['zipCodes'].errors }" [items]="zipCodes" [multiple]="true" [virtualScroll]="true" [closeOnSelect]="false" [hideSelected]="true" [searchWhileComposing]="true" bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom"></ng-select>
                <div *ngIf="submitted && formNameZipCode['zipCodes'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="formNameZipCode['zipCodes'].errors['required']">Enter ZIP Code</div>
                </div>
            </div>
        </div>
    </form>
</div>
<form [formGroup]="capacityForm" *ngIf="showForm">
    <div formArrayName="capForm" class="cap-form">
        <ng-container *ngFor="let control of form.controls; let i = index">
            <div class="capacity-form-container" [formGroupName]="i">
                <div>
                    <label *ngIf="i == 0" for="day">Day</label>
                    <ng-select id="day" formControlName="day" class="form-control" [items]="selectValue" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" bindLabel="prettyName" bindValue="techName" [clearable]="false"></ng-select>
                </div>
                <div class="div-time">
                    <label *ngIf="i == 0" for="start-time">Start Time</label>
                    <ng-select for="start-time" [items]="hours" class="form-control" bindLabel="prettyName" formControlName="startTime" bindValue="techName" dropdownPosition="bottom" [clearable]="false" (change)="selectStartTime($event, i)"></ng-select>
                </div>
                <div class="div-time">
                    <label *ngIf="i == 0" for="end-time">End Time</label>
                    <ng-select for="end-time" [items]="hours" class="form-control" bindLabel="prettyName" formControlName="endTime" bindValue="techName" dropdownPosition="bottom" [clearable]="false" [disabled]="true"></ng-select>
                </div>
                <div class="div-other">
                    <label *ngIf="i == 0">Units</label>
                    <input type="number" class="form-control" formControlName="units" required type="text" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                </div>
                <div class="div-other">
                    <label *ngIf="i == 0">Total Weight (lb)</label>
                    <input type="text" class="form-control" formControlName="totalWeight" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
                </div>
                <div class="div-other">
                    <label *ngIf="i == 0">Total Volume (cu in)</label>
                    <input type="text" class="form-control" formControlName="totalVolume" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
                </div>
                <div class="div-other">
                    <label *ngIf="i == 0">Stops</label>
                    <input type="number" class="form-control" formControlName="stops" required type="text" onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                </div>
                <div class="div-delete">
                    <button *ngIf="i != 0" (click)="removeClearCapForm(i)" class="btn btn-sm btn-danger remove-item-btn" style="display: inline-block;">Delete</button>
                </div>
                <br>
                <div>
                    <button *ngIf="capForm.value.length == i + 1" (click)="addCapForm()" class="btn btn-sm btn-primary edit-item-btn" style="display: inline-block; margin-right: 10px;">Add</button>
                </div>
            </div>
        </ng-container>
    </div>
</form>
<div class="hstack gap-2 justify-content-end" id="footer-cap-button">
    <button type="button" class="btn btn-light" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="save()" id="add-btn">Save</button>
</div>