<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <button [disabled]="accountType === 'carrier-account'" type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" routerLink="/pages/create-contract">
                            <i class="ri-add-line align-bottom me-1"></i> Add Contract </button>
                    </div>
                </div>
                <div class="table-responsive table-card mt-3 mb-1">
                    <p-treeTable *ngIf="showData" #tt [value]="files" [columns]="cols" [lazy]="lazy" [loading]="loading" [scrollable]="true" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-treetable-sm">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style.width]="col.width">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" ttResizableColumn>
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                            <tr (click)="goToViewContract(rowData.contractId)" class="pointer">
                                <td *ngFor="let column of columns">
                                    <span [ngClass]="{'text-primary text-decoration-underline pointer' : column.field === 'name'}">{{rowData[column.field]}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                    <table class="table align-middle table-nowrap mt-3 mb-1" *ngIf="!showData">
                        <thead class="table-light">
                            <tr>
                                <th data-sort="name">Name</th>
                                <th data-sort="zoneName">Sender</th>
                                <th data-sort="zoneName">Receiver</th>
                                <th data-sort="zoneName">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="loader-body">
                                <td class="placeholder-glow name-td">
                                    <span class="placeholder col-6"></span>
                                </td>
                                <td class="placeholder-glow name-td">
                                    <span class="placeholder col-6"></span>
                                </td>
                                <td class="placeholder-glow name-td">
                                    <span class="placeholder col-6"></span>
                                </td>
                                <td class="placeholder-glow name-td">
                                    <span class="placeholder col-6"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>