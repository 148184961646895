import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BillingProfile } from 'src/app/core/services/models/billing-profile.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { getProductId } from 'src/app/core/services/models/invoice.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngbd-modal-content-buy-licenses',
  templateUrl: './buy-licenses.component.html',
  styleUrls: ['./buy-licenses.component.scss'],

})
export class NgbdModalBuyLicenses implements OnInit{
//   @Input() defaultZones: any = [];
  licenseForm!: FormGroup;
  submitted = false;

  driverOnlyTotal = 0;
  trucks = 0;
  driverWithHelperTotal = 0;
  driverTotal = 0;
  truckTotal = 0;
  isLoading = false;
  invoiceSent = false;

  providerUrl = '';

  billingProfiles: BillingProfile[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public auth: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef,
    private router: Router
   ) {}

  ngOnInit(): void {
    this.httpRequest.listBillingProfiles().subscribe(
      res => {
        
        const successRes = <SuccessApiResponse<BillingProfile[]>> res;
        this.billingProfiles = successRes.data;
        if(this.billingProfiles.length){
          const billingProfileId = this.billingProfiles[0].billingProfileId;
          this.initForms(billingProfileId);
        }else{
          this.initForms();
        }
      }
    );
  }

  close(update:boolean) {
    this.activeModal?.close(update);
      // let currentUrl = this.router.url;
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //     this.router.navigate([currentUrl]);
      // });
  }

  initForms(billingProfileId?: string) {
    this.licenseForm = this.formBuilder.group({
      // driverOnly: [''],
      // driverWithHelper: [''],
      quantity: ['', Validators.required],
      billingProfileId: [billingProfileId, Validators.required]
    });
  }

  toNumber(str: string): number {
    if(str){
      return Number(str);
    }
    return 0;
  }

  get form() {
    return this.licenseForm.controls;
  }

  changeDriverOnly(event: any) {
    this.driverOnlyTotal = ((Number(event.target.value)) * 150); 
    this.driverTotal = this.driverOnlyTotal + this.driverWithHelperTotal;
    this.trucks = ((Number(event.target.value)) * 150); 
    this.truckTotal = this.trucks + this.driverWithHelperTotal;
  }

  changeDriverWithHelper(event: any) {
    this.driverWithHelperTotal = ((Number(event.target.value)) * 750);
    this.driverTotal = this.driverOnlyTotal + this.driverWithHelperTotal;
    this.truckTotal = this.trucks + this.driverWithHelperTotal;
  }

  _keyUp(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  sendInvoice() {

    if(this.licenseForm.invalid){
      return;
    }

    const {quantity, billingProfileId} = this.licenseForm.value;
    this.isLoading = true;
    this.httpRequest.postInvoice(
      billingProfileId,
      {
        lineItems: [{
          productId: getProductId(this.auth.currentAccountSelected.accountType)!,
          quantity: Number(quantity)
        }]
      }
    ).subscribe(
      res => {
        this.isLoading = false;
        this.activeModal.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully created invoice.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        });
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to invoice for paid plan: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );

    // this.isLoading = true;
    // let dataToSend: any = {
    //   lineItems: ''
    // };
    // let lineItems = [];
    // if(this.licenseForm.controls['driverOnly'].value != 0) {
    //   let data = {
    //     productId: "150805db-92c3-4ad2-8b39-92d2fb2d809b",
    //     quantity: this.licenseForm.controls['driverOnly'].value
    //   };
    //   lineItems.push(data);
    // }
    // if(this.licenseForm.controls['driverWithHelper'].value != 0) {
    //   let data = {
    //     productId: "d176adc8-7035-479e-a2bd-f1487e681621",
    //     quantity: this.licenseForm.controls['driverWithHelper'].value
    //   };
    //   lineItems.push(data);
    // }
    // dataToSend.lineItems = lineItems;
    // if(dataToSend.lineItems.length != 0) {
    //   this.httpRequest.buyLicense(dataToSend).subscribe((data) => {
    //     this.providerUrl = data.data.providerURL;
    //     this.isLoading = false;
    //     this.invoiceSent = true;
    //     // this.activeModal.close();
    //   }, error => {
    //     this.isLoading = false;
    //   })
    // } else {
      
    // }
    
  }

  redirectToLicenses() {
    if(this.router.url != '/pages/settings') {
      this.router.navigateByUrl('/pages/settings', {state: {activeTab: 6}});
      this.close(true);
    } else {
      this.router.navigateByUrl('/pages/settings', {state: {activeTab: 6}});
      this.close(true);
      this.reloadComponent();
    }
  }

  redirectToInvoices() {
    if(this.router.url != '/pages/settings') {
      this.router.navigateByUrl('/pages/settings', {state: {activeTab: 5}});
      this.close(true);
    } else {
      this.router.navigateByUrl('/pages/settings', {state: {activeTab: 5}});
      this.close(true);
      this.reloadComponentInvoices();
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, {state: {activeTab: 6}});
  }

  reloadComponentInvoices() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, {state: {activeTab: 5}});
  }

  getActionButtonDisplay(): string { 

    const quantityGreaterThan20 = this.toNumber(this.licenseForm?.value?.quantity) > 20;

    const billingProfile = this.billingProfiles.find(
      billingProfile => billingProfile.billingProfileId === this.licenseForm?.value?.billingProfileId
    );
    const noCreditCard = !billingProfile?.stripeCustomerId;

    return  quantityGreaterThan20 || noCreditCard ? 'Send Invoice': 'Process Credit Card';

  }

}