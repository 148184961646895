<table class="table table-sm table-bordered table-striped text-center">
	<thead>
		<tr>
			<th scope="col">
				Id
			</th>
			<th scope="col">
				Name
			</th>
			<th scope="col">
				Price
			</th>
		</tr>
	</thead>
	<tbody cdkDropList (cdkDropListDropped)="drop($event)">
		<tr cdkDrag cdkDragLockAxis="y" *ngFor="let data of dataSource">
			<td cdkDragHandle>{{data.id}}</td>
			<td>{{data.name}}</td>
			<td>{{data.price}}</td>

			<!-- Preview -->
			<div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
				{{data.name}}
			</div>
		</tr>
	</tbody>
</table>

<h2>PDF Input Demo</h2>
<pre>{{formData | json}}</pre>
<ngx-extended-pdf-viewer 
  [src]="'/assets/pdf/ACORD_Certificate_of_Insurance.pdf'"
  [showToolbar]="false"
  [zoom]="'page-width'"
  [formData]="formData"
  (formDataChange)="formData = $event">
</ngx-extended-pdf-viewer>


<h1>TinyMCE 6 Angular Demo</h1>
<editor
  [(ngModel)]="html"
  apiKey="txbcanytoasuhpzdz7k4enu2mtowefxvuldixj4byfd4zh38"
  [init]="{
    selector: 'textarea',
    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    mergetags_list: [
      { value: 'First.Name', title: 'First Name' },
      { value: 'Email', title: 'Email' },
    ],
    content_css: 'document',
    content_style: 'body { min-height: 1056.25px; margin-bottom: 1rem;}',
    height: '100vh' 
  }"
></editor>
<br/>
<h1>Sync's up with TinyMCE</h1>
<textarea [(ngModel)]="html" style="width: 100%;height: 12em;"></textarea>

<br/>
<hr/>
<br/>

<h1>Stripe Checkout</h1>
<div id="stripe-checkout">
  <div class="product">
    <img src="https://i.imgur.com/EHyR2nP.png" alt="The cover of Stubborn Attachments" />
    <div class="description">
      <h3>Stubborn Attachments</h3>
      <h5>$20.00</h5>
    </div>
  </div>
  <button type="button" (click)="redirectToStripeSessionUrl()">Setup</button>
</div>

<hr/>

<h1>Excel to JSON (1 Header Row, 1st Column Is The Key, Other Columns Are The Values)</h1>
<app-excel-data></app-excel-data>

<h1>Zip Code Picker</h1>
<app-zipcode-picker></app-zipcode-picker>

<h1>Google Maps AutoComplete Input</h1>
<app-google-maps-autocomplete [addressTypes]="['address']" (onPlaceChanged)="onPlaceChanged($event)"></app-google-maps-autocomplete>
<pre>{{place | json}}</pre>

<h1>CAPACITY</h1>
<app-capacity-form></app-capacity-form>

<div class="container">
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="select('one')" [ngClass]="{'selected' : optionOne, 'unselected': !optionOne}">Option 1</button>
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="select('two')" [ngClass]="{'selected' : optionTwo, 'unselected': !optionTwo}">Option 2</button>
</div>

<h1>Org Chart</h1>
<label>Max Level:</label>
<input type="text" pInputText [(ngModel)]="orgChartMaxLevel"/>
<button (click)="initOrgChart()" class="btn btn-sm btn-primary edit-item-btn" style="display: inline-block; margin-left: 10px;">Generate</button>
<br/>
<label>Preserve space:</label>
<input
  type="checkbox"
  [(ngModel)]="preserveSpace"
  [ngModelOptions]="{standalone: true}"/> `
<div id="orgchart-sample-container">
  <p-organizationChart
    [value]="orgChartData"
    [preserveSpace]="preserveSpace"
  >
    <ng-template let-node pTemplate="default">
      <p-checkbox
        name="orgchartname"
        [value]="node"
        [(ngModel)]="orgChartSelectedNodes"
        (onChange)="onOrgChartCheckBoxChange($event)"
      ></p-checkbox> {{node.label}}
    </ng-template>
  </p-organizationChart>
</div>

<label>Preserve space:</label>
<input
  type="checkbox"
  [(ngModel)]="preserveSpace"
  [ngModelOptions]="{standalone: true}"/> `

<div id="orgchart-sample-container">
  <p-organizationChart
    [value]="orgChartData2"
    [preserveSpace]="preserveSpace"
  >
    <ng-template let-node2 pTemplate="default">
      <p-checkbox
        name="orgchartname"
        [value]="node2"
        [(ngModel)]="orgChartSelectedNodes2"
        (onChange)="onOrgChartCheckBoxChange2($event)"
      ></p-checkbox> {{node2.label}}
    </ng-template>
  </p-organizationChart>
</div>