import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../core/services/models/models';
import { FMCSA } from '../core/services/models/fmcsa.model';

const FORM_SUBMIT_URL = environment.signup_submit_url;
const VERIFY_SIGNUP_URL = environment.verify_signup_url;

export const CarrierOrShipperType = {
  carrier: 'carrier',
  shipper: 'shipper'
}
export type ECarrierOrShipperType = typeof CarrierOrShipperType[keyof typeof CarrierOrShipperType];

export interface SignupFormData {
  type: ECarrierOrShipperType,
  dbaName: string,
  legalName: string,
  dotNumber: number,
  mcNumber?: number,
  hasAuthority: boolean,
  isFleet: boolean,
  isBroker: boolean,
  street: string,
  city: string,
  state: string,
  zipCode: string,
  firstName: string,
  lastName: string,
  title: string,
  email: string,
  phone: string,
  legalEntity: string,
  shipperType: string,
  password: string,
  einNumber: string
}

export interface VerifyTokenResponse {
  isFleet: boolean;
}

const HTTP_HEADERS = {
  headers: {
    'Content-Type': 'application/json'
  }
};

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(
    private httpClient: HttpClient
  ) { }

  submitSignup(data: SignupFormData): Observable<unknown> {
    // return of(data);
    return this.httpClient.post<unknown>(FORM_SUBMIT_URL, data, HTTP_HEADERS);
  }

  verifyToken(token: string): Observable<ApiResponse<VerifyTokenResponse>> {
    // return of({
    //   isVerified: true,
    //   isFleet: true
    // });

    // TODO: this is a simulated response. Remove this once verify signup api is working
    // if (token === '1') {
    //   return of({
    //     error: false,
    //     data: {
    //       isFleet: false
    //     }
    //   });
    // } else if (token === '2') {
    //   return of({
    //     error: false,
    //     data: {
    //       isFleet: true
    //     }
    //   });
    // }

    return this.httpClient.post<ApiResponse<VerifyTokenResponse>>(`${VERIFY_SIGNUP_URL}/${token}`, {}, HTTP_HEADERS);
  }

  getCarrierSubTypes(fmcsa: any, legalEntity: string, fleetType?: string) {
    if(fmcsa.isFleet) {
        if(legalEntity === 'sole-proprietor' && (fleetType === null || fleetType === '')) {
            return 'error this is not allowed';
        } else if(fleetType === 'Only IC Drivers') {
            return 'independent-master-carrier';
        } else if(fleetType === 'Only W2 Drivers') {
            return 'master-carrier-fleet';
        } else {
            return 'master-carrier-vendor';
        }
    } else {
        if(legalEntity === 'sole-proprietor') {
            return 'independent-carrier';
        } else {
            return 'slmc'
        }
    }
  }
}
