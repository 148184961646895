import { capitalizeFirstLetter, truthyFilter } from 'src/app/core/utils/commons';
import { CreateRolePayload, UpdateRolePayload } from '../http-service';
import { Role } from './role.model';

export const PERMISSION_BIT = {
  Read: 1,
  Create: 2,
  Update: 4,
  Delete: 8,
} as const;
export const PERMISSIONS_KEYS = ['*', 'branches', 'users', 'roles', 'modes', 'contracts'] as const;
export const FEATURES = PERMISSIONS_KEYS.map(a => capitalizeFirstLetter(a)) as PermissionsGetter[];
export type PermissionsJson = {
  [key in typeof PERMISSIONS_KEYS[number]]: number;
};
type InternalPermissions = {
  [key in keyof PermissionsJson]: Permission;
};

export const PERMISSION_GETTERS: (keyof Permission)[] = ['Read', 'Create', 'Update', 'Delete'];
export class Permission {
  private _integerValue?: number;
  private _allPermission?: Permission;

  constructor(integerValue?: number, allPermission?: Permission) {
    this._integerValue = integerValue;
    this._allPermission = allPermission;
  }

  get integerValue(): number | undefined {
    return this._integerValue;
  }
  set integerValue(value: number | undefined) {
    this._integerValue = value;
  }

  get ReadNoAll(): boolean {
    return this._getPermission(PERMISSION_BIT.Read)
  }
  get Read(): boolean {
    return this._allPermission?.Read || this.ReadNoAll;
  }
  set Read(access: boolean) {
    this._setPermission(access, PERMISSION_BIT.Read);
  }

  get CreateNoAll(): boolean {
    return this._getPermission(PERMISSION_BIT.Create);
  }
  get Create(): boolean {
    return this._allPermission?.Create || this.CreateNoAll;
  }
  set Create(access: boolean) {
    this._setPermission(access, PERMISSION_BIT.Create);
  }

  get UpdateNoAll(): boolean {
    return this._getPermission(PERMISSION_BIT.Update);
  }
  get Update(): boolean {
    return this._allPermission?.Update || this.UpdateNoAll;
  }
  set Update(access: boolean) {
    this._setPermission(access, PERMISSION_BIT.Update);
  }

  get DeleteNoAll(): boolean {
    return this._getPermission(PERMISSION_BIT.Delete);
  }
  get Delete(): boolean {
    return this._allPermission?.Delete || this.DeleteNoAll;
  }
  set Delete(access: boolean) {
    this._setPermission(access, PERMISSION_BIT.Delete);
  }

  getNoAll(getter: (keyof Permission)): boolean {
    return this[`${getter}NoAll` as (keyof Permission)] as boolean;
  }

  private _getPermission(permissionBit: number): boolean {
    if (this.integerValue === undefined) {
      return false;
    }
    return !!(this.integerValue & permissionBit);
  }

  private _setPermission(access: boolean, permissionBit: number): void {
    if (access) {
      this._integerValue = (this._integerValue || 0) | permissionBit;
    } else {
      this._integerValue = (this._integerValue || 0) & ~permissionBit;
    }
  }
}

export type PermissionsGetter = Exclude<keyof Permissions, 'json'>;
export class Permissions {
  private _permissions: InternalPermissions;

  constructor(json: Partial<PermissionsJson>) {
    const allPermission = new Permission(json['*']);
    this._permissions = PERMISSIONS_KEYS.reduce((obj, key) => {
      return {
        ...obj,
        [key]: key === '*' ? allPermission : new Permission(json[key], allPermission)
      };
    }, {} as InternalPermissions);
  }

  get All(): Permission {
    return this['*'];
  }

  get '*'(): Permission {
    return this._permissions['*'];
  }

  get Branches(): Permission {
    return this._permissions.branches;
  }

  get Users(): Permission {
    return this._permissions.users;
  }

  get Roles(): Permission {
    return this._permissions.roles;
  }

  get Modes(): Permission {
    return this._permissions.modes;
  }

  get Contracts(): Permission {
    return this._permissions.contracts;
  }

  get json(): Partial<PermissionsJson> {
    return PERMISSIONS_KEYS.reduce((obj, key) => {
      if (this._permissions[key].integerValue === undefined) {
        return obj;
      }

      return {
        ...obj,
        [key]: this._permissions[key].integerValue
      };
    }, {} as PermissionsJson);
  }
}

export const FEATURE_VALUES = ['Read', 'Create', 'Update', 'Delete'] as const;
export class PermissionsCheckboxes {
  featuresCheckboxValues: {
    [key in typeof FEATURES[number]]: (typeof FEATURE_VALUES[number])[];
  };

  constructor(permissionSetArray: Partial<PermissionsJson>[] = [{}]) {
    this.mergePermissionSets(permissionSetArray);
  }

  private mergePermissionSets(permissionSetArray: Partial<PermissionsJson>[]): void {
    this.mergePermissions(permissionSetArray.map(a => new Permissions(a)));
  }

  private mergePermissions(permissionsArray: Permissions[]): void {
    this.featuresCheckboxValues = FEATURES.reduce((obj, key) => {
      return {
        ...obj,
        [key]: FEATURE_VALUES.map(v => permissionsArray.some(permissions => permissions[key] && permissions[key][`${v}NoAll`]) ? v : undefined).filter(truthyFilter)
      };
    }, {} as PermissionsCheckboxes['featuresCheckboxValues'])
  }

  get permissionSet(): Partial<PermissionsJson> {
    const permissions = new Permissions({});
    FEATURES.forEach(key => {
      this.featuresCheckboxValues[key].forEach(v => {
        permissions[key][v] = true;
      })
    });
    return permissions.json;
  }
}
class EditRoleModalContent extends PermissionsCheckboxes {
  role: Pick<Role, 'name' | 'roleId' | 'permissionSet'>;

  constructor(role: Pick<Role, 'name' | 'roleId' | 'permissionSet'>) {
    super([role.permissionSet]);
    this.role = role;
  }
};
class NewRoleModalContent extends PermissionsCheckboxes {
  role: Pick<Role, 'name'>;
  constructor(role: Pick<Role, 'name'>) {
    super();
    this.role = role;
  }
};

export class RoleModalObject {
  private _content: EditRoleModalContent | NewRoleModalContent;
  private _origRole?: Role;
  private _errors: Partial<{
    name: {
      required: boolean;
    };
  }> = {};

  constructor(role?: Role) {
    this._origRole = role;
    if (role) {
      this._content = new EditRoleModalContent(role);
    } else {
      this._content = new NewRoleModalContent({name: ''});
    }
  }

  get role(): (EditRoleModalContent | NewRoleModalContent)['role'] {
    return this._content.role;
  }

  get featuresCheckboxValues(): PermissionsCheckboxes['featuresCheckboxValues'] {
    return this._content.featuresCheckboxValues;
  }

  get mode(): 'Add' | 'Edit' {
    return this.isEditRole() ? 'Edit' : 'Add';
  }

  isEditRole(role?: (EditRoleModalContent | NewRoleModalContent)['role']): role is EditRoleModalContent['role'] {
    return !!((role || this._content.role) as EditRoleModalContent['role']).roleId;
  }

  isAddRole(role?: (EditRoleModalContent | NewRoleModalContent)['role']): role is NewRoleModalContent['role'] {
    return !this.isEditRole(role);
  }

  get permissionSet(): Partial<PermissionsJson> {
    return this._content.permissionSet;
  }

  get updatePayload(): {
    payload: UpdateRolePayload
  } & Pick<Role, 'roleId'> {
    const { role, permissionSet} = this;
    if (!this.isEditRole(role)) {
      throw new Error('Not in Edit mode');
    }

    const response: {
      payload: UpdateRolePayload
    } & Pick<Role, 'roleId'> = {
      payload: {
        permissionSet,
      },
      roleId: role.roleId
    };
    // if (!this._origRole || this._origRole.name !== this.role.name) {
      response.payload.name = this.role.name;
    // }
    return response;
  }

  get createRolePayload(): CreateRolePayload {
    return {
      permissionSet: this.permissionSet,
      name: this.role.name
    };
  }

  get errors(): Partial<{
    name: {
      required: boolean;
    };
  }> {
    return this._errors;
  }

  validate(): boolean {
    if (!this.role.name) {
      this._errors.name = {
        required: true
      };
      return false;
    } else {
      delete this._errors.name;
    }
    return true;
  }
}
