import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ServiceType } from 'src/app/core/services/models/service-type.model';
import { DAYS, DayType, generateZoneColor } from 'src/app/core/utils/commons';
import { PrettyTechName } from 'src/app/core/services/models/pretty-technical-name';
import { InputZipCodeGroup } from 'src/app/shared/mapbox-zipcode-picker/mapbox-zipcode-picker.component';
import { isValidZipCode } from 'src/app/core/utils/zip-code-utils';
import { ServiceTypeTruckTrailerCombo, ServiceTypeTruckTrailerComboUtil } from 'src/app/core/services/models/service-type-truck-trailer-combo.model';
import { HttpService } from 'src/app/core/services/http-service';
import { SuccessApiResponse, TrailerTypeModel, TruckTypeModel } from 'src/app/core/services/models/models';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { AccountPlan } from 'src/app/core/services/models/account-plan.model';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbdModalBuyLicenses } from 'src/app/pages/settings/licenses/modals/buy-licenses/buy-licenses.component';

type UVWS = {
  u: number;
  w: number;
  v: number;
  s: number;
};

export type Capacity = {
  days?: DayType[];
  startTime?: string;
  endTime?: string;
  units?: number;
  totalWeight?: number;
  totalVolume?: number;
  stops?: number;
};

@Component({
  selector: 'modal-app-save-capacity',
  templateUrl: './modal-save-capacity.component.html',
  styleUrls: ['./modal-save-capacity.component.scss']
})
export class NgbdModalSaveCapacity implements OnInit , AfterViewInit {
  readonly separatorExp = /,| |\n|\r\n/;
  @Input()
  serviceTypes: ServiceType[] = [];
  @Input()
  zipCodes: string[] = [];
  @Input()
  truckTrailerCombo: ServiceTypeTruckTrailerCombo;
  @Output()
  onCapacities = new EventEmitter<Capacity[]>();
  
  vehicleName: string;
  truckType?: TruckTypeModel;
  trailerType?: TrailerTypeModel;
  private _zipCodeGroups: InputZipCodeGroup[] = [];
  selectedZipCodeGroup?: InputZipCodeGroup;
  currentEditZipCodeIndex?: number;
  activeTab = 0;
  activeTab1 = 0;
  activeTab2 = 0;
  capacityTable:{
    total: UVWS;
    used: {[key: string]: UVWS};
    unused: {[key: string]: UVWS}
  } = {
    total: {u: 0,w: 0,v: 0,s: 0},
    used: {},
    unused: {}
  };
  dayOptions: DayType[] = [...DAYS];
  currentEditCapacityIndex?: number;
  currentSaveCapacityClicked = false;
  zipCodeGroupId_capacitiesMap: Map<string, Capacity[]> = new Map();
  hours: PrettyTechName[] = [];
  saveLoading = false;

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  isPaidPlan: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private clipboard: Clipboard,
    private el: ElementRef,
    private httpService: HttpService,
    private authService: AuthService,
    private modalService: NgbModal
  ) { }

  displayDaysInOrder(selectedDays: string[] = []): string{
    return this.dayOptions.filter(day => selectedDays.includes(day))
      .join('/');
  }

  ngOnInit(): void {
    this.zipCodeGroups.push({
      id: 'Zone Capacity 1',
      color: generateZoneColor(0),
      zipCodes: [...this.zipCodes]
    });
    this.selectedZipCodeGroup = this.zipCodeGroups[0];
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hours = [...new Set(jsonData)] as PrettyTechName[];
    });
    this.zipCodeGroupId_capacitiesMap.set(this.selectedZipCodeGroup.id, []);
    this.computeCapacityTable();
    this.vehicleName = ServiceTypeTruckTrailerComboUtil.getPrettyName(this.truckTrailerCombo);
    const {truckType, trailerType1} = this.truckTrailerCombo;
    if(truckType){
      this.httpService.getTruckType(truckType).subscribe(
        res => {
          const successRes = <SuccessApiResponse<TruckTypeModel>> res;
          this.truckType = successRes.data;
          this.initializeTruckTypeTotal();
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Error fetching truck type: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    }
    if(trailerType1){
      this.httpService.getTrailerType(trailerType1).subscribe(
        res => {
          const successRes = <SuccessApiResponse<TrailerTypeModel>> res;
          this.trailerType = successRes.data;
          this.initializeTrailerTypeTotal();
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Error fetching trailer type: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    }
    this.subscriptions.push(
      this.authService.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
          this.isPaidPlan = this.currentAccountPlan.status === 'paid';
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  deleteCapacity(ndx: number){
    this.zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id)!.splice(ndx, 1); 
    this.currentEditCapacityIndex = undefined;
    this.computeCapacityTable();
  }

  addCapacity(){
    this.currentSaveCapacityClicked = false; 
    if(!this.zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id)){
      this.zipCodeGroupId_capacitiesMap.set(this.selectedZipCodeGroup!.id, []);
    }
    this.zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id)!.push({}); 
    this.currentEditCapacityIndex=this.zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id)!.length - 1;
  }

  private initializeTruckTypeTotal(): void{
    if(!this.truckType){
      return;
    }
    this.capacityTable.total.w += this.truckType.capacityWeight;
    this.capacityTable.total.v += Number(this.truckType.capacityVolume);
    this.capacityTable.total.u = 12;
    this.capacityTable.total.s = 16;
    this.computeCapacityTable();
  }

  private initializeTrailerTypeTotal(): void{
    if(!this.trailerType){
      return;
    }
    this.capacityTable.total.w += this.trailerType.capacityWeight;
    this.capacityTable.total.v += Number(this.trailerType.capacityVolume);
    this.computeCapacityTable();
  }

  ngAfterViewInit(){
    this.focusOnZipChipsInput();
  }

  focusOnZipChipsInput(): void {
    const inputElement = <HTMLElement>this.el.nativeElement
    .querySelector('#zip-chips .p-chips-input-token input');
    inputElement.click();
  }

  addZipCodeGroups() {
    this.zipCodeGroups = [
      ...this._zipCodeGroups,
      {
        id: '',
        color: generateZoneColor(this._zipCodeGroups.length),
        zipCodes: []
      }
    ];
    this.currentEditZipCodeIndex = this._zipCodeGroups.length - 1;
  }

  saveZipCodeGroup(zipCodeGroupId: string, newId: string): void {
    this.zipCodeGroups = this.zipCodeGroups.map(
      group => {
        if(group.id === zipCodeGroupId){
          group.id = newId
        }
        return group;
      }
    )

    this.currentEditZipCodeIndex = undefined;
  }

  deleteZipCodeGroup(zipCodeGroupId: string): void {
    this.zipCodeGroups = this.zipCodeGroups.filter(group => group.id !== zipCodeGroupId);
    this.selectedZipCodeGroup = undefined;
    this.currentEditZipCodeIndex = undefined;
  }

  get focusedGroupId(): string | undefined {
    this.selectedZipCodeGroup = this.selectedZipCodeGroup ?? this.zipCodeGroups[0];
    return this.selectedZipCodeGroup?.id;
  }

  saveCapacity(): void{
    this.currentSaveCapacityClicked = true;
    const capacity = this.zipCodeGroupId_capacitiesMap.get(this.selectedZipCodeGroup!.id)![this.currentEditCapacityIndex!];
    if(
      this.isNullish(capacity.days) || capacity.days?.length === 0 || this.isNullish(capacity.startTime)
      || this.isNullish(capacity.endTime) || this.isNullish(capacity.units) || this.isNullish(capacity.totalWeight)
      || this.isNullish(capacity.totalVolume) || this.isNullish(capacity.stops)
    ){
      return;
    }
    this.computeCapacityTable();
    this.currentEditCapacityIndex = undefined;
  }

  computeCapacityTable(): void{
    for(const day of this.dayOptions){
      this.capacityTable.used[day] = {u: 0, v: 0, w: 0, s: 0};
      this.capacityTable.unused[day] = {u: 0, v: 0, w: 0, s: 0};
    }

    for(const capacities of this.zipCodeGroupId_capacitiesMap.values()){
      for(const capacity of capacities){
        for(const day of capacity.days ?? []) {
          const used = this.capacityTable.used[day];
          used.u += +(capacity.units ?? 0);
          used.w += +(capacity.totalWeight ?? 0);
          used.v += +(capacity.totalVolume ?? 0);
          used.s += +(capacity.stops ?? 0);
        }
      }
    }

    for(const day of this.dayOptions){
      const total = this.capacityTable.total;
      const used = this.capacityTable.used[day];
      const unused = this.capacityTable.unused[day];

      unused.u = total.u - used.u;
      unused.w = total.w - used.w;
      unused.v = total.v - used.v;
      unused.s = total.s - used.s;
    }
  }

  isNullish(data: any){
    return data === null || data === undefined;
  }

  get selectedZipCodes(): string[] | undefined {
    return this.selectedZipCodeGroup?.zipCodes?.sort((z1, z2) => +z1 - +z2);
  }

  set selectedZipCodes(zipCodes: string[] | undefined) {
    if(!this.selectedZipCodeGroup){
      return;
    }

    const currentSelectedZipCodes = [...this.selectedZipCodeGroup?.zipCodes ?? []];
    zipCodes = zipCodes ?? [];

    this.selectedZipCodeGroup = {
      ...this.selectedZipCodeGroup,
      zipCodes
    };

    const allEqual = zipCodes.length ===  currentSelectedZipCodes.length && 
      zipCodes.every(zipCode => currentSelectedZipCodes.includes(zipCode));

    if(allEqual){
      return;
    }

    this.zipCodeGroups = [
      ...this.zipCodeGroups.map(
        group => {
          if(group.id === this.selectedZipCodeGroup?.id){
            return this.selectedZipCodeGroup;
          }
          return group;
        }
      )
    ];
  }

  get zipCodeGroups(): InputZipCodeGroup[] {
    return this._zipCodeGroups;
  }

  set zipCodeGroups(zipCodeGroups: InputZipCodeGroup[]) {
    this._zipCodeGroups = zipCodeGroups;
    this.selectedZipCodeGroup = zipCodeGroups
      .find(group => group.id === this.focusedGroupId) ?? {...this.selectedZipCodeGroup} as InputZipCodeGroup;
    this.selectedZipCodes = [...this.selectedZipCodeGroup?.zipCodes ?? []];
  }

  onAddZipCodes(){
    this.selectedZipCodes = [
      ...new Set(this.selectedZipCodes?.filter(zipCode => isValidZipCode(zipCode)) ?? this.selectedZipCodes)
    ];
  }

  copyZipCodes(popup:NgbPopover) {
    setTimeout(()=> popup.close(),1000);
    this.clipboard.copy(this.selectedZipCodes?.join(',') ?? '');
  }

  save(){
    const capacities = [...this.zipCodeGroupId_capacitiesMap.values()].flat();
    this.onCapacities.emit(capacities);
    this.activeModal?.close('Close click');
  }

  promptUpgrade() {
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed) {
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true })
      }
    })
  }
}
