import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Branch } from './models/branch.model';

export enum EventType {
    BRANCH_CHANGED = 'BRANCH_CHANGED',
    BRANCH_ADDED = 'BRANCH_ADDED',
    BRANCH_DELETED = 'BRANCH_DELETED'
}
interface Event {
    type: string;
    payload?: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private branchAddedOrChangedCnt = 0;
    public branchAddedOrChangedSubject = new BehaviorSubject<number>(this.branchAddedOrChangedCnt);
    private handler = new Subject<Event>();
    constructor() { }

    /**
     * Broadcast the event
     * @param type type of event
     * @param payload payload
     */
    broadcast(type: string, payload = {}) {
        this.handler.next({ type, payload });
    }

    broadCastBranchAddedOrChanged(){
        this.branchAddedOrChangedSubject.next(++this.branchAddedOrChangedCnt);
    }

    /**
     * Subscribe to event
     * @param type type of event
     * @param callback call back function
     */
    subscribe(type: string, callback: EventCallback): Subscription {
        return this.handler.pipe(
            filter(event => event.type === type)).pipe(
                map(event => event.payload))
            .subscribe(callback);
    }

    subscribeToBranchChanged(callback: (payload: Branch) => Promise<void>): Subscription {
        return this.subscribe(EventType.BRANCH_CHANGED, callback);
    }

    broadcastBranchChanged(payload: Branch): void {
        this.broadcast(EventType.BRANCH_CHANGED, payload);
    }

    subscribeToBranchAdded(callback: (payload: Branch) => Promise<void>): Subscription {
        return this.subscribe(EventType.BRANCH_ADDED, callback);
    }

    subscribeToBranchDeleted(callback: (id: string) => Promise<void>): Subscription {
        return this.subscribe(EventType.BRANCH_DELETED, callback);
    }

    broadcastBranchAdded(payload: Branch): void {
        this.broadcast(EventType.BRANCH_ADDED, payload);
    }

    broadcastBranchDeleted(id: string): void {
        this.broadcast(EventType.BRANCH_DELETED, id);
    }
}
