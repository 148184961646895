import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-tms',
  templateUrl: './tms.component.html',
  styleUrls: ['./tms.component.scss']
})
export class TMSComponent implements OnInit {
  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Final Mile TMS'}
  ];
  constructor() { }

  ngOnInit(): void { }

  redirectToLogin(){
    window.open('https://products.loginextsolutions.com/login', '_blank');
  }
}
