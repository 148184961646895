<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4>1. Set up Integrations</h4>
                    <p class="text-muted">Please complete the integration form and schedule a call with the Integration Team.</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="openModalsStartIntegration()">
                                    Start New Integration
                                </a>
                            </span>
                        </li>
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="openCalendly()">
                                    Schedule First Integration Call
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4>2. Set Up User Profile</h4>
                    <p class="text-muted">Please complete your user profile</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a href="/pages/user-profile">
                                    Complete User Profile
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4>3. Set Up Company Global Settings</h4>
                    <p class="text-muted">Set up company global settings</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            
                                <span class="verification-text">
                                    <a href="/pages/settings/company/profile">
                                        
                                        Complete Company Global Settings
                                    </a>
                                </span>
                            
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4>4. Set Up Company Public Profile</h4>
                    <p class="text-muted">Set up company public settings</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/company/public-profile')">
                                    Create Company Public Profile
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4>5. Company Verification Documents</h4>
                    <p class="text-muted">Upload company verification documents</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="openModalHelp('ss4')">
                                    Upload Employer Identification Number Form SS4
                                </a>
                            </span>
                        </li>
                        <li class="list-group-item" *ngIf="hasDotNumber">
                            <span class="verification-text">
                                <a (click)="openModalHelp('authority')">
                                    Upload Motor Carrier Identification Report Form MCS-150
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top" *ngIf="accountType == 'broker-account'">
                    <h4>6. Upload Freight Broker Authority</h4>
                    <p class="text-muted">Upload Freight Broker Authority</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="openModalHelp('ss4')">
                                    Upload Employer Identification Number Form SS4
                                </a>
                            </span>
                        </li>
                        <li class="list-group-item" *ngIf="hasDotNumber">
                            <span class="verification-text">
                                <a (click)="openModalHelp('authority')">
                                    Upload Motor Carrier Identification Report Form MCS-150
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">7. Set Up Modes</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">6. Set Up Modes</h4>
                    <p class="text-muted">Set up modes</p>
                    <li class="list-group-item">
                        <span class="verification-text">
                            <a (click)="navigateTo('/pages/settings/company/modes')">
                                Confirm Modes
                            </a>
                        </span>
                    </li>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">8. Set Up Service Windows & Types</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">7. Set Up Service Windows & Types</h4>
                    <p class="text-muted">Add service windows & types</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/company/service-types-add-service-window')">
                                    Create Service Windows & Service Types
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">9. Set Up Company Location Hierarchy</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">8. Set Up Company Location Hierarchy</h4>
                    <p class="text-muted">Set up company location hierarchy</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a href="/pages/branches">
                                    Create Branches
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">10. Set Up Company Users & Roles</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">9. Set Up Company Users & Roles</h4>
                    <p class="text-muted">Add company users & roles</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/users/roles')">
                                    Create Company Roles
                                </a>
                            </span>
                        </li>
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/users/users')">
                                    Create Company Users
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">11. Set Up Service Area</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">10. Set Up Service Area</h4>
                    <p class="text-muted">Add service area</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/zones/service-areas')">
                                    Create Service Areas & Zones
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">12. Set Up Lanes</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">11. Set Up Lanes</h4>
                    <p class="text-muted">Add lanes</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="navigateTo('/pages/settings/lanes/lanes')">
                                    Create Lanes
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="row margin-top">
                    <h4 *ngIf="accountType == 'broker-account'">13. Upgrade to Paid Plan</h4>
                    <h4 *ngIf="accountType == 'shipper-account'">12. Upgrade to Paid Plan</h4>
                    <p class="text-muted">Pay using stripe</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a (click)="openUpgradePlanModal()">
                                    Upgrade Plan
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <!-- <div class="row margin-top">
                    <h4>12. Set Up Carriers</h4>
                    <p class="text-muted">Set Up Carriers Description</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a href="javascript:void(0);">
                                    Add Carriers to Platform
                                </a>
                            </span>
                        </li>
                        <li class="list-group-item">
                            <span class="verification-text">
                                <a href="javascript:void(0);">
                                    Upload Carriers to Platform
                                </a>
                            </span>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>
