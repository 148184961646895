export interface PrettyTechName {
    prettyName: string;
    techName: string;
}

export interface PrettyTechnicalName {
    prettyName: string;
    technicalName: string;
}

export const PrettyTechnicalName_NONE: PrettyTechnicalName = {
    prettyName: 'None',
    technicalName: 'none'
};