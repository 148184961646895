import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Messages'}
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
