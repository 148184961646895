<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
        <div class="bg-overlay"></div>
  
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
  
    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <!-- <h1 class="icon-text">Direct to Carrier</h1> -->
                            <img src="../../../assets/logo-blue-background-login.png" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card mt-4">
                    
                        <div class="card-body p-4"> 
                            <div class="text-center mt-2">
                                <h5 class="text-primary">Create new password</h5>
                                <p class="text-muted">Your new password must be different from previous used password.</p>
                            </div>

                            <div class="p-2">
                                <form [formGroup]="passresetForm">
                                    <div class="row mb-3"> 
                                        <div class="col-lg-1"></div>
                                        <div class="col-lg-3 label">
                                            <label class="form-label">Password: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="input-group">
                                                <div class="position-relative auth-pass-inputgroup">
                                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control pe-5" id="password-input" formControlName="password" [ngClass]="{ 'is-invalid': submitted && form['password'].errors }">
                                                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="mdi align-middle eye" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                                                    }" (click)="toggleFieldTextType()"></i></button>
                                                    <!-- <div class="invalid-feedback" *ngIf="form['password'].errors">
                                                        Enter Password
                                                    </div>   -->
                                                    <div *ngIf="submitted" class="password-requirement-error">
                                                        <ul *ngFor="let pwError of getPasswordRequirmentErrors()">
                                                            <li>{{pwError}}</li>
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3"> 
                                        <div class="col-lg-4 label">
                                            <label class="form-label">Confirm Password: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="input-group">
                                                <div class="position-relative auth-pass-inputgroup">
                                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control pe-5" id="cpassword-input" formControlName="cpassword" [ngClass]="{ 'is-invalid': submitted && form['cpassword'].errors }">
                                                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="cpassword-addon"><i class="mdi align-middle eye" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                                                    }" (click)="toggleFieldTextType()"></i></button>
                                                    <!-- <div class="invalid-feedback" *ngIf="form['password'].errors">
                                                        Enter Password
                                                    </div>   -->
                                                    <div *ngIf="submitted" class="password-requirement-error">
                                                        <ul *ngFor="let pwError of getConfirmasswordRequirmentErrors()">
                                                            <li>{{pwError}}</li>
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="mb-3">
                                        <label class="form-label" for="password-input">Password</label>
                                        <div class="position-relative auth-pass-inputgroup">
                                            <input [type]="passwordField ? 'text' : 'password'" class="form-control pe-5 password-input" placeholder="Enter password" id="password-input" aria-describedby="passwordInput" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                id="password-addon"><i class="ri-eye-fill align-middle" [ngClass]="{'mdi-eye-off-outline': !passwordField, 'mdi-eye-outline': passwordField
                                            }" (click)="togglepasswordField()"></i></button>
                                            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                                <span *ngIf="f['password'].errors['required']">Password is required</span>
                                              </div>
                                        </div>
                                        <div id="passwordInput" class="form-text">Must be at least 8 characters.</div>
                                    </div>
            
                                    <div class="mb-3">
                                        <label class="form-label" for="confirm-password-input">Confirm Password</label>
                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                            <input [type]="confirmField ? 'text' : 'password'" class="form-control pe-5 password-input" placeholder="Confirm password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" id="confirm-password-input" required formControlName="cpassword"
                                            [ngClass]="{ 'is-invalid': submitted && f['cpassword'].errors }">
                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-input"><i class="ri-eye-fill align-middle" [ngClass]="{'mdi-eye-off-outline': !passwordField, 'mdi-eye-outline': passwordField
                                            }" (click)="toggleconfirmField()"></i></button>
                                            <div *ngIf="submitted && f['cpassword'].errors" class="invalid-feedback">
                                                <span *ngIf="f['cpassword'].errors['required']">Confirm Password is required</span>
                                              </div>
                                        </div>
                                    </div>

                                    <div id="password-contain" class="p-3 bg-light mb-2 rounded">
                                        <h5 class="fs-13">Password must contain:</h5>
                                        <p id="pass-length" class="invalid fs-12 mb-2">Minimum <b>10 characters</b></p>
                                        <p id="pass-lower" class="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                        <p id="pass-upper" class="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                        <p id="pass-number" class="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                    </div>
                                    
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                                        <label class="form-check-label" for="auth-remember-check">Remember me</label>
                                    </div> -->
                                    
                                    <div class="mt-4">
                                        <!-- <button class="btn btn-primary w-100" type="submit">Reset Password</button> -->
                                        <button type="button" class="btn btn-primary btn-label w-100" data-nexttab="pills-success-tab" (click)="onSubmit()">
                                            <span class="flex-grow-1 me-2">
                                                Create Password
                                              </span>
                                              <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                  <span class="visually-hidden">Loading...</span>
                                              </span>
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->

                    <div class="mt-4 text-center">
                        <p class="mb-0">Wait, I remember my password... <a routerLink="/login" class="fw-semibold text-primary text-decoration-underline"> Click here </a> </p>
                    </div>

                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->
  
    <!-- footer -->
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0 text-muted">  ©  <script>document.write(new Date().getFullYear())</script>Direct To Carrier.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
  