<div class="modal-header bg-light p-3">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>

<div class="modal-body">
    <h3 *ngIf="type == 'ss4'">
        Upload SS4 Document
    </h3>
    <h3 *ngIf="type == 'authority'">
        Upload Authority Document
    </h3>
    <div class="container dnd-cont" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" [multiple]="false" (change)="fileBrowseHandler($event)" accept=".doc, .docx, .pdf"/>
        <i class="las la-file-alt" id="drag-drop-icon"></i>
        <h3 id="dnd-text">Drag and drop file here</h3>
        <h3>or</h3>
        <label for="fileDropRef" id="browse-file">Browse for file</label>
        </div>
        <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
            <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
            <div class="info">
            <h4 class="name">
                {{ file?.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.size) }}
            </p>
            <app-progress [progress]="file?.progress"></app-progress>
            </div>
            <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
        </div>
    </div>
</div>