<div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
    <div class="chat-leftsidebar">
        <ngx-simplebar class="chat-room-list">
            <div *ngIf="!originalLoadBoard" class="d-flex align-items-center pt-4 px-4 mb-2">
                <div>
                    <div data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="New Channel" placement="right" (click)="openModalAddChannel()">
                        <button type="button" class="btn btn-primary">
                            <i class="ri-add-line align-bottom"></i> Add Channel </button>
                    </div>
                </div>
            </div>
            <div class="chat-message-list" style="cursor: pointer;">
                <ul class="list-unstyled chat-list chat-user-list" id="userList" *ngIf="channelList.length != 0">
                    <li *ngFor="let data of channelList" [ngClass]="{'active': data.channelId == channelId}">
                        <a (click)="selectChannel(data)">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-truncate mb-0">{{originalLoadBoard ? data.carrierName : data.name}}</p>
                                </div>
                                <div class="flex-shrink-0">
                                    <span class="badge badge-soft-dark rounded p-1">{{$any(data).unread}}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </ngx-simplebar>
        <h6 class="no-channels" *ngIf="channelList.length == 0 && !isMessagedChannelChecked">
            <!-- No Channels -->
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </h6>
    </div>
    <!-- end chat leftsidebar -->
    <!-- Start User chat -->
    <div class="user-chat flex-fill">
        <div class="chat-content d-lg-flex" *ngIf="selectedChannel">
            <div class="w-100">
                <div class="position-relative">
                    <!-- end chat user head -->
                    <div class="position-relative chat-messages">
                        <h5 *ngIf="chatMessagesData.length == 0 && isMessagedHistoryChecked" id="no-history"> No Messages </h5>
                        <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
                            <ul class="list-unstyled chat-conversation-list">
                                <div class="spinner-border text-primary" role="status" *ngIf="chatMessagesData.length == 0 && !isMessagedHistoryChecked">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <li class="chat-list left" *ngFor="let data of chatMessagesData; let i = index">
                                    <h5 class="time-divider" *ngIf="data.date != '' && data.date != today">
                                        <span>{{ data.date }}</span>
                                    </h5>
                                    <h5 class="time-divider" *ngIf="data.date == today && data.date != ''">
                                        <span>Today</span>
                                    </h5>
                                    <!-- <h5 class="time-divider" *ngIf="data.date != today && data.date != ''"><span>{{ data.date }}</span></h5> -->
                                    <div class="conversation-list">
                                        <div class="user-chat-content">
                                            <div class="row messages-content" *ngIf="data.align !== 'right'">
                                                <div class="col-lg-12 conversation-messages-left">
                                                    <small class="name-private">{{ data.userFirstName }}
                                                        <span style="padding: 0px 0.5em;">
                                                            <small class="text-muted time">{{data.sentTime | date: 'h:mm a'}}</small>
                                                        </span>
                                                    </small>
                                                    <div class="ctext-wrap">
                                                        <div class="ctext-wrap-content public-text" *ngIf="data.type == 'public'">
                                                            <!-- <p class="mb-0 ctext-content header-content">To: {{ getOtherCompanyName() }}</p> -->
                                                            <p class="mb-0 ctext-content">{{data.text}}</p>
                                                            <ul class="list-inline message-img mt-3  mb-0" *ngIf="data.image">
                                                                <li class="list-inline-item message-img-list" *ngFor="let images of data.image; let i=index">
                                                                    <a class="d-inline-block m-1">
                                                                        <img src="{{images}}" alt="" class="rounded img-thumbnail">
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="ctext-wrap-content private-text" *ngIf="data.type == 'private'">
                                                            <p class="mb-0 ctext-content">
                                                                <i class="bx bxs-lock align-bottom lock-icon"></i> {{data.text}}
                                                            </p>
                                                            <ul class="list-inline message-img mt-3  mb-0" *ngIf="data.image">
                                                                <li class="list-inline-item message-img-list" *ngFor="let images of data.image; let i=index">
                                                                    <a class="d-inline-block m-1">
                                                                        <img src="{{images}}" alt="" class="rounded img-thumbnail">
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </ngx-simplebar>
                    </div>
                    <!-- end chat-conversation -->
                    <div class="row bottom-chat">
                        <div class="col-6 chat-input-section p-3 p-lg-4 private-chat-message">
                            <div class="row chat-header">
                                <h5>
                                    <strong>Private Message</strong>
                                </h5>
                            </div>
                            <form (ngSubmit)="messageSave('private')" [formGroup]="formData" id="chatinput-form" enctype="multipart/form-data">
                                <div class="d-flex flex-wrap g-0 align-items-center">
                                    <div class="flex-fill">
                                        <div *ngIf="submitted && form['message'].errors" class="chat-input-feedback">
                                            <span *ngIf="form['message'].errors['required']">Please Enter a Message</span>
                                        </div>
                                        <input type="text" class="form-control chat-input bg-light border-light" id="chat-input-private" placeholder="To {{currentAccount.name}} ..." autocomplete="off" formControlName="message" [ngClass]="{ 'is-invalid': submitted && form['message'].errors }">
                                    </div>
                                    <div class="chat-submit-btn-container">
                                        <div class="links-list-item">
                                            <button type="submit" [disabled]="!getMessage('private')" class="btn btn-text-message-private chat-send waves-effect waves-light shadow btn-submit-message">
                                                <span class="button-text-message">Send </span>
                                                <i class="bx bxs-lock align-bottom lock-icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-6 chat-input-section p-3 p-lg-4 public-message-chat">
                            <div class="row chat-header">
                                <h5>
                                    <strong>Public Message</strong>
                                </h5>
                            </div>
                            <form (ngSubmit)="messageSave('public')" [formGroup]="formData" id="chatinput-form" enctype="multipart/form-data">
                                <div class="d-flex flex-wrap g-0 align-items-center">
                                    <div class="flex-fill">
                                        <div *ngIf="submitted && form['message'].errors" class="chat-input-feedback">
                                            <span *ngIf="form['message'].errors['required']">Please Enter a Message</span>
                                        </div>
                                        <input type="text" class="form-control chat-input bg-light border-light" id="chat-input-public" placeholder="To {{getOtherCompanyName()}} ..." autocomplete="off" formControlName="message" [ngClass]="{ 'is-invalid': submitted && form['message'].errors }">
                                    </div>
                                    <div class="chat-submit-btn-container">
                                        <div class="links-list-item">
                                            <button type="submit" [disabled]="!getMessage('public')" class="btn btn-text-message-public chat-send waves-effect waves-light shadow btn-submit-message">
                                                <span class="button-text-message">Send </span>
                                                <i class="bx bxs-send align-bottom send-icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="files" style="width: 30em;">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title mb-0 people-header">People</h5>
                <button *ngIf="selectedChannel" (click)="inviteCoworker(selectedChannel)" type="button" class="ms-auto btn btn-primary"> Edit </button>
            </div>
            <ng-container *ngIf="selectedChannel && selectedChannel.carrierId == accountId">
                <div class="card-body">
                    <h4 class="card-title mb-0" *ngIf="selectedChannel.carrierId == accountId">{{ selectedChannel.carrierName }}</h4>
                    <div class="row name-section">
                        <ng-container *ngFor="let user of selectedChannel.carrierMembers">
                            <h6>
                                <i class="bx bxs-user-circle align-bottom"></i> {{ user.firstName }} {{ user.lastName }}
                            </h6>
                        </ng-container>
                    </div>
                </div>
                <div class="card-body" style="border-top: 1px solid #F1F1F7;">
                    <h4 class="card-title mb-0">{{ selectedChannel.shipperName }}</h4>
                    <div class="row name-section">
                        <ng-container *ngFor="let user of selectedChannel.shipperMembers">
                            <h6>
                                <i class="bx bxs-user-circle align-bottom"></i> {{ user.firstName }} {{ user.lastName }}
                            </h6>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedChannel && selectedChannel.shipperId == accountId">
                <div class="card-body">
                    <h4 class="card-title mb-0" *ngIf="selectedChannel.shipperId == accountId">{{ selectedChannel.shipperName }}</h4>
                    <div class="row name-section">
                        <ng-container *ngFor="let user of selectedChannel.shipperMembers">
                            <h6>
                                <i class="bx bxs-user-circle align-bottom"></i> {{ user.firstName }} {{ user.lastName }}
                            </h6>
                        </ng-container>
                    </div>
                </div>
                <div class="card-body" style="border-top: 1px solid #F1F1F7;">
                    <h4 class="card-title mb-0">{{ selectedChannel.carrierName }}</h4>
                    <div class="row name-section">
                        <ng-container *ngFor="let user of selectedChannel.carrierMembers">
                            <h6>
                                <i class="bx bxs-user-circle align-bottom"></i> {{ user.firstName }} {{ user.lastName }}
                            </h6>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>