import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { ContractTemplatesService } from 'src/app/core/services/contract-templates-service/contract-templates.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { NgbdModalHelp } from './modals/help/help.component';
import { Document, DocumentWithDownloadUrl, DocumentWithUploadUrl } from 'src/app/core/services/models/document.model';
import Swal from 'sweetalert2';
import { getUtf8HtmlFile, TINYMCE_MERGETAGS_LIST } from 'src/app/core/utils/commons';

export const DOCUMENT_TYPE_OPTIONS = [
  {
    name: 'Service Agreement'
  },
  {
    name: 'Appendix A'
  },
  {
    name: 'Schedule A'
  },
  {
    name: 'W9'
  },
  {
    name: 'Direct Deposit'
  },
  {
    name: 'MC Authority'
  },
  {
    name: 'Business License'
  },
  {
    name: 'COI'
  },
  {
    name: 'SS4'
  },
  {
    name: 'Sample Invoice'
  },
  {
    name: 'Labor Contract Receipt'
  },
  {
    name: 'Schedule B Supplements'
  },
  {
    name: 'Other'
  },
] as const;

type Screen = 'addContract' | 'viewContract' | 'newDocument' | 'listContract';

@Component({
  selector: 'app-contract-templates',
  templateUrl: './contract-templates.component.html',
  styleUrls: ['./contract-templates.component.scss'],
  providers: [ContractTemplatesService, DecimalPipe]

})
export class ContractTemplatesComponent implements OnInit, OnDestroy {
  screen: Screen = 'listContract';
  TINYMCE_MERGETAGS_LIST = TINYMCE_MERGETAGS_LIST;
  breadCrumbItems!: Array<{}>;
  title: any;
  invoiceDetails: any;
  activeModal?: NgbModalRef;
  @Input() public contractDetailsData: any;
  @Output() breadcrumbItems = new EventEmitter();
  contractTemplateData: any;

  ListJsList$: Observable<Document[]>;
  total$: Observable<number>;
  subscriptions: Subscription[] = [];
  addContractTemplateForm!: FormGroup;
  DOCUMENT_TYPE_OPTIONS = [...DOCUMENT_TYPE_OPTIONS];
  submitted = false;
  isLoading = false;

  selectedAccount: (Account | null);

  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  
  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    public service: ContractTemplatesService,
  ) {
    this.ListJsList$ = service.contractTemplates$;
    this.total$ = service.total$;
    
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;
        }
      )
    );
  }
  
  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.loadAllContractTemplates();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  openModalHelp() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalHelp, { size: 'xl', centered: true });
  }

  openNewDocument(content: any){
    this.initForms();
    this.screen = 'newDocument';
    this.openModal(content);
  }

  submitDocument(){ 
    this.submitted = false;
    if(this.addContractTemplateForm.invalid){
      this.submitted =  true;
      return;
    }
    const {name, type, body} = this.addContractTemplateForm.value;
    const file = getUtf8HtmlFile(`${name}.html`, body);
    this.isLoading = true;;
    this.httpService.addDocument(
      {
        displayName: name,
        contentType: 'text/html',
        documentType: type,
        filename: file.name,
        modes: [this.selectedMode?.modeId!],
        branchId: this.selectedBranch?.branchId!
      }
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<DocumentWithUploadUrl>> res;
        const uploadUrl = successRes.data.uploadUrl;

        this.httpService.uploadfileAWSS3(uploadUrl, file).subscribe(
          res =>{
            this.isLoading = false;
            Swal.fire({
              title: 'Success',
              text: 'Successfully saved document.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
            });
            this.loadAllContractTemplates();
          },
          error => {
            this.isLoading = false;
            Swal.fire({
              title: 'Error',
              text: 'Failed to upload document: ' + error?.error?.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to save document: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  initForms() {
    this.addContractTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      body: [''],
    });
  }

  get form() {
    return this.addContractTemplateForm.controls;
  }


  customSearchFn(term: string, item: any) {
    item.name = item.name.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.name.toLocaleLowerCase().indexOf(term) > -1) {
      return item.name.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.name = item.name.replace(',','');
      return item.name.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  openModalAddContractTemplate(content: any) {
    this.screen = 'addContract';
    this.breadcrumbItems.emit('Add');
    this.openModal(content);
  }

  private openModal(content: any){
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      modalDialogClass: 'full-page-modal-container'
    });
  }

  reloadComponentShowView() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, {state: {activeTab: 9, contractDetailsData: this.contractTemplateData}});
  }

  async viewContractTemplateDetails(contract: Document, content: any) {
    const documentResponse = <SuccessApiResponse<DocumentWithDownloadUrl>>await this.httpService
      .getDocument(contract.documentId).toPromise();
    const document = documentResponse.data;

    this.contractTemplateData = {
      name: contract.displayName,
      filename: contract.filename,
      type: contract.documentType,
      downLoadUrl: document.downloadUrl,
      contentType: contract.contentType,
      documentId: contract.documentId,
      modes: contract.modes,
      branchId: contract.branchId
    }
    this.screen = 'viewContract';
    this.openModal(content);
  }

  loadAllContractTemplates() {
    this.screen = 'listContract';
    this.activeModal?.close();
    this.service.updateTable();
  }

  downloadStandardHomeDeliveryDoc(){
    this.httpService.downloadFile(
      'Direct.to.Carrier.-.Standard.Home.Delivery.Agreement.docx', 
      '/assets/docx/Direct.to.Carrier.-.Standard.Home.Delivery.Agreement.docx'
    );
  }

}
