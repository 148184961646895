<div class="auth-page-wrapper pt-5 pricing-plan">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <img src="../../../assets/logo-blue-background-login.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="text-center mb-4">
                        <div class="d-inline-flex">
                            <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist" role="tablist" style="display: none;">
                                <li [ngbNavItem]="1" class="nav-item">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Monthly </a>
                                    <ng-template ngbNavContent>
                                        <div class="row" *ngIf="planFor === 'carrier-plan'">
                                            <div class="col-xxl-6 col-lg-6" *ngFor="let data of MonthlyPlan">
                                                <app-plan-pricing-card
                                                    [monthlyPlan]="data"
                                                    (selectedPlan)="selectPlan($event)"
                                                ></app-plan-pricing-card>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="planFor !== 'carrier-plan'">
                                            <div class="col-xxl-6 col-lg-6" *ngFor="let data of MonthlyPlan">
                                                <app-plan-pricing-card
                                                    [monthlyPlan]="data"
                                                    (selectedPlan)="selectPlan($event)"
                                                ></app-plan-pricing-card>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div [ngbNavOutlet]="customNav"></div>
            </div>
        </div>
    </div>
</div>