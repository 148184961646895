/**
 * Monthly Price
 */

 const MonthlyPlan =  [
  {
    id : 2,
    type :"Pre-Release Asset License",
    rate : 0,
    price: 150,
    description : "Sign Up for Free Until 10.1.2023",
    projects : 8,
    Customers :499,
    FTP:7,
    supportClass:"primary",
    supportClassSymbol :"checkbox",
    storageClass :"danger",
    storageClassSymbol :"close",
    domainClass :"danger",
    domainClassSymbol :"close",
    ribbon:false,
    planButtonClassname: "primary",
    url:"free",
    features: [
        "",
        "3 System Users",
        "Trading Relationships",
        "Load Board Access",
        "Intergraded 'TMS'",
        "Driver Qualification Files",
        "Dashboard & Reports",
        "Capacity & Rates",
        "Calendar",
        "Shipper/Broker Integrations",
        "Unlimited Customizable Users",
        "ELD Integrations",
        "Quick Start Links",
        "Shipper & Broker Search",
        "Lane Bidding Tool",
        "Service Area Tools",
        "Step by Step Sign Up Process",
        "Public Profiles Page",
        "Compliance Suite",
        "24/7 Online Support",
        "Cloud Based Platform"
    ]
},
  {
    id : 3,
    type :"Pre-Release Asset License",
    rate : 150,
    price: 400,
    description : "Each asset license bought before 10.1.2023 is locked at $150/month for 24 months",
    projects : 15,
    Customers :"Unlimited",
    FTP:12,
    supportClass:"primary",
    supportClassSymbol :"checkbox",
    storageClass :"primary",
    storageClassSymbol :"checkbox",
    domainClass :"danger",
    domainClassSymbol :"close",
    ribbon:true,
    planButtonClassname: "primary",
    url:"paid",
    features: [
        "",
        "Unlimited Users",
        "Unlimited Trading Relationships",
        "Load Board Access",
        "Intergraded 'TMS'",
        "Driver Qualification Files",
        "Dashboard & Reports",
        "Capacity & Rates",
        "Calendar",
        "Shipper/Broker Integrations",
        "Unlimited Customizable Users",
        "ELD Integrations",
        "Quick Start Links",
        "Shipper & Broker Search",
        "Lane Bidding Tool",
        "Service Area Tools",
        "Step by Step Sign Up Process",
        "Public Profiles Page",
        "Compliance Suite",
        "24/7 Online Support",
        "Cloud-Based Platform"
    ]
  },
]

const CarrierCostingPlan =  [
    {
        id : 5,
        type :"Pre-Release Asset License",
        rate : 0,
        price: 150,
        description : "Sign Up for Free Until 10.1.2023",
        projects : 8,
        Customers :499,
        FTP:7,
        supportClass:"primary",
        supportClassSymbol :"checkbox",
        storageClass :"danger",
        storageClassSymbol :"close",
        domainClass :"danger",
        domainClassSymbol :"close",
        ribbon:false,
        planButtonClassname: "primary",
        url:"free",
        features: [
            "1 Free Asset License Per Carrier Authority",
            "3 System Users",
            "Trading Relationships",
            "Load Board Access",
            "Intergraded 'TMS'",
            "Driver Qualification Files",
            "Dashboard & Reports",
            "Capacity & Rates",
            "Calendar",
            "Shipper/Broker Integrations",
            "Unlimited Customizable Users",
            "ELD Integrations",
            "Quick Start Links",
            "Shipper & Broker Search",
            "Lane Bidding Tool",
            "Service Area Tools",
            "Step by Step Sign Up Process",
            "Public Profiles Page",
            "Compliance Suite",
            "24/7 Online Support",
            "Cloud Based Platform"
        ]
    },
    {
        id : 6,
        type :"Pre-Release Asset License",
        rate : 150,
        price: 400,
        description : "Each asset license bought before 10.1.2023 is locked at $150/month for 24 months",
        projects : 15,
        Customers :"Unlimited",
        FTP:12,
        supportClass:"primary",
        supportClassSymbol :"checkbox",
        storageClass :"primary",
        storageClassSymbol :"checkbox",
        domainClass :"danger",
        domainClassSymbol :"close",
        ribbon:true,
        planButtonClassname: "primary",
        url:"paid",
        features: [
            "",
            "Unlimited Users",
            "Unlimited Trading Relationships",
            "Load Board Access",
            "Intergraded 'TMS'",
            "Driver Qualification Files",
            "Dashboard & Reports",
            "Capacity & Rates",
            "Calendar",
            "Shipper/Broker Integrations",
            "Unlimited Customizable Users",
            "ELD Integrations",
            "Quick Start Links",
            "Shipper & Broker Search",
            "Lane Bidding Tool",
            "Service Area Tools",
            "Step by Step Sign Up Process",
            "Public Profiles Page",
            "Compliance Suite",
            "24/7 Online Support",
            "Cloud-Based Platform"
        ]
    },
  ]

export { MonthlyPlan, CarrierCostingPlan };
