import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { ContractSignersService } from 'src/app/core/services/contract-signers-service/contract-signers.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { ChattersService } from 'src/app/core/services/chatters-service/chatters-service';


@Component({
  selector: 'app-chat-request',
  templateUrl: './chat-request.component.html',
  styleUrls: ['./chat-request.component.scss']
})
export class ChatRequestComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Chat Request'
    }
  ];
  activeModal?: NgbModalRef;
  isLoading = false;
  contractSignersForm!: FormGroup;
  accountUsers: any = [];
  chattersUsers: any = [];

  selectedAccountSubscription: Subscription;

  constructor(
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    private service: ChattersService,
    private authService: AuthService,
    public permissionsService: PermissionsService
  ) {
    this.selectedAccountSubscription = this.authService.subscribeToSelectedAccount(async (account) => {
      // await this.permissionsService.checkUrlPermission('_modes');
      return this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getUsers();
    this.initForm();
  }

  initForm() {
    this.contractSignersForm = this.formBuilder.group({
      users: ['', [Validators.required]],
    });
  }

  getUsers() {
    this.httpRequest.getAccountUsers().subscribe((data: any) => {
      if(data.data.length != 0) {
        this.accountUsers = this.service.addFullNameKey(data.data);
        this.getAccountChatters();
      }
    })
    
  }

  getAccountChatters() {
    this.httpRequest.getAccountChatters().subscribe((data: any) => {
      console.log(data);
      if(data.data.length != 0) {
        this.chattersUsers = this.service.getContractSignerUsers(this.accountUsers, data.data);
      }
    })
  }

  submit() {
    this.isLoading = true;
    console.log(this.contractSignersForm.value);
    if(this.checkIfAlready(this.contractSignersForm.controls['users'].value, this.chattersUsers)) {
      Swal.fire({
        title: "Can't Add User",
        text: "You have already added this user.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        
      });
    } else {
      this.httpRequest.updateAccountChatters({
        users: this.service.getPayload(this.chattersUsers, this.contractSignersForm.controls['users'].value)
      }).subscribe((data: any) => {
        this.isLoading = false;
        this.getUsers();
        this.initForm();
      }, error => {
        this.isLoading = false;
        console.warn(error);
      })
    }
  }

  deleteChatters(users: any) {
    this.httpRequest.deleteUserChatters(users.userId).subscribe((data: any) => {
      this.chattersUsers = [];
      this.getUsers();
      this.initForm();
    })
  }

  checkIfAlready(userId: any, usersArr: any) {
    for(let x = 0; x < usersArr.length; x++) {
      if(userId === usersArr[x].userId) {
        return true;
      }
    }
    return false;
  }



}
