import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
// import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import Swal from 'sweetalert2';
import { getUtf8HtmlFile, TINYMCE_MERGETAGS_LIST } from 'src/app/core/utils/commons';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

@Component({
  selector: 'view-contract-template',
  templateUrl: './view-contract-template.component.html',
  styleUrls: ['./view-contract-template.component.scss'],

})
export class ViewContractTemplateComponent implements OnInit{
    TINYMCE_MERGETAGS_LIST = TINYMCE_MERGETAGS_LIST;

    @Input() public contractTemplateData: any;
    @Output() close = new EventEmitter();
    types = [
      {
        prettyName: 'Service Agreement',
        technicalName: 'serviceAgreement'
      },
      {
          prettyName: 'Appendix A',
          technicalName: 'appendixA'
      },
      {
          prettyName: 'Schedule A',
          technicalName: 'scheduleA'
      }
    ];
    html?: string;
    addContractTemplateForm!: FormGroup;
    submitted = false;
    contentType: 'pdf' | 'html';
    accountId: string;

    constructor(
      private formBuilder: FormBuilder,
      private httpRequest: HttpService,
      private auth: AuthService
    ) {
      if(this.auth.currentAccountSelected.accountId) {
        this.accountId = this.auth.currentAccountSelected.accountId
      }
    }

    ngOnInit(): void {
      this.initForms();
      if(this.contractTemplateData.contentType == 'application/pdf') {
        this.contentType = 'pdf';
      } else {
        this.contentType = 'html';
        this.httpRequest.fetchBlob(this.contractTemplateData.downLoadUrl).subscribe(
          async blob => {
            this.html = await blob.text();
          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Failed to fetch blob: ' + error?.error?.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      }
        
    }

    initForms() {
        this.addContractTemplateForm = this.formBuilder.group({
          name: [this.contractTemplateData.name, [Validators.required]],
          type: [{value: this.contractTemplateData.type, disabled: true}, [Validators.required]],
          file: ['', [Validators.required]]
        });
    }

    get form() {
        return this.addContractTemplateForm.controls;
    }

    customSearchFn(term: string, item: any) {
        item.technicalName = item.technicalName.replace(',','');
        term = term.toLocaleLowerCase();
        if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
          return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
        } else {
          item.prettyName = item.prettyName.replace(',','');
          return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
        }
    }

    cancel() {
        this.close.emit(true);
    }

    download() {
      if(this.contentType === 'pdf'){
        this.httpRequest.downloadFile(
          this.contractTemplateData.filename, 
          this.contractTemplateData.downLoadUrl
        );
      }else{
        const htmlFile = getUtf8HtmlFile(this.contractTemplateData.filename, this.html!);
        this.httpRequest.convertAndDownloadDocxFile(htmlFile, `${this.contractTemplateData.name}.docx`);
      }
      
    }

    async submitUpdate() {
      let payload = {
        accountId: this.accountId,
        displayName: this.addContractTemplateForm.controls['name'].value,
        documentType: this.addContractTemplateForm.controls['type'].value,
        contentType : this.contractTemplateData.contentType,
        filename: this.contractTemplateData.filename,
        modes: this.contractTemplateData.modes,
        branchId: this.contractTemplateData.branchId
      }

      this.httpRequest.updateDocument(payload, this.contractTemplateData.documentId).subscribe((res: any) => {
        this.close.emit(true);
      })
    }
}