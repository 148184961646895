import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MonthlyPlanModel } from '../pricing.model';
import { Router } from '@angular/router';
import { CarrierCostingPlan } from '../data';

@Component({
  selector: 'app-plan-pricing-card',
  templateUrl: './plan-pricing-card.component.html',
  styleUrls: ['./plan-pricing-card.component.scss']
})
export class PlanPricingCardComponent implements OnInit {

  @Input()
  monthlyPlan: MonthlyPlanModel; 

  @Input()
  showSelectPlan = true;

  @Output() selectedPlan: EventEmitter<any> = new EventEmitter();

  url: string;
  constructor(private router: Router) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    if(!this.monthlyPlan) {
      if(this.isURLCarrier() && this.isCarrierPaid()) {
        this.monthlyPlan = CarrierCostingPlan[1];
      } else if(this.isURLCarrier() && !this.isCarrierPaid()) {
        this.monthlyPlan = CarrierCostingPlan[0];
      } else {
        //nothing
      }
    }
  }

  selectPlan(planType: MonthlyPlanModel) {
    this.selectedPlan.emit(planType);
  }

  isURLCarrier(): boolean {
    if(this.url.includes('/carrier')) {
      return true;
    } else {
      return false;
    }
  }

  isCarrierPaid(): boolean {
    if(this.url.includes('plan=paid')) {
      return true;
    } else {
      return false;
    }
  }

  isNeedPaidFeature(feature: string) {
    switch(feature) {
      case 'Trading Relationships':
          return true;
      case 'Load Board Access':
          return true;
      case "Intergraded 'TMS'":
          return true;
      case 'Driver Qualification Files':
          return true;
      case 'Dashboard & Reports':
          return true;
      case 'Capacity & Rates':
        return true;
        case 'Calendar':
        return true;
      case 'Shipper/Broker Integrations':
        return true;
      case 'Unlimited Customizable Users':
        return true;
      case 'ELD Integrations':
        return true;
      default:
          return false;
    }
  }

}
