<app-cover-not-found *ngIf="!isProfilePublic && !isLoading"></app-cover-not-found>
<span class="d-flex align-items-center" *ngIf="!isProfilePublic && isLoading">
    <span class="flex-grow-1 me-2">
    </span>
    <span class="spinner-border flex-shrink-0" role="status">
        <span class="visually-hidden">Loading...</span>
    </span>
</span>
<div class="container" *ngIf="isProfilePublic && !isLoading">
    <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
            <img src="assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
        </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4" style="padding-top: 3.5em !important;">
        <div class="row g-4">
            <div class="col-auto">
                <div class="avatar-lg" *ngIf="profile">
                    <img [src]="profile.profilePicture" alt="user-img" class="img-thumbnail rounded-circle" />
                </div>
            </div><!--end col-->
            <div class="col" *ngIf="profile">
                <div class="p-2">
                    <h3 class="text-white mb-1">{{ profile.firstName }}  {{ profile.lastName }}</h3>
                    <p class="text-white-75">{{ profile.title }}</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
    
    <div class="row" *ngIf="profile">
        <div class="col-lg-12">
            <div>
                <div class="d-flex">
                    <!-- Nav tabs -->
                    <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" style="display: none;">
                            <i class="ri-airplay-fill d-inline-block d-md-none"></i> <span class="d-none d-md-inline-block">Overview</span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row" *ngIf="profile">
                            <div class="col-xxl-5">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title mb-3">Info</h5>
                                        <div class="table-responsive">
                                            <table class="table table-borderless mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th class="ps-0" scope="row">Full Name:</th>
                                                        <td class="text-muted">{{ profile.firstName }}  {{ profile.lastName }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="ps-0" scope="row">Mobile:</th>
                                                        <td class="text-muted">{{ profile.phone }} </td>
                                                    </tr>
                                                    <tr>
                                                        <th class="ps-0" scope="row">E-mail:</th>
                                                        <td class="text-muted">{{ profile.email }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="ps-0" scope="row">Location:</th>
                                                        <td class="text-muted">{{ getPrettyNameByTechName('state', profile.state) }}, {{ getPrettyNameByTechName('country', profile.country) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="ps-0" scope="row">Member Since:</th>
                                                        <td class="text-muted">{{ getPrettyDate(profile.creationDate) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div><!-- end card body -->
                                </div><!-- end card -->
    
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title mb-4">Links</h5>
                                        <div class="d-flex flex-wrap gap-2">
                                            <div>
                                                <a [href]="origin + '/users/' + profile.profileSlug" class="avatar-xs d-block">
                                                    <span class="avatar-title rounded-circle fs-16 bg-primary">
                                                        <i> <img src="./assets/dtc-icon-title-no-bg.png" width="35px"> </i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div>
                                                <a [href]="origin + '/users/' + profile.profileSlug + '/schedule'" class="avatar-xs d-block">
                                                    <span class="avatar-title rounded-circle fs-16 bg-success">
                                                        <i class="ri-calendar-event-fill"></i>
                                                    </span>
                                                </a>
                                            </div>
                                            <div>
                                                <a [href]="profile.linkedinUrl" class="avatar-xs d-block">
                                                    <span class="avatar-title rounded-circle fs-16 bg-primary">
                                                        <i class="ri-linkedin-box-fill"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div><!-- end card body -->
                                </div><!-- end card -->
    
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title mb-4">Skills</h5>
                                        <div class="d-flex flex-wrap gap-2 fs-15" *ngIf="profile.skills">
                                            <a href="javascript:void(0);" class="badge badge-soft-primary" *ngFor="let skills of getPrettySkills(profile.skills)">{{skills}}</a>
                                        </div>
                                    </div><!-- end card body -->
                                </div><!-- end card -->
                            </div><!--end col-->
                            <div class="col-xxl-7">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title mb-3">About</h5>
                                        <p></p>
                                        <p></p>
                                        <div class="row">
                                            <div class="col-6 col-md-6">
                                                <div class="d-flex mt-6">
                                                    <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                                        <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                            <i class="ri-user-2-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <p class="mb-1">Title:</p>
                                                        <h6 class="text-truncate mb-0">{{ profile.title }}</h6>
                                                    </div>
                                                </div>
                                            </div><!--end col-->
                                            <div class="col-6 col-md-6">
                                                <div class="d-flex mt-6">
                                                    <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                                        <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                            <i class="ri-global-line"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <p class="mb-1">Website :</p>
                                                        <a [href]="profile.website" target="_blank" fw-semibold>{{profile.website}}</a>
                                                    </div>
                                                </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end card-body-->
                                </div><!-- end card -->
    
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-header align-items-center d-flex">
                                                <h3 class="card-title mb-0  me-2">Experience</h3>
                                                <div class="flex-shrink-0 ms-auto">
                                                    <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                                    <li [ngbNavItem]="1" class="nav-item">
                                                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" style="display: none;">
                                                            Experience
                                                        </a>
                                                        <ng-template ngbNavContent *ngIf="workExperience">
                                                            <div class="row" *ngFor="let exp of workExperience">
                                                                <div class="col-lg-12" >
                                                                    <div class="mb-3">
                                                                        <h5>{{ exp.jobTitle }}</h5>
                                                                        <p class="exp-par-cname">{{ exp.companyName }}</p>
                                                                        <p class="exp-par">{{ exp.startDate | date:'MMM y'}} - {{ exp.endDate | date:'MMM y'}}</p>
                                                                        <p class="exp-par" [innerHTML]="exp.jobDescription | lineBreaks"></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="tab-content text-muted">
                                                    <div [ngbNavOutlet]="customNav"></div>
                                                </div>
                                            </div><!-- end card body -->
                                        </div><!-- end card -->
                                    </div><!-- end col -->
                                </div><!-- end row -->
                            </div><!--end col-->
                            </div><!--end row-->
                        </ng-template>
                    </li>
                    </ul>
                </div>
                <!-- Tab panes -->
                <div class="tab-content pt-4 text-muted">
                <div [ngbNavOutlet]="customNav"></div>
                </div><!--end tab-content-->
            </div>
        </div><!--end col-->
    </div><!--end row-->
</div>