<div class="card pricing-box" [ngClass]=" { 'ribbon-box right': monthlyPlan.ribbon === true}" *ngIf="!isURLCarrier()">
    <div class="card-body bg-light m-2 p-4">
        <div class="ribbon-two ribbon-two-danger" [ngClass]=" { 'd-none': monthlyPlan.ribbon !== true}">
            <span>Popular</span>
        </div>
        <div class="d-flex align-items-center mb-3">
            <div class="flex-grow-1">
                <h5 class="mb-0 fw-semibold">{{monthlyPlan.type}}</h5>
            </div>
            <div class="ms-auto">
                <h2 class="annual mb-0"><del>${{monthlyPlan.price}}</del>
                    <span *ngIf="monthlyPlan.rate === 0" class="off-price">Free</span>
                    <span *ngIf="monthlyPlan.rate !== 0" class="off-price">${{monthlyPlan.rate}}</span>
                </h2>
            </div>
        </div>
        <div class="row desc-row">
            <p class="text-muted desc">{{monthlyPlan.description}}</p>
        </div>
        <ul class="list-unstyled vstack gap-3">
            <li *ngFor="let item of monthlyPlan.features">
                <div class="d-flex"*ngIf="monthlyPlan.id === 3">
                    <div class="flex-shrink-0 text-primary">
                        <i class="ri-checkbox-circle-fill fs-15 align-middle" *ngIf="item != ''"></i>
                        <i class="ri-checkbox-circle-fill fs-15 align-middle hidden" *ngIf="item == ''"></i>
                    </div>
                    <div class="flex-grow-1">
                        <b>{{item}}</b>
                    </div>
                </div>
                <div class="d-flex" *ngIf="monthlyPlan.id === 2">
                    <div class="flex-shrink-0 text-primary">
                        <i class="ri-checkbox-circle-fill fs-15 align-middle hidden" *ngIf="item == ''"></i>
                        <i class="ri-checkbox-circle-fill fs-15 align-middle" *ngIf="item != '' && !isNeedPaidFeature(item)"></i>
                    </div>
                    <div class="flex-shrink-0 text-danger">
                        <i class="ri-close-circle-fill fs-15 align-middle" *ngIf="item != '' && isNeedPaidFeature(item)"></i>
                    </div>
                    <div class="flex-grow-1">
                        <b>{{item}}</b>
                    </div>
                </div>
            </li>
        </ul>
        <div class="mt-3 pt-2" *ngIf="showSelectPlan">
            <a class="btn btn-primary w-100" (click)="selectPlan(monthlyPlan)">Select Plan</a>
        </div>
    </div>
</div>

<div class="card pricing-box" [ngClass]=" { 'ribbon-box right': monthlyPlan.ribbon === true}" *ngIf="isURLCarrier()">
    <div class="card-body bg-light m-2 p-4">
        <div class="ribbon-two ribbon-two-danger" [ngClass]=" { 'd-none': monthlyPlan.ribbon !== true}">
            <span>Popular</span>
        </div>
        <div class="d-flex align-items-center mb-3">
            <div class="flex-grow-1">
                <h5 class="mb-0 fw-semibold">{{monthlyPlan.type}}</h5>
            </div>
            <div class="ms-auto">
                <h2 class="annual mb-0"><del>${{monthlyPlan.price}}</del>
                    <span *ngIf="monthlyPlan.rate === 0" class="off-price">Free</span>
                    <span *ngIf="monthlyPlan.rate !== 0" class="off-price">${{monthlyPlan.rate}}</span>
                </h2>
            </div>
        </div>
        <div class="row desc-row">
            <p class="text-muted desc">{{monthlyPlan.description}}</p>
        </div>
        <ul class="list-unstyled vstack gap-3">
            <li *ngFor="let item of monthlyPlan.features">
                <div class="d-flex"*ngIf="monthlyPlan.id === 6">
                    <div class="flex-shrink-0 text-primary">
                        <i class="ri-checkbox-circle-fill fs-15 align-middle" *ngIf="item != ''"></i>
                        <i class="ri-checkbox-circle-fill fs-15 align-middle hidden" *ngIf="item == ''"></i>
                    </div>
                    <div class="flex-grow-1">
                        <b>{{item}}</b>
                    </div>
                </div>
                <div class="d-flex" *ngIf="monthlyPlan.id === 5">
                    <div class="flex-shrink-0 text-primary">
                        <i class="ri-checkbox-circle-fill fs-15 align-middle hidden" *ngIf="item == ''"></i>
                        <i class="ri-checkbox-circle-fill fs-15 align-middle" *ngIf="item != '' && !isNeedPaidFeature(item)"></i>
                    </div>
                    <div class="flex-shrink-0 text-danger">
                        <i class="ri-close-circle-fill fs-15 align-middle" *ngIf="item != '' && isNeedPaidFeature(item)"></i>
                    </div>
                    <div class="flex-grow-1">
                        <b>{{item}}</b>
                    </div>
                </div>
            </li>
        </ul>
        <div class="row bottom-text">
            <p *ngIf="monthlyPlan.id === 5">Carriers Assets Licenses are determined by the number of power units registered with FMCSA</p>
            <p *ngIf="monthlyPlan.id === 6">Carriers Assets Licenses are determined by the number of power units registered with FMCSA. To maintain exclusive receiving pricing, each license needs to remain active.</p>
        </div>
        <div class="mt-3 pt-2" *ngIf="showSelectPlan">
            <a class="btn btn-primary w-100" (click)="selectPlan(monthlyPlan)">Select Plan</a>
        </div>
    </div>
</div>