<div class="btn-group">
    <div>
        <button type="submit" class="btn btn-primary licenses-btn" (click)="openModalBuyLicense()">Buy Licenses</button>
    </div>
    <div>
        <button type="submit" class="btn btn-primary licenses-btn" (click)="openModalContactSales()">Contact Sales</button>
    </div>
    <div *ngIf="loadSpinner">
        <button type="submit" class="btn btn-primary licenses-btn" id="refresh-btn" [disabled]="true">
        <span class="d-flex align-items-center">
            <span class="flex-grow-1 me-2">
                Refresh
            </span>
        <span class="spinner-border flex-shrink-0" role="status" >
            <span class="visually-hidden">Loading...</span>
        </span>
        </span>
    </button>
    </div>
    <div *ngIf="!loadSpinner">
        <button type="submit" class="btn btn-primary" id="refresh-btn" (click)="refresh()">
            Refresh
        </button>
    </div>
</div>

<table *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'"
    class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" width="200px" class="padded-spacer"></th>
            <th scope="col" width="100px" class="type-td">Assets</th>
            <th scope="col"></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="bold padded-spacer-content">
                Available
            </td>
            <td class="center">
                {{driverOnlyAvailable + driverWithHelperAvailable}}
            </td>
            <td class="center-two">
            </td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active On Load Board</td>
            <td class="center">{{driverOnlyActiveLoadOnBoard + driverWithHelperActiveLoadOnBoard}}</td>
            <td class="center-two"></td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active with Shippers</td>
            <td class="center">{{driverOnlyActive + driverWithHelperActive}}</td>
            <td class="center-two"></td>
        </tr>
    </tbody>
</table>

<table *ngIf="auth.currentAccountSelected.accountType === 'shipper-account' || auth.currentAccountSelected.accountType === 'broker-account'"
    class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" width="200px" class="padded-spacer"></th>
            <th scope="col" width="100px" class="type-td">Assets</th>
            <th scope="col"></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="bold padded-spacer-content">
                Available
            </td>
            <td class="center">
                {{trucks}}
            </td>
            <td class="center-two">
            </td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Available For Refund</td>
            <td class="center">{{availableForRefund}}</td>
            <td class="center-two"></td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active On Load Board</td>
            <td class="center">{{driverOnlyActiveLoadOnBoard}}</td>
            <td class="center-two"></td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active with Carriers</td>
            <td class="center">{{driverOnlyActive}}</td>
            <td class="center-two"></td>
        </tr>
    </tbody>
</table>