import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'
import Pusher from 'pusher-js';
import { AuthService } from './authentication/auth.service';
import { HttpService } from './http-service';
import { Observable } from 'rxjs';
import { SuccessApiResponse } from './models/models';
import { User } from './models/auth.models';
// declare const Pusher: any;
// declare const Pusher: any;

export interface ChannelMessage {
  accountId: string;
  userLastName: string;
  version: number;
  userId: string;
  subchannel: string;
  userFirstName: string;
  companyName: string;
  channelId: string;
  text: string;
  sentTime: number;
}

export interface ChannelMessagesResponse {
  items: ChannelMessage[];
  lastEvaluatedKey?: {
    channelId: string;
    sentTime: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: Pusher;
  channel: any;
  presenceChannel: any;
  privateChannel: any;
  messagesChannel: any;
  channelId: any;
  /**
   * undefined if no message retrieved yet;
   * null if last message reached;
   */
  private lastEvaluatedKey?: {
    lastEvaluatedChannelId: string;
    lastEvaluatedSentTime: number;
  } | null = undefined;
  private channelSubId: any;
  private channelPrivateSubId: any;
  private userChannel: any;
  currentUser: User;
  constructor(private auth: AuthService,
    private httpRequest: HttpService) {
    this.currentUser = this.auth.currentUserValue;
    // Pusher.logToConsole = true;
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: 'mt1',
      channelAuthorization: {
        endpoint:`${environment.api}` + "/hooks/pusher_channel_auth",
        transport: "ajax",
        params: {},
        headers: {
          Authorization: `Bearer ${this.currentUser?.token}`
        },
      },
      userAuthentication: {
        endpoint: `${environment.api}` + "/hooks/pusher_user_auth",
        transport: "ajax",
        params: {},
        headers: {
          Authorization: `Bearer ${this.currentUser?.token}`
        },
      }
    });
    this.pusher.signin();
  }

  public setChannelId(value: any) {
    this.lastEvaluatedKey = undefined;
    this.channelId = value;
  }

  public getChannelId() {
    return this.channelId;
  }

  public setChannelSubId(value: any) {
    this.channelSubId = value;
  }
  public getChannelSubId() {
    return this.channelSubId;
  }

  public setChannelPrivateSubId(value: any) {
    this.channelPrivateSubId = value;
  }

  public getChannelPrivateSubId() {
    return this.channelPrivateSubId;
  }

  public setUserChannel(value: any) {
    this.userChannel = value;
  }

  public getUserChannel() {
    return this.userChannel;
  }

  subscribeChannel = new Observable<ChannelMessage[]>((observer) => {
    // observable execution
    let accountId = this.auth.currentAccountSelected.accountId!;
    this.presenceChannel = this.pusher.subscribe(
      this.getUserChannel().subchannels.common
      ?? Object.values(this.getUserChannel().subchannels)[0]
    );
    this.setChannelSubId(this.getUserChannel().subchannels.common);
    if(this.getUserChannel().subchannels[accountId]) {
      this.privateChannel = this.pusher.subscribe(this.getUserChannel().subchannels[accountId]);
      this.setChannelPrivateSubId(this.getUserChannel().subchannels[accountId]);
    } else {
      let subChannels = this.getUserChannel().subchannels;
      const keys = Object.keys(subChannels) as (keyof typeof subChannels)[];
      this.privateChannel = this.pusher.subscribe(this.getUserChannel().subchannels[keys[2]]);
      this.setChannelPrivateSubId(this.getUserChannel().subchannels[keys[2]]);
    }
    const channelId = this.getUserChannel().channelId;
    if (this.lastEvaluatedKey === null) {
      // nothing else to retrieve
      console.warn('Last message reached. Nothing else to retrieve');
      return;
    }
    this.httpRequest.retrieveHistory(
      channelId,
      {
        lastEvaluatedChannelId: this.lastEvaluatedKey?.lastEvaluatedChannelId,
        lastEvaluatedSentTime: this.lastEvaluatedKey?.lastEvaluatedSentTime,
      }
    ).subscribe((data: SuccessApiResponse<ChannelMessagesResponse>) => {
      if (data.data.lastEvaluatedKey) {
        this.lastEvaluatedKey = {
          lastEvaluatedChannelId: data.data.lastEvaluatedKey.channelId,
          lastEvaluatedSentTime: data.data.lastEvaluatedKey.sentTime
        };
      } else {
        this.lastEvaluatedKey = null;
      }
      observer.next(data.data.items);
      observer.complete();
    }, error => {
      // Temporary fix for issue #879 until #891 is resolved.
      // CORS error will show on the first call but not on the second api call, thus calling it twice loads the messages.
      if(error.status == 0) {
        this.httpRequest.retrieveHistory(
          channelId,
          {
            lastEvaluatedChannelId: this.lastEvaluatedKey?.lastEvaluatedChannelId,
            lastEvaluatedSentTime: this.lastEvaluatedKey?.lastEvaluatedSentTime,
          }
        ).subscribe((data: SuccessApiResponse<ChannelMessagesResponse>) => {
          if (data.data.lastEvaluatedKey) {
            this.lastEvaluatedKey = {
              lastEvaluatedChannelId: data.data.lastEvaluatedKey.channelId,
              lastEvaluatedSentTime: data.data.lastEvaluatedKey.sentTime
            };
          } else {
            this.lastEvaluatedKey = null;
          }
          observer.next(data.data.items);
          observer.complete();
        })
      }
    });
  })

  unsubscribeChannels(channels: any) {
    for (let x = 0; x < channels.length; x++) {
      if (x == 0) {
        this.presenceChannel = this.pusher.unsubscribe(channels[x]);
      } else {
        this.privateChannel = this.pusher.unsubscribe(channels[x]);
      }

      Pusher.log = msg => {
        console.log('[unsubscribeChannels] msg', msg);
      }
    }
    return true;
  }

  subscribePresenceChannelMessages() {
    return this.presenceChannel.bind("client-message-new", () => { });
  }

  subscribePrivateChannelMessages() {
    return this.privateChannel.bind("client-message-new", () => { });
  }

  sendMessage(channel: string, message: string) {
    if (channel == 'public') {
      let triggered = this.presenceChannel.trigger("client-message-new", {
        message: message,
      });
    } else {
      let triggered = this.privateChannel.trigger("client-message-new", {
        message: message,
      });
    }
  }

  getChannelName() {
    if (this.auth.currentAccountSelected.accountId === this.userChannel.carrierId) {
      return this.userChannel.shipperName;
    } else if (this.auth.currentAccountSelected.accountId === this.userChannel.shipperId) {
      return this.userChannel.carrierName;
    } else {
      return 'No Company'
    }
  }

  getFormattedDate(date: Date) {
    return date.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  checkIfMessageIfHasToday(chatMessagesData: any) {
    let today = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    let retValue = false;
    for (let x = 0; x < chatMessagesData.length; x++) {
      if (this.getFormattedDate(new Date(chatMessagesData[x].sentTime)) === today) {
        retValue = true;
      }
    }
    return retValue;
  }

}
