import { DecimalPipe } from "@angular/common";
import { Injectable, PipeTransform } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { HttpService } from "../http-service";
import { IntegrationFile, ShipperIntegration } from "../models/shipper-integration.model";
import { SortColumn, SortDirection } from "./gridjs-sortable.directive";
import { AuthService } from "../authentication/auth.service";

interface SearchResult {
    shipperIntegration: ShipperIntegration[];
    total: number;
}
interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    startIndex: number;
    endIndex: number;
    totalRecords: number;
}

const compare = (
    v1: string | IntegrationFile[],
    v2: string | IntegrationFile[]
) => {
    v1 = v1 ?? 0;
    v2 = v2 ?? 0;
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

function sort(shipperIntegration: ShipperIntegration[], column: SortColumn, direction: string): ShipperIntegration[] {
    if (direction === '' || column === '') {
      return shipperIntegration;
    } else {
      return [...shipperIntegration].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
}

function matches(shipperIntegration: ShipperIntegration, term: string, pipe: PipeTransform) {
    return (
        shipperIntegration.name?.toLowerCase().includes(term.toLowerCase()) ||
        shipperIntegration.software?.toLowerCase().includes(term.toLowerCase()) ||
        shipperIntegration.protocol?.toLowerCase().includes(term.toLowerCase()) ||
        shipperIntegration.format?.toLowerCase().includes(term.toLowerCase())
    );
}
  
@Injectable({ providedIn: 'root' })
export class ShipperIntegrationService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _shipperIntegration$ = new BehaviorSubject<ShipperIntegration[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        startIndex: 0,
        endIndex: 9,
        totalRecords: 0,
    };

    private data: any;

    constructor(private pipe: DecimalPipe, private httpRequest: HttpService) {
        if(sessionStorage.getItem('selectedMode')) {
            const mode = JSON.parse(sessionStorage.getItem('selectedMode')!);
            this.updateTable(mode.modeId);
        }
    }

    updateTable(modeId: string): void {
        this.httpRequest.listShipperIntegrations(modeId).subscribe((result: any) => {
          this.data = result.data;
          this._search$
            .pipe(
              tap(() => this._loading$.next(true)),
              debounceTime(200),
              switchMap(() => this._search()),
              delay(200),
              tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
              this._shipperIntegration$.next(result.shipperIntegration);
              this._total$.next(result.total);
            });
    
          this._search$.next();
        });
    }

    get shipperIntegration$() {
        return this._shipperIntegration$.asObservable();
    }
    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    get startIndex() {
        return this._state.startIndex;
    }
    get totalRecords() {
        return this._state.totalRecords;
    }
    get endIndex() {
        return this._state.endIndex;
    }

    set page(page: number) {
        this._set({ page });
    }
    set totalRecords(totalRecords: number) {
        this._set({ totalRecords });
    }
    set sortColumn(sortColumn: SortColumn) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set startIndex(startIndex: number) {
        this._set({ startIndex });
    }
    set endIndex(endIndex: number) {
        this._set({ endIndex });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } =
          this._state;
        // 1. sort
        let shipperIntegration = sort(this.data, sortColumn, sortDirection);
        // 2. filter
        shipperIntegration = shipperIntegration.filter((shipperIntegration) => matches(shipperIntegration, searchTerm, this.pipe));
        const total = shipperIntegration.length;
    
        // 3. paginate
        this.totalRecords = shipperIntegration.length;
        this._state.startIndex = (page - 1) * this.pageSize + 1;
        this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
        if (this.endIndex > this.totalRecords) {
          this.endIndex = this.totalRecords;
        }
        shipperIntegration = shipperIntegration.slice(this._state.startIndex - 1, this._state.endIndex);
        return of({ shipperIntegration, total });
    }
}