<div class="modal-header bg-light p-3">
      <h5 class="modal-title" id="exampleModalLabel">Add Service Area</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
    </div>
    <form [formGroup]="serviceAreaForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label">
                  <label for="name-field" class="form-label">Name</label>
                </div>
                <div class="col-lg-10">
                  <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                  <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['name'].errors['required']">Enter Name</div>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label">
                  <label for="zones-field" class="form-label">Zones</label>
                </div>
                <div class="col-lg-10 table-zone-column">
                    <button type="submit" class="btn btn-primary" id="add-zone" (click)="openModalZone()">Add Zone</button>  
                    <div class="table-responsive" *ngIf="defaultZones.length != 0">
                        <table class="table table-hover align-middle table-nowrap mb-0 table-zone">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col" style="width: 25px;">
                                    </th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Branch</th>
                                    <th scope="col">Zipcodes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let zone of defaultZones">
                                    <th scope="row">
                                    </th>
                                    <td>{{ zone.name }}</td>
                                    <td>{{ zone.branch.name }}</td>
                                    <td>
                                        ({{zone.zipcode.length}}) {{zone.zipcode | slice:0:2}}
                                        <span *ngIf="zone.zipcode.length > 2" [title]="zone.zipcode"> 
                                                ...
                                            <i class="ri-information-line me-1"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div  *ngIf="defaultZones.length == 0 && !hasZone">
                        <p id="add-zone-warning">Please add a zone</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" id="add-service-area-md-footer">
            <div class="hstack gap-2 justify-content-end">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close()">Close</button>
              <button type="submit" class="btn btn-primary" id="add-btn" (click)="addServiceArea()">Add Service Area</button>
            </div>
        </div>
    </form>