<app-breadcrumbs [breadCrumbItems]="breadCrumbItems" *ngIf="showHeader"></app-breadcrumbs>
<div class="align-items-center d-flex">
    <div class="flex-grow-1"> Upload Zip Codes: <input type="file" (change)="convertFile($event)">
        <div class="btn-group radio" role="group">
            <ng-container *ngFor="let tab of heatmapHelper.mapTabs">
                <input type="radio" class="btn-check" name="data-layout-position" [id]="'btn-' + tab" [value]="tab" [checked]="heatmapHelper.selectedMapTab === tab" (change)="heatmapHelper.setServiceType(tab)">
                <label class="btn btn-light w-sm" [for]="'btn-' + tab">{{tab}}</label>
            </ng-container>
        </div>
    </div>
    <div class="flex-shrink-0">
        <a class="btn btn-primary" id="download-btn" download="example-heatmap-data.csv" href="/assets/example-heatmap-data.csv"> Download Example File </a>
    </div>
</div>
<app-mapbox-heatmap #heatMap [zipCodeCounts]="heatmapHelper.zipCodesCount"></app-mapbox-heatmap>