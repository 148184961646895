import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import mapboxgl, { Expression } from "mapbox-gl";
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MapService } from 'src/app/core/services/map.service';
import { MapsDataSource, TrimbleMapsHelper } from 'src/app/core/services/models/trimble-maps.helper';
import { getRandomColor, removeFromArray } from 'src/app/core/utils/commons';
import { getBounds, getFirstLngLat, getLatitudesLongitudesCenterPoint, getZipCodeChildren, getZipCodes } from 'src/app/core/utils/zip-code-utils';
import tinycolor from 'tinycolor2';

const VUE_APP_MAP_BOX_ACCESS_TOKEN="pk.eyJ1IjoiamFyZWRxc3MiLCJhIjoiY2w0c2Nna2dkMGIwYjNsbGE0NzAzYWQ5aCJ9.YUw0RlTZ6PdfYAGoV9MvPg";
const VUE_APP_MAPBOX_TILESET_URL='https://api.mapbox.com/v4/jaredqss.33y15llv.json?secure&access_token=pk.eyJ1IjoiamFyZWRxc3MiLCJhIjoiY2w0c2Nna2dkMGIwYjNsbGE0NzAzYWQ5aCJ9.YUw0RlTZ6PdfYAGoV9MvPg';
const VUE_APP_MAPBOX_SOURCE="cb_2020_us_zcta520_500k-8kjc1u";
const VUE_APP_MAPBOX_ZIPS_TEXT_URL='https://api.mapbox.com/v4/jaredqss.01o02iyq.json?secure&access_token=pk.eyJ1IjoiamFyZWRxc3MiLCJhIjoiY2w0c2Nna2dkMGIwYjNsbGE0NzAzYWQ5aCJ9.YUw0RlTZ6PdfYAGoV9MvPg';
const VUE_APP_MAPBOX_ZIPS_TEXT_SOURCE="2022_Gaz_zcta_national-6mc3x6";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

export interface ZipCodeGroup<T = unknown> {
  id: string;
  color: string;
  zipCodes: string[];

  /** Any extra data for this group */
  data?: T;
}

export type InputZipCodeGroup<T = unknown> = Omit<ZipCodeGroup<T>, 'color'> & Partial<Pick<ZipCodeGroup<T>, 'color'>>;
export type ZipCodeColors = { [key in string]: string };

const MODULE = '[MapboxZipcodePicker]';
const HEATMAP_OPACITY = 0.9;
const DEFAULT_GROUP_OPACIT = 0.25;
const HOVER_OPACITY = 0.9;
const FOCUSED_GROUP_OPACITY = 0.9;
const DEFAULT_OPACITY = 0.5;
const SELECTABLE_OPACITY = 0;
const CENTER: [number, number] = [-98.38, 38.69];
const DEFAULT_ZOOM = 10;
const ZONE_WITHOUT_BOUNDARY_SOURCE = 'zone-without-boundary-source';
const ZONE_WITHOUT_BOUNDARY_LAYER = 'zone-without-boundary-layer';
const DARKEN = 25;
const LIGHTEN = 25;
const SELECTED_HOVER = 'selected-hover';
const UNSELECTED_HOVER = 'unselected-hover';

@Component({
  selector: 'app-mapbox-zipcode-picker',
  templateUrl: './mapbox-zipcode-picker.component.html',
  styleUrls: ['./mapbox-zipcode-picker.component.scss']
})
export class MapboxZipcodePickerComponent implements OnInit, AfterViewInit {
  // private _selectedZipCodes?: string[];
  private _selectableZipCodes?: string[];
  private _zipCodeGroups: ZipCodeGroup[] = [{zipCodes: [], id: 'service-area', color: '#64bdbc'}];
  private _center?: [number, number]; // lng, lat
  private _zoom?: number;
  private _groupOpacity = DEFAULT_GROUP_OPACIT;
  private _autoSelectPoBoxes = true;
  private _showPoBoxes = true;

  /** Default bg colors for the zip codes */
  private _zipCodeColors: ZipCodeColors = {};
  /** Default bg color for the not specified zip codes */
  private _defaultFillColor = 'transparent';

  /** The focusedGroup is the group where events (click, hover) will be applied to */
  private _focusedGroup?: string;
  map: mapboxgl.Map;
  // selectedZipIds: (string | number | undefined)[] = [];
  canSetZipcodes = false;
  private readonly defaultZipCodeColor = '#64bdbc';

  fillColorZipCodeDefaults(get = "ZCTA5CE20"): any[] {
    const colorMap: { [color in string]: string[]} = {};  // key is color, value is array of zipCodes
    Object.entries(this._zipCodeColors).forEach(([zipCode, color]) => {
      if (!colorMap[color]) {
        colorMap[color] = [];
      }

      colorMap[color].push(zipCode);
    });
    return Object.entries(colorMap).map(([color, zipCodes]) => {
      return [
        ["in", ["get", get], ["literal", zipCodes]], this.getColor(color, 1, get)
      ];
    }).flat()
  }

  fillColorGroups(get = "ZCTA5CE20"): any[] {
    return this._zipCodeGroups.map(group => {
      return [
        ["in", ["get", get], ["literal", group.zipCodes]], this.getGroupColor(group.color, this._groupOpacity, get)
      ];
    }).flat();
  }

  fillColorFocusedGroup(get = "ZCTA5CE20"): any {
    if (!this._focusedGroup) {
      return [];
    }

    const group = this._zipCodeGroups.find(g => g.id === this._focusedGroup);
    if (!group) {
      return [];
    }

    return [
      ["in", ["get", get], ["literal", group.zipCodes]], this.getColor(group.color, FOCUSED_GROUP_OPACITY, get)
    ];
  }

  fillColorSelectable(get = "ZCTA5CE20"): any {
    if (!this.selectableZipCodes) {
      return [];
    }
    return [
      ["in", ["get", get], ["literal", this.selectableZipCodes]], this.getColor('white', SELECTABLE_OPACITY, get)
    ];
  }

  getRgbOpacityValue(value: number, opacity: number, isWhiteBg = true): number {
    return value * opacity + ((isWhiteBg ? 255 : 50) * (1 - opacity)); // 255 is for white background
  }

  getGroupColor(color: string, opacity = 1, get = "ZCTA5CE20"): string {
    const tcolor = tinycolor(color);
    const rgb = tcolor.toRgb();
    const newColor = `rgb(${this.getRgbOpacityValue(rgb.r, opacity, false)},${this.getRgbOpacityValue(rgb.g, opacity, false)},${this.getRgbOpacityValue(rgb.b, opacity, false)})`;
    const newTcolor = tinycolor(newColor);

    return this.getColor(newTcolor.toRgbString(), FOCUSED_GROUP_OPACITY, get);
  }

  getColor(color: string, opacity = 1, get = "ZCTA5CE20", darken = 0, lighten = 0): string {
    const tcolor = tinycolor(color);
    if (darken) {
      tcolor.darken(darken);
    }
    if (lighten) {
      tcolor.lighten(lighten);
    }
    if (get === 'ZCTA5CE20') {
      tcolor.setAlpha(opacity);
      return tcolor.toRgbString();
    }


    // tcolor.setAlpha(opacity);
    const rgb = tcolor.toRgb();
    const newColor = `rgb(${this.getRgbOpacityValue(rgb.r, opacity)},${this.getRgbOpacityValue(rgb.g, opacity)},${this.getRgbOpacityValue(rgb.b, opacity)})`;
    // console.log(`${MODULE} [getColor]`, color, opacity, newColor);
    return newColor;
  }

  fillOpacityHeatMap(get = "ZCTA5CE20"): any[] {
    if (Object.keys(this._zipCodeColors).length === 0) {
      return [];
    }

    return [
      ["in", ["get", get], ["literal", Object.keys(this._zipCodeColors)]], HEATMAP_OPACITY
    ];
  }

  fillOpacityFocusedGroup(get = "ZCTA5CE20"): any {
    if (!this._focusedGroup) {
      return [];
    }

    const group = this._zipCodeGroups.find(g => g.id === this._focusedGroup);
    if (!group) {
      return [];
    }

    return [
      ["in", ["get", get], ["literal", group.zipCodes]], FOCUSED_GROUP_OPACITY
    ];
  }

  fillOpacityGroups(get = "ZCTA5CE20"): any {
    if (!this._zipCodeGroups) {
      return [];
    }

    return [
      ...this._zipCodeGroups.map(group => {
        return [
          ["in", ["get", get], ["literal", group.zipCodes]], this._groupOpacity
        ];
      }).flat()
    ];
  }

  fillOpacitySelectable(get = "ZCTA5CE20"): any {
    if (!this.selectableZipCodes) {
      return [];
    }
    return [
      ["in", ["get", get], ["literal", this.selectableZipCodes]], SELECTABLE_OPACITY
    ];
  }

  fillColor(get = "ZCTA5CE20"): Expression {
    return [
      "case",
      // ["==", ["feature-state", "hovered"], SELECTED_HOVER], this.getColor(this.defaultZipCodeColor, HOVER_OPACITY, get, DARKEN),
      // ["==", ["feature-state", "hovered"], UNSELECTED_HOVER], this.getColor(this.defaultZipCodeColor, HOVER_OPACITY, get),
      ...this.fillColorFocusedGroup(get),
      ...this.fillColorGroups(get),
      // ["==", ["get", get], this.hoveredZipCode], this.defaultZipCodeColor,
      ...this.fillColorZipCodeDefaults(get),
      ...this.fillColorSelectable(get),
      this.getColor(this.selectableZipCodes ? "black" : this._defaultFillColor, DEFAULT_OPACITY, get)
    ];
  }

  lineColor(get = "ZCTA5CE20"): Expression {
    return [
      "case",
      ["==", ["feature-state", "hovered"], SELECTED_HOVER], "black",
      ["==", ["feature-state", "hovered"], UNSELECTED_HOVER], "black",
      ["==", ["get", get], this._focusedZipCode], "black",
      "#292929"
    ];
  }

  lineWidth(get = "ZCTA5CE20"): Expression {
    const add = get === "ZCTA5CE20" ? 0 : 0.7;
    return [
      "case",
      ["==", ["feature-state", "hovered"], SELECTED_HOVER], 2.3 + add,
      ["==", ["feature-state", "hovered"], UNSELECTED_HOVER], 2.3 + add,
      ["==", ["get", get], this._focusedZipCode], 2.3 + add,
      0.1 + add
    ];
  }

  private setLine(): void {
    if (!this.canSetZipcodes) {
      return;
    }
    this.map?.setPaintProperty("zips", "fill-outline-color", this.lineColor());
    this.map?.setPaintProperty("zips_boundary", "line-color", this.lineColor());
    this.map?.setPaintProperty("zips_boundary", "line-width", this.lineWidth());
    this.map?.setPaintProperty(ZONE_WITHOUT_BOUNDARY_LAYER, "circle-stroke-color", this.lineColor('zip'));
    this.map?.setPaintProperty(ZONE_WITHOUT_BOUNDARY_LAYER, "circle-stroke-width", this.lineWidth('zip'));
  }

  hoveredZipCode = '';
  private _focusedZipCode: string = '';
  @Input()
  set focusedZipCode(focusedZipCode: string) {
    this._focusedZipCode = focusedZipCode;
    this.drawZipCodes();
  }
  hoveredFeature?: any;

  // @Input()
  // set selectedZipCodes(selectedZipCodes: string[]) {
  //   this._selectedZipCodes = selectedZipCodes;
  //   this._setSelectedZipCodes(selectedZipCodes);
  // }
  // get selectedZipCodes(): string[] {
  //   return this._selectedZipCodes || [];
  // }

  /** Set to undefined if all zip codes are selectable */
  @Input()
  set selectableZipCodes(selectableZipCodes: string[] | undefined) {
    this._selectableZipCodes = selectableZipCodes;
    this.adjustZoom();
    this.drawZipCodes();
    // console.log(`${MODULE} [set selectableZipCodes]`, selectableZipCodes, this.fillColor, this.fillOpacity);
  }
  get selectableZipCodes(): string[] | undefined {
    return this._selectableZipCodes;
  }

  @Input()
  set zipCodeGroups(zipCodeGroups: InputZipCodeGroup[]) {
    // console.log(`${MODULE} [set zipCodeGroups]`, zipCodeGroups);
    if(!zipCodeGroups){
      return;
    }
    if (zipCodeGroups[0] && !zipCodeGroups[0].color) {
      // set first zipCodeGroup color to default, if not defined
      zipCodeGroups[0].color = this.defaultZipCodeColor;
    }
    this._zipCodeGroups = zipCodeGroups.map(group => this._buildZipCodeGroup(group));
    // console.log(`${MODULE} [set zipCodeGroups]`, zipCodeGroups, this.fillColor, this.fillOpacity);
    this.drawZipCodes();
  }
  get zipCodeGroups(): ZipCodeGroup[] {
    return this._zipCodeGroups || [];
  }

  @Input()
  set groupOpacity(opacity: number) {
    this._groupOpacity = opacity;
    this.setFillColor();
  }

  @Input()
  set showPoBoxes(showPoBoxes: boolean) {
    this._showPoBoxes = showPoBoxes;
    try {
      this.map?.setLayoutProperty(ZONE_WITHOUT_BOUNDARY_LAYER, 'visibility', showPoBoxes ? 'visible' : 'none');
    } catch (e) {
      // ignore
    }
    try {
      this.map?.setLayoutProperty(ZONE_WITHOUT_BOUNDARY_LAYER + 2, 'visibility', showPoBoxes ? 'visible' : 'none');
    } catch (e) {
      // ignore
    }
  }

  @Input()
  set autoSelectPoBoxes(autoSelectPoBoxes: boolean) {
    this._autoSelectPoBoxes = autoSelectPoBoxes;
    this.setFillColor();
  }

  @Output()
  zipCodeGroupsChange = new EventEmitter<ZipCodeGroup[]>();
  @Output()
  focusedZipCodeChange = new EventEmitter<string>();

  @Input()
  set focusedGroupId(groupId: string | undefined) {
    console.log(`${MODULE} [set focusedGroupId]`, groupId);
    this._focusedGroup = groupId;
    this.drawZipCodes();
  }
  /** Get the focusedGroup. Will default to first zipCodeGroup if not defined */
  get focusedGroupId(): string | undefined {
    return this._focusedGroup || this._zipCodeGroups[0].id;
  }

  get focusedGroup(): ZipCodeGroup | undefined {
    return this._zipCodeGroups.find(g => g.id === this.focusedGroupId);
  }

  @Input()
  set zipCodeColors(zipCodes: ZipCodeColors) {
    this._zipCodeColors = zipCodes;
    this.drawZipCodes();
  }

  @Input()
  set defaultFillColor(color: string) {
    this._defaultFillColor = color;
    this.setFillColor();
  }

  computeCenter(): Promise<[number, number]> {
    if (this._center) {
      return Promise.resolve(this._center);
    }

    return this.getCenter().pipe(
      tap(response => {
        this._center = response;
      })
    ).toPromise();
  }

  @Input()
  set zoom(zoom: number) {
    this._zoom = zoom;
  }

  get zoom(): number {
    if (this._zoom !== undefined) {
      return this._zoom;
    }

    const selectedZipCodes = this._zipCodeGroups[0]?.zipCodes || this._selectableZipCodes;
    if (selectedZipCodes && selectedZipCodes.length > 0) {
      return 5;
    }

    return DEFAULT_ZOOM;
  }

  @Output()
  onSelectedZipCodesChange = new EventEmitter<string[]>();

  @Output()
  onZipCodeGroupChange = new EventEmitter<ZipCodeGroup>();

  @Output()
  onLoad = new EventEmitter<void>();

  constructor(
    private mapService: MapService,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.createMap();
    window.mapboxZipcodePicker = this;
  }

  getCenter(): Observable<[number, number]> {
    const selectedZipCodes = this._zipCodeGroups[0]?.zipCodes || this._selectableZipCodes;
    if (selectedZipCodes && selectedZipCodes.length > 0) {
      return of(CENTER);
    }

    return this.mapService.geoLocateIP().pipe(
      map(response => {
        return [response.location.lng, response.location.lat]
      })
    );
  }

  fitBounds(zipCodesArr?: string[], offset = 0): void {
    if (!this.map) {
      return;
    }

    if (!zipCodesArr) {
      zipCodesArr = this._selectableZipCodes || this._zipCodeGroups[0]?.zipCodes;
    }
    if (!zipCodesArr || zipCodesArr.length === 0) {
      return;
    }
    if (zipCodesArr.length === 1 && !offset) {
      offset = 0.1;
    }

    try{
      const box = getBounds(zipCodesArr);
      this.map.fitBounds([[box.minX - offset, box.minY - offset], [box.maxX + offset, box.maxY + offset]], {
        padding: 50
      });
    } catch (e) {
      console.error('[fitBounds] - unable to fitBound', e);
    }
  }

  drawZipCodes(): void {
    // if (!this.canSetZipcodes) {
    //   return;
    // }

    // this.zipCodeGroups.forEach(group => {
    //   this._setSelectedZipCodes(group.zipCodes);
    // });
    this.setFillColor();
    // this.setFillOpacity();
    this.setLine();
  }

  private _buildZipCodeGroup<T = unknown>(input: InputZipCodeGroup<T>): ZipCodeGroup<T> {
    return {
      ...input,
      color: input.color || (this._zipCodeGroups.length === 0 ? this.defaultZipCodeColor : getRandomColor()),
    };
  }

  // addGroup(group: InputZipCodeGroup): void {
  //   // if (!this.canSetZipcodes) {
  //   //   return;
  //   // }

  //   // that.zones.map(function (zone) {
  //   //   fill_color.push(["in", ["get", "ZCTA5CE20"], ["literal", zone.zips]]);
  //   //   fill_color.push(zone.color);
  //   // });

  //   const theGroup = this._buildZipCodeGroup(group);
  //   this._zipCodeGroups.push(theGroup);
  //   this.drawZipCodes();
  // }

  private setFillColor(): void {
    // console.log(`${MODULE} [setFillColor]`, this.fillColor);
    if (!this.canSetZipcodes) {
      return;
    }
    this.map?.setPaintProperty("zips", "fill-color", this.fillColor());
    this.map?.setPaintProperty(ZONE_WITHOUT_BOUNDARY_LAYER, "circle-color", this.fillColor('zip'));
  }

  // private setFillOpacity(): void {
  //   if (!this.canSetZipcodes) {
  //     return;
  //   }
  //   // this.map?.setPaintProperty("zips", "fill-opacity", this.fillOpacity());
  //   // this.map?.setPaintProperty(ZONE_WITHOUT_BOUNDARY_LAYER, "circle-opacity", this.fillOpacity('zip'));
  // }

  getZipCodeFromEvent(
    e: {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    }
  ): string | undefined {
    if (!e.features || e.features.length === 0) {
      return undefined;
    }

    return e.features[0].properties?.["ZCTA5CE20"];
  }

  @Input()
  set center(center: [number, number]) {
    this._center = center;
    this.map?.flyTo({
      center,
      essential: true
    });
  }

  getZoneWithoutBoundary(): MapsDataSource {
    const features = getZipCodes().filter(zone => !zone.boundary_exists).map(zone => {
      return {
        coordinates: [zone.lng, zone.lat],
        properties: {
          zip: zone.zip,
          color: '#FFF'
        }
      };
    });
    return TrimbleMapsHelper.generateGeoJsonDataSource(ZONE_WITHOUT_BOUNDARY_SOURCE, features, true);
  }

  getEventZip(e: {point: mapboxgl.Point}, click = false): {zipCode: string; feature: mapboxgl.MapboxGeoJSONFeature} | undefined {
    // If the user clicked on one of your markers, get its information.
    let features = this.map.queryRenderedFeatures(e.point, {
      layers: [ZONE_WITHOUT_BOUNDARY_LAYER] // replace with your layer name
    });
    let zip = 'zip'
    if (!features.length) {
      features = this.map.queryRenderedFeatures(e.point, {
        layers: ['zips'] // replace with your layer name
      });
      zip = 'ZCTA5CE20';
    }
    if (!features.length) {
      return;
    }
    const feature = features[0];
    const selected = feature.properties?.[zip];
    if (!selected) {
      return;
    }
    return {zipCode: selected, feature};
  }

  async createMap() {
    try {
      const center = await this.computeCenter();
      console.log('[MapboxZipcodePicker] [createMap] - center', center);

      mapboxgl.accessToken = VUE_APP_MAP_BOX_ACCESS_TOKEN;
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: center, // [-95.03734351262877, 29.537451890638886],
        zoom: this.zoom,
      });

      this.map.addControl(new mapboxgl.NavigationControl());

      let hoveredStateId: string | number | undefined | null = null;
      let clickStateId = null;

      this.map.once("idle", (ev) => {
        this.canSetZipcodes = true;
        this.drawZipCodes();
        // if (this.selectedZipCodes) {
        //   this._setSelectedZipCodes(this.selectedZipCodes);
        // }
      });

      this.map.on("load", () => {
        this.fitBounds();

        this.map.addSource(VUE_APP_MAPBOX_SOURCE, {
          type: "vector",
          url: VUE_APP_MAPBOX_TILESET_URL,
        });

        this.map.addLayer({
          id: "zips",
          type: "fill",
          source: VUE_APP_MAPBOX_SOURCE,
          "minzoom": 5,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-outline-color": this.lineColor(),
            "fill-color": this.fillColor(),
            // "fill-opacity": this.fillOpacity(),
          },
          "source-layer": VUE_APP_MAPBOX_SOURCE,
        });

        this.map.addLayer({
          id: "zips_boundary",
          type: "line",
          source: VUE_APP_MAPBOX_SOURCE,
          "minzoom": 5,
          paint: {
            "line-color": this.lineColor(), // "#7393B3",
            "line-width": this.lineWidth() // 1.5,
          },
          "source-layer": VUE_APP_MAPBOX_SOURCE,
        });

        this.map.addSource(VUE_APP_MAPBOX_ZIPS_TEXT_SOURCE, {
          type: "vector",
          url: VUE_APP_MAPBOX_ZIPS_TEXT_URL,
        });

        this.map.addLayer({
          id: "zips_point",
          type: "symbol",
          source: VUE_APP_MAPBOX_ZIPS_TEXT_SOURCE,
          "minzoom": 8,
          layout: {
            visibility: "visible",
            "text-field": "{GEOID}",
          },
          paint: {
            "text-opacity": 1,
          },
          "source-layer": VUE_APP_MAPBOX_ZIPS_TEXT_SOURCE,
        });

        const source = this.getZoneWithoutBoundary();
        this.map.addSource(source.name, source.data);

        this.map.addLayer({
          'id': ZONE_WITHOUT_BOUNDARY_LAYER,
          'type': 'circle',
          'source': source.name,
          'minzoom': 5,
          paint: {
            'circle-radius': [
              'interpolate',
              ['exponential', 2],
              ['zoom'],
              1, 5,
              10, 36
            ],
            'circle-color': this.fillColor('zip'),
            'circle-stroke-color': this.lineColor('zip'),
            'circle-stroke-width': this.lineWidth('zip'),
          },
          layout: {
            visibility: this._showPoBoxes ? 'visible' : 'none'
          }
        });

        this.map.addLayer({
          'id': ZONE_WITHOUT_BOUNDARY_LAYER + 2,
          'type': 'symbol',
          'source': source.name,
          'minzoom': 8,
          'layout': {
            'text-field': ['get', 'zip'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            // 'text-radial-offset': 0.5,
            'text-justify': 'auto',
            // 'icon-image': ['get', 'icon']
            'visibility':  this._showPoBoxes ? 'visible' : 'none',
          },
          paint: {
            'text-color': '#000'
          }
        });

        this.map.on("mouseout", e => {
          if (this.hoveredFeature) {
            this.map.setFeatureState(
              this.hoveredFeature,
              {
                hovered: undefined,
              }
            );
          }
          this.hoveredZipCode = '';
          this.hoveredFeature = undefined;
        });

        this.map.on("mousemove", (e) => {
          const event = this.getEventZip(e);
          if (!event) {
            return;
          }

          const {zipCode, feature} = event;
          if (zipCode !== this.hoveredZipCode) {
            this.setHoverColor(zipCode, feature);
          }
        });

        this.map.on("click", (e) => {
          const event = this.getEventZip(e, true);
          if (!event) {
            return;
          }

          const clickedZipCode = event.zipCode
          if (this._selectableZipCodes && !this._selectableZipCodes.includes(clickedZipCode)) {
            return; // ignore if not in selectable
          }
          const focusedGroup = this.focusedGroup;
          if (!focusedGroup) {
            return;
          }
          const children = this._autoSelectPoBoxes ? getZipCodeChildren(clickedZipCode) : [];
          let toEditZipCodes = [clickedZipCode];
          if (children) {
            toEditZipCodes = [clickedZipCode, ...children];
          }

          if (!focusedGroup.zipCodes.includes(clickedZipCode)) {
            // if in other group, remove
            for (const toEditZipCode of toEditZipCodes) {
              const prevGroup = this.zipCodeGroups.find(g => g.zipCodes.includes(toEditZipCode) && g.id !== focusedGroup.id);
              if (prevGroup) {
                prevGroup.zipCodes = removeFromArray(prevGroup.zipCodes, (zipCode) => zipCode === toEditZipCode);
              }
            }

            focusedGroup.zipCodes = [...focusedGroup.zipCodes, ...toEditZipCodes];
          } else {
            focusedGroup.zipCodes = focusedGroup.zipCodes.filter(zipCode => !toEditZipCodes.find(toEditZipCode => toEditZipCode === zipCode));
          }
          this.onZipCodeGroupChange.emit(focusedGroup);
          this.setClickColor(clickedZipCode);
          this.drawZipCodes();
          this.zipCodeGroupsChange.emit(this._zipCodeGroups);
        });

        this.onLoad.emit();
      });

      // window.map = this.map;
    } catch (err) {
      console.log("map error", err);
    }
  }

  setClickColor(zipCode: string) {
    this.hoveredZipCode = zipCode;
    if (!this._selectableZipCodes || this._selectableZipCodes.includes(zipCode)) {
      const hoverType = this.getHoverType();
      this.map.setFeatureState(
        this.hoveredFeature,
        {
          hovered: hoverType,
        }
      );
    }
    this._focusedZipCode = '';
    this.focusedZipCodeChange.emit(this._focusedZipCode);
  }

  setHoverColor(zipCode: string, feature: mapboxgl.MapboxGeoJSONFeature) {
    this.hoveredZipCode = zipCode;
    if (!this._selectableZipCodes || this._selectableZipCodes.includes(zipCode)) {
      const hoverType = this.getHoverType();
      this.map.setFeatureState(
        feature,
        {
          hovered: hoverType,
        }
      );

    }

    if (this.hoveredFeature) {
      this.map.setFeatureState(
        this.hoveredFeature,
        {
          hovered: undefined,
        }
      );
    }
    this.hoveredFeature = feature;
    if (this._focusedZipCode) {
      this._focusedZipCode = '';
      this.focusedZipCodeChange.emit(this._focusedZipCode);
      this.drawZipCodes()
    }
  }

  getHoverType(): string {
    const serviceAreaGroup = this.zipCodeGroups.find(g => g.id === this.focusedGroupId);
    if (!serviceAreaGroup) {
      return SELECTED_HOVER;
    }
    return this.hoveredZipCode && serviceAreaGroup.zipCodes.includes(this.hoveredZipCode)
      ? SELECTED_HOVER : UNSELECTED_HOVER;
  }

  adjustZoom(): void {
    const zipCodesArr = this.selectableZipCodes || [];
    if (zipCodesArr.length > 0) {
      this.fitBounds(zipCodesArr);
      // const box = getBounds(zipCodesArr);
      // this.map?.fitBounds([[box.minX, box.minY], [box.maxX, box.maxY]], {
      //   padding: 50
      // });
    }
  }

  // private _addSelectedZipCodes(zipCodes: string[]): void {
  //   if (!this.canSetZipcodes) {
  //     return;
  //   }

  //   this.map
  //     .queryRenderedFeatures(undefined, {
  //       layers: ["zips"],
  //     })
  //     .map((el) => {
  //       if (zipCodes.includes(el.properties?.["ZCTA5CE20"])) {
  //         this.map.setFeatureState(
  //           {
  //             source: VUE_APP_MAPBOX_SOURCE,
  //             sourceLayer: VUE_APP_MAPBOX_SOURCE,
  //             id: el.id,
  //           },
  //           {
  //             clicked: true,
  //           }
  //         );
  //       }

  //     });
  // }

  // private _setSelectedZipCodes(zipCodes: string[]): void {
  //   if (!this.canSetZipcodes) {
  //     return;
  //   }
  //   this.map
  //     .queryRenderedFeatures(undefined, {
  //       layers: ["zips"],
  //     })
  //     .map((el) => {
  //       let clicked = false;
  //       if (zipCodes.includes(el.properties?.["ZCTA5CE20"])) {
  //         clicked = true;
  //       }
  //       this.map.setFeatureState(
  //         {
  //           source: VUE_APP_MAPBOX_SOURCE,
  //           sourceLayer: VUE_APP_MAPBOX_SOURCE,
  //           id: el.id,
  //         },
  //         {
  //           clicked,
  //         }
  //       );
  //     });
  // }

  // removeSelectedZipCodes(zipCodes: string[]): void {
  //   this.map
  //     .queryRenderedFeatures(undefined, {
  //       layers: ["zips"],
  //     })
  //     .map((el) => {
  //       if (!zipCodes.includes(el.properties?.["ZCTA5CE20"])) {
  //         return;
  //       }
  //       this.map.setFeatureState(
  //         {
  //           source: VUE_APP_MAPBOX_SOURCE,
  //           sourceLayer: VUE_APP_MAPBOX_SOURCE,
  //           id: el.id,
  //         },
  //         {
  //           clicked: false,
  //         }
  //       );
  //     });
  // }

  // updateMap(item?: {zips: string[]; selected: boolean; clicked: boolean}) {
  //   const fill_color: (string | ['==', string[], boolean])[] = ["case"];
  //   // that.zones.map(function (zone) {
  //   //   fill_color.push(["in", ["get", "ZCTA5CE20"], ["literal", zone.zips]]);
  //   //   fill_color.push(zone.color);
  //   // });
  //   fill_color.push(["==", ["feature-state", "hover"], true]);
  //   fill_color.push("#64bdbc");
  //   fill_color.push(["==", ["feature-state", "clicked"], true]);
  //   fill_color.push("#64bdbc");
  //   fill_color.push("transparent");

  //   this.map.setPaintProperty("zips", "fill-color", fill_color);
  //   if (item) {
  //     this.map
  //       .queryRenderedFeatures(undefined, {
  //         layers: ["zips"],
  //       })
  //       .map((el) => {
  //         if (item.zips.includes(el.properties?.["ZCTA5CE20"])) {
  //           if (item.selected) {
  //             this.map.setFeatureState(
  //               {
  //                 source: VUE_APP_MAPBOX_SOURCE,
  //                 sourceLayer: VUE_APP_MAPBOX_SOURCE,
  //                 id: el.id,
  //               },
  //               {
  //                 selected: true,
  //               }
  //             );
  //           } else {
  //             this.map.setFeatureState(
  //               {
  //                 source: VUE_APP_MAPBOX_SOURCE,
  //                 sourceLayer: VUE_APP_MAPBOX_SOURCE,
  //                 id: el.id,
  //               },
  //               {
  //                 selected: false,
  //                 clicked: false,
  //               }
  //             );
  //           }
  //         }
  //       });
  //   }
  // }
}
