<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Load Board Details</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-n4 padd">
                <div class="bg-soft-warning">
                    <div class="card-body pb-0 px-4">
                        <div class="row mb-3">
                            <div class="col-md">
                                <div class="row align-items-center g-3">
                                    <div class="col-md-auto">
                                        <div class="avatar-md">
                                            <div class="avatar-title bg-white rounded-circle">
                                                <img src="assets/images/brands/slack.png" alt="" class="avatar-xs">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div>
                                            <h4 class="fw-bold">{{loadData.title}}</h4>
                                            <div class="hstack gap-3 flex-wrap">
                                                <div>
                                                    <i class="ri-building-line align-bottom me-1"></i>Route : {{loadData.routeName}}
                                                </div>
                                                <div class="vr"></div>
                                                <div>Mode : <span class="fw-medium">{{ loadData.modeName }}</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div>Zone : <span class="fw-medium">{{ loadData.zoneName }}</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div>Service Area : <span class="fw-medium">{{ loadData.serviceAreaName }}</span>
                                                </div>
                                                <div class="vr"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="hstack gap-1 flex-wrap">
                                    <button type="button" class="btn py-0 fs-16 favourite-btn active">
                                        <i class="ri-star-fill"></i>
                                    </button>
                                    <button type="button" class="btn py-0 fs-16 text-body">
                                        <i class="ri-share-line"></i>
                                    </button>
                                    <button type="button" class="btn py-0 fs-16 text-body">
                                        <i class="ri-flag-line"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0 hide" role="tablist">
                            <li [ngbNavItem]="1" class="nav-item">
                                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Overview </a>
                                <ng-template ngbNavContent>
                                    <div class="act-btn">
                                        <button type="button" class="btn btn-primary" (click)="openModalAddMessage('', content)"> Apply </button>
                                        <button type="button" class="btn btn-primary" id="apply-reserve-btn"> Apply and Reserve Capacity </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-8 col-lg-7">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="text-muted">
                                                        <h6 class="mb-3 fw-semibold text-uppercase">Description</h6>
                                                        <p>
                                                            {{loadData.description}}
                                                        </p>
                                                        <div class="pt-3 border-top border-top-dashed mt-4">
                                                            <div class="row">
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">Start Date :</p>
                                                                        <h5 class="fs-15 mb-0">{{loadData.startDate | date}}</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">Type :</p>
                                                                        <h5 class="fs-15 mb-0">{{loadData.type | titlecase }}</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">Duration :</p>
                                                                        <div class="badge bg-danger fs-12 duration">{{getDurationRange(loadData)}} ({{loadData.duration}} months)</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">Posted :</p>
                                                                        <div class="badge bg-warning fs-12">{{ loadData.creationTime * 1000 | timeago }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h6 class="mb-3 fw-semibold text-uppercase">Contact Details</h6>
                                                    <div class="row summary">
                                                        <div class="row">
                                                            <div class="col-lg-3 label">
                                                                <label>Name:</label>
                                                            </div>
                                                            <div class="col-lg-9">
                                                                {{loadData.contactName}}
                                                            </div>
                                                            <div class="col-lg-1"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-3 label">
                                                                <label>Email:</label>
                                                            </div>
                                                            <div class="col-lg-9">
                                                                {{loadData.contactEmail}}
                                                            </div>
                                                            <div class="col-lg-1"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-3 label">
                                                                <label>Phone:</label>
                                                            </div>
                                                            <div class="col-lg-9">
                                                                {{loadData.contactPhone}}
                                                            </div>
                                                            <div class="col-lg-1"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-3 label">
                                                                <label>Link:</label>
                                                            </div>
                                                            <div class="col-lg-9">
                                                                <a href="javascript:void(0);">{{loadData.contactLink}}</a>
                                                            </div>
                                                            <div class="col-lg-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img *ngIf="loadData.loadId === '2796f04e-756c-498f-b512-ef7ea6b567e5'" src="/assets/images/sample/stops-and-map.png" class="w-100" />
                                        <img *ngIf="loadData.loadId === '8f39f341-75a5-4868-9f7a-2aa4904b3e06'" src="/assets/images/sample/map-and-cap-table.png" class="w-100" />
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="tab-content text-muted">
                <div [ngbNavOutlet]="customNav"></div>
            </div>
        </div>
    </div>
</div>
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Load Message</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-lg-2 padding-top text-align-right">
                <label class="form-label">Message: <span class="opacity-0">*</span>
                </label>
            </div>
            <div class="col-lg-10">
                <textarea type="text" [(ngModel)]="message" class="form-control" placeholder=""></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button class="btn btn-primary" type="submit" (click)="apply()" [disabled]="isLoading">
                <span class="flex-grow-1 me-2"> Submit </span>
                <span class="spinner-border submit-spinner flex-shrink-0" role="status" *ngIf="isLoading">
                    <span class="visually-hidden">Loading...</span>
                </span>
            </button>
        </div>
    </div>
</ng-template>