import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ZonesAndRoutesService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  getLowestLevel(branches: any, allBranches: any) {
    let selectedDeliverableBranches: any = [];
    for(let x = 0; x < allBranches.length; x++) {
      for(let y = 0; y < branches.length; y++) {
        if(branches[y] === allBranches[x].branchId) {
          selectedDeliverableBranches.push(allBranches[x]);
        }
      }
    }
    let lowestLevel: any;

    for(let x = 0; x < selectedDeliverableBranches.length; x++) {
      if(x == 0) {
        lowestLevel = selectedDeliverableBranches[x].level;
      } else {
        if(selectedDeliverableBranches[x].level < lowestLevel) {
          lowestLevel = selectedDeliverableBranches[x].level;
        } else {

        }
      }
        
    }
    return lowestLevel;
  }

  getMainBranches(branches: any, allBranches: any, lowestLevel: number) {
    let mainBranches = [];
    for(let x = 0; x < allBranches.length; x++) {
        if(allBranches[x].level == lowestLevel) {
            mainBranches.push(allBranches[x]);
        }
    }
    return mainBranches;
  }

  getFilesystem() {
    return this.http.get<any>('assets/filesystem.json')
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  manipulateData(dataFromAPI: any) {
    let result: any = [];
    for(let x = 0; x < dataFromAPI.length; x++){
        let data = {
            zoneId: dataFromAPI[x].zoneId,
            name: dataFromAPI[x].name,
            zipCodes: dataFromAPI[x].zipCodes,
            accountPayableId: dataFromAPI[x].accountPayableId,
            accountId: dataFromAPI[x].accountId,
            deliverableBranchIds: dataFromAPI[x].deliverableBranchIds,
            mainBranchId: dataFromAPI[x].mainBranchId,
            mainBranchName: dataFromAPI[x].mainBranchName
        }
        if(dataFromAPI[x].type == 'store' || dataFromAPI[x].type == 'remote-store' || dataFromAPI[x].type == '3pl-cross-dock') {
          result.push({data: data, leaf: true})
        } else {
          result.push({data: data, leaf: false})
        }
    }
    return result;
  }

  getBranchesName(branches: any, id: string) {
    for(let x = 0; x < branches.length; x++) {
      if(branches[x].branchId == id) {
        return branches[x].name;
      }
    }
  }

  getDayPrettyName(dayNum: any) {
    if(dayNum == 0) {
      return 'Sun';
    } else if(dayNum == 1) {
      return 'Mon';
    } else if(dayNum == 2) {
      return 'Tue';
    } else if(dayNum == 3) {
      return 'Wed';
    } else if(dayNum == 4) {
      return 'Thu';
    } else if(dayNum == 5) {
      return 'Fri';
    } else if(dayNum == 6) {
      return 'Sat';
    } else {
      return;
    }
  }
  

  isDeliverableIdsInBranches(allBranches: any, deliverableBranches: any) {
    let tempBranch = allBranches;
    for(let x = 0 ; x < deliverableBranches.deliverableBranchIds.length; x++) {
      if(allBranches.find((x: any) => x.branchId == deliverableBranches.deliverableBranchIds[x])) {
      } else {
        if(x == 0) {
          tempBranch.push(
            {
              branchId: deliverableBranches.deliverableBranchIds[x],
              name: deliverableBranches.mainBranchName,
              level: 1
            }
          )
        } else {
          tempBranch.push(
            {
              branchId: deliverableBranches.deliverableBranchIds[x],
              name: deliverableBranches.mainBranchName + x,
              level: 1
            }
          )
        }
        
      }
    }
    return tempBranch;
  }

  
}
