
<div class="contract-signers">
    <form [formGroup]="contractSignersForm">
        <div class="row" *ngIf="accountUsers.length != 0">
            <div class="col-lg-2">
                <ng-select #accountUserDropdown id="day" formControlName="users" class="form-control" [items]="accountUsers" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="fullName" bindValue="userId"></ng-select>
            </div>
            <div class="col-lg-6">
                <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" (click)="submit(); accountUserDropdown.handleClearClick()" [disabled]="contractSignersForm.invalid">
                    <i class="ri-add-line align-bottom me-1"></i> Add User
                </button>
            </div>
        </div>
    </form>
    <table class="table align-middle table-nowrap mb-0">
        <thead class="table-light">
            <tr>
                <th scope="col" class="name-td">First Name</th>
                <th scope="col" class="name-td">Last Name</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let users of chattersUsers">
                <td class="name-td">
                    {{ users.firstName }}
                </td>
                <td class="name-td">
                    {{ users.lastName }}
                </td>
                <td>
                    <div class="d-flex gap-2">
                        <div class="remove">
                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteChatters(users)">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
