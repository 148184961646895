<app-breadcrumbs *ngIf="isRouted" [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header" [ngClass]="{'d-none': !isRouted}">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="0" class="nav-item" (click)="setActiveTab(0);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Information
                        </a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="branchForm">
                                <div class="modal-body">
                                    <div class="row mb-3" >
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="typeInput" class="form-label">Parent Branch: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9" *ngIf="isMainBranch">
                                            <p-treeSelect
                                                [options]="nodes4"
                                                [metaKeySelection]="false"
                                                selectionMode="single"
                                                placeholder="Select Item"
                                                [filter]="true"
                                                [filterInputAutoFocus]="true"
                                                (onNodeSelect)="selectParent($event)"
                                            >
                                            </p-treeSelect>
                                        </div>
                                        <div class="col-lg-9" *ngIf="!isMainBranch">
                                            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required [value]="parentBranch.name" [disabled]="true">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="typeInput" class="form-label">Type: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="shipperCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="(parentBranch.type == 'shipper-company' || parentBranch.type == 'shipper-account' || parentBranch.type == 'broker-account' || !parentBranch.type) && currentAccount.type == 'shipper-account'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="carrierCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="(parentBranch.type == 'carrier-company' || parentBranch.type == 'carrier-account' || !parentBranch.type) && currentAccount.type == 'carrier-account'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="hqBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="parentBranch.type == 'hq'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="subsidiaryBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="parentBranch.type == 'subsidiary'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="organizationBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="parentBranch.type == 'organization'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="regionBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="parentBranch.type == 'region'"></ng-select>
                                            <ng-select class="custom form-control" (change)="selectedType($event)" [items]="dcHubCrossDockBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="parentBranch.type == 'dc' || parentBranch.type == 'hub' || parentBranch.type == '3pl-cross-dock' || parentBranch.type == 'cross-dock'"></ng-select>
                                            <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['type'].errors['required']">Enter Type</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="numberInput" class="form-label">Name: <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-9" *ngIf="isPrefix">
                                            <div class="input-group">
                                                <span class="input-group-text prefix" id="inputGroup-sizing-default">{{ returnPrettyTypeName() }}</span>
                                                <input type="text" id="name-field" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                                            </div>
                                        </div>
                                        <div class="col-lg-9" *ngIf="!isPrefix">
                                            <input type="text" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                                            <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['name'].errors['required']">Enter Name</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="col-lg-2 padding-top text-align-right label-column">
                                            <label class="form-label">Modes: </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="field-checkbox padding-top" 
                                                [ngClass]="{ 'is-invalid': submitted && modesControl.value?.length === 0}" 
                                                *ngFor="let mode of modes">
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="groupname" 
                                                            [formControl]="modesControl"
                                                            [value]="mode.modeId"
                                                            (onChange)="modesChanged()"
                                                        >
                                                        </p-checkbox>
                                                        {{ mode.name }}
                                                    </div>
                                                </div>
                                                <div class="row mt-2 ps-4">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="domicileLocations"
                                                            [value]="mode.modeId"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="domicileLocationModeIds"
                                                            (onChange)="changeSelectedModes()">
                                                        </p-checkbox>
                                                        Domicile Location
                                                    </div>
                                                </div>
                                                <div class="row mt-2 ps-4">
                                                    <div class="col-lg-4">
                                                        <p-checkbox name="trailerPools"
                                                            [value]="mode.modeId"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="trailerPoolModeIds"
                                                            (onChange)="changeSelectedModes()">
                                                        </p-checkbox>
                                                        Trailer Pool
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && form['modes'].errors"  class="invalid-feedback" style="padding-bottom: 10px;">
                                                <div *ngIf="form['modes'].errors['required']">Pick at least 1 mode</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label class="form-label">Address: </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <app-google-maps-search-box
                                              #googleMapsInput
                                              [isSubmittedOnce]="submitted"
                                              [isRequired]="true"
                                              [isRequiredPostalCode]="true"
                                              (onPlaceChanged)="onAddressChange($event)"
                                            ></app-google-maps-search-box>
                                            <br/>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactNameInput" class="form-label">Contact Name: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactName-field" class="form-control" formControlName="contactName" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactPhoneInput" class="form-label">Contact Phone: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactPhone-field" class="form-control" formControlName="contactPhone" />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-lg-2 label-form text-align-right">
                                            <label for="contactEmailInput" class="form-label">Contact Email: <span class="opacity-0">*</span></label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" id="contactEmail-field" class="form-control" formControlName="contactEmail" />
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer" id="add-service-area-md-footer">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-primary" (click)="proceedNextStep()">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="1" class="nav-item" (click)="setActiveTab(1);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Services Areas
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li> -->
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>
