import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';

@Component({
  selector: 'app-signed-contract',
  templateUrl: './signed-contract.component.html',
  styleUrls: ['./signed-contract.component.scss']
})
export class SignedContractComponent implements OnInit {
  @HostBinding('id') get htmlId() { return 'dtc-signed-contract'; }

  pdfUrl: SafeResourceUrl | undefined;

  constructor(
    private httpService: HttpService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const contractId = this.route.snapshot.paramMap.get('contractId') as string;
    if (!contractId) {
      console.error('No contract Id in url path');
      return;
    }

    this.httpService.getContractBase64(contractId)
      .subscribe(response => {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${response.base64}#toolbar=0&navpanes=0`);
      });

  }
}
