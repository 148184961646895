<ng-container *ngIf="pageMode === 'list'">
    <div class="btn-group">
        <div>
            <button type="button" 
                class="btn btn-primary licenses-btn" 
                (click)="goToAddPage()">
                Add Billing Profile
            </button>
        </div>
    </div>
    <table class="mt-4 table align-middle table-nowrap mb-0">
        <thead class="table-light">
            <tr>
                <th>Name</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let billingProfile of billingProfiles">
                <td>
                    <span class="clickable-column"
                        (click)="gotToEditPage(billingProfile)">
                        {{billingProfile.name}}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>    
</ng-container>

<ng-container *ngIf="pageMode === 'save'">
    <div class="row">
        <div class="col-md-6 col-lg-5">
            <div class="row">
                <form>
                    <div class="row">
                        <div class="col-lg-12">
                            <form [formGroup]="billingInfoForm">

                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Payment Type: <span class="opacity-0">*</span></label>
                                    </div>
                                    <div class="col-lg-8" style="padding-top: 8px;">
                                        {{originalBillingProfile?.type === 'prepaid' ? 'Credit Card' : 'Invoice'}}
                                        <button *ngIf="originalBillingProfile?.type === 'prepaid'"
                                            (click)="redirectToStripe()"
                                            type="button" class="ms-4 btn btn-sm btn-primary">
                                            Update Credit Card
                                        </button>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Name: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="name" type="text" class="form-control" placeholder="" required 
                                            [ngClass]="{ 'is-invalid': submitted && billingInfo['name'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Name
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Company Name: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="companyName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['companyName'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Company Name
                                        </div>  
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Address: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <app-google-maps-search-box 
                                            #googleMapsInput
                                            [defaultPlace]="defaultPlace"
                                            [showMap]="false" 
                                            [isSubmittedOnce]="submitted"
                                            [isRequired]="true"
                                            [isRequiredPostalCode]="true"
                                            (onPlaceChanged)="onPlaceChanged($event)">
                                        </app-google-maps-search-box>
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Contact First Name: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="firstName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['firstName'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Contact First Name
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Contact Last Name: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="lastName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['lastName'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Contact Last Name
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Contact Phone: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="phone" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['phone'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Contact Phone
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">Contact Email: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input formControlName="receiptEmail" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['receiptEmail'].errors }">
                                        <div class="invalid-feedback">
                                            Enter Contact Email
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mb-3">
                                    <div class="col-lg-4 padding-top text-align-right">
                                        <label class="form-label">CC Emails: <span class="opacity-0">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <textarea formControlName="additionalEmails"  class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
    
                                <div class="row mb-3 mt-5">
                                    <div class="col-lg-6 padding-top">
                                        <span *ngIf="originalBillingProfile" class="ms-2 text-danger">
                                            <span *ngIf="!isLoading" 
                                                (click)="deleteBillingProfile()"
                                                class="clickable-column inline-block text-danger">
                                                Delete  
                                            </span>
                                            <span *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-lg-6 button-submit">
                                        <button type="button" 
                                            class="btn btn-light  me-4" 
                                            data-bs-dismiss="modal" 
                                            (click)="goToListPage()">
                                            Cancel
                                        </button>
                                        <button type="button" id="save-btn" class="btn btn-primary" (click)="submit()" [disabled]="isLoading">
                                            <span *ngIf="!isLoading" class="flex-grow-1 me-2">
                                                Save
                                            </span>
                                            <span *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6 col-lg-7" *ngIf="originalBillingProfile">
            <app-invoices [billingProfileId]="originalBillingProfile.billingProfileId" [mainPage]="false"></app-invoices>
        </div>
    </div>
</ng-container>