import {Component, OnInit} from '@angular/core';
// Sweet Alert
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';

import { NgbdModalBuyLicenses } from '../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContactSales } from '../settings/licenses/modals/contact-sales/contact-sales.component';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { User } from 'src/app/core/services/models/auth.models';



@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss'],
  providers: []
})
export class CarriersComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Trading Relationships'
    }
  ];
  activeModal?: NgbModalRef;

  currentUser: User;

  myCarriers: any = [];
  // invited: any = [];
  pending: any = [];
  // declined: any = [];
  completed: any = [];
  blocked: any = [];


  constructor(
    public auth: AuthService,
    private httpRequest: HttpService
    ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.currentUserValue;
    console.log(this.currentUser);
    this.getMyCarriers();
    this.getPending();
    this.getCompleted();
    this.getBlocked();
  }

  getMyCarriers() {
    this.httpRequest.getMyCarriers().subscribe((data) => {
      this.myCarriers = data.data;
      if(this.myCarriers.length == 0) {
        this.myCarriers = ['1'];
      }
    })
  }


  getPending() {
    this.httpRequest.getPending().subscribe((data) => {
      this.pending = data.data;
      if(this.pending.length == 0) {
        this.pending = ['1'];
      }
    })
  }


  getCompleted() {
    this.httpRequest.getCompleted().subscribe((data) => {
      this.completed = data.data;
      if(this.completed.length == 0) {
        this.completed = ['1'];
      }
    })
  }

  getBlocked() {
    this.httpRequest.getBlocked().subscribe((data) => {
      this.blocked = data.data;
      if(this.blocked.length == 0) {
        this.blocked = ['1'];
      }
    })
  }

}
