import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { LoadBoardService } from 'src/app/core/services/load-board-service/load-board.service';
import { NgbdGridJsSortableHeader, SortEvent } from 'src/app/core/services/load-board-service/gridjs-sortable.directive';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { LoadBoardModel } from 'src/app/core/services/models/load-board.model';

// Sweet Alert
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { Mode } from 'src/app/core/services/models/mode.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Account } from 'src/app/core/services/models/account.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { NylasSchedulingPage, RawNylasSchedulingPage, RawNylasSchedulingPageResponse } from 'src/app/core/services/models/nylasSchedulingPage.model';
import { DocumentFlow } from 'src/app/core/services/models/document-flow.model';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';

declare const nylas:any;

@Component({
  selector: 'app-load-board',
  templateUrl: './load-board.component.html',
  styleUrls: ['./load-board.component.scss'],
  providers: [LoadBoardService, DecimalPipe]

})
export class LoadBoardComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    { label: 'Load Board' }
  ];
  activeModal?: NgbModalRef;
  modalType = '';
  error = '';
  submitted = false;
  currentUser: any;

  loadBoardForm!: FormGroup;
  routes: any = [];

  ListJsList$!: Observable<LoadBoardModel[]>;
  total$: Observable<number>;
  @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;

  inputBox = document.getElementById("duration") as HTMLInputElement | null;

  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  selectedAccount: (Account | null);
  subscriptions: Subscription[] = [];
  showAddButton: boolean = false;

  isMeetingAvailabilityEdittable = true;
  nylasSchedulingPage?: NylasSchedulingPage;
  rawNylasSchedulingPage?: RawNylasSchedulingPage;

  documentFlows: DocumentFlow[] = [];

  isLoading = false;

  constructor(
    private auth: AuthService,
    public service: LoadBoardService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private router: Router,
  ) {
    this.ListJsList$ = service.loadBoard$;
    this.total$ = service.total$;

    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject,
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if (this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)) {
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;
          this.getRoutes();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean {
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean {
    return this.selectedAccount?.accountId == account?.accountId
      && this.selectedMode?.modeId == mode?.modeId
      && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.currentUser = this.auth.currentUserValue;
    if (this.auth.currentAccountSelected.type == 'shipper-account' || this.auth.currentAccountSelected.type == 'broker-account') {
      this.showAddButton = true;
    } else {
      this.showAddButton = false;
    }
    this.loadDocumentFlows();
    this.initForms();
    this.dummyInit();
  }

  initForms() {
    this.submitted = false;
    this.loadBoardForm = this.formBuilder.group({
      type: ['dedicated', [Validators.required]],
      isPublic: [true, [Validators.required]],
      title: ['', [Validators.required]],
      postStartDate: ['', [Validators.required]],
      postEndDate: ['', [Validators.required]],
      description: ['', [Validators.required]],
      routeId: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      duration: [null, [Validators.required]],
      contactName: ['', [Validators.required]],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactPhone: ['', [Validators.required]],
      contactLink: ['', [Validators.required]],
      documentFlowId: ['', Validators.required],
    });
    this.resetDefaultValidators();
    this.loadBoardForm.get('postStartDate')!.valueChanges.subscribe(
      (postStartDate: Date) => {
        if (!postStartDate) {
          this.form['postEndDate'].setValue(undefined);
          return;
        }
        const postEndDate: Date = this.form['postEndDate'].value;
        if (!postEndDate) {
          return;
        }
        if (postEndDate.getTime() >= postStartDate.getTime()) {
          return;
        }
        this.form['postEndDate'].setValue(undefined);
      }
    );
  }

  get form() {
    return this.loadBoardForm.controls;
  }

  get contactLink() {
    return this.loadBoardForm.get('contactLink');
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  private loadDocumentFlows(){
    this.httpRequest.listDocumentFlows(
      this.selectedMode?.modeId,
      this.selectedBranch?.branchId
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<DocumentFlow[]>> res;
        this.documentFlows = successRes.data;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch document groups: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }


  async openModal(content: any, type: string, data: any) {
    this.isLoading = true;
    if(!this.nylasSchedulingPage){
      await this.createNylasSchedulingPage();
    }
    
    this.initForms();
    
    if (type == 'add') {
      this.modalType = 'Add';
    } else { }
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    });
    this.isLoading = false;
  }

  getRoutes() {
    this.httpRequest.getRoutes(
      this.selectedBranch?.branchId,
      this.selectedMode?.modeId,
      this.selectedAccount?.accountId
    ).subscribe((data) => {
      this.routes = data.data;
    })
  }

  isPublic(status: any) {
    if (status == 'public') {
      this.loadBoardForm.controls['isPublic'].setValue(true);
    } else {
      this.loadBoardForm.controls['isPublic'].setValue(false);
    }
  }

  async addLoad() {
    this.resetDefaultValidators();
    this.submitted = true;
    if (this.loadBoardForm.invalid) {
      return;
    }
    const isPaidPlan = this.auth.currentAccountPlanValue.status === 'paid';
    if (!isPaidPlan) {
      Swal.fire({
        title: "",
        text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Buy Pre-Release Assets'
      }).then(result => {
        if(result.isConfirmed){
          this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
        }
      });
      return;
    }
    const postStartDate = this.service.formatDateTime(this.loadBoardForm.controls['postStartDate'].value);
    const postEndDate = this.service.formatDateTime(this.loadBoardForm.controls['postEndDate'].value);
    const startDate = this.service.formatDateTime(this.loadBoardForm.controls['startDate'].value);
    const {title} = this.loadBoardForm.value;
    const schedulingPageId = this.nylasSchedulingPage!.schedulingPageId;

    const payload: Partial<LoadBoardModel> = {
      ...this.loadBoardForm.value,
      postStartDate,
      postEndDate,
      startDate
    };

    if(this.isMeetingAvailabilityEdittable){
      this.nylasSchedulingPage = (<SuccessApiResponse<NylasSchedulingPage>> await this.httpRequest.getNylasSchedulingPage(schedulingPageId).toPromise())
        .data;
      await this.httpRequest.updateNylasSchedulingPage(
        schedulingPageId,
        {
          title,
          calendars: this.nylasSchedulingPage.calendars?.map(cal => cal.calendarId) ?? []
        }
      ).toPromise();
      payload.nylasSchedulingPageId = this.nylasSchedulingPage.nylasPageId;
    }else{
      await this.httpRequest.deleteNylasSchedulingPage(schedulingPageId).toPromise();
    }

    this.httpRequest.addLoad(
      payload,
      this.selectedAccount?.accountId
    ).subscribe((data) => {
      this.activeModal?.close();
      this.initForms();
      this.submitted = false;
      this.clearNylasSchedulingPage();
      this.service.updateTable();
    });
  }

  pressEventEmail() {
    if (!this.loadBoardForm.controls['contactEmail'].value) {
      this.loadBoardForm.controls['contactPhone'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    } else if (this.loadBoardForm.controls['contactEmail'].value && this.loadBoardForm.controls['contactEmail'].valid) {
      this.loadBoardForm.controls['contactPhone'].clearValidators();
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].clearValidators();
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    } else if (this.loadBoardForm.controls['contactEmail'].invalid) {
      this.loadBoardForm.controls['contactPhone'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    }
  }

  pressEventPhone() {
    if (!this.loadBoardForm.controls['contactPhone'].value) {
      this.loadBoardForm.controls['contactEmail'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    } else {
      this.loadBoardForm.controls['contactEmail'].removeValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].clearValidators();
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    }
  }

  pressEventLink() {
    if (!this.loadBoardForm.controls['contactLink'].value) {
      this.loadBoardForm.controls['contactPhone'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
      this.loadBoardForm.controls['contactEmail'].addValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
    } else {
      this.loadBoardForm.controls['contactEmail'].removeValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
      this.loadBoardForm.controls['contactPhone'].clearValidators();
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
    }
  }

  checkContactDetailsValidity() {
    if (this.loadBoardForm.controls['contactEmail'].value) {
      this.loadBoardForm.controls['contactPhone'].clearValidators();
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].clearValidators();
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    } else if (this.loadBoardForm.controls['contactPhone'].value) {
      this.loadBoardForm.controls['contactEmail'].removeValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
      this.loadBoardForm.controls['contactLink'].clearValidators();
      this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    } else if (this.loadBoardForm.controls['contactLink'].value) {
      this.loadBoardForm.controls['contactEmail'].removeValidators([Validators.required]);
      this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
      this.loadBoardForm.controls['contactPhone'].clearValidators();
      this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
    }
  }

  resetDefaultValidators() {
    this.loadBoardForm.controls['contactEmail'].addValidators([Validators.required]);
    this.loadBoardForm.controls['contactEmail'].updateValueAndValidity();
    this.loadBoardForm.controls['contactPhone'].addValidators([Validators.required]);
    this.loadBoardForm.controls['contactPhone'].updateValueAndValidity();
    this.loadBoardForm.controls['contactLink'].addValidators([Validators.required]);
    this.loadBoardForm.controls['contactLink'].updateValueAndValidity();
    this.checkContactDetailsValidity();
  }

  navigateDetails(data: any) {
    if(this.auth.currentAccountSelected.type === 'carrier-account'){
      this.router.navigateByUrl('/pages/load-details', { state: { loadDetails: data } });
    }else if(this.auth.currentAccountSelected.type === 'shipper-account' || this.auth.currentAccountSelected.type == 'broker-account'){
      this.router.navigateByUrl('/pages/shipper-load-board', { state: { loadBoard: data } });
    }
  }

  applyjobChart: any;
  basicRadialbarChart: any;
  newjobChart: any;
  interviewChart: any;
  hiredChart: any;
  rejectedChart: any;
  dashedLineChart: any;
  candidatelist: candidateModel[] = [
    {
      id: '1',
      image: 'assets/images/users/avatar-10.jpg',
      name: 'Tonya Noble',
      insta: 'tonya',
      designation: 'Web Developer'
    },
    {
      id: '2',
      image: 'assets/images/users/avatar-1.jpg',
      name: 'Nicholas Ball',
      insta: 'nicholas',
      designation: 'Assistant / Store Keeper'
    },
    {
      id: '3',
      image: 'assets/images/users/avatar-9.jpg',
      name: 'Zynthia Marrow',
      insta: 'zynthia',
      designation: 'Full Stack Engineer'
    },
    {
      id: '4',
      image: 'assets/images/users/avatar-2.jpg',
      name: 'Cheryl Moore',
      insta: 'Cheryl',
      designation: 'Product Designer'
    },
    {
      id: '5',
      image: 'assets/images/users/avatar-5.jpg',
      name: 'Jennifer Bailey',
      insta: 'Jennifer',
      designation: 'Marketing Director'
    },
    {
      id: '6',
      image: 'assets/images/users/avatar-8.jpg',
      name: 'Hadley Leonard',
      insta: 'hadley',
      designation: 'Executive, HR Operations'
    }
  ];
  candidatedetail: any;
  followbtn: any = 1;
  followtxt: any = 'Follow';

  dummyInit(): void {
    this._basicRadialbarChart('["--vz-success"]');
    this._ApplyjobChart('["--vz-success"]');
    this._NewjobChart('["--vz-success"]');
    this._InterviewChart('["--vz-danger"]');
    this._HiredChart('["--vz-success"]');
    this._RejectedChart('["--vz-danger"]');
    this._dashedLineChart('["--vz-success", "--vz-info", "--vz-primary"]');
  }

  // Follow - unfollow
  followClick(ev: any) {
    if (this.followbtn == '1') {
      this.followbtn = '2'
      this.followtxt = 'Unfollow'
    } else {
      this.followbtn = '1'
      this.followtxt = 'Follow'
    }
  }

  opendetail(id: any) {
    this.candidatedetail = this.candidatelist[id]
  }

  private _dashedLineChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.dashedLineChart = {
      chart: {
        height: 345,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        }
      },
      colors: colors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [3, 4, 3],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      series: [{
        name: 'New Application',
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
      },
      {
        name: "Interview",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
        name: " Hired",
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      }
      ],
      markers: {
        size: 0,

        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
          '10 Jan', '11 Jan', '12 Jan'
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    };
  }

  //  Rejected Chart
  private _RejectedChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.rejectedChart = {
      series: [20],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }


  //  Hired Chart
  private _HiredChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.hiredChart = {
      series: [64],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }

  //  apply jobs Charts
  private _ApplyjobChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.applyjobChart = {
      series: [97],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }

  // Chart Colors Set
  private getChartColorsArray(colors: any) {
    colors = JSON.parse(colors);
    return colors.map(function (value: any) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
        if (color) {
          color = color.replace(" ", "");
          return color;
        }
        else return newValue;;
      } else {
        var val = value.split(',');
        if (val.length == 2) {
          var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
          return rgbaColor;
        } else {
          return newValue;
        }
      }
    });
  }

  /**
* TOTAL JOBS Chart
*/
  private _basicRadialbarChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.basicRadialbarChart = {
      series: [95],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }

  //  interview_chart
  private _InterviewChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.interviewChart = {
      series: [89],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }

  //  new jobs Charts
  private _NewjobChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.newjobChart = {
      series: [80],
      chart: {
        type: 'radialBar',
        width: 105,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%'
          },
          track: {
            margin: 1
          },
          dataLabels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              offsetY: 8,
            }
          }
        },
      },
      colors: colors,
    };
  }

  async edit(){
    nylas.scheduler.show({
      auth: {
        pageEditToken: this.rawNylasSchedulingPage!.edit_token
      },
      behavior: {
        displayOnly: ["event-info", "opening-hours"],
        disableSlugChanges: true,
        disableEditing: ["slug", "available_days_in_future"],
      },
      style: {
        // Style the Schedule Editor
        tintColor: "#32325d",
        backgroundColor: "white",
      },
      pageDomain: 'https://schedule.directtocarrier.io'
    });
  }

  clearNylasSchedulingPage() {
    this.nylasSchedulingPage = undefined;
    this.rawNylasSchedulingPage = undefined;
  }

  async createNylasSchedulingPage(){
    this.nylasSchedulingPage = (<SuccessApiResponse<NylasSchedulingPage>> await this.httpRequest.createNylasSchedulingPage(
      {
        title: 'Load Board Post Scheduling Page',
        calendars: [this.auth.currentUserValue.nylas?.calendarId!]
      }
    ).toPromise()).data;

    this.rawNylasSchedulingPage = (<SuccessApiResponse<RawNylasSchedulingPage>> await this.httpRequest
      .getRawNylasSchedulingPageById(this.nylasSchedulingPage.schedulingPageId).toPromise()).data;
  }

  //  Dashed line chart

  setapplicationvalue(value: any) {
    if (value == 'All') {
      this.dashedLineChart.series = [{
        name: 'New Application',
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
      },
      {
        name: "Interview",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
        name: " Hired",
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      }
      ]
    }
    if (value == '1M') {
      this.dashedLineChart.series = [{
        name: 'New Application',
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
        name: "Interview",
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      },
      {
        name: " Hired",
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
      }
      ]
    }
    if (value == '6M') {
      this.dashedLineChart.series = [{
        name: 'New Application',
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      },
      {
        name: "Interview",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
        name: " Hired",
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
      }
      ]
    }
    if (value == '1Y') {
      this.dashedLineChart.series = [{
        name: 'New Application',
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
      {
        name: "Interview",
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      },
      {
        name: " Hired",
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
      }
      ]
    }
  }

}

interface candidateModel {
  id: string;
  image: string;
  name: string;
  insta: string;
  designation: string;
}



