import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { AccountType } from 'src/app/core/services/models/account.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-billing-and-invoicing-menu',
  templateUrl: './billing-and-invoicing-menu.component.html',
  styleUrls: ['./billing-and-invoicing-menu.component.scss']
})
export class BillingAndInvoicingMenuComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Settings'
    }
  ];
  activeModal?: NgbModalRef;
  accountType: AccountType;
  @Input() activeTab: any = 1;
  @Output() changeChildTab: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.accountType = this.auth.currentAccountSelected.accountType; 
    console.log(this.auth.currentUserValue);
  }

  changeTab(event: any) {
    if(event == 1) {
      this.router.navigateByUrl("pages/settings/billing-and-invoicing/billing");
    } else if(event == 2) {
      this.router.navigateByUrl("pages/settings/billing-and-invoicing/invoicing");
    } else if(event == 3) {
      this.router.navigateByUrl("pages/settings/billing-and-invoicing/licenses");
    }
    this.changeChildTab.emit(event);
  }

}
