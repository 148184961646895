import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { Account } from '../models/account.model';
import { Branch } from '../models/branch.model';
import { Mode } from '../models/mode.model';
import { TreeNode } from 'primeng/api';
import { AccountPlan } from '../models/account-plan.model';
import { HttpService } from '../http-service';
import { SuccessApiResponse } from '../models/models';
import { BranchTreeNode } from 'src/app/pages/origin-points/origin-point/branches.service';
import { loginModel } from '../models/login.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
    baseUrl = environment.api;
    private currentUserSubject: BehaviorSubject<User>;
    public currentAccountPlanSubject: BehaviorSubject<AccountPlan> = new BehaviorSubject({status: 'trial'} as AccountPlan);
    public selectedAccountSubject: BehaviorSubject<Account | null>;
    public selectedBranchTreeNodeSubject: BehaviorSubject<BranchTreeNode> = new BehaviorSubject({});
    public selectedBranchSubject: BehaviorSubject<Branch | null>;
    public selectedModeSubject: BehaviorSubject<Mode | null>;
    private loadPermissions$: BehaviorSubject<Account | null | undefined>;
    public currentUser: Observable<User>;

    private httpService: HttpService

    constructor(
        private http: HttpClient,
        injector:Injector
      ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
        this.currentUser = this.currentUserSubject.asObservable();
        const selectedAccountStr = sessionStorage.getItem('selectedAccount');
        const selectedAccount = selectedAccountStr ? JSON.parse(selectedAccountStr) : null;
        this.selectedAccountSubject = new BehaviorSubject<Account | null>(selectedAccount);

        const selectedBranchStr = sessionStorage.getItem('selectedBranch');
        const selectedBranch = selectedBranchStr ? JSON.parse(selectedBranchStr) : null;
        this.selectedBranchSubject = new BehaviorSubject<Branch | null>(selectedBranch);

        const selectedModeStr = sessionStorage.getItem('selectedMode');
        const selectedMode = selectedModeStr ? JSON.parse(selectedModeStr) : null;
        this.selectedModeSubject = new BehaviorSubject<Mode | null>(selectedMode);

        // do not load permissions on first page load. Let AuthGuard load the permissions,
        // otherwise, both subscribed to loadPermissions$ and AuthGuard will call api to resolvedPermissions
        this.loadPermissions$ = new BehaviorSubject<Account | null | undefined>(undefined);

        setTimeout(() => {
          this.httpService = injector.get(HttpService);
          console.log('[this.httpService]', this.httpService);
          this.loadAccountPlan();
        });
    }

    async loadAccountPlan(): Promise<void> {
      if(!this.currentUserValue){
        return;
      }
      const res = await this.httpService.getAccountPlan(this.currentAccountSelected.accountId!).toPromise();
      const successRes = <SuccessApiResponse<AccountPlan>> res;
      const accountPlan = successRes.data;
      this.currentAccountPlanSubject.next(accountPlan);
    }

    public get currentAccountPlanValue(): AccountPlan {
      return this.currentAccountPlanSubject.value;
    }

    /**
     * current user
     */
    public get currentUserValue(): User {
      return this.currentUserSubject.value;
    }

    public subscribeToCurrentUser(fn: (user: User | null) => void): Subscription {
      return this.currentUserSubject.subscribe(fn);
    }

    public subscribeToSelectedAccount(fn: (account: Account | null) => void, ...log: any[]): Subscription {
      return this.selectedAccountSubject.subscribe((account: Account | null) => {
        if (log && log.length > 0) {
          console.log(`[subscribeToSelectedAccount]`, ...log);
        }
        return fn(account);
      });
    }

    public subscribeToSelectedBranch(fn: (branch: Branch | null) => void, ...log: any[]): Subscription {
      return this.selectedBranchSubject.subscribe((branch: Branch | null) => {
        if (log && log.length > 0) {
          console.log(`[subscribeToSelectedBranch]`, ...log);
        }
        return fn(branch);
      });
    }

    public subscribeToSelectedMode(fn: (mode: Mode | null) => void, ...log: any[]): Subscription {
      return this.selectedModeSubject.subscribe((mode: Mode | null) => {
        if (log && log.length > 0) {
          console.log(`[subscribeToSelectedMode]`, ...log);
        }
        return fn(mode);
      });
    }

    public subscribeToLoadPermissions(fn: (account: Account | null) => void, ...log: any[]): Subscription {
      return this.loadPermissions$.subscribe((account: Account | null | undefined) => {
        if (account === undefined) {
          return; // ignore undefined
        }
        if (log && log.length > 0) {
          console.log(`[subscribeToLoadPermissions]`, ...log);
        }
        return fn(account);
      });
    }

    async setSelectedAccount(account: Account | null): Promise<void> {
      console.log(`setting selected account to '${account && account.accountId}'`);
      if (account) {
        sessionStorage.setItem('selectedAccount', JSON.stringify(account));
        await this.loadAccountPlan();
      } else {
        sessionStorage.removeItem('selectedAccount');
      }
      this.loadPermissions$.next(account);
    }

    setSelectedBranch(branchNode: TreeNode<Branch> | null): void {
      console.log(`setting selected branch to '${branchNode && branchNode?.data?.branchId}'`);
      if (branchNode) {
        sessionStorage.setItem('selectedBranch', JSON.stringify(branchNode.data));
        sessionStorage.setItem('selectedBranchNode', JSON.stringify(this.decycle(branchNode)));
      } else {
        sessionStorage.removeItem('selectedBranch');
        sessionStorage.removeItem('selectedBranchNode');
      }
      this.selectedBranchTreeNodeSubject.next(branchNode!);
      this.selectedBranchSubject.next(branchNode?.data || null);
    }

    setSelectedMode(modeNode: TreeNode<Mode> | null) {
      console.log(`setting selected mode to '${modeNode && modeNode?.data?.modeId}'`);
      console.log('[setSelectedMode] modeNode', modeNode);
      if (modeNode) {
        sessionStorage.setItem('selectedMode', JSON.stringify(modeNode.data));
        sessionStorage.setItem('selectedModeNode', JSON.stringify(modeNode));
      }else {
        sessionStorage.removeItem('selectedMode');
        sessionStorage.removeItem('selectedModeNode');
      }
      this.selectedModeSubject.next(modeNode?.data || null);
    }

    /**
     * Return the session's current selected account.
     * For newly opened tab, selectedAccount is undefined, because opening a new tab initializes a new session.
     * In this case, initialize it
     */
    public get currentAccountSelected(): Account {
      const accountStr = sessionStorage.getItem('selectedAccount');
      if (!accountStr) {
        const defaultAccount = this.currentUserValue.accounts[0];
        this.setSelectedAccount(defaultAccount);
        return defaultAccount;
      }
      return JSON.parse(accountStr);
  }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {

        return this.http.post<loginModel>(this.baseUrl + '/tokens', { email, password })

            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.data.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('toast', 'true');
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                }
                return user;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
      this.http.delete(`${this.baseUrl}/tokens/${this.currentUserValue.token}`)
        .subscribe(() => {
          // remove user from local storage to log user out
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null!);
          this.setSelectedAccount(null);
          this.setSelectedMode(null);
          this.setSelectedBranch(null);
          window.location.reload();
        });
    }

    getAuthToken() {
        let user: any = localStorage.getItem('currentUser');
        return user.token;
    }

    private decycle(obj: any | never, stack = []): any {
      if (!obj || typeof obj !== 'object')
          return obj;
      
      if (stack.includes(obj as never))
          return null;
  
      let s = stack.concat([obj as never]);
  
      return Array.isArray(obj)
          ? obj.map(x => this.decycle(x, s))
          : Object.fromEntries(
              Object.entries(obj)
                  .map(([k, v]) => [k, this.decycle(v, s)]));
  }
}
