<input [id]="inputId" class="pac-input form-control" type="text" placeholder=""
  [class.is-invalid]="formErrors.address"
  (change)="changeAddress($event)"
/>
<div class="invalid-feedback" style="padding-bottom: 10px; display:block" *ngIf="formErrors.address">
  {{formErrors.address}}
</div>
<div class="d-flex justify-content-center" *ngIf="showMap && loading">
  <div class="align-self-center me-1">
    Loading map ...
  </div>
  <span class="spinner-border">
    <span class="visually-hidden">Loading...</span>
  </span>
</div>
<div class="full" [hidden]="loading || !showMap">
    <div id="map"></div>
</div>
