import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errors = new Subject<string[]>();

  constructor() { }

  addErrors(errors: string[]): void {
    this.errors.next(errors);
  }

  getErrors(): Observable<string[]> {
    return this.errors.asObservable();
  }
}
