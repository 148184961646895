import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import {
  AssetJsonModel,
  CreateTrailerPayload,
  CreateTruckPayload,
  EldCompanyName,
  TrailerModel,
  TrailerTypeModel,
  TruckAndTrailerType,
  TruckModel,
  TruckOwnershipType,
  TruckTermUnit,
  TruckTypeModel
} from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';

import TRUCK_AND_TRAILER_TYPES from '../../../../assets/jsons/truck-makes-and-models.json';
import STATES from '../../../../assets/jsons/states.json';
import ELD_COMPANY_NAMES from '.././../../../assets/jsons/eld/eld-company-name.json';
import { ActivatedRoute, Router } from '@angular/router';
import { TABS, TabsKey, TabsValue } from '../vehicles.component';
import { getKeyByValue } from 'src/app/core/utils/commons';

interface FormData {
  name: string;
  isInService: boolean;
  vehicleType: string;
  ownershipType: TruckOwnershipType;
  vendorName: string;
  dateAcquired: string;
  termNumber: number;
  termUnits: TruckTermUnit;
  unitNumber: number;
  year: string;
  make: string;
  makeOther: string;
  model: string;
  modelOther: string;
  vin: string;
  licensePlate: string;
  licensePlateState: string;
  eldAobrdCompany: string;
  eldAobrdCompanyOther: string;
  eldAobrdMake: string;
  eldAobrdMakeOther: string;
  eldAobrdModel: string;
  eldAobrdModelOther: string;
  eldAobrdYear: string;
}

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.scss']
})
export class CreateVehicleComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [];
  urlVehicleType: TabsValue;
  readonly TABS = TABS;
  readonly TabKeys: TabsKey[] = Object.keys(TABS) as TabsKey[];
  readonly tabsEnum = TABS;

  vehicleTypeOptions: (TruckTypeModel[] | TrailerTypeModel[]) = [];
  ownershipTypeOptions: TruckOwnershipType[] = Object.values(TruckOwnershipType);
  readonly truckOwnershipTypeEnum = TruckOwnershipType;

  termOptions: TruckTermUnit[] = Object.values(TruckTermUnit);
  // selectedTerm = this.termOptions[0];
  stateOptions: AssetJsonModel[] = STATES;

  dateValue: any;

  form!: FormGroup;
  submitted = false;
  subscriptions: Subscription[] = [];
  readonly makeOptions: TruckAndTrailerType[] = TRUCK_AND_TRAILER_TYPES;
  modelOptions: (string | number)[] = [];

  eldCompanyOptions: string[] = [];
  eldMakeOptions: string[] = [];
  eldModelOptions: string[] = [];

  

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.subscriptions.push(
      this.auth.subscribeToSelectedAccount(
        async () => {
          return this.loadData();
        },
        'CreateVehicleComponent'
      )
    );
  }

  async loadData(): Promise<void> {
    if (!Object.values(TABS).includes(this.urlVehicleType)) {
      return; // don't load any data yet until we know what vehicle type we are working on
    }

    switch (this.urlVehicleType) {
      case TABS.Trucks:
        this.vehicleTypeOptions = await this.httpService.listTruckTypes().toPromise();
        break;
      case TABS.Trailers:
        this.vehicleTypeOptions = await this.httpService.listTrailerTypes().toPromise();
        break;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!Object.values(TABS).includes(params['activeTab'])) {
        return this.router.navigateByUrl('/404', {skipLocationChange: true});
      }

      this.urlVehicleType = params['activeTab'];
      this.initBreadcrumbs();
      this.loadData();
      console.log(this.urlVehicleType);
      return;
    });

    const activeTab: TabsValue = this.route.snapshot.params['activeTab'];

    this.form = this.formBuilder.group({
      name: ['', []],
      isInService: [false, []],
      vehicleType: ['', [Validators.required]],
      ownershipType: [TruckOwnershipType.Purchased, [Validators.required]],
      vendorName: [''],
      dateAcquired: ['', [Validators.required]],
      termNumber: ['', []],
      termUnits: [TruckTermUnit.Days, []],
      unitNumber: ['', [Validators.required]],
      year: ['', [Validators.required]],
      make: ['', [Validators.required]],
      makeOther: ['', []],
      model: ['', [Validators.required]],
      modelOther: ['', []],
      vin: ['', [Validators.required]],
      licensePlate: ['', [Validators.required]],
      licensePlateState: ['', [Validators.required]],
      eldAobrdCompany: ['', activeTab === TABS.Trailers ? []: [Validators.required]],
      eldAobrdCompanyOther: ['', []],
      eldAobrdMake: [''],
      eldAobrdMakeOther: ['', []],
      eldAobrdModel: [''],
      eldAobrdModelOther: ['', []],
      eldAobrdYear: [''],
    });

    this.setEldCompanyOptions();
    this.setEldMakeOptions();
    this.setEldModelOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initBreadcrumbs(): void {
    this.breadCrumbItems = [
      {
        label: 'Vehicles & Equipment',
        url: `/pages/vehicles-and-equipment/${this.urlVehicleType}`
      },
      {
        label: getKeyByValue(TABS, this.urlVehicleType) as TabsKey
      },
      {
        label: 'Add'
      }
    ];
  }

  get createForm() {
    return this.form.controls as {[key in keyof FormData]: FormControl};
  }

  setEldCompanyOptions(filter: Partial<EldCompanyName> = {}): void {
    this.eldCompanyOptions = [
      ...[...new Set(ELD_COMPANY_NAMES.map(i => i.companyName))]
        // .filter((companyName) => {
        //   return ELD_COMPANY_NAMES.findIndex(a => {
        //     if (companyName !== a.companyName) {
        //       return false;
        //     }

        //     if (filter.deviceName && filter.deviceName !== a.deviceName) {
        //       return false;
        //     }
        //     if (filter.modelNumber && filter.modelNumber !== a.modelNumber) {
        //       return false;
        //     }

        //     return true;
        //   }) > -1;
        // })
        .sort(),
      'Other'
    ];
  }

  setEldMakeOptions(filter: Partial<EldCompanyName> = {}): void {
    this.eldMakeOptions = [
      ...[...new Set(ELD_COMPANY_NAMES.map(i => i.deviceName))]
        .filter((deviceName) => {
          return ELD_COMPANY_NAMES.findIndex(a => {
            if (deviceName !== a.deviceName) {
              return false;
            }

            if (filter.companyName && filter.companyName !== a.companyName) {
              return false;
            }
            // if (filter.modelNumber && filter.modelNumber !== a.modelNumber) {
            //   return false;
            // }

            return true;
          }) > -1;
        })
        .sort(),
      'Other'
    ];
  }

  setEldModelOptions(filter: Partial<EldCompanyName> = {}): void {
    this.eldModelOptions = [
      ...[...new Set(ELD_COMPANY_NAMES.map(i => i.modelNumber))]
        .filter((modelNumber) => {
          return ELD_COMPANY_NAMES.findIndex(a => {
            if (modelNumber !== a.modelNumber) {
              return false;
            }

            if (filter.companyName && filter.companyName !== a.companyName) {
              return false;
            }
            if (filter.deviceName && filter.deviceName !== a.deviceName) {
              return false;
            }

            // console.log('[setEldModelOptions]', filter, a);
            return true;
          }) > -1;
        })
        .sort(),
      'Other'
    ]
  }

  getOtherValue(field: 'make' | 'model' | 'eldAobrdCompany' | 'eldAobrdMake' | 'eldAobrdModel'): string {
    if (this.createForm[field].value === 'Other') {
      return this.createForm[`${field}Other`]?.value;
    }

    return this.createForm[field].value;
  }

  buildCreatePayload(): CreateTruckPayload | CreateTrailerPayload {
    const payload: CreateTruckPayload | CreateTrailerPayload = {
      name: this.createForm.name.value,
      isInService: this.createForm.isInService.value,
      type: this.createForm.vehicleType.value,
      ownershipType: this.createForm.ownershipType.value,
      vendorName: this.createForm.vendorName.value,
      dateAcquired: this.createForm.dateAcquired.value,
      termNumber: this.createForm.termNumber.value || 0,
      termUnits: this.createForm.termUnits?.value || TruckTermUnit.Days,
      unitNumber: this.createForm.unitNumber.value,
      year: +this.createForm.year.value,
      make: this.getOtherValue('make'),
      model: this.getOtherValue('model'),
      vehicleIdNumber: this.createForm.vin.value,
      licensePlateNumber: this.createForm.licensePlate.value,
      licensePlateState: this.createForm.licensePlateState.value,
      eldCompany: this.getOtherValue('eldAobrdCompany'),
      eldMake: this.getOtherValue('eldAobrdMake'),
      eldModel: this.getOtherValue('eldAobrdModel'),
      eldYear: +this.createForm.eldAobrdYear.value
    };
    return payload;
  }

  getFormValidationErrors() {
    Object.keys(this.createForm).forEach(key => {
      const controlErrors: ValidationErrors | null | undefined = this.form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  onSubmit() {
    // console.log('[onSubmit]', this.form);
    this.submitted = true;
    if (this.form.invalid) {
      console.log('[onSubmit] - invalid form', this.getFormValidationErrors());
      return;
    } else {
      const payload = this.buildCreatePayload();
      const createVehicle$: Observable<TruckModel | TrailerModel> = this.urlVehicleType === TABS.Trucks ?
        this.httpService.createTruck(payload) :
        this.httpService.createTrailer(payload);
      createVehicle$.subscribe(vehicle => {
        // console.log('[onSubmit] - successfully created truck', truck);
        this.router.navigate(['/pages/vehicles-and-equipment', this.urlVehicleType]);
      });
    }
  }

  onOwnershipTypeChange(type: TruckOwnershipType) {
    if (type === TruckOwnershipType.Purchased) {
      this.createForm.termNumber.removeValidators(Validators.required);
      this.createForm.termUnits.removeValidators(Validators.required);
    } else {
      this.createForm.termNumber.addValidators(Validators.required);
      this.createForm.termUnits.addValidators(Validators.required);
    }
    this.createForm.termNumber.updateValueAndValidity();
    this.createForm.termUnits.updateValueAndValidity();
  }

  onMakeChange(event: TruckAndTrailerType) {
    if (event.models.length === 0) {
      this.modelOptions = ['Other'];
      // this.createForm.model.setValue('Other');
    } else {
      this.modelOptions = event.models;
      // this.createForm.model.setValue(undefined);
    }
    this.createForm.model.setValue(undefined);

    // Update Make Other validator
    if (this.createForm.make.value === 'Other') {
      this.createForm.makeOther.addValidators(Validators.required);
    } else {
      this.createForm.makeOther.removeValidators(Validators.required);
    }
    this.createForm.makeOther.updateValueAndValidity();
  }

  onModelChange(event: string | number) {
    // Update Model Other validator
    if (this.createForm.model.value === 'Other') {
      this.createForm.modelOther.addValidators(Validators.required);
    } else {
      this.createForm.modelOther.removeValidators(Validators.required);
    }
    this.createForm.modelOther.updateValueAndValidity();
  }

  onEldCompanyChange(companyName: string) {
    this.setEldMakeOptions({
      companyName,
      modelNumber: '', // this.createForm.eldAobrdModel.value,
      deviceName: '', // this.createForm.eldAobrdMake.value
    });
    this.setEldModelOptions({
      companyName,
      modelNumber: '', //this.createForm.eldAobrdModel.value,
      deviceName: '', //this.createForm.eldAobrdMake.value
    });
    this.createForm.eldAobrdMake.setValue(undefined);
    this.createForm.eldAobrdModel.setValue(undefined);

    // Update Other validator
    if (this.createForm.eldAobrdCompany.value === 'Other') {
      this.createForm.eldAobrdCompanyOther.addValidators(Validators.required);
    } else {
      this.createForm.eldAobrdCompanyOther.removeValidators(Validators.required);
    }
    this.createForm.eldAobrdCompanyOther.updateValueAndValidity();
  }

  onEldMakeChange(eldMake: string) {
    // this.setEldCompanyOptions({
    //   companyName: this.createForm.eldAobrdCompany.value,
    //   modelNumber: this.createForm.eldAobrdModel.value,
    //   deviceName: eldMake
    // });
    this.setEldModelOptions({
      companyName: this.createForm.eldAobrdCompany.value,
      modelNumber: '', // this.createForm.eldAobrdModel.value,
      deviceName: eldMake
    });
    this.createForm.eldAobrdModel.setValue(undefined);

    // Update Other validator
    if (this.createForm.eldAobrdMake.value === 'Other') {
      this.createForm.eldAobrdMakeOther.addValidators(Validators.required);
    } else {
      this.createForm.eldAobrdMakeOther.removeValidators(Validators.required);
    }
    this.createForm.eldAobrdMakeOther.updateValueAndValidity();
  }

  onEldModelChange(eldModel: string) {
    // this.setEldCompanyOptions({
    //   companyName: this.createForm.eldAobrdCompany.value,
    //   modelNumber: eldModel,
    //   deviceName: this.createForm.eldAobrdMake.value
    // });
    // this.setEldMakeOptions({
    //   companyName: this.createForm.eldAobrdCompany.value,
    //   modelNumber: eldModel,
    //   deviceName: this.createForm.eldAobrdMake.value
    // });

    // Update Other validator
    if (this.createForm.eldAobrdModel.value === 'Other') {
      this.createForm.eldAobrdModelOther.addValidators(Validators.required);
    } else {
      this.createForm.eldAobrdModelOther.removeValidators(Validators.required);
    }
    this.createForm.eldAobrdModelOther.updateValueAndValidity();
  }

  conditionalRequired = (condition: boolean) => (control: FormControl) => {
    if (condition && Validators.required(control)) {
      return { required: true };
    }
    return null;
  };
}
