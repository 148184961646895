<app-breadcrumbs *ngIf="showBreadCrumbs" [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div *ngIf="showButtons" class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div>
                            <button type="button" class="btn btn-primary add-btn me-4" data-bs-toggle="modal" id="create-btn" routerLink="/pages/create-lane">
                                <i class="ri-add-line align-bottom me-1"></i> Add Lane </button>
                            <button type="button" class="btn btn-primary add-btn visibly-disabled" data-bs-toggle="modal" (click)="promptUpgrade()">
                                <i class="ri-add-line align-bottom me-1"></i> Upload Lanes </button>
                        </div>
                    </div>
                </div>
                <table class="table align-middle table-nowrap mt-3 mb-1">
                    <thead class="table-light">
                        <tr>
                            <th>Name</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th class="text-align-right">Estimated Miles</th>
                            <th class="text-align-right">Estimated Time</th>
                            <th>Trailer</th>
                            <th class="text-align-right">Weight</th>
                            <th class="text-align-right">Pallets</th>
                            <th class="text-align-right">Suggested Rate</th>
                            <th class="text-align-right">Total $</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showData">
                        <ng-container *ngFor="let lane of lanes" >
                            <tr [ngClass]="{'border-bottom-white': lane.stopsImageUrl}">
                                <td>
                                    <a class="clickable-column" [routerLink]="['/pages/edit-lane']" [state]="{lane}">
                                        <u>{{lane.name}}</u>
                                    </a>
                                </td>
                                <td>
                                    {{lane.firstStopCity + ", " + lane.firstStopState}}
                                </td>
                                <td>
                                    {{lane.lastStopCity + ", " + lane.lastStopState}}
                                </td>
                                <td class="text-align-right">{{lane.estimatedDistance | number:'1.2-2'}}</td>
                                <td class="text-align-right">{{(lane.estimatedTime/60 < 1 ? '': (lane.estimatedTime/60 | number: '1.0-0') + ' hrs ')}}{{lane.estimatedTime%60 | number: '1.0-0'}} mins </td>
                                <td>{{trailerTypeIdMap.get(lane.trailerTypeId)?.name}}</td>
                                <td class="text-align-right">{{lane.weight | number}}</td>
                                <td class="text-align-right">{{lane.pallets | number}}</td>
                                <td class="text-align-right">${{lane.suggestedRate | number:'1.2-2'}}</td>
                                <td class="text-align-right">${{(lane.suggestedRate + lane.fuelSurchargeRate) * lane.estimatedDistance| number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="lane.stopsImageUrl">
                                <td colspan="10">
                                    <img class="postal-codes-image" 
                                        [src]="lane.stopsImageUrl + '?cacheBust=' + cacheBust"/>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tbody *ngIf="!showData">
                        <tr class="loader-body">
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>