import ZIP_LNG_LAT from '../../../assets/jsons/zip-lng-lat.json';
import ZIP_CODES from '../../../assets/jsons/zip-codes.json';

const _cacheZipLngLat: { [zipCode in string]: [number, number] } = {}; // key is zipcode, value is [longitude, latitude]
const _cacheZipCodes: { [zipCode in string]: {
  longLat: [number, number],
  children: string[],
  boundaryExists: boolean
}} = {}; // key is zipcode, value is [longitude, latitude]

ZIP_LNG_LAT.forEach(z => {
  _cacheZipLngLat[z.Zip] = [+z.Longitude.trim(), +z.Latitude.trim()];
});

(ZIP_CODES as {zip: string, lat: number, lng: number, parent_zcta: string, boundary_exists: boolean}[]).forEach(z => {
  if (z.parent_zcta) {
    const parent = _cacheZipCodes[z.parent_zcta];
    if (parent) {
      parent.children.push(z.zip);
    } else {
      _cacheZipCodes[z.parent_zcta] = {
        longLat: [0, 0],
        children: [z.zip],
        boundaryExists: false
      }
    }
  }
  const cache = _cacheZipCodes[z.zip];
  if (cache) {
    cache.longLat = [z.lng, z.lat];
    cache.boundaryExists = z.boundary_exists;
  } else {
    _cacheZipCodes[z.zip] = {
      longLat: [z.lng, z.lat],
      children: [],
      boundaryExists: z.boundary_exists
    };
  }
});

export function getZipCodes():  {zip: string, lat: number, lng: number, boundary_exists: boolean}[]{
  return (ZIP_CODES as {zip: string, lat: number, lng: number, boundary_exists: boolean}[]);
}

export function getZipCodeChildren(zipCode: string): string[] | undefined{
  if (!_cacheZipCodes[zipCode]) {
    if (_cacheZipLngLat[zipCode]) {
      return 
    } else {
      console.error(`ZipCode not found: ${zipCode}`);
      return undefined;
    }
  }

  return _cacheZipCodes[zipCode].children; 
}

export function getZipCodeBoundaryExists(zipCode: string): boolean | undefined{
  if (!_cacheZipCodes[zipCode]) {
    if (_cacheZipLngLat[zipCode]) {
      return true;
    } else {
      console.error(`ZipCode not found: ${zipCode}`);
      return undefined;
    }
  }

  return _cacheZipCodes[zipCode].boundaryExists; 
}

export function getBounds(zipCodes: string[]): {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
} {
  let [long, lat] = getFirstLngLat(zipCodes) ?? [undefined, undefined];
  const box: {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  } = {
    minX: long ?? Number.MAX_VALUE,
    maxX: long ?? Number.MIN_VALUE,
    minY: lat ?? Number.MAX_VALUE,
    maxY: lat ?? Number.MIN_VALUE
  };

  zipCodes.forEach(zipCode => {
    [long, lat] = getZipCodeLngLat(zipCode) ?? [long, lat];
    if(!long || !lat){
      return;
    }
    if (long < box.minX) {
      box.minX = long;
    }
    if (long > box.maxX) {
      box.maxX = long;
    }
    if (lat < box.minY) {
      box.minY = lat;
    }
    if (lat > box.maxY) {
      box.maxY = lat;
    }
  });

  return box;
}

export function getFirstLngLat(zipCodes: string[]): [number, number] | undefined {
  for(const zipCode of zipCodes){
    const lngLat = getZipCodeLngLat(zipCode);
    if(lngLat){
      return lngLat;
    }
  }
  return undefined;
}

export function getZipCodeLngLat(zipCode: string): [number, number] | undefined {
  if (!_cacheZipCodes[zipCode]) {
    if (_cacheZipLngLat[zipCode]) {
      return _cacheZipLngLat[zipCode];
    } else {
      console.error(`ZipCode not found: ${zipCode}`);
      return undefined;
    }
  }

  return _cacheZipCodes[zipCode].longLat; 
}

export function isValidZipCode(zipcode: string): boolean{
    if(zipcode.length != 5){
        return false;
    }

    return !Number.isNaN(Number(zipcode));
}

export function getLatitudesLongitudesCenterPoint(zipCodes: string[]): [number, number] {
    if (!zipCodes || zipCodes.length === 0) {
      throw new Error('Zip Codes are required.');
    }
  
    const items = (ZIP_CODES as {zip: string, lat: number, lng: number}[]).filter(
      ({ zip }) => zipCodes.includes(zip),
    ).map(
      ({ lat, lng }) => [lat, lng],
    );
  
    if (!items || items.length === 0 || zipCodes.length !== items.length) {
        const validZipCodes = Object.keys(_cacheZipCodes);
        const invalidZipCodes = zipCodes.filter(
            zip => !validZipCodes.includes(zip)
        );
        console.error('The zip code list is incorrect. Invalid zips: ' + invalidZipCodes);
    }
  
    const coordinatesLen = items.length;
  
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
  
    for(let i = 0; i < coordinatesLen; i++){
        let lat = items[i][0] * Math.PI / 180;
        let lon = items[i][1] * Math.PI / 180;
  
        let a = Math.cos(lat) * Math.cos(lon);
        let b = Math.cos(lat) * Math.sin(lon);
        let c = Math.sin(lat);
  
        x += a;
        y += b;
        z += c;
    }
  
    x /= coordinatesLen;
    y /= coordinatesLen;
    z /= coordinatesLen;
  
    let lon = Math.atan2(y, x);
    let hyp = Math.sqrt(x * x + y * y);
    let lat = Math.atan2(z, hyp);
  
    return [(lon * 180 / Math.PI), (lat * 180 / Math.PI)];
  };