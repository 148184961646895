<app-breadcrumbs [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="table-responsive table-card mt-3 mb-1">
                    <p-treeTable #tt [value]="files" [columns]="cols" [lazy]="lazy" [loading]="loading" [scrollable]="true" [resizableColumns]="true" (onLazyLoad)="getData()" columnResizeMode="expand" styleClass="p-treetable-sm">
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style.width]="col.width">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" ttResizableColumn>
                                    {{col.header}}
                                </th>
                                <th> Action </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                            <tr [ngClass]="{'kb-row': rowData.routeId}">
                                <td *ngFor="let col of columns; let i = index" [ngClass]="{'p-toggler-column': i === 0}">
                                    <span>{{rowData.userInvitationId}}</span>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" style="margin-right: 25px;" (click)="acceptInvitation(rowData); selectedRow = rowData;"> Accept Invitation </button>
                                    <button class="btn btn-sm btn-danger" (click)="declineInvitation(rowData); selectedRow = rowData;"> Decline Invitation </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                </div>
            </div>
        </div>
    </div>
</div>