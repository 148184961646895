<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">{{currentShipperIntegration ? 'Edit': 'Add'}} Integration</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>
<form [formGroup]="shipperIntegrationForm">
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-lg-2 label-form text-align-right">
                <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <input type="text" id="name" class="form-control" name="name" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['name'].errors['required']">Enter name</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 label-form text-align-right">
                <label for="eldAobrdCompany" class="form-label">Software: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <ng-select class="custom form-control"
                    [items]="SOFTWARE_OPTIONS"
                    [clearable]="true"
                    formControlName="software"
                    [ngClass]="{ 'is-invalid': submitted && form['software'].errors }"
                ></ng-select>
                <div *ngIf="submitted && form['software'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['software'].errors['required']">Select software</div>
                </div>
                <input type="text"
                    class="form-control mt-2"
                    [class.visually-hidden]="form['software'].value !== 'Other'"
                    placeholder="Enter software"
                    formControlName="otherSoftware"
                    [class.is-invalid]="submitted && form['software'].value === 'Other' && !form['otherSoftware'].value"
                />
                <div *ngIf="submitted && form['software'].value === 'Other' && !form['otherSoftware'].value" 
                    class="text-danger" align="left">
                    Enter software
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 label-form text-align-right">
                <label for="eldAobrdCompany" class="form-label">Protocol: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <ng-select class="custom form-control"
                    [items]="PROTOCOL_OPTIONS"
                    [clearable]="true"
                    formControlName="protocol"
                    [ngClass]="{ 'is-invalid': submitted && form['protocol'].errors }"
                ></ng-select>
                <div *ngIf="submitted && form['protocol'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['protocol'].errors['required']">Select protocol</div>
                </div>
                <input type="text"
                    class="form-control mt-2"
                    [class.visually-hidden]="form['protocol'].value !== 'Other'"
                    placeholder="Enter protocal"
                    formControlName="otherProtocol"
                    [class.is-invalid]="submitted && form['protocol'].value === 'Other' && !form['otherProtocol'].value"
                />
                <div *ngIf="submitted && form['protocol'].value === 'Other' && !form['otherProtocol'].value" 
                    class="text-danger" align="left">
                    Enter protocol
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 label-form text-align-right">
                <label for="eldAobrdCompany" class="form-label">Format: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <ng-select class="custom form-control"
                    [items]="FORMAT_OPTIONS"
                    [clearable]="true"
                    formControlName="format"
                    [ngClass]="{ 'is-invalid': submitted && form['format'].errors }"
                ></ng-select>
                <div *ngIf="submitted && form['format'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['format'].errors['required']">Select format</div>
                </div>
                <input type="text"
                    class="form-control mt-2"
                    [class.visually-hidden]="form['format'].value !== 'Other'"
                    placeholder="Enter format"
                    formControlName="otherFormat"
                    [class.is-invalid]="submitted && form['format'].value === 'Other' && !form['otherFormat'].value"
                />
                <div *ngIf="submitted && form['format'].value === 'Other' && !form['otherFormat'].value" 
                    class="text-danger" align="left">
                    Enter format
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 padding-top text-align-right">
                <label class="form-label">Notes: <span class="opacity-0">*</span></label>
            </div>
            <div class="col-lg-9">
                <textarea class="form-control" row="3" formControlName="notes"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 padding-top text-align-right">
                <label class="form-label">Files: <span class="opacity-0">*</span></label>
            </div>
            <div class="col-lg-9">
                <input #fileInput class="d-none" type="file" multiple (change)="selectFiles($event)"/>
                <button type="button" 
                    class="btn btn-primary"
                    (click)="fileInput.click()">
                    Add Files
                </button>
                <table class="table align-middle table-nowrap mb-0">
                    <tbody>
                        <tr *ngFor="let name of fileNames">
                            <td class="w-100">{{name}}</td>
                            <td>
                                <button class="btn btn-sm btn-danger remove-item-btn"
                                    (click)="unselectFile(name)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="d-flex gap-2 p-4" 
        [ngClass]="{'justify-content-end': !currentShipperIntegration}">
        <span *ngIf="currentShipperIntegration" 
                class="clickable-column me-auto d-flex align-items-center text-danger"
                (click)="deleteMode()">
            <div *ngIf="!loading"   
                class="inline-block">
                Delete  
            </div>
            <div *ngIf="loading"  
                class="spinner-border inline-block save-loading" 
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </span>
        <button type="button" 
            class="btn btn-light" 
            data-bs-dismiss="modal" 
            (click)="close()"
            >
            Cancel
        </button>
        <button type="submit" class="btn btn-primary action-schedule-btn"
            (click)="save('https://calendly.com/directtocarrier/shipper')">
            <div *ngIf="!loading"   
                class="inline-block">
                Save And Schedule Call  
            </div>
            <div *ngIf="loading"  
                class="spinner-border inline-block save-loading" 
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
        <button type="submit" class="btn btn-primary action-btn"
            (click)="save()">
            <div *ngIf="!loading"   
                class="inline-block">
                Save  
            </div>
            <div *ngIf="loading"  
                class="spinner-border inline-block save-loading" 
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
    </div>
</form>