import { numberDisplay } from "../../utils/commons";
import { TrailerTypeModel, TruckTypeModel } from "./models";

export interface ServiceTypeTruckTrailerCombo {
    serviceTypeTruckTrailerComboId: string;
    serviceTypeId: string;
    serviceTypeName: string;
    truckType: string;
    truckTypeName: string;
    trailerType1: string;
    trailerType1Name: string;
    trailerType2: string;
    trailerType2Name: string;
    trailerType3: string;
    trailerType3Name: string;
}

export interface ServiceTypeTruckTrailerComboPayload {
    truckTypeId: string;
    trailerType1: string | null;
    trailerType2?: string | null;
    trailerType3?: string | null;
}

export class ServiceTypeTruckTrailerComboUtil {
    static getPrettyName(combo: ServiceTypeTruckTrailerCombo): string{
        const {truckTypeName, trailerType1Name, trailerType2Name, trailerType3Name} = combo;
        const trailerTypeNames = [trailerType1Name, trailerType2Name, trailerType3Name]
            .filter(trailerTypeName => trailerTypeName);

        if(!trailerTypeNames || trailerTypeNames.length === 0){
            return `${truckTypeName}`;
        }
        
        return `${truckTypeName} - ${trailerTypeNames.join(', ')}`
    }

    static getPrettyNameWithCapacity(
        combo: ServiceTypeTruckTrailerCombo,
        truckTypeIdMap: {[key: string]: TruckTypeModel},
        trailerTypeIdMap: {[key: string]: TrailerTypeModel}
    ): string{
        return `${ServiceTypeTruckTrailerComboUtil.getPrettyName(combo)}`
            + `  \u00A0 ${
                ServiceTypeTruckTrailerComboUtil.getTotalWeightVolumeCapacityFromTruckTypeAndTrailerType(
                    combo.truckType, combo.trailerType1, truckTypeIdMap, trailerTypeIdMap
                )
            }`;
    }

    static getTotalWeightVolumeCapacityFromTruckTypeAndTrailerType(
        truckTypeId:string, 
        trailerTypeId: string,
        truckTypeIdMap: {[key: string]: TruckTypeModel},
        trailerTypeIdMap: {[key: string]: TrailerTypeModel}
    ): string {
        const totalWeight = (truckTypeIdMap[truckTypeId]?.capacityWeight ?? 0)
            + (trailerTypeIdMap[trailerTypeId]?.capacityWeight ?? 0);
        const totalVolume = Number(truckTypeIdMap[truckTypeId]?.capacityVolume ?? 0) 
            + Number(trailerTypeIdMap[trailerTypeId]?.capacityVolume ?? 0);
        return `Weight=${numberDisplay(totalWeight)}, Volume=${numberDisplay(totalVolume)}`;
    }
}
