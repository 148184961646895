<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">{{initialChannel? 'Update': 'New'}} Channel</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="channelForm" *ngIf="channelForm">
        <div class="row mb-3">
            <div class="col-lg-3 padding-top no-wrap text-align-right">
                <label class="form-label">Name: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <input formControlName="name" type="text" class="form-control" id="phone-input" placeholder="" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
                <div class="invalid-feedback">
                    Enter Channel Name
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 padding-top no-wrap text-align-right">
                <ng-container *ngIf="authService.currentAccountSelected.accountType == 'shipper-account' || authService.currentAccountSelected.accountType == 'broker-account'">
                    <label class="form-label">Carrier: <span class="opacity-0">*</span></label>
                </ng-container>
                <ng-container *ngIf="authService.currentAccountSelected.accountType == 'carrier-account'">
                    <label class="form-label">Shipper: <span class="opacity-0">*</span></label>
                </ng-container>
            </div>
            <div class="col-lg-9">
                <ng-select [readonly]="!!initialChannel"
                    class="custom form-control" [items]="accountOptions" [clearable]="true"
                    [ngClass]="{ 'is-invalid': submitted && f['accountId'].errors }"
                    bindLabel="name" bindValue="accountId" formControlName="accountId"
                    [virtualScroll]="true"
                    (scrollToEnd)="onScrollToEndCarriers()"
                    (search)="onSearchCarriers($event)"
                >
                </ng-select>
                <div *ngIf="submitted && f['accountId'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="f['accountId'].errors['required']">
                        <ng-container *ngIf="authService.currentAccountSelected.accountType == 'shipper-account' || authService.currentAccountSelected.accountType == 'broker-account'">Select Carrier</ng-container>
                        <ng-container *ngIf="authService.currentAccountSelected.accountType == 'carrier-account'">Select Shipper</ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-3 shipper-padding-top no-wrap text-align-right">
                <label for="select-shipper" class="form-label">Team Members: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-9">
                <p-multiSelect id="select-shipper"
                    [ngClass]="{ 'is-invalid': submitted && f['coworkers'].errors }"
                    [options]="userNodeOptions"
                    scrollHeight="50vh"
                    display="chip"
                    selectionMode="checkbox"
                    formControlName="coworkers"
                ></p-multiSelect>
                <div *ngIf="submitted && f['coworkers'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="f['coworkers'].errors['required']">Select atleast 1 coworker</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-2 padding-top no-wrap">
            </div>
            <div class="col-lg-10">
                <div class="hstack gap-2 justify-content-end">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-primary action-btn" id="add-btn" (click)="submit()" [disabled]="isLoading">
                        <span class="flex-grow-1 me-2" *ngIf="!isLoading">
                            Save
                        </span>
                        <span class="spinner-border flex-shrink-0 channel-save-loading" role="status" *ngIf="isLoading">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

