import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import Swal from 'sweetalert2';

import { MENU, MENU_ID } from './menu';
import { MenuItem } from './menu.model';
import { NgbdModalBuyLicenses } from 'src/app/pages/settings/licenses/modals/buy-licenses/buy-licenses.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];
  @Output() mobileMenuButtonClicked = new EventEmitter();

  profileTaskRem = 5;
  messagesAlert = 8;
  hasUserInvitation = false;
  subscriptions: Subscription[] = [];

  constructor(
    public translate: TranslateService,
    private permissionsService: PermissionsService,
    private httpRequest: HttpService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) {
    translate.setDefaultLang('en');
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async () => {
        return this.loadData();
      })
    );
    this.subscriptions.push(
      this.authService.subscribeToCurrentUser(async () => {
        this.processMenu();
      })
    );
  }

  ngOnInit(): void {
    void this.loadData();
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    await this.setUserInvitations();
    this.processMenu();
  }

  processMenu(): void {
    this.menuItems = MENU
      .filter(item => this.permissionsService.checkMenuUserTypePermission(item))
      .map(item => {
        switch (item.id) {
          case MENU_ID.BRANCHES:
            item.hidden = !this.permissionsService.permissions.Branches.Read;
            break;
          case MENU_ID.VERIFICATION:
            if (item.badge) {
              item.badge.text = this.profileTaskRem + '';
            }
            break;
          case MENU_ID.MESSAGES:
            if (item.badge) {
              item.badge.text = this.messagesAlert + '';
            }
            break;
          case MENU_ID.USER_INVITATIONS:
            item.hidden = !this.hasUserInvitation;
            break;
          case MENU_ID.CONTRACTS:
            item.hidden = !this.permissionsService.permissions.Contracts.Read;
            break;
        }

        item.disabled = !this.permissionsService.checkMenuPlanTypePermission(item);
        return item;
      });
  }

  onClickMenuItem(item: MenuItem): void {
    if (item.disabled) {
      Swal.fire({
        title: "",
        text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Buy Pre-Release Assets'
      }).then(result => {
        if(result.isConfirmed){
          this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
        }
      });
    }
  }

  async setUserInvitations(): Promise<void> {
    return this.httpRequest.getUserInvitations().pipe(
      tap(data => {
        this.hasUserInvitation = data.data && data.data.length;
      })
    ).toPromise()
      .catch(() => {
        //do nothing?
      });
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    var sidebarsize = document.documentElement.getAttribute("data-sidebar-size");
    if (sidebarsize == 'sm-hover-active') {
      document.documentElement.setAttribute("data-sidebar-size", 'sm-hover')
    } else {
      document.documentElement.setAttribute("data-sidebar-size", 'sm-hover-active')
    }
  }

  /**
   * SidebarHide modal
   * @param content modal content
   */
   SidebarHide() {
    document.body.classList.remove('vertical-sidebar-enable');
  }

}
