import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { CreateTruckTrailerComboPayload, TrailerModel, TruckModel, TruckTrailerCombModel } from 'src/app/core/services/models/models';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { removeFromArray } from 'src/app/core/utils/commons';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';

@Component({
  selector: 'app-vehicle-combos-tab',
  templateUrl: './vehicle-combos-tab.component.html',
  styleUrls: ['./vehicle-combos-tab.component.scss']
})
export class VehicleCombosTabComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  activeModal?: NgbModalRef;
  error = '';
  createForm!: FormGroup;
  submitted = false;
  truckOptions: TruckModel[] = [];
  trailerOptions: TrailerModel[] = [];
  subscriptions: Subscription[] = [];
  modalType: 'Create' | 'Edit';
  searchTerm = '';
  allVehicles: TruckTrailerCombModel[] = [];

  constructor(
    public service: VehiclesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private authService: AuthService
  ) {
    super();
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async (data: any) => {
        return this.loadData();
      })
    );
  }

  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    const resp = await Promise.all([
      this.initTruckOptions(),
      this.initTrailerOptions(),
      this.httpService.listTruckTrailerCombos().toPromise()
    ]);
    this.allVehicles = resp[2];
  }

  initForms() {
    this.submitted = false;
    if (!this.createForm) {
      this.createForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        truckId: ['', [Validators.required]],
        trailerId1: ['', [Validators.required]],
      });
    } else {
      this.createForm.reset();
    }
  }

  async initTruckOptions(): Promise<void> {
    this.truckOptions = await this.httpService.listTrucks().toPromise();
  }

  async initTrailerOptions(): Promise<void> {
    this.trailerOptions = await this.httpService.listTrailers().toPromise();
  }

  initAddComboModal(): void {
    this.modalType = 'Create';
    this.initForms();
  }

  get createFormControls() {
    return this.createForm.controls as {[key in keyof CreateTruckTrailerComboPayload]: FormControl};
  }

  onClickAddCombo(modal: TemplateRef<any>): void {
    this.initAddComboModal();
    this.activeModal = this.modalService.open(modal, { size: 'lg', centered: true });
  }

  createCombo(): void {
    this.submitted = true;
    if (this.createForm.invalid) {
      return;
    } else {
      const payload: CreateTruckTrailerComboPayload = {
        name: this.createFormControls.name?.value,
        truckId: this.createFormControls.truckId.value,
        trailerId1: this.createFormControls.trailerId1.value,
      };
      this.httpService.createTruckTrailerCombo(payload).subscribe(combo => {
        this.activeModal?.close();
        this.allVehicles = [...this.allVehicles, combo];
      });
    }
  }

  saveCombo(): void {
    switch (this.modalType) {
      case 'Create':
        this.createCombo();
        break;
    }
  }

  deleteVehicles(id: string) {
    this.httpService.deleteTruckTrailerCombo(id).subscribe((data) => {
      this.allVehicles = removeFromArray(this.allVehicles, (a) => a.truckTrailerComboId === id);
    });
  }
}
