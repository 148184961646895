import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { ChannelMessage, PusherService } from 'src/app/core/services/pusher.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NgbdSaveChannelModal } from 'src/app/pages/messages/modals/save-channel/save-channel.component';
import { HttpService } from 'src/app/core/services/http-service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/services/models/auth.models';
import { Account } from 'src/app/core/services/models/account.model';
import { ActivatedRoute } from '@angular/router';
import { Channel } from 'src/app/core/services/models/channel.model';
import { LoadBoardModel } from 'src/app/core/services/models/load-board.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';


@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
    @Input()
    originalLoadBoard?: LoadBoardModel;
    currentUser: User;
    currentAccount: Account;

    formData!: FormGroup;
    submitted = false;

    activeModal?: NgbModalRef;
    channelList: Channel[] = [];
    chatMessagesData: any = [];
    isMessagedHistoryChecked: boolean = false;
    isMessagedChannelChecked: boolean = false;
    channelId: string = '';
    channelName: any = '';
    otherCompanyName: any = '';
    selectedChannel: any;
    accountId: any;
    channels: any = [];
    @ViewChild('scrollRef') scrollRef: any;
    today = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    dateHolder = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    scrollHeight: any;

    constructor(
        private modalService: NgbModal,
        private httpService: HttpService,
        private auth: AuthService,
        private pusherService: PusherService,
        public formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef
        // private lightbox: Lightbox,
        // private router: Router
    ) {
        this.currentUser = this.auth.currentUserValue;
        this.currentAccount = this.auth.currentAccountSelected;
    }

    ngOnInit(): void {
        this.route.queryParams
        .subscribe(
            params => {
                if(params['newChannel']=='true'){
                    const modalRef = this.openModalAddChannel();
                    modalRef.componentInstance.accountId = params['carrierAccountId'];
                }
            }
        );

        this.getChannels();
        // Validation
        this.formData = this.formBuilder.group({
            message: ['', [Validators.required]],
        });
    }

    displayMessageHistory(data: ChannelMessage[]) {
        const allMessages: (ChannelMessage & { type?: string; align?: string, date?: string })[] = data;
        if (this.chatMessagesData.length != 0) {
            this.chatMessagesData[0].date = '';
        }
        for (let x = 0; x < allMessages.length; x++) {
            if (allMessages[x].subchannel && allMessages[x].subchannel.includes('-common')) {
                allMessages[x]['type'] = 'public';
                allMessages[x]['align'] = 'left'
                if (x == 0) {
                    this.dateHolder = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                    allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                    this.chatMessagesData.unshift(allMessages[x]);
                } else {
                    if (this.dateHolder == this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime))) {
                        allMessages[x - 1]['date'] = '';
                        allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        this.chatMessagesData.unshift(allMessages[x]);
                    } else {
                        this.dateHolder = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        this.chatMessagesData.unshift(allMessages[x]);
                    }
                }
            } else {
                allMessages[x]['type'] = 'private';
                allMessages[x]['align'] = 'left'
                if (x == 0) {
                    this.dateHolder = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                    allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                    this.chatMessagesData.unshift(allMessages[x]);
                } else {
                    if (this.dateHolder == this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime))) {
                        allMessages[x - 1]['date'] = '';
                        allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        this.chatMessagesData.unshift(allMessages[x]);
                    } else {
                        this.dateHolder = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        allMessages[x]['date'] = this.pusherService.getFormattedDate(new Date(allMessages[x].sentTime));
                        this.chatMessagesData.unshift(allMessages[x]);
                    }
                }
            }
        }
        this.scrollBottom();
    }

    openModalAddChannel(): NgbModalRef {
        this.activeModal?.close();
        const modalRef = this.modalService.open(NgbdSaveChannelModal, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
        modalRef.result.finally(() => {
            this.getChannels();
        });
        return modalRef;
    }

    getChannels() {
        const httpCall = this.originalLoadBoard ?
            this.httpService.getChannels(this.originalLoadBoard.loadId)
            : this.httpService.getUserChannels();
        httpCall.subscribe(res => {
            this.isMessagedChannelChecked = true;
            const successRes = <SuccessApiResponse<Channel[]>> res;
            const channels = successRes.data;
            if (channels.length != 0) {
                this.channelList = channels;
                this.channelId = channels[0].channelId;
                this.channelName = channels[0].name;
                this.selectedChannel = channels[0];
                this.pusherService.setUserChannel(this.selectedChannel);
                this.accountId = this.currentAccount.accountId;
                this.pusherService.setChannelId(this.selectedChannel.channelId);
                this.pusherService.subscribeChannel.subscribe((data: ChannelMessage[]) => {
                    this.displayMessageHistory(data);
                    this.isMessagedHistoryChecked = true;
                });
                this.subscribeToPusher();
            }
        })
    }

    getOtherCompanyName() {
        return this.pusherService.getChannelName();
    }

    selectChannel(data: any) {
        this.isMessagedHistoryChecked = false;
        this.chatMessagesData = [];
        if (this.pusherService.unsubscribeChannels(this.channels)) {
            this.channels = [];
            this.channelId = data.channelId;
            this.channelName = data.name;
            this.selectedChannel = data;
            this.pusherService.setUserChannel(this.selectedChannel);
            this.pusherService.setChannelId(this.selectedChannel.channelId);
            this.pusherService.subscribeChannel.subscribe((data: ChannelMessage[]) => {
                this.chatMessagesData = [];
                this.displayMessageHistory(data);
                this.isMessagedHistoryChecked = true;
            }, error => {
                console.log('[subscribeChannel] error', error);
            });
            this.subscribeToPusher();
        }
    }

    subscribeToPusher() {
        this.pusherService.subscribePrivateChannelMessages().bind("client-message-new", (data: any) => {
            let message = JSON.parse(data.message);
            this.myAudioFunction();
            this.chatMessagesData.push(message);
            this.scrollBottom();
        });
        this.pusherService.subscribePresenceChannelMessages().bind("client-message-new", (data: any) => {
            let message = JSON.parse(data.message);
            this.myAudioFunction();
            this.chatMessagesData.push(message);
            this.scrollBottom();
        });
    }


    get form() {
        return this.formData.controls;
    }

    getMessage(messageType: string): string {
        return (document.getElementById('chat-input-' + messageType) as HTMLInputElement).value;
    }

    messageSave(messageType: string) {
        const message = this.formData.get('message')!.value;
        const currentDateEpochMilli = Date.now();
        let date: any;
        if (message) {
            if (messageType == 'public') {
                if (this.pusherService.checkIfMessageIfHasToday(this.chatMessagesData)) {
                    date = '';
                } else {
                    date = this.today;
                }
                this.chatMessagesData.push({
                    version: 1,
                    text: message,
                    channelId: this.pusherService.getChannelId(),
                    subchannel: this.pusherService.getChannelSubId(),
                    accountId: this.currentAccount.accountId,
                    companyName: this.currentAccount.name,
                    userId: this.currentUser.userId,
                    userFirstName: this.currentUser.firstName,
                    userLastName: this.currentUser.lastName,
                    sentTime: currentDateEpochMilli,
                    type: 'public',
                    date: date
                });
                this.pusherService.sendMessage('public', JSON.stringify({
                    version: 1,
                    text: message,
                    channelId: this.pusherService.getChannelId(),
                    subchannel: this.pusherService.getChannelSubId(),
                    accountId: this.currentAccount.accountId,
                    companyName: this.currentAccount.name,
                    userId: this.currentUser.userId,
                    userFirstName: this.currentUser.firstName,
                    userLastName: this.currentUser.lastName,
                    sentTime: currentDateEpochMilli,
                    type: 'public',
                    date: date
                }))
                this.scrollBottom();

                // Set Form Data Reset
                this.formData = this.formBuilder.group({
                    message: null,
                });
            } else {
                this.chatMessagesData.push({
                    version: 1,
                    text: message,
                    channelId: this.pusherService.getChannelId(),
                    subchannel: this.pusherService.getChannelPrivateSubId(),
                    accountId: this.currentAccount.accountId,
                    companyName: this.currentAccount.name,
                    userId: this.currentUser.userId,
                    userFirstName: this.currentUser.firstName,
                    userLastName: this.currentUser.lastName,
                    sentTime: currentDateEpochMilli,
                    type: 'private',
                    date: this.today
                });
                this.pusherService.sendMessage('private', JSON.stringify({
                    version: 1,
                    text: message,
                    channelId: this.pusherService.getChannelId(),
                    subchannel: this.pusherService.getChannelPrivateSubId(),
                    accountId: this.currentAccount.accountId,
                    companyName: this.currentAccount.name,
                    userId: this.currentUser.userId,
                    userFirstName: this.currentUser.firstName,
                    userLastName: this.currentUser.lastName,
                    sentTime: currentDateEpochMilli,
                    type: 'private',
                    date: this.today
                }))
                this.scrollBottom();
                this.formData = this.formBuilder.group({
                    message: null,
                });
            }

        }
        this.submitted = true;
    }
    myAudioFunction() {
        var x = document.createElement("AUDIO");
        x.setAttribute("src", "../../../assets/audio/pristine-609.mp3");
        x.setAttribute("autoplay", "");
        document.body.appendChild(x);
    }

    scrollBottom() {
        setTimeout(() => {
            this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
            this.scrollHeight = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
        }, 5);
    }

    inviteCoworker(selectedChannel: Channel){
        const modalRef = this.openModalAddChannel();
        modalRef.componentInstance.initialChannel = selectedChannel;
    }

}
