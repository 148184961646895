import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../core/services/http-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-oauth-login',
  templateUrl: './oauth-login.component.html',
  styleUrls: ['./oauth-login.component.scss']
})
export class OauthLoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private httpService: HttpService) {
    const paramToken = route.snapshot.queryParams['token'];
    const state = this.randomString(10);
    const paramType  = route.snapshot.paramMap.get('type');

    sessionStorage.setItem('token', paramToken);
    sessionStorage.setItem('state', state);

    window.location.href = `${environment.api}/oauth/${paramType}?token=${paramToken}&state=${state}`;
    // route.queryParams
    //   .subscribe(params => {
    //     this.token = params['token'];
    //     sessionStorage.setItem('token', this.token);
    //     this.state = this.randomString(10);
    //     sessionStorage.setItem('state', this.state);
    //     this.type  = params['type'];

    //     window.location.href = `https://dev-api.directtocarrier.io/oauth/${this.type}?token=${this.token}&state=${this.state}`;
    //   }
    // );
  }

  ngOnInit(): void {
  }

  randomString(length: number): string {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
}
