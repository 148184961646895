<!-- <ng-select [items]="selectValue" [multiple]="true" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="false" [hideSelected]="true" [clearSearchOnAdd]="true" (search)="addZip($event)" [searchWhileComposing]="true" [clearSearchOnAdd]="clearSearchKey"></ng-select> -->
<ng-select #selectEl
    [attr.data-name]="name"
    [ngClass]="customClass"
    [items]="selectValue"
    [multiple]="false"
    [virtualScroll]="true"
    [searchFn]="searchFn"
    [closeOnSelect]="true"
    [hideSelected]="true"
    [formControl]="$any(control)"
    (search)="addZip($event)"
    [searchWhileComposing]="true"
    [clearSearchOnAdd]="clearSearchKey"
    (blur)="onBlur($event)"
    (open)="onOpen()"
    (close)="onClose()"
    placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zip Codes"
></ng-select>
<div *ngIf="isShowError" class="invalid-feedback" align="left">
    <div *ngIf="control.errors!['required']!">Enter Zipcode</div>
</div>