/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {Injectable, OnDestroy, PipeTransform} from '@angular/core';

import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';

import { LoadBoardModel } from '../models/load-board.model';
import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortColumn, SortDirection} from './gridjs-sortable.directive';
import { HttpService } from 'src/app/core/services/http-service';
import { AuthService } from '../authentication/auth.service';
import { Mode } from '../models/mode.model';
import { Branch } from '../models/branch.model';
import { Account } from '../models/account.model';

interface SearchResult {
  loadBoard: LoadBoardModel[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(loadBoard: LoadBoardModel[], column: SortColumn, direction: string): LoadBoardModel[] {
  if (direction === '' || column === '') {
    return loadBoard;
  } else {
    return [...loadBoard].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(loadBoard: LoadBoardModel, term: string, pipe: PipeTransform) {
    return loadBoard.loadId.toLowerCase().includes(term.toLowerCase())
        || loadBoard.type.toLowerCase().includes(term.toLowerCase())
        || loadBoard.routeName.toLowerCase().includes(term.toLowerCase())
        || String(loadBoard.isPublic).toLowerCase().includes(term.toLowerCase())
        || loadBoard.title.toLowerCase().includes(term.toLowerCase())
        || loadBoard.description.toLowerCase().includes(term.toLowerCase())
        || loadBoard.routeId.toLowerCase().includes(term.toLowerCase())
        || loadBoard.startDate.toLowerCase().includes(term.toLowerCase())
        || String(loadBoard.duration).toLowerCase().includes(term.toLowerCase())
        || loadBoard.contactName.toLowerCase().includes(term.toLowerCase())
        || loadBoard.contactEmail.toLowerCase().includes(term.toLowerCase())
        || loadBoard.contactPhone.toLowerCase().includes(term.toLowerCase())
        || loadBoard.contactLink.toLowerCase().includes(term.toLowerCase())
    ;
}

@Injectable({providedIn: 'root'})
export class LoadBoardService implements OnDestroy{
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _loadBoard$ = new BehaviorSubject<LoadBoardModel[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    startIndex: 0,
    endIndex: 4,
    totalRecords: 0
  };

  private data: any;

  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  constructor(
    private pipe: DecimalPipe, 
    private httpRequest: HttpService,
    private authService: AuthService,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.updateTable();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get loadBoard$() { return this._loadBoard$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get startIndex() { return this._state.startIndex; }
  get endIndex() { return this._state.endIndex; }
  get totalRecords() { return this._state.totalRecords; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: SortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
  set startIndex(startIndex: number) { this._set({ startIndex }); }
  set endIndex(endIndex: number) { this._set({ endIndex }); }
  set totalRecords(totalRecords: number) { this._set({ totalRecords }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

    // 1. sort
    let loadBoard = sort(this.data, sortColumn, sortDirection);
    // 2. filter
    loadBoard = loadBoard.filter(loadBoard => matches(loadBoard, searchTerm, this.pipe));
    const total = loadBoard.length;

    // 3. paginate
    this.totalRecords = loadBoard.length;
    this._state.startIndex = (page - 1) * this.pageSize + 1;
    this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
        this.endIndex = this.totalRecords;
    }
    loadBoard = loadBoard.slice(this._state.startIndex - 1, this._state.endIndex);
    return of({loadBoard, total});
  }

  updateTable() {
    this.httpRequest.listLoads(
      this.selectedBranch?.branchId,
      this.selectedMode?.modeId,
      this.selectedAccount?.accountId
    ).subscribe((result: any) => {
        this.data = result.data;
        this._search$.pipe(
          tap(() => this._loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._loadBoard$.next(result.loadBoard);
          this._total$.next(result.total);
        });
    
        this._search$.next();
      });
  }

  formatDateTime(date: any): string {
    if(!date){
      return '';
    }
    const now = new Date(date);
    let dateTimeformat: any = '';
    dateTimeformat = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()).padStart(2, '0');
    return dateTimeformat
  }
}
