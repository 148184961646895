import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MessageService, TreeNode } from 'primeng/api';
import { Router } from '@angular/router';

// Language
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from 'src/app/pages/settings/licenses/modals/buy-licenses/buy-licenses.component';
import { Subject, Subscription } from 'rxjs';
import { BranchesService, BranchTreeNode } from 'src/app/pages/origin-points/origin-point/branches.service';
import { EventService } from 'src/app/core/services/event.service';
import { Mode } from 'src/app/core/services/models/mode.model';
import { ApiResponse, SuccessApiResponse } from 'src/app/core/services/models/models';
import { Account } from 'src/app/core/services/models/account.model';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';
import { isArrayEmpty } from 'src/app/core/utils/commons';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [MessageService],
})
export class TopbarComponent implements OnInit, OnDestroy {
  PAGES_USER_PROFILE = 'user-profile';
  PAGES_SETTINGS = 'settings/company/profile';
  PAGES_FAQ = 'faq';
  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  userProfle?: UserProfile;

  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  currentUser: any = {};

  activeModal?: NgbModalRef;
  driverWithHelperAvailable = 0;
  driverOnlyAvailable = 0;

  settingsActiveTab = 0;
  branchNodes: BranchTreeNode[] = [];
  selectedNodes3: any;
  default: BranchTreeNode | null;

  defaultAccount: any;
  accountsList: any[] = [];

  unsubscribe$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  public selectBranchPlaceholder = 'No Branch Available';

  modes: TreeNode<Mode>[] = [];
  defaultMode: TreeNode<Mode> | null;
  public selectModesPlaceholder = 'Loading Modes...';
  windowSize: any;
  userInitials = '';
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.windowSize = event.target.innerWidth;
    this.checkUserInitials();
  }
  
  constructor(
    @Inject(DOCUMENT) private document: any,
    public _cookiesService: CookieService,
    private auth: AuthService,
    private httpRequest: HttpService,
    private modalService: NgbModal,
    private router: Router,
    private branchService: BranchesService,
    private eventService: EventService,
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.windowSize = window.innerWidth;
    this.initSubscriptions();
    this.getAvailableLicenses();
    this.getAccount();
    this.element = document.documentElement;
    this.httpRequest.getUsersByIdOrEmail(this.currentUser.userId!).subscribe(res => {
      const successRes = <SuccessApiResponse<UserProfile>> res;
      this.userProfle = successRes.data;
    })
    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x: { lang: any; }) => x.lang === this.cookieValue);
    this.countryName = val.map((element: { text: any; }) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.svg';
      }
    } else {
      this.flagvalue = val.map((element: { flag: any; }) => element.flag);
    }
    this.checkUserInitials();
    this.subscriptions.push(
      this.auth.selectedBranchTreeNodeSubject.subscribe(
        branch => this.default = branch
      )
    );
  }

  initSubscriptions(): void {
    this.subscriptions.push(this.eventService.subscribeToBranchChanged(async (payload) => {
      const node = this.branchService.getNode(payload.branchId || '', this.branchNodes);
      if (!node) {
        console.warn('[TopbarComponent] [subscribeToBranchChanged] - updated branch not found', payload, this.branchNodes);
        return;
      }
      node.label = payload.name;
      node.data = payload as any;

      if(node.key === this.defaultAccount.key){
        this.defaultAccount = node;
      }

      this.auth.setSelectedBranch(node);
      this.branchNodes = [...this.branchNodes];
      this.eventService.broadCastBranchAddedOrChanged();
    }));
    this.subscriptions.push(this.eventService.subscribeToBranchAdded(async (payload) => {
      this.getAccount();
    }));
    this.subscriptions.push(this.eventService.subscribeToBranchDeleted(async (payload) => {
      this.auth.setSelectedBranch(isArrayEmpty(this.branchNodes) ? null : this.branchNodes[0]);
    }));
  }

  getAccount() {
    const { accounts } = JSON.parse(localStorage.getItem('currentUser')!);
    this.accountsList = this.branchService.manipulateDataFromTestJson({
      "children": accounts
    });
    if(!this.auth.currentAccountSelected) {
      if(this.accountsList && this.accountsList.length > 0) {
        let selectedAccount = this.branchService.manipulateDataFromTestJson({
          "children": [this.auth.currentAccountSelected]
        });
        this.defaultAccount = selectedAccount[0];
        this.accountsList = selectedAccount;
      }
    } else {
      let selectedAccount = this.branchService.manipulateDataFromTestJson({
        "children": [this.auth.currentAccountSelected]
      });
      this.defaultAccount = selectedAccount[0];
      this.getBranchModes();
    }
  }

  selectABranch(event: {node: BranchTreeNode}) {
    this.default = event.node;
    this.auth.setSelectedBranch(event.node);
    this.getBranchModes();
  }

  selectAnAccount(event: any) {
    this.auth.setSelectedAccount(event.node.data);
    this.defaultAccount = event.node;
    this.getBranchModes();
    this.default = null;
    this.defaultMode = null;
  }

  convertModeToTreeNode(data: Mode): TreeNode<Mode> {
    return {
      data,
      label: data.name,
      key: data.modeId,
      leaf: true
    };
  }

  getBranchModes() {
    this.selectModesPlaceholder = 'Loading Modes...';
    this.httpRequest.getTopbarModes().subscribe((data: ApiResponse<Mode[]>) => {
      if (data.error) {
        throw new Error(data.reason);
      }

      if(data.data.length != 0) {
        this.modes = data.data.map(d => this.convertModeToTreeNode(d));
        const selectedModeString = sessionStorage.getItem('selectedModeNode');
        this.defaultMode = selectedModeString
          ? JSON.parse(selectedModeString)
          : this.modes[0];
        this.auth.setSelectedMode(this.defaultMode);
        this.getBranchesByMode(this.defaultMode?.data?.modeId || '');
      } else {
        this.selectModesPlaceholder = 'No Modes Available';
        this.auth.setSelectedMode(null);
        this.getBranchesByMode('noMode');
        this.defaultMode = null;
        this.modes = [];
      }
    })
  }

  getBranchesByMode(modeId: string) {
    this.selectBranchPlaceholder = 'Loading Branches...';
    if(modeId == 'noMode') {
      this.selectBranchPlaceholder = 'No Branches Available';
      this.auth.setSelectedBranch(null);
    } else {
      this.httpRequest.getBranchesByMode(modeId).subscribe((data: any) => {
        this.updateSelectedAccountFromBranches(data.data);
        let result = {
          "data": [{
            "children": data.data
          }]
        };
        this.branchNodes = this.branchService.manipulateDataFromTestJson(result.data[0]);
        this.eventService.broadCastBranchAddedOrChanged();
        if(!this.branchNodes || this.branchNodes.length === 0 || !this.branchNodes[0]) {
          this.auth.setSelectedBranch(null);
          this.selectBranchPlaceholder = 'No Branches Available';
        } else {
          const branchInSession = sessionStorage.getItem('selectedBranch');
          const branchNodeInSession = sessionStorage.getItem('selectedBranchNode');
          const selectedBranch = branchInSession ? JSON.parse(branchInSession) : null;
          if (selectedBranch) {
            this.default = branchNodeInSession && branchNodeInSession !== undefined ? JSON.parse(branchNodeInSession) : this.branchNodes[0];
          }
          else {
            this.default = this.branchNodes[0];
          }
          this.auth.setSelectedBranch(this.default);
        }
      })
    }
  }

  private updateSelectedAccountFromBranches(branches: Account[]){
    const accountId = this.auth.currentAccountSelected.accountId;
    const branchAccount = branches.find(branch => branch.accountId! === accountId!);
    if(branchAccount){
      this.auth.setSelectedAccount(branchAccount);
    }
  }

  selectAMode(event: any) {
    this.auth.setSelectedMode(event.node);
    this.getBranchesByMode(event.node.data.modeId);
  }
  
  getAvailableLicenses() {
    this.driverWithHelperAvailable = 0;
    this.driverOnlyAvailable = 0;
    this.httpRequest.getAvailableLicenses().subscribe((data: { data: string | any[]; }) => {
      if (data.data.length != 0) {
        for (let x = 0; x < data.data.length; x++) {
          if (data.data[x].hasHelper) {
            this.driverWithHelperAvailable += 1;
          } else {
            this.driverOnlyAvailable += 1;
          }
        }
      }
    });
  }

  redirectToInvoices() {
    this.settingsActiveTab = 5;
    if (this.router.url != '/pages/settings') {
      this.router.navigateByUrl('/pages/settings', {
        state: { activeTab: this.settingsActiveTab },
      });
    } else {
      this.reloadComponent();
    }
  }

  redirectToLicenses() {
    this.settingsActiveTab = 6;
    if (this.router.url != '/pages/settings') {
      this.router.navigateByUrl('/pages/settings', {
        state: { activeTab: this.settingsActiveTab },
      });
    } else {
      this.reloadComponent();
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, {
      state: { activeTab: this.settingsActiveTab },
    });
  }

  reloadCurrentComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  checkUserInitials() {
    if(this.windowSize < 520) {
      this.userInitials = String(this.currentUser.firstName).charAt(0) + String( this.currentUser.lastName).charAt(0);
    }
  }

  // Set Topbar Branch Name
  setBranchName(text: string) {
    const myHtmlEl = document.getElementsByClassName('p-placeholder').item(0) as HTMLElement;
    myHtmlEl.innerHTML = text;
  }

  handleError(event: any) {
    event.target.src = 'assets/images/users/user-dummy-img.jpg';
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.mode = mode;

    switch (mode) {
      case 'light':
        document.body.setAttribute('data-layout-mode', 'light');
        document.body.setAttribute('data-sidebar', 'light');
        break;
      case 'dark':
        document.body.setAttribute('data-layout-mode', 'dark');
        document.body.setAttribute('data-sidebar', 'dark');
        break;
      default:
        document.body.setAttribute('data-layout-mode', 'light');
        break;
    }
  }

  /***
   * Language Listing
   */
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'Española', flag: 'assets/images/flags/spain.svg', lang: 'es' },
    { text: 'Deutsche', flag: 'assets/images/flags/germany.svg', lang: 'de' },
    { text: 'Italiana', flag: 'assets/images/flags/italy.svg', lang: 'it' },
    { text: 'русский', flag: 'assets/images/flags/russia.svg', lang: 'ru' },
    { text: '中国人', flag: 'assets/images/flags/china.svg', lang: 'ch' },
    { text: 'français', flag: 'assets/images/flags/french.svg', lang: 'fr' },
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
  }
  

  /**
   * Logout the user
   */
  logout() {
    this.auth.logout();
  }

  openUpgradePlanModal(){
    this.activeModal?.close();
    this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
  }

}

