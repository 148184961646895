import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngbd-modal-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],

})
export class NgbdModalHelp implements OnInit{
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef,
    private router: Router
   ) {}

  ngOnInit(): void { }

  close() {
    this.activeModal?.close();
  }


}