<div class="row">
    <div class="col-lg-12">
        <div class="row g-4 mb-3">
            <div class="col-sm-auto">
                <div>
                    <button type="button" class="btn btn-primary add-btn me-1"
                        (click)="openSaveModal(modal)">
                        New Document Group
                    </button>
                </div>
            </div>
        </div>
        <table class="table align-middle table-nowrap mt-3 mb-1">
            <thead class="table-light">
                <tr>
                    <th>Name</th>
                    <th>Expiration Date</th>
                </tr>
            </thead>
            <tbody *ngIf="showData">
                <ng-container *ngFor="let documentFlow of documentFlows" >
                    <tr>
                        <td>
                            <a class="clickable-column" (click)="openSaveModal(modal, documentFlow)">
                                <u>{{documentFlow.name}}</u>
                            </a>
                        </td>
                        <td>
                            {{Number(documentFlow.expirationDate) * 1000  | date}}
                        </td>
                </ng-container>
            </tbody>
            <tbody *ngIf="!showData">
                <tr class="loader-body">
                    <td class="placeholder-glow name-td">
                        <span class="placeholder col-6"></span>
                    </td>
                    <td class="placeholder-glow name-td">
                        <span class="placeholder col-6"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #modal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" style="margin: 0 auto">
            {{ orginalDocumentFlow ? 'Edit': 'New' }} Document Group
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="activeModal?.close()" style="margin-left: 0"></button>
    </div>
    <form [formGroup]="documentForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label text-align-right">
                    <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="name" class="form-control" name="name" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && documentFormControls['name'].errors }"/>
                    <div *ngIf="submitted && documentFormControls['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="documentFormControls['name'].errors['required']">Enter name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label text-align-right">
                    <label for="expirationDate" class="form-label">Expiration: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <div class="input-group flex-nowrap" mwlFlatpickr [convertModelValue]="true" minDate="today" formControlName="expirationDate"
                        [(ngModel)]="expirationDate">
                        <span class="input-group-text" id="addon-wrapping">
                            <i class="ri-calendar-event-line"></i>
                        </span>
                        <input class="form-control flatpickr-input" type="text" [value]="documentFormControls['expirationDate'].value | date" 
                            formControlName="expirationDate" [ngClass]="{ 'is-invalid': submitted && documentFormControls['expirationDate'].errors }">
                    </div>
                    <div *ngIf="submitted && documentFormControls['expirationDate'].errors" class="text-danger" align="left">
                        <div *ngIf="documentFormControls['expirationDate'].errors['required']">Enter expiration date</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label text-align-right">
                    <label for="expirationDate" class="form-label">Documents: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <table *ngIf="steps.length" class="steps-table w-100 align-middle table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Direction</th>
                                <th>Type</th>
                                <th>File</th>
                                <th></th>
                                <th>Service Types</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                            <tr *ngFor="let step of steps; let i=index" cdkDrag cdkDragLockAxis="y">
                                <td class="mt-4"
                                    cdkDragHandle>
                                    <div class="task-handle px-1 bg-light rounded">: :</div>
                                </td>
                                <td>{{i + 1}}</td>
                                <td class="drop-downs">
                                    <ng-select for="sendOrReceive" [items]="SEND_OR_RECEIVE_OPTIONS" class="form-control" 
                                        (change)="sendOrReceiveOptionChanged(i)"
                                        [(ngModel)]="step.direction" [ngModelOptions]="{standalone: true}"
                                        bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                    <div *ngIf="submitted && isNullish(step.direction)" class="text-danger"> Select Direction </div>
                                </td>
                                <td class="drop-downs">
                                    <ng-select for="type" [items]="DOCUMENT_TYPE_OPTIONS" class="form-control" 
                                        (change)="typeOptionChanged(i)"
                                        [(ngModel)]="step.type" [ngModelOptions]="{standalone: true}"
                                        bindLabel="name" bindValue="name" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                    <div *ngIf="submitted && isNullish(step.type)" class="text-danger"> Select Type </div>
                                </td>
                                <td class="drop-downs">
                                    <ng-container *ngIf="step.direction !== 'receive'">
                                        <ng-select for="documents" [items]="step.documents ?? []" 
                                            [(ngModel)]="step.documentId" [ngModelOptions]="{standalone: true}"
                                            class="form-control" bindLabel="displayName" bindValue="documentId" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                        <div *ngIf="submitted && isNullish(step.documentId)" class="text-danger"> Select Document </div>
                                    </ng-container>
                                </td>
                                <td>
                                    For
                                </td>
                                <td class="drop-downs">
                                    <ng-select for="serviceTypes" 
                                        [items]="
                                            step.serviceTypes && step.serviceTypes.includes('') 
                                                ? [ALL_SERVICE_TYPE]
                                                : serviceTypes
                                        " 
                                        class="form-control" 
                                        [(ngModel)]="step.serviceTypes" [multiple]="true" [ngModelOptions]="{standalone: true}"
                                        (change)="serviceTypeSelected(i)"
                                        bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false"></ng-select>
                                    <div *ngIf="submitted && (isNullish(step.serviceTypes) || !step.serviceTypes?.length)" class="text-danger"> Select Service Type </div>
                                </td>
                                <td>
                                    <span class="clickable-column text-danger" (click)="deleteStep(i)"> Delete </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="submitted && !this.steps.length" class="text-danger my-2"> Add at least 1 document </div>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" [disabled]="isLoading"
                        [ngClass]="{'mt-4': steps.length > 0}"
                        (click)="steps.push({})">
                        Add Document
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer px-5" [ngClass]="{'justify-content-end': !orginalDocumentFlow}">
            <span *ngIf="orginalDocumentFlow" class="clickable-column me-auto d-flex align-items-center text-danger">
                <div *ngIf="!isLoading" (click)="delete()" class="inline-block"> Delete </div>
                <div *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="submit" class="btn btn-light" data-bs-toggle="modal" 
                (click)="activeModal?.close()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" [disabled]="isLoading"
                (click)="submitDocumentFlows()">
                <span class="flex-grow-1 me-2">
                    Save
                  </span>
                  <span class="spinner-border save-loading" role="status" *ngIf="isLoading">
                      <span class="visually-hidden">Loading...</span>
                  </span>
            </button>
        </div>
    </form>
</ng-template>