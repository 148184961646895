import { Directive,HostListener,ElementRef, Renderer2, AfterViewInit, Input} from '@angular/core';

@Directive({
  selector: 'pdf-viewer[appCustomScrollTerms]',
  exportAs:'appCustomScrollTerms'
})
export class TermsScrollDirective implements AfterViewInit {

  disableBtn = true;

  private pdfNativeElement: HTMLElement;

  constructor(
    private eleRef:ElementRef,
    private renderer: Renderer2 
  ){}

  ngAfterViewInit(): void {
    this.pdfNativeElement = this.eleRef.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0];
    this.renderer.listen(this.pdfNativeElement, 'scroll', () => {
      this.onScrollEvent();
    });
  }

  onScrollEvent(){
    const pdfNativeElement = this.pdfNativeElement
    const top = pdfNativeElement.scrollTop;
    const offSetHeight = pdfNativeElement.offsetHeight;
    const scrollHeight= pdfNativeElement.scrollHeight;
    if(top === 0){
      this.disableBtn=true;
    }
    if(top>scrollHeight-offSetHeight-1){
      this.disableBtn=false;
    }
  }
}