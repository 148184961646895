import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { getKeyByValue } from 'src/app/core/utils/commons';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

export const TABS = {
  Trucks: 'trucks',
  Trailers: 'trailers',
  Combos: 'combos',
} as const;
export type TabsKey = keyof typeof TABS;
export type TabsValue = typeof TABS[TabsKey];

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
  providers: [VehiclesService, DecimalPipe]
})
export class VehiclesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [];
  readonly TABS = TABS;
  activeTab: TabsValue;
  readonly TabKeys: TabsKey[] = Object.keys(TABS) as TabsKey[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!Object.values(TABS).includes(params['activeTab'])) {
        return this.router.navigateByUrl('/404', {skipLocationChange: true});
      }

      this.activeTab = params['activeTab'];
      this.initBreadcrumbs();
      return;
    });
    
  }

  initBreadcrumbs(): void {
    this.breadCrumbItems = [
      {
        label: 'Vehicles & Equipment'
      },
      {
        label: getKeyByValue(TABS, this.activeTab) || ''
      }
    ];
  }

  setActiveTab(tabId: TabsValue) {
    this.activeTab = tabId;
    this.router.navigate([`../${this.activeTab}`], {
      relativeTo: this.route
    });
  }
}
