<div *ngIf="screen === 'listContract'" class="row">
    <div class="col-lg-4">
        <button *ngIf="selectedAccount?.accountType !== 'carrier-account'" 
            type="button" class="btn btn-primary add-btn me-1" 
            (click)="openNewDocument(modal);">
            New
        </button>
        <button type="button" class="btn btn-primary add-btn me-1" 
            (click)="openModalAddContractTemplate(modal)">
            <i class="ri-add-line align-bottom me-1"></i> Upload
        </button>
        <div *ngIf="selectedAccount?.accountType !== 'carrier-account'"  
            class="btn-group add-btn me-1" ngbDropdown #myDrop="ngbDropdown">
            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false"
                ngbDropdownToggle>
                Import
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" ngbDropdownItem
                    (click)="myDrop.close()">Direct To Carrier - Service Agreement v1</button>
                <button class="dropdown-item" ngbDropdownItem
                    (click)="myDrop.close()">Direct To Carrier - Service Agreement v2</button>
                <button class="dropdown-item" ngbDropdownItem
                    (click)="myDrop.close()">Direct To Carrier - Service Agreement v3</button>
            </div>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <button *ngIf="selectedAccount?.accountType !== 'carrier-account'" 
                    type="button" class="btn btn-primary download-ex-btn" data-bs-toggle="modal">
                    Tags
                </button>
                <div *ngIf="selectedAccount?.accountType !== 'carrier-account'" 
                    class="btn-group me-1" ngbDropdown #myDrop2="ngbDropdown">
                    <button type="button" class="btn btn-primary download-ex-btn" data-bs-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false"
                        ngbDropdownToggle>
                        Download Examples
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <button class="dropdown-item" ngbDropdownItem
                            (click)="downloadStandardHomeDeliveryDoc(); myDrop2.close()">
                            Direct to Carrier - Standard Home Delivery Agreement
                        </button>
                    </div>
                </div>
                <button *ngIf="selectedAccount?.accountType !== 'carrier-account'" 
                    type="button" class="btn btn-primary" data-bs-toggle="modal" (click)="openModalHelp()">
                    Help
                </button>
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="screen === 'listContract'">
    <table  class="table align-middle table-nowrap mb-0">
        <thead class="table-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col" class="type-td">Type</th>
            </tr>
        </thead>
        <tbody *ngIf="!isLoading">
            <tr *ngFor="let document of ListJsList$ | async">
                <td class="name-td w-25">
                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                    <span (click)="viewContractTemplateDetails(document, modal)">
                        {{document.displayName.toString().replace('.docx', '')}}
                    </span>
                </td>
                <td class="type-td">
                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                    <span>{{document.documentType}}</span>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isLoading">
            <tr class="loader-body">
                <td class="placeholder-glow name-td">
                    <span class="placeholder col-6"></span>
                </td>
                <td class="placeholder-glow">
                    <span class="placeholder col-4"></span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="(total$ | async)! && !isLoading"
        class="row justify-content-md-between align-items-md-center">
        <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries
            </div>
        </div>
        <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end gridjs-pagination">
                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" style="margin: 0 auto">
            <ng-container *ngIf="screen === 'newDocument'">
                New
            </ng-container>
            <ng-container *ngIf="screen === 'addContract'">
                Upload
            </ng-container>
            <ng-container *ngIf="screen === 'viewContract'">
                Update {{contractTemplateData.name}}
            </ng-container>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="loadAllContractTemplates();" style="margin-left: 0"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="screen === 'newDocument'">
            <form [formGroup]="addContractTemplateForm">
                <div class="modal-body">
                    <div class="row mb-3 ms-0">
                        <div class="col-lg-1 user-form-label">
                            <label for="name-field" class="form-label">Name: <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                            <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="form['name'].errors['required']">Enter Name</div>
                            </div>
                        </div>
                        <div class="col-lg-1 user-form-label">
                            <label for="name-field" class="form-label">Type: <span class="text-danger">*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <ng-select name="data" class="custom form-control" [items]="DOCUMENT_TYPE_OPTIONS" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true"
                                [hideSelected]="false" [clearSearchOnAdd]="true" bindLabel="name" bindValue="name" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }">
                            </ng-select>
                            <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="form['type'].errors['required']">Enter Type</div>
                            </div>
                        </div>
                    </div>
                    <editor
                        formControlName="body"
                        apiKey="txbcanytoasuhpzdz7k4enu2mtowefxvuldixj4byfd4zh38"
                        [init]="{
                            selector: 'textarea',
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            mergetags_list: TINYMCE_MERGETAGS_LIST,
                            content_css: 'document',
                            content_style: 'body { min-height: 1056.25px; margin-bottom: 1rem;}',
                            height: '100vh',
                            contextmenu: 'mergetags'
                        }"
                    ></editor>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-light" data-bs-toggle="modal" 
                        (click)="loadAllContractTemplates();">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary" data-bs-toggle="modal" (click)="submitDocument()" [disabled]="isLoading">
                        <span class="flex-grow-1 me-2">
                            Save
                          </span>
                          <span class="spinner-border save-loading" role="status" *ngIf="isLoading">
                              <span class="visually-hidden">Loading...</span>
                          </span>
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="screen === 'addContract'">
            <ngbd-add-contract-template (close)="loadAllContractTemplates()" 
                (add)="loadAllContractTemplates(); service.updateTable()">
            </ngbd-add-contract-template>
        </div>
    </div>
    <div *ngIf="screen === 'viewContract'" class="row">
        <div class="modal-body">
            <view-contract-template [contractTemplateData]="contractTemplateData" (close)="loadAllContractTemplates()"></view-contract-template>
        </div>
    </div>
</ng-template>