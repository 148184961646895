import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http-service';
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { Account } from 'src/app/core/services/models/account.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { FMCSA } from 'src/app/core/services/models/fmcsa.model';
import { LoadBoardModel } from 'src/app/core/services/models/load-board.model';
import { PaginatedResults, SuccessApiResponse } from 'src/app/core/services/models/models';
import { ProposalsModel, ProposalStatus } from 'src/app/core/services/models/proposals.model';
import { PageRequest, TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

type TableData = {
  // name: string,
  // city: string,
  // state: string,
  // accountId?:string;
  companyInfoId: string,
  accountId: string,
  type: string,
  legalEntity: string,
  legalName: string,
  dbaName: string,
  einNumber: string,
  mcNumber: string,
  dotNumber: string,
  hasAuthority: string,
  street: string,
  city: string,
  state: string,
  zipCode: string,
  email: string,
  phone: string,
  website: string,
  description: string,
  subtype: string,
  numberOfEmployees: number,
  foundingYear: any,
  logoUrl: string,
};

@Component({
  selector: 'app-broker-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss']
})
export class BrokerListComponent implements OnInit {
  @Input()
  originalLoadBoard?: LoadBoardModel;

  searchForm!: FormGroup;
  fmcsaForm!: FormGroup;
  activeTab = 0;
  activeNdx = 0;
  tableHelper: TablePaginationHelper<TableData>;
  currentCarrier?: TableData;
  loading = false;

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  domain = environment.domain;
  constructor(
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.tableHelper = new TablePaginationHelper(
      {
        loadRecords: (pageRequest) => {
          if(this.activeTab === 0 || this.activeTab === 1 || this.activeTab === 2){
            const name = (this.searchForm.controls['name'].value === '') ? '' : this.searchForm.controls['name'].value;
            const dotNumber = (this.searchForm.controls['dotNumber'].value === '') ? '' : this.searchForm.controls['dotNumber'].value;
            const zipCodes = (this.searchForm.controls['zipCodes'].value === '' || this.searchForm.controls['zipCodes'].value === null) ? '' : this.searchForm.controls['zipCodes'].value;
            const state = (this.searchForm.controls['state'].value === '') ? '' : this.searchForm.controls['state'].value;
            const city = (this.searchForm.controls['city'].value === '') ? '' : this.searchForm.controls['city'].value;
            const radius = (this.searchForm.controls['radius'].value === '' || this.searchForm.controls['radius'].value === null) ? '' : this.searchForm.controls['radius'].value + 'mi';
            
            return this.fetchCarriers(pageRequest, name, dotNumber, zipCodes, state, city, radius);

          }else if(this.activeTab === 3){
            const {name, dotNumber} = this.fmcsaForm.value;

            if(name){
              return this.httpRequest.paginatedGetFromList(
                ()=> this.httpRequest.lookupFMCSAnameAndDotNumber(name, dotNumber),
                pageRequest
              ).pipe(map(res => this.mapFMCSAtoTableData(res)))
              .toPromise();
            }
            
            if(dotNumber){
              return this.httpRequest.paginatedGetFromList(
                ()=> this.httpRequest.lookupFMCSAdotNumber(dotNumber).pipe(
                  map(
                    res => {
                      if (res.error) {
                        throw new Error(res.reason);
                      }
                      return {
                        ...res,
                        data: res.data && Object.keys(res.data).length > 0 
                          ? [res.data]
                          : []
                      }
                    }
                  )
                ),
                pageRequest
              ).pipe(map(res => this.mapFMCSAtoTableData(res))
              ).toPromise();
            }
            
            return this.fetchCarriers(pageRequest);
          }

          return this.fetchCarriers(pageRequest);
        }
      }
    );

    this.loadRecords();
    this.subscriptions.push(
      this.auth.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  loadRecords(): void {
    this.loading = true;
    this.tableHelper.loadRecords().then(
      () => {
        const records = this.tableHelper.searchResult;
        if(!isArrayEmpty(records)){
          this.currentCarrier = records[0];
        }else{
          this.currentCarrier = undefined;
        }
        this.loading = false;
      }
    );
  }

  private mapFMCSAtoTableData(fmcsaPage: PaginatedResults<FMCSA>): PaginatedResults<TableData>{
    return {
      ...fmcsaPage,
      results: fmcsaPage.results?.map(
        (data: any) => ({
          companyInfoId: data.companyInfoId,
          accountId: data.accountId,
          type: data.type,
          legalEntity: data.legalEntity,
          legalName: data.legalName,
          dbaName: data.dbaName,
          einNumber: data.einNumber,
          mcNumber: data.mcNumber,
          dotNumber: data.dotNumber,
          hasAuthority: data.hasAuthority,
          street: data.street,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          email: data.email,
          phone: data.phone,
          website: data.website,
          description: data.description,
          subtype: data.subtype,
          numberOfEmployees: data.numberOfEmployees,
          foundingYear: data.foundingYear,
          logoUrl: data.logoUrl
          // name: data.legalName + (data.dbaName ? ` (DBA: ${data.dbaName})`: ''),
          // city: data.city,
          // state: data.state,
        })
      ) ?? []
    }
  }

  fetchCarriers(
    pageRequest?: PageRequest,
    name: string = '',
    dotNumber: string = '',
    zipCodes: string = '',
    state: string = '',
    city: string = '',
    radius: string = '10mi',
  ): Promise<PaginatedResults<TableData>> {
    const params = (this.searchForm.controls['hasAuthority'].value === true) ? {
      name,
      dotNumber,
      state,
      city,
      zipCode: zipCodes,
      hasAuthority: '',
      radius,
    } : {
      name,
      dotNumber,
      zipCode: zipCodes
    };
    let searchType: string = '';
    if(this.activeTab === 0) {
      searchType = 'type=carrier-account';
      // searchType = 'type=shipper-account&type=broker-account';
    } else if(this.activeTab === 1) {
      searchType = 'type=shipper-account';
    } else if(this.activeTab === 2) {
      searchType = 'type=carrier-account&type=shipper-account';
    }
    return this.httpRequest.paginatedGetRequest<CompanyInfo>(
      '/companyInfos?' + searchType
      , {
        ...params as any,
        ...pageRequest
      }
    ).pipe(
      map(
        res => {
          return {
            ...res,
            results: res.results.map(
              (data: any) => ({
                companyInfoId: data.companyInfoId,
                accountId: data.accountId,
                type: data.type,
                legalEntity: data.legalEntity,
                legalName: data.legalName,
                dbaName: data.dbaName,
                einNumber: data.einNumber,
                mcNumber: data.mcNumber,
                dotNumber: data.dotNumber,
                hasAuthority: data.hasAuthority,
                street: data.street,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
                email: data.email,
                phone: data.phone,
                website: data.website,
                description: data.description,
                subtype: data.subtype,
                numberOfEmployees: data.numberOfEmployees,
                foundingYear: data.foundingYear,
                logoUrl: data.logoUrl
              }) as TableData
            )
          };
        }
      )
    ).toPromise()

  }

  get form() {
    return this.searchForm.controls;
  }

  initForms() {
    this.searchForm = this.formBuilder.group({
      name: [''],
      dotNumber: [''],
      zipCodes: [null],
      hasAuthority: [true],
      radius: [''],
      state: [''],
      city: [''],
    });
    this.fmcsaForm = this.formBuilder.group({
      name: [''],
      dotNumber: ['']
    });
  }

  createProposal(tableData: TableData){
    const carrierId = tableData.accountId!
    this.loading = true;
    this.httpRequest.getProposals({carrierId, loadId: this.originalLoadBoard?.loadId}).subscribe(
      res => {
        const successRes = <SuccessApiResponse<ProposalsModel[]>> res;
        const proposals = successRes.data;
        if(isArrayEmpty(proposals)){
          this.createProposalByCarrierId(carrierId);
        }else{
          this.loading = false;
          Swal.fire({
            title: 'Success',
            text: 'Carrier is already invited.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          })
        }
      },
      error => {
        this.loading = false;
        Swal.fire({
            title: 'Error',
            text: 'Failed to invite carrier: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
      }
    );
  }

  createProposalByCarrierId(carrierId: string){
    this.httpRequest.createProposal(
      {
        loadId: this.originalLoadBoard?.loadId!,
        carrierId,
        status: 'shipper_invited',
        message: 'Placeholder invite message.'
      }
    ).subscribe(
      res => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully invited carrier.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        });
        this.loadRecords();
      },
      error=>{
        this.loading = false;
        Swal.fire({
            title: 'Error',
            text: 'Failed to invite carrier: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
    }
    );
  }

  handleError(event: any) {
    event.target.src = 'assets/images/companies/img-4.png';
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  stopRedirect(event: Event) {
    event.preventDefault();
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

}
