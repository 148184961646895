import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { Branch, BranchMode } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { GoogleMapsSearchBoxComponent } from 'src/app/shared/google-maps-search-box/google-maps-search-box.component';
import branchesTestJson from '../../../../assets/jsons/test-branch-data.json'
import { BranchesService } from '../origin-point/branches.service';
import { BranchEvent } from '../origin-point/origin-point.component';
import { GoogleMapsPlaceResultJson } from 'src/app/core/services/models/google-maps-place-result-json.model';
import { Account } from 'src/app/core/services/models/account.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent implements OnInit {
  @Input()
  isRouted = true;
  @Input()
  parentBranch: Branch | Partial<Branch>;
  @Output()
  event = new EventEmitter<BranchEvent>();

  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Branches',
      url: '/pages/branches'
    },
    {
      label: 'Add'
    }
  ];

  @ViewChild('googleMapsInput') googleMapsInput: GoogleMapsSearchBoxComponent;

  currentUser: any;
  currentAccount: Account
  activeTab = 0;
  branchForm!: FormGroup;
  submitted = false;

  modes: Mode[] = [];

  shipperCompanyBranchTypes: any = [];
  carrierCompanyBranchTypes: any = [];
  hqBranchTypes: any = [];
  subsidiaryBranchTypes: any = [];
  organizationBranchTypes: any = [];
  regionBranchTypes: any = [];
  dcHubCrossDockBranchTypes: any = [];

  rootAccountType: any = 'subsidiary';
  branchesJsonTest: any = branchesTestJson.data;

  address!: google.maps.places.PlaceResult;

  statesJson: any = [];
  zipcodesJson: any;

  isPrefix = false;

  isMainBranch = false;

  nodes4: any[] = [];

  domicileLocationModeIds: string[] = [];
  trailerPoolModeIds: string[] = [];

  private routedBranchData: any;

  constructor(
    private auth: AuthService,
    private router: Router,
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    private branchService: BranchesService,
    private eventService: EventService,
  ) {
    this.routedBranchData = this.router.getCurrentNavigation()?.extras.state?.['branchData'];
  }

  ngOnInit(): void {
    if(this.isRouted){
      this.parentBranch = this.routedBranchData;
      if(!this.parentBranch) {
        this.router.navigate(['/pages/branches']);
        return;
      }
    }else{
      console.log('!this.parentBranch', !this.parentBranch);
      if(!this.parentBranch){
        this.parentBranch = {};
      }
    }
    
    if(Object.keys(this.parentBranch).length === 0) {
      this.isMainBranch = true;
      this.getBranches();
      this.getModes();
    } else {
      this.isMainBranch = false;
      this.getModes();
    }
    
    this.currentUser = this.auth.currentUserValue;
    this.currentAccount = this.auth.currentAccountSelected;
    this.initForm();
    this.rootAccountType = this.auth.currentAccountSelected.accountType;

    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });
    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });

    fetch('./assets/jsons/branch-types/shipper-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.shipperCompanyBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/carrier-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.carrierCompanyBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/hq-child-types.json').then(res => res.json()).then(jsonData => {
      this.hqBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/subsidiary-child-types.json').then(res => res.json()).then(jsonData => {
      this.subsidiaryBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/organization-child-types.json').then(res => res.json()).then(jsonData => {
      this.organizationBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/region-child-types.json').then(res => res.json()).then(jsonData => {
      this.regionBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/branch-types/dc-hub-crossdock-child-types.json').then(res => res.json()).then(jsonData => {
      this.dcHubCrossDockBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });
  }

  setActiveTab(tabId: number) {
    this.activeTab = tabId;
  }

  initForm() {
    const commonControls = {
        modes: ['', [Validators.required]],
        street: ['', []],
        city: ['', []],
        state: ['', []],
        zipcode: ['', []],
        contactName: [''],
        contactPhone: [''],
        contactEmail: [''],
    };
    if(this.parentBranch) {
      this.branchForm = this.formBuilder.group({
        parentId: [this.parentBranch.branchId, [Validators.required]],
        name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        type: [this.parentBranch.type, [Validators.required]],
        ...commonControls
      });
    } else {
      this.branchForm = this.formBuilder.group({
        parentId: ['', [Validators.required]],
        name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        type: ['', []],
        ...commonControls
      });
    }
  }

  getBranches() {
    this.httpRequest.getBranchesByHierarchy(false).subscribe((data: any) => {
      let result = {
        "data": [{
          "children": data.data
        }]
      };
      this.nodes4 = this.branchService.manipulateDataFromTestJson(result.data[0]);
    })
  }

  getModes() {
    if(Object.keys(this.parentBranch).length === 0) {
      console.log('no modes');
      this.httpRequest.getTopbarModes().subscribe((data: any) => {
        this.modes = data.data;
      });
    } else {
      console.log('has modes')
    }
    if(this.parentBranch.modes && this.parentBranch.modes.length != 0) {
      this.modes = this.parentBranch.modes;
    }
  }

  changeSelectedModes(){
    const modes = new Set(this.modesControl.value);
    this.domicileLocationModeIds.forEach(modeId => modes.add(modeId));
    this.trailerPoolModeIds.forEach(modeId => modes.add(modeId));
    this.modesControl.setValue([...modes]);
  }

  modesChanged(){
    const modes = new Set(this.modesControl.value);
    this.domicileLocationModeIds = this.domicileLocationModeIds.filter(
      modeId => modes.has(modeId)
    );
    this.trailerPoolModeIds = this.trailerPoolModeIds.filter(
      modeId => modes.has(modeId)
    );
  }

  get form() {
    return this.branchForm.controls;
  }
  
  get modesControl(): FormControl {
    return this.branchForm.controls['modes'] as FormControl;
  }

  selectedType(event: any) {
    this.isPrefix = this.branchService.checkIfPrefix(event.technicalName);
  }

  onAddressChange(address: google.maps.places.PlaceResult): void {
    this.address = address;
  }

  proceedNextStep() {
    this.submitted = true;
    const isBranchInvalid = this.branchForm.invalid;
    const isAdressInvalid = !this.googleMapsInput.validateForm();
    if(isBranchInvalid || isAdressInvalid) {
      return;
    } else {
      this.createCompanyInfo();
    }
  }


  selectParent(event: any) {
    this.parentBranch = event.node.data;
    this.branchForm.controls['parentId'].setValue(event.node.key);
    this.getModes();
  }

  createCompanyInfo() {
    const modes: string[] = this.modesControl.value;
    const placeJson = new GoogleMapsPlaceResultJson(this.address);
    const accountId = this.auth.selectedAccountSubject.getValue()?.accountId!;
    if(this.branchForm.controls['type'].value === 'hq' || this.branchForm.controls['type'].value === 'subsidiary' || this.branchForm.controls['type'].value === 'organization') {
     //default payload if the branch type is hq, subsidiary, or organization as per issue #1880
     //cant create a totally blank payload.
      let payload: any = {
        type: 'shipper-carrier',
        legalEntity: 'llc',
        legalName: 'default',
        street: '647 Ajroc Highway',
        city: 'Kapazin',
        state: 'WV',
        zipCode: '59991',
        email: this.auth.currentUserValue.email!,
        phone: '00000',
        hasAuthority: false,
      }
      this.httpRequest.createCompanyInfo(accountId, payload).subscribe((resp: any) => {
        const branch: Branch = {
          ...this.branchForm.value,
          address: new AddressComponent(this.address),
          name: this.branchForm.value.name,
          companyInfo: resp.data,
          modes: modes.map(
            modeId => ({
              modeId,
              domicileLocation: this.domicileLocationModeIds.includes(modeId),
              trailerPool: this.trailerPoolModeIds.includes(modeId)
            } as BranchMode)
          ),
          openingHours: placeJson.opening_hours,
          //TODO: https://github.com/project-dtc/issues/issues/1655#issuecomment-1500437455
          //secondaryOpeningHours: placeJson.secondary_opening_hours
        };
    
        this.addBranch(branch);
      })
    } else {
      console.log(this.parentBranch);
      const branch: Branch = {
        ...this.branchForm.value,
        address: new AddressComponent(this.address),
        name: this.branchForm.value.name,
        companyInfoId: this.parentBranch.companyInfoId,
        modes: modes.map(
          modeId => ({
            modeId,
            domicileLocation: this.domicileLocationModeIds.includes(modeId),
            trailerPool: this.trailerPoolModeIds.includes(modeId)
          } as BranchMode)
        ),
        openingHours: placeJson.opening_hours,
        //TODO: https://github.com/project-dtc/issues/issues/1655#issuecomment-1500437455
        //secondaryOpeningHours: placeJson.secondary_opening_hours
      };
      this.addBranch(branch);
    }

  }

  addBranch(branch: Branch) {
    this.httpRequest.addBranch(branch).subscribe((data: any) => {
      this.eventService.broadcastBranchAdded(data.data);
      if(this.isRouted){
        this.router.navigate(['/pages/branches']);
      }else{
        this.event.emit(
          {
            type: 'list-branch',
            branch: null
          }
        );
      }
    })
  }

  returnPrettyTypeName() {
    switch (this.branchForm.controls['type'].value) {
      case "hq":
        return 'HQ';
        break;
      case "store":
        return 'Store';
        break;
      case "remote-store":
        return 'Remote Store';
        break;
      case "dc":
        return 'DC';
        break;
      case "hub":
        return 'Hub';
        break;
      case "region":
        return 'Region';
        break;
      case "branch":
        return 'Branch';
        break;
      case "3pl-cross-dock":
        return '3PL Cross Dock';
        break;
      case "cross-dock":
        return 'Cross Dock';
        break;
      case "organization":
        return 'Organization';
        break;
      case "subsidiary":
        return 'Subsidiary';
        break;
      case "":
        return '';
        break;
      default:
        return 'Store';
        break;
    }
  }


}
