import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbModule,
  NgbToastModule
} from '@ng-bootstrap/ng-bootstrap';

import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule, NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Swiper Slider
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { LightboxModule } from 'ngx-lightbox';
import { TimeagoModule } from 'ngx-timeago';

// Load Icons
// import { defineLordIconElement } from 'lord-icon-element';
// import lottie from 'lottie-web';
import { DragDropModule } from '@angular/cdk/drag-drop';
// Pages Routing
import { PagesRoutingModule } from "./pages-routing.module";
import { EditorModule } from '@tinymce/tinymce-angular';

// Components
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { OriginPointComponent } from './origin-points/origin-point/origin-point.component';
import { LoadBoardComponent } from './load-board/load-board/load-board.component';
import { UserComponent } from './users/user/user.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { SettingsComponent } from './extrapages/profile-settings/settings.component';
import { LicensesComponent } from './settings/licenses/licenses.component';
import { BillingComponent } from './settings/billing/billing.component';
import { InvoicesComponent } from './settings/invoices/invoices.component';
import { InvoiceDetailsComponent } from './settings/invoice-details/invoice-details.component';
import { CompanySettingsComponent } from './extrapages/company-setting/company-settings.component';
import { ServiceAreaComponent } from './service-area/service-area.component';
import { ServiceableZipcodesComponent } from './serviceable-zipcodes/serviceable-zipcodes.component';
import { AccountsPayableComponent } from './accounts-payable/accounts-payable.component';
import { ZonesAndRoutesComponent } from './zones-and-routes/zones-and-routes.component';
import { UserCompanySettingsComponent } from './settings/settings.component';
import { CarriersComponent } from './carriers/carriers.component';
import { CarrierSearchComponent } from './carrier-search/carrier-search.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { FinalMileCertifiedComponent } from './final-mile-certified/final-mile-certified.component';
import { LoadDetailsComponent } from './load-board/load-details/load-details.component';
import { CarrierLoadBoardComponent } from './load-board/carrier-load-board/carrier-load-board.component';
import { CarrierLoadBoardInvitationsComponent } from './load-board/carrier-load-board/menus/invitations/invitations.component';
import { CarrierLoadBoardApplicationsComponent } from './load-board/carrier-load-board/menus/applications/applications.component';
import { CarrierLoadBoardInterviewingComponent } from './load-board/carrier-load-board/menus/interviewing/interviewing.component';
import { CarrierLoadBoardRejectedComponent } from './load-board/carrier-load-board/menus/rejected/rejected.component';
import { CarrierLoadBoardWithdrewComponent } from './load-board/carrier-load-board/menus/withdrew/withdrew.component';
import { CarrierLoadBoardOffersComponent } from './load-board/carrier-load-board/menus/offers/offers.component';
import { TemplateComponent } from './template/template.component';
import { AddContractTemplateComponent } from './settings/contract-templates/add-contract-templates/add-contract-component';
import { ViewContractTemplateComponent } from './settings/contract-templates/view-contract-template/view-contract-template.component';
import { MessagesComponent } from './messages/messages.component';
import { TestComponent } from './test/test.component';
import { TMSComponent } from './tms/tms.component';
import { TruckloadTMSComponent } from './truckload-tms/truckload-tms.component';

// Modals
import { NgbdModalAddZone } from './service-area/modals/add-zone/add-zone.component';
import { NgbdModalAddServiceArea } from './service-area/modals/add-service-area/add-service-area.component';
import { NgbdModalBuyLicenses } from './settings/licenses/modals/buy-licenses/buy-licenses.component';
import { NgbdModalContactSales } from './settings/licenses/modals/contact-sales/contact-sales.component';
import { NgbdModalAddLane } from './zones-and-routes/modals/lane/lane.component';
import { NgbdGridJsSortableHeader } from '../core/services/vehicles-service/gridjs-sortable.directive';
import { ContractTemplatesComponent } from './settings/contract-templates/contract-templates.component';
import { NgbdModalHelp } from './settings/contract-templates/modals/help/help.component';
import { NgbdModalIntroWizard } from './dashboards/dashboard/modals/intro-wizard-modal/intro-wizard-modal.component';
import { NgbdModalGenaralCompanyInformation } from './extrapages/company-setting/modals/general-company-information/general-company-information.component';
import { NgbdModalTransportationCompanyInformation } from './extrapages/company-setting/modals/transportation-company-information/transportation-company-information.component';
import { NgbdModalContactInformation } from './extrapages/company-setting/modals/contact-information/contact-information.component';
import { NgbdModalCustomPermission } from './test/modals/custom-permission/custom-permission.component';
import { NgbdSaveChannelModal } from './messages/modals/save-channel/save-channel.component';
import { NgbdModalUploadVerificationDocument } from './setup/modal/upload-verification-document-modal.component';
import { NgbdModalLoadBoardDetails } from './load-board/modal/load-board-details/load-board-details-modal.component';

// TreeTable Module
import { TreeTableModule } from 'primeng/treetable';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';
import {ContextMenuModule} from 'primeng/contextmenu';
import { TreeSelectModule } from 'primeng/treeselect';
import {OrganizationChartModule} from 'primeng/organizationchart';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {PanelModule} from 'primeng/panel';
import {StepsModule} from 'primeng/steps';
import { ChipsModule } from 'primeng/chips';
// import { NodeService } from './pages/tree-table/tree-table.service';
import { NgxMaskModule } from 'ngx-mask';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DndDirective } from './settings/contract-templates/modals/directives/dnd.directive';
import { ProgressComponent } from './settings/contract-templates/modals/progress/progress.component';
import { ArchwizardModule } from 'angular-archwizard';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { RouteCapacityAndRatesComponent } from './route-capacity-and-rates/route-capacity-and-rates.component';
import { CustomScrollDirective } from '../core/directives/custom-scroll.directive';
import { MeetingsComponent } from './meetings/meetings.component';
import { ModesComponent } from './settings/modes/modes.component';
import { RolesComponent } from './settings/roles/roles.component';
import { AddBranchComponent } from './origin-points/add-branch/add-branch.component';
import { EditBranchComponent } from './origin-points/edit-branch/edit-branch.component';
import { ServiceAreasComponent } from './settings/service-areas/service-areas.component';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { UserInvitationsComponent } from './user-invitations/user-invitations.component';
import { CreateContractComponent } from './create-contract/create-contract.component';
import { SaveRouteComponent } from './zones-and-routes/save-route/save-route.component';
import { PrivacyPageComponent } from './privacy/privacy-page.component';
import { ContractsComponent } from './contracts/contracts.component';
import { TermsPageComponent } from './terms/terms-page.component';
import { ContractSignersComponent } from './settings/contract-signers/contract-signers.component';
import { ViewContractComponent } from './view-contract/view-contract.component';
import { ChatRequestComponent } from './settings/chat-request/chat-request.component';
import { SignedContractComponent } from './signed-contract/signed-contract.component';
import { CreateVehicleComponent } from './vehicles/create-vehicle/create-vehicle.component';
import { ServiceTypesComponent } from './settings/service-types/service-types.component';
import { VehicleTrucksTabComponent } from './vehicles/vehicle-trucks-tab/vehicle-trucks-tab.component';
import { VehicleTrailersTabComponent } from './vehicles/vehicle-trailers-tab/vehicle-trailers-tab.component';
import { VehicleCombosTabComponent } from './vehicles/vehicle-combos-tab/vehicle-combos-tab.component';
import { MapsComponent } from './maps/maps.component';
import { ReportsComponent } from './reports/reports.component';
import { BranchSchedulingComponent } from './branch-scheduling/branch-scheduling.component';
import { NgbdModalSaveCapacity } from './zones-and-routes/modals/save-capacity/modal-save-capacity.component';
import { ServiceAreaModalComponent } from './settings/service-areas/modals/service-area-modal/service-area-modal.component';
import { APIIntegrationComponent } from './settings/api-integrations/api-integrations.component';
import { ServiceAreasAndZonesComponent } from './service-areas-and-zones/service-areas-and-zones.component';
import { LanesComponent } from './lanes/lanes.component';
import { RatesComponent } from './rates/rates.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { SaveLaneComponent } from './lanes/save-lane/save-lane.component';
import { SetupComponent } from './setup/setup.component';
import { SetupShipperComponent } from './setup/setup-shipper/setup-shipper.component';
import { CompanyMenuComponent } from './settings/menus/company-menu/company-menu.component';
import { ZonesMenuComponent } from './settings/menus/zones-menu/zones-menu.component';
import { LanesMenuComponent } from './settings/menus/lanes-menu/lanes-menu.component';
import { TradingRelationshipsMenuComponent } from './settings/menus/trading-relationships-menu/trading-relationships-menu.component';
import { BillingAndInvoicingMenuComponent } from './settings/menus/billing-and-invoicing-menu/billing-and-invoicing-menu.component';
import { UsersMenuComponent } from './settings/menus/users-menu/users-menu.component';
import { NgbdModalAddAPIIntegrations } from './settings/api-integrations/modals/add-api-integrations/add-api-integrations.component';
import { ZonesRoutesComponent } from './settings/menus/zones-menu/routes/zones-routes-menu.component';
import { FAQComponent } from './faq/faq.component';
import { LineBreaksPipe } from '../core/utils/pipe/linebreak.pipe';
import { LineBreaksModule } from '../core/utils/pipe/linebreak.module';
import { SetupCarrierComponent } from './setup/setup-carrier/setup-carrier.component';
import { ShipperLoadBoardComponent } from './load-board/shipper-load-board/shipper-load-board.component';
import { LoadBoardListComponent } from './load-board/load-board-list/load-board-list.component';
import { CarrierListComponent } from './carriers/carrier-list/carrier-list.component';
import { ShipperListComponent } from './carriers/shipper-list/shipper-list.component';
import { EquipmentComplianceComponent } from './equipment-compliance/equipment-compliance.component';
import { DriverQualificationFilesComponent } from './driver-qualification-files/driver-qualification-files.component';
import { TradingRelationshipsComponent } from './trading-relationships/trading-relationships.component';
import { PublicProfileComponent } from './public-profile/public-profile.component';
import { CapacityComponent } from './capacity/capacity.component';
import { SaveCapacityComponent } from './capacity/save-capacity/save-capacity.component';
import { DocumentGroupsComponent } from './settings/document-groups/document-groups.component';
import { AssetsComponent } from './assets/assets.component';
import { UpgradePlanModalComponent } from './setup/modal/upgrade-plan-modal/upgrade-plan-modal.component';
import { BrokerListComponent } from './carriers/broker-list/broker-list.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    CreateContractComponent,
    DashboardComponent,
    CompleteProfileComponent,
    UserComponent,
    LoadBoardComponent,
    LoadDetailsComponent,
    OriginPointComponent,
    AddBranchComponent,
    EditBranchComponent,
    ComingSoonComponent,
    RouteCapacityAndRatesComponent,
    SettingsComponent,
    LicensesComponent,
    ModesComponent,
    ServiceAreasComponent,
    ServiceAreaModalComponent,
    BillingComponent,
    APIIntegrationComponent,
    ContractSignersComponent,
    ChatRequestComponent,
    InvoiceDetailsComponent,
    InvoicesComponent,
    ContractTemplatesComponent,
    CompanySettingsComponent,
    ServiceAreaComponent,
    ServiceableZipcodesComponent,
    AccountsPayableComponent,
    ZonesAndRoutesComponent,
    SaveRouteComponent,
    CarriersComponent,
    CarrierSearchComponent,
    FinalMileCertifiedComponent,
    VehiclesComponent,
    VehicleTrucksTabComponent,
    VehicleTrailersTabComponent,
    VehicleCombosTabComponent,
    UserCompanySettingsComponent,
    TemplateComponent,
    AddContractTemplateComponent,
    MessagesComponent,
    MeetingsComponent,
    NgbdModalAddZone,
    NgbdModalAddServiceArea,
    NgbdModalBuyLicenses,
    NgbdModalContactSales,
    NgbdModalAddLane,
    NgbdModalHelp,
    NgbdModalIntroWizard,
    NgbdModalGenaralCompanyInformation,
    NgbdModalTransportationCompanyInformation,
    NgbdModalContactInformation,
    NgbdModalCustomPermission,
    NgbdSaveChannelModal,
    NgbdModalSaveCapacity,
    NgbdModalAddAPIIntegrations,
    NgbdModalUploadVerificationDocument,
    NgbdModalLoadBoardDetails,
    ViewContractTemplateComponent,
    // ToastsContainer,
    NgbdGridJsSortableHeader,
    CarrierLoadBoardComponent,
    CarrierLoadBoardInvitationsComponent,
    CarrierLoadBoardApplicationsComponent,
    CarrierLoadBoardInterviewingComponent,
    CarrierLoadBoardRejectedComponent,
    CarrierLoadBoardWithdrewComponent,
    CarrierLoadBoardOffersComponent,
    DndDirective,
    ProgressComponent,
    TestComponent,
    CustomScrollDirective,
    RolesComponent,
    UserInvitationsComponent,
    SaveRouteComponent,
    PrivacyPageComponent,
    ContractsComponent,
    TermsPageComponent,
    ViewContractComponent,
    SignedContractComponent,
    CreateVehicleComponent,
    ServiceTypesComponent,
    MapsComponent,
    TMSComponent,
    TruckloadTMSComponent,
    ReportsComponent,
    BranchSchedulingComponent,
    ServiceAreasAndZonesComponent,
    LanesComponent,
    RatesComponent,
    ComplianceComponent,
    SaveLaneComponent,
    SetupComponent,
    SetupShipperComponent,
    SetupCarrierComponent,
    CompanyMenuComponent,
    ZonesMenuComponent,
    LanesMenuComponent,
    TradingRelationshipsMenuComponent,
    BillingAndInvoicingMenuComponent,
    UsersMenuComponent,
    ZonesRoutesComponent,
    FAQComponent,
    ShipperLoadBoardComponent,
    LoadBoardListComponent,
    CarrierListComponent,
    ShipperListComponent,
    BrokerListComponent,
    EquipmentComplianceComponent,
    DriverQualificationFilesComponent,
    TradingRelationshipsComponent,
    PublicProfileComponent,
    CapacityComponent,
    SaveCapacityComponent,
    DocumentGroupsComponent,
    AssetsComponent,
    UpgradePlanModalComponent
  ],
  imports: [
    CommonModule,
    NgbToastModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgApexchartsModule,
    LeafletModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbModule,
    SimplebarAngularModule,
    PagesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    SwiperModule,
    LightboxModule,
    TreeTableModule,
    TableModule,
    InputTextareaModule,
    TabViewModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    ToastModule,
    DialogModule,
    AutoCompleteModule,
    TreeSelectModule,
    OrganizationChartModule,
    PanelModule,
    NgSelectModule,
    PdfViewerModule,
    DropzoneModule,
    ArchwizardModule,
    DropdownModule,
    CheckboxModule,
    StepsModule,
    ChipsModule,
    EditorModule,
    DragDropModule,
    NgxMaskModule.forRoot(),
    LineBreaksModule.forRoot(),
    TimeagoModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: []
})
export class PagesModule { 
  constructor() {
    // defineLordIconElement(lottie.loadAnimation);
  }
}
