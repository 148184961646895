<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card mt-n4 mx-n4">
            <div class="bg-soft-warning">
                <div class="card-body pb-0 px-4">
                    <div class="row mb-3">
                        <div class="col-md">
                            <div class="row align-items-center g-3">
                                <div class="col-md-auto">
                                    <div class="avatar-md">
                                        <div class="avatar-title bg-white rounded-circle">
                                            <img src="assets/images/brands/slack.png" alt="" class="avatar-xs">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div>
                                        <h4 class="fw-bold">{{originalLoadBoard.title}}</h4>
                                        <div class="hstack gap-3 flex-wrap">
                                            <div>
                                                <i class="ri-building-line align-bottom me-1"></i>Route : {{originalLoadBoard.routeName}}
                                            </div>
                                            <div class="vr"></div>
                                            <div>Mode : <span class="fw-medium">{{ originalLoadBoard.modeName }}</span>
                                            </div>
                                            <div class="vr"></div>
                                            <div>Zone : <span class="fw-medium">{{ originalLoadBoard.zoneName }}</span>
                                            </div>
                                            <div class="vr"></div>
                                            <div>Service Area : <span class="fw-medium">{{ originalLoadBoard.serviceAreaName }}</span>
                                            </div>
                                            <div class="vr"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <div class="hstack gap-1 flex-wrap">
                                <button type="button" class="btn py-0 fs-16 favourite-btn active">
                                    <i class="ri-star-fill"></i>
                                </button>
                                <button type="button" class="btn py-0 fs-16 text-body">
                                    <i class="ri-share-line"></i>
                                </button>
                                <button type="button" class="btn py-0 fs-16 text-body">
                                    <i class="ri-flag-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0" role="tablist">
                        <li [ngbNavItem]="1" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Overview </a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-xl-9 col-lg-8">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="text-muted">
                                                    <h6 class="mb-3 fw-semibold text-uppercase">Description</h6>
                                                    <p>
                                                        {{originalLoadBoard.description}}
                                                    </p>
                                                    <div class="pt-3 border-top border-top-dashed mt-4">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-sm-6">
                                                                <div>
                                                                    <p class="mb-2 text-uppercase fw-medium">Start Date :</p>
                                                                    <h5 class="fs-15 mb-0">{{originalLoadBoard.startDate | date}}</h5>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6">
                                                                <div>
                                                                    <p class="mb-2 text-uppercase fw-medium">Type :</p>
                                                                    <h5 class="fs-15 mb-0">{{originalLoadBoard.type | titlecase }}</h5>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6">
                                                                <div>
                                                                    <p class="mb-2 text-uppercase fw-medium">Duration :</p>
                                                                    <div class="badge bg-danger fs-12">{{originalLoadBoard.duration}} months</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6">
                                                                <div>
                                                                    <p class="mb-2 text-uppercase fw-medium">Posted :</p>
                                                                    <div class="badge bg-warning fs-12">{{ getTimestamp(originalLoadBoard.startDate) | timeago }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pt-3 border-top border-top-dashed mt-4">
                                                        <h6 class="mb-3 fw-semibold text-uppercase">Resources</h6>
                                                        <div class="row g-3">
                                                            <div class="col-xxl-4 col-lg-6">
                                                                <div class="border rounded border-dashed p-2">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="flex-shrink-0 me-3">
                                                                            <div class="avatar-sm">
                                                                                <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                                    <i class="ri-folder-zip-line"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="flex-grow-1 overflow-hidden">
                                                                            <h5 class="fs-13 mb-1">
                                                                                <a href="javascript: void(0);" class="text-body text-truncate d-block">App pages.zip</a>
                                                                            </h5>
                                                                            <div>2.2MB</div>
                                                                        </div>
                                                                        <div class="flex-shrink-0 ms-2">
                                                                            <div class="d-flex gap-1">
                                                                                <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                                    <i class="ri-download-2-line"></i>
                                                                                </button>
                                                                                <div class="dropdown" ngbDropdown>
                                                                                    <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                                        <i class="ri-more-fill"></i>
                                                                                    </button>
                                                                                    <ul class="dropdown-menu" ngbDropdownMenu>
                                                                                        <li>
                                                                                            <a class="dropdown-item" href="javascript: void(0);">
                                                                                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename </a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a class="dropdown-item" href="javascript: void(0);">
                                                                                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xxl-4 col-lg-6">
                                                                <div class="border rounded border-dashed p-2">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="flex-shrink-0 me-3">
                                                                            <div class="avatar-sm">
                                                                                <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                                    <i class="ri-file-ppt-2-line"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="flex-grow-1 overflow-hidden">
                                                                            <h5 class="fs-13 mb-1">
                                                                                <a href="javascript: void(0);" class="text-body text-truncate d-block">Velzon admin.ppt</a>
                                                                            </h5>
                                                                            <div>2.4MB</div>
                                                                        </div>
                                                                        <div class="flex-shrink-0 ms-2">
                                                                            <div class="d-flex gap-1">
                                                                                <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                                    <i class="ri-download-2-line"></i>
                                                                                </button>
                                                                                <div class="dropdown" ngbDropdown>
                                                                                    <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                                        <i class="ri-more-fill"></i>
                                                                                    </button>
                                                                                    <ul class="dropdown-menu" ngbDropdownMenu>
                                                                                        <li>
                                                                                            <a class="dropdown-item" href="javascript: void(0);">
                                                                                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename </a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a class="dropdown-item" href="javascript: void(0);">
                                                                                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header align-items-center d-flex">
                                                <h4 class="card-title mb-0 flex-grow-1">Comments</h4>
                                                <div class="flex-shrink-0">
                                                    <div class="dropdown card-header-dropdown" ngbDropdown>
                                                        <a class="text-reset dropdown-btn arrow-none" href="javascript: void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                            <span class="text-muted">Recent <i class="mdi mdi-chevron-down ms-1"></i>
                                                            </span>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                            <a class="dropdown-item" href="javascript: void(0);">Recent</a>
                                                            <a class="dropdown-item" href="javascript: void(0);">Top Rated</a>
                                                            <a class="dropdown-item" href="javascript: void(0);">Previous</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <ngx-simplebar class="px-3 mx-n3 mb-2" style="max-height: 300px;">
                                                    <div class="d-flex mb-4">
                                                        <div class="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-8.jpg" alt="" class="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div class="flex-grow-1 ms-3">
                                                            <h5 class="fs-13">Joseph Parker <small class="text-muted ms-2">20 Dec 2021 - 05:47AM</small>
                                                            </h5>
                                                            <p class="text-muted">I am getting message from customers that when they place order always get error message . </p>
                                                            <a href="javascript: void(0);" class="badge text-muted bg-light">
                                                                <i class="mdi mdi-reply"></i> Reply </a>
                                                            <div class="d-flex mt-4">
                                                                <div class="flex-shrink-0">
                                                                    <img src="assets/images/users/avatar-10.jpg" alt="" class="avatar-xs rounded-circle" />
                                                                </div>
                                                                <div class="flex-grow-1 ms-3">
                                                                    <h5 class="fs-13">Alexis Clarke <small class="text-muted ms-2">22 Dec 2021 - 02:32PM</small>
                                                                    </h5>
                                                                    <p class="text-muted">Please be sure to check your Spam mailbox to see if your email filters have identified the email from Dell as spam.</p>
                                                                    <a href="javascript: void(0);" class="badge text-muted bg-light">
                                                                        <i class="mdi mdi-reply"></i> Reply </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-4">
                                                        <div class="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-6.jpg" alt="" class="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div class="flex-grow-1 ms-3">
                                                            <h5 class="fs-13">Donald Palmer <small class="text-muted ms-2">24 Dec 2021 - 05:20PM</small>
                                                            </h5>
                                                            <p class="text-muted">If you have further questions, please contact Customer Support from the “Action Menu” on your <a href="javascript:void(0);" class="text-decoration-underline">Online Order Support</a>. </p>
                                                            <a href="javascript: void(0);" class="badge text-muted bg-light">
                                                                <i class="mdi mdi-reply"></i> Reply </a>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-10.jpg" alt="" class="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div class="flex-grow-1 ms-3">
                                                            <h5 class="fs-13">Alexis Clarke <small class="text-muted ms-2">26 min ago</small>
                                                            </h5>
                                                            <p class="text-muted">Your <a href="javascript:void(0)" class="text-decoration-underline">Online Order Support</a> provides you with the most current status of your order. To help manage your order refer to the “Action Menu” to initiate return, contact Customer Support and more. </p>
                                                            <div class="row g-2 mb-3">
                                                                <div class="col-lg-1 col-sm-2 col-6">
                                                                    <img src="assets/images/small/img-4.jpg" alt="" class="img-fluid rounded">
                                                                </div>
                                                                <div class="col-lg-1 col-sm-2 col-6">
                                                                    <img src="assets/images/small/img-5.jpg" alt="" class="img-fluid rounded">
                                                                </div>
                                                            </div>
                                                            <a href="javascript: void(0);" class="badge text-muted bg-light">
                                                                <i class="mdi mdi-reply"></i> Reply </a>
                                                            <div class="d-flex mt-4">
                                                                <div class="flex-shrink-0">
                                                                    <img src="assets/images/users/avatar-6.jpg" alt="" class="avatar-xs rounded-circle" />
                                                                </div>
                                                                <div class="flex-grow-1 ms-3">
                                                                    <h5 class="fs-13">Donald Palmer <small class="text-muted ms-2">8 sec ago</small>
                                                                    </h5>
                                                                    <p class="text-muted">Other shipping methods are available at checkout if you want your purchase delivered faster.</p>
                                                                    <a href="javascript: void(0);" class="badge text-muted bg-light">
                                                                        <i class="mdi mdi-reply"></i> Reply </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                                <form class="mt-4">
                                                    <div class="row g-3">
                                                        <div class="col-12">
                                                            <label for="exampleFormControlTextarea1" class="form-label text-body">Leave a Comments</label>
                                                            <textarea class="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" placeholder="Enter your comment..."></textarea>
                                                        </div>
                                                        <div class="col-12 text-end">
                                                            <button type="button" class="btn btn-ghost-secondary btn-icon waves-effect me-1">
                                                                <i class="ri-attachment-line fs-16"></i>
                                                            </button>
                                                            <a href="javascript:void(0);" class="btn btn-success">Post Comments</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <h6 class="mb-3 fw-semibold text-uppercase">Contact Details</h6>
                                                <div class="row summary">
                                                    <div class="row">
                                                        <div class="col-lg-3 label">
                                                            <label>Name:</label>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            {{originalLoadBoard.contactName}}
                                                        </div>
                                                        <div class="col-lg-1"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-3 label">
                                                            <label>Email:</label>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            {{originalLoadBoard.contactEmail}}
                                                        </div>
                                                        <div class="col-lg-1"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-3 label">
                                                            <label>Phone:</label>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            {{originalLoadBoard.contactPhone}}
                                                        </div>
                                                        <div class="col-lg-1"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-3 label">
                                                            <label>Link:</label>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            <a href="javascript:void(0);">{{originalLoadBoard.contactLink}}</a>
                                                        </div>
                                                        <div class="col-lg-1"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header align-items-center d-flex border-bottom-dashed">
                                                <h4 class="card-title mb-0 flex-grow-1">Members</h4>
                                                <div class="flex-shrink-0">
                                                    <button type="button" class="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal" (click)="openModal(content)">
                                                        <i class="ri-share-line me-1 align-bottom"></i> Invite Member </button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <ngx-simplebar style="height: 235px;" class="mx-n3 px-3">
                                                    <div class="vstack gap-3">
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <img src="assets/images/users/avatar-2.jpg" alt="" class="img-fluid rounded-circle">
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Nancy Martino</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <div class="avatar-title bg-soft-danger text-danger rounded-circle"> HB </div>
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Henry Baird</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <img src="assets/images/users/avatar-3.jpg" alt="" class="img-fluid rounded-circle">
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Frank Hook</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <img src="assets/images/users/avatar-4.jpg" alt="" class="img-fluid rounded-circle">
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Jennifer Carter</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <div class="avatar-title bg-soft-success text-success rounded-circle"> AC </div>
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Alexis Clarke</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-xs flex-shrink-0 me-3">
                                                                <img src="assets/images/users/avatar-7.jpg" alt="" class="img-fluid rounded-circle">
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-13 mb-0">
                                                                    <a href="javascript: void(0);" class="text-body d-block">Joseph Parker</a>
                                                                </h5>
                                                            </div>
                                                            <div class="flex-shrink-0">
                                                                <div class="d-flex align-items-center gap-1">
                                                                    <button type="button" class="btn btn-light btn-sm">Message</button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon btn-sm fs-16 text-muted dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-eye-fill text-muted me-2 align-bottom float-start"></i>View </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-star-fill text-muted me-2 align-bottom float-start"></i>Favourite </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript:void(0);">
                                                                                    <i class="ri-delete-bin-5-fill text-muted me-2 align-bottom float-start"></i>Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ngx-simplebar>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header align-items-center d-flex border-bottom-dashed">
                                                <h4 class="card-title mb-0 flex-grow-1">Attachments</h4>
                                                <div class="flex-shrink-0">
                                                    <button type="button" class="btn btn-soft-info btn-sm">
                                                        <i class="ri-upload-2-fill me-1 align-bottom"></i> Upload </button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="vstack gap-2">
                                                    <div class="border rounded border-dashed p-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-3">
                                                                <div class="avatar-sm">
                                                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                        <i class="ri-folder-zip-line"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <h5 class="fs-13 mb-1">
                                                                    <a href="javascript: void(0);" class="text-body text-truncate d-block">App-pages.zip</a>
                                                                </h5>
                                                                <div>2.2MB</div>
                                                            </div>
                                                            <div class="flex-shrink-0 ms-2">
                                                                <div class="d-flex gap-1">
                                                                    <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                        <i class="ri-download-2-line"></i>
                                                                    </button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-pencil-fill align-bottom me-2 text-muted float-start"></i> Rename </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-delete-bin-fill align-bottom me-2 text-muted float-start"></i> Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border rounded border-dashed p-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-3">
                                                                <div class="avatar-sm">
                                                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                        <i class="ri-file-ppt-2-line"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <h5 class="fs-13 mb-1">
                                                                    <a href="javascript: void(0);" class="text-body text-truncate d-block">Velzon-admin.ppt</a>
                                                                </h5>
                                                                <div>2.4MB</div>
                                                            </div>
                                                            <div class="flex-shrink-0 ms-2">
                                                                <div class="d-flex gap-1">
                                                                    <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                        <i class="ri-download-2-line"></i>
                                                                    </button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-pencil-fill align-bottom me-2 text-muted float-start"></i> Rename </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-delete-bin-fill align-bottom me-2 text-muted float-start"></i> Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border rounded border-dashed p-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-3">
                                                                <div class="avatar-sm">
                                                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                        <i class="ri-folder-zip-line"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <h5 class="fs-13 mb-1">
                                                                    <a href="javascript: void(0);" class="text-body text-truncate d-block">Images.zip</a>
                                                                </h5>
                                                                <div>1.2MB</div>
                                                            </div>
                                                            <div class="flex-shrink-0 ms-2">
                                                                <div class="d-flex gap-1">
                                                                    <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                        <i class="ri-download-2-line"></i>
                                                                    </button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-pencil-fill align-bottom me-2 text-muted float-start"></i> Rename </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-delete-bin-fill align-bottom me-2 text-muted float-start"></i> Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border rounded border-dashed p-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-3">
                                                                <div class="avatar-sm">
                                                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                        <i class="ri-image-2-line"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <h5 class="fs-13 mb-1">
                                                                    <a href="javascript: void(0);" class="text-body text-truncate d-block">bg-pattern.png</a>
                                                                </h5>
                                                                <div>1.1MB</div>
                                                            </div>
                                                            <div class="flex-shrink-0 ms-2">
                                                                <div class="d-flex gap-1">
                                                                    <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                                                        <i class="ri-download-2-line"></i>
                                                                    </button>
                                                                    <div class="dropdown" ngbDropdown>
                                                                        <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                                            <i class="ri-more-fill"></i>
                                                                        </button>
                                                                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-pencil-fill align-bottom me-2 text-muted float-start"></i> Rename </a>
                                                                            </li>
                                                                            <li>
                                                                                <a class="dropdown-item" href="javascript: void(0);">
                                                                                    <i class="ri-delete-bin-fill align-bottom me-2 text-muted float-start"></i> Delete </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 text-center">
                                                        <button type="button" class="btn btn-success">View more</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Carrier Search </a>
                            <ng-template ngbNavContent>
                                <app-carrier-list [originalLoadBoard]="originalLoadBoard"></app-carrier-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Invitations </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['shipper_invited']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Applications </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['carrier_submitted']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Interviewing </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['shipper_acknowledged']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="9" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Chat </a>
                            <ng-template ngbNavContent>
                                <app-chat [originalLoadBoard]="originalLoadBoard"></app-chat>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="6" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Rejected </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['shipper_rejected', 'carrier_rejected']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="7" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Withdrew </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['shipper_withdrew', 'carrier_withdrew']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="8" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Offers </a>
                            <ng-template ngbNavContent>
                                <app-load-board-list [proposalStatuses]="['shipper_accepted']" [originalLoadBoard]="originalLoadBoard"></app-load-board-list>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="tab-content text-muted">
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>
<!-- Modal -->
<ng-template #content role="document" let-modal>
    <div class="modal-header p-3 bg-soft-success">
        <h5 class="modal-title" id="inviteMembersModalLabel">Members</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
        <div class="search-box mb-3">
            <input type="text" class="form-control bg-light border-light" placeholder="Search here...">
            <i class="ri-search-line search-icon"></i>
        </div>
        <div class="mb-4 d-flex align-items-center">
            <div class="me-2">
                <h5 class="mb-0 fs-13">Members :</h5>
            </div>
            <div class="avatar-group justify-content-center">
                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                    <div class="avatar-xs">
                        <img src="assets/images/users/avatar-3.jpg" alt="" class="rounded-circle img-fluid">
                    </div>
                </a>
                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Sylvia Wright">
                    <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-secondary"> S </div>
                    </div>
                </a>
                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Ellen Smith">
                    <div class="avatar-xs">
                        <img src="assets/images/users/avatar-4.jpg" alt="" class="rounded-circle img-fluid">
                    </div>
                </a>
            </div>
        </div>
        <ngx-simplebar class="mx-n4 px-4" style="max-height: 225px;">
            <div class="vstack gap-3">
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <img src="assets/images/users/avatar-2.jpg" alt="" class="img-fluid rounded-circle">
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Nancy Martino</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <div class="avatar-title bg-soft-danger text-danger rounded-circle"> HB </div>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Henry Baird</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <img src="assets/images/users/avatar-3.jpg" alt="" class="img-fluid rounded-circle">
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Frank Hook</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <img src="assets/images/users/avatar-4.jpg" alt="" class="img-fluid rounded-circle">
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Jennifer Carter</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <div class="avatar-title bg-soft-success text-success rounded-circle"> AC </div>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Alexis Clarke</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="avatar-xs flex-shrink-0 me-3">
                        <img src="assets/images/users/avatar-7.jpg" alt="" class="img-fluid rounded-circle">
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0">
                            <a href="javascript: void(0);" class="text-body d-block">Joseph Parker</a>
                        </h5>
                    </div>
                    <div class="flex-shrink-0">
                        <button type="button" class="btn btn-light btn-sm">Add</button>
                    </div>
                </div>
            </div>
        </ngx-simplebar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light w-xs" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
        <button type="button" class="btn btn-success w-xs">Invite</button>
    </div>
</ng-template>