import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';



@Component({
  selector: 'app-helper-new',
  templateUrl: './helper-new.component.html',
  styleUrls: ['./helper-new.component.scss']
})
export class HelperNewComponent implements OnInit {
  signUpForm!: FormGroup;
  submitted = false;
  isSuccess = false;
  isFailed = false;
  isLoading = false;

  constructor(
    private httpRequestService: HttpService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    this.signUpForm = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        title: [''],
        email: ['', [Validators.required, Validators.pattern(emailPattern)]],
        phone: ['', [Validators.required]],
        type: ['helper', [Validators.required]],
        plan: ['']
    });
  }

  get f() {
    return this.signUpForm.controls;
  }

  submitForm() {
    this.submitted = true;
    this.isLoading = true;
    this.isSuccess = false;
    this.isFailed = false;
    if(this.signUpForm.invalid) {
      this.isLoading = false;
      return;
    } else {
      let metaData = this.signUpForm.value;
      delete metaData['email'];
      this.httpRequestService.verifyEmail(this.signUpForm.controls['email'].value, metaData, 'signup').subscribe((data: any) => {
        this.initForm();
        this.router.navigate(['/sign-up-success']);
        this.isLoading = false;
        this.submitted = false;
      }, error => {
        this.submitted = false;
        this.isFailed = true;
        this.isLoading = false;
      })
    }
  }

}
