import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cover-not-found',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})

/**
 * 404 Cover Component
 */
export class CoverComponentNotFound implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
