export class AddressComponent {
    googlePlaceId?: string;
    name?: string;
    street1?: string;
    street2?: string;
    street3?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    postalCodeSuffix?: string;
    country?: string;
    latitude?: number;
    longitude?: number;

    constructor(placeResult: google.maps.places.PlaceResult){
        const addressParts = placeResult.address_components?.reduce((result, current)=> {
            result.set(
                current.types.sort().join(' '), 
                current.short_name
            );
            return result;
        }, new Map<string, string>());
        this.googlePlaceId = placeResult.place_id;
        this.name = placeResult.name;
        this.street1 = (addressParts?.get('street_number') ? `${addressParts.get('street_number')} ` : '') + (addressParts?.get('route') || '');
        this.street2 = addressParts?.get('subpremise');
        this.street3 = undefined;
        this.city = addressParts?.get('locality political') ?? addressParts?.get('political sublocality sublocality_level_1');
        this.state = addressParts?.get('administrative_area_level_1 political');
        this.postalCode = addressParts?.get('postal_code');
        this.postalCodeSuffix = addressParts?.get('postal_code_suffix');
        this.country = addressParts?.get('country political');
        this.latitude = placeResult.geometry?.location?.lat();
        this.longitude = placeResult.geometry?.location?.lng();
    }
}