<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- <app-maps></app-maps> -->


<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <table class="table align-middle table-nowrap mt-3 mb-1">
                    <thead class="table-light">
                        <tr>
                            <th data-sort="name">Route</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showData">
                        <tr *ngFor="let route of routes">
                            <td>
                                <a class="clickable-column" 
                                    (click)="openCapacityEditModal(route)"
                                ><u>{{route.name}}</u></a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!showData">
                        <tr class="loader-body">
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>