import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
// import { DecimalPipe } from '@angular/common';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

// Sweet Alert
import Swal from 'sweetalert2';
import { ProposalsModel } from 'src/app/core/services/models/proposals.model';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import { NgbdModalLoadBoardDetails } from '../../../modal/load-board-details/load-board-details-modal.component';


@Component({
  selector: 'app-carrier-load-board-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class CarrierLoadBoardInvitationsComponent implements OnInit {
  currentUser: any;
  activeModal?: NgbModalRef;

  tableHelper: TablePaginationHelper<ProposalsModel>;
  loading = false;
  currentProposal ?: ProposalsModel;

  constructor(
    private auth: AuthService,
    private httpRequest: HttpService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.tableHelper = new TablePaginationHelper(
        {
          loadRecords: (pageRequest) =>{
            return this.httpRequest.paginatedGetFromList(
              ()=>this.httpRequest.getProposalsByStatuses(['shipper_invited']),
              pageRequest
            ).toPromise()
          }
        }
      );
      this.loadRecords();
  }


  loadRecords(): void {
    this.loading = true;
    this.tableHelper.loadRecords().then(
      () => {
        const records = this.tableHelper.searchResult;
        if(!isArrayEmpty(records)){
          this.currentProposal = records[0];
        }else{
          this.currentProposal = undefined;
        }
        this.loading = false;
      }
    );
  }

  rejectInvitation(loadData: any) {
    this.httpRequest.editProposals(
        {
            status: "carrier_rejected"
        },
        loadData.proposalId,
        this.auth.currentAccountSelected.accountId
    ).subscribe(data => {
        Swal.fire({
            title: 'Success',
            text: 'Invitation has been successfuly rejected.',
            icon: 'success',
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
            timerProgressBar: false,
        }).then((result: any) => {
            this.loadRecords();
        });
    }, (error: any) => {
        Swal.fire({
            title: 'Error',
            text: error.error.reason,
            icon: 'warning',
            timer: 3000,
            showCancelButton: false,
            showConfirmButton: false,
            timerProgressBar: false,
        }).then((result: any) => {
            
        });
    })
  }

  openModalDetails(event: any) {
    this.activeModal?.close();
    this.httpRequest.getLoadById(event.loadId).subscribe(data => {
      let modalRef = this.modalService.open(NgbdModalLoadBoardDetails, { size: 'xl', centered: true, backdrop: true, keyboard : false });
      modalRef.componentInstance.loadData = data.data;
    })
  }


}
