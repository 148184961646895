import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Lane } from 'src/app/core/services/models/lane.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse, TrailerTypeModel } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';
import { NgbdModalBuyLicenses } from '../settings/licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-lanes',
  templateUrl: './lanes.component.html',
  styleUrls: ['./lanes.component.scss']
})
export class LanesComponent implements OnInit, OnDestroy {
  @Input()
  showBreadCrumbs = true;
  @Input()
  showButtons = true;
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Lanes'
    }
  ];
  showData = false;
  trailerTypeIdMap: Map<string, TrailerTypeModel> = new Map<string, TrailerTypeModel>();
  lanes: Lane[] = [];
  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  cacheBust = Date.now();

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private modalService: NgbModal,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.fetchLanes();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async ngOnInit() { 
    const trailerTypes = await this.httpService.listTrailerTypes().toPromise();
    this.trailerTypeIdMap = trailerTypes.reduce(
      (acc, cur) => {
        acc.set(cur.trailerTypeId, cur);
        return acc;
      },
      this.trailerTypeIdMap
    );
  }

  fetchLanes(): void {
    this.httpService.listLanes(
      this.selectedMode?.modeId!,
      this.selectedBranch?.branchId!,
      this.authService.currentAccountSelected.accountId!
    ).subscribe(
      res => {
        this.showData = true;
        const successRes = <SuccessApiResponse<Lane[]>> res;
        this.lanes = successRes.data;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch lanes: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  promptUpgrade(): void {
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }
}
