<app-mapbox-zipcode-picker #mapbox
    [zoom]="5"
    [center]="center"
    [zipCodeColors]="zipCodeColors"
    [defaultFillColor]="'black'"
    [selectableZipCodes]="selectableZipCodes"
    [zipCodeGroups]="zipCodeGroups"
    (zipCodeGroupsChange)="zipCodeGroupsChange.emit($event)"
    [focusedGroupId]="focusedGroupId"
    (onLoad)="onLoad.emit($event)"
    [groupOpacity]="_groupOpacity"
    [showPoBoxes]="_showPoBoxes"
    [autoSelectPoBoxes]="_autoSelectPoBoxes"
    [focusedZipCode]="_focusedZipCode"
    (focusedZipCodeChange)="focusedZipCodeChange.emit($event)"
>
</app-mapbox-zipcode-picker>