import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpService } from "../http-service";
import { PaginatedResults } from "./models";

export type PageRequest = {page: number, pageSize: number};

export class TablePaginationHelper<T> {
	total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private _page = 1;
	private _pages = 1;
	private _pageSize = 10;
	private _searchResult: T[] = [];

	constructor(
		private config: {
			/** set to true to load data right away */
			eager?: boolean,
			loadRecords: ( pageRequest: PageRequest) => Promise<PaginatedResults<T>>
		}
	) {
		if (config.eager) {
			this._loadRecords();
		}
	}

	get page(): number {
		return this._page;
	}

	set page(page: number) {
		this._page = page;
		// this._loadRecords();
	}

	get pageSize(): number {
		return this._pageSize;
	}

	get pages(): number {
		return this._pages;
	}

	get searchResult(): T[] {
		return this._searchResult;
	}

	get startIndex(): number {
		return (this.page - 1) * this.pageSize + 1;
	}

	get endIndex(): number {
		const last = (this.page - 1) * this.pageSize + this.pageSize;
		return Math.min(last, this.total$.value);
	}

	private async _loadRecords(): Promise<void> {
		const response = await this.config.loadRecords!({
			page: this.page,
			pageSize: this.pageSize
		});
		
		this.total$.next(response.totalResults);
		this._pageSize = response.pageSize;
		this._page = response.page;
		this._searchResult = response.results;
		this._pages = response.pages;
	}

	async loadRecords(pageNumber: number = 1): Promise<void> {
		this.page = pageNumber;
		await this._loadRecords();
	}

	async loadNextRecords(): Promise<boolean> {
		if (this.page >= this.pages) {
			return false;	// nothing to load
		}
		await this.loadRecords(this.page + 1);
		return true;
	}
}