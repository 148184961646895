<form [formGroup]="addContractTemplateForm">
    <div class="modal-body">
        <div class="row mb-3 ms-0">
            <div class="col-lg-1 user-form-label text-align-right">
                <label for="name-field" class="form-label">Name: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-3">
                <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['name'].errors['required']">Enter Name</div>
                </div>
            </div>
            <div class="col-lg-1 user-form-label text-align-right">
                <label for="name-field" class="form-label">Type: <span class="text-danger">*</span></label>
            </div>
            <div class="col-lg-3">
                <ng-select name="data" class="custom form-control" [items]="types" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true"
                    [hideSelected]="true" [clearSearchOnAdd]="true" bindLabel="prettyName" bindValue="prettyName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }">
                </ng-select>
            </div>
        </div>
        <pdf-viewer
            [src]="contractTemplateData.downLoadUrl"
            [render-text]="true"
            [original-size]="false"
            [show-all]="true"
            style="width: 95%; height: 50em"
            *ngIf="contentType === 'pdf'"
        ></pdf-viewer>
        <editor
            *ngIf="contentType === 'html'"
            [(ngModel)]="html"
            [ngModelOptions]="{standalone: true}"
            apiKey="txbcanytoasuhpzdz7k4enu2mtowefxvuldixj4byfd4zh38"
            [init]="{
                selector: 'textarea',
                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                mergetags_list: TINYMCE_MERGETAGS_LIST,
                content_css: 'document',
                content_style: 'body { min-height: 1056.25px; margin-bottom: 1rem;}',
                height: '100vh',
                contextmenu: 'mergetags'
            }"
        ></editor>
        <div class="footer-btn">
            <button type="submit" class="btn btn-primary" data-bs-toggle="modal" (click)="download()">
                Download Template
            </button>
            <button type="submit" class="btn btn-primary right-align" data-bs-toggle="modal" (click)="submitUpdate()">
                Save
            </button>
            <button type="submit" class="btn btn-primary right-align" data-bs-toggle="modal" (click)="cancel()">
                Close
            </button>
        </div>
    </div>
</form>
   