<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header p-3">
                <h5 class="modal-title" id="exampleModalLabel">{{originalCapacityGroup? 'Edit': 'Add'}} Capacity</h5>
            </div>
            <div class="card-body p-5">
                <form [formGroup]="capacityForm">
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="name" class="form-label">Name: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="name" class="form-control" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && capacityFormControls['name'].errors }" />
                            <div *ngIf="submitted && capacityFormControls['name'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="capacityFormControls['name'].errors['required']">Enter name</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="btnradioHasHelper" class="form-label select-button-toggle">Enabled: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" [value]="false" name="isEnabled" id="isEnabledFalse" formControlName="isEnabled">
                                <label class="btn btn-outline-primary mb-0" for="isEnabledFalse">No</label>
                                <input type="radio" class="btn-check" [value]="true" name="isEnabled" id="isEnabledTrue" formControlName="isEnabled">
                                <label class="btn btn-outline-primary mb-0" for="isEnabledTrue">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="mode" class="form-label">Mode: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 label-form">
                            <label class="form-label">{{selectedMode? selectedMode.name: ''}}</label>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="mainBranch" class="form-label">Main Branch: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 label-form">
                            <label class="form-label">{{selectedBranch? selectedBranch.name: ''}}</label>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="serviceArea" class="form-label">Service Area: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" name="serviceArea" [options]="serviceAreaOptions" optionLabel="name" optionValue="serviceAreaId" [autoDisplayFirst]="false" 
                                (onChange)="serviceAreaSelected($event)" [ngClass]="{ 'is-invalid': submitted && capacityFormControls['serviceArea'].errors }" formControlName="serviceArea"></p-dropdown>
                            <div *ngIf="submitted && capacityFormControls['serviceArea'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="capacityFormControls['serviceArea'].errors['required']">Select a service area</div>
                            </div>
                            <img *ngIf="selectedServiceArea?.postalCodesImageUrl"
                                class="d-block mt-3 postal-codes-image"
                                [src]="selectedServiceArea?.postalCodesImageUrl"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="zone" class="form-label">Zone: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" name="zoneId" [options]="zoneOptions" optionLabel="zoneName" optionValue="zoneId" [autoDisplayFirst]="false" 
                                (onChange)="zoneSelected($event)" [ngClass]="{ 'is-invalid': submitted && capacityFormControls['zoneId'].errors }" formControlName="zoneId"></p-dropdown>
                            <div *ngIf="submitted && capacityFormControls['zoneId'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="capacityFormControls['zoneId'].errors['required']">Select a zone</div>
                            </div>
                            <img *ngIf="selectedZone?.postalCodesImageUrl"
                                class="d-block mt-3 postal-codes-image"
                                [src]="selectedZone?.postalCodesImageUrl"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="postalCode" class="form-label">Zip Codes: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9" [ngClass]="{
                                'label-form': postalCodeOptions.length == 0, 
                                'pad-top-checkbox': postalCodeOptions.length != 0
                            }">
                            <p class="form-label" *ngIf="postalCodeOptions.length == 0">No zip codes available.</p>
                            <p class="form-label" *ngIf="postalCodeOptions.length != 0">{{ postalCodeOptions.join(', ') }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="serviceType" class="form-label">Service Types: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div *ngFor="let option of serviceTypeOptions" class="field-checkbox padding-top">
                                <p-checkbox name="serviceType" [value]="option.technicalName" [ngClass]="{'is-invalid': submitted && selectedServiceTypes.length === 0 }" [(ngModel)]="selectedServiceTypeIds" [ngModelOptions]="{standalone: true}" (onChange)="serviceTypeChanged()"></p-checkbox>
                                {{option.prettyName}}
                            </div>
                            <div *ngIf="submitted && selectedServiceTypes.length === 0" class="text-danger label-form" align="left"> Select service types </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="vehicle" class="form-label">Vehicle: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" formControlName="truckTrailerCombo" name="truckTrailerCombo" [options]="truckTrailerComboOptions" optionLabel="prettyName" optionValue="technicalName" [autoDisplayFirst]="false" [ngClass]="{'is-invalid': submitted && capacityFormControls['truckTrailerCombo'].errors }"></p-dropdown>
                            <div *ngIf="submitted && capacityFormControls['truckTrailerCombo'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="capacityFormControls['truckTrailerCombo'].errors['required']">Select a vehicle</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="capacity" class="form-label">Capacity: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <button type="submit" class="btn btn-primary action-btn" (click)="openCapacityEditModal()"> Edit </button>
                            <div *ngIf="capacityModalClicked && capacityFormControls['truckTrailerCombo'].errors" class="mt-1 text-danger" align="left">
                                <div *ngIf="capacityFormControls['truckTrailerCombo'].errors['required']"> Please select a Vehicle </div>
                            </div>
                            <div *ngIf="capacityModalClicked && selectedServiceTypes.length === 0" class="mt-1 text-danger" align="left"> Please select a Service Type </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !originalCapacityGroup}">
                    <span *ngIf="originalCapacityGroup" class="clickable-column me-auto d-flex align-items-center text-danger">
                        <div *ngIf="!saveLoading" (click)="delete()" class="inline-block"> Delete </div>
                        <div *ngIf="saveLoading" class="spinner-border inline-block save-loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <div>
                        <button type="button" class="btn btn-light" routerLink="/pages/capacity">
                            <div class="inline-block"> Cancel </div>
                        </button>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary action-btn" (click)="saveCapacity()">
                            <div *ngIf="!saveLoading" class="inline-block"> Save </div>
                            <div *ngIf="saveLoading" class="spinner-border inline-block route-save-loading" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>