<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div>
                            <button type="button" class="btn btn-primary add-btn me-4" data-bs-toggle="modal" id="zone-btn" routerLink="/pages/save-capacity">
                                <i class="ri-add-line align-bottom me-1"></i> Add Capacity </button>
                        </div>
                    </div>
                </div>
                <table class="table align-middle table-nowrap mt-3 mb-1">
                    <thead class="table-light">
                        <tr>
                            <th data-sort="name">Name</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showData">
                        <tr *ngFor="let capacityGroup of capacityGroups">
                            <td>
                                <a class="clickable-column" [routerLink]="['/pages/save-capacity']" [state]="{capacityGroup}">
                                    <u>{{capacityGroup.name}}</u>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!showData">
                        <tr class="loader-body">
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>