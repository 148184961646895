<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div>
                            <button type="button" class="btn btn-primary add-btn me-4" data-bs-toggle="modal" id="zone-btn" routerLink="/pages/create-route" [disabled]="!selectedMode || !selectedBranch">
                                <i class="ri-add-line align-bottom me-1"></i> Add Zone Route </button>
                            <!-- <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="lane-btn" 
                                routerLink="/pages/create-route" [disabled]="!selectedMode || !selectedBranch"><i class="ri-add-line align-bottom me-1"></i> Add Lane Route
                            </button> -->
                        </div>
                    </div>
                </div>
                <table class="table align-middle table-nowrap mt-3 mb-1">
                    <thead class="table-light">
                        <tr>
                            <th data-sort="name">Route</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showData">
                        <tr *ngFor="let route of routes">
                            <td>
                                <a class="clickable-column" [routerLink]="['/pages/edit-route']" [state]="{route}">
                                    <u>{{route.name}}</u>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!showData">
                        <tr class="loader-body">
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- Add Zone Modal -->
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{ modalType }} Zone</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
        {{ error }}
    </div>
    <form [formGroup]="zonesForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="name-field" class="form-label">Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['name'].errors['required']">Enter Name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="zipcodeInput" class="form-label">ZIP Codes: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <app-zipcode-picker [name]="'data'" [customClass]="'custom form-control ' + (submitted && form['zipCodes'].errors ? 'is-invalid' : '')" [control]="$any(form['zipCodes'])" [isShowError]="!!(submitted && form['zipCodes'].errors)" (blur)="getBranches()"></app-zipcode-picker>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Deliverable Branches: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['deliverableBranchIds'].errors }" [items]="branches" [multiple]="true" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="deliverableBranchIds" bindLabel="name" bindValue="branchId" (blur)="getMain()"></ng-select>
                    <div *ngIf="submitted && form['deliverableBranchIds'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['deliverableBranchIds'].errors['required']">Enter Deliverable Branches</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="mainBranchInput" class="form-label">Main Branch: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['mainBranchId'].errors }" [items]="mainBranches" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" formControlName="mainBranchId" bindLabel="name" bindValue="branchId"></ng-select>
                    <div *ngIf="submitted && form['mainBranchId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['mainBranchId'].errors['required']">Enter Main Branch</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="accountsPayableInput" class="form-label">Accounts Payable: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="accountPayableId" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['accountPayableId'].errors }" [items]="accountsPayable" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="accountPayableId" bindLabel="name" bindValue="accountPayableId"></ng-select>
                    <div *ngIf="submitted && form['accountPayableId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['accountPayableId'].errors['required']">Enter Accounts Payable</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Add'" (click)="addZone()">Save</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Edit'">Save</button>
            </div>
        </div>
    </form>
</ng-template>
<!-- Add Route Modal -->
<ng-template #addRoute role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="modalType != 'Edit'">{{modalType}} Route</h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="modalType == 'Edit'">Edit Route For {{selectedRow.zoneName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="errorRoute != ''">
        {{ errorRoute }}
    </div>
    <form [formGroup]="addRoutesForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="name-field" class="form-label">Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="name-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['name'].errors }" />
                    <div *ngIf="submittedRoute && formRoute['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['name'].errors['required']">Enter Name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="zipCodes" class="form-label">Zip Codes: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['zipCodes'].errors }" [items]="addRouteZipCodes" [multiple]="true" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="false" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="zipCodes"></ng-select>
                    <div *ngIf="submittedRoute && formRoute['zipCodes'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['zipCodes'].errors['required']">Enter ZIP Code</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Vehicle Type: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['vehicleType'].errors }" [items]="vehicleTypesData" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="vehicleType" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                    <div *ngIf="submittedRoute && formRoute['vehicleType'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['vehicleType'].errors['required']">Enter Vehicle Type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="name-field" class="form-label select-button-toggle">Has Helper: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9 check-box-on-site">
                    <div class="col-lg-9 user-form-label input-form has-authority">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradioHasHelper" id="btnradiohasHelperFalse" checked="" (click)="hasHelperRadio('inactive')">
                            <label class="btn btn-outline-primary mb-0" for="btnradiohasHelperFalse">No</label>
                            <input type="radio" class="btn-check" name="btnradioHasHelper" id="btnradiohasHelperTrue" (click)="hasHelperRadio('active')">
                            <label class="btn btn-outline-primary mb-0" for="btnradiohasHelperTrue">Yes</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="name-field" class="form-label select-button-toggle">On Site Truck Parking: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9 check-box-on-site">
                    <div class="col-lg-9 user-form-label input-form has-authority">
                        <div class="btn-group" role="group">
                            <input type="radio" class="btn-check" name="btnradioOnSite" id="btnradioOnSiteFalse" checked="" (click)="onSiteRadio('inactive')">
                            <label class="btn btn-outline-primary mb-0" for="btnradioOnSiteFalse">No</label>
                            <input type="radio" class="btn-check" name="btnradioOnSite" id="btnradioOnSiteTrue" (click)="onSiteRadio('active')">
                            <label class="btn btn-outline-primary mb-0" for="btnradioOnSiteTrue">Yes</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Delivery Service Type: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['deliveryServiceTypes'].errors }" [items]="deliveryServiceData" [multiple]="true" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="false" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="deliveryServiceTypes" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                    <div *ngIf="submittedRoute && formRoute['deliveryServiceTypes'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['deliveryServiceTypes'].errors['required']">Enter Delivery Service Type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Delivery Skill Sets: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['deliverySkillSets'].errors }" [items]="deliverySkillSetsData" [multiple]="true" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="false" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="deliverySkillSets" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                    <div *ngIf="submittedRoute && formRoute['deliverySkillSets'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['deliverySkillSets'].errors['required']">Enter Delivery Skill Sets</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Service Type: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedRoute && formRoute['serviceType'].errors }" [items]="serviceTypeData" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="serviceType" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                    <div *ngIf="submittedRoute && formRoute['serviceType'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formRoute['serviceType'].errors['required']">Enter Service Type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="deliverableBranchInput" class="form-label">Capacity: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="table-responsive table-card mt-3 mb-1" id="capacity-table">
                        <table class="table align-middle table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Zip Codes</th>
                                    <th scope="col">Days</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="capacityData">
                                    <tr *ngFor="let capData of capacityData; let i = index;">
                                        <td (click)="openCapacity(capacity); capDataDefault = capacityData[i]">{{capData.name}}</td>
                                        <td (click)="openCapacity(capacity); capDataDefault = capacityData[i]">
                                            <span>{{(capData.zipCodes | slice:0:2).join(', ')}}</span>
                                            <span *ngIf="capData.zipCodes.length > 2" [title]="capData.zipCodes"> +{{capData.zipCodes.length - 2}} more </span>
                                        </td>
                                        <td (click)="openCapacity(capacity); capDataDefault = capacityData[i]">
                                            <ng-container *ngFor="let item of capData.capData; let i = index">
                                                <span>{{getDayPrettyName(item.day)}}</span>
                                                <span *ngIf="capData.capData.length > 1 && (capData.capData.length - 1) != i">, </span>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <div class="delete">
                                                <button class="btn btn-sm btn-danger" (click)="deleteCap(capData, i)"> Delete </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <div class="add">
                                                <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal" (click)="openCapacity(capacity);">Add</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Add'" (click)="addRoutes()">Save</button>
            <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Edit'">Save</button>
        </div>
    </div>
</ng-template>
<!-- Edit Branch -->
<ng-template #editBranchModal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Edit Branch</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <form [formGroup]="branchForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="typeInput" class="form-label">Type <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <select class="form- form-select mb-3" data-trigger name="type-field" id="type-field" formControlName="type" [ngClass]="{ 'is-invalid': submittedBranch && formBranch['type'].errors }" *ngIf="selectedNode != ''" (change)="onChangeType($event)">
                        <option value="hq">HQ</option>
                        <option value="store">Store</option>
                        <option value="remote-store">Remote Store</option>
                        <option value="dc">DC</option>
                        <option value="hub">Hub</option>
                        <option value="3pl-cross-dock">3PL Cross Dock</option>
                    </select>
                    <div *ngIf="submittedBranch && formBranch['type'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formBranch['type'].errors['required']">Enter Type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="numberInput" class="form-label">Number <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <input type="text" id="number-field" class="form-control" required formControlName="number" [ngClass]="{ 'is-invalid': submittedBranch && formBranch['number'].errors }" (input)="onNumberChange($event)" />
                    <div *ngIf="submittedBranch && formBranch['number'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formBranch['number'].errors['required']">Enter Number</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="numberInput" class="form-label">Name</label>
                </div>
                <div class="col-lg-10">
                    <p id="name-value">{{ branchForm.controls['name'].value }}</p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-6">
                    <div class="row mb-3">
                        <div class="col-lg-4 label-form">
                            <label for="address-field" class="form-label">Address</label>
                        </div>
                        <div class="col-lg-8">
                            <app-google-maps-autocomplete [addressTypes]="['address']" (onPlaceChanged)="onAddressChange($event)"></app-google-maps-autocomplete>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 label-form">
                            <label for="streetInput" class="form-label">Street <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="street-field" class="form-control" required formControlName="street" [ngClass]="{ 'is-invalid': submittedBranch && formBranch['street'].errors }" />
                            <div *ngIf="submittedBranch && formBranch['street'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="formBranch['street'].errors['required']">Enter Street</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 label-form">
                            <label for="cityInput" class="form-label">City <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="city-field" class="form-control" required formControlName="city" [ngClass]="{ 'is-invalid': submittedBranch && formBranch['city'].errors }" />
                            <div *ngIf="submittedBranch && formBranch['city'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="formBranch['city'].errors['required']">Enter City</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 label-form">
                            <label for="stateInput" class="form-label">State <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-8">
                            <ng-select [items]="statesJson" bindLabel="prettyName" bindValue="techName" formControlName="state" [searchFn]="customSearchFn"></ng-select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 label-form">
                            <label for="zipcodeInput" class="form-label">ZIP Code <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-8">
                            <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedBranch && formBranch['zipcode'].errors }" [items]="zipcodesJson" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="zipcode"></ng-select>
                            <div *ngIf="submittedBranch && formBranch['zipcode'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="formBranch['zipcode'].errors['required']">Enter ZIP Code</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <iframe width="100%" height="250" frameborder="0" style="border:0" referrerpolicy="no-referrer-when-downgrade" [src]="googleMapSrc | safe"></iframe>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="contactNameInput" class="form-label">Contact Name</label>
                </div>
                <div class="col-lg-10">
                    <input type="text" id="contactName-field" class="form-control" formControlName="contactName" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="contactPhoneInput" class="form-label">Contact Phone</label>
                </div>
                <div class="col-lg-10">
                    <input type="text" id="contactPhone-field" class="form-control" formControlName="contactPhone" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 label-form">
                    <label for="contactEmailInput" class="form-label">Contact Email</label>
                </div>
                <div class="col-lg-10">
                    <input type="text" id="contactEmail-field" class="form-control" formControlName="contactEmail" />
                </div>
            </div>
        </div>
        <div class="modal-footer" id="add-service-area-md-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="editBranch()"> Save </button>
            </div>
        </div>
    </form>
</ng-template>
<!-- Capacity -->
<ng-template #capacity role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Capacity</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div style="height: 80vh; overflow: auto;">
        <app-capacity-form (valueChange)="closeModal($event)" (formValue)="formValue($event)" [zipCodes]="selectedRow.zoneZipCodes" [defaultValue]="capDataDefault"></app-capacity-form>
    </div>
</ng-template>