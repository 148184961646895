<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div id="table">
    <div class="tr">
        <div class="td text-align-right">Meetings:</div>
        <div class="td">
            <div class="calendar" style="height:55em">
                <nylas-agenda 
                    [id]="id" 
                    [calendar_id]="calendarId"
                    auto_time_box="true"
                    allow_date_change="true"
                    hide_current_time="true"
                    prevent_zoom="true">
                </nylas-agenda>
            </div>
        </div>
    </div>
    <div class="tr visibility-collapse">
        <div class="td text-align-right">Scheduling Link:</div>
        <div class="td">
        </div>
    </div>
</div>


<app-calendar-settings></app-calendar-settings>