<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">Buy Licenses</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close(false)"></button>
</div>

<form [formGroup]="licenseForm" *ngIf="licenseForm">
    <div class="alert alert-success" role="alert" *ngIf="invoiceSent">
       Successfully sent your invoice!
    </div>
    <div class="modal-body pp-5">
        <div class="row mb-3">
            <div class="col-4 user-form-label text-align-right">
                <label for="driver-only" class="form-label">Pre-Release Asset Licenses: <span class="text-danger">*</span></label>
            </div>
            <div class="col-3 column-input">
                <input (keypress)="_keyUp($event)" (keyup)="changeDriverOnly($event)" type="text" 
                    maxlength="5" pattern="[0-9]*" id="driver-only" 
                    class="form-control" formControlName="quantity" 
                    required (keyup)="changeDriverOnly($event)" 
                    [readonly]="invoiceSent"/>
                <div *ngIf="submitted && form['driverOnly'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['driverOnly'].errors['required']">Enter Amount</div>
                </div>
            </div>
            <div class="col-2 output-value">
                <p class="total text-center">@ $150 = </p>
            </div>
            <div class="col-3 output-value sum-value">
                <p class="total">{{trucks | currency:'USD':true:'1.0'}}</p>
            </div>
        </div>
        <div class="row mb-3" *ngIf="billingProfiles.length > 1">
            <div class="col-4 user-form-label text-align-right">
                <label for="billingProfile" class="form-label">Billing Profile: <span class="text-danger">*</span></label>
            </div>
            <div class="col-8">
                <ng-select for="billingProfile" [items]="billingProfiles" class="form-control" 
                    formControlName="billingProfileId"
                    [ngModelOptions]="{standalone: true}"
                    bindLabel="name" bindValue="billingProfileId" dropdownPosition="bottom" [clearable]="false">
                </ng-select>
            </div>
        </div>
        <!-- <div *ngIf="auth.currentAccountSelected.accountType === 'shipper-account' || auth.currentAccountSelected.accountType === 'broker-account'" 
            class="row mb-3"
        >
            <div class="col-lg-4 user-form-label text-align-right">
                <label for="driver-only" class="form-label">Trucks: </label>
            </div>
            <div class="col-lg-3 column-input">
                <input (keypress)="_keyUp($event)" (keyup)="changeDriverOnly($event)" type="text" maxlength="5" pattern="[0-9]*" id="driver-only" class="form-control" formControlName="driverOnly" required (keyup)="changeDriverOnly($event)" [readonly]="invoiceSent"/>
                <div *ngIf="submitted && form['driverOnly'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['driverOnly'].errors['required']">Enter Amount</div>
                </div>
            </div>
            <div class="col-lg-2 output-value">
                <p class="total">@ $500 = </p>
            </div>
            <div class="col-lg-3 output-value sum-value">
                <p class="total">{{trucks | currency:'USD':true:'1.0'}}</p>
            </div>
        </div>
        <div *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'" 
            class="row mb-3"
        >
            <div class="col-lg-4 user-form-label text-align-right">
                <label for="driver-only" class="form-label">Driver Only: </label>
            </div>
            <div class="col-lg-3 column-input">
                <input (keypress)="_keyUp($event)" (keyup)="changeDriverOnly($event)" type="text" maxlength="5" pattern="[0-9]*" id="driver-only" class="form-control" formControlName="driverOnly" required (keyup)="changeDriverOnly($event)" [readonly]="invoiceSent"/>
                <div *ngIf="submitted && form['driverOnly'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['driverOnly'].errors['required']">Enter Amount</div>
                </div>
            </div>
            <div class="col-lg-2 output-value">
                <p class="total">@ $500 = </p>
            </div>
            <div class="col-lg-3 output-value sum-value">
                <p class="total">{{driverOnlyTotal | currency:'USD':true:'1.0'}}</p>
            </div>
        </div>
        <div *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'"  
            class="row mb-3">
            <div class="col-lg-4 user-form-label text-align-right">
                <label for="driver-with-helper" class="form-label">Driver With Helper: </label>
            </div>
            <div class="col-lg-3 column-input">
                <input (keypress)="_keyUp($event)" (keyup)="changeDriverWithHelper($event)" type="text" maxlength="5" pattern="[0-9]*" id="driver-with-helper" class="form-control" formControlName="driverWithHelper" required [readonly]="invoiceSent"/>
                <div *ngIf="submitted && form['driverWithHelper'].errors" class="invalid-feedback" align="left">
                    <div *ngIf="form['driverWithHelper'].errors['required']">Enter Amount</div>
                </div>
            </div>
            <div class="col-lg-2 output-value">
                <p class="total">@ $750 = </p>
            </div>
            <div class="col-lg-3 output-value sum-value">
                <p class="total">{{driverWithHelperTotal | currency:'USD':true:'1.0'}}</p>
            </div>
        </div> -->
        <div class="row mb-3" id="line-separator"></div>
        <div class="row mb-3">
            <div class="col-4 user-form-label text-align-right">
                <label for="driver-with-helper" class="form-label">Total (plus taxes and fees): <span class="opacity-0">*</span></label>
            </div>
            <div class="col-5">
            </div>
            <div class="col-3">
                <p class="total">{{driverTotal | currency:'USD':true:'1.0'}}</p>
                <!-- <p *ngIf="auth.currentAccountSelected.accountType === 'shipper-account' || auth.currentAccountSelected.accountType === 'broker-account'" class="total all-total">{{truckTotal | currency:'USD':true:'1.0'}}</p>
                <p *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'" class="total all-total">{{driverTotal | currency:'USD':true:'1.0'}}</p> -->
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12">
            </div>
        </div>
    </div>
</form>

<div class="modal-footer" id="add-service-area-md-footer">
    <div class="hstack gap-2 justify-content-end">
        <button type="submit" class="btn btn-light" (click)="close(false)" *ngIf="!invoiceSent">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="sendInvoice()" *ngIf="!invoiceSent" 
            [disabled]="
                isLoading
                || (auth.currentAccountSelected.accountType === 'shipper-account' && truckTotal === 0)
                || (auth.currentAccountSelected.accountType === 'carrier-account' && driverTotal === 0)
                || (auth.currentAccountSelected.accountType === 'broker-account' && truckTotal === 0)
            "
        >
            <span class="flex-grow-1 me-2">
                {{ getActionButtonDisplay() }}
            </span>
            <div *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
        <button type="submit" class="btn btn-light" (click)="redirectToInvoices()" *ngIf="invoiceSent">Pay Later</button>
        <a *ngIf="invoiceSent" [href]="providerUrl" target="_blank">
            <button type="submit" class="btn btn-primary" (click)="redirectToLicenses()">
               Pay Now
            </button>
        </a>
    </div>
</div>