import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalCustomPermission } from './modals/custom-permission/custom-permission.component';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import jsonData from '../../../assets/jsons/files.json'
import jsonDataOrg from '../../../assets/jsons/files-org.json'
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Add User'}
  ]
  activeModal?: NgbModalRef;
  role = [
    {
        prettyName: 'Admin',
        technicalName: 'admin'
    },
    {
        prettyName: 'User',
        technicalName: 'user'
    },
    {
        prettyName: 'Custom',
        technicalName: 'custom'
    }
  ];
  @ViewChild('welcomeVideoModal') welcomeVideoModal!: Element;
  label = document.getElementsByClassName('p-treeselect-label');
  nodes4: any[] = jsonData.data;
  nodesOrg: any[] = jsonDataOrg.data;
  default: any = jsonData.data;
  selectedNodeString = '';


  tableData = [
    {
      branch: 'Guitar Center',
      role: 'Admin',
    },
    {
      branch: 'Best Buy',
      role: 'Admin',
    }
  ]
  permissions: any = [];

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private httpRequest: HttpService,
  ) { }

  ngOnInit(): void {
    this.getPermissions();
  }

  selectRole(event: any) {
    if(event.technicalName == 'custom') {
      this.openCustomPermissionModal();
    }
  }

  selectedNode(event: any) {
    this.label[1].textContent = event.node.data;
    this.selectedNodeString = event.node.data;
  }

  openCustomPermissionModal() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalCustomPermission, { centered: true });
    modalRef.result.then((result: any) => { 
      
    })
  }

  addPermission() {
    this.httpRequest.addPermission({
      branches: [],
      modes: [],
      roles: [
        '03e1a022-7cad-413a-b226-9f09e2f7adbb'
      ],
      permissionSet: {
        branches: 4
      }
    }).subscribe((data) => {
      this.permissions.push(data.data);
      console.log('this.permissions: ', this.permissions);
      // this.files = this.serv.manipulateData(data.data);
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    }, error => {
      console.error('error: ', error);
    })
    this.tableData.push({
      branch: '',
      role: '',
    })
  }

  getPermissions() {
    this.httpRequest.getPermissions().subscribe((data) => {
      this.permissions = data.data;
      console.log('this.permissions: ', this.permissions);
      // this.files = this.serv.manipulateData(data.data);
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    }, error => {
      console.error('error: ', error);
    })
  }

}
