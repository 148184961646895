import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model'
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { Subscription, combineLatest } from 'rxjs';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from '../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { Branch } from 'src/app/core/services/models/branch.model';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Compliance'
    }
  ];

  window = window;
  fmcsaLink: string;
  fmcsa: any;
  isLoading: boolean = true;
  errorMessage: string = '';

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  private selectedBranch: (Branch | null);

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private modalService: NgbModal,
  ) {
  }

  async ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.authService.currentAccountPlanSubject, 
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([accoundPlan, selectedBranch]) => {
          if(this.anyNull(accoundPlan, selectedBranch) || this.allSelectedSame(accoundPlan, selectedBranch)){
            return;
          }

          this.currentAccountPlan = accoundPlan;
          this.selectedBranch = selectedBranch;
        }
      )
    );

    try {
      const res = await this.httpService.getCompanyInfos(this.selectedBranch?.companyInfoId!).toPromise()
      const successRes = <SuccessApiResponse<CompanyInfo>> res;
      const companyInfo = successRes.data;
      this.fmcsaLink = `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${companyInfo.dotNumber}`;
      if(companyInfo) {
        this.getFMCSA(companyInfo.dotNumber!);
      } else  {
        this.isLoading = false;
      }
    } catch (error: any) {
      this.errorMessage = error.error.reason;
      this.isLoading = false;
    }
    
  }

  private anyNull(accountPlan: AccountPlan | null, branch: Branch | null): boolean{
    return !accountPlan || !branch;
  }

  private allSelectedSame(account: AccountPlan | null, branch: Branch | null): boolean{
    return this.currentAccountPlan?.accountId == account?.accountId && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  redirectToFmcsa(){
    window.open(this.fmcsaLink, '_blank');
  }
  
  getFMCSA(dotNumber: string) {
    this.httpService.lookupFMCSAdotNumber(dotNumber).subscribe((data:any) => {
      this.fmcsa = data.data;
      this.isLoading = false;
    })
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  promptUpgrade(): void {
    if(this.authService.currentAccountPlanValue.status === 'paid'){
      return;
    }

    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }
}
