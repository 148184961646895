import { FormGroup, ValidationErrors } from "@angular/forms";
import { environment } from "src/environments/environment";


export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
] as const;
export type DayType = typeof DAYS[number];
export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const TINYMCE_MERGETAGS_LIST = [
  {
    title: 'Data',
    menu: [
      {
        value: 'day',
        title: 'day'
      },
      {
        value: 'month',
        title: 'month'
      },
      {
        value: 'year',
        title: 'year'
      }
    ]
  },
  {
    title: 'Shipper',
    menu: [
      {
        value: 'shipperName',
        title: 'shipperName'
      },
      {
        value: 'ShippersLegalName',
        title: 'ShippersLegalName'
      },
      {
        value: 'ShippersDBAName',
        title: 'ShippersDBAName'
      },
      {
        value: 'ShippersBusinessPhoneNumber',
        title: 'ShippersBusinessPhoneNumber'
      },
      {
        value: 'ShippersBusinessEmail',
        title: 'ShippersBusinessEmail'
      },
      {
        value: 'ShippersOwnerFirstName',
        title: 'ShippersOwnerFirstName'
      },
      {
        value: 'ShippersOwnerLastName',
        title: 'ShippersOwnerLastName'
      },
      {
        value: 'ShippersOwnerTitle',
        title: 'ShippersOwnerTitle'
      },
      {
        value: 'ShippersOwnerPhone',
        title: 'ShippersOwnerPhone'
      },
      {
        value: 'ShippersOwnerEmail',
        title: 'ShippersOwnerEmail'
      },
      {
        value: 'ShippersSCAC',
        title: 'ShippersSCAC'
      },
      {
        value: 'ShippersDOTNumber',
        title: 'ShippersDOTNumber'
      },
      {
        value: 'ShippersMCNumber',
        title: 'ShippersMCNumber'
      },
      {
        value: 'ShippersStateMCNumber',
        title: 'ShippersStateMCNumber'
      },
      {
        value: 'ShippersEINNumber',
        title: 'ShippersEINNumber'
      },
      {
        value: 'ShippersLegalEntityType',
        title: 'ShippersLegalEntityType'
      },
      {
        value: 'ShippersHQAddress',
        title: 'ShippersHQAddress'
      },
      {
        value: 'ShippersOrganizationAddress',
        title: 'ShippersOrganizationAddress'
      },
      {
        value: 'ShippersRegionAddress',
        title: 'ShippersRegionAddress'
      },
      {
        value: 'ShippersDCAddress',
        title: 'ShippersDCAddress'
      },
      {
        value: 'ShippersHUBAddress',
        title: 'ShippersHUBAddress'
      }
    ]
  },
  {
    title: 'Carrier',
    menu: [
      {
        value: 'carrierName',
        title: 'carrierName'
      },
      {
        value: 'CarrierLegalName',
        title: 'CarrierLegalName'
      },
      {
        value: 'CarrierDBAName',
        title: 'CarrierDBAName'
      },
      {
        value: 'CarrierBusinessPhoneNumber',
        title: 'CarrierBusinessPhoneNumber'
      },
      {
        value: 'CarrierBusinessEmail',
        title: 'CarrierBusinessEmail'
      },
      {
        value: 'CarrierOwnerFirstName',
        title: 'CarrierOwnerFirstName'
      },
      {
        value: 'CarrierOwnerLastName',
        title: 'CarrierOwnerLastName'
      },
      {
        value: 'CarrierOwnerTitle',
        title: 'CarrierOwnerTitle'
      },
      {
        value: 'CarrierOwnerPhone',
        title: 'CarrierOwnerPhone'
      },
      {
        value: 'CarrierOwnerEmail',
        title: 'CarrierOwnerEmail'
      },
      {
        value: 'CarrierSCAC',
        title: 'CarrierSCAC'
      },
      {
        value: 'CarrierDOTNumber',
        title: 'CarrierDOTNumber'
      },
      {
        value: 'CarrierMCNumber',
        title: 'CarrierMCNumber'
      },
      {
        value: 'CarrierStateMCNumber',
        title: 'CarrierStateMCNumber'
      },
      {
        value: 'CarrierEINNumber',
        title: 'CarrierEINNumber'
      },
      {
        value: 'CarrierLegalEntityType',
        title: 'CarrierLegalEntityType'
      },
      {
        value: 'CarrierAddress',
        title: 'CarrierAddress'
      },
      {
        value: 'CarrierStreet',
        title: 'CarrierStreet'
      },
      {
        value: 'CarrierCity',
        title: 'CarrierCity'
      },
      {
        value: 'CarrierState',
        title: 'CarrierState'
      },
      {
        value: 'CarrierZipCode',
        title: 'CarrierZipCode'
      }
    ]
  }
];

export type StringNullUndefined = string | null | undefined;

export const ZONE_COLORS = [
  'DarkMagenta',
  'DarkBlue',
  'DarkGoldenRod',
  'DarkGreen',
  'RebeccaPurple',
  'DarkOrange',
  'DarkGray',
  'Chocolate',
  'CornflowerBlue',
  'LightGoldenRodYellow',
  'DarkOliveGreen',
  'DarkSlateBlue',
  'SandyBrown',
  'HoneyDew',
  'RoyalBlue',
];

export function generateZoneColor(index: number): string {
  return ZONE_COLORS[index] || getRandomColor();
}

export const truthyFilter = Boolean as any as <T>(x: T | false | undefined | null | '' | 0) => x is T;

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getKeyByValue(object: Record<string, string>, value: string): string | undefined {
  return Object.keys(object).find(key => object[key] === value);
}

export function removeFromArray<T>(arr: T[], eqFn: (a: T) => boolean): T[] {
  const index = arr.findIndex(a => eqFn(a));
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function logFormValidationErrors(form: FormGroup) {
  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors | null | undefined = form.get(key)?.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });
}

export function getRandomColor(): string {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function isArrayEmpty(arr?: any[]): boolean{
  if (!arr || arr.length == 0) {
    return true;
  }

  return false;
}

export function isStrArrayEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const arr1Map: { [k in string]: number} = {};
  arr1.forEach(a1 => {
    if (arr1Map[a1]) {
      arr1Map[a1]++;
    } else {
      arr1Map[a1] = 1;
    }
  });

  for (const a2 of arr2) {
    if (!arr1Map[a2]) {
      return false;
    }

    arr1Map[a2]--;
  }

  return true;
}

export function numberDisplay(num?: Number): string{
  if(!num){
    return '';
  }
  return Number(num.toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2
  });
}

export function timeToNumber(hour?: string): number{
  if(
    (hour?.split(':') ?? []).length !== 3
  ){
    throw new Error(`Time need to be in h:m:s format. Invalid time: ${hour}`);
  }
  return Number(hour?.split(':').join('') ?? 0);
}

export function getDateDisplay(date: Date): string{
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export function getUtf8HtmlFile(filename: string, body: string){
  console.log('[getUtf8HtmlFile] body', body);
  //wrapping as html as looks like html to docx doesn't really work in convertapi without it
  const blob = new Blob([`<html>${body}</html>`], {type:"text/html;charset=UTF-8"});
  return new File([blob], filename, { type: 'text/html' });
}

//Reference: https://stackoverflow.com/a/62537645
export function utf8_to_b64( str: string ) {
  return window.btoa(unescape(encodeURIComponent( str )));
}

//Reference: https://stackoverflow.com/a/62537645
export function b64_to_utf8( str: string ) {
  return decodeURIComponent(escape(window.atob( str )));
}

//Reference: https://stackoverflow.com/a/55257089
export function convertBase64ToBlob(base64encodedString: string, type: string): Blob {
 // Decode Base64 string
 const decodedData = window.atob(base64encodedString);

 // Create UNIT8ARRAY of size same as row data length
 const uInt8Array = new Uint8Array(decodedData.length);

 // Insert all character code into uInt8Array
 for (let i = 0; i < decodedData.length; ++i) {
   uInt8Array[i] = decodedData.charCodeAt(i);
 }

 // Return BLOB image after conversion
 return new Blob([uInt8Array], { type });
}

// export function environment.calendly_url: string {
//   return environment.calendly_url;
// }

//From: https://medium.com/@aeshghi/convert-jpg-images-to-png-using-html5-url-and-canvas-45b14ee853c9
export const JpgToPngConvertor = (() => {
  function convertor(imageFileBlob: File, callback: (file: File)=>{}) {
    const options = {};
    const defaults = {
      downloadLinkSelector: '.js-download-png'
    };
    const settings = extend(defaults, options);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d")!;
    const imageEl = createImage();
    const downloadLink = settings.downloadEl || createDownloadLink();

    function createImage(options?: any) {
      options = options || {};
      const img = (Image) ? new Image() : document.createElement('img');
      const parent = options.parentEl || document.body;
      img.style.width = (options.width) ? options.width + 'px' : 'auto';
      img.style.height = (options.height) ? options.height + 'px' : 'auto';
      return img;
    }

    function extend(target: any, source: any) {
      for (let propName in source) {
        if (source.hasOwnProperty(propName)) {
          target[propName] = source[propName];
        }
      }
      return target;
    }

    function createDownloadLink() {
      return document.createElement('a');
    }

    function isFirefox() {
      return navigator.userAgent.indexOf("Firefox") > -1;
    }

    function download() {
      // Add download link to DOM in case it is not there and on the firefox
      if (!document.contains(downloadLink) && isFirefox()) {
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
      }
      if ('click' in downloadLink) {
        downloadLink.click();
      } else {
        downloadLink.dispatchEvent(createClickEvent());
      }
    }

    function updateDownloadLink(jpgFileName: any, pngBlob: any) {
      const linkEl = downloadLink;
      const pngFileName = jpgFileName.replace(/jpe?g/i, 'png');
      linkEl.setAttribute('download', pngFileName);
      linkEl.href = window.URL.createObjectURL(pngBlob);
      // If there is custom download link we don't download automatically
      if (settings.downloadEl) {
        settings.downloadEl.style.display = 'block';
      } else {

        callback(new File([pngBlob], pngFileName, { type: 'image/png' }));

        //download();
      }
    }

    function createClickEvent() {
      if ('MouseEvent' in window) {
        return new MouseEvent('click');
      } else {
        const evt: any = document.createEvent("MouseEvents");
        evt.initMouseEvent("click", true, true, window);
        return evt;
      }
    }

    function process() {
      const imageUrl = window.URL.createObjectURL(imageFileBlob);

      imageEl.onload = (e: any) => {
        canvas.width = e.target.width;
        canvas.height = e.target.height;
        ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
        canvas.toBlob(updateDownloadLink.bind(window, imageFileBlob.name), 'image/png', 1);
      };

      imageEl.src = imageUrl;
      if (settings.downloadEl) {
        settings.downloadEl.style.display = 'none';
      }
    }

    return {
      process
    };
  }
  return convertor;
})();