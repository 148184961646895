<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Load Search </a>
                        <ng-template ngbNavContent>
                            <div class="row pad-btm">
                                <div class="col-xl-2 col-lg-2 col-xxl-3">
                                    <div class="card">
                                        <div class="card-header hide">
                                            <ul ngbNav #customNav="ngbNav" [activeId]="0" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                                <li [ngbNavItem]="0" class="nav-item">
                                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                                        <i class="fas fa-home"></i> Direct To Carrier </a>
                                                    <ng-template ngbNavContent>
                                                        <form [formGroup]="searchForm">
                                                            <div class="search-box search-box-sm w-100 mb-2">
                                                                <input type="text" class="form-control bg-light border-0" placeholder="Name" (keyup.enter)="loadRecords()" formControlName="name">
                                                                <i class="ri-search-line search-icon"></i>
                                                            </div>
                                                            <div class="search-box search-box-sm w-100 mb-2">
                                                                <input type="text" class="form-control bg-light border-0" placeholder="DOT Number" (keyup.enter)="loadRecords()" formControlName="dotNumber">
                                                                <i class="ri-search-line search-icon"></i>
                                                            </div>
                                                            <div class="search-box search-box-sm w-100 mb-2">
                                                                <input type="text" class="form-control bg-light border-0" (keyup.enter)="loadRecords()" placeholder="City">
                                                                <i class="ri-search-line search-icon"></i>
                                                            </div>
                                                            <div class="search-box search-box-sm w-100 mb-2">
                                                                <input type="text" class="form-control bg-light border-0" (keyup.enter)="loadRecords()" placeholder="State">
                                                                <i class="ri-search-line search-icon"></i>
                                                            </div>
                                                            <div class="search-box search-box-sm w-100 mb-2">
                                                                <app-zipcode-picker [name]="'data'" [customClass]="'custom form-control'" (keyup.enter)="loadRecords()" [control]="$any(formSearch['zipCodes'])"></app-zipcode-picker>
                                                                <i *ngIf="!formSearch['zipCodes'].value || formSearch['zipCodes'].value.length === 0" class="ri-search-line search-icon"></i>
                                                            </div>
                                                            <button class="btn btn-md btn-primary mt-2 w-100" data-bs-toggle="modal" [disabled]="loading" type="button" (click)="loadRecords()"> Search <div *ngIf="loading" class="spinner-border inline-block loading" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </form>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="card-body border-bottom">
                                            <div class="tab-content">
                                                <div [ngbNavOutlet]="customNav"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-lg-10 col-xxl-9">
                                    <div>
                                        <div class="card joblist-card" *ngFor="let res of tableHelper.searchResult; let i=index;" (click)="openModalDetails(res)">
                                            <div class="card-body pt-2 row">
                                                <div class="col-lg-4">
                                                    <div class="d-flex mb-4">
                                                        <div class="avatar-sm">
                                                            <div class="avatar-title bg-light rounded">
                                                                <img src="assets/images/companies/img-{{ i%9 }}.png" alt="" class="avatar-xxs companyLogo-img">
                                                            </div>
                                                        </div>
                                                        <div class="ms-3 flex-grow-1">
                                                            <img src="assets/images/small/img-8.jpg" alt="" class="d-none cover-img">
                                                            <a href="javascript:void(0);">
                                                                <h5 class="job-title">{{ res.title }}</h5>
                                                            </a>
                                                            <p class="company-name text-muted mb-0">Themesbrand</p>
                                                        </div>
                                                    </div>
                                                    <p class="text-muted job-description">{{res.description}}</p>
                                                </div>
                                                <div class="col-lg-4">
                                                    <img *ngIf="res.routePostalCodesImageUrl" [src]="res.routePostalCodesImageUrl" class="w-100"/>
                                                </div>
                                                <div class="col-lg-4">
                                                    <img *ngIf="i==0" src="/assets/images/sample/estimated.png" class="w-100"/>
                                                    <img *ngIf="i==1" src="/assets/images/sample/cap table.png" class="w-100"/>
                                                </div>
                                            </div>
                                            <div class="card-footer border-top-dashed">
                                                <div class="d-flex justify-content-between align-items-center flex-wrap gap-3">
                                                    <div>
                                                        <i class="ri-briefcase-2-line align-bottom me-1"></i>
                                                        <span class="job-type">{{res.type | titlecase}}</span>
                                                    </div>
                                                    <div class="d-none">
                                                        <span class="job-experience">1 - 2 Year</span>
                                                    </div>
                                                    <div>
                                                        <i class="ri-map-pin-2-line align-bottom me-1"></i>
                                                        <span class="job-location">USA</span>
                                                    </div>
                                                    <div>
                                                        <i class="ri-user-3-line align-bottom me-1"></i> {{getDurationRange(res)}} ({{res.duration}} months)
                                                    </div>
                                                    <div>
                                                        <i class="ri-time-line align-bottom me-1"></i>
                                                        <span class="job-postdate">Posted {{ res.creationTime * 1000 | timeago }}</span>
                                                    </div>
                                                    <div>
                                                        <a href="javascript:void(0);" class="btn btn-primary viewjob-list">View More <i class="ri-arrow-right-line align-bottom ms-1"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <app-table-pagination *ngIf="!loading" [tableHelper]="tableHelper"></app-table-pagination>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Invitations </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-invitations></app-carrier-load-board-invitations>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Applications </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-applications></app-carrier-load-board-applications>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Interviewing </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-interviewing></app-carrier-load-board-interviewing>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Rejected </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-rejected></app-carrier-load-board-rejected>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Withdrew </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-withdrew></app-carrier-load-board-withdrew>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Offers </a>
                        <ng-template ngbNavContent>
                            <app-carrier-load-board-offers></app-carrier-load-board-offers>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>