import { Position } from "@trimblemaps/trimblemaps-js/geojson";

export interface MapsDataSource {
  name: string;
  data: TrimbleMaps.AnySourceData;
};

export interface MapsLayer {
  layer: TrimbleMaps.AnyLayer;
  before?: string;
}

export interface FeaturePointProperties {
  zipCode: string;
  serviceType: string;
  cnt: number;
  lng: number;
  lat: number;
}

export interface MapsEventListener {
  event: keyof TrimbleMaps.MapLayerEventType;
  layerId: string;
  listenerFn: (ev: (TrimbleMaps.MapLayerMouseEvent | TrimbleMaps.MapLayerTouchEvent) & TrimbleMaps.EventData) => void;
}

export class TrimbleMapsHelper {
  static generateGeoJsonDataSource(sourceName: string, features: {
    coordinates: Position;
    properties?: Record<string, unknown>; //{ [name: string]: unknown; }
  }[], generatedId = false): MapsDataSource {
    return {
      name: sourceName, 
      data: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features.map(f => ({
            type: 'Feature',
            properties: f.properties || {},
            geometry: {
              type: 'Point',
              coordinates: f.coordinates
            }
          }))
        },
        generateId: generatedId
      },
    };
  }
}