import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { AccountType } from 'src/app/core/services/models/account.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import Swal from 'sweetalert2';
import { NgbdModalBuyLicenses } from '../../licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-users-menu',
  templateUrl: './users-menu.component.html',
  styleUrls: ['./users-menu.component.scss']
})
export class UsersMenuComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Settings'
    }
  ];
  activeModal?: NgbModalRef;
  
  @Input() activeTab: any = 1;
  @Output() changeChildTab: EventEmitter<any> = new EventEmitter();
  currentAccountPlan: AccountPlan;
  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
  }

  changeTab(event: any) {
    if(
      AccountPlanUtil.isTrial(this.auth.currentAccountPlanValue) 
      && (event === 3)
    ){
      this.promptUpgrade();
      return;
    }
    if(event == 1) {
      this.router.navigateByUrl("pages/settings/users/users");
    } else if(event == 2) {
      this.router.navigateByUrl("pages/settings/users/roles");
    } else if(event == 3) {
      this.router.navigateByUrl("pages/settings/users/chat-requests");
    } else if(event == 4) {
      if(this.auth.currentAccountSelected.accountType !== 'carrier-account'){
        this.changeTab(1);
        return;
      }
      this.router.navigateByUrl("pages/settings/users/drivers");
    }
    this.changeChildTab.emit(event);
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  promptUpgrade(): void {
    if(this.auth.currentAccountPlanValue.status === 'paid'){
      return;
    }
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

}
