import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipper-search',
  templateUrl: './shipper-search.component.html',
  styleUrls: ['./shipper-search.component.scss']
})
export class ShipperSearchComponent implements OnInit {
    searchForm!: FormGroup;
    inviteForm!: FormGroup;
    @Input() showCard!: boolean;
    @Input() loadDetails!: any;
    activeModal?: NgbModalRef;
    submitted = false;
    submittedInvite = false;
    companyInformation: any;
    routes: any = [];
    loads: any = [];
    errorInvite = '';
    carrierName = '';
    hasAuthorityData = [{
      prettyName: 'Active',
      technicalName: true
    },
    {
      prettyName: 'Inactive',
      technicalName: false
    }];

    status = [{
      prettyName: 'Accepted',
      technicalName: 'accepted'
    },
    {
      prettyName: 'Rejected',
      technicalName: 'rejected'
    }];
    selectedRouteName: any;
    modalOpen = false;

  // Table data
  tableHelper: TablePaginationHelper<Account & {hasAuthority?: boolean; zipCodes?: string[]}>;

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private httpRequest: HttpService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForms();
    
    // this.search();
    this.tableHelper = new TablePaginationHelper({
      loadRecords: (pageRequest)=>{
        let name = (this.searchForm.controls['name'].value === '') ? '' : this.searchForm.controls['name'].value;
        let dotNumber = (this.searchForm.controls['dotNumber'].value === '') ? '' : this.searchForm.controls['dotNumber'].value;
        let zipCodes = (this.searchForm.controls['zipCodes'].value === '') ? [] : this.searchForm.controls['zipCodes'].value;
        let url = (this.searchForm.controls['hasAuthority'].value === true) 
        ? 'name=' + name + '&dotNumber=' + dotNumber + '&zipCodes=' + zipCodes.join() + '&hasAuthority'
        : 'name=' + name + '&dotNumber=' + dotNumber + '&zipCodes=' + zipCodes.join();
        const params = (this.searchForm.controls['hasAuthority'].value === true) ? {
          name,
          dotNumber,
          zipCodes: zipCodes.join(),
          hasAuthority: ''
        } : {
          name,
          dotNumber,
          zipCodes: zipCodes.join()
        };
        return this.httpRequest.paginatedGetRequest<Account & {hasAuthority?: boolean; zipCodes?: string[]}>(
          '/shippers'
          , {
            ...params as any,
            ...pageRequest
          }
        ).toPromise()
      }
    });
    this.tableHelper.loadRecords();

    if(!this.loadDetails) {
      this.getLoad();
    } else {
      this.inviteForm.controls['loadId'].setValue(this.loadDetails.loadId);
      this.selectedRouteName = this.loadDetails.routeName;
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  get formInvite() {
    return this.inviteForm.controls;
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  } 
  
  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  initForms() {
    this.searchForm = this.formBuilder.group({
      name: [''],
      dotNumber: [''],
      zipCodes: [''],
      hasAuthority: [true],
    });

    this.inviteForm = this.formBuilder.group({
      loadId: ['', [Validators.required]],
      shipperId: ['', [Validators.required]],
      carrierId: ['', [Validators.required]],
      status: [null],
      message: ['', [Validators.required]]
    });
  }

  getLoad() {
    this.httpRequest.getLoadBoard().subscribe((result: any) => {
      this.loads = result.data;
    });
  }

  selectLoad(event: any) {
    this.selectedRouteName = event.routeName;
  }

  isActive(status: any) {
    if(status == 'active') {
      this.searchForm.controls['hasAuthority'].setValue(true);
    } else {
      this.searchForm.controls['hasAuthority'].setValue(false);
    }
  }

  openModal(content: any, data: any, modalType: string) {
    if(!this.modalOpen) {
      this.carrierName = data.legalName;
      if(modalType == 'view') {
        this.modalOpen = true;
        this.httpRequest.get('companies/' + data.accountId).subscribe((data: any) => {
          this.companyInformation = data.data;
          this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
          this.activeModal.result.finally(() => {
              this.modalOpen = false;
              this.initForms();
           });
        }, error => {
            return error;
        })
      } else if(modalType == 'invite') {
        this.modalOpen = true;
        this.inviteForm.controls['carrierId'].setValue(data.accountId);
        this.httpRequest.getRoutes().subscribe((data) => {
          this.routes = data.data;
          this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
          this.activeModal.result.finally(() => {
            this.modalOpen = false;
            this.initForms();
          });
        }) 
      }    
    } else {

    }
  }

  // search() {	
  //   let name = (this.searchForm.controls['name'].value === '') ? '' : this.searchForm.controls['name'].value;	
  //   let dotNumber = (this.searchForm.controls['dotNumber'].value === '') ? '' : this.searchForm.controls['dotNumber'].value;	
  //   let zipCodes = (this.searchForm.controls['zipCodes'].value === '') ? [] : this.searchForm.controls['zipCodes'].value;	
  //   let url = (this.searchForm.controls['hasAuthority'].value === true) 	
  //   ? 'name=' + name + '&dotNumber=' + dotNumber + '&zipCodes=' + zipCodes.join() + '&hasAuthority'	
  //   : 'name=' + name + '&dotNumber=' + dotNumber + '&zipCodes=' + zipCodes.join();	
  //   this.httpRequest.shipperSearch(url).subscribe((data) => {	
  //       this.searchResult = data.data;	
  //   })	
  // }

  block() {
    Swal.fire({
        title: 'Are you Sure ?',
        text: 'Are you sure you want to block this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#405189',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(result => {
      });
  }

  goToCreateContract(accountId: string): void {
    this.router.navigate([`./create-contract/${accountId}`], {
      relativeTo: this.route.parent
    });
  }

  inviteRoute() {
    this.submittedInvite = true;
    this.inviteForm.controls['shipperId'].setValue(this.auth.currentAccountSelected.accountId);
    if(this.inviteForm.invalid) {
      return;
    } else {
      this.httpRequest.inviteCarriers(this.inviteForm.value).subscribe((data: any) => {
        this.submittedInvite = false;
        this.initForms();
        this.activeModal?.close();
      }, error => {
        this.errorInvite = error.error.reason;
      })
    }
  }


}
