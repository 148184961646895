import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http-service';
import { LoadBoardModel } from 'src/app/core/services/models/load-board.model';
import { ProposalsModel } from 'src/app/core/services/models/proposals.model';
import { TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { isArrayEmpty } from 'src/app/core/utils/commons';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { projectListWidgets } from './data';
import { TeamService } from './shipper-load-board.service';
import { NgbdTeamSortableHeader, SortEvent } from './shipper-load-board.sortable.directive';

interface projectListModel {
  icon?: string;
  icon_color?: string;
  name?: string;
  type?: string;
  size?: string;
  date?: string;
}

export interface TeamModel {
  id: string;
  image: string;
  bg_color: string;
  name: string;
  position: string;
  projects: string;
  tasks: string;
  star: string;
}

@Component({
  selector: 'app-shipper-load-board',
  templateUrl: './shipper-load-board.component.html',
  styleUrls: ['./shipper-load-board.component.scss'],
  providers: [TeamService, DecimalPipe]
})
export class ShipperLoadBoardComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [];

  projectListWidgets!: projectListModel[];
  submitted = false;

  originalLoadBoard: LoadBoardModel;

  // Table data
  teamOverviewList$!: Observable<TeamModel[]>;
  total$: Observable<number>;
  @ViewChildren(NgbdTeamSortableHeader) headers!: QueryList<NgbdTeamSortableHeader>;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public service: TeamService
  ) {
    this.originalLoadBoard = this.router.getCurrentNavigation()?.extras.state?.['loadBoard'];
    console.log('[this.originalLoadBoard]', this.originalLoadBoard);
    if(!this.originalLoadBoard){
      this.router.navigate(['/pages/dedicated-load-board']);
    }
    this.teamOverviewList$ = service.countries$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Load Board', url: '/pages/dedicated-load-board' },
      { label: this.originalLoadBoard.title }
    ];
    /**
     * Fetches the data
     */
     this.fetchData();
  }


  /**
   * Fetches the data
   */
   private fetchData() {
    this.projectListWidgets = projectListWidgets;
  }

  /**
  * Sort table data
  * @param param0 sort the column
  *
  */
   onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

    /**
   * Open modal
   * @param content modal content
   */
     openModal(content: any) {
      this.submitted = false;
      this.modalService.open(content, { size: 'md', centered: true });
    }

   /**
   * Active Toggle navbar
   */
  activeMenu(id:any) {            
    document.querySelector('.star_'+id)?.classList.toggle('active');
  }

  getTimestamp(date: string) {
    return Date.parse(date);
  }

}
