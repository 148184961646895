<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div>
                            <button type="button" class="btn btn-primary add-btn me-4" 
                                data-bs-toggle="modal" id="create-btn"
                                (click)="openSaveModal(modal)"
                            >
                                <i class="ri-add-line align-bottom me-1"></i> Add Asset 
                            </button>
                        </div>
                    </div>
                </div>
                <table class="table align-middle table-nowrap mt-3 mb-1">
                    <thead class="table-light">
                        <tr>
                            <th style="width: 2%">Truck</th>
                            <th style="width: 2%">Trailer</th>
                            <th style="width: 15%">Driver Type</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showData">
                        <ng-container *ngFor="let asset of assets" >
                            <tr>
                                <td>
                                    <a class="clickable-column" (click)="openSaveModal(modal, asset)">
                                        <u>{{asset.truckName}}</u>
                                    </a>
                                </td>
                                <td>
                                    {{asset.trailerName}}
                                </td>
                                <td>
                                    {{asset.driverType}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tbody *ngIf="!showData">
                        <tr class="loader-body">
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                            <td class="placeholder-glow name-td">
                                <span class="placeholder col-6"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #modal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" style="margin: 0 auto">
            {{ originalAsset ? 'Edit': 'New' }} Asset
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="activeModal?.close()" style="margin-left: 0"></button>
    </div>
    <form [formGroup]="assetForm">
        <div class="modal-body">
            <div style="padding-right: 270px;">
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="isPublic" class="form-label">Public: <span class="opacity-0">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <div class="mt-1"
                            [ngClass]="{ 'is-invalid': submitted && assetFormControls['isPublic'].errors }">
                            <p-checkbox name="isPublic" [value]="true"
                                (click)="isPublicClick()"
                                [disabled]="true"
                                [formControl]="$any(assetFormControls['isPublic'])">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="truckId" class="form-label">Truck: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="truckId" [items]="truckOptions" class="form-control" 
                            formControlName="truckId" 
                            bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['truckId'].errors" class="text-danger"> Select Truck </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="trailerId" class="form-label">Trailer: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="trailerId" [items]="trailerOptions" class="form-control" 
                            formControlName="trailerId" 
                            bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['trailerId'].errors" class="text-danger"> Select Trailer </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="primaryDriver" class="form-label">Primary Driver: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="primaryDriver" [items]="primaryDriverOptions" class="form-control" 
                            formControlName="primaryDriver" (change)="primaryDriverChanged($event)"
                            bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['primaryDriver'].errors" class="text-danger"> Select a Primary Driver </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="backupDrivers" class="form-label">Backup Drivers: <span class="opacity-0">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="backupDrivers" [items]="backupDriverOptions" class="form-control" [multiple]="true" 
                            formControlName="backupDrivers" 
                            bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['backupDrivers'].errors" class="text-danger"> Select a Backup Driver </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="driverType" class="form-label">Driver Type: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="driverType" [items]="driverTypeOptions" class="form-control" 
                            formControlName="driverType" 
                            bindLabel="name" bindValue="name" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['driverType'].errors" class="text-danger"> Select Driver Type </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 user-form-label text-align-right">
                        <label for="isDriverDutyStatusDay" class="form-label">Duty Status: <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-lg-9">
                        <ng-select for="isDriverDutyStatusDay" [items]="DUTY_STATUS_OPTIONS" class="form-control" 
                            formControlName="isDriverDutyStatusDay" 
                            bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" [clearable]="false">
                        </ng-select>
                        <div *ngIf="submitted && assetFormControls['isDriverDutyStatusDay'].errors" class="text-danger"> Select Duty Status </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 px-5">
                <div class="col-lg-12">
                    <p-table [value]="scheduleInputs" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 1%"></th>
                                <th style="width: 5%">Day</th>
                                <th style="width: 5%">Start Time</th>
                                <th style="width: 5%">End Time</th>
                                <th style="width: 10%">Preferred Zones</th>
                                <th style="width: 20%">Preferred Lanes</th>
                                <th style="width: 10%">Final Miles Service Types</th>
                                <th style="width: 10%">Truckload Service Types</th>
                                <th style="width: 1%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-scheduleInput let-rowIndex="rowIndex">
                            <tr>
                                <td>
                                    <p-checkbox [(ngModel)]="scheduleInput.enabled"
                                        [binary]="true"
                                        [ngModelOptions]="{standalone: true}"
                                        [name]="'enable_'+rowIndex" [value]="true"></p-checkbox>
                                </td>
                                <td>
                                    {{scheduleInput.dayOfWeekDisplay}}
                                </td>
                                <td>
                                    <ng-select [(ngModel)]="scheduleInput.startTime"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="hourOptions" class="form-control" 
                                        bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select [(ngModel)]="scheduleInput.endTime"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="hourOptions" class="form-control" 
                                        bindLabel="prettyName" bindValue="techName" dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>
                                    <a class="clickable-column" (click)="openZipModal(zipModal)">
                                        <u>Edit</u>
                                    </a>
                                    <ng-select [(ngModel)]="scheduleInput.preferredZones"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="[{code: '90210'}]"
                                        [multiple]="true"
                                        class="form-control" 
                                        bindLabel="code" bindValue="code" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>
                                    <table class="w-100 mb-1">
                                        <tr *ngFor="let lane of scheduleInput.preferredLaneInputs">
                                            <td style="width: 48%" class="pb-2">
                                                <p-autoComplete class="w-100"
                                                    [(ngModel)]="lane.origin"
                                                    [ngModelOptions]="{standalone: true}" 
                                                    [suggestions]="suggestions" 
                                                    (completeMethod)="search($event)"></p-autoComplete>
                                            </td>
                                            <td class="text-center pb-2" style="width: 4%">to</td>
                                            <td style="width: 48%" class="pb-2">
                                                <p-autoComplete class="w-100" 
                                                    [(ngModel)]="lane.destination"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [suggestions]="suggestions" 
                                                    (completeMethod)="search($event)"></p-autoComplete>
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="d-flex">
                                        <a *ngIf="scheduleInput.preferredLaneInputs.length > 1"
                                            class="clickable-column text-danger"
                                            (click)="scheduleInput.preferredLaneInputs.pop()">
                                            <u>Delete</u>
                                        </a>
                                        <a class="clickable-column ms-auto" 
                                            (click)="scheduleInput.preferredLaneInputs.push({})">
                                            <u>Add</u>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <ng-select [(ngModel)]="scheduleInput.finalMileServiceTypes"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="SERVICE_TYPE_NAME_OPTIONS['FM']"
                                        [multiple]="true"
                                        class="form-control" 
                                        bindLabel="name" bindValue="name" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select [(ngModel)]="scheduleInput.truckloadServiceTypes"
                                        [ngModelOptions]="{standalone: true}"
                                        [items]="SERVICE_TYPE_NAME_OPTIONS['TL']"
                                        [multiple]="true"
                                        class="form-control" 
                                        bindLabel="name" bindValue="name" 
                                        dropdownPosition="bottom" 
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>
                                    <i class="pointer las la-copy fs-4 d-inline-block align-middle"
                                        role="button"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <div class="modal-footer px-4" [ngClass]="{'justify-content-end': !originalAsset}">
            <span *ngIf="originalAsset" class="clickable-column me-auto d-flex align-items-center text-danger">
                <div *ngIf="!isLoading" (click)="delete()" class="inline-block"> Delete </div>
                <div *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="submit" class="btn btn-light" data-bs-toggle="modal" 
                (click)="activeModal?.close()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" [disabled]="isLoading"
                (click)="submitAsset()">
                <span class="flex-grow-1 me-2">
                    Save
                  </span>
                  <span class="spinner-border save-loading" role="status" *ngIf="isLoading">
                      <span class="visually-hidden">Loading...</span>
                  </span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #zipModal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" style="margin: 0 auto">
            Select Preferred Zones for MondayX
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="zipActiveModal?.close()" style="margin-left: 0"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-7">
                <app-mapbox-zipcode-picker [selectableZipCodes]="zipCodes" [(zipCodeGroups)]="zipCodeGroups" [zoom]="7"></app-mapbox-zipcode-picker>
                <div class="row">
                    <div class="col-lg-5">
                        <span for="input-radius-mile">Select All Zips In A</span>
                        <input type="number" id="input-radius-mile"
                            class="form-control"
                            [(ngModel)]="radiusMile" />
                    </div>
                    <div class="col-lg-5">
                        <span for="input-radius-zipcode">Mile Radius Of</span>
                        <input type="text" id="input-radius-zipcode"
                            class="form-control"
                            placeholder="Zip Code"
                            [(ngModel)]="radiusZipCode" />
                    </div>
                    <div class="col-lg-2 pt-3">
                        <button type="button" id="btn-radius-select"
                            class="btn btn-primary action-btn"
                            [disabled]="!radiusMile || !radiusZipCode"
                            (click)="onClickSelectZipsRadius()"
                        >
                            Select Zips
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="card" style="height: 90%">
                    <div class="card-header text-center" #target>
                        {{selectedZipCodes?.length || 0}} Zips &nbsp; 
                        <i placement="top" container="body" 
                            [ngbPopover]="popContent" 
                            [attr.positionTarget]="target" 
                            popoverClass="modal-app-save-capacity-popoverClass" 
                            #popup="ngbPopover" 
                            class="pointer las la-copy fs-4 d-inline-block align-middle" 
                            (click)="copyZipCodes(popup)">
                            <ng-template #popContent>
                                <span style="color: green">
                                    <i class="las la-check-circle"></i> Zips copied 
                                </span>
                            </ng-template>
                        </i>
                    </div>
                    <div class="zip-codes-section card-body text-center p-0">
                        <p-chips id="zip-chips" [(ngModel)]="selectedZipCodes" [styleClass]="'form-control p-0 border-0'" [inputStyleClass]="'form-control'" [separator]="$any(separatorExp)" (onAdd)="onAddZipCodes()">
                            <ng-template let-item pTemplate="item">
                                {{item}}
                                <br />
                            </ng-template>
                        </p-chips>
                        <div class="text-muted mt-n3" (click)="focusOnZipChipsInput()"> Enter Zips </div>
                    </div>
                </div>
                <div class="modal-footer px-4" [ngClass]="{'justify-content-end': !originalAsset}">
                    <span *ngIf="originalAsset" class="clickable-column me-auto d-flex align-items-center text-danger">
                        <div *ngIf="!isLoading" (click)="delete()" class="inline-block"> Delete </div>
                        <div *ngIf="isLoading" class="spinner-border inline-block save-loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <button type="submit" class="btn btn-light" data-bs-toggle="modal" 
                        (click)="activeModal?.close()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" [disabled]="isLoading"
                        (click)="submitAsset()">
                        <span class="flex-grow-1 me-2">
                            Save
                          </span>
                          <span class="spinner-border save-loading" role="status" *ngIf="isLoading">
                              <span class="visually-hidden">Loading...</span>
                          </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>