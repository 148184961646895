import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-zipcode-picker',
  templateUrl: './zipcode-picker.component.html',
  styleUrls: ['./zipcode-picker.component.scss']
})
export class ZipcodePickerComponent implements OnInit, AfterViewInit {
  selectValue: string[] = [];
  clearSearchKey = false;

  @ViewChild('selectEl', {static: false}) selectEl!: NgSelectComponent;
  showButtonEl!: HTMLButtonElement;
  doneButtonEl!: HTMLButtonElement;

  @Input() name!: string;

  /** space separated list of classes */
  @Input() customClass!: string;
  // @Input() formControlName!: string;
  // @Input() form!: FormGroup;
  @Input() control: FormControl = new FormControl();
  @Input() isShowError = false;
  
  @Output() blur: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.selectValue = [...new Set(jsonData)] as string[];
    });
  }

  ngAfterViewInit(): void {
    const selectContainerEl = this.selectEl.element.querySelector('.ng-select-container');
    if (!selectContainerEl) {
      console.error('.ng-select-container not found. Unable to append Show/Done button');
      return;
    }

    selectContainerEl.className += ' input-group';

    this.showButtonEl = document.createElement('button');
    this.showButtonEl.innerText = 'Show';
    this.showButtonEl.classList.add('btn', 'btn-primary');
    selectContainerEl.appendChild(this.showButtonEl);

    this.doneButtonEl = document.createElement('button');
    this.doneButtonEl.innerText = 'Done';
    this.doneButtonEl.classList.add('btn', 'btn-primary');
    this.doneButtonEl.addEventListener('click', () => this.closeDropdown());
    selectContainerEl.appendChild(this.doneButtonEl);

    this.updateShowDoneButton(false);
  }

  private updateShowDoneButton(isDropdownOpen: boolean): void {
    if (isDropdownOpen) {
      this.doneButtonEl.style.display = 'inline-block';
      this.showButtonEl.style.display = 'none';
    } else {
      this.doneButtonEl.style.display = 'none';
      this.showButtonEl.style.display = 'inline-block';
    }
  }

  private closeDropdown(): void {
    this.selectEl.close();
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  addZip(event: any) {
    if(event.term.length >= 5) {
      this.clearSearchKey = true;
    } else {
      this.clearSearchKey = false;
    }
  }

  onBlur(event: any): void {
    this.blur.emit(event);
  }

  onOpen(): void {
    this.updateShowDoneButton(true);
  }

  onClose(): void {
    this.updateShowDoneButton(false);
  }
}
