import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { NgbdGridJsSortableHeader } from 'src/app/core/services/invitations-service/gridjs-sortable.directive';
import { InvitationsService } from 'src/app/core/services/invitations-service/invitations.service';
import { InvitationsModel } from 'src/app/core/services/models/invitations.model';
import { OffersModel } from 'src/app/core/services/models/offers.model';
import { ProposalsModel } from 'src/app/core/services/models/proposals.model';
import { OffersService } from 'src/app/core/services/offers-service/offers.service';
import { ProposalsService } from 'src/app/core/services/propsals-service/proposals.service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-load-details',
  templateUrl: './load-details.component.html',
  styleUrls: ['./load-details.component.scss'],
  providers: [
    InvitationsService,
    ProposalsService,
    OffersService,
    DecimalPipe
  ]

})
export class LoadDetailsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Dedicated Load Board'}
  ];
  activeModal?: NgbModalRef;
  loadDetails: any;

  ListJsListInvitations$!: Observable<InvitationsModel[]>;
  totalInvitations$: Observable<number>;

  ListJsListProposals$!: Observable<ProposalsModel[]>;
  totalProposals$: Observable<number>;

  ListJsListOffers$!: Observable<OffersModel[]>;
  totalOffers$: Observable<number>;

  companyInformation: any;
  carrierName: any;

  // @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;
  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    public serviceInvitations: InvitationsService,
    public serviceProposals: ProposalsService,
    public serviceOffers: OffersService,
  ) {
    this.ListJsListInvitations$ = serviceInvitations.invitations$;
    this.totalInvitations$ = serviceInvitations.total$;

    this.ListJsListProposals$ = serviceProposals.proposals$;
    this.totalProposals$ = serviceProposals.total$;

    this.ListJsListOffers$ = serviceOffers.offers$;
    this.totalOffers$ = serviceOffers.total$;

    if (this.router.getCurrentNavigation() != null) {
        this.loadDetails = this.router.getCurrentNavigation()?.extras.state;
        if(!this.loadDetails) {
          this.router.navigate(['/pages/dedicated-load-board']);
        }
      }
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      {label: 'Dedicated Load Board', url: '/pages/dedicated-load-board'},
      {label: this.loadDetails.loadDetails.title}
    ];
  }

  acceptProposal(proposalData: any) {
    let id = proposalData.proposalId
    let dataToSend = {
      loadId: proposalData.loadId,
      status: 'accepted'
    };
    this.httpService.updateProposal(id, dataToSend as any).subscribe(() => {
      Swal.fire({
        title: 'Success',
        text: 'Load has been accepted.',
        icon: 'success',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: false,
      }).then((result: any) => {
        this.serviceProposals.updateTable();
      });
    })
  }

  rejectProposal(proposalData: any) {
    let id = proposalData.proposalId
    let dataToSend = {
      loadId: proposalData.loadId,
      status: 'rejected'
    };
    this.httpService.updateProposal(id, dataToSend as any).subscribe(() => {
      Swal.fire({
        title: 'Success',
        text: 'Load has been rejected.',
        icon: 'success',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: false,
      }).then((result: any) => {
        this.serviceProposals.updateTable();
      });
    })
  }

  updateInvitations() {
    this.serviceInvitations.updateTable();
  }

  openModal(content: any, data: any, modalType: string) {
    this.carrierName = data.carrierName;
    if(modalType == 'view') {
      this.httpService.get('companies/' + data.carrierId).subscribe((data: any) => {
        this.companyInformation = data.data;
        this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
        this.activeModal.result.finally(() => {
         });
      }, error => {
          return error;
      })
    }  
  }

}
