export type AccountPlanStatus = 'paid' | 'trial' | 'trial_expired';

export interface AccountPlan {
    accountId: string;
    status: AccountPlanStatus;
    expiration: number;
}

export class AccountPlanUtil {
    static isTrial(accountPlan?: AccountPlan):boolean {
        const status = accountPlan?.status;
        if(!status){
            return true;
        }
        return status === 'trial' || status === 'trial_expired';
    }
}