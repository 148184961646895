import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { UserComponent } from './users/user/user.component';
import { OriginPointComponent } from './origin-points/origin-point/origin-point.component';
import { LoadBoardComponent } from './load-board/load-board/load-board.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { SettingsComponent } from './extrapages/profile-settings/settings.component';
import { CompanySettingsComponent } from './extrapages/company-setting/company-settings.component';
import { ServiceAreaComponent } from './service-area/service-area.component';
import { ServiceableZipcodesComponent } from './serviceable-zipcodes/serviceable-zipcodes.component';
import { AccountsPayableComponent } from './accounts-payable/accounts-payable.component';
import { ZonesAndRoutesComponent } from './zones-and-routes/zones-and-routes.component';
import { CarriersComponent } from './carriers/carriers.component';
import { CarrierSearchComponent } from './carrier-search/carrier-search.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { FinalMileCertifiedComponent } from './final-mile-certified/final-mile-certified.component';
import { LoadDetailsComponent } from './load-board/load-details/load-details.component';
import { CarrierLoadBoardComponent } from './load-board/carrier-load-board/carrier-load-board.component';
import { UserCompanySettingsComponent } from './settings/settings.component';
import { InvoiceDetailsComponent } from './settings/invoice-details/invoice-details.component';
import { TemplateComponent } from './template/template.component';
import { RouteCapacityAndRatesComponent } from './route-capacity-and-rates/route-capacity-and-rates.component';
import { TestComponent } from './test/test.component';
import { MessagesComponent } from './messages/messages.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { RolesComponent } from './settings/roles/roles.component';
import { AddBranchComponent } from './origin-points/add-branch/add-branch.component';
import { EditBranchComponent } from './origin-points/edit-branch/edit-branch.component';
import { UserInvitationsComponent } from './user-invitations/user-invitations.component';
import { CreateContractComponent } from './create-contract/create-contract.component';
import { SaveRouteComponent } from './zones-and-routes/save-route/save-route.component';
import { PrivacyPageComponent } from './privacy/privacy-page.component';
import { ContractsComponent } from './contracts/contracts.component';
import { SignedContractComponent } from './signed-contract/signed-contract.component';
import { TermsPageComponent } from './terms/terms-page.component';
import { ViewContractComponent } from './view-contract/view-contract.component';
import { CreateVehicleComponent } from './vehicles/create-vehicle/create-vehicle.component';
import { MapsComponent } from './maps/maps.component';
import { TMSComponent } from './tms/tms.component';
import { TruckloadTMSComponent } from './truckload-tms/truckload-tms.component';
import { ReportsComponent } from './reports/reports.component';
import { BranchSchedulingComponent } from './branch-scheduling/branch-scheduling.component';
import { ServiceAreasAndZonesComponent } from './service-areas-and-zones/service-areas-and-zones.component';
import { LanesComponent } from './lanes/lanes.component';
import { RatesComponent } from './rates/rates.component';
import { SaveLaneComponent } from './lanes/save-lane/save-lane.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { SetupComponent } from './setup/setup.component';
import { FAQComponent } from './faq/faq.component';
import { ShipperLoadBoardComponent } from './load-board/shipper-load-board/shipper-load-board.component';
import { EquipmentComplianceComponent } from './equipment-compliance/equipment-compliance.component';
import { DriverQualificationFilesComponent } from './driver-qualification-files/driver-qualification-files.component';
import { CapacityComponent } from './capacity/capacity.component';
import { SaveCapacityComponent } from './capacity/save-capacity/save-capacity.component';
import { AssetsComponent } from './assets/assets.component';
// Component pages

const routes: Routes = [
  {
    path: "",
    component: ComingSoonComponent
  },
  {
    path: "dashboard",
    component: DashboardComponent
  },
  {
    path: "privacy",
    component: PrivacyPageComponent
  },
  {
    path: "terms",
    component: TermsPageComponent
  },
  {
    path: "user-management",
    component: UserComponent
  },
  {
    path: "branches",
    component: OriginPointComponent
  },
  {
    path: "add-branch",
    component: AddBranchComponent
  },
  {
    path: "edit-branch",
    component: EditBranchComponent
  },
  {
    path: "dedicated-load-board",
    component: LoadBoardComponent
  },
  {
    path: "shipper-load-board",
    component: ShipperLoadBoardComponent
  },
  {
    path: "carrier-load-board",
    component: CarrierLoadBoardComponent
  },
  {
    path: "user-profile",
    component: SettingsComponent
  },
  {
    path: "company-profile",
    component: CompanySettingsComponent
  },
  {
    path: "service-area",
    component: ServiceAreaComponent
  },
  {
    path: "serviceable-zipcodes",
    component: ServiceableZipcodesComponent
  },
  {
    path: "accounts-payable",
    component: AccountsPayableComponent
  },
  {
    path: "integrations",
    component: AccountsPayableComponent
  },
  {
    path: "routes",
    component: ZonesAndRoutesComponent,
  },
  {
    path: "create-route",
    component: SaveRouteComponent
  },
  {
    path: "edit-route",
    component: SaveRouteComponent
  },
  {
    path: "trading-relationships",
    component: CarriersComponent
  },
  {
    path: "carrier-search",
    component: CarrierSearchComponent
  },
  {
    path: "shipper-search",
    component: CarrierSearchComponent // change when there is specs on how the ShipperSearch should look like. Or if it's similar to carriersearch, reuse
  },
  {
    path: "vehicles-and-equipment/:activeTab/create",
    component: CreateVehicleComponent
  },
  {
    path: "vehicles-and-equipment/:activeTab",
    component: VehiclesComponent
  },
  {
    path: "vehicles-and-equipment",
    redirectTo: "vehicles-and-equipment/trucks"
  },
  {
    path: "assets",
    component: AssetsComponent
  },
  {
    path: "final-mile-certified",
    component: FinalMileCertifiedComponent
  },
  {
    path: "load-details",
    component: LoadDetailsComponent
  },
  {
    path: "settings/:activeTab/:activeChildTab",
    component: UserCompanySettingsComponent
  },
  {
    path: "settings/:activeTab",
    component: UserCompanySettingsComponent
  },
  // {
  //   path: "settings",
  //   component: UserCompanySettingsComponent
  // },
  {
    path: "invoice",
    component: InvoiceDetailsComponent
  },
  {
    path: "template",
    component: TemplateComponent
  },
  {
    path: "verification",
    component: CompleteProfileComponent
  },
  //TODO: temporarily commented as instructed on https://github.com/project-dtc/issues/issues/1644
  {
    path: "route-capacity",
    component: RouteCapacityAndRatesComponent
  },
  {
    path: "capacity",
    component: CapacityComponent
  },
  {
    path: "save-capacity",
    component: SaveCapacityComponent
  },
  {
    path: "test",
    component: TestComponent
  },
  {
    path: "messages",
    component: MessagesComponent
  },
  {
    path: "meetings",
    component: MeetingsComponent
  },
  {
    path: "roles",
    component: RolesComponent
  },
  {
    path: "user-invitations",
    component: UserInvitationsComponent
  },
  {
    path: "create-contract/:accountId",
    component: CreateContractComponent
  },
  {
    path: "contracts/:contractId/signed",
    component: SignedContractComponent
  },
  {
    path: "contracts/:contractId",
    component: ViewContractComponent
  },
  {
    path: "contracts",
    component: ContractsComponent
  },
  {
    path: "create-contract",
    component: CreateContractComponent
  },
  {
    path: "maps",
    component: MapsComponent
  },
  {
    path: "tms",
    component: TMSComponent
  },
  {
    path: "truckload-tms",
    component: TruckloadTMSComponent
  },
  {
    path: "reports",
    component: ReportsComponent
  },
  {
    path: "branch-scheduling",
    component: BranchSchedulingComponent
  },
  {
    path: "service-areas-and-zones",
    component: ServiceAreasAndZonesComponent
  },
  {
    path: "lanes",
    component: LanesComponent
  },
  {
    path: "create-lane",
    component: SaveLaneComponent
  },
  {
    path: "edit-lane",
    component: SaveLaneComponent
  },
  {
    path: "rates",
    component: RatesComponent
  },
  {
    path: "compliance",
    component: ComplianceComponent
  },
  {
    path: "quick-start-links",
    component: SetupComponent
  },
  {
    path: "faq",
    component: FAQComponent
  },
  {
    path: "equipment-compliance",
    component: EquipmentComplianceComponent
  },
  {
    path: "driver-qualification-files",
    component: DriverQualificationFilesComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
