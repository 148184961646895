import { MenuItem } from './menu.model';

export const MENU_ID = {
  VERIFICATION: 2,
  DASHBOARD: 3,
  TRADING_RELATIONSHIPS: 4,
  CARRIER_SEARCH: 5,
  DEDICATED_LOAD_BOARD: 6,
  BRANCHES: 7,
  ROUTES: 8,
  FINAL_MILE_CERTIFIED: 9,
  CAPACITY: 10,
  MESSAGES: 11,
  MEETINGS: 12,
  USER_INVITATIONS: 13,
  SHIPPER_SEARCH: 14,
  CONTRACTS: 15,
  VEHICLES_AND_EQUIPMENT: 16,
  MAPS: 17,
  TMS: 18,
  REPORTS: 19,
  BRANCH_SCHEDULING: 20,
  SERVICE_AREAS_AND_ZONES: 21,
  LANES: 22,
  RATES: 23,
  COMPLIANCE: 24,
  SETUP: 25,
  TRUCKLOADTMS: 26,
  EQUIPMENT_COMPLIANCE: 27,
  DRIVER_QUALIFICATION_FILES: 28,
  ASSETS: 29,
}

export const MENU: MenuItem[] = [
  // {
  //   id: 1,
  //   label: 'MENUITEMS.MENU.TEXT',
  //   isTitle: true
  // },
  // {
  //   id: MENU_ID.USER_INVITATIONS,
  //   label: 'Invitations',
  //   icon: 'ri-mail-line icon nav-icon',
  //   link: '/pages/user-invitations',
  // },
  // {
  //   id: MENU_ID.VERIFICATION,
  //   label: 'Verification',
  //   icon: 'ri-check-fill icon nav-icon',
  //   link: '/pages/verification',
  //   badge: {
  //     variant: 'badge bg-danger ms-1',
  //     text: ''
  //   },
  //   forPlanTypes: ['Free' , 'paid'],
  // },
  {
    id: MENU_ID.SETUP,
    label: 'Quick Start Links',
    icon: 'ri-check-fill icon nav-icon',
    link: '/pages/quick-start-links',
    badge: {
      variant: 'badge bg-danger ms-1',
      text: ''
    },
    foAccountTypes: ['shipper-account', 'carrier-account', 'broker-account'],
    forPlanTypes: ['trial', 'trial_expired' , 'paid'],
  },
  {
    id: MENU_ID.DASHBOARD,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'ri-dashboard-2-line icon nav-icon',
    link: '/pages/dashboard',
    foAccountTypes: ['shipper-account', 'carrier-account', 'broker-account'],
    forPlanTypes: ['paid']
  },
  {
    id: MENU_ID.TMS,
    label: 'Final Mile TMS',
    icon: 'ri-dashboard-2-line icon nav-icon',
    link: '/pages/tms',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.TRUCKLOADTMS,
    label: 'Truckload TMS',
    icon: 'ri-dashboard-2-line icon nav-icon',
    link: '/pages/truckload-tms',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.REPORTS,
    label: 'Reports',
    icon: 'ri-dashboard-2-line icon nav-icon',
    link: '/pages/reports',
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.MESSAGES,
    label: 'Messages',
    icon: 'ri-chat-3-fill icon nav-icon',
    link: '/pages/messages',
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.MEETINGS,
    label: 'My Calendar',
    icon: 'ri-calendar-event-fill icon nav-icon',
    link: '/pages/meetings',
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.TRADING_RELATIONSHIPS,
    label: 'Trading Relationships',
    icon: 'ri-shopping-cart-line icon nav-icon',
    link: '/pages/trading-relationships',
    forPlanTypes: ['paid'],
  },
  // {
  //   id: MENU_ID.CONTRACTS,
  //   label: 'Contracts',
  //   icon: 'las la-file-contract icon nav-icon',
  //   link: '/pages/contracts',
  //   forPlanTypes: ['paid'],
  // },
  {
    id: MENU_ID.CARRIER_SEARCH,
    label: 'Carrier Search',
    icon: 'ri-search-line icon nav-icon',
    link: '/pages/carrier-search',
    foAccountTypes: ['shipper-account', 'broker-account'],
    forPlanTypes: ['paid', 'trial', 'trial_expired'],
  },
  {
    id: MENU_ID.SHIPPER_SEARCH,
    label: 'Shipper & Broker Search',
    icon: 'ri-search-line icon nav-icon',
    link: '/pages/shipper-search',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['paid', 'trial', 'trial_expired'],
  },
  {
    id: MENU_ID.DEDICATED_LOAD_BOARD,
    label: 'Load Board',
    icon: 'ri-todo-line icon nav-icon',
    link: '/pages/dedicated-load-board',
    foAccountTypes: ['shipper-account', 'carrier-account'],
    forPlanTypes: ['paid'],
  },
  {
    id: MENU_ID.MAPS,
    label: 'Maps',
    icon: 'ri-map-2-line icon nav-icon',
    link: '/pages/maps',
    foAccountTypes: ['shipper-account', 'broker-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid'],
  },
  {
    id: MENU_ID.BRANCHES,
    label: 'Branches',
    icon: 'las la-sitemap icon nav-icon',
    link: '/pages/branches',
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.ROUTES,
    label: 'Routes',
    icon: 'ri-direction-fill icon nav-icon',
    link: '/pages/routes',
    foAccountTypes: ['shipper-account', 'broker-account'],
    forPlanTypes: ['paid']
  },
  {
    id: MENU_ID.SERVICE_AREAS_AND_ZONES,
    label: 'Service Areas & Zones',
    icon: 'las la-globe',
    link: '/pages/service-areas-and-zones',
    foAccountTypes: ['shipper-account', 'broker-account', 'carrier-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.LANES,
    label: 'Lanes',
    icon: 'las la-route',
    link: '/pages/lanes',
    foAccountTypes: ['shipper-account', 'broker-account', 'carrier-account'],
    forPlanTypes: ['paid', 'trial', 'trial_expired']
  },
  {
    id: MENU_ID.CAPACITY,
    label: 'Capacity',
    icon: 'las la-truck-loading icon nav-icon',
    link: '/pages/capacity',
    foAccountTypes: ['shipper-account', 'broker-account', 'carrier-account'],
    forPlanTypes: ['paid']
  },
  {
    id: MENU_ID.RATES,
    label: 'Rates',
    icon: 'las la-dollar-sign',
    link: '/pages/rates',
    foAccountTypes: ['shipper-account', 'broker-account'],
    forPlanTypes: ['paid']
  },
  // {
  //   id: MENU_ID.BRANCH_SCHEDULING,
  //   label: 'Branch Scheduling',
  //   icon: 'ri-dashboard-2-line icon nav-icon',
  //   link: '/pages/branch-scheduling',
  //   foAccountTypes: ['shipper-account', 'broker-account'],
  //   forPlanTypes: ['paid']    
  // },
  {
    id: MENU_ID.VEHICLES_AND_EQUIPMENT,
    label: 'Vehicles & Equipment',
    icon: 'ri-truck-line icon nav-icon',
    link: '/pages/vehicles-and-equipment',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.ASSETS,
    label: 'Assets',
    icon: 'ri-truck-line icon nav-icon',
    link: '/pages/assets',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.EQUIPMENT_COMPLIANCE,
    label: 'Equipment Compliance',
    icon: 'mdi mdi-check-decagram',
    link: '/pages/equipment-compliance',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.COMPLIANCE,
    label: 'Compliance',
    icon: 'mdi mdi-check-decagram',
    link: '/pages/compliance',
    foAccountTypes: ['carrier-account', 'broker-account'],
    forPlanTypes: ['trial', 'trial_expired', 'paid']
  },
  {
    id: MENU_ID.DRIVER_QUALIFICATION_FILES,
    label: 'Driver Qualification FIles',
    icon: 'mdi mdi-check-decagram',
    link: '/pages/driver-qualification-files',
    foAccountTypes: ['carrier-account'],
    forPlanTypes: ['paid']
  },
  // {
  //   id: MENU_ID.FINAL_MILE_CERTIFIED,
  //   label: 'Final Mile Certified',
  //   icon: 'mdi mdi-check-decagram',
  //   link: '/pages/final-mile-certified',
  //   forPlanTypes: ['paid'],
  //   foAccountTypes: ['shipper-account'],
  // }
];
