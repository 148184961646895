import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { NgbdModalContactInformation } from '../extrapages/company-setting/modals/contact-information/contact-information.component';
import { NgbdModalGenaralCompanyInformation } from '../extrapages/company-setting/modals/general-company-information/general-company-information.component';
import { NgbdModalTransportationCompanyInformation } from '../extrapages/company-setting/modals/transportation-company-information/transportation-company-information.component';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileComponent implements OnInit {
  @Input() showCard = true;
  showData = false;
  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Verification'}
  ];
  showVerification = false;
  activeModal?: NgbModalRef;
  companyInformation: any;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private httpRequest: HttpService,
  ) { }

  ngOnInit(): void {
    // this.getProfile();
  }

  getProfile() {
    this.httpRequest.get('companies/' + this.auth.currentAccountSelected).subscribe((data: any) => {
      this.companyInformation = data.data;
      this.showData = true;
    })
  }

  verifyInformation(type: any) {
    if(type == 'companyInformation') {
      this.openModalGeneralInformation();
    } else if(type == 'transportationInformation') {
      this.openModalTransportationInformation();
    } else if(type == 'billing') {
        this.redirectToBilling();
    } else if(type == 'companyEmail') {
        this.openModalContactInformation('emailInformation');
    } else if(type == 'companyPhone') {
        this.openModalContactInformation('phoneInformation');
    }
  }

  openModalContactInformation(type: any) {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalContactInformation, { centered: true });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInformation;
    if(type == 'phoneInformation') {
      modalRef.componentInstance.toOpen = type;
    } else {
      modalRef.componentInstance.toOpen = type;
    }
  }

  reloadComponentCancel() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl);
  }

  redirectToBilling() {
    if(this.router.url != '/pages/settings') {
      this.router.navigateByUrl('/pages/settings', {state: {activeTab: 4}});
    } else {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(currentUrl, {state: {activeTab: 4}});
    }
  }

  openModalGeneralInformation() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalGenaralCompanyInformation, { size: 'lg', centered: true });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInformation;
  }

  openModalTransportationInformation() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalTransportationCompanyInformation, { centered: true });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInformation;
  }

}
