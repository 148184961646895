import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { PrettyTechName, PrettyTechnicalName } from 'src/app/core/services/models/pretty-technical-name';
import { DAYS, DayType, JpgToPngConvertor, isArrayEmpty } from 'src/app/core/utils/commons';
import { DefaultPlace, GoogleMapsSearchBoxComponent } from 'src/app/shared/google-maps-search-box/google-maps-search-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit {

  branchForm!: FormGroup;
  companyInfoForm!: FormGroup;
  imageURL: string | undefined;
  submitted = false;
  hours: PrettyTechName[] = [];
  dayOptions: DayType[] = [...DAYS];
  defaultPlace?: DefaultPlace;
  addressComponent?: AddressComponent;
  companyInfo?: CompanyInfo;

  @ViewChild('googleMapsInput') 
  googleMapsInput: GoogleMapsSearchBoxComponent;

  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  public accountType!: string;

  readonly LEGAL_ENTITY_OPTIONS: PrettyTechnicalName[] = [
    {prettyName: 'Sole Proprietor', technicalName: 'sole-proprietor'},
    {prettyName: 'LLC', technicalName: 'llc'},
    {prettyName: 'C Corporation', technicalName: 'c-corp'},
    {prettyName: 'S Corporation', technicalName: 's-corp'},
  ];

  changedCompanyFormControls: string[] = [];
  isLoading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private httpService: HttpService,
    private router: Router,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          // this.fetchBranch();
          this.fetchCompanyInfo();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnInit(): void {
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hours = [...new Set(jsonData)] as PrettyTechName[];
    });
    this.accountType = this.selectedAccount?.type!;
  }

  fetchBranch(){
    this.httpService.getBranchById(this.selectedBranch?.branchId!).subscribe(
      res => {
        const successRes = <SuccessApiResponse<Branch>> res;
        const branch = successRes.data;
        this.initForm(branch);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch branch: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  fetchCompanyInfo() {
    this.httpService.getCompanyInfos(this.selectedAccount?.companyInfoId!).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        const companyInfo = successRes.data;
        this.companyInfo = companyInfo;
        this.initFormCompanyInfo(companyInfo);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch branch: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  handleError(event: any) {
    event.target.src = 'assets/images/users/multi-user.jpg';
  }

  initFormCompanyInfo(companyInfo: CompanyInfo) {
    this.defaultPlace = {
      input: companyInfo.street + ', ' + companyInfo.city +  ', ' + companyInfo.state + ', ' + companyInfo.zipCode
    };

    this.companyInfoForm = this.formBuilder.group({
      companyInfoId: [companyInfo.companyInfoId, [Validators.required]],
      type: [companyInfo.type, [Validators.required]],
      legalName: [companyInfo.legalName, [Validators.required]],
      legalEntity: [companyInfo.legalEntity, [Validators.required]],
      dbaName: [companyInfo.dbaName, [Validators.required]],
      street: [companyInfo.street, [Validators.required]],
      city: [companyInfo.city, [Validators.required]],
      state: [companyInfo.state, [Validators.required]],
      zipCode: [companyInfo.zipCode, [Validators.required]],
      email: [companyInfo.email, [Validators.required]],
      phone: [companyInfo.phone, [Validators.required]],
      einNumber: [companyInfo.einNumber, [Validators.required]],
      mcNumber: [{value: companyInfo.mcNumber, disabled: true}, [Validators.required]],
      dotNumber: [{value: companyInfo.dotNumber, disabled: true}, [Validators.required]],
      hasAuthority:  [{value: companyInfo.hasAuthority ? 'Active' : 'Inactive', disabled: true}, [Validators.required]],
      subtype: [{value: companyInfo.subtype, disabled: true}, [Validators.required]],
      website: [companyInfo.website, [Validators.required]],
      description: [companyInfo.description, [Validators.required]],
      numberOfEmployees: [companyInfo.numberOfEmployees ?? 0, [Validators.required]],
      foundingYear: [companyInfo.foundingYear ?? 0, [Validators.required]],
      isPublic: [companyInfo.isPublic ?? false, [Validators.required]]
    })

    Object.keys(this.companyInfoForm.controls).forEach(controlName => {
      const control = this.companyInfoForm.get(controlName);

      control?.valueChanges.subscribe(() => {
        if (!this.changedCompanyFormControls.includes(controlName)) {
          this.changedCompanyFormControls.push(controlName);
        }
      });
    });
    
    if(this.accountType === 'carrier-account') {
      this.companyInfoForm.get('legalName')?.disable()
      this.companyInfoForm.get('legalEntity')?.disable()
      this.companyInfoForm.get('dbaName')?.disable()
    }
  
   
  }

  initForm(branch: Branch){
    const placeId = branch?.address?.googlePlaceId;
    const lat = branch?.address?.latitude;
    const lng = branch?.address?.longitude;
    if (placeId && lat && lng) {
      this.defaultPlace = {
        gmap: {placeId, lat, lng}
      };
    }
    this.branchForm = this.formBuilder.group(
      {
        //TODO: ask what is the difference between name and branchName
        name: [branch.accountName, [Validators.required]],
        branchName: [branch.name, [Validators.required]],
        industryType: ['', [Validators.required]], //TODO: ask where to find
        information: [branch.information, [Validators.required]],
        website: ['', [Validators.required]], //TODO: ask where to find
        since: ['', [Validators.required]], //TODO: ask where to find
        openingHoursChecked: [[]],
        secondaryOpeningHoursChecked: [[]]
      }
    );
  }
  
  get form() {
    return this.branchForm.controls;
  }

  get cInfoForm() {
    return this.companyInfoForm.controls;
  }

  async fileChange(event: any) {

    const files: File[] = Array.from(event.target.files);
    console.log('[selectFiles] files', files);

    if(isArrayEmpty(files)){
      alert('No file available!');
      return;
    }

    let file = files[0];
    if(file.type === 'image/jpeg'){
      console.log('[selectFiles] converting jpeg to png...');
      JpgToPngConvertor(file, async (pngFile: File)=>{
        console.log('[selectFiles] converting jpeg to png file', pngFile);
        await this.uploadLogo(pngFile);
      }).process();
    }else{
      await this.uploadLogo(file);
    }
  }

  private async uploadLogo(file: File){
    await this.httpService.uploadfileAWSS3(this.companyInfo?.logoUploadUrl!, file).toPromise();
    this.reloadCurrentComponent();
  }

  private reloadCurrentComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  onPlaceChanged(address: google.maps.places.PlaceResult): void {
    if(address){
        this.addressComponent = new AddressComponent(address);
        this.cInfoForm['street'].setValue(this.addressComponent.street1);
        this.cInfoForm['city'].setValue(this.addressComponent.city);
        this.cInfoForm['state'].setValue(this.addressComponent.state);
        this.cInfoForm['zipCode'].setValue(this.addressComponent.postalCode);
    }else{
        this.addressComponent = undefined;
    }
  }

  save(){
    this.isLoading = true;
    let payload: any = {};
    if(this.changedCompanyFormControls.length != 0) {
      for(let x = 0 ; x < this.changedCompanyFormControls.length; x++) {
        payload[this.changedCompanyFormControls[x]] = this.cInfoForm[this.changedCompanyFormControls[x]].value;
      }
    }
    this.httpService.editCompanyInfo(this.cInfoForm['companyInfoId'].value, payload).subscribe((resp: any) => {
      this.isLoading = false;
      this.initFormCompanyInfo(resp.data);
      Swal.fire({
        title: 'Success',
        text: 'Successfully saved profile.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        //do nothing
      });
    }, error => {
      this.isLoading = false;
      Swal.fire({
        title: 'Error',
        text: 'Failed to update branch: ' + error.error.reason,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
    })
    // console.log('[save] this.branchForm.value', this.branchForm.value);
    // console.log('[save] this.form[\'openingHoursChecked\'].value', this.form['openingHoursChecked'].value);
    // console.log('[save] this.form[\'openingHoursChecked\'].includes(\'Saturday\')', this.form['openingHoursChecked'].value.includes('Saturday'));

    // const branch = {
    //   ...this.branchForm.value,
    //   address: this.addressComponent
    // };

    // this.httpService.updateBranch(this.selectedBranch!.branchId!, branch).subscribe(
    //   (data:any) => {
    //     this.eventService.broadcastBranchChanged(data.data);
    //     Swal.fire({
    //       title: 'Success',
    //       text: 'Successfully saved profile.',
    //       icon: 'success',
    //       showCancelButton: false,
    //       confirmButtonColor: 'rgb(60,76,128)',
    //       confirmButtonText: 'Ok'
    //     }).then(result => {
    //       //do nothing
    //     });
    //   },
    //   error => {
    //     Swal.fire({
    //       title: 'Error',
    //       text: 'Failed to update branch: ' + error.error.reason,
    //       icon: 'warning',
    //       showCancelButton: false,
    //       confirmButtonColor: 'rgb(60,76,128)',
    //       confirmButtonText: 'Ok',
    //     }).then((result) => {
    //       //do nothing
    //     });
    //   }
    // );
  }

}
