import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import Swal from 'sweetalert2';


@Component({
  selector: 'ngbd-modal-upload-verification-document',
  templateUrl: './upload-verification-document-modal.component.html',
  styleUrls: ['./upload-verification-document-modal.component.scss'],

})
export class NgbdModalUploadVerificationDocument implements OnInit {
  @Input() type: string;
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;

  addZoneForm!: FormGroup;
  submittedZone = false;
  branches: any;
  zipcodesJson: any;

  files: any = [];
  fileContent: any;
  constructor(
  	public activeModal: NgbActiveModal,
  	private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
  	) {}

	ngOnInit(): void { }

  close() {
  	this.activeModal?.close();
  }

  onFileDropped($event: any) {
    if(this.files.length > 0) {
        Swal.fire({
            title: 'Error',
            text: 'Cannot upload more than 1 file',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then(result => {
            
          });
    } else {
        this.fileContent = $event[0];
        this.prepareFilesList($event);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  fileBrowseHandler(files: any) {
    if(this.files.length > 0) {
        Swal.fire({
            title: 'Error',
            text: 'Cannot upload more than 1 file',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then(result => {
            
          });
    } else {
      this.fileContent = files.target.files[0];
      this.prepareFilesList(files.target.files);
    }
  }

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }
}