<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="0" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="0" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> My Compliance </a>
                        <ng-template ngbNavContent>
                            <h4 *ngIf="!fmcsa && !isLoading">{{errorMessage}}</h4>
                            <div class="card" *ngIf="fmcsa && !isLoading">
                                <div class="card-body p-4">
                                    <div class="row summary">
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>DBA Name:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.dbaName}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Legal Name:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.legalName}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Street:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.street}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>City:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.city}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>State:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.state}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>ZIP Code:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.zipcode}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Country:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.country}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Drivers:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.drivers}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Power Units:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.powerUnits}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Has Authority:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.hasAuthority? 'Yes': 'No'}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Is Fleet:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.isFleet? 'Yes': 'No'}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Is Broker:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.isBroker? 'Yes': 'No'}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>Has Broker Authority</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.hasBrokerAuthority? 'Yes': 'No'}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>DOT Number:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.dotNumber}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>MC Number:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.mcNumber}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 label">
                                                <label>EIN Number:</label>
                                            </div>
                                            <div class="col-lg-8 ">
                                                {{fmcsa?.einNumber}}
                                            </div>
                                            <div class="col-lg-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> SCAC </a>
                        <ng-template ngbNavContent>
                            <div class="card mt-4">
                                <div class="card-body p-4">
                                    <div class="text-center mt-2">
                                        <div class="ratio ratio-16x9">
                                            <iframe src="https://nmfta.org/scac/" title="SCAC"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> UCR </a>
                        <ng-template ngbNavContent>
                            <div class="card mt-4">
                                <div class="card-body p-4">
                                    <div class="text-center mt-2">
                                        <div class="ratio ratio-16x9">
                                            <iframe src="https://plan.ucr.gov/registration/" title="UCR"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> FMCSA </a>
                        <ng-template ngbNavContent>
                            <img [src]="'//image.thum.io/get/auth/67153-fc224ca602839a789eff5df5e77c6b6b/width/1200/' + fmcsaLink" class="mb-4" role="button" (click)="redirectToFmcsa()" />
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> ELD </a>
                        <ng-template ngbNavContent>
                            <div class="card mt-4">
                                <div class="card-body p-4">
                                    <div class="text-center mt-2">
                                        <div class="ratio ratio-16x9">
                                            <img src="/assets/images/screenshots/samsara.png" class="w-100 mb-4" role="button" (click)="window.open('https://www.samsara.com','_blank')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Fuel Card </a>
                        <ng-template ngbNavContent>
                            <div class="card mt-4">
                                <div class="card-body p-4">
                                    <div class="text-center mt-2">
                                        <div class="ratio ratio-16x9">
                                            <iframe src="https://www.efsllc.com/compare-fleet-cards/" title="Fuel Card"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> IFTA </a>
                        <ng-template ngbNavContent>
                            <img src="/assets/images/screenshots/samsara.png" class="w-100 mb-4" role="button" (click)="window.open('https://www.iftach.org/Carriers/','_blank')" />
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> BOC - 3 </a>
                        <ng-template ngbNavContent>
                            <img src="/assets/images/screenshots/BOC-3.png" class="w-100 mb-4" role="button" (click)="window.open('https://www.fmcsa.dot.gov/registration/form-boc-3-designation-agents-service-process','_blank')" />
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="8" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> HVUT </a>
                        <ng-template ngbNavContent>
                            <img src="/assets/images/screenshots/HUVT.png" class="w-100 mb-4" role="button" (click)="window.open('https://www.fhwa.dot.gov/policyinformation/hvut/mod1/whatishvut.cfm','_blank')" />
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="9" class="nav-item" 
                        [disabled]="isTrial()"
                        (click)="promptUpgrade()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Form 2290 </a>
                        <ng-template ngbNavContent></ng-template>
                    </li>
                    <li [ngbNavItem]="10" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Drug & Alcohol Testing </a>
                        <ng-template ngbNavContent>
                            <img src="/assets/images/screenshots/Drug & Alcohol Testing.png" class="w-100 mb-4" role="button" (click)="window.open('https://www.fmcsa.dot.gov/regulations/drug-alcohol-testing-program','_blank')" />
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="11" class="nav-item"
                        [disabled]="isTrial()"
                        (click)="promptUpgrade()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Insurance </a>
                        <ng-template ngbNavContent></ng-template>
                    </li>
                    <li [ngbNavItem]="12" class="nav-item" 
                        [disabled]="isTrial()"
                        (click)="promptUpgrade()"> 
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> MCS-90 </a>
                        <ng-template ngbNavContent></ng-template>
                    </li>
                    <li [ngbNavItem]="13" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Cat Scale </a>
                        <ng-template ngbNavContent>
                            <img src="/assets/images/screenshots/cat-scale-screenshot.png" class="w-100 mb-4" role="button" (click)="window.open('https://catscale.com/','_blank')" />
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>