<div class="row g-4 mb-3">
    <div class="col-sm-auto">
        <div>
            <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" routerLink="./create">
                <i class="ri-add-line align-bottom me-1"></i> Add Truck </button>
        </div>
    </div>
    <div class="col-sm">
        <div class="d-flex justify-content-sm-end">
            <div class="search-box ms-2">
                <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive table-card mt-3 mb-1">
    <table class="table align-middle table-nowrap mb-0" *ngIf="allVehicles.length != 0">
        <thead class="table-light">
            <tr>
                <th class="sort" data-sort="unitNumber" sortable="unitNumber" (sort)="onSort($event)">Unit Number</th>
                <th class="sort" data-sort="year" sortable="year" (sort)="onSort($event)">Year</th>
                <th class="sort" data-sort="make" sortable="make" (sort)="onSort($event)">Make</th>
                <th class="sort" data-sort="model" sortable="model" (sort)="onSort($event)">Model</th>
                <th class="sort" data-sort="vin" sortable="vin" (sort)="onSort($event)">VIN</th>
                <th class="sort" data-sort="licensePlate" sortable="licensePlate" (sort)="onSort($event)">License Plate</th>
                <th class="sort" data-sort="type" sortable="type" (sort)="onSort($event)">Type</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of allVehicles">
                <td>
                    <ngb-highlight [result]="vehicle.unitNumber + ''" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                    {{vehicle.year}}
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.make" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.model" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.vehicleIdNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.licensePlateNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.type" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <div class="d-flex gap-2">
                        <div class="edit">
                            <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal" (click)="openModal(content, 'edit', vehicle)">Edit</button>
                        </div>
                        <div class="remove">
                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteVehicles(vehicle)">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{ modalType }} Vehicle</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
        {{ error }}
    </div>
    <form [formGroup]="vehicleForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="unitNumber" class="form-label">Unit Number</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="unitNumber" class="form-control" required formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitted && form['unitNumber'].errors }" />
                    <div *ngIf="submitted && form['unitNumber'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['unitNumber'].errors['required']">Enter Unit Number</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="year" class="form-label">Year</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" pattern="\d*" maxlength="4" id="year" class="form-control" required formControlName="year" [ngClass]="{ 'is-invalid': submitted && form['year'].errors }" />
                    <div *ngIf="submitted && form['year'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['year'].errors['required']">Enter Year</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="make" class="form-label">Make</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="make" class="form-control" required formControlName="make" [ngClass]="{ 'is-invalid': submitted && form['make'].errors }" />
                    <div *ngIf="submitted && form['make'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['make'].errors['required']">Enter Make</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="model" class="form-label">Model</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="model" class="form-control" required formControlName="model" [ngClass]="{ 'is-invalid': submitted && form['model'].errors }" />
                    <div *ngIf="submitted && form['model'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['model'].errors['required']">Enter Model</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="vin" class="form-label">VIN</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="vin" class="form-control" required formControlName="vin" [ngClass]="{ 'is-invalid': submitted && form['vin'].errors }" />
                    <div *ngIf="submitted && form['vin'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['vin'].errors['required']">Enter VIN</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="licensePlate" class="form-label">License Plate</label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="licensePlate" class="form-control" required formControlName="licensePlate" [ngClass]="{ 'is-invalid': submitted && form['licensePlate'].errors }" />
                    <div *ngIf="submitted && form['licensePlate'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['licensePlate'].errors['required']">Enter License Plate</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label">
                    <label for="vin" class="form-label">Type</label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" [items]="vehicleTypesData" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="type" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                    <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['type'].errors['required']">Enter Type</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Add'" (click)="addVehicle()">Save</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Edit'" (click)="editVehicle()">Save</button>
            </div>
        </div>
    </form>
</ng-template>