import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Asset, AssetPayload, AssetScehdulePayload } from 'src/app/core/services/models/asset.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { PrettyTechName } from 'src/app/core/services/models/pretty-technical-name';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';
import { DUTY_STATUS_OPTIONS, SERVICE_TYPE_NAME_OPTIONS } from '../settings/service-types/service-types.component';
import { User } from 'src/app/core/services/models/user.model';
import { AssetDriver } from 'src/app/core/services/models/asset-driver.model';
import { DAYS, isArrayEmpty } from 'src/app/core/utils/commons';
import { concatMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InputZipCodeGroup } from 'src/app/shared/mapbox-zipcode-picker/mapbox-zipcode-picker.component';
import { getZipCodeBoundaryExists, isValidZipCode } from 'src/app/core/utils/zip-code-utils';
import { PostalCode } from 'src/app/core/services/models/postal-code.model';
import { NgbdModalBuyLicenses } from '../settings/licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, OnDestroy {
  readonly separatorExp = /,| |\n|\r\n/;
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Assets'
    }
  ];
  originalAsset?: Asset;
  originalAssetDrivers: AssetDriver[] = [];

  showData = false;

  assets: Asset[] = [];

  readonly DUTY_STATUS_OPTIONS = DUTY_STATUS_OPTIONS;

  assetForm!: FormGroup;

  activeModal?: NgbModalRef;
  zipActiveModal?: NgbModalRef;

  zipCodes: string[] = [];
  selectedZipCodeGroup?: InputZipCodeGroup;
  radiusMile: number = 50;
  radiusZipCode?: string;

  isLoading = false;

  truckOptions: PrettyTechName[] = [];
  trailerOptions: PrettyTechName[] = [];
  driverTypeOptions = [
    {name: 'Local'},
    {name: 'Regional'},
    {name: 'Over-The-Road'}
  ];
  primaryDriverOptions: PrettyTechName[] = [];
  backupDriverOptions: PrettyTechName[] = [];

  submitted = false;

  hourOptions: PrettyTechName[] = [];
  readonly SERVICE_TYPE_NAME_OPTIONS = {...SERVICE_TYPE_NAME_OPTIONS};

  suggestions: string[] = [];
  postalCodeMap = new Map<string, PostalCode[]>();
  searchSubject = new Subject<string>();

  scheduleInputs: (
    AssetScehdulePayload & {
      enabled: boolean,
      dayOfWeekDisplay: string;
      preferredLaneInputs: {origin: string, destination: string}[]
    }
  )[] = DAYS.map(
    (day, ndx) => (
      {
        dayOfWeek: ndx,
        dayOfWeekDisplay: day,
        startTime: '',
        endTime: '',
        preferredZones: [],
        preferredLanes: [],
        preferredLaneInputs: [{origin: '', destination: ''}],
        finalMileServiceTypes: [],
        truckloadServiceTypes: [],
        enabled: false
      }
    )
  );


  private _zipCodeGroups: InputZipCodeGroup[] = [];
  private subscriptions: Subscription[] = [];
  selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private authService: AuthService,
    private el: ElementRef,
    private clipboard: Clipboard,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnInit(): void {
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hourOptions = [...new Set(jsonData)] as PrettyTechName[];
    });
    this.loadAssets();
    this.getDrivers();
    this.initForm();
    this.getAllTruckOptions();
    this.getAllTrailerOptions();

    this.searchSubject.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(
      query => {

        this.httpService.queryPostalCodes(query).subscribe(
          res => {
            const successRes = <SuccessApiResponse<PostalCode[]>> res;
            this.suggestions = [
              ...new Set (
                successRes.data.map(postalCode => `${postalCode.city}, ${postalCode.state}`)
              )
            ];
            const newPostalCodeMap = successRes.data.reduce(
              (acc, cur) => {
                const key = `${cur.city}, ${cur.state}`;
                if(acc.has(key)){
                  acc.get(key)?.push(cur);
                }else{
                  acc.set(key, [cur]);
                }
                return acc;
              },
              new Map<string, PostalCode[]>()
            );

            newPostalCodeMap.forEach(
              (val, key) => this.postalCodeMap.set(key, val)
            )
          },
          error => {
            this.isLoading = false;
            Swal.fire({
              title: 'Error',
              text: 'Failed to query postal code: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getDrivers(){
    this.httpService.getUsers('driver').subscribe(
      res => {
        const successRes = <SuccessApiResponse<User[]>> res;
        this.primaryDriverOptions = successRes.data.map(
          user => ({
            techName: user.userId!,
            prettyName: `${user.firstName} ${user.lastName}` 
          })
        );
        this.backupDriverOptions = [...this.primaryDriverOptions];
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch drivers: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private initForm(){
    this.submitted = false;
    const orginalAssetIsDriverDutyStatusDay = this.originalAsset?.isDriverDutyStatusDay;
    this.backupDriverOptions = [...this.primaryDriverOptions];

    this.assetForm = this.formBuilder.group({
      truckId: [this.originalAsset?.truckId,[Validators.required]],
      trailerId: [this.originalAsset?.trailerId,[Validators.required]],
      driverType: [this.originalAsset?.driverType, [Validators.required]],
      primaryDriver: ['', [Validators.required]],
      backupDrivers: [[], []],
      isPublic: [this.originalAsset?.isPublic ? [true]: []],
      isDriverDutyStatusDay: [
        (orginalAssetIsDriverDutyStatusDay === undefined || orginalAssetIsDriverDutyStatusDay === null)  
          ? ''
          : String(orginalAssetIsDriverDutyStatusDay)
        , Validators.required
      ],
    });

    this.fetchOriginalAssetDrivers();
  }

  private fetchOriginalAssetDrivers(){
    if(!this.originalAsset){
      return;
    }

    this.httpService.listAssetDrivers(this.originalAsset.assetId)
      .subscribe(
        res => {
          const successRes = <SuccessApiResponse<AssetDriver[]>> res;
          const assetDrivers = successRes.data;
          this.originalAssetDrivers = assetDrivers;
          this.assetForm.patchValue(
            {
              primaryDriver: this.originalAssetDrivers
                .find(driver => driver.isDriverPrimary)?.driverId,
              backupDrivers: this.originalAssetDrivers
                .filter(driver => !driver.isDriverPrimary)
                .map(driver => driver.driverId)
            }
          );
          this.primaryDriverChanged(this.primaryDriverOptions.find(driver => driver.techName === this.assetForm.value.primaryDriver)!);
        },
        error => {
          this.isLoading = false;
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch asset drivers: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  primaryDriverChanged(newPrimaryDriver: PrettyTechName){
    if(!newPrimaryDriver){
      return;
    }

    this.backupDriverOptions = [...this.primaryDriverOptions];
    this.backupDriverOptions = this.backupDriverOptions.filter(
      driver => driver.techName !== newPrimaryDriver.techName
    );
    this.assetForm.patchValue(
      {
        'backupDrivers': [
          ...this.assetForm.value['backupDrivers']?.filter(
            (driverId: string) => driverId !== newPrimaryDriver.techName
          ) ?? []
        ]
      }
    );
  }

  loadAssets(){
    this.showData = false;
    this.httpService.listAssets().subscribe(
      res => {
        this.showData = true;
        const successRes = <SuccessApiResponse<Asset[]>> res;
        this.assets = successRes.data;
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch assets: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  get assetFormControls(): { [key: string]: AbstractControl } {
    return this.assetForm.controls;
  }

  getAllTruckOptions() {
    this.httpService.listTrucks().subscribe((data) => {
      this.truckOptions = data.map(
        truck => ({
          prettyName: truck.name!,
          techName: truck.truckId
        })
      );
    })
  }

  getAllTrailerOptions(){
    this.httpService.listTrailers().subscribe((data) => {
      this.trailerOptions = data.map(
        trailer => ({
          prettyName: trailer.name!,
          techName: trailer.trailerId
        })
      );
    })
  }
  
  openZipModal(content: any){
    this.zipActiveModal = this.modalService.open(
      content,
      {
        size: 'lg',
        centered: true,
        backdropClass: 'modal-on-modal-backdrop',
        modalDialogClass: 'almost-full-page-modal-container'
      }
    );
  }

  openSaveModal(content: any, asset?: Asset){
    this.originalAsset = asset;
    if(!this.originalAsset){
      this.originalAssetDrivers = [];
    }
    this.initForm();
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      modalDialogClass: 'full-page-modal-container'
    });
  }

  get selectedZipCodes(): string[] | undefined {
    return this.selectedZipCodeGroup?.zipCodes?.sort((z1, z2) => +z1 - +z2);
  }

  set selectedZipCodes(zipCodes: string[] | undefined) {
    if(!this.selectedZipCodeGroup){
      return;
    }

    const currentSelectedZipCodes = [...this.selectedZipCodeGroup?.zipCodes ?? []];
    zipCodes = zipCodes ?? [];

    this.selectedZipCodeGroup = {
      ...this.selectedZipCodeGroup,
      zipCodes
    };

    const allEqual = zipCodes.length ===  currentSelectedZipCodes.length && 
      zipCodes.every(zipCode => currentSelectedZipCodes.includes(zipCode));

    if(allEqual){
      return;
    }

    this.zipCodeGroups = [
      ...this.zipCodeGroups.map(
        group => {
          if(group.id === this.selectedZipCodeGroup?.id){
            return this.selectedZipCodeGroup;
          }
          return group;
        }
      )
    ];
  }

  get zipCodeGroups(): InputZipCodeGroup[] {
    return this._zipCodeGroups;
  }

  get focusedGroupId(): string | undefined {
    this.selectedZipCodeGroup = this.selectedZipCodeGroup ?? this.zipCodeGroups[0];
    return this.selectedZipCodeGroup?.id;
  }

  set zipCodeGroups(zipCodeGroups: InputZipCodeGroup[]) {
    this._zipCodeGroups = zipCodeGroups;
    this.selectedZipCodeGroup = zipCodeGroups
      .find(group => group.id === this.focusedGroupId) ?? {...this.selectedZipCodeGroup} as InputZipCodeGroup;
    this.selectedZipCodes = [...this.selectedZipCodeGroup?.zipCodes ?? []];
  }

  onAddZipCodes(){
    this.selectedZipCodes = [
      ...new Set(this.selectedZipCodes?.filter(zipCode => isValidZipCode(zipCode)) ?? this.selectedZipCodes)
    ];
  }

  copyZipCodes(popup:NgbPopover) {
    setTimeout(()=> popup.close(),1000);
    this.clipboard.copy(this.selectedZipCodes?.join(',') ?? '');
  }

  onClickSelectZipsRadius(): void {
    if (!this.radiusZipCode) {
      return;
    }
    this.httpService.queryPostalCodes(this.radiusZipCode, this.radiusMile + 'mi')
      .subscribe(response => {
        const successRes = <SuccessApiResponse<PostalCode[]>> response;
        let responseZips = successRes.data.map(r=>r.postalCode);

        if (!responseZips) {
          return;
        }
        responseZips = responseZips.filter(zip => getZipCodeBoundaryExists(zip));
        const joinZipCodes = new Set<string>([...this.selectedZipCodes || [], ...responseZips]);  // unique
        this.selectedZipCodes = [...joinZipCodes];
        this.fitBounds();
      });
  }

  fitBounds(): void {
    //TODO
  }

  delete(){
    this.isLoading = true;
    this.httpService.deleteAsset(this.originalAsset?.assetId!).subscribe(
      res => {
        this.isLoading = false;
        this.activeModal?.close();
        this.loadAssets();
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted asset.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
        });
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete asset: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  submitAsset(){
    this.submitted = true;

    if(this.assetForm.invalid){
      return;
    }

    const {isPublic, isDriverDutyStatusDay, primaryDriver, backupDrivers} = this.assetForm.value;


    console.log(
      '[this.scheduleInputs]',
      JSON.stringify(this.scheduleInputs, null, 4)
    );
    

    const payload: AssetPayload = {
      ...this.assetForm.value,
      isPublic: (<boolean[]>isPublic).some(e => e === true),
      isDriverDutyStatusDay: isDriverDutyStatusDay === 'true',
      schedule: this.scheduleInputs.filter(
        input => input.enabled
      ).map(
        input => (
          {
            ...input,
            preferredLanes: input.preferredLaneInputs.map(
              preferredLaneInput => (
                {
                  origins: this.postalCodeMap.get(preferredLaneInput.origin)?.map(
                    postalCode => postalCode.postalCode
                  ),
                  destinations: this.postalCodeMap.get(preferredLaneInput.destination)?.map(
                    postalCode => postalCode.postalCode
                  )
                }
              )
            )
          }
        )
      )
    };

    let httpCall;

    if(this.originalAsset) {
      httpCall = this.httpService.updateAsset(
        this.originalAsset.assetId,
        payload
      );
    }else{
      httpCall = this.httpService.addAsset(payload);
    }

    this.isLoading = true;
    httpCall.subscribe(
      res => {
        const successRes = <SuccessApiResponse<Asset>> res;
        const asset = successRes.data;

        const deleteDriversCalls = isArrayEmpty(this.originalAssetDrivers) 
          ? [of(null)] 
          : this.originalAssetDrivers.map(
            assetDriver => this.httpService.deleteAssetDriver(assetDriver.assetDriverId)
          );

        const httpCalls = [
          this.httpService.addAssetDriver(
            asset.assetId,
            {driverId: primaryDriver, isDriverPrimary: true} 
          ),
          ...backupDrivers?.map(
            (driverId: string) => this.httpService.addAssetDriver(
              asset.assetId,
              {driverId, isDriverPrimary: false} 
            ) ?? []
          )
        ];

        forkJoin(deleteDriversCalls)
        .pipe(concatMap(r=>forkJoin(httpCalls)))
        .subscribe(
          res => {
            this.doAfterSaveAsset();
          },
          error => {
            this.isLoading = false;
            Swal.fire({
              title: 'Error',
              text: 'Failed to save asset: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      },
      error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to save asset: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private doAfterSaveAsset(){
    this.isLoading = false;
    this.activeModal?.close();
    this.loadAssets();
    Swal.fire({
      title: 'Success',
      text: 'Successfully saved asset.',
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Ok',
    }).then((result) => {
    });
  }

  focusOnZipChipsInput(): void {
    const inputElement = <HTMLElement>this.el.nativeElement
    .querySelector('#zip-chips .p-chips-input-token input');
    inputElement.click();
  }

  search(event: any){
    this.searchSubject.next(event.query)
  }

  isPublicClick(){
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(
          NgbdModalBuyLicenses, 
          { size: 'lg', backdropClass: 'modal-on-modal-backdrop', centered: true }
        );
      }
    });
  }
}
