import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],

})
export class InvoiceDetailsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Settings'},
    {label: 'Invoice'}
  ];
  invoiceDetails: any

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    
  ) {
    if (this.router.getCurrentNavigation() != null) {
      this.invoiceDetails = this.router.getCurrentNavigation()?.extras.state;
      if(!this.invoiceDetails) {
        this.router.navigate(['/pages/settings']);
      }
    }
  }

  ngOnInit(): void {
  }

}
