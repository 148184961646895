import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { SignUpService, VerifyTokenResponse } from '../services/sign-up.service';
import { StripeCheckoutOverlayComponent } from '../stripe-checkout-overlay/stripe-checkout-overlay.component';

@Component({
  selector: 'app-verify-sign-up',
  templateUrl: './verify-sign-up.component.html',
  styleUrls: ['./verify-sign-up.component.scss']
})
export class VerifySignUpComponent implements OnInit, AfterViewInit {
  isLoading = false;
  submitted = false;
  isError = false;
  verifyResponse?: VerifyTokenResponse;
  VerifySignupForm!: FormGroup;
  stepIds = {
    SCHEDULE_CALL: 'schedule-call'
  }

  @ViewChild('stripeCheckoutOverlay') stripeCheckoutOverlayComponent!: StripeCheckoutOverlayComponent;
  @ViewChild('wizard') wizard!: WizardComponent;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private signupService: SignUpService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    /**
     * Form Validation
     */
    this.VerifySignupForm = this.formBuilder.group({
      verificationToken: [this.route.snapshot.params['token'] || '' , [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.verifyToken();
    });
    
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.VerifySignupForm.controls;
  }

  /**
   * Form submit
   */
   onSubmit() {
    this.submitted = true;

    if (this.VerifySignupForm.invalid) {
      // stop here if form is invalid
      return;
    }
  }

  canExitVerifyTokenStep(): boolean {
    return !!this.verifyResponse;
  }

  verifyToken(): void {
    this.isLoading = true;
    this.signupService.verifyToken(this.f['verificationToken'].value).subscribe(response => {
      this.isLoading = false;
      if (response.error) {
        this.verifyResponse = undefined;
        this.isError = true;
      } else {
        this.isError = false;
        this.verifyResponse = response.data;
      }
      
      this.cdr.detectChanges();
      this.goToNextUrl();
    }, err => {
      this.isLoading = false;
      this.verifyResponse = undefined;
      this.isError = true;
      console.error('Error verifying token', err);
    })
  }

  goToNextUrl(): void {
    if (!this.verifyResponse) {
      return;
    }

    if (this.verifyResponse.isFleet) {
      this.router.navigate(['fleet']);
    } else {
      this.router.navigate(['owner-operator']);
    } 
  }

  openStripeCheckoutOverlay(): void {
    this.stripeCheckoutOverlayComponent.openModal();
  }
}
