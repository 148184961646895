<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="name" class="form-label">Name: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input formControlName="name" type="text" id="name" [ngClass]="{ 'is-invalid': submitted && form.controls['name'].errors}" class="form-control" name="name" />
                                    <div *ngIf="submitted && form.controls['name'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form.controls['name'].errors['required']">Enter name</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="trailerTypeId" class="form-label">Truck Or Trailer: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <ng-select class="custom form-control" formControlName="trailerTypeId" bindLabel="prettyName" bindValue="techName" [ngClass]="{ 'is-invalid': submitted && form.controls['trailerTypeId'].errors }" [items]="trailerTypeOptions" [clearable]="false"></ng-select>
                                    <div *ngIf="submitted && form.controls['trailerTypeId'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form.controls['trailerTypeId'].errors['required']">Select Trailer</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="commodities" class="form-label">Commodity: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <ng-select class="custom form-control" formControlName="commodities" 
                                        [multiple]="true"
                                        bindValue="id"
                                        bindLabel="name"
                                        [ngClass]="{ 'is-invalid': submitted && form.controls['commodities'].errors }" 
                                        [items]="COMMODITY_OPTIONS" 
                                        [clearable]="false">
                                    </ng-select>
                                    <div *ngIf="submitted && form.controls['commodities'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form.controls['commodities'].errors['required']">Select Commodity</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="weight" class="form-label">Max Weight: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" mask="separator.0" thousandSeparator="," id="weight" formControlName="weight" [ngClass]="{ 'is-invalid': submitted && form.controls['weight'].errors}" class="form-control d-inline-block w-90" name="weight" /> &nbsp; lbs
                                </div>
                                <div *ngIf="submitted && form.controls['weight'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form.controls['weight'].errors['required']">Enter weight</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="pallets" class="form-label">Max Pallets: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" mask="separator.0" thousandSeparator="," [ngClass]="{ 'is-invalid': submitted && form.controls['pallets'].errors}" formControlName="pallets" id="pallets" class="form-control" name="pallets" />
                                    <div *ngIf="submitted && form.controls['pallets'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form.controls['pallets'].errors['required']">Enter pallets</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-3 label-form text-align-right">
                                    <label for="softwareVersion" class="form-label">Software: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9">
                                    <ng-select class="custom form-control" [items]="SOFTWARE_OPTIONS" [ngClass]="{ 'is-invalid': submitted && form.controls['softwareVersion'].errors}" bindLabel="prettyName" bindValue="techName" [clearable]="false" formControlName="softwareVersion" (change)="computeTrimbleMileage()"></ng-select>
                                    <div *ngIf="submitted && form.controls['softwareVersion'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form.controls['softwareVersion'].errors['required']">Select software</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-trimble-map #trimbleMap class="col-md-6" [mapOptions]="trimbleMapOptions" [sources]="geoJsonDataSources" [layers]="mapLayers" [listeners]="mapListeners" [routeStops]="routeStops"></app-trimble-map>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="software" class="form-label">Lane: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-10">
                                    <table class="table align-middle table-nowrap mb-0">
                                        <tbody>
                                            <tr *ngFor="let stop of stops; let i=index">
                                                <td>
                                                    {{i + 1}}) {{i==0 ? 'Origin': 'Consignee'}}
                                                </td>
                                                <td class="w-80">
                                                    <app-google-maps-search-box #googleMapsInputs [inputId]="'stopsMap'+i" [showMap]="false" [isSubmittedOnce]="submitted" [isRequired]="true" [isRequiredPostalCode]="true" [defaultPlace]="stop.defaultPlace" (onPlaceChanged)="onStopPlaceChanged(i, $event)"></app-google-maps-search-box>
                                                </td>
                                                <td class="w-10">
                                                    <button [style.visibility]="i > 1 || (i > 0 && stops.length > 2)? 'visible': 'hidden'" class="btn btn-sm btn-danger" (click)="deleteStop(i)"> Delete </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex">
                                        <div>
                                            <button class="mt-3 btn btn-primary" (click)="stops.push({})">
                                                <i class="ri-add-line align-bottom me-1"></i> Add Stop </button>
                                        </div>
                                        <div class="pt-2 w-75">
                                            <div class="row mb-0 pb-0">
                                                <div class="col-3 col-lg-4 label-form text-align-right lh-base">
                                                    <label for="software">Estimated Distance:</label>
                                                </div>
                                                <div class="col-9 col-lg-8">
                                                    {{ totalMiles== undefined? '': (totalMiles | number:'1.2-2') + ' miles'}}
                                                </div>
                                            </div>
                                            <div class="row mb-0 pb-0">
                                                <div class="col-3 col-lg-4 label-form text-align-right lh-base">
                                                    <label for="software">Estimated Time:</label>
                                                </div>
                                                <div class="col-9 col-lg-8">
                                                    {{ totalHours== undefined? '': (totalHours | number: '1.0-0') + ' hrs'}} &nbsp; {{ totalMinutes== undefined? '': (totalMinutes | number:'1.0-0') + ' mins'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-10 row">
                                    <div class="col-4"></div>
                                    <div class="col-3 label-form fw-bold"> Rate </div>
                                    <div class="col-3 label-form fw-bold"> Rate with Fuel </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="software" class="form-label">Highest Rate: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-10 row">
                                    <div class="col-4"> $ <input formControlName="highestRate" type="text" mask="separator.2" thousandSeparator="," (keydown)="handleRateKeyDown('highestRate', $event)" (change)="handleRateChange('highestRate', $event)" [ngClass]="{ 'is-invalid': submitted && form.controls['highestRate'].errors }" class="form-control w-5em d-inline-block" /> Per Mile <div *ngIf="submitted && form.controls['highestRate'].errors" class="invalid-feedback" align="left">
                                            <div *ngIf="form.controls['highestRate'].errors['required']">Enter Highest Rate</div>
                                        </div>
                                        <div *ngIf="form.controls['highestRate'].value && form.controls['suggestedRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['highestRate'].value) < toNumber(form.controls['suggestedRate'].value)">Should be greater than or equal Suggested Rate </div>
                                        </div>
                                        <div *ngIf="form.controls['highestRate'].value && form.controls['lowestRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['highestRate'].value) < toNumber(form.controls['lowestRate'].value)">Should be greater than or equal Lowest Rate </div>
                                        </div>
                                    </div>
                                    <div class="col-3 label-form"> ${{ form.controls['highestRate'].value==undefined || totalMiles==undefined 
                                            ? ''
                                            : toNumber(form.controls['highestRate'].value) * totalMiles | number:'1.2-2'
                                        }}
                                    </div>
                                    <div class="col-3 label-form"> ${{ form.controls['highestRate'].value==undefined || totalMiles==undefined || form.controls['fuelSurchargeRate'].value==undefined? 
                                            '':
                                            (toNumber(form.controls['highestRate'].value) + toNumber(form.controls['fuelSurchargeRate'].value)) * totalMiles | number:'1.2-2'
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="software" class="form-label">Suggested Rate: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-10 row">
                                    <div class="col-4"> $ <input formControlName="suggestedRate" type="text" mask="separator.2" thousandSeparator="," (keydown)="handleRateKeyDown('suggestedRate', $event)" (change)="handleRateChange('suggestedRate', $event)" [ngClass]="{ 'is-invalid': submitted && form.controls['suggestedRate'].errors }" class="form-control w-5em d-inline-block" /> Per Mile <div *ngIf="submitted && form.controls['suggestedRate'].errors" class="invalid-feedback" align="left">
                                            <div *ngIf="form.controls['suggestedRate'].errors['required']">Enter Suggested Rate</div>
                                        </div>
                                        <div *ngIf="form.controls['highestRate'].value && form.controls['suggestedRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['highestRate'].value) < toNumber(form.controls['suggestedRate'].value)">Should be less than or equal Highest Rate </div>
                                        </div>
                                        <div *ngIf="form.controls['lowestRate'].value && form.controls['suggestedRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['lowestRate'].value) > toNumber(form.controls['suggestedRate'].value)">Should be greater than or equal Lowest Rate</div>
                                        </div>
                                    </div>
                                    <div class="col-3 label-form"> ${{form.controls['suggestedRate'].value==undefined || totalMiles==undefined ? '': toNumber(form.controls['suggestedRate'].value) * totalMiles | number:'1.2-2'}}
                                    </div>
                                    <div class="col-3 label-form"> ${{ form.controls['suggestedRate'].value==undefined || totalMiles==undefined || form.controls['fuelSurchargeRate'].value==undefined? 
                                            '':
                                            (toNumber(form.controls['suggestedRate'].value) + toNumber(form.controls['fuelSurchargeRate'].value)) * totalMiles | number:'1.2-2'
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="software" class="form-label">Lowest Rate: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-10 row">
                                    <div class="col-4"> $ <input formControlName="lowestRate" type="text" mask="separator.2" thousandSeparator="," (keydown)="handleRateKeyDown('lowestRate', $event)" (change)="handleRateChange('lowestRate', $event)" [ngClass]="{ 'is-invalid': submitted && form.controls['lowestRate'].errors }" class="form-control w-5em d-inline-block" /> Per Mile <div *ngIf="submitted && form.controls['lowestRate'].errors" class="invalid-feedback" align="left">
                                            <div *ngIf="form.controls['lowestRate'].errors['required']">Enter Lowest Rate</div>
                                        </div>
                                        <div *ngIf="form.controls['highestRate'].value && form.controls['lowestRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['highestRate'].value) < toNumber(form.controls['lowestRate'].value)">Should be less than or equal Highest Rate </div>
                                        </div>
                                        <div *ngIf="form.controls['lowestRate'].value && form.controls['suggestedRate'].value" class="font-size-11 text-danger" align="left">
                                            <div *ngIf="toNumber(form.controls['lowestRate'].value) > toNumber(form.controls['suggestedRate'].value)">Should be less than or equal Suggested Rate</div>
                                        </div>
                                    </div>
                                    <div class="col-3 label-form"> ${{form.controls['lowestRate'].value ==undefined || totalMiles==undefined ? '': toNumber(form.controls['lowestRate'].value) * toNumber(totalMiles) | number:'1.2-2'}}
                                    </div>
                                    <div class="col-3 label-form"> ${{ form.controls['lowestRate'].value==undefined || totalMiles==undefined || form.controls['fuelSurchargeRate'].value==undefined? 
                                            '':
                                            (toNumber(form.controls['lowestRate'].value) + toNumber(form.controls['fuelSurchargeRate'].value)) * totalMiles | number:'1.2-2'
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="software" class="form-label">Fuel Surcharge: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-10 row">
                                    <div class="col-4"> $ <input formControlName="fuelSurchargeRate" type="text" mask="separator.2" thousandSeparator="," (keydown)="handleRateKeyDown('fuelSurchargeRate', $event)" (change)="handleRateChange('fuelSurchargeRate', $event)" [ngClass]="{ 'is-invalid': submitted && form.controls['fuelSurchargeRate'].errors }" class="form-control w-5em d-inline-block" /> Per Mile <div *ngIf="submitted && form.controls['fuelSurchargeRate'].errors" class="invalid-feedback" align="left">
                                            <div *ngIf="form.controls['fuelSurchargeRate'].errors['required']">Enter Fuel Surcharge Rate</div>
                                        </div>
                                    </div>
                                    <div class="col-3 label-form"> ${{form.controls['fuelSurchargeRate'].value==undefined || totalMiles==undefined ? '': toNumber(form.controls['fuelSurchargeRate'].value) * totalMiles | number:'1.2-2'}}
                                    </div>
                                    <div class="col-3 label-form"></div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 label-form text-align-right">
                                    <label for="branches" class="form-label">Branches: <span class="text-danger">*</span>
                                    </label>
                                </div>
                                <div class="col-lg-9 mt-n2">
                                    <app-tree-checkbox [options]="branches" [(selected)]="selectedBranches"></app-tree-checkbox>
                                    <div class="text-danger" *ngIf="submitted && selectedBranches.length === 0">
                                        <small>Pick at least 1 branch</small>
                                    </div>
                                    <div class="d-flex mt-3">
                                        <div class="branches-container text-muted">
                                            <small>* Hold Shift Key while clicking checkbox to prevent propagation.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !originalLane}">
                    <span *ngIf="originalLane" class="clickable-column me-auto d-flex align-items-center text-danger">
                        <div *ngIf="!loading" (click)="deleteLane()" class="inline-block"> Delete </div>
                        <div *ngIf="loading" class="spinner-border inline-block save-loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="router.navigate(['/pages/lanes'])"> Cancel </button>
                    <button type="submit" class="btn btn-primary action-btn" id="add-btn" (click)="!loading && saveLane()">
                        <div *ngIf="!loading" class="inline-block"> Save </div>
                        <div *ngIf="loading" class="spinner-border inline-block save-loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>