import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';

// Sweet Alert
import Swal from 'sweetalert2';

import { User, userModel } from 'src/app/core/services/models/user.model';
import { HttpService } from 'src/app/core/services/http-service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';

import { UserService } from 'src/app/core/services/user-service/user.service';
import { MenuItem, TreeNode } from 'primeng/api';
import { Mode } from 'src/app/core/services/models/mode.model';
import { Role } from 'src/app/core/services/models/role.model';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { Permission as PermissionModel } from 'src/app/core/services/models/permission.model';
import { Permissions, Permission, PermissionsCheckboxes, PermissionsJson, PERMISSION_GETTERS } from 'src/app/core/services/models/permissions.model';
import { FEATURES } from 'src/app/core/services/models/permissions.model';
import { truthyFilter } from 'src/app/core/utils/commons';
import { BranchesService, TreeNodeBranch } from '../../origin-points/origin-point/branches.service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';
import { AccountType } from 'src/app/core/services/models/account.model';

type PermissionsTabModalRole = (Role & {
  selected: boolean;
  permissions: Permissions;
});

class PermissionsTabModalObject {
  private _currentRoles: PermissionsTabModalRole[];
  private _currentPermissions: PermissionsCheckboxes;
  private _previewPermissions: PermissionsCheckboxes;
  private _focusedRole: PermissionsTabModalRole | undefined;

  constructor(roles: (Role & {selected: boolean})[] = [], permissionSet: Partial<PermissionsJson> = {}) {
    this.setCurrentRoles(roles);
    this.setCurrentPermissions(permissionSet);
  }

  setPreviewPermissions(): void {
    const permissionsSets = [
      ...this._currentRoles.filter(r => r.selected).map(r => r.permissionSet),
      this._currentPermissions?.permissionSet
    ].filter(truthyFilter);
    this._previewPermissions = new PermissionsCheckboxes(permissionsSets);
  }

  get focusedRole(): PermissionsTabModalRole | undefined {
    return this._focusedRole;
  }

  get previewPermissions(): PermissionsCheckboxes {
    return this._previewPermissions;
  }

  get currentPermissions(): PermissionsCheckboxes {
    return this._currentPermissions;
  }

  get currentRoles(): PermissionsTabModalRole[] {
    return this._currentRoles;
  }

  setCurrentRoles(roles: (Role & {selected: boolean})[]): void {
    this._currentRoles = roles.map(r => ({
      ...r,
      permissions: new Permissions(r.permissionSet)
    }));
    this.setPreviewPermissions();
  }

  setCurrentPermissions(permissionSet: Partial<PermissionsJson>): void {
    const permissions = new Permissions(permissionSet);
    this._currentPermissions = new PermissionsCheckboxes([permissionSet]);
    this.setPreviewPermissions();
  }

  onCurrentPermissionsChange(): void {
    this.setPreviewPermissions();
  }

  onCurrentRoleChange(): void {
    this.setPreviewPermissions();
  }

  onCurrentRoleMouseEnter(role: PermissionsTabModalRole): void {
    this._focusedRole = role;
  }

  onCurrentRoleMouseLeave(): void {
    this._focusedRole = undefined;
  }
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UserService, DecimalPipe],
})
export class UserComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  @Input()
  type: 'regular' | 'driver';

  saveLoading = false;
  features = FEATURES;
  permissionGetters = PERMISSION_GETTERS;
  // roleModalObj!: RoleModalObject;
  permissionsTabModalObj: PermissionsTabModalObject;
  branches: TreeNode<TreeNodeBranch>[] = [];
  selectedBranches: TreeNode<TreeNodeBranch>[] = [];
  cols = [
    { field: 'name' }
  ];
  roles: Role[] = [];
  selectedModes: string[] = [];
  activeTab: number = 0;
  highlightEven = false;
  highlightOdd = false;
  selectedPreviewFeatures: string[] = [];
  selectedCustomFeatures: string[] = [];
  customPreview = '';
  items: MenuItem[] = [
    { label: 'Email', disabled: false},
    { label: 'Info', disabled: true},
    { label: 'Modes', disabled: true },
    { label: 'Branches', disabled: true },
    { label: 'Permissions', disabled: true },
  ];
  activeItemIndex = 0;

  modes!: Mode[];
  products: userModel[] = [];

  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'USER MANAGEMENT'}
  ];

  submitted = false;
  userForm!: FormGroup;
  emailForm !: FormGroup;
  emailExists?:boolean = undefined;
  exitingUser?: UserProfile;
  ListJsData!: userModel[];
  checkedList: any;
  masterSelected!: boolean;
  error = '';
  modalType?: 'Add' | 'Edit';
  userIdEdit: any;
  userToEdit: any;

  activeModal?: NgbModalRef;

  // Table data
  ListJsList$!: Observable<userModel[]>;
  total$: Observable<number>;

  showData = false;

  readonly carrierOwnerRoles: {
    name: string;
    code: string;
  }[] = [
    { name: 'Owner', code: 'carrier-owner' },
    { name: 'Driver', code: 'carrier-driver' },
    { name: 'Helper', code: 'carrier-helper' },
    { name: 'Dispatcher', code: 'carrier-dispatcher' },
    { name: 'Office Manager', code: 'carrier-office-manager' },
  ];

  readonly shipperOwnerRoles: {
    name: string;
    code: string;
  }[] = [
    { name: 'Operations Manager', code: 'shipper-operations-manager' },
    { name: 'Owner', code: 'shipper-owner' },
    { name: 'Logistics Coordinator', code: 'shipper-logistics-coordinator' },
  ];
  saveRole = false;
  saveRoleHide = true;
  roleNumbers: number[] = [];
  selectedAccountSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    public service: UserService,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    public auth: AuthService,
    public permissionsService: PermissionsService,
  ) {
    super();
    this.selectedAccountSubscription = this.auth.subscribeToSelectedAccount(
      async (account) => {
        await this.permissionsService.checkUrlPermission('_users');
        return this.loadData();
      },
      'Users'
    );
    this.ListJsList$ = service.user$;
    this.total$ = service.total$;
  }

  async loadData(): Promise<void> {
    this.httpRequest.getModes().subscribe((res) => (this.modes = res.data));
    this.httpRequest.getRoles().subscribe((res) => {
      this.roles = res.data;
    });
    this.httpRequest.getBranchesByHierarchy()
      .subscribe(res => this.branches = BranchesService.getRecursiveTreeNodeBranch(res.data));
    setTimeout(() => {
      this.showData = true;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.selectedAccountSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.service.updateTable(this.type);
    this.initForms();
  }

  initForms() {
    this.error = '';
    this.activeItemIndex = 0;
    this.emailExists = undefined;
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      title: [''],
      role: [''],
      isVerified: []
    });
    this.selectedModes = [];
    this.selectedBranches = [];
    this.roleNumbers = [];
    this.activeTab = 0;
    this.selectedCustomFeatures = [];
    this.selectedPreviewFeatures = [];
    this.branches.forEach((branch) => this.expandChildren(branch));
    this.permissionsTabModalObj = new PermissionsTabModalObject();
  }

  private expandChildren(node:TreeNode){
    if(node.children){
      node.expanded=true;
      for(let cn of node.children){
        this.expandChildren(cn);
      }
    }
  }

  openModal(content: any, type: string, data: any) {
    this.initForms();
    if (type == 'add') {
      this.modalType = 'Add';
      this.activeItemIndex = 0;
      this.permissionsTabModalObj.setCurrentRoles(this.roles.map(r => ({
        ...r,
        selected: false
      })));
    } else {
      this.modalType = 'Edit';
      this.activeItemIndex = 1;
      this.userIdEdit = data.userId;
      this.userToEdit = data;
      const {
        firstName, lastName, phone, title, role, isVerified
      } = data;
      this.userForm.patchValue({
        firstName, lastName, phone, title, role, isVerified,
        password: 'Test@123!'
      });
      this.getUserPermission(data, 1);
    }
    this.adjustStepItems();
    this.submitted = false;
    this.activeModal = this.modalService.open(content, {
      size: 'xl',
      centered: true,
    });
    this.activeModal.result.finally(() => {
      this.initForms();
    });
  }

  saveModal(){
    if(this.modalType == 'Add'){
      if(this.emailExists){
        this.inviteUser();
      }else{
        this.addUser();
      }
    }else if(this.modalType == 'Edit'){
      this.savePermission(this.exitingUser!.userId, 'User has been updated.')
    }
  }

  inviteUser() {
    this.saveLoading = true;
    this.httpRequest
      .inviteUser({ userId: this.exitingUser!.userId, type: this.type })
      .pipe(first(), finalize(()=>this.saveLoading=false))
      .subscribe(
        (data) => {
          const successMsg = 'Invitation has been sent.';
          this.savePermission(data.data.userId, successMsg);
          this.service.updateTable(this.type);
        },
        (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to send invitation: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  addUser() {
    let dataToSend: User = {
      email: this.emailForm.controls['email'].value,
      firstName: this.userForm.controls['firstName'].value,
      lastName: this.userForm.controls['lastName'].value,
      phone: this.userForm.controls['phone'].value,
      title: this.userForm.controls['title'].value
    };
    if(this.type === 'driver'){
      (dataToSend as any).type = 'driver';
    }
    this.saveLoading = true;
    this.httpRequest
      .addUser(dataToSend)
      .pipe(first(), finalize(()=>this.saveLoading=false))
      .subscribe(
        (data) => {
          const successMsg = 'User has been saved.';
          this.savePermission(data.data.userId!, successMsg);
          this.service.updateTable(this.type);
        },
        (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to save user: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  savePermission(userId: string, successMsg: string) {
    this.saveLoading = true;
    this.httpRequest
      .addPermission(
        {
          modes: this.selectedModes,
          branches: this.selectedBranches.map(branch => branch.data!.branchId),
          roles: this.permissionsTabModalObj.currentRoles
            .filter(role => role.selected)
            .map(role => role.roleId!),
          permissionSet: this.permissionsTabModalObj.currentPermissions.permissionSet,
        },
        userId
      )
      .pipe(finalize(()=>this.saveLoading=false))
      .subscribe(
        (data) => {
          if(this.modalType == 'Add' && !this.emailExists){
            const email = this.emailForm.controls['email'].value;
            this.httpRequest.verifyEmailForgotPassword(email).subscribe(
              res => {
                this.finishUserSave(successMsg);    
              },
              err => {
                Swal.fire({
                  title: 'Error',
                  text: 'Failed to verify email and forget password: ' + err.error.reason,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: 'rgb(60,76,128)',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  //do nothing
                });
              }
            );
          }else{
            this.finishUserSave(successMsg);
          }
        },
        (err) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to save permission: ' + err.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  private finishUserSave(successMsg: string){
    this.activeModal?.close();
    this.initForms();
    Swal.fire({
      title: 'Success',
      text: successMsg,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Ok',
    }).then((result) => {
      //do nothing
    });
  }

  get form() {
    return this.userForm.controls;
  }

  get formEmail() {
    return this.emailForm.controls;
  }

  get usersPermissions(): Permission {
    return this.permissionsService.permissions.Users;
  }

  delete(){
    this.saveLoading = true;
    this.httpRequest.deleteUser(this.exitingUser!.userId)
    .pipe(finalize(()=>this.saveLoading=false))
    .subscribe(
      res => {
        this.activeModal?.close();
        this.initForms();
        this.service.updateTable(this.type);
        Swal.fire({
          title: 'Success',
          text: 'User has been deleted.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        })
      },
      err => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete user.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        })
      }
    )
  }

  confirm(user: any) {
    Swal.fire({
      title: 'Are you Sure ?',
      text: 'Are you Sure You want to Remove this Record ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.value) {
        this.httpRequest
          .delete('users', user.userId)
          .pipe(first())
          .subscribe(
            (data) => {
              Swal.fire('Deleted!', 'ListJs has been deleted.', 'success');
              this.service.updateTable(this.type);
            },
            (error) => {}
          );
      }
    });
  }

  sendEmail(user: any) {
    this.httpRequest.post('resendVerification/' + user.userId, '').subscribe(
      (data) => {
        Swal.fire(
          'Email Sent',
          'Email verification link has been sent.',
          'success'
        );
      },
      (error) => {
        this.error = error.error.reason;
      }
    );
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  isRowSelected(rowNode: any): boolean {
    return this.selectedBranches.indexOf(rowNode.node) >= 0;
  }

  toggleRowSelection(rowNode: any, event: any): void {
    if (this.isRowSelected(rowNode)) {
      this.removeSelectedBranch(rowNode.node);
      if(!event.shiftKey){
        this.propagateRemoveDown(rowNode.node);
        this.propagateRemoveUp(rowNode.node.parent);
      }
    } else {
      this.selectedBranches.push(rowNode.node);
      if(!event.shiftKey){
        this.propagatePushDown(rowNode.node);
        this.propagatePushUp(rowNode.node.parent);
      }
    }

    this.selectedBranches = [...this.selectedBranches];
  }

  private removeSelectedBranch(node:TreeNode): void {
    this.selectedBranches = this.selectedBranches.filter(
      branch => branch != node
    );
  }

  private propagateRemoveDown(node: TreeNode): void {
    this.removeSelectedBranch(node);

    if(!node.children || node.children.length == 0){
      return;
    }

    node.children.forEach(
      child => this.propagateRemoveDown(child)
    );
  }

  private propagatePushDown(node: TreeNode): void {
    if(!this.selectedBranches.includes(node)){
      this.selectedBranches.push(node);
    }

    if(!node.children || node.children.length == 0){
      return;
    }

    node.children.forEach(
      child => this.propagatePushDown(child)
    );
  }

  private propagateRemoveUp(parent: TreeNode | undefined): void {
    if(!parent){
      return;
    }

    if(!this.selectedBranches.includes(parent)){
      return;
    }

    this.removeSelectedBranch(parent);
    this.propagateRemoveUp(parent.parent);
  }

  private propagatePushUp(parent: TreeNode | undefined): void {
    if(!parent || !parent.children || parent.children.length == 0){
      return;
    }

    const totalChildren = parent.children.length;
    const selectedChildren = parent.children.filter(
      child => this.selectedBranches.indexOf(child) != -1
    ).length;

    if(totalChildren == selectedChildren && !this.selectedBranches.includes(parent)){
      this.selectedBranches.push(parent);
    }

    this.propagatePushUp(parent.parent);
  }

  handleNext() {
    if(this.activeItemIndex == 0){
      this.checkEmailInAccount();
    } else {
      this.activeItemIndex++;
      this.adjustStepItems();
    }
  }

  activeIndexChange(){
    this.adjustStepItems();
  }

  adjustStepItems(){
    for(let i=0; i < this.items.length; i++){
      this.items[i].disabled = i <= this.activeItemIndex ? false: true;
    }

    if(this.modalType == 'Edit'){
      this.items[0].disabled = true;
    }

    this.items = [...this.items];
  }

  private checkEmailInAccount() {
    const email = this.emailForm.controls['email'].value;
    this.saveLoading = true;
    this.httpRequest
      .getUserInAccountByEmail(email)
      .pipe(finalize(()=>this.saveLoading = false))
      .subscribe(
        (res: any) => {
          if (res.error || !res.data || res.data.length == 0) {
            this.checkUserByIdOrEmail(email);
            return;
          }
          this.handleErrorUserIsAlreadyPartOfAccount();
        },
        (err:any) => {
          this.emailExists = false;
          this.activeItemIndex = 1;
          this.adjustStepItems();
        }
      );
  }

  private handleErrorUserIsAlreadyPartOfAccount(err?: any){
    this.emailExists = undefined;
    Swal.fire({
      title: 'Error',
      text: err? err.error.reason: 'User is already part of this account.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Ok',
    }).then((result) => {
      //do nothing
    });
  }

  private checkUserByIdOrEmail(idEmail: string){
    this.saveLoading = true;
    this.httpRequest.getUsersByIdOrEmail(idEmail)
    .pipe(finalize(()=>this.saveLoading = false))
    .subscribe(
      res =>{
        if (res.error || !res.data) {
          this.emailExists = false;
          this.activeItemIndex = 1;
          this.adjustStepItems();
          return;
        }

        this.emailExists = true;
        this.getUserPermission(res.data);
        return;
      },
      err => {
        this.emailExists = false;
        this.activeItemIndex = 1;
        this.adjustStepItems();
      }
    )
  }

  private getUserPermission(user: UserProfile, activeItemIndex?: number ){
    this.saveLoading = true;
    this.httpRequest.getPermissions(user.userId)
    .pipe(finalize(()=>this.saveLoading=false))
    .subscribe(
      res => {
        this.initializeSelectedPermissions(res.data);
        this.exitingUser = user;
        this.activeItemIndex = activeItemIndex || this.activeItemIndex+1;
        this.adjustStepItems();
      },
      err => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch permission: ' + err.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then(result => {
          //do nothing
        });
      }
    );
  }

  private initializeSelectedPermissions(permission: PermissionModel){
    const {modes, branches, roles, permissionSet} = permission;

    this.selectedModes = modes;

    this.selectedBranches = [];

    this.selectedBranches = BranchesService.getSelectedTreeNodeBranch(branches, this.branches);

    this.permissionsTabModalObj.setCurrentRoles(this.roles.map(r => ({
      ...r,
      selected: roles.includes(r.roleId!)
    })));
    this.permissionsTabModalObj.setCurrentPermissions(permissionSet);
  }
}
