<app-breadcrumbs *ngIf="loadDetails" [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Details </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Visibility</th>
                                            <td class="text-muted" *ngIf="loadDetails && loadDetails.loadDetails.isPublic">Public</td>
                                            <td class="text-muted" *ngIf="loadDetails && !loadDetails.loadDetails.isPublic">Private</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Title</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.title}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Description</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.description}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Route</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.routeName}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Start Date</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.startDate | date: 'MM/dd/yyyy'}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Duration</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.duration}} months</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Contact Name</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.contactName}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Contact Email</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.contactEmail}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Contact Phone</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.contactPhone}}</td>
                                        </tr>
                                        <tr>
                                            <th class="ps-0" scope="row" *ngIf="loadDetails">Contact Link</th>
                                            <td class="text-muted" *ngIf="loadDetails">{{loadDetails.loadDetails.contactLink}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Carrier Search </a>
                        <ng-template ngbNavContent>
                            <app-search [showCard]="false" [loadDetails]="loadDetails.loadDetails"></app-search>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" (click)="updateInvitations()">
                            <i class="far fa-envelope"></i> Invitations </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Carrier</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let invitations of ListJsListInvitations$ | async">
                                            <td class="carrier-name">
                                                <ngb-highlight [result]="invitations.carrierName" [term]="serviceInvitations.searchTerm"></ngb-highlight>
                                            </td>
                                            <td *ngIf="invitations.status == 'accepted'">
                                                <ngb-highlight [term]="serviceInvitations.searchTerm"></ngb-highlight>
                                                <p style="color: green; margin-bottom: 0px;">
                                                    {{invitations.status}}
                                                </p>
                                            </td>
                                            <td *ngIf="invitations.status == 'rejected'">
                                                <ngb-highlight [term]="serviceInvitations.searchTerm"></ngb-highlight>
                                                <p style="color: red; margin-bottom: 0px;">
                                                    {{invitations.status}}
                                                </p>
                                            </td>
                                            <td *ngIf="invitations.status != 'rejected' && invitations.status != 'accepted'">
                                                <ngb-highlight [term]="serviceInvitations.searchTerm"></ngb-highlight>
                                                <p style="margin-bottom: 0px;">
                                                    {{invitations.status}}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row justify-content-md-between align-items-md-center" *ngIf="(totalInvitations$ | async)!">
                                <div class="col-sm-12 col-md-5">
                                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite"> Showing {{serviceInvitations.startIndex}} to {{serviceInvitations.endIndex}} of {{serviceInvitations.totalRecords}} entries </div>
                                </div>
                                <div class="col-sm-12 col-md-5">
                                    <div class="text-md-right float-md-end gridjs-pagination">
                                        <ngb-pagination [collectionSize]="(totalInvitations$ | async)!" [(page)]="serviceInvitations.page" [pageSize]="serviceInvitations.pageSize"></ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Proposals </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Carrier</th>
                                            <th>Message</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let proposals of ListJsListProposals$ | async">
                                            <td class="carrier-name" (click)="openModal(content, proposals, 'view')">
                                                <ngb-highlight [result]="proposals.carrierName" [term]="serviceProposals.searchTerm"></ngb-highlight>
                                            </td>
                                            <td class="message-column">
                                                <ngb-highlight [result]="proposals.message" [term]="serviceProposals.searchTerm"></ngb-highlight>
                                            </td>
                                            <td *ngIf="proposals.status == 'shipper_accepted'">
                                                <ngb-highlight [term]="serviceProposals.searchTerm"></ngb-highlight>
                                                <p style="color: green; margin-bottom: 0px;">
                                                    {{proposals.status}}
                                                </p>
                                            </td>
                                            <td *ngIf="proposals.status == 'shipper_rejected'">
                                                <ngb-highlight [term]="serviceProposals.searchTerm"></ngb-highlight>
                                                <p style="color: red; margin-bottom: 0px;">
                                                    {{proposals.status}}
                                                </p>
                                            </td>
                                            <td *ngIf="proposals.status != 'shipper_rejected' && proposals.status != 'shipper_accepted'">
                                                <button class="btn btn-sm btn-accepted" (click)="acceptProposal(proposals)"> Accept Proposal </button>
                                                <button class="btn btn-sm btn-rejected action-btn" (click)="rejectProposal(proposals)"> Reject Proposal </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row justify-content-md-between align-items-md-center" *ngIf="(totalProposals$ | async)!">
                                <div class="col-sm-12 col-md-5">
                                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite"> Showing {{serviceProposals.startIndex}} to {{serviceProposals.endIndex}} of {{serviceProposals.totalRecords}} entries </div>
                                </div>
                                <div class="col-sm-12 col-md-5">
                                    <div class="text-md-right float-md-end gridjs-pagination">
                                        <ngb-pagination [collectionSize]="(totalProposals$ | async)!" [(page)]="serviceProposals.page" [pageSize]="serviceProposals.pageSize"></ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Offers </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mt-3 mb-1">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Carrier</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let offers of ListJsListOffers$ | async">
                                            <td class="carrier-name">
                                                <ngb-highlight [result]="offers.carrierName" [term]="serviceOffers.searchTerm"></ngb-highlight>
                                            </td>
                                            <td>
                                                <ngb-highlight [result]="offers.status" [term]="serviceOffers.searchTerm"></ngb-highlight>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">View</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="container">
        <div class="table-responsive" *ngIf="companyInformation">
            <table class="table table-borderless mb-0">
                <tbody>
                    <tr>
                        <th class="ps-0" scope="row">Legal Name :</th>
                        <td class="text-muted">{{ companyInformation.legalName }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">DBA Name :</th>
                        <td class="text-muted">{{ companyInformation.dbaName }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Street :</th>
                        <td class="text-muted">{{ companyInformation.street }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">City :</th>
                        <td class="text-muted">{{ companyInformation.city }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">State :</th>
                        <td class="text-muted">{{ companyInformation.state }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Zip Code :</th>
                        <td class="text-muted">{{ companyInformation.zipCodes }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">DOT Number :</th>
                        <td class="text-muted">{{ companyInformation.dotNumber }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">MC Number :</th>
                        <td class="text-muted">{{ companyInformation.mcNumber }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Authority :</th>
                        <td class="text-muted" *ngIf="companyInformation.hasAuthority">Active</td>
                        <td class="text-muted" *ngIf="!companyInformation.hasAuthority">Inactive</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Type :</th>
                        <td class="text-muted" *ngIf="companyInformation.type == 'carrier-owner-operator'">Owner Operator</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'carrier-fleet'">Fleet</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-retail'">Retail</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-government'">Government</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-carrier'">Shipper (Carrier)</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-broker'">Shipper (Broker)</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Legal Entity :</th>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'sole-proprietor'">Sole Proprietor</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'llc'">LLC</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'c-corp'">C Corporation</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 's-corp'">S Corporation</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>