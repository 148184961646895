<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #billingAndInvoicingMenu="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink>
                        Billing Profile
                    </a>
                    <ng-template ngbNavContent>
                        <app-billing></app-billing>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item" (click)="changeTab(2)">
                    <a ngbNavLink>
                        <span class="d-sm-block">Invoicing</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-invoices [mainPage]="true"></app-invoices>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item" (click)="changeTab(3)">
                    <a ngbNavLink>
                        <span class="d-sm-block">Licenses</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-licenses></app-licenses>
                    </ng-template>
                </li>
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content">
              <div [ngbNavOutlet]="billingAndInvoicingMenu"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->