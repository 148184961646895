import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private router: Router
  ) { }

  getDirtyState(form: FormGroup): Object {
    return Object.keys(form.controls).reduce<Object>((dirtyState, controlKey) => {
      let control = form.controls[controlKey];
  
      if (!control.dirty) {
        return dirtyState;
      }
  
      if (control instanceof FormGroup) {
        return {
          ...dirtyState,
          [controlKey]: this.getDirtyState(control),
        };
      }
  
      return {
        ...dirtyState,
        [controlKey]: control.value,
      };
    }, {});
  }

  getCurrentUser() {
    return localStorage.getItem('currentUser');
  }

  updateCurrentUser(firstName: string, lastName: string, role: string) {
    let currentUser = JSON.parse(this.getCurrentUser()!);
    localStorage.removeItem('currentUser');
    currentUser.firstName = firstName;
    currentUser.lastName = lastName;
    currentUser.role = role;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));

  }

  navigateCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
