import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { NgbdModalUploadVerificationDocument } from '../modal/upload-verification-document-modal.component';
import { UpgradePlanModalComponent } from '../modal/upgrade-plan-modal/upgrade-plan-modal.component';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-setup-carrier',
  templateUrl: './setup-carrier.component.html',
  styleUrls: ['./setup-carrier.component.scss']
})
export class SetupCarrierComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Quick Start Links'
    }
  ];
  activeModal?: NgbModalRef;
  activeIds = ['static-1', 'static-2', 'static-3']
  branchId: any;
  hasDotNumber: boolean = false;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private httpRequest: HttpService,
    private auth: AuthService
  ) {
  }

  async ngOnInit() {
    this.branchId = this.auth.currentAccountSelected.branchId;
    if(this.branchId) {
      this.fetchCompanyInfo();
    }
  }

  redirectTo(url: string, activeTab?: number, childTab?: number) {
    if(activeTab != 0) {
      this.router.navigateByUrl(url, { state: { activeTab: activeTab, childTab: childTab } });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  private fetchCompanyInfo(): void {
    this.httpRequest.getCompanyInfo(this.branchId).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        console.log(res);
        if(successRes.data.dotNumber) {
          this.hasDotNumber = true;
        } else {
          this.hasDotNumber = false;
        }
      },
      error =>{
        console.error('Failed to fetch company info: ' + error.error.reason);
        this.hasDotNumber = false;
      }
    );
  }

  openModalHelp(type: string) {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalUploadVerificationDocument, { size: 'md', centered: true });
    modalRef.componentInstance.type = type;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  openUpgradePlanModal(){
    this.activeModal?.close();
    this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });

  }
}
