<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <p class="mb-0 text-muted"> © {{year}} Direct To Carrier - <a class="text-muted privacy-link" routerLink="/privacy">Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
</footer>