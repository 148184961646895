import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Load Icons
// import { defineLordIconElement } from 'lord-icon-element';
// import lottie from 'lottie-web';

// Component
import { PassResetRoutingModule } from "./pass-reset-routing.module";
import { BasicComponent } from './basic/basic.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PasswordSuccessComponent } from './password-success/password-success.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';

@NgModule({
  declarations: [
    BasicComponent,
    NewPasswordComponent,
    PasswordSuccessComponent,
    PasswordResetSuccessComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    PassResetRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PassResetModule {
  constructor() {
    // defineLordIconElement(lottie.loadAnimation);
  }
 }
