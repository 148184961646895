<div class="row">
    <div class="col-xl-10 col-lg-9 col-xxl-8">
        <div>
            <div class="card">
                <div class="card-body pt-2">
                    <div class="table-responsive table-card mb-1">
                        <table class="table align-middle table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Carrier</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="loading">
                                <tr class="loader-body">
                                    <td class="placeholder-glow name-td">
                                        <span class="placeholder col-6"></span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!loading && tableHelper.searchResult.length != 0">
                                <tr role="button" *ngFor="let res of tableHelper.searchResult; let i=index;" (click)="
                                    currentProposal = res;
                                    activeNdx = i;
                                ">
                                    <td class="w-75">
                                        <span>
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div class="avatar-sm bg-light rounded p-1">
                                                        <img src="assets/images/companies/img-{{ i%9 }}.png" alt="" class="img-fluid d-block">
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-14 mb-1">
                                                        <span class="text-dark">{{ res.carrierName }}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </span>
                                    </td>
                                    <td>
                                        <button *ngIf="proposalStatuses.includes('carrier_submitted')" class="btn btn-sm btn-primary" (click)="updateProposal('shipper_acknowledged')"> Interview </button>
                                    </td>
                                    <td>
                                        <button *ngIf="proposalStatuses.includes('carrier_submitted') || proposalStatuses.includes('shipper_acknowledged')" class="btn btn-sm btn-success" (click)="updateProposal('shipper_accepted')"> Make Offer </button>
                                    </td>
                                    <td>
                                        <button *ngIf="proposalStatuses.includes('carrier_submitted') || proposalStatuses.includes('shipper_acknowledged')" class="btn btn-sm btn-danger" (click)="updateProposal('shipper_rejected')"> &nbsp; Reject &nbsp; </button>
                                        <button *ngIf="proposalStatuses.includes('shipper_invited')" class="btn btn-sm btn-danger" (click)="updateProposal('shipper_withdrew')"> Uninvite </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <app-table-pagination *ngIf="!loading" [tableHelper]="tableHelper"></app-table-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-2 col-lg-3 col-xxl-4">
        <div class="card" id="company-view-detail">
            <div class="card-body text-center company-details">
                <div class="position-relative d-inline-block">
                    <div class="avatar-md">
                        <div class="avatar-title bg-light rounded-circle">
                            <img src="assets/images/companies/img-{{ activeNdx%9 }}.png" alt="" class="avatar-sm rounded-circle object-cover">
                        </div>
                    </div>
                </div>
                <h5 class="mt-3 mb-1">{{currentProposal?.carrierName}}</h5>
                <ul class="list-inline mb-0">
                    <li class="list-inline-item avatar-xs">
                        <a href="javascript:void(0);" class="avatar-title bg-soft-success text-success fs-15 rounded">
                            <i class="ri-global-line"></i>
                        </a>
                    </li>
                    <li class="list-inline-item avatar-xs">
                        <a href="javascript:void(0);" class="avatar-title bg-soft-danger text-danger fs-15 rounded">
                            <i class="ri-mail-line"></i>
                        </a>
                    </li>
                    <li class="list-inline-item avatar-xs">
                        <a href="javascript:void(0);" class="avatar-title bg-soft-warning text-warning fs-15 rounded">
                            <i class="ri-question-answer-line"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <h6 class="text-muted text-uppercase fw-semibold mb-3">Information</h6>
                <p class="text-muted mb-4">A company incurs fixed and variable costs such as the purchase of raw materials, salaries and overhead, as explained by AccountingTools, Inc. Business owners have the discretion to determine the actions.</p>
                <div class="table-responsive table-card">
                    <table class="table table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td class="fw-medium" scope="row">Industry Type</td>
                                <td class="industry_type">Chemical Industries</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Location</td>
                                <td class="location">Damascus, Syria</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Employee</td>
                                <td class="employee">10-50</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Rating</td>
                                <td>
                                    <span class="rating">4.0 </span>
                                    <i class="ri-star-fill text-warning align-bottom"></i>
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Website</td>
                                <td>
                                    <a href="javascript:void(0);" class="link-primary text-decoration-underline website">www.syntycesolution.com</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Contact Email</td>
                                <td class="email">info@syntycesolution.com</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Since</td>
                                <td class="since">1995</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>