import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputZipCodeGroup, MapboxZipcodePickerComponent, ZipCodeColors, ZipCodeGroup } from '../mapbox-zipcode-picker/mapbox-zipcode-picker.component';
import chroma from 'chroma-js';
import { MapService } from 'src/app/core/services/map.service';
import { getBounds, getFirstLngLat, getLatitudesLongitudesCenterPoint, getZipCodeLngLat } from 'src/app/core/utils/zip-code-utils';

export type ZipCodeCounts = {
  counts: { [key in string]: number };  // key is the zipcode
  total: number;
  max: number;
};

const PARTITION_COUNT = 9;

@Component({
  selector: 'app-mapbox-heatmap',
  templateUrl: './mapbox-heatmap.component.html',
  styleUrls: ['./mapbox-heatmap.component.scss']
})
export class MapboxHeatmapComponent implements OnInit, AfterViewInit {
  @ViewChild('mapbox') mapBox: MapboxZipcodePickerComponent;
  private _zipCodeCounts: ZipCodeCounts = {
    counts: {},
    total: 15,
    max: 10,
  };
  zipCodeColors: ZipCodeColors = {};
  center: [number, number] = [-98.38, 38.69];

  @Input()
  set zipCodeCounts(zipCodes: ZipCodeCounts) {
    this._zipCodeCounts = zipCodes;
    this.setZipCodeColors();
    // this.setCenter();

    const zipCodesArr = Object.keys(this._zipCodeCounts.counts);
    if (zipCodesArr.length > 0) {
      this.mapBox?.fitBounds(zipCodesArr);
      // this.center = MapboxZipcodePickerComponent.getLatitudesLongitudesCenterPoint(zipCodesArr);
      this.setTextField();
    }
  }

  @Input()
  selectableZipCodes: string[] | undefined;

  @Input()
  zipCodeGroups: InputZipCodeGroup[];
  @Output()
  zipCodeGroupsChange = new EventEmitter<ZipCodeGroup[]>();
  @Output()
  focusedZipCodeChange = new EventEmitter<string>();

  @Input()
  focusedGroupId: string | undefined;

  _groupOpacity = 0.25;
  @Input()
  set groupOpacity(opacity: number) {
    this._groupOpacity = opacity;
  };

  _showPoBoxes = true;
  @Input()
  set showPoBoxes(showPoBoxes: boolean) {
    this._showPoBoxes = showPoBoxes;
  };

  _autoSelectPoBoxes = true;
  @Input()
  set autoSelectPoBoxes(autoSelectPoBoxes: boolean) {
    this._autoSelectPoBoxes = autoSelectPoBoxes;
  };

  _focusedZipCode = '';
  @Input()
  set focusedZipCode(focusedZipCode: string) {
    this._focusedZipCode = focusedZipCode;
  };

  @Output()
  onLoad = new EventEmitter<void>();

  ngOnInit(): void {
  }

  ngAfterViewInit(): void { 
  }

  private setTextField(): void {
    const text = [
      "case",
      ...Object.entries(this._zipCodeCounts.counts).map(([key, value]) => {
        return [
          ["in", ["get", "GEOID"], ["literal", key]],
          ['concat', ['get', 'GEOID'], '\n', value]
        ]
      }).flat(),
      ['get', 'GEOID']
    ];
    this.mapBox?.map?.setLayoutProperty("zips_point", "text-field", text);
  }

  getColorByPercentage(percentage: number): string {
    if (percentage > 0.9) {
      return '#ff0000';
    } else if (percentage > 0.8) {
      return '#ff3f20';
    } else if (percentage > 0.7) {
      return '#ff5d38';
    } else if (percentage > 0.6) {
      return '#ff754f';
    } else if (percentage > 0.5) {
      return '#ff8b67';
    } else if (percentage > 0.4) {
      return '#ff9f7f';
    } else if (percentage > 0.3) {
      return '#ffb297';
    } else if (percentage > 0.2) {
      return '#ffc5b1';
    } else if (percentage > 0.1) {
      return '#ffd8cb';
    } else {
      return '#ffd8ca';
    }
  }

  getColorByMaxFn(max: number): (count: number) => string {
    return (count: number) => {
      if (count <= max * .10) {
        return '#fffc9d'; //Light Yellow
      } else if (count <= max * .20) {
        return '#fdc70c'; //Light Orange
      } else if (count <= max * .30) {
        return '#e93e3a'; //Red
      } else {
        return '#a32a29'; //Dark Red
      }
    };
  }

  getColorByTotalFn(total: number): (count: number) => string {
    return (count: number) => {
      if (count <= total * .05) {
        return 'cyan';
      } else if (count <= total * .10) {
        return 'green';
      } else if (count <= total * .15) {
        return 'yellow';
      } else if (count <= total * .20) {
        return 'orange';
      } else {
        return 'red';
      }
    };
  }

  getDynamicColorByMaxFn(max: number, partitionCount = PARTITION_COUNT): (count: number) => string {
    // const partition = max / partitionCount;
    // const shades = this.generateLighterShades('red', partitionCount);  // lighter to darker
    // return (count: number) => {
    //   for (let i = 1; i <= partitionCount; i++) {
    //     if (count > max - (i * partition)) {
    //       return shades[partitionCount - i];
    //     }
    //   }

    //   return shades[0];  // return lightests
    // }

    // const scale = chroma.scale(['#fcdcdc', 'red']);
    // return (count: number) => {
    //   return scale(count / max).hex();
    // }

    return (count: number) => {
      const h = (1.0 - (count / max)) * 240;
      return "hsl(" + h + ", 100%, 50%)";
    }
  }

  /**
   * Generate lighter shades for the given color.
   * @param color - the base color (darkest)
   * @param numberOfShades - number of shades to generate, including the base color
   * @returns the shades array from lightest to darkest
   */
  generateLighterShades(color: string, numberOfShades = PARTITION_COUNT): string[] {
    const scale = chroma.scale(['white', color]);
    return [...Array(numberOfShades).keys()].map(index => {
      const scaleNum = (index + 1) / numberOfShades;
      // console.log(index, scaleNum, scale(scaleNum).hex());
      return scale(scaleNum).hex();
    });
  }

  setZipCodeColors(): void {
    if (!this._zipCodeCounts.total || !this._zipCodeCounts.max) {
      this.zipCodeColors = {};
      return;
    }
    const tmpZipCodeColors: ZipCodeColors = {};
    for (const [key, value] of Object.entries(this._zipCodeCounts.counts)) {
      // const color = this.getColorByPercentage(value / this._zipCodeCounts.total);
      const fn = this.getColorByMaxFn(this._zipCodeCounts.max);
      const color = fn(value);
      // if (count === 0) {
      //   continue; // skip this zip
      // }
      tmpZipCodeColors[key] = color;
    }
    this.zipCodeColors = tmpZipCodeColors;
  }

  setCenter(): void {
    const zipCodesArr = Object.keys(this._zipCodeCounts.counts);
    if (zipCodesArr.length > 0) {
      this.center = getLatitudesLongitudesCenterPoint(zipCodesArr);
    }
  }
}
