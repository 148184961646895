import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import '@nylas/components-agenda';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

declare const nylas:any;

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit, AfterViewInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Meetings'}
  ];
  activeModal?: NgbModalRef;
  id?: string;
  calendarId?: string;

  @ViewChild('welcomeVideoModal') welcomeVideoModal!: Element;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;
    this.id = currentUser.nylas!.agendaComponentId;
    this.calendarId = currentUser.nylas!.calendarId;
  }

  ngAfterViewInit(): void {
    // if (this.auth.currentUserValue.showWelcomeVideo && this.auth.currentUserValue.role != 'shipper-owner') {
    //   this.openModal();
    // }
  }

  closeModal(): void {
    this.activeModal?.close();
  }
}
