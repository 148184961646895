/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from './gridjs-sortable.directive';
import { HttpService } from 'src/app/core/services/http-service';
import { Mode } from '../models/mode.model';

interface SearchResult {
  mode: Mode[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

const compare = (
  v1: undefined | string | number | boolean,
  v2: undefined | string | number | boolean
) => {
  v1 = v1 ?? 0;
  v2 = v2 ?? 0;
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

function sort(mode: Mode[], column: SortColumn, direction: string): Mode[] {
  if (direction === '' || column === '') {
    return mode;
  } else {
    return [...mode].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(mode: Mode, term: string, pipe: PipeTransform) {
  return (
    mode.name?.toLowerCase().includes(term.toLowerCase()) ||
    mode.type?.toLowerCase().includes(term.toLowerCase())
  );
}

@Injectable({ providedIn: 'root' })
export class ModeService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _mode$ = new BehaviorSubject<Mode[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    startIndex: 0,
    endIndex: 9,
    totalRecords: 0,
  };

  private data: any;

  loadData(): void {
    this.httpRequest.getModes().subscribe((result: any) => {
      this.data = result.data;
      this._search$
        .pipe(
          tap(() => this._loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this._loading$.next(false))
        )
        .subscribe((result) => {
          this._mode$.next(result.mode);
          this._total$.next(result.total);
        });

      this._search$.next();
    });
  }

  constructor(private pipe: DecimalPipe, private httpRequest: HttpService) {
    this.loadData();
  }

  get mode$() {
    return this._mode$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get startIndex() {
    return this._state.startIndex;
  }
  get endIndex() {
    return this._state.endIndex;
  }
  get totalRecords() {
    return this._state.totalRecords;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set startIndex(startIndex: number) {
    this._set({ startIndex });
  }
  set endIndex(endIndex: number) {
    this._set({ endIndex });
  }
  set totalRecords(totalRecords: number) {
    this._set({ totalRecords });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;
    // 1. sort
    let mode = sort(this.data, sortColumn, sortDirection);
    // 2. filter
    mode = mode.filter((mode) => matches(mode, searchTerm, this.pipe));
    const total = mode.length;

    // 3. paginate
    this.totalRecords = mode.length;
    this._state.startIndex = (page - 1) * this.pageSize + 1;
    this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    mode = mode.slice(this._state.startIndex - 1, this._state.endIndex);
    return of({ mode, total });
  }

  updateTable() {
    this.httpRequest.getModes().subscribe((result: any) => {
      this.data = result.data;
      this._search$
        .pipe(
          tap(() => this._loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this._loading$.next(false))
        )
        .subscribe((result) => {
          this._mode$.next(result.mode);
          this._total$.next(result.total);
        });

      this._search$.next();
    });
  }
}
