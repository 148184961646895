<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
        <div class="bg-overlay"></div>
  
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
  
    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <p class="mt-3 fs-1 fw-semibold">Direct To Carrier</p>
                    </div>
                </div>
            </div>
            <!-- end row -->
  
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card mt-4">
                        <div class="card-body p-4 text-center">
                            <div class="avatar-lg mx-auto mt-2">
                                <div [class]="generalMessageService.icon?.containerCls || 'avatar-title bg-light text-success display-3 rounded-circle'">
                                    <i [class]="generalMessageService.icon?.cls || 'ri-checkbox-circle-fill'"></i>
                                </div>
                            </div>
                            <div class="mt-4 pt-2">
                                <h4 *ngIf="generalMessageService.text?.header">{{generalMessageService.text?.header}}</h4>
                                <p class="text-muted mx-4" *ngIf="generalMessageService.text?.message">{{generalMessageService.text?.message}}</p>
                                <div class="mt-4" *ngIf="generalMessageService.goToLink">
                                    <a [routerLink]="generalMessageService.goToLink?.url" class="btn btn-success w-100">{{generalMessageService.goToLink?.label}}</a>
                                </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
  
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->
  
    <!-- footer -->
    <app-signup-footer></app-signup-footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
  