import {Component, OnInit} from '@angular/core';
// Sweet Alert
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';

import zipcodes from '../../../assets/jsons/zipcodes.json';
import { ToastService } from '../../services/toast-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';


@Component({
  selector: 'app-serviceable-zipcodes',
  templateUrl: './serviceable-zipcodes.component.html',
  styleUrls: ['./serviceable-zipcodes.component.scss'],
  providers: []
})
export class ServiceableZipcodesComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Serviceable ZIP Codes'}
  ];

  zipcodesForm!: FormGroup;
  submitted = false;
  zipcodesJson: any;
  zipcodes: any = [];
  showSpinner = false;
  showForm = false;
  showSuccess = false;
  showError = false;
  errorMessage = '';
  clearSearchKey = false;
  constructor(
    private auth: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    public toastService: ToastService
    ) { }

    ngOnInit(): void {
      fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
          this.zipcodesJson = [...new Set(jsonData)];
      });
      this.initForms();
      this.getDefaultZipcodes();
    }

    initForms() {
      this.zipcodesForm = this.formBuilder.group({
        zipCodes: [''],
      });
    }

    getDefaultZipcodes() {
      this.httpRequest.getUsersZipcodes(this.auth.currentAccountSelected.accountId!).subscribe((data:any) => {
        this.form['zipCodes'].setValue(data.data.zipCodes);
        this.showForm = true;
      })
    }

    get form() {
      return this.zipcodesForm.controls;
    }


    submitZipcodes() {
      this.submitted = true;
      this.showSpinner = true;
      this.showError = false;
      this.showSuccess = false;
      if(this.zipcodesForm.invalid) {
        this.showSpinner = false;
        return;
      } else {
        this.httpRequest.submitZipcodes(this.auth.currentAccountSelected.accountId!, this.zipcodesForm.value).subscribe((data: any) => {
          this.submitted = false;
          this.showSpinner = false;
          this.showSuccessToast();
        }, error => {
          this.submitted = false;
          this.showSpinner = false;
          this.errorMessage = error.error.reason;
          this.showDanger(this.errorMessage);
        })
      }
    }

    showSuccessToast() {
      this.toastService.show('ZIP Codes have been saved.', { classname: 'bg-success text-center text-white', delay: 5000, autohide: true });
    }

    showDanger(message: string) {
      this.toastService.show(message, { classname: 'bg-danger text-center text-white', delay: 50000, autohide: false, headertext: ' ' });
    }
}
