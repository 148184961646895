import { Component, OnDestroy, OnInit } from '@angular/core';
import { Mode } from 'src/app/core/services/models/mode.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { User } from 'src/app/core/services/models/auth.models';
import { Observable, of, Subscription } from 'rxjs';
import { ModeService } from 'src/app/core/services/mode-service/mode.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { Permission } from 'src/app/core/services/models/permissions.model';
import { TreeNode } from 'primeng/api';
import { BranchesService, TreeNodeBranch } from '../../origin-points/origin-point/branches.service';

@Component({
  selector: 'app-modes',
  templateUrl: './modes.component.html',
  styleUrls: ['./modes.component.scss'],
  providers: [ModeService],
})
export class ModesComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  saveLoading = false;
  currentUser!: User;
  submitted = false;
  modeForm!: FormGroup;
  activeModal?: NgbModalRef;
  modes!: Mode[];
  branches: TreeNode<TreeNodeBranch>[] = [];
  selectedBranches: TreeNode<TreeNodeBranch>[] = [];
  // Table data
  ListJsList$: Observable<Mode[]> = of([]);
  total$: Observable<number> = of(0);
  showData = false;
  edit = false;
  readonly TYPE_MENU_OPTIONS = [
    { name: 'Final Mile', code: 'FM' },
    { name: 'Truckload', code: 'TL' },
  ];
  readonly ROUTE_TYPES_MENU_OPTIONS = [
    { name: 'Service Areas', code: 'serviceAreas', hasServiceAreas: true, hasLanes: false },
    { name: 'Lanes', code: 'lanes', hasServiceAreas: false, hasLanes: true },
    {
      name: 'Service Areas + Lanes',
      code: 'serviceAreas+lanes',
      hasServiceAreas: true,
      hasLanes: true,
    },
  ];
  readonly ROUTE_TYPES_MENU_OPTIONS_MAP: Map<
    string,
    { hasServiceAreas: boolean; hasLanes: boolean }
  > = new Map(
    this.ROUTE_TYPES_MENU_OPTIONS.map((routeTypes) => [
      routeTypes.code,
      { hasServiceAreas: routeTypes.hasServiceAreas, hasLanes: routeTypes!.hasLanes },
    ])
  );
  readonly ROUTE_TYPES_MENU_OPTIONS_REVERSE_MAP!: Map<boolean,Map<boolean, string>>;
  selectedAccountSubscription: Subscription;

  constructor(
    public service: ModeService,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    public permissionsService: PermissionsService
  ) {
    super();
    this.ROUTE_TYPES_MENU_OPTIONS_REVERSE_MAP = this.get_ROUTE_TYPES_MENU_OPTIONS_REVERSE_MAP();
    this.selectedAccountSubscription = this.authService.subscribeToSelectedAccount(async (account) => {
      await this.permissionsService.checkUrlPermission('_modes');
      return this.loadData();
    }, 'Modes');
  }

  async loadData(): Promise<void> {
    this.service.loadData();
  }

  get modesPermissions(): Permission {
    return this.permissionsService.permissions.Modes;
  }

  private get_ROUTE_TYPES_MENU_OPTIONS_REVERSE_MAP(): Map<boolean,Map<boolean, string>>{
    const routeTypeMenuReverseMap = new Map<boolean,Map<boolean, string>>();
    this.ROUTE_TYPES_MENU_OPTIONS.forEach(
      routTypes => {
        const {hasServiceAreas, hasLanes, code} = routTypes;
        if(!routeTypeMenuReverseMap.has(hasServiceAreas)){
          routeTypeMenuReverseMap.set(hasServiceAreas, new Map<boolean, string>());
        }

        routeTypeMenuReverseMap.get(hasServiceAreas)?.set(hasLanes, code);
      }
    );

    return routeTypeMenuReverseMap;
  }

  ngOnDestroy(): void {
    this.selectedAccountSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.ListJsList$ = this.service.mode$;
    this.total$ = this.service.total$;
    this.modeForm = this.formBuilder.group({
      modeId: [undefined],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      routeTypes: ['', [Validators.required]],
    });
    this.currentUser = this.authService.currentUserValue;
    this.httpService.getBranchesByHierarchy()
      .subscribe(res => this.branches = BranchesService.getRecursiveTreeNodeBranch(res.data));
    setTimeout(() => {
      this.showData = true;
    }, 2000);
  }

  get form(): { [key: string]: AbstractControl } {
    return this.modeForm.controls;
  }

  openModal(content: any, defaultMode?: Mode) {
    this.submitted = false;
    this.selectedBranches = [];
    if (defaultMode && (!this.modesPermissions || !this.modesPermissions.Update)) {
      return;
    } else if (!defaultMode && (!this.modesPermissions || !this.modesPermissions.Create)) {
      return;
    }
    this.submitted = false;
    this.modeForm.reset();

    if(defaultMode) {
      const {modeId, name, type, hasServiceAreas, hasLanes} = defaultMode;
      const routeTypes = this.ROUTE_TYPES_MENU_OPTIONS_REVERSE_MAP
        .get(hasServiceAreas)
        ?.get(hasLanes);
      this.modeForm.patchValue({
        modeId,
        name,
        type,
        routeTypes
      });
      this.edit = true;
    } else {
      this.edit = false;
    }

    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
    });
  }

  deleteMode(){
    this.saveLoading = true;
    const { modeId } = this.modeForm.value;

    this.httpService.deleteMode(modeId)
    .pipe(finalize(() => (this.saveLoading = false)))
    .subscribe(
      res => {
        this.service.updateTable();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Mode has been deleted.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        })
      },
      err => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete mode.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        })
      }
    );
  }

  saveMode() {
    this.submitted = true;
    if (this.modeForm.invalid || this.selectedBranches.length == 0) {
      return;
    }

    this.saveLoading = true;

    const { modeId, name, type, routeTypes } = this.modeForm.value;

    const mode: Mode = {
      accountId: this.authService.currentAccountSelected.accountId!,
      name,
      type,
      hasLanes: this.ROUTE_TYPES_MENU_OPTIONS_MAP.get(routeTypes)!.hasLanes,
      hasServiceAreas: this.ROUTE_TYPES_MENU_OPTIONS_MAP.get(routeTypes)!.hasServiceAreas,
    };

    const httpCall = modeId ? this.httpService.updateMode(modeId, mode) : this.httpService.addMode(mode);

    httpCall.pipe(finalize(() => (this.saveLoading = false)))
      .subscribe(
        (sucess) => {
          this.submitted = false;
          this.service.updateTable();
          this.activeModal?.close();
          Swal.fire({
            title: 'Success',
            text: 'Mode has been saved.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        },
        (error) => {
          this.submitted = true;
          Swal.fire({
            title: 'Error',
            text: 'Failed to save mode.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
            }).then(result => {
              //do nothing
            });
        }
      );
  }
}
