import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})

export class TemplateComponent implements OnInit {
    breadCrumbItems: BreadCrumbItem[] = [
      {label: 'TEMPLATE'}
    ];
    currentUser: any;

    constructor(
    private router: Router
    ){ }

    ngOnInit(): void {
    }
 

}
