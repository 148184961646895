import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { Account } from 'src/app/core/services/models/account.model';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { Branch, BranchMode } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { DefaultPlace, GoogleMapsSearchBoxComponent } from 'src/app/shared/google-maps-search-box/google-maps-search-box.component';
import Swal from 'sweetalert2';
import branchesTestJson from '../../../../assets/jsons/test-branch-data.json'
import { BranchesService, BranchTreeNode, TreeNodeBranch } from '../origin-point/branches.service';
import { BranchEvent } from '../origin-point/origin-point.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-edit-branch',
  templateUrl: './edit-branch.component.html',
  styleUrls: ['./edit-branch.component.scss']
})
export class EditBranchComponent implements OnInit, OnDestroy {
  @Input()
  isRouted = true;
  @Input()
  branchData: Branch;
  @Input()
  parentBranch?: Branch;
  @Output()
  event = new EventEmitter<BranchEvent>();
  loading = false;

  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Branches',
      url: '/pages/branches'
    },
    {
      label: this.auth.currentAccountSelected.accountName!
    },
    {
      label: 'Edit'
    }
  ];
  currentUser: any;
  activeTab = 0;
  branchForm!: FormGroup;
  submitted = false;

  modes: any = [];

  shipperCompanyBranchTypes: any = [];
  carrierCompanyBranchTypes: any = [];
  hqBranchTypes: any = [];
  subsidiaryBranchTypes: any = [];
  organizationBranchTypes: any = [];
  regionBranchTypes: any = [];
  dcHubCrossDockBranchTypes: any = [];
  branchBranchTypes: any = [];
  storeBranchTypes: any = [];
  remoteStoreBranchTypes: any = [];

  rootAccountType:any = 'subsidiary';
  branchesJsonTest: any = branchesTestJson.data;

  @ViewChild('googleMapsInput') googleMapsInput: GoogleMapsSearchBoxComponent;
  address!: google.maps.places.PlaceResult;
  defaultPlace?: DefaultPlace;

  statesJson: any = [];
  zipcodesJson: any;
  disableType = false;
  subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  isPrefix = false;

  private routedNodeData: Branch;
  private routedParentData: Branch;

  edittingInstructions = false;
  instructions?: string;

  domicileLocationModeIds: string[] = [];
  trailerPoolModeIds: string[] = [];

  currentAccountPlan: AccountPlan;
  currentAccount: Account;
  constructor(
    private auth: AuthService,
    private router: Router,
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    private branchService: BranchesService,
    private eventService: EventService,
    private modalService: NgbModal,
  ) {
    const branchTreeNode: BranchTreeNode = this.router.getCurrentNavigation()?.extras.state?.['branchData'].node;
    this.auth.setSelectedBranch(branchTreeNode);
    this.routedNodeData = branchTreeNode?.data!;
    this.routedParentData = this.router.getCurrentNavigation()?.extras.state?.['branchData'].parent?.data;
  }

  ngOnInit(): void {
    if(this.isRouted){
      this.branchData = this.routedNodeData;
      this.parentBranch = this.routedParentData;
    }

    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject,
        this.auth.selectedBranchTreeNodeSubject,
        this.auth.currentAccountPlanSubject
      ]).subscribe(
        ([account, mode, branch, branchTreeNode, accountPlan]) => {
          if(this.anyNull(account, mode, branch, accountPlan) || this.allSelectedSame(account, mode, branch, accountPlan)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;
          this.currentAccountPlan = accountPlan;

          if(!branchTreeNode?.data){
            return;
          }
          this.branchData = branchTreeNode.data;
          this.parentBranch = branchTreeNode.parent?.data;
          this.initComponent();
        }
      )
    );

    this.initComponent();
    
    this.fetchJsons();
    this.currentAccount = this.auth.currentAccountSelected;
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null, accountPlan: AccountPlan | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null, accountPlan: AccountPlan | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  initComponent(){
    const placeId = this.branchData?.address?.googlePlaceId;
    const lat = this.branchData?.address?.latitude;
    const lng = this.branchData?.address?.longitude;
    if (placeId && lat && lng) {
      this.defaultPlace = {
        gmap: {placeId, lat, lng}
      };
    }
    if(this.branchData) {
      this.getModes();
      if(this.branchService.isTypeShipperCarrier(this.branchData)) {
        this.disableType = true;
      } else {
        this.disableType = false;
      }
    } else {
      console.log('[edit-branch] - no branchData, redirecting...');
      this.router.navigate(['/pages/branches']);
    }

    this.currentUser = this.auth.currentUserValue;
    this.initForm();
    this.rootAccountType = this.auth.currentAccountSelected.accountType;
  }

  fetchJsons(): void {
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
        this.zipcodesJson = [...new Set(jsonData)];
    });

    fetch('./assets/jsons/edit-branch-types/shipper-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.shipperCompanyBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/carrier-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.carrierCompanyBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/hq-child-types.json').then(res => res.json()).then(jsonData => {
      this.hqBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/subsidiary-child-types.json').then(res => res.json()).then(jsonData => {
      this.subsidiaryBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/organization-child-types.json').then(res => res.json()).then(jsonData => {
      this.organizationBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/region-child-types.json').then(res => res.json()).then(jsonData => {
      this.regionBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/dc-hub-crossdock-child-types.json').then(res => res.json()).then(jsonData => {
      this.dcHubCrossDockBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/branch-child-types.json').then(res => res.json()).then(jsonData => {
      this.branchBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/store-child-types.json').then(res => res.json()).then(jsonData => {
      this.storeBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });

    fetch('./assets/jsons/edit-branch-types/remote-store-child-types.json').then(res => res.json()).then(jsonData => {
      this.remoteStoreBranchTypes = [...new Set(jsonData)]
        .map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; })
        .filter(i => this.branchService.filterFnBranchType(i));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setActiveTab(tabId: number) {
    if(
      AccountPlanUtil.isTrial(this.currentAccountPlan)
      && (tabId === 2 || tabId === 3 || tabId === 4)
    ){
      return;
    } else {
      this.activeTab = tabId;
    }
  }

  initForm() {
    this.branchForm = this.formBuilder.group({
      parentId: [this.branchData.parentId],
      name: [this.branchData.name, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      type: [this.branchData.type, [Validators.required]],
      modes: [this.branchData.modes?.map(m => m.modeId), [Validators.required]],
      contactName: [''],
      contactPhone: [''],
      contactEmail: [''],
    });
    this.branchForm.controls['type'].disable();

    const modes = this.branchData.modes ?? [];
    this.domicileLocationModeIds = [];
    this.trailerPoolModeIds = [];
    modes.forEach(
      mode => {
        if(mode.domicileLocation){
          this.domicileLocationModeIds.push(mode.modeId);
        }
        if(mode.trailerPool){
          this.trailerPoolModeIds.push(mode.modeId);
        }
      }
    );

    this.setOriginalInstructions();
    
    this.checkPrefix();
  }

  private setOriginalInstructions(): void{
    this.instructions = this.branchData.modes?.find(
      mode => this.selectedMode?.modeId === mode.modeId
    )?.instructions;
  }

  editInstructions(): void {
    this.setOriginalInstructions();
    this.edittingInstructions = true;
  }

  cancelEditInstructions(): void {
    this.setOriginalInstructions();
    this.edittingInstructions = false;
  }

  changeSelectedModes(){
    const modes = new Set(this.modesControl.value);
    this.domicileLocationModeIds.forEach(modeId => modes.add(modeId));
    this.trailerPoolModeIds.forEach(modeId => modes.add(modeId));
    this.modesControl.setValue([...modes]);
  }

  modesChanged(){
    const modes = new Set(this.modesControl.value);
    this.domicileLocationModeIds = this.domicileLocationModeIds.filter(
      modeId => modes.has(modeId)
    );
    this.trailerPoolModeIds = this.trailerPoolModeIds.filter(
      modeId => modes.has(modeId)
    );
  }

  get form() {
    return this.branchForm.controls;
  }

  get modesControl(): FormControl {
    return this.branchForm?.controls && this.branchForm.controls['modes'] as FormControl;
  }

  setDefaultModes() {
    if(this.branchData.modes) {
      this.modesControl?.setValue(this.branchData.modes.map(m => m.modeId));
    }
  }

  getModes() {
    if(this.branchData.parentId && this.parentBranch?.modes){
      this.modes = [];
      for(let x = 0; x < this.parentBranch.modes.length; x++) {
        this.modes.push(
          {
            hasLanes: this.parentBranch.modes[x].hasLanes,
            hasServiceAreas: this.parentBranch.modes[x].hasServiceAreas,
            modeId: this.parentBranch.modes[x].modeId,
            name: this.parentBranch.modes[x].name,
            type: this.parentBranch.modes[x].type
          }
        )
      }
      this.setDefaultModes();

      return;
    }

    this.httpRequest.getModes().subscribe((data: any) => {
      this.modes = [];
      for(let x = 0; x < data.data.length; x++) {
        this.modes.push(
          {
            hasLanes: data.data[x].hasLanes,
            hasServiceAreas: data.data[x].hasServiceAreas,
            modeId: data.data[x].modeId,
            name: data.data[x].name,
            type: data.data[x].type
          }
        )
      }
      this.setDefaultModes();
    });

    this.setDefaultModes();
  }


  onAddressChange(address: google.maps.places.PlaceResult): void {
    this.address = address;
  }

  proceedNextStep() {
    this.submitted = true;
    const isBranchInvalid = this.branchForm.invalid;
    const isAdressInvalid = !this.googleMapsInput.validateForm();
    if(isBranchInvalid || isAdressInvalid) {
        return;
    } else {
      this.editBranch();
    }
  }

  updateInstructions(){
    let branchMode = this.branchData.modes?.find(
      mode => this.selectedMode?.modeId === mode.modeId
    );
    
    if(branchMode){
      branchMode.instructions = this.instructions;
    }else{
      this.branchData.modes ??= [];
      this.branchData.modes.push(
        {
          modeId: this.selectedMode?.modeId,
          name: this.selectedMode?.name,
          type: this.selectedMode?.type,
          instructions: this.instructions
        } as BranchMode
      );
    }
    this.httpRequest.updateBranch(
      this.branchData.branchId, 
      {
        modes: this.branchData.modes
      }
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<Branch>> res;
        this.edittingInstructions = false;
        this.eventService.broadcastBranchChanged(successRes.data);
        Swal.fire({
          title: 'Success',
          text: 'Successfully saved instructions.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save instructions: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  editBranch() {
    if(this.branchService.checkIfPrefix(this.branchForm.controls['type'].value)) {
      this.branchForm.controls['name'].setValue(this.returnPrettyTypeName() + '' + this.branchForm.controls['name'].value);
    }

    const selectedModeIds: string[] = this.modesControl.value ?? [];

    this.branchData.modes ??= [];

    const currentModeMap = this.branchData.modes.reduce(
      (acc, cur) => {
        acc.set(cur.modeId, cur);
        return acc;
      },
      new Map<string, BranchMode>()
    );

    console.log('[editBranch] currentModeMap', currentModeMap);
    console.log('[editBranch] selectedModeIds', selectedModeIds);

    const modesToSave = selectedModeIds.reduce(
      (acc, modeId)=>{
        const branchMode = currentModeMap.has(modeId)
          ? currentModeMap.get(modeId)!
          : {modeId} as BranchMode;
        branchMode.domicileLocation = this.domicileLocationModeIds.includes(modeId);
        branchMode.trailerPool = this.trailerPoolModeIds.includes(modeId);
        acc.push(branchMode);
        return acc;
      },
      [] as BranchMode[]
    );

    console.log('[editBranch] modesToSave', modesToSave);

    const branch = {
      ...this.branchForm.value,
      address: new AddressComponent(this.address),
      modes: modesToSave
    }

    this.httpRequest.updateBranch(this.branchData.branchId, branch).subscribe(
      (data:any) => {
        this.eventService.broadcastBranchChanged(data.data);
        if(this.isRouted){
          this.router.navigate(['/pages/branches']);
        }else{
          this.event.emit(
            {
              type: 'list-branch',
              branch: null
            }
          );
        }
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update branch: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  checkPrefix() {
    if(this.branchService.checkIfPrefix(this.branchForm.controls['type'].value)) {
      this.isPrefix = true;
      let name: string = this.branchForm.controls['name'].value;
      if(name.includes(this.returnPrettyTypeName())) {
        this.branchForm.controls['name'].setValue(name.replace(this.returnPrettyTypeName(), ''));
      }
    } else {
      this.isPrefix = false;
    }
    // console.log(this.branchForm.controls['type'].value);
  }

  returnPrettyTypeName() {
    switch (this.branchForm.controls['type'].value) {
      case "hq":
        return 'HQ';
        break;
      case "store":
        return 'Store';
        break;
      case "remote-store":
        return 'Remote Store';
        break;
      case "dc":
        return 'DC';
        break;
      case "hub":
        return 'Hub';
        break;
      case "region":
        return 'Region';
        break;
      case "branch":
        return 'Branch';
        break;
      case "3pl-cross-dock":
        return '3PL Cross Dock';
        break;
      case "cross-dock":
        return 'Cross Dock';
        break;
      case "organization":
        return 'Organization';
        break;
      case "subsidiary":
        return 'Subsidiary';
        break;
      case "":
        return '';
        break;
      default:
        return 'Store';
        break;
    }
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  promptUpgrade(): void {
    if(
      this.auth.currentAccountPlanValue.status === 'paid'
    ){
      return;
    }
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

  deleteBranchConfirm(){
    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + this.branchData.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.deleteBranch();
      }
    });
  }

  private deleteBranch(){
    this.loading = true;
    this.httpRequest.deleteBranch(this.branchData.branchId).subscribe(
      res => { 
        this.loading = false;
        this.eventService.broadcastBranchDeleted(this.branchData.branchId);
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted branch.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if(this.isRouted){
            this.router.navigate(['/pages/branches']);
          }else{
            this.event.emit(
              {
                type: 'list-branch',
                branch: null
              }
            );
          }
        });
      },
      error => {
        this.loading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete branch: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

}
