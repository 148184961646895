<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row" *ngIf="showAddButton">
    <div class="col-xl-6">
        <div class="d-flex flex-column h-100">
            <div class="row">
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Routes Not Posted</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="36894" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="total_jobs" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="basicRadialbarChart.series" [chart]="basicRadialbarChart.chart" [dataLabels]="basicRadialbarChart.dataLabels" [plotOptions]="basicRadialbarChart.plotOptions" [colors]="basicRadialbarChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Total Posting</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="28410" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="apply_jobs" data-colors='["--vz-success"]' class="apex-charts" dir="ltr">
                                        <apx-chart [series]="applyjobChart.series" [chart]="applyjobChart.chart" [plotOptions]="applyjobChart.plotOptions" [labels]="applyjobChart.labels" [colors]="applyjobChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">Open Posting</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="4305" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="new_jobs_chart" data-colors='["--vz-success"]' class="apex-charts" dir="ltr">
                                        <apx-chart [series]="newjobChart.series" [chart]="newjobChart.chart" [plotOptions]="newjobChart.plotOptions" [labels]="newjobChart.labels" [colors]="newjobChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Contract Interview</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="5021" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="interview_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="interviewChart.series" [chart]="interviewChart.chart" [plotOptions]="interviewChart.plotOptions" [labels]="interviewChart.labels" [colors]="interviewChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Total Contracted</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="3948" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="hired_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="hiredChart.series" [chart]="hiredChart.chart" [plotOptions]="hiredChart.plotOptions" [labels]="hiredChart.labels" [colors]="hiredChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="card card-animate overflow-hidden">
                        <div class="position-absolute start-0" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200" height="120">
                                <style>
                                    .s0 {
                                        opacity: .05;
                                        fill: var(--vz-success)
                                    }
                                </style>
                                <path id="Shape 8" class="s0" d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <div class="card-body" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">Contract Rejected</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <span [CountTo]="1340" class="counter-value" [from]="0" [duration]="1"></span>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <div id="rejected_chart" class="apex-charts" dir="ltr">
                                        <apx-chart [series]="rejectedChart.series" [chart]="rejectedChart.chart" [plotOptions]="rejectedChart.plotOptions" [labels]="rejectedChart.labels" [colors]="rejectedChart.colors"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end col-->
    <div class="col-xl-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">New Carriers</h4>
                <div class="flex-shrink-0">
                    <a herf="javascript:void(0);" class="btn btn-soft-primary btn-sm">View All Carriers <i class="ri-arrow-right-line align-bottom"></i>
                    </a>
                </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
                <div class="table-responsive table-card">
                    <table class="table table-centered table-hover align-middle table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-secondary rounded">
                                                <img src="assets/images/companies/img-1.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Force Medicines</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Cullera, Spain
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-warning rounded">
                                                <img src="assets/images/companies/img-3.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Syntyce Solutions</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-primary rounded">
                                                <img src="assets/images/companies/img-2.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Moetic Fashion</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="assets/images/companies/img-4.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Meta4Systems</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Germany
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="assets/images/companies/img-5.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Themesbrand</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Limestone, US
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-secondary">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-danger">
                                                <i class="ri-mail-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-primary">
                                                <i class="ri-global-line"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a herf="javascript:void(0);" class="link-info">
                                                <i class="ri-twitter-line"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <a herf="javascript:void(0);" class="btn btn-link btn-sm">View More <i class="ri-arrow-right-line align-bottom"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
                    <div class="col flex-shrink-0">
                        <div class="text-muted"> Showing <span class="fw-semibold">5</span> of <span class="fw-semibold">25</span> Results </div>
                    </div>
                    <ul class="pagination pagination-separated pagination-sm mb-0">
                        <li class="page-item disabled">
                            <a herf="javascript:void(0);" class="page-link">←</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">1</a>
                        </li>
                        <li class="page-item active">
                            <a herf="javascript:void(0);" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                            <a herf="javascript:void(0);" class="page-link">→</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="showAddButton">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div *ngIf="showAddButton">
                            <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal"
                                [disabled]="isLoading"
                                id="create-btn" (click)="openModal(content, 'add', '')">
                                <i class="ri-add-line align-bottom me-1"></i> Add
                                <span class="ms-2 spinner-border save-loading" role="status" *ngIf="isLoading">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="d-flex justify-content-sm-end">
                            <div class="search-box ms-2">
                                <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive table-card mt-3 mb-1">
                    <table class="table align-middle table-nowrap mb-0">
                        <thead class="table-light">
                            <tr>
                                <th>Title</th>
                                <th>Visibility</th>
                                <th>Route</th>
                                <th>Start Date</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let loadBoard of ListJsList$ | async" (click)="navigateDetails(loadBoard)">
                                <td>
                                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                                    <p id="title-data">{{loadBoard.title}}</p>
                                </td>
                                <td>
                                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                                    <p *ngIf="loadBoard.isPublic"> Public </p>
                                    <p *ngIf="!loadBoard.isPublic"> Private </p>
                                </td>
                                <td>
                                    <ngb-highlight [result]="loadBoard.routeName" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="loadBoard.startDate | date: 'MM/dd/yyyy'" [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                                    {{loadBoard.duration}} months
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row justify-content-md-between align-items-md-center" *ngIf="(total$ | async)!">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite"> Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end gridjs-pagination">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Post A Dedicated Load</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
        {{ error }}
    </div>
    <form [formGroup]="loadBoardForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name-field" class="form-label-visibility">Visibility: <span class="opacity-0">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" checked="" (click)="isPublic('public')">
                        <label class="btn btn-outline-primary mb-0" for="btnradio1">Public</label>
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" (click)="isPublic('private')">
                        <label class="btn btn-outline-primary mb-0" for="btnradio2">Private</label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="title" class="form-label">Title: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="title" class="form-control" required formControlName="title" [ngClass]="{ 'is-invalid': submitted && form['title'].errors }" />
                    <div *ngIf="submitted && form['title'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['title'].errors['required']">Enter Title</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="postStartDate" class="form-label">Post Start Date: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="input-group flex-nowrap" mwlFlatpickr [convertModelValue]="true" minDate="today" formControlName="postStartDate">
                        <span class="input-group-text" id="addon-wrapping">
                            <i class="ri-calendar-event-line"></i>
                        </span>
                        <input class="form-control flatpickr-input" type="text" [value]="form['postStartDate'].value | date" formControlName="postStartDate" [ngClass]="{ 'is-invalid': submitted && form['postStartDate'].errors }">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="postEndDate" class="form-label">Post End Date: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="input-group flex-nowrap" mwlFlatpickr [convertModelValue]="true" [minDate]="service.formatDateTime(form['postStartDate'].value)" formControlName="postEndDate">
                        <span class="input-group-text" id="addon-wrapping">
                            <i class="ri-calendar-event-line"></i>
                        </span>
                        <input class="form-control flatpickr-input" type="text" [value]="form['postEndDate'].value | date" formControlName="postEndDate" [ngClass]="{ 'is-invalid': submitted && form['postEndDate'].errors }">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="description" class="form-label">Description: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div>
                        <textarea class="form-control" id="exampleFormControlTextarea5" rows="3" required formControlName="description" [ngClass]="{ 'is-invalid': submitted && form['description'].errors }"></textarea>
                    </div>
                    <div *ngIf="submitted && form['description'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['description'].errors['required']">Enter Description</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="routeId" class="form-label">Route: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['routeId'].errors }" [items]="routes" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="routeId" bindLabel="name" bindValue="routeId"></ng-select>
                    <div *ngIf="submitted && form['routeId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['routeId'].errors['required']">Enter Route</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="startDate" class="form-label">Route Start Date: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="input-group flex-nowrap" mwlFlatpickr [convertModelValue]="true" formControlName="startDate">
                        <span class="input-group-text" id="addon-wrapping">
                            <i class="ri-calendar-event-line"></i>
                        </span>
                        <input class="form-control flatpickr-input" type="text" [value]="form['startDate'].value | date" formControlName="startDate" [ngClass]="{ 'is-invalid': submitted && form['startDate'].errors }">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="duration" class="form-label">Duration: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-2">
                    <input type="number" min="1" id="duration" class="form-control" required formControlName="duration" [ngClass]="{ 'is-invalid': submitted && form['duration'].errors }" />
                    <div *ngIf="submitted && form['duration'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['duration'].errors['required']">Enter Duration</div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <p id="month-label"> months </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="contactName" class="form-label">Contact Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactName" class="form-control" required formControlName="contactName" [ngClass]="{ 'is-invalid': submitted && form['contactName'].errors }" />
                    <div *ngIf="submitted && form['contactName'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['contactName'].errors['required']">Enter Contact Name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="contactEmail" class="form-label">Contact Email: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactEmail" class="form-control" formControlName="contactEmail" [ngClass]="{ 'is-invalid': submitted && form['contactEmail'].errors }" (input)="pressEventEmail()" />
                    <div *ngIf="submitted && form['contactEmail'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['contactEmail'].errors['required']">You must enter at least 1 of the following: Contact Email, Contact Phone. or Contact Link</div>
                        <div *ngIf="form['contactEmail'].errors['email']">Enter Valid Email</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="contactPhone" class="form-label">Contact Phone: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactPhone" class="form-control" formControlName="contactPhone" [ngClass]="{ 'is-invalid': submitted && form['contactPhone'].errors }" (input)="pressEventPhone()" />
                    <div *ngIf="submitted && form['contactPhone'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['contactPhone'].errors['required']">You must enter at least 1 of the following: Contact Email, Contact Phone. or Contact Link</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="contactLink" class="form-label">Contact Link: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactLink" class="form-control" formControlName="contactLink" [ngClass]="{ 'is-invalid': submitted && form['contactLink'].errors }" (input)="pressEventLink()" />
                    <div *ngIf="submitted && form['contactLink'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['contactLink'].errors['required']">You must enter at least 1 of the following: Contact Email, Contact Phone. or Contact Link</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="documentFlowId" class="form-label">Documents: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" 
                        [ngClass]="{ 'is-invalid': submitted && form['documentFlowId'].errors }" 
                        [items]="documentFlows" 
                        bindLabel="name" bindValue="documentFlowId"
                        [multiple]="false" [virtualScroll]="true" 
                        [searchFn]="searchFn" [closeOnSelect]="true" [hideSelected]="true" 
                        [clearSearchOnAdd]="true" formControlName="documentFlowId" 
                    ></ng-select>
                    <div *ngIf="submitted && form['documentFlowId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['documentFlowId'].errors['required']">Select a document</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="contactLink" class="form-label">Meeting Availability: <span class="opacity-0">*</span>
                    </label>
                </div>
                <div class="col-lg-9">
                    <div class="form-check form-switch form-switch-lg d-inline-block align-middle" dir="ltr">
                        <input type="checkbox" class="form-check-input" id="customSwitchsizelg" [(ngModel)]="isMeetingAvailabilityEdittable" [ngModelOptions]="{standalone: true}">
                    </div>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="edit()" [disabled]="!isMeetingAvailabilityEdittable">Edit</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" id="add-btn" *ngIf="modalType == 'Add'" (click)="addLoad()">Post Your Load Now</button>
            </div>
        </div>
    </form>
</ng-template>
<div class="row" *ngIf="showAddButton">
    <div class="col-xxl-8">
        <div class="card card-height-100">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Load Statistic</h4>
                <div class="hstack gap-1">
                    <button type="button" class="btn btn-soft-secondary btn-sm" (click)="setapplicationvalue('All')"> ALL </button>
                    <button type="button" class="btn btn-soft-secondary btn-sm" (click)="setapplicationvalue('1M')"> 1M </button>
                    <button type="button" class="btn btn-soft-secondary btn-sm" (click)="setapplicationvalue('6M')"> 6M </button>
                    <button type="button" class="btn btn-soft-primary btn-sm" (click)="setapplicationvalue('1Y')"> 1Y </button>
                </div>
            </div>
            <div class="card-header p-0 border-0 bg-soft-light">
                <div class="row g-0 text-center">
                    <div class="col-6 col-sm-3">
                        <div class="p-3 border border-dashed border-start-0">
                            <h5 class="mb-1">
                                <span [CountTo]="3364" class="counter-value" [from]="0" [duration]="1"></span>
                            </h5>
                            <p class="text-muted mb-0">Posting Opened</p>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3">
                        <div class="p-3 border border-dashed border-start-0">
                            <h5 class="mb-1">
                                <span [CountTo]="2804" class="counter-value" [from]="0" [duration]="1"></span>
                            </h5>
                            <p class="text-muted mb-0">Postings Saved</p>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3">
                        <div class="p-3 border border-dashed border-start-0">
                            <h5 class="mb-1">
                                <span [CountTo]="2402" class="counter-value" [from]="0" [duration]="1"></span>
                            </h5>
                            <p class="text-muted mb-0">Posting Meeting Scheduled</p>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3">
                        <div class="p-3 border border-dashed border-start-0 border-end-0">
                            <h5 class="mb-1 text-success">
                                <span [CountTo]="8" class="counter-value" [from]="0" [duration]="1"></span>k
                            </h5>
                            <p class="text-muted mb-0">Total Applications</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 pb-2">
                <div class="w-100">
                    <div id="line_chart_dashed" data-colors='["--vz-success", "--vz-info", "--vz-primary"]' class="apex-charts" dir="ltr">
                        <apx-chart [series]="dashedLineChart.series" [chart]="dashedLineChart.chart" [colors]="dashedLineChart.colors" [dataLabels]="dashedLineChart.dataLabels" [stroke]="dashedLineChart.stroke" [series]="dashedLineChart.series" [title]="dashedLineChart.title" [markers]="dashedLineChart.markers" [xaxis]="dashedLineChart.xaxis" [tooltip]="dashedLineChart.tooltip" [grid]="dashedLineChart.grid" dir="ltr"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xxl-4">
        <div class="card card-height-100">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <h6 class="card-title mb-0 flex-grow-1">Featured Carriers</h6>
                    <div class="flex-shrink-0">
                        <a routerLink="/jobs/listview" class="link-primary">View All <i class="ri-arrow-right-line"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row g-0">
                <div class="col-lg-6">
                    <div class="card-body border-end">
                        <div class="search-box">
                            <input type="text" class="form-control bg-light border-light" autocomplete="off" id="searchList" placeholder="Search candidate..." [(ngModel)]="service.searchTerm">
                            <i class="ri-search-line search-icon"></i>
                        </div>
                        <ngx-simplebar data-simplebar style="max-height: 100%;" class="px-3 mx-n3">
                            <ul class="list-unstyled mb-0 pt-2" id="candidate-list">
                                <li *ngFor="let people of candidatelist;let i = index;" (click)="opendetail(i)">
                                    <a href="javascript:void(0);" class="d-flex align-items-center py-2">
                                        <div class="flex-shrink-0 me-2">
                                            <div class="avatar-xs">
                                                <img src="{{people.image}}" alt="" class="img-fluid rounded-circle candidate-img">
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h5 class="fs-13 mb-1 text-truncate">
                                                <span class="candidate-name">{{people.name}}</span>
                                                <span class="text-muted fw-normal"> @{{people.insta}}</span>
                                            </h5>
                                            <div class="d-none candidate-position">{{people.designation}}</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ngx-simplebar>
                    </div>
                </div>
                <div *ngIf="candidatedetail" class="col-lg-6">
                    <div class="card-body text-center">
                        <div class="avatar-md mb-3 mx-auto">
                            <img src="{{candidatedetail.image}}" alt="" id="candidate-img" class="img-thumbnail rounded-circle shadow-none">
                        </div>
                        <h5 id="candidate-name" class="mb-0">{{candidatedetail.name}}</h5>
                        <p id="candidate-position" class="text-muted">{{candidatedetail.designation}}</p>
                        <div class="d-flex gap-2 justify-content-center mb-3">
                            <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Google">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-google-line"></i>
                                </span>
                            </button>
                            <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Linkedin">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-linkedin-line"></i>
                                </span>
                            </button>
                            <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Dribbble">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-dribbble-fill"></i>
                                </span>
                            </button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-success custom-toggle w-100" id="togglefollow" (click)="followClick($event)">
                                <span class="icon-on">
                                    <i class="me-1" [ngClass]="(followbtn == '1')?'ri-add-line align-bottom':'ri-user-unfollow-line align-bottom'"></i>
                                    {{followtxt}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row pad-btm" *ngIf="!showAddButton">
    <app-carrier-load-board></app-carrier-load-board>
</div>