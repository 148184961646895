import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { getDateDisplay } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { TableData } from '../../carrier-load-board/carrier-load-board.component';


@Component({
  selector: 'ngbd-modal-load-board-details',
  templateUrl: './load-board-details-modal.component.html',
  styleUrls: ['./load-board-details-modal.component.scss'],

})
export class NgbdModalLoadBoardDetails implements OnInit{
    @Input() public loadData: TableData;
    message: string;
    isLoading: boolean = false;
   
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private auth: AuthService,
        private httpRequest: HttpService
    ) {}

    ngOnInit(): void {
        console.log(this.loadData);
    }

    close() {
        this.activeModal?.close();
    }

    openModalAddMessage(event: any, content: any) {
        this.activeModal?.close();
        console.log(event);
        this.activeModal = this.modalService.open(content, { size: 'md', centered: true });
    }

    apply() {
        this.isLoading = true;
        this.httpRequest.createProposal(
            {
                loadId: this.loadData.loadId,
                message: this.message
            } as any
        ).subscribe(data => {
            Swal.fire({
                title: 'Success',
                text: 'A proposal has been submitted.',
                icon: 'success',
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
                timerProgressBar: false,
            }).then((result: any) => {
                this.isLoading = false;
                this.close();
            });
        }, error => {
            Swal.fire({
                title: 'Error',
                text: error.error.reason,
                icon: 'warning',
                timer: 3000,
                showCancelButton: false,
                showConfirmButton: false,
                timerProgressBar: false,
            }).then((result: any) => {
                this.isLoading = false;
                this.close();
            });
        })
    }

    getTimestamp(date: string) {
        return Date.parse(date);
    }

    getDurationRange(tableData: TableData): string{
        const startDate = new Date(tableData.startDate);
        const endDate = new Date(tableData.startDate);
        endDate.setMonth(endDate.getMonth() + (tableData.duration ?? 0))
    
        return `${getDateDisplay(startDate)} - ${getDateDisplay(endDate)}`;
      }
}