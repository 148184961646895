<div class="row">
    <div class="col-lg-12">
        <div class="card" [ngStyle]="{'box-shadow': showCard ? '0 1px 2px rgb(56 65 74 / 15%)': 'none'}">
            <div class="card-body">
                <form [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row mb-3">
                                <div class="col-lg-4 user-form-label text-align-right">
                                    <label for="nameInput">Company Name: <span class="opacity-0">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="firtname-field" class="form-control" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }" />
                                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['name'].errors['required']">Enter Company Name</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row mb-3">
                                <div class="col-lg-3 user-form-label text-align-right">
                                    <label for="dotNumberInput">DOT Number: <span class="opacity-0">*</span></label>
                                </div>
                                <div class="col-lg-9">
                                    <input type="text" id="firtname-field" class="form-control" required formControlName="dotNumber" [ngClass]="{ 'is-invalid': submitted && form['dotNumber'].errors }" />
                                    <div *ngIf="submitted && form['dotNumber'].errors" class="invalid-feedback" align="left">
                                        <div *ngIf="form['dotNumber'].errors['required']">Enter DOT Number</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row mb-3">
                                <div class="col-lg-4 user-form-label text-align-right">
                                    <label for="firstnameInput">Serviceable ZIP Codes: <span class="opacity-0">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <app-zipcode-picker [name]="'data'" [customClass]="'custom form-control ' + (submitted && form['zipCodes'].errors ? 'is-invalid' : '')" [control]="$any(form['zipCodes'])" [isShowError]="!!(submitted && form['zipCodes'].errors)"></app-zipcode-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row mb-3">
                                <div class="col-lg-3 user-form-label text-align-right">
                                    <label for="name-field">Authority: <span class="opacity-0">*</span></label>
                                </div>
                                <div class="col-lg-9 user-form-label input-form has-authority">
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" checked="" (click)="isActive('active')">
                                        <label class="btn btn-outline-primary mb-0" for="btnradio1">Active</label>

                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" (click)="isActive('inactive')">
                                        <label class="btn btn-outline-primary mb-0" for="btnradio2">Inactive</label>
                                    </div>
                                    <!-- <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submitted && form['hasAuthority'].errors }" [items]="hasAuthorityData" [multiple]="false" [virtualScroll]="true" [searchFn]="customSearchFn" [closeOnSelect]="true" [hideSelected]="true"
                                        [clearSearchOnAdd]="true" formControlName="hasAuthority" bindLabel="prettyName" bindValue="technicalName"></ng-select> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="hstack gap-2 justify-content-start" (click)="tableHelper.loadRecords()">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="table-responsive table-card mt-3 mb-1">
                    <table class="table align-middle table-nowrap mb-0">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Company Name</th>
                                <th scope="col">Serviceable ZIP Codes</th>
                                <th scope="col">Authority</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="tableHelper.searchResult.length != 0">
                            <tr *ngFor="let res of tableHelper.searchResult">
                                <td (click)="openModal(content, res, 'view')" class="clickable">{{ res.name }}</td>
                                <td (click)="openModal(content, res, 'view')">
                                    <ng-container *ngIf="res.zipCodes">
                                        {{(res.zipCodes | slice:0:2).join(', ')}}
                                        <span *ngIf="res.zipCodes.length > 2" [title]="res.zipCodes"> 
                                                , +{{res.zipCodes.length - 2}} more
                                            </span>
                                    </ng-container>
                                </td>
                                <td (click)="openModal(content, res, 'view')" *ngIf="res.hasAuthority">Active</td>
                                <td (click)="openModal(content, res, 'view')" *ngIf="!res.hasAuthority">Inactive</td>
                                <td>
                                    <div class="d-flex gap-2">
                                        <!-- <div class="view">
                                            <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" (click)="openModal(content, res, 'view')">View</button>
                                        </div> -->
                                        <div class="invite">
                                            <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" (click)="openModal(invite, res, 'invite')">Invite</button>
                                        </div>
                                        <div class="block">
                                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="block()">Block</button>
                                        </div>
                                        <div *ngIf="res.hasSigners">
                                            <button class="btn btn-sm btn-primary" (click)="goToCreateContract(res.accountId || '')">Create Contract</button>
                                        </div>
                                        <div *ngIf="res.hasChatters">
                                            <button class="btn btn-sm btn-primary" routerLink="/pages/messages" [queryParams]="{newChannel: true, carrierAccountId: res.accountId}">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-table-pagination [tableHelper]="tableHelper"></app-table-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">View</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="container">
        <div class="table-responsive" *ngIf="companyInformation">
            <table class="table table-borderless mb-0">
                <tbody>
                    <tr>
                        <th class="ps-0" scope="row">Legal Name :</th>
                        <td class="text-muted">{{ companyInformation.legalName }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">DBA Name :</th>
                        <td class="text-muted">{{ companyInformation.dbaName }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Street :</th>
                        <td class="text-muted">{{ companyInformation.street }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">City :</th>
                        <td class="text-muted">{{ companyInformation.city }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">State :</th>
                        <td class="text-muted">{{ companyInformation.state }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Zip Code :</th>
                        <td class="text-muted">{{ companyInformation.zipCodes }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">DOT Number :</th>
                        <td class="text-muted">{{ companyInformation.dotNumber }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">MC Number :</th>
                        <td class="text-muted">{{ companyInformation.mcNumber }}</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Authority :</th>
                        <td class="text-muted" *ngIf="companyInformation.hasAuthority">Active</td>
                        <td class="text-muted" *ngIf="!companyInformation.hasAuthority">Inactive</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Type :</th>
                        <td class="text-muted" *ngIf="companyInformation.type == 'carrier-owner-operator'">Owner Operator</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'carrier-fleet'">Fleet</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-retail'">Retail</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-government'">Government</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-carrier'">Shipper (Carrier)</td>
                        <td class="text-muted" *ngIf="companyInformation.type == 'shipper-broker'">Shipper (Broker)</td>
                    </tr>
                    <tr>
                        <th class="ps-0" scope="row">Legal Entity :</th>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'sole-proprietor'">Sole Proprietor</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'llc'">LLC</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 'c-corp'">C Corporation</td>
                        <td class="text-muted" *ngIf="companyInformation.legalEntity == 's-corp'">S Corporation</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #invite role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="loadDetails">Invite "{{carrierName}}" To "{{ loadDetails.title }}"</h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="!loadDetails">Invite "{{carrierName}}"</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="errorInvite != ''">
        {{ errorInvite }}
    </div>
    <form [formGroup]="inviteForm">
        <div class="modal-body">
            <div class="row mb-3" *ngIf="!loadDetails">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="load" class="form-label">Load: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <ng-select name="data" class="custom form-control" [ngClass]="{ 'is-invalid': submittedInvite && formInvite['loadId'].errors }" [items]="loads" [multiple]="false" [virtualScroll]="true" [searchFn]="searchFn" [closeOnSelect]="true" [clearSearchOnAdd]="true"
                        formControlName="loadId" bindLabel="title" bindValue="loadId" (change)="selectLoad($event)"></ng-select>
                    <div *ngIf="submittedInvite && formInvite['loadId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formInvite['loadId'].errors['required']">Enter Load</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="loadDetails">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="load" class="form-label">Load: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p id="name-value">
                        {{loadDetails.title}}
                    </p>
                </div>
            </div>
            <div class="row mb-3" *ngIf="selectedRouteName">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="load" class="form-label">Route: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p id="name-value">
                        {{selectedRouteName}}
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="message" class="form-label">Message: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <textarea class="form-control" id="exampleFormControlTextarea5" rows="3" required formControlName="message" [ngClass]="{ 'is-invalid': submittedInvite && formInvite['message'].errors }"></textarea>
                    <div *ngIf="submittedInvite && formInvite['message'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="formInvite['message'].errors['required']">Enter Message</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary" id="add-btn" (click)="inviteRoute()">Send</button>
        </div>
    </div>
</ng-template>