<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header p-3">
                <h5 class="modal-title" id="exampleModalLabel">Add {{urlVehicleType === TABS.Trucks ? 'Truck' : urlVehicleType === TABS.Trailers ? 'Trailer' : 'Combo'}}</h5>
            </div>
            <div class="card-body p-5">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="name" class="form-label">Name: <span class="opacity-0">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="name" class="form-control" name="name" formControlName="name" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="btnradioIsInService" class="form-label select-button-toggle">Service Status: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" [value]="false" name="isInService" id="btnradioIsInServiceFalse" formControlName="isInService" checked>
                                <label class="btn btn-outline-primary mb-0" for="btnradioIsInServiceFalse">Inservice</label>
                                <input type="radio" class="btn-check" [value]="true" name="isInService" id="btnradioIsInServiceTrue" formControlName="isInService">
                                <label class="btn btn-outline-primary mb-0" for="btnradioIsInServiceTrue">Out of Service</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="typeInput" class="form-label">Vehicle Type: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" bindLabel="name" [bindValue]="urlVehicleType === TABS.Trucks ? 'truckTypeId' : 'trailerTypeId'" formControlName="vehicleType" [items]="vehicleTypeOptions" [clearable]="false" [ngClass]="{ 'is-invalid': submitted && createForm.vehicleType.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.vehicleType.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.vehicleType.errors['required']">Select vehicle type</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="typeInput" class="form-label">Ownership Type: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" [items]="ownershipTypeOptions" [clearable]="false" formControlName="ownershipType" [ngClass]="{ 'is-invalid': submitted && createForm.ownershipType.errors }" (change)="onOwnershipTypeChange($event)"></ng-select>
                            <div *ngIf="submitted && createForm.ownershipType.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.ownershipType.errors['required']">Select ownership type</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="vendorName" class="form-label">Vendor Name: <span class="opacity-0">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="vendorName" class="form-control" name="vendorName" formControlName="vendorName" [ngClass]="{ 'is-invalid': submitted && createForm.vendorName.errors }" />
                            <div *ngIf="submitted && createForm.vendorName.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.vendorName.errors['required']">Enter vendor name</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="postStartDate" class="form-label" [ngSwitch]="createForm.ownershipType.value">
                                <span *ngSwitchCase="truckOwnershipTypeEnum.Purchased">Purchase Date: </span>
                                <span *ngSwitchCase="truckOwnershipTypeEnum.Leased">Lease Start Date: </span>
                                <span *ngSwitchCase="truckOwnershipTypeEnum.Rented">Rental Start Date: </span>
                                <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="input-group flex-nowrap">
                                <span class="input-group-text">
                                    <i class="ri-calendar-event-line"></i>
                                </span>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="F j, Y" dateFormat="Y-m-d" formControlName="dateAcquired" [ngClass]="{ 'is-invalid': submitted && createForm.dateAcquired.errors }" />
                            </div>
                            <div *ngIf="submitted && createForm.dateAcquired.errors" class="invalid-feedback row mx-0" align="left" style="display: block;">
                                <div *ngIf="createForm.dateAcquired.errors['required']" class="ps-0 px-0">Select date</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="createForm.ownershipType.value !== truckOwnershipTypeEnum.Purchased">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="term" class="form-label">Term: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="input-group row mx-0">
                                <input type="number" class="form-control col-lg-6" aria-label="Text input with dropdown button" formControlName="termNumber" [ngClass]="{ 'is-invalid': submitted && createForm.termNumber.errors }" />
                                <ng-select class="custom form-control col-lg-6" [items]="termOptions" [clearable]="false" formControlName="termUnits" [ngClass]="{ 'is-invalid': submitted && createForm.termUnits.errors }"></ng-select>
                            </div>
                            <div class="row mx-0">
                                <div *ngIf="submitted && createForm.termNumber.errors" class="invalid-feedback col-lg-6 ps-0 px-0" align="left" style="display: block;">
                                    <div *ngIf="createForm.termNumber.errors['required']" class="">Enter term number</div>
                                </div>
                                <div *ngIf="submitted && createForm.termUnits.errors" class="invalid-feedback col-lg-6 ps-0 px-0" align="left" style="display: block;">
                                    <div *ngIf="createForm.termUnits.errors['required']" class="">Select units</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="unitNumber" class="form-label">Unit #: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="number" id="unitNumber" class="form-control" name="unitNumber" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitted && createForm.unitNumber.errors }" />
                            <div *ngIf="submitted && createForm.unitNumber.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.unitNumber.errors['required']">Enter unit #</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="year" class="form-label">Year: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input id="year" class="form-control" name="year" formControlName="year" mask="0000" [ngClass]="{ 'is-invalid': submitted && createForm.year.errors }" />
                            <div *ngIf="submitted && createForm.year.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.year.errors['required']">Enter year</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="make" class="form-label">Make: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select id="make" class="custom form-control" [items]="makeOptions" [clearable]="false" bindLabel="Make " bindValue="Make " (change)="onMakeChange($event)" formControlName="make" [ngClass]="{ 'is-invalid': submitted && createForm.make.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.make.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.make.errors['required']">Select make</div>
                            </div>
                            <input type="text" class="form-control mt-2" formControlName="makeOther" [class.visually-hidden]="createForm.make.value !== 'Other'" [ngClass]="{ 'is-invalid': submitted && createForm.makeOther.errors }" placeholder="Enter Make" />
                            <div *ngIf="submitted && createForm.makeOther.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.makeOther.errors['required']">Enter make</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="model" class="form-label">Model: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select id="model" class="custom form-control" [items]="modelOptions" [clearable]="false" formControlName="model" [ngClass]="{ 'is-invalid': submitted && createForm.model.errors }" (change)="onModelChange($event)"></ng-select>
                            <div *ngIf="submitted && createForm.model.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.model.errors['required']">Select model</div>
                            </div>
                            <input type="text" class="form-control mt-2" formControlName="modelOther" [class.visually-hidden]="createForm.model.value !== 'Other'" [ngClass]="{ 'is-invalid': submitted && createForm.modelOther.errors }" placeholder="Enter Model" />
                            <div *ngIf="submitted && createForm.modelOther.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.modelOther.errors['required']">Enter model</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="vin" class="form-label">VIN: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="vin" class="form-control" name="vin" formControlName="vin" [ngClass]="{ 'is-invalid': submitted && createForm.vin.errors }" />
                            <div *ngIf="submitted && createForm.vin.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.vin.errors['required']">Enter VIN</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="licensePlate" class="form-label">License Plate: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="licensePlate" class="form-control" name="licensePlate" formControlName="licensePlate" [ngClass]="{ 'is-invalid': submitted && createForm.licensePlate.errors }" />
                            <div *ngIf="submitted && createForm.licensePlate.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.licensePlate.errors['required']">Enter license plate</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="licensePlateState" class="form-label">License Plate State: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" [items]="stateOptions" [clearable]="false" bindLabel="techName" bindValue="techName" formControlName="licensePlateState" [ngClass]="{ 'is-invalid': submitted && createForm.licensePlateState.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.licensePlateState.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.licensePlateState.errors['required']">Enter license plate state</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="eldAobrdCompany" class="form-label">
                                ELD/AOBRD Company: 
                                <span [ngClass]="urlVehicleType === TABS.Trailers ? 'opacity-0' : 'text-danger'">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" [items]="eldCompanyOptions" [clearable]="true" formControlName="eldAobrdCompany" (change)="onEldCompanyChange($event)" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdCompany.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.eldAobrdCompany.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdCompany.errors['required']">Select company</div>
                            </div>
                            <input type="text" class="form-control mt-2" formControlName="eldAobrdCompanyOther" [class.visually-hidden]="createForm.eldAobrdCompany.value !== 'Other'" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdCompanyOther.errors }" placeholder="Enter ELD/AOBRD Company" />
                            <div *ngIf="submitted && createForm.eldAobrdCompanyOther.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdCompanyOther.errors['required']">Enter company</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="eldAobrdMake" class="form-label">ELD/AOBRD Make: <span class="opacity-0">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" [items]="eldMakeOptions" [clearable]="true" formControlName="eldAobrdMake" (change)="onEldMakeChange($event)" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdMake.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.eldAobrdMake.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdMake.errors['required']">Select make</div>
                            </div>
                            <input type="text" class="form-control mt-2" formControlName="eldAobrdMakeOther" [class.visually-hidden]="createForm.eldAobrdMake.value !== 'Other'" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdMakeOther.errors }" placeholder="Enter ELD/AOBRD Make" />
                            <div *ngIf="submitted && createForm.eldAobrdMakeOther.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdMakeOther.errors['required']">Enter make</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="eldAobrdModel" class="form-label">ELD/AOBRD Model: <span class="opacity-0">*</span></label>
                        </div>
                        <div class="col-lg-9">
                            <ng-select class="custom form-control" [items]="eldModelOptions" [clearable]="true" 
                                formControlName="eldAobrdModel" (change)="onEldModelChange($event)" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdModel.errors }"></ng-select>
                            <div *ngIf="submitted && createForm.eldAobrdModel.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdModel.errors['required']">Select model</div>
                            </div>
                            <input type="text" class="form-control mt-2" formControlName="eldAobrdModelOther" [class.visually-hidden]="createForm.eldAobrdModel.value !== 'Other'" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdModelOther.errors }" placeholder="Enter ELD/AOBRD Model" />
                            <div *ngIf="submitted && createForm.eldAobrdModelOther.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdModelOther.errors['required']">Enter model</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="eldAobrdYear" class="form-label">ELD/AOBRD Year: <span class="opacity-0">*</span></label>
                        </div>
                        <div class="col-lg-9">
                            <input id="eldAobrdYear" class="form-control" name="eldAobrdYear" formControlName="eldAobrdYear" mask="0000" [ngClass]="{ 'is-invalid': submitted && createForm.eldAobrdYear.errors }" />
                            <div *ngIf="submitted && createForm.eldAobrdYear.errors" class="invalid-feedback" align="left">
                                <div *ngIf="createForm.eldAobrdYear.errors['required']">Enter year</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="d-flex gap-2 p-4 justify-content-end">
                    <div>
                        <button type="button" class="btn btn-light" routerLink="/pages/routes">
                            <div class="inline-block"> Cancel </div>
                        </button>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary action-btn" (click)="onSubmit()">
                            <div class="inline-block"> Save </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>