<div class="row g-4 mb-3">
    <div class="col-sm-auto">
        <div>
            <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" (click)="onClickAddCombo(createComboModal)">
                <i class="ri-add-line align-bottom me-1"></i> Add Combo </button>
        </div>
    </div>
    <div class="col-sm">
        <div class="d-flex justify-content-sm-end">
            <div class="search-box ms-2">
                <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive table-card mt-3 mb-1">
    <table class="table align-middle table-nowrap mb-0" *ngIf="allVehicles.length != 0">
        <thead class="table-light">
            <tr>
                <th class="sort">Name</th>
                <th class="sort">Truck</th>
                <th class="sort">Trailer</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of allVehicles">
                <td>
                    <ngb-highlight [result]="vehicle.name + ''" [term]="searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.truckId" [term]="searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.trailerId1" [term]="searchTerm"></ngb-highlight>
                </td>
                <td>
                    <div class="d-flex gap-2">
                        <!-- <div class="edit"><button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal" (click)="openModal(content, 'edit', vehicle)">Edit</button></div> -->
                        <div class="remove">
                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteVehicles(vehicle.truckTrailerComboId)">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #createComboModal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title">{{modalType === 'Create' ? 'New' : 'Edit'}} Combo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="alert alert-danger user-added" role="alert" *ngIf="error != ''">
        {{ error }}
    </div>
    <form [formGroup]="createForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label padding-top no-wrap text-align-right">
                    <label for="input-name" class="form-label">Name: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <input type="text" id="input-name" class="form-control" required formControlName="name" 
                        [ngClass]="{ 'is-invalid': submitted && $any(createFormControls['name']).errors }" />
                    <div *ngIf="submitted && $any(createFormControls['name']).errors" class="invalid-feedback" align="left">
                        <div *ngIf="$any(createFormControls['name']).errors['required']">Enter Name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label padding-top no-wrap text-align-right">
                    <label for="input-truck" class="form-label">Truck: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <ng-select class="custom form-control" [ngClass]="{ 'is-invalid': submitted && createFormControls['truckId'].errors }" [items]="truckOptions" [multiple]="false" [closeOnSelect]="true" [hideSelected]="true" [clearSearchOnAdd]="true" formControlName="truckId" bindLabel="name" bindValue="truckId" [clearable]="true"></ng-select>
                    <div *ngIf="submitted && createFormControls['truckId'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="createFormControls['truckId'].errors['required']">Select Truck</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2 user-form-label padding-top no-wrap text-align-right">
                    <label for="input-trailer" class="form-label">Trailer: <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-lg-10">
                    <ng-select class="custom form-control" [ngClass]="{ 'is-invalid': submitted && createFormControls['trailerId1'].errors }" [items]="trailerOptions" [multiple]="false" formControlName="trailerId1" bindLabel="name" bindValue="trailerId" [clearable]="true"></ng-select>
                    <div *ngIf="submitted && createFormControls['trailerId1'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="createFormControls['trailerId1'].errors['required']">Select Trailer</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
                <button type="submit" class="btn btn-primary" id="save-btn" (click)="saveCombo()">Save</button>
            </div>
        </div>
    </form>
</ng-template>