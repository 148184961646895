import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeCheckoutOverlayComponent } from '../stripe-checkout-overlay/stripe-checkout-overlay.component';

@Component({
  selector: 'app-owner-operator',
  templateUrl: './owner-operator.component.html',
  styleUrls: ['./owner-operator.component.scss']
})
export class OwnerOperatorComponent implements OnInit {

  @ViewChild('stripeCheckoutOverlay') stripeCheckoutOverlayComponent!: StripeCheckoutOverlayComponent;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  openStripeCheckoutOverlay(): void {
    this.stripeCheckoutOverlayComponent.openModal();
  }
}
