import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleModel } from 'src/app/core/services/models/vehicles.model';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { NgbdGridJsSortableHeader, SortEvent } from 'src/app/core/services/vehicles-service/gridjs-sortable.directive';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TruckModel } from 'src/app/core/services/models/models';
import { removeFromArray } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehicle-trucks-tab',
  templateUrl: './vehicle-trucks-tab.component.html',
  styleUrls: ['./vehicle-trucks-tab.component.scss']
})
export class VehicleTrucksTabComponent implements OnInit {
  submitted = false;
  vehicleForm!: FormGroup;
  modalType = '';
  error = '';
  activeModal?: NgbModalRef;
  selectedVehicle: any;

  vehicleTypesData: any = [];
  allVehicles: TruckModel[] = [];

  @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;

  constructor(
    public service: VehiclesService,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.initForms();
    this.getAllVehicles();
    fetch('./assets/jsons/vehicle-types.json').then(res => res.json()).then(jsonData => {
      this.vehicleTypesData = [...new Set(jsonData)];
    });
  }

  initForms() {
    this.vehicleForm = this.formBuilder.group({
      unitNumber: ['', [Validators.required]],
      year: ['', [Validators.required, Validators.maxLength(4)]],
      make: ['', [Validators.required]],
      model: ['', [Validators.required]],
      vin: ['', [Validators.required]],
      licensePlate: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });

  }
  get form() {
    return this.vehicleForm.controls;
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  openModal(content: any, type: string, data: any) {
    if(type == 'add') {
      this.modalType = 'Add';
    } else {
      this.modalType = 'Edit';
      this.selectedVehicle = data;
      this.vehicleForm.controls['unitNumber'].setValue(data.unitNumber);
      this.vehicleForm.controls['year'].setValue(data.year);
      this.vehicleForm.controls['make'].setValue(data.make);
      this.vehicleForm.controls['model'].setValue(data.model);
      this.vehicleForm.controls['vin'].setValue(data.vin);
      this.vehicleForm.controls['licensePlate'].setValue(data.licensePlate);
      this.vehicleForm.controls['type'].setValue(data.type);
    }
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    });
    
  }

  getAllVehicles() {
    this.httpRequest.listTrucks().subscribe((data) => {
      this.allVehicles = data;
    })
  }

  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  addVehicle() {
    this.submitted = true;
    if(this.vehicleForm.invalid) {
      return;
    } else {
      this.vehicleForm.controls['year'].setValue(Number(this.vehicleForm.controls['year'].value));
      this.httpRequest.addVehicles(this.vehicleForm.value).subscribe((data) => {
        this.activeModal?.close();
        this.initForms();
        this.submitted = false;
        this.service.updateTable();
      })
    }
  }

  editVehicle() {
    this.submitted = true;
    if(this.vehicleForm.invalid) {
      return;
    } else {
      this.vehicleForm.controls['year'].setValue(Number(this.vehicleForm.controls['year'].value));
      this.httpRequest.updateVehicle(this.selectedVehicle.vehicleId, this.vehicleForm.value).subscribe((data) => {
        this.activeModal?.close();
        this.initForms();
        this.submitted = false;
        this.service.updateTable();
      },
      error => {
        this.error = error.error.reason;
      })
    }
  }

  deleteVehicles(truck: TruckModel) {
    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + truck.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.httpRequest.deleteTruck(truck.truckId).subscribe((data) => {
          this.allVehicles = removeFromArray(this.allVehicles, (a) => a.truckId === truck.truckId);
        });
      }
    });
    
  }
}
