import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http-service';
import { ToastService } from 'src/app/services/toast-service';
import Swal from 'sweetalert2';
import { InvoiceService } from 'src/app/core/services/invoices-service/invoices.service';
import { InvoiceDisplay } from 'src/app/core/services/models/invoice.model';
import { BillingProfile } from 'src/app/core/services/models/billing-profile.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [InvoiceService, DecimalPipe]

})
export class InvoicesComponent implements OnInit {
    @Input()
    billingProfileId: string;
    @Input()
    mainPage = true;

    billingProfiles: BillingProfile[] = [];
    downloading = false;
    loading = false;
    tableLoading = true;
    sendingEmail = false;
    selectedEmailIndex = 0;
    selectedIndex = 0;
    selectedIndexView = 0;
    activeModal?: NgbModalRef;
    pdfSrc: any;

    ListJsList$: Observable<InvoiceDisplay[]>;
    total$: Observable<number>;

    constructor(
        private modalService: NgbModal,
        private httpRequest: HttpService,
        public toastService: ToastService,
        private router: Router,
        public config: NgbDropdownConfig,
        public service: InvoiceService,
    ) {
      this.ListJsList$ = service.invoice$;
      this.total$ = service.total$;
    }

    ngOnInit(): void {

      if(this.mainPage){
        this.fetchBillingProfiles();
      }else{
        this.updateTable();
      }
      
    }

    private fetchBillingProfiles(): void {
      this.httpRequest.listBillingProfiles().subscribe(
          res => {
              const successRes = <SuccessApiResponse<BillingProfile[]>> res;
              this.billingProfiles = successRes.data;
              this.billingProfileId = this.billingProfiles?.[0]?.billingProfileId;
              this.updateTable();
          }
      );
    }

    updateTable(){
      this.tableLoading = true;
      this.service.updateTable(this.billingProfileId).then(
        ()=>this.tableLoading = false
      );
    }

    navigateBilling(data: any) {
        this.router.navigateByUrl('/pages/invoice', {state: {invoiceDetails: data}});
      }
    
      downloadPDF(invoiceData: any, index: any) {
        this.selectedIndex = index;
        this.downloading = true;
        this.httpRequest.downloadFilePDF(invoiceData.invoiceId).subscribe((data:any) => {
          this.showPdf(data, invoiceData);
        }),
        (error:any) => console.log("Error downloading the file."), () => console.log("Error");
      }
    
      showPdf(data: any, invoiceData: any) {
        let binary = '';
        let bytes = new Uint8Array( data );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] );
        }
        const linkSource =
          'data:application/octet-stream;base64,' + binary;
        const downloadLink = document.createElement('a');
        const fileName = 'dtc-invoice-' + invoiceData.invoiceNumber +'.pdf';
        downloadLink.href = linkSource;
        this.downloading = false;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    
      openViewInvoiceModal(invoiceData: any, content: any, index: any) {
        this.loading = true;
        this.selectedIndexView = index;
        this.httpRequest.downloadFilePDF(invoiceData.invoiceId).subscribe((data:any) => {
          let binary = '';
          let bytes = new Uint8Array( data );
          let len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
          }
          this.loading = false;
          this.pdfSrc = this._base64ToArrayBuffer(binary);
          this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
          this.activeModal.result.finally(() => { });
        }),
        (error:any) => console.log("Error downloading the file."), () => console.log("Error");
      }
    
      _base64ToArrayBuffer(base64: any) {
          var binary_string = base64.replace(/\\n/g, '');
        binary_string =  window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array( len );
        for (var i = 0; i < len; i++)        {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
      }
    
      resendEmail(invoiceData: any, index: number) {
        this.sendingEmail = true;
        this.selectedEmailIndex = index;
        this.httpRequest.sendInvoiceEmail(invoiceData.invoiceId).subscribe((data: any) => {
          this.sendingEmail = false;
          this.selectedEmailIndex = 0;
          Swal.fire({
            title: 'Success',
            text: 'Successfully sent an invoice email',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            
          });
        }, error => {
          this.sendingEmail = false;
          this.selectedEmailIndex = 0;
          Swal.fire({
            title: 'Error',
            text: 'Email send failed.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            
          });
        }
      );
    }

    billingProfileIdChanged(){
      this.updateTable();
    }
}