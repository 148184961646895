import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-call',
  templateUrl: './schedule-call.component.html',
  styleUrls: ['./schedule-call.component.scss']
})
export class ScheduleCallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
