import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { FmcsaService } from 'src/app/services/fmcsa.service';
import Swal from 'sweetalert2';
import { NgbdModalContactInformation } from './modals/contact-information/contact-information.component';
import { NgbdModalGenaralCompanyInformation } from './modals/general-company-information/general-company-information.component';
import { NgbdModalTransportationCompanyInformation } from './modals/transportation-company-information/transportation-company-information.component';


@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})

/**
 * Profile Settings Component
 */
export class CompanySettingsComponent implements OnInit {
  haveDOTNumber = false;
  breadCrumbItems!: Array<{}>;
  currentUser: any;
  role: any;
  companyInformation: any;
  companyInfoForm!: FormGroup;
  isLoading = false;
  isLookingUp = false;

  activeModal?: NgbModalRef;

  legalEntityOptions = [
    { name: 'LLC', value: 'llc' },
    { name: 'C Corporation', value: 'c-corp' },
    { name: 'S Corporation', value: 's-corp' }
  ];
  shipperTypeOptions = [
    { name: 'Retail', value: 'shipper-retail' },
    { name: 'Shipper (Carrier)', value: 'shipper-carrier' },
    { name: 'Shipper (Broker)', value: 'shipper-broker' },
    { name: 'Government', value: 'shipper-government' }
];
haveDOTNumberOptions = [
    { name: "I don't have a DOT Number", value: 'false' },
    { name: 'I have a DOT Number', value: 'true' }
];
  submitted = false;

  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);
  companyInfo?: CompanyInfo;

  constructor(
    private httpRequest: HttpService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.currentUserValue;
    this.breadCrumbItems = [];
    // this.getProfile();
    this.initForm('');
    // this.getRole();
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.fetchCompanyInfo();
        }
      )
    );
  }

  private fetchCompanyInfo(): void {
    this.httpRequest.getCompanyInfos(this.selectedBranch?.companyInfoId!).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        this.companyInfo = successRes.data;
        console.log(this.companyInfo);
      },
      error =>{
        console.error('Failed to fetch company info: ' + error.error.reason);
        this.companyInfo = undefined;
        // Swal.fire({
        //   title: 'Error',
        //   text: 'Failed to fetch company info: ' + error.error.reason,
        //   icon: 'warning',
        //   showCancelButton: false,
        //   confirmButtonColor: 'rgb(60,76,128)',
        //   confirmButtonText: 'Ok',
        // }).then((result) => {
        //   //do nothing
        // });
      }
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }


  initForm(data: any) {
    if(data) {
      this.companyInfoForm = this.formBuilder.group({
        legalEntity: [data.legalEntity, [Validators.required]],
        type: [data.type, [Validators.required]],
        dotNumber: [data.dotNumber],
        phone: [data.phone, [Validators.required]],
        email: [data.email, [Validators.required, Validators.email]],
        einNumber: [data.einNumber],
        legalName: [data.legalName, [Validators.required]],
        dbaName: [data.dbaName],
        mcNumber: [data.mcNumber],
        street: [data.street, [Validators.required]],
        city: [data.city, [Validators.required]],
        state: [data.state, [Validators.required]],
        zipCode: [data.zipCode, [Validators.required]],
        hasAuthority: [data.hasAuthority],
        isBroker: [data.isBroker],
        haveDOTNumber: ['false']
      })
    } else {
      this.companyInfoForm = this.formBuilder.group({
        legalEntity: ['', [Validators.required]],
        type: ['', [Validators.required]],
        haveDOTNumber: 'false',
        dotNumber: [],
        phone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        einNumber: [''],
        legalName: ['', [Validators.required]],
        dbaName: [''],
        mcNumber: [''],
        street: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        hasAuthority: [false],
        isBroker: [false],
      })
    }
  }

  get companyInfoControl() {
    return this.companyInfoForm.controls;
  } 

  getProfile() {
    this.httpRequest.get('companies/' + this.auth.currentAccountSelected.accountId).subscribe((data: any) => {
      this.initForm(data.data);
      this.companyInformation = data.data;
      if(this.companyInformation.dotNumber) {
        this.haveDOTNumber = true;
      } else {
        this.haveDOTNumber = false;
      }
    })
  }

  setCompanyInfo(data: any) {
    this.companyInfoControl['legalName'].setValue(data.legalName);
    this.companyInfoControl['dbaName'].setValue(data.dbaName);
    this.companyInfoControl['mcNumber'].setValue(data.mcNumber);
    this.companyInfoControl['street'].setValue(data.street);
    this.companyInfoControl['city'].setValue(data.city);
    this.companyInfoControl['state'].setValue(data.state);
    this.companyInfoControl['zipCode'].setValue(data.zipcode);
    this.companyInfoControl['hasAuthority'].setValue(data.hasAuthority);
    this.companyInfoControl['isBroker'].setValue(data.isBroker);
    this.companyInfoControl['einNumber'].setValue(data.einNumber);
  }

  openModalGeneralInformation() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalGenaralCompanyInformation, { size: 'md', centered: true, windowClass: 'general-info' });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInfoForm.value;
  }

  openModalTransportationInformation() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalTransportationCompanyInformation, { size: 'md', centered: true,  windowClass: 'transpo-info' });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInfoForm.value;
  }

  openModalContactInformation(type: any) {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalContactInformation, { size: 'md', centered: true, windowClass: 'contact-info' });
    modalRef.componentInstance.generalCompanyInfoData = this.companyInfoForm.value;
    if(type == 'phoneInformation') {
      modalRef.componentInstance.toOpen = type;
    } else {
      modalRef.componentInstance.toOpen = type;
    }
  }

  submit() {
    this.activeModal?.close();
    Swal.fire({
        title: 'Thank you for submitting your new business information!',
        text: 'Once verified, your new information will be updated in your company profile.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
    }).then(result => {
    });
  }
}
