<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <img src="../../../assets/logo-blue-background-login.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card mt-4">
                        <div class="card-body p-4 text-center">
                            <div class="mt-4 pt-2">
                                <h4>Almost done...</h4>
                                <p class="text-muted mx-4"> Welcome To Direct To Carrier.  Please check your email and click on the Get Started Button. </p>
                            </div>
                            <div class="welcome-video">
                                <div class="ratio ratio-16x9">
                                    <iframe src="https://share.synthesia.io/embeds/videos/60abfe63-2b96-4958-bac4-77a1ee677da1" loading="lazy" title="Synthesia video player - Shipper Welcome Videos " allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>