<div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-8">
                    <div *ngIf="isError" class="alert alert-danger alert-label-icon" role="alert">
                        <i class="ri-error-warning-line label-icon"></i>
                        <strong> Your verification link is invalid or has expired. </strong>
                        <br /> For help, please contact <b>support@directtocarrier.com</b> or call <b>800-503-5612</b>.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo"></a>
                        </div>
                        <p class="mt-3 fs-1 fw-semibold">Direct To Carrier</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5" [class.w-100]="wizard?.currentStep?.stepId === stepIds.SCHEDULE_CALL">
                    <div class="card mt-4">
                        <div class="card-body p-4 form-steps">
                            <div class="p-2 mt-4">
                                <form [formGroup]="VerifySignupForm" (ngSubmit)="onSubmit()">
                                    <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'" class="nav nav-pills progress-bar-tab custom-nav">
                                        <aw-wizard-step [canExit]="canExitVerifyTokenStep()">
                                            <ng-template awWizardStepSymbol>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link rounded-pill active" data-progressbar="custom-progress-bar" id="step-1-tab" data-bs-toggle="pill" data-bs-target="#step-1" type="button" role="tab" aria-controls="step-1" aria-selected="true">1</button>
                                                </li>
                                            </ng-template>
                                            <div>
                                                <div class="row">
                                                    <div class="mb-3">
                                                        <label class="form-label" for="verification-token-input">Verification Token:</label>
                                                        <input formControlName="verificationToken" type="text" class="form-control" id="verification-token-input" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="step-2-tab" awNextStep (click)="verifyToken()">
                                                    <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next </button>
                                            </div>
                                        </aw-wizard-step>
                                        <ng-template [ngIf]="!(verifyResponse?.isFleet)">
                                            <aw-wizard-step>
                                                <ng-template awWizardStepSymbol>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="step-2-tab" data-bs-toggle="pill" data-bs-target="#step-2" type="button" role="tab" aria-controls="step-2" aria-selected="false">2</button>
                                                    </li>
                                                </ng-template>
                                                <div>
                                                    <div class="text-center mt-2">
                                                        <div class="ratio ratio-16x9">
                                                            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-start gap-3 mt-4">
                                                    <button type="button" class="btn btn-success" (click)="openStripeCheckoutOverlay()">Pay Now</button>
                                                    <button type="button" class="btn btn-success right ms-auto">7 Day Free Trial</button>
                                                </div>
                                            </aw-wizard-step>
                                        </ng-template>
                                        <ng-template [ngIf]="verifyResponse?.isFleet">
                                            <aw-wizard-step>
                                                <ng-template awWizardStepSymbol>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="step-2-tab" data-bs-toggle="pill" data-bs-target="#step-2" type="button" role="tab" aria-controls="step-2" aria-selected="false">2</button>
                                                    </li>
                                                </ng-template>
                                                <div>
                                                    <div class="text-center mt-2">
                                                        <div class="ratio ratio-16x9">
                                                            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" title="YouTube video" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="p-2 mt-4">
                                                        <div class="mt-4">
                                                            <a routerLink="/schedule-call">
                                                                <button type="button" class="btn btn-success w-100">Schedule Call</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </aw-wizard-step>
                                            <aw-wizard-step [stepId]="stepIds.SCHEDULE_CALL">
                                                <ng-template awWizardStepSymbol>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="step-3-tab" data-bs-toggle="pill" data-bs-target="#step-3" type="button" role="tab" aria-controls="step-3" aria-selected="false">3</button>
                                                    </li>
                                                </ng-template>
                                                <div>
                                                    <div class="text-center mt-2">
                                                        <iframe src="https://calendly.com/sales-directtocarrier/30min" width="100%" height="950" scrolling="no" frameborder="0"></iframe>
                                                    </div>
                                                    <div class="p-2 mt-4">
                                                        <div class="mt-4">
                                                            <button type="button" class="btn btn-success w-100" data-nexttab="step-4-tab" awNextStep>Add Supporting Documents</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </aw-wizard-step>
                                            <aw-wizard-step>
                                                <ng-template awWizardStepSymbol>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="step-4-tab" data-bs-toggle="pill" data-bs-target="#step-4" type="button" role="tab" aria-controls="step-4" aria-selected="false">4</button>
                                                    </li>
                                                </ng-template>
                                                <div>
                                                    <div class="text-center mt-2">
                                                        <h5 class="text-primary">Add Supporting Documents</h5>
                                                    </div>
                                                    <div class="p-2 mt-4">
                                                        <div class="mt-4">
                                                            <input class="form-control" type="file" id="document1">
                                                        </div>
                                                        <div class="mt-4">
                                                            <input class="form-control" type="file" id="document2">
                                                        </div>
                                                        <div class="mt-4">
                                                            <button (click)="openStripeCheckoutOverlay()" class="btn btn-success w-100">Pay Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </aw-wizard-step>
                                        </ng-template>
                                    </aw-wizard>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <p class="mb-0"> Already have an account? <a routerLink="/auth/signin/basic" class="fw-semibold text-primary text-decoration-underline"> Sign in </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-signup-footer></app-signup-footer>
</div>
<ng-template #noShipperModal let-modal>
    <div class="modal-body text-center p-5">
        <div class="mt-4">
            <h4 class="mb-3">Oops something went wrong!</h4>
            <p class="text-muted mb-4"> Shipper signup is not yet implemented.</p>
            <div class="hstack gap-2 justify-content-center">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>
<app-stripe-checkout-overlay #stripeCheckoutOverlay></app-stripe-checkout-overlay>
<div *ngIf="isLoading" class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
</div>