import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, OnDestroy} from '@angular/core';
// import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { BranchesService } from 'src/app/pages/origin-points/origin-point/branches.service';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { DocumentWithUploadUrl } from 'src/app/core/services/models/document.model';
import { getUtf8HtmlFile, TINYMCE_MERGETAGS_LIST } from 'src/app/core/utils/commons';
import { DOCUMENT_TYPE_OPTIONS } from '../contract-templates.component';
import { Mode } from 'src/app/core/services/models/mode.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Account } from 'src/app/core/services/models/account.model';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'ngbd-add-contract-template',
  templateUrl: './add-contract-templates.html',
  styleUrls: ['./add-contract-templates.scss'],

})
export class AddContractTemplateComponent implements OnInit, OnDestroy {
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;
  @Output() close = new EventEmitter();
  @Output() add = new EventEmitter();
  TINYMCE_MERGETAGS_LIST = TINYMCE_MERGETAGS_LIST;
  html?: string;
  files: any[] = [];
  DOCUMENT_TYPE_OPTIONS = [...DOCUMENT_TYPE_OPTIONS];
  addContractTemplateForm!: FormGroup;
  submitted = false;
  fileContent: File;
  isLoading = false;

  modes: any = [];
  placeHolderSelectedModes: string[] = [];

  private subscriptions: Subscription[] = [];

  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  constructor(
    // private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private router: Router,
    private auth: AuthService,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;
        }
      )
    );
  }

  ngOnInit(): void {
    this.initForms();
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initForms() {
    this.addContractTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      file: ['', [Validators.required]],
    });
  }

  get form() {
    return this.addContractTemplateForm.controls;
  }

  cancel() {
    this.close.emit(true);
  }

  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    if(this.files.length > 0 || $event.length > 1) {
        Swal.fire({
            title: 'Error',
            text: 'Cannot upload more than 1 file',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then(result => {
            
          });
    } else {
        this.fileContent = $event[0];
        this.convertToHtml();
        this.prepareFilesList($event);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    if(this.files.length > 0 || files.target.files.length > 1) {
        Swal.fire({
            title: 'Error',
            text: 'Cannot upload more than 1 file',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then(result => {
            
          });
    } else {
      this.fileContent = files.target.files[0];
      this.convertToHtml();
      this.prepareFilesList(files.target.files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  convertToHtml(){
    if(this.fileContent.type === 'application/pdf'){
      this.html = undefined;
      return;
    }
    this.httpRequest.convertToHtml(this.fileContent).subscribe(
      (response) => {
        this.html = response;
      },
      (error) => {
        this.html = undefined;
      }
    );

  }

  async submitApproval() {
    this.submitted = true;
    // this.isLoading = true;
    if(this.files.length == 0) {
      this.isLoading = false;
      Swal.fire({
        title: "Error",
        text: "Please add a template file to submit for approval.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        return;
      });
    } else {
      this.addContractTemplateForm.controls['file'].setValue(this.files);
      if(this.addContractTemplateForm.invalid) {
        this.isLoading = false;
        return;
      } else {
        let file: File;
        let filename: string;
        let contentType: string;
        const name = this.addContractTemplateForm.controls['name'].value;
        if(this.fileContent.type == 'application/pdf') {
          contentType = 'application/pdf';
          file = this.fileContent;
          filename = `${name}.pdf`;
        } else {
          contentType = 'text/html';
          file = getUtf8HtmlFile(`${name}.html`, this.html!);
          filename = file.name;
        }
        let payload = {
          displayName: this.addContractTemplateForm.controls['name'].value,
          documentType: this.addContractTemplateForm.controls['type'].value,
          contentType,
          filename,
          modes: [this.selectedMode?.modeId!],
          branchId: this.selectedBranch?.branchId!
        }
        this.httpRequest.addDocument(payload).subscribe(res => {
          const successRes = <SuccessApiResponse<DocumentWithUploadUrl>> res;
          this.httpRequest.uploadfileAWSS3(successRes.data.uploadUrl, file)
          .subscribe(res => {
            this.isLoading = false;
            this.submitted = false;
            Swal.fire({
              title: 'Success',
              text: 'Contract Template has been submitted.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok'
            }).then(result => {
              this.add.emit(true);
              this.initForms();
              this.deleteFile(0);
            });
          }, error => {
            this.isLoading = false;
          });
        }, error => {
          this.isLoading = false;
        })
      }
    }
  }

  reloadComponentShowView() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, {state: {activeTab: 7}});
  }
}