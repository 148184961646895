<div class="row mb-3">
    <div class="col-lg-5">
        <ul class="list-unstyled mb-0">
            <li class="d-flex">
                <div class="flex-grow-1">
                    <label for="directMessage" class="form-check-label fs-14">Allow Capacity to be split by Zip Codes</label>
                    <p class="text-muted">Reserve Capacity based on different Zip Codes</p>
                </div>
                <div class="flex-shrink-0">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="directMessage" [checked]="zoneRouteAllowCapacityToBeSplitByPostalCodes" (change)="toggleSwitch($event, 'zoneRouteAllowCapacityToBeSplitByPostalCodes')"/>
                    </div>
                </div>
            </li>
            <li class="d-flex mt-2">
                <div class="flex-grow-1">
                    <label class="form-check-label fs-14" for="desktopNotification">
                        Allow Capacity to be split by Service Types
                    </label>
                    <p class="text-muted">Reserve Capacity based on different Service Types</p>
                </div>
                <div class="flex-shrink-0">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="desktopNotification" [checked]="zoneRouteAllowCapacityToBeSplitByServiceTypes" (change)="toggleSwitch($event, 'zoneRouteAllowCapacityToBeSplitByServiceTypes')"/>
                    </div>
                </div>
            </li>
            <li class="d-flex mt-2">
                <div class="flex-grow-1">
                    <label class="form-check-label fs-14" for="emailNotification">
                        Allow Capacity to have overlapping ZIP Codes
                    </label>
                    <p class="text-muted">Zip Codes can be in multiple Capacity Sub-Zones </p>
                </div>
                <div class="flex-shrink-0">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="emailNotification" [checked]="zoneRouteAllowCapacityPostalCodesToOverlap" (change)="toggleSwitch($event, 'zoneRouteAllowCapacityPostalCodesToOverlap')"/>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-lg-7"></div>
</div>