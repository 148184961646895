<div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>

        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <!-- <h1 class="icon-text">Direct to Carrier</h1> -->
                            <img src="../../../assets/logo-blue-background-login.png" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card mt-4">

                        <div class="card-body p-4">
                            <div class="text-center mt-2">
                                <h5 class="text-primary">Welcome Back!</h5>
                                <p class="text-muted">Sign in to continue.</p>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="alert alert-danger" role="alert" *ngIf="error != ''">
                                    Incorrect login details.
                                </div>
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                                    <div class="mb-3">
                                        <label class="form-label" for="username">Email</label>
                                        <input type="email" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                            <div *ngIf="f['email'].errors['required']">Email is required</div>
                                            <div *ngIf="f['email'].errors['email']">Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="password-input">Password</label>
                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                            <input [type]="fieldTextType ? 'text' : 'password'" class="form-control pe-5" id="password-input" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                                          }" (click)="toggleFieldTextType()"></i></button>
                                            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                                <span *ngIf="f['password'].errors['required']">Password is required</span>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                                        <label class="form-check-label" for="auth-remember-check">Remember me</label>
                                        <div class="float-end" (click)="redirectPasswordReset()">
                                            <a routerLink="/password-reset" class="text-muted text-decoration-underline">Forgot password?</a>
                                        </div>
                                    </div>

                                    <!-- <button type="button" class="btn btn-success btn-load">
                                        <span class="d-flex align-items-center">
                                            <span class="flex-grow-1 me-2">
                                                Loading...
                                            </span>
                                            <span class="spinner-border flex-shrink-0" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </span>
                                        </span>
                                    </button> -->
                                    <!-- Load More Buttons -->
                                    <!-- <div class="hstack flex-wrap gap-2 mb-3 mb-lg-0">
                                        <button class="btn btn-outline-primary btn-load">
                                            <span class="d-flex align-items-center">
                                                
                                                <span class="flex-grow-1 ms-2">
                                                    Loading...
                                                </span>
                                            </span>
                                        </button>
                                    </div> -->

                                    <div class="mt-4">

                                        <!-- <button class="btn btn-outline-primary btn-load">
                                            <span class="d-flex align-items-center">
                                                <span class="flex-grow-1 me-2">
                                                    Loading...
                                                </span>
                                                <span class="spinner-border flex-shrink-0" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </span>
                                        </button> -->

                                        <button class="btn btn-primary w-100" type="submit" [disabled]="submitted">
                                            <span class="d-flex align-items-center">
                                                <span class="flex-grow-1 me-2">
                                                    Sign In
                                                </span>
                                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="submitted">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->

                    <div class="mt-4 text-center">
                        <p class="mb-0">Don't have an account ? <a routerLink="/carrier" class="fw-semibold text-primary text-decoration-underline"> Carrier Signup</a> - <a routerLink="/shipper" class="fw-semibold text-primary text-decoration-underline"> Shipper Signup</a> - <a routerLink="/broker" class="fw-semibold text-primary text-decoration-underline"> Broker Signup</a></p>
                    </div>

                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0 text-muted"> © {{year}} Direct To Carrier - <a class="text-muted privacy-link" routerLink="/privacy"><u>Privacy Policy</u></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</div>
<!-- end auth-page-wrapper -->