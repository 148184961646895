import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralMessageService } from '../services/general-message.service';

@Component({
  selector: 'app-general-message',
  templateUrl: './general-message.component.html',
  styleUrls: ['./general-message.component.scss']
})
export class GeneralMessageComponent implements OnInit, OnDestroy {

  constructor(
    public generalMessageService: GeneralMessageService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.generalMessageService.reset();
  }
}
