import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../core/services/models/models';

const POST_HTTP_HEADERS = {
  headers: {
    'Content-Type': 'application/json'
  }
};
const EXCEPTIONS_API_URL = environment.exceptions_api_url;

@Injectable({
  providedIn: 'root'
})
export class ExceptionsApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  postExceptions(data: Record<string, unknown>): Observable<ApiResponse<unknown>> {
    return this.httpClient.post<ApiResponse<unknown>>(EXCEPTIONS_API_URL, data, POST_HTTP_HEADERS);
  }
}
