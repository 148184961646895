<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header p-3 align-items-center d-flex">
                <h5 class="card-title  mb-0 flex-grow-1">Contract Details</h5>
                <div class="flex-shrink-0">
                    <a *ngIf="isPendingSign()" class="btn btn-primary mx-2" id="sign-later-btn" routerLink="../"> Sign Later </a>
                    <a class="btn btn-primary" id="download-btn" [href]="downloadUrl" [class.disabled]="!downloadUrl"> Download </a>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="row mb-3">
                            <div class="col-lg-4 label-form text-align-right">
                                <label class="form-label">Name:</label>
                            </div>
                            <div class="col-lg-8 label-form">
                                <p class="form-label">{{contract?.name}}</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-4 label-form text-align-right">
                                <label class="form-label">Status:</label>
                            </div>
                            <div class="col-lg-8 label-form">
                                <p class="form-label">{{contract?.status}}</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-4 label-form text-align-right">
                                <label class="form-label">Sender Account:</label>
                            </div>
                            <div class="col-lg-8 label-form">
                                <p class="form-label">{{contract?.senderAccountName}}</p>
                                <p class="form-label" *ngFor="let signer of senderSigners">{{signer.firstName}} {{signer.lastName}} ({{getSigningStatus(signer)}})</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-4 label-form text-align-right">
                                <label class="form-label">Receiver Account:</label>
                            </div>
                            <div class="col-lg-8 label-form">
                                <p class="form-label">{{contract?.receiverAccountName}}</p>
                                <p class="form-label" *ngFor="let signer of receiverSigners">{{signer.firstName}} {{signer.lastName}} ({{getSigningStatus(signer)}})</p>
                            </div>
                        </div>
                    </div>
                    <div id="pdf-container" class="col-lg-8">
                        <div *ngIf="!signUrl">Loading PDF ...</div>
                        <iframe id="iframe-signUrl" #signUrlIframe *ngIf="signUrl" [src]="signUrl" (load)="onIframeLoad(signUrlIframe)"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>