import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { finalize, first, map } from 'rxjs/operators';
import { AuthService } from '../core/services/authentication/auth.service';
import { HttpService, UrlObj } from '../core/services/http-service';
import { Mode } from '../core/services/models/mode.model';
import { forkJoin, Observable, of } from 'rxjs';
import { Account } from '../core/services/models/account.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { BillingProfile, BillingType } from '../core/services/models/billing-profile.model';
import { EldCompanyName, SuccessApiResponse } from '../core/services/models/models';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { FMCSA } from '../core/services/models/fmcsa.model';
import { PrettyTechnicalName } from '../core/services/models/pretty-technical-name';
import { DefaultPlace, GoogleMapsSearchBoxComponent } from '../shared/google-maps-search-box/google-maps-search-box.component';
import { BranchMode } from '../core/services/models/branch.model';
import { CompanyInfo, CompanyInfoLegalEntity, CompanyInfoType } from '../core/services/models/company-info.model';
import { GoogleMapsAutocompleteComponent } from '../shared/google-maps-autocomplete/google-maps-autocomplete.component';
import { environment } from 'src/environments/environment';
import { SignUpService } from '../services/sign-up.service';

declare var Calendly: any;

export type Plan = '14daytrial' | '30daytrial' | 'paid' | 'free';

interface MetaData {
    companyInformation: {
        name: string;
        transportationMode: Mode['type'][];
    };
    firstName: string;
    lastName: string;
    phone: string;
    title: string;
    type: string;
    plan: Plan;
}

@Component({
  selector: 'app-sign-up-process',
  templateUrl: './sign-up-process.component.html',
  styleUrls: ['./sign-up-process.component.scss']
})
export class SignUpProcessComponent implements OnInit, AfterViewInit {
    baseUrl = environment.api;
    domain = environment.domain;

    @ViewChild(WizardComponent)
    public wizard: WizardComponent;

    readonly DOT_TYPE_OPTIONS: PrettyTechnicalName[] = [
        {prettyName: 'I don\'t have a DOT Number', technicalName: 'none'},
        {prettyName: 'I have a DOT Number', technicalName: 'dot'},
    ];

    readonly FLEET_TYPE: PrettyTechnicalName[] = [
        {prettyName: 'Only W2 employee drivers', technicalName: 'Only W2 Drivers'},
        {prettyName: 'Only 1099 independent subcontractor drivers', technicalName: 'Only IC Drivers'},
        {prettyName: 'Both W2 employee and 1099 independent subcontractor drivers', technicalName: 'both'},
    ];

    readonly LEGAL_ENTITY_OPTIONS: PrettyTechnicalName[] = [
        {prettyName: 'Sole Proprietor', technicalName: 'sole-proprietor'},
        {prettyName: 'LLC', technicalName: 'llc'},
        {prettyName: 'C Corporation', technicalName: 'c-corp'},
        {prettyName: 'S Corporation', technicalName: 's-corp'},
    ];

    readonly LEGAL_ENTITY_OPTIONS_MAP = this.LEGAL_ENTITY_OPTIONS.reduce(
        (acc, cur)=>{
            acc.set(cur.technicalName, cur.prettyName);
            return acc;
        },
        new Map<string, string>()
    );

    selectedLegalEntity:CompanyInfoLegalEntity = this.LEGAL_ENTITY_OPTIONS[0].technicalName as CompanyInfoLegalEntity;
    isX = false;

    isLoading = false;
    isLoadingDashboard = false;
    isLoadingCall = false;
    dotLookupLoading = false;
    dotLookupTriggered = false;
    dotForm!: FormGroup;
    fmcsa?: FMCSA;
    billingInfoForm!: FormGroup;
    billingInfoAddress?: google.maps.places.PlaceResult;
    isBillingInfoAddressEmpty = false;
    passwordForm!: FormGroup;
    eldForm!: FormGroup;
    fuelProvidersForm!: FormGroup;
    shipperCompanyInfoForm!: FormGroup;
    passwordSubmitted = false;
    submitted = false;
    isChecked = false;
    fieldTextType!: boolean;
    showErrorRead = false;
    code: string;
    metaData: MetaData;
    email: string;
    type = [
        { name: "Carrier", value: 'carrier' },
        { name: 'Shipper', value: 'shipper' }
    ];
    shipperType = [
        { name: '11 Agriculture, Forestry, Fishing and Hunting'},
        { name: '21 Mining, Quarrying, and Oil and Gas Extraction'},
        { name: '22 Utilities'},
        { name: '23 Construction'},
        { name: '31-33 Manufacturing'},
        { name: '42 Wholesale Trade'},
        { name: '44-45 Retail Trade'},
        { name: '48-49 Transportation and Warehousing'},
        { name: '51 Information'},
        { name: '52 Finance and Insurance'},
        { name: '53 Real Estate and Rental and Leasing'},
        { name: '54 Professional, Scientific, and Technical Services'},
        { name: '55 Management of Companies and Enterprises'},
        { name: '56 Administrative and Support and Waste Management and Remediation Services'},
        { name: '61 Educational Services'},
        { name: '62 Health Care and Social Assistance'},
        { name: '71 Arts, Entertainment, and Recreation'},
        { name: '72 Accommodation and Food Services'},
        { name: '81 Other Services (except Public Administration)'},
        { name: '92 Public Administration'}
    ];

    shipperTypeBroker = [
        { name: '48-49 Transportation and Warehousing'},
    ];
    shipperSubTypeBroker = [
        {name: '481 Air Transportation'},
        {name: '482 Rail Transportation'},
        {name: '483 Water Transportation'},
        {name: '484 Truck Transportation'},
        {name: '485 Transit and Ground Passenger Transportation'},
        {name: '486 Pipeline Transportation'},
        {name: '487 Scenic and Sightseeing Transportation'},
        {name: '488 Support Activities for Transportation'},
        {name: '492 Couriers and Messengers'},
    ]

    shipperSubType: {[key: string]: {name: string}[]} = {
        [this.shipperType[0].name]: [
            {name: '111 Crop Production'},
            {name: '112 Animal Production and Aquaculture'},
            {name: '113 Forestry and Logging'},
            {name: '114 Fishing, Hunting and Trapping'},
            {name: '115 Support Activities for Agriculture and Forestry'},
        ],
        [this.shipperType[1].name]: [
            {name: '211 Oil and Gas Extraction'},
            {name: '212 Mining (except Oil and Gas)'},
            {name: '213 Support Activities for Mining'},
        ],
        [this.shipperType[2].name]: [
            {name: '221 Utilities'},
        ],
        [this.shipperType[3].name]: [
            {name: '236 Construction of Buildings'},
            {name: '237 Heavy and Civil Engineering Construction'},
            {name: '238 Specialty Trade Contractors'},
        ],
        [this.shipperType[4].name]: [
            {name: '311 Food Manufacturing'},
            {name: '312 Beverage and Tobacco Product Manufacturing'},
            {name: '313 Textile Mills'},
            {name: '314 Textile Product Mills'},
            {name: '315 Apparel Manufacturing'},
            {name: '316 Leather and Allied Product Manufacturing'},
            {name: '321 Wood Product Manufacturing'},
            {name: '322 Paper Manufacturing'},
            {name: '323 Printing and Related Support Activities'},
            {name: '324 Petroleum and Coal Products Manufacturing'},
            {name: '325 Chemical Manufacturing'},
            {name: '326 Plastics and Rubber Products Manufacturing'},
            {name: '327 Nonmetallic Mineral Product Manufacturing'},
            {name: '331 Primary Metal Manufacturing'},
            {name: '332 Fabricated Metal Product Manufacturing'},
            {name: '333 Machinery Manufacturing'},
            {name: '334 Computer and Electronic Product Manufacturing'},
            {name: '335 Electrical Equipment, Appliance, and Component Manufacturing'},
            {name: '336 Transportation Equipment Manufacturing'},
            {name: '337 Furniture and Related Product Manufacturing'},
            {name: '339 Miscellaneous Manufacturing'},
        ],
        [this.shipperType[5].name]: [
            {name: '423 Merchant Wholesalers, Durable Goods'},
            {name: '424 Merchant Wholesalers, Nondurable Goods'},
            {name: '425 Wholesale Trade Agents and Brokers'}
        ],
        [this.shipperType[6].name]: [
            {name: '441 Motor Vehicle and Parts Dealers'},
            {name: '444 Building Material and Garden Equipment and Supplies Dealers'},
            {name: '445 Food and Beverage Retailers'},
            {name: '449 Furniture, Home Furnishings, Electronics, and Appliance Retailers'},
            {name: '455 General Merchandise Retailers'},
            {name: '456 Health and Personal Care Retailers'},
            {name: '457 Gasoline Stations and Fuel Dealers'},
            {name: '458 Clothing, Clothing Accessories, Shoe, and Jewelry Retailers'},
            {name: '459 Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers'},
        ],
        [this.shipperType[7].name]: [
            {name: '481 Air Transportation'},
            {name: '482 Rail Transportation'},
            {name: '483 Water Transportation'},
            {name: '484 Truck Transportation'},
            {name: '485 Transit and Ground Passenger Transportation'},
            {name: '486 Pipeline Transportation'},
            {name: '487 Scenic and Sightseeing Transportation'},
            {name: '488 Support Activities for Transportation'},
            {name: '491 Postal Service'},
            {name: '492 Couriers and Messengers'},
            {name: '493 Warehousing and Storage'}
        ],
        [this.shipperType[8].name]: [
            {name: '512 Motion Picture and Sound Recording Industries'},
            {name: '513 Publishing Industries'},
            {name: '516 Broadcasting and Content Providers'},
            {name: '517 Telecommunications'},
            {name: '518 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services'},
            {name: '519 Web Search Portals, Libraries, Archives, and Other Information Services'}
        ],
        [this.shipperType[9].name]: [
            {name: '521 Monetary Authorities-Central Bank'},
            {name: '522 Credit Intermediation and Related Activities'},
            {name: '523 Securities, Commodity Contracts, and Other Financial Investments and Related Activities'},
            {name: '524 Insurance Carriers and Related Activities'},
            {name: '525 Funds, Trusts, and Other Financial Vehicles'},
        ],
        [this.shipperType[10].name]: [
            {name: '531 Real Estate'},
            {name: '532 Rental and Leasing Services'},
            {name: '533 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'},
        ],
        [this.shipperType[11].name]: [
            {name: '541 Professional, Scientific, and Technical Services'}
        ],
        [this.shipperType[12].name]: [
            {name: '551 Management of Companies and Enterprises'},
        ],
        [this.shipperType[13].name]: [
            {name: '561 Administrative and Support Services'},
            {name: '562 Waste Management and Remediation Services'},
        ],
        [this.shipperType[14].name]: [
            {name: '611 Educational Services'}
        ],
        [this.shipperType[15].name]: [
            {name: '621 Ambulatory Health Care Services'},
            {name: '622 Hospitals'},
            {name: '623 Nursing and Residential Care Facilities'},
            {name: '624 Social Assistance'},
        ],
        [this.shipperType[16].name]: [
            {name: '711 Performing Arts, Spectator Sports, and Related Industries'},
            {name: '712 Museums, Historical Sites, and Similar Institutions'},
            {name: '713 Amusement, Gambling, and Recreation Industries'},
        ],
        [this.shipperType[17].name]: [
            {name: '721 Accommodation'},
            {name: '722 Food Services and Drinking Places'},
        ],
        [this.shipperType[18].name]: [
            {name: '811 Repair and Maintenance'},
            {name: '812 Personal and Laundry Services'},
            {name: '813 Religious, Grantmaking, Civic, Professional, and Similar Organizations'},
            {name: '814 Private Households'},
        ],
        [this.shipperType[19].name]: [
            {name: '921 Executive, Legislative, and Other General Government Support'},
            {name: '922 Justice, Public Order, and Safety Activities'},
            {name: '923 Administration of Human Resource Programs'},
            {name: '924 Administration of Environmental Quality Programs'},
            {name: '925 Administration of Housing Programs, Urban Planning, and Community Development'},
            {name: '926 Administration of Economic Programs'},
            {name: '927 Space Research and Technology'},
            {name: '928 National Security and International Affairs'},
        ]
    }

    uniqueEldCompanyName: EldCompanyName[] = [];
    eldCompanyName: EldCompanyName[] = [];
    uniqueEldDeviceName: EldCompanyName[] = [];
    eldDeviceName: EldCompanyName[] = [];
    uniqueEldModelName: EldCompanyName[] = [];
    fuelProviders: Array<any> = [];

    selectedFuelProviders: Array<any> = [];

    defaultPlace?: DefaultPlace;

    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

    showAddressMap: boolean = false;
    @ViewChild(GoogleMapsSearchBoxComponent) googlemapSearchBoxComponent: GoogleMapsSearchBoxComponent;
    redirectTo: string = '';

    @ViewChild('googleMapsInput') googleMapsInput: GoogleMapsSearchBoxComponent;
    @ViewChild('googleMapsInputAuto') googleMapsInputAuto: GoogleMapsAutocompleteComponent;
    submittedAddress = false;
    billingAddressValid = false;
    companyInfoAddressValid = false;

    association: Array<any> = [];
    selectedAssociation: Array<string>;
    redirectLoading = false;
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private httpRequest: HttpService,
        private auth: AuthService,
        private router: Router,
        private signUpService: SignUpService
    ) {
    }

    ngOnInit(): void {
        this.code = this.route.snapshot.params['code'];
        this.metaData = JSON.parse(this.route.snapshot.params['metadata']);
        this.email = this.route.snapshot.params['email'];
        this.initForms();
        fetch('./assets/jsons/eld/eld-company-name.json').then(res => res.json()).then((jsonData: EldCompanyName[]) => {
            const companyNames = new Set<string>();
            this.eldCompanyName = jsonData;
            this.uniqueEldCompanyName = [];
            for(const eldCompanyName of jsonData) {
                if(!companyNames.has(eldCompanyName.companyName)){
                    this.uniqueEldCompanyName.push(eldCompanyName);
                    companyNames.add(eldCompanyName.companyName);
                }
            }
            this.uniqueEldCompanyName.sort((a,b) => a.companyName.localeCompare(b.companyName));
        });
        fetch('./assets/jsons/fuel-providers.json').then(res => res.json()).then(jsonData => {
            this.fuelProviders = [...new Set(jsonData)];
        });

        fetch('./assets/jsons/associations.json').then(res => res.json()).then(jsonData => {
            this.association = [...new Set(jsonData)];
        });
    }

    ngAfterViewInit(): void {
    }

    onAddressChangeCompanyInfo(address: google.maps.places.PlaceResult): void {
        if(this.googleMapsInputAuto.validateForm()) {
            this.companyInfoAddressValid = true;
            this.shipperCompanyInfo_companyInfoControls['address'].setValue(address);
            this.billingInfoAddress = address;
            this.defaultPlace = {
                input: address.formatted_address,
            };
        } else {
            this.companyInfoAddressValid = false;
        }
        
    }

    onAddressChangeCompanyInfoIsEmpty(event: any) {
        if(event) {
            this.shipperCompanyInfo_companyInfoControls['address'].setValue('');
        }
    }

    onAddressChange(address: google.maps.places.PlaceResult): void {
        this.submittedAddress = true;
        this.isBillingInfoAddressEmpty = false;
        this.billingInfoAddress = address;
        if(this.googleMapsInput.validateForm()) {
            this.billingAddressValid = true;
        } else {
            this.billingAddressValid = false;
        }
    }
    onAddressChangIsEmpty(event: any) {
        if(event) {
            this.isBillingInfoAddressEmpty = true;
        }
    }

    moveNextPassword(){
        this.passwordSubmitted = true;
        if(this.passwordForm.invalid || this.getPasswordRequirmentErrors().length){
            return;
        }else{
            this.billingInfoForm.controls['billingContactFirstName'].setValue(this.passwordForm.controls['firstName'].value);
            this.billingInfoForm.controls['billingContactLastName'].setValue(this.passwordForm.controls['lastName'].value);
            this.billingInfoForm.controls['billingContactPhone'].setValue(this.passwordForm.controls['phone'].value);
            this.billingInfoForm.controls['billingContactEmail'].setValue(this.passwordForm.controls['email'].value);
            this.showAddressMap = true;
            this.wizard.goToNextStep();
        }
    }

    moveNextLegalEntitySteps() {
        this.submitted = true;
        this.isLoading = true;
        const legalInfoForm = this.shipperCompanyInfoForm.controls['legalInfo'] as FormGroup;
        const companyInfoForm = this.shipperCompanyInfoForm.controls['companyInfo']  as FormGroup;
        const isDOTlookupNeeded = this.isDOTlookupNeeded();
        if(
            legalInfoForm.invalid
            || (isDOTlookupNeeded && !this.fmcsa)
            || (!isDOTlookupNeeded && companyInfoForm.invalid)
        ) {
            this.isLoading = false;
            return;
        } else {
            if(isDOTlookupNeeded && this.fmcsa){
                companyInfoForm.controls['legalName'].setValue(this.fmcsa.legalName);
                companyInfoForm.controls['dbaName'].setValue(this.fmcsa.dbaName);
                companyInfoForm.controls['einNumber'].setValue(this.fmcsa.einNumber);
                companyInfoForm.controls['address'].setValue(
                    `${this.fmcsa.street}, ${this.fmcsa.city}, ${this.fmcsa.state}, ${this.fmcsa.zipcode}`
                );
            }

            this.submitted = false;
            this.isLoading = false;
            this.showAddressMap = true;
            // this.googlemapSearchBoxComponent.reset();
            this.wizard.goToNextStep();
        }
    }

    initForms() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            firstName: [this.metaData.firstName, [Validators.required]],
            lastName: [this.metaData.lastName, [Validators.required]],
            title: [this.metaData.title],
            phone: [this.metaData.phone, [Validators.required]],
            email: [this.email, {disabled: true}, [Validators.required, Validators.email]],
            type: [this.metaData.type, [Validators.required]],
            verificationCode: [this.code]
        })

        this.eldForm = this.formBuilder.group({
            companyName: ['', [Validators.required]],
            deviceName: ['', [Validators.required]],
            modelNumber: ['', [Validators.required]],
            assocation: ['']
        })

        this.fuelProvidersForm = this.formBuilder.group({
            name: ['', [Validators.required]],
        })

        this.billingInfoForm = this.formBuilder.group({
            billingContactFirstName: [this.metaData.firstName, [Validators.required]],
            billingContactLastName: [this.metaData.lastName, [Validators.required]],
            billingContactEmail: [this.email, [Validators.required]],
            billingContactPhone: [this.metaData.phone, [Validators.required]],
            billingCompanyName: ['', [Validators.required]],
            billingContactEmailCC: [''],
        });

        this.dotLookupLoading = false;
        this.dotForm = this.formBuilder.group({
            type: [this.DOT_TYPE_OPTIONS[0].technicalName, [Validators.required]],
            number: ['', [Validators.required]],
            fleetType: ['']
        });

        this.shipperCompanyInfoForm = this.formBuilder.group({
            legalInfo: this.formBuilder.group({
                legalEntity: ['', [Validators.required]],
                type: ['', [Validators.required]],
                subtype: ['', [Validators.required]],
            }),
            companyInfo: this.formBuilder.group({
                legalName: [this.metaData?.companyInformation?.name, [Validators.required]],
                dbaName: [''],
                einNumber: ['', [Validators.required]],
                address: ['', [Validators.required]]
            })
        });
    }

    get form() {
        return this.passwordForm.controls;
    }

    get billingInfo() {
        return this.billingInfoForm.controls;
    }

    get dotFormControls(){
        return this.dotForm.controls;
    }

    get shipperCompanyInfo_legalInfoControls(){
        return (this.shipperCompanyInfoForm.controls['legalInfo'] as FormGroup).controls;
    }

    get shipperCompanyInfo_companyInfoControls() {
        return (this.shipperCompanyInfoForm.controls['companyInfo'] as FormGroup).controls;
    }

    nextFMCSA(){
        if(this.isFMCAX()){
            this.isX = true;
        }else{
            this.isX = false;
        }
        this.wizard.goToNextStep();
    }

    isFMCAX(): boolean{
        const {type}: {type: string} = this.dotForm.value;
        return type === 'none' || !this.fmcsa?.dotNumber || 
            !this.fmcsa?.mcNumber || !this.fmcsa?.hasAuthority || 
            this.fmcsa?.isBroker;
    }

    nextLegalEntity(){
        this.isX = false
        // if(this.selectedLegalEntity === 'sole-proprietor'){
        //     this.isX = true;
        // }else{
        //     this.isX = false
        // }
        if(this.fmcsa?.isFleet && this.selectedLegalEntity === 'sole-proprietor') {
            Swal.fire({
                title: 'Error',
                text: 'Failed to create user',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
            }).then(result => {
                //do nothing
            });
        } else {
            this.wizard.goToNextStep();
        }
    }

    lookupFMCSA(){
        this.dotLookupTriggered = true;
        if(this.dotForm.invalid){
            return;
        }
        const {number} = this.dotForm.value;
        this.dotLookupLoading = true;

        this.httpRequest.lookupFMCSAdotNumber(number, true)
        .pipe(finalize(()=>this.dotLookupLoading = false))
        .subscribe(
            res => {
                const successRes = <SuccessApiResponse<FMCSA>> res;
                if(successRes.data.carrierOperationDesc == 'Interstate' || !successRes.data.carrierOperationDesc) {
                    if(this.metaData.type == 'shipper') {
                        if(successRes.data.isCarrier) {
                            Swal.fire({
                                title: 'Error',
                                text: 'DOT Number is not allowed to sign up as shipper',
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: 'rgb(60,76,128)',
                                confirmButtonText: 'Ok',
                            }).then(result => {
                                this.dotForm.controls['number'].setValue('');
                                //do nothing
                            });

                        } else {
                            this.fmcsa = successRes.data;
                            const {street, city, state, country} = this.fmcsa;
                            this.defaultPlace = {
                                input: `${street}, ${city}, ${state}, ${country}`
                            };
                            this.companyInfoAddressValid = true;
                        }
                    } else if(this.metaData.type === 'broker') {
                        if(successRes.data.isBroker) {
                            this.fmcsa = successRes.data;
                            const {street, city, state, country} = this.fmcsa;
                            this.defaultPlace = {
                                input: `${street}, ${city}, ${state}, ${country}`
                            };
                            this.companyInfoAddressValid = true;
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'DOT Number is not allowed to sign up as a broker.',
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: 'rgb(60,76,128)',
                                confirmButtonText: 'Ok',
                            }).then(result => {
                                this.dotForm.controls['number'].setValue('');
                                //do nothing
                            });
                        }

                    } else {
                        this.fmcsa = successRes.data;
                        const {street, city, state, country} = this.fmcsa;
                        this.defaultPlace = {
                            input: `${street}, ${city}, ${state}, ${country}`
                        };
                        this.companyInfoAddressValid = true;
                    }
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: `
                            <div class="col-lg-12 text-align-center text-danger">
                            <p>We only accept with interstate authority.</p>
                            <p>We determined that you are a federal motor carrier, this platform only accepts carriers with interstate authority.</p>
                            </div>
                        `,
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Go to Independent Driver Signup',
                    }).then(result => {
                        this.dotForm.controls['number'].setValue('');
                        if (result.isConfirmed) {
                            window.location.href = this.domain + '/driver'
                        }
                    });
                }
                this.billingInfoForm.controls['billingCompanyName'].setValue(this.fmcsa?.legalName);
                
            },
            error => {
                this.fmcsa = undefined;
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to lookup DOT/FMCSA data: '+ error.error.reason,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                }).then(result => {
                    this.dotForm.controls['number'].setValue('');
                    //do nothing
                });
            }
        );
    }

    changeFMCAType(){
        this.fmcsa = undefined;
    }

    checkedValue(event: any){
        this.isChecked = event.currentTarget.checked;
    }

    getPasswordRequirmentErrors(): string[] {
        const pw: string = this.form['password'] ? this.form['password'].value : '';
        const passwordRequirmentErrors: string[] = [];
        if (pw.length < 10) {
        passwordRequirmentErrors.push('Must have at least 10 characters in length')
        }
        if (!/[a-z]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have lowercase letter')
        }
        if (!/[A-Z]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have uppercase letter')
        }
        if (!/[0-9]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have number')
        }
        if (!/[!@#&()\-/$=<>?]/g.test(pw)) {
        passwordRequirmentErrors.push('Must have special character')
        }
        return passwordRequirmentErrors;
    }

    submit() {
        console.log(this.metaData);
        this.redirectLoading = true;
        this.submitted = true;
        this.isLoading = true;
        if(this.passwordForm.invalid || this.getPasswordRequirmentErrors().length || this.billingInfoForm.invalid || !this.billingInfoAddress) {
            this.isLoading = false;
            return;
        } else {
            if(this.metaData.type === 'carrier') {
                if(this.signUpService.getCarrierSubTypes(this.fmcsa, this.selectedLegalEntity, this.dotFormControls['fleetType'].value) === 'error this is not allowed') {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to create user',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Ok',
                    }).then(result => {
                        //do nothing
                    });
                } else {
                    this.httpRequest.createUsers(this.passwordForm.value).subscribe((user) => {
                        if('data' in user) {
                            this.submitted = false;
                            this.login(user.data.email, this.passwordForm.controls['password'].value);
                        }
                    }, error => {
                        this.isLoading = false;
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed to create user: '+ error.error.reason,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: 'rgb(60,76,128)',
                            confirmButtonText: 'Ok',
                        }).then(result => {
                            //do nothing
                        });
                    })
                }
            } else {
                this.httpRequest.createUsers(this.passwordForm.value).subscribe((user) => {
                    if('data' in user) {
                        this.submitted = false;
                        this.login(user.data.email, this.passwordForm.controls['password'].value);
                    }
                }, error => {
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to create user: '+ error.error.reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Ok',
                    }).then(result => {
                        //do nothing
                    });
                })
            }
            
        }
    }

    goToSummary() {
        this.wizard.goToNextStep();
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    showError() {
        this.showErrorRead = true;
    }

    convertToAccountType(type: string): string {
        switch (type) {
            case 'shipper':
            case 'carrier':
            case 'driver':
            case 'helper':
            case 'broker':
                return `${type}-account`;
            default:
                return type;
        }
    }

    addModes(account: Account): Observable<SuccessApiResponse<Mode[]>> {
        const modes = this.metaData.companyInformation.transportationMode;
        if (!modes) {
            return of({ error: false, data: [] });
        }
        const addModes$ = modes.map(mode => this.httpRequest.addMode({
            name: this.returnPrettyModeName(mode),
            type: mode,
            hasLanes: true,
            hasServiceAreas: true,
            accountId: account.accountId
        }, account.accountId));
        return forkJoin(addModes$).pipe(
            map((responses: any[]) => ({
            error: false,
            data: responses.map(response => response.data)
            }))
        );
    }

    login(email: string, password: string): void {
        this.auth.login(email, password).pipe(first()).subscribe((data) => {
            if((this.metaData.type == 'shipper' || this.metaData.type == 'carrier' || this.metaData.type == 'driver' || this.metaData.type == 'helper' || this.metaData.type == 'broker')
                && this.metaData.companyInformation) {
                    const {phone} = this.passwordForm.value;
                    let companyInfo: CompanyInfo = {
                        email, phone, type: 'shipper-carrier', hasAuthority: this.fmcsa?.hasAuthority, totalPowerUnits: this.fmcsa?.totalPowerUnits, totalDrivers: this.fmcsa?.totalDrivers
                    } as CompanyInfo;
                    const {hasAuthority, ...filteredFmcsa} = this.fmcsa ?? {};
                    if(this.metaData.type === 'shipper' || this.metaData.type === 'broker'){
                        const legalInfoForm = this.shipperCompanyInfoForm.controls['legalInfo'] as FormGroup;
                        const companyInfoForm = this.shipperCompanyInfoForm.controls['companyInfo']  as FormGroup;
                        let city, street, state, zipCode;
                        if(this.shipperCompanyInfo_companyInfoControls['address'].value?.formatted_address){
                            const address: AddressComponent = new AddressComponent(this.shipperCompanyInfo_companyInfoControls['address'].value);
                            city = address.city;
                            street = address.street1;
                            state = address.state;
                            zipCode = address.postalCode;
                        }else{
                            city = this.fmcsa!.city;
                            street = this.fmcsa!.street;
                            state = this.fmcsa!.state;
                            zipCode = this.fmcsa!.zipcode;
                        }
                        companyInfo = {
                            ...legalInfoForm.value,
                            ...companyInfoForm.value,
                            ...companyInfo,
                            ...filteredFmcsa ?? {},
                            subtype: this.shipperCompanyInfo_legalInfoControls['subtype'].value,
                            city,
                            street,
                            state,
                            zipCode,
                            einNumber: this.fmcsa?.einNumber && String(this.fmcsa?.einNumber),
                            mcNumber: this.fmcsa?.mcNumber && String(this.fmcsa?.mcNumber),
                            dotNumber: this.fmcsa?.mcNumber && String(this.fmcsa?.dotNumber),
                        }
                    } else if(this.metaData.type === 'carrier'){
                        companyInfo = {
                            ...companyInfo,
                            ...filteredFmcsa ?? {},
                            subtype: this.signUpService.getCarrierSubTypes(filteredFmcsa, this.selectedLegalEntity, this.dotFormControls['fleetType'].value),
                            legalEntity: this.selectedLegalEntity,
                            zipCode: this.fmcsa?.zipcode!,
                            einNumber: String(this.fmcsa?.einNumber!),
                            mcNumber: String(this.fmcsa?.mcNumber!),
                            dotNumber: String(this.fmcsa?.dotNumber!),
                        }
                    }   
                const accountType = this.convertToAccountType(this.metaData.type);
                companyInfo.type = accountType as CompanyInfoType;
                let payload = {
                    "type": accountType,
                    "name": this.metaData.companyInformation.name,
                    "companyInfo": companyInfo
                };
                this.httpRequest.addAccount(payload, data.data.token).subscribe((resp) => {
                    if (resp.error) {
                        this.isLoading = false;
                        console.error('Error adding account', resp.reason);
                        return;
                    }
                    const account: Account = resp.data;
                    this.addModes(account).subscribe((resp) => {
                        this.httpRequest.setDefaultAccount(data.data.token).subscribe((response) => {
                            this.auth.setSelectedAccount(response.data[0]);
                            let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
                            currentUser.accounts = response.data;
                            localStorage.setItem('currentUser', JSON.stringify(currentUser));

                            let selectedModes = [];
                            for(let x = 0; x < resp.data.length; x++) {
                                selectedModes.push(resp.data[x]);
                            }
                            const modes: BranchMode[] = selectedModes.filter(mode => !!mode.modeId).map(mode => ({
                                modeId: mode.modeId!,
                                name: mode.name,
                                type: mode.type,
                                hasServiceAreas: mode.hasServiceAreas,
                                hasLanes: mode.hasLanes,
                                trailerPool: false,
                                domicileLocation: false
                            }));
                            this.httpRequest.updateBranch(response.data[0].branchId,{
                                modes: modes,
                                address: new AddressComponent(this.billingInfoAddress!)
                            }).subscribe(res => {
                                if(this.metaData.type == 'carrier') {
                                    this.httpRequest.createEld(this.eldForm.value).subscribe((eldResp) => {
                                        if(this.selectedFuelProviders.length != 0) {
                                            this.submitFuelProviders().subscribe((data) => {
                                                this.httpRequest.addAccountPlan(
                                                    {
                                                        status: this.metaData.plan
                                                    }
                                                ).subscribe((data) => {
                                                    if(this.selectedAssociation) {
                                                        this.httpRequest.addAssociation(
                                                            {
                                                                names: this.selectedAssociation
                                                            }
                                                        ).subscribe((data) => {
                                                            this.saveBillingInfo(account);
                                                        })
                                                    } else {
                                                        this.saveBillingInfo(account);
                                                    }
                                                    
                                                })
                                            },
                                            error => {
                                                Swal.fire({
                                                    title: 'Error',
                                                    text: 'Failed to add fuel providers: '+ error.error.reason,
                                                    icon: 'warning',
                                                    showCancelButton: false,
                                                    confirmButtonColor: 'rgb(60,76,128)',
                                                    confirmButtonText: 'Ok',
                                                }).then(result => {
                                                    //do nothing
                                                });
                                            });
                                        } else {
                                            this.httpRequest.addAccountPlan(
                                                {
                                                    status: this.metaData.plan
                                                }
                                            ).subscribe((data) => {
                                                if(this.selectedAssociation) {
                                                    this.httpRequest.addAssociation(
                                                        {
                                                            names: this.selectedAssociation
                                                        }
                                                    ).subscribe((data) => {
                                                        this.saveBillingInfo(account);
                                                    })
                                                } else {
                                                    this.saveBillingInfo(account);
                                                }
                                                
                                            });
                                        }
                                    })
                                } else {
                                    this.httpRequest.addAccountPlan(
                                        {
                                            status: '30daytrial'
                                        }
                                    ).subscribe((data) => {
                                        this.saveBillingInfo(account);
                                    });
                                }
                            }, error => {
                                Swal.fire({
                                    title: 'Error',
                                    text: 'Failed to update branch: '+ error.error.reason,
                                    icon: 'warning',
                                    showCancelButton: false,
                                    confirmButtonColor: 'rgb(60,76,128)',
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    //do nothing
                                });
                            });
                        });
                    }, error => {
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed to add modes: '+ error.error.reason,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: 'rgb(60,76,128)',
                            confirmButtonText: 'Ok',
                        }).then(result => {
                            //do nothing
                        });
                    });
                },
                error => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to add account: '+ error.error.reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Ok',
                    }).then(result => {
                        //do nothing
                    });
                });
            } else if((this.metaData.type == 'shipper' || this.metaData.type == 'carrier' || this.metaData.type == 'driver' || this.metaData.type == 'helper')
                && !this.metaData.companyInformation) {
                    let payload = {
                        "type": this.convertToAccountType(this.metaData.type),
                        "name": 'home'
                    };
                    this.httpRequest.addAccount(payload, data.data.token).subscribe((resp) => {
                        if (resp.error) {
                            this.isLoading = false;
                            console.error('Error adding account', resp.reason);
                            return;
                        }
                        this.httpRequest.setDefaultAccount(data.data.token).subscribe((response) => {
                            this.auth.setSelectedAccount(response.data[0]);
                            this.redirectToDashboard();
                        });
                    });
            }
        }, error => {
            Swal.fire({
                title: 'Error',
                text: 'Failed to login: '+ error.error.reason,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
            }).then(result => {
                //do nothing
            });
        });
    }

    saveBillingInfo(account:Account){
        const { 
            billingContactFirstName, billingContactLastName, 
            billingContactEmail, billingContactPhone, billingCompanyName
        } = this.billingInfoForm.value;

        let type: BillingType;

        if(this.metaData.type == 'shipper') {
            type = 'postpaid';
        }else if(this.metaData.type == 'carrier'){
            type = 'prepaid';
        } else if(this.metaData.type == 'broker'){
            type = 'postpaid';
        }

        const addressComponent = new AddressComponent(this.billingInfoAddress!);
    
        return this.httpRequest.addAccountBillingProfiles(
            {
                // name: this.fmcsa?.legalName ? this.fmcsa.legalName : this.metaData.companyInformation.name,
                name: billingCompanyName ? billingCompanyName : this.metaData.companyInformation.name,
                receiptEmail: billingContactEmail,
                type: type!,
                firstName: billingContactFirstName,
                lastName: billingContactLastName,
                companyName: this.metaData.companyInformation.name,
                street: addressComponent.street1!,
                city: addressComponent.city!,
                state: addressComponent.state!,
                postalCode: addressComponent.postalCode!,
                phone: billingContactPhone
            },
            account.accountId!
        ).subscribe(
            res => {
                this.isLoading = false;

                const billingResponse = res as SuccessApiResponse<BillingProfile>;
                const billingProfile = billingResponse.data;

                if(this.metaData.type == 'shipper' || this.metaData.type == 'broker'){
                    this.wizard.goToNextStep();
                } else if(this.metaData.type == 'carrier'){
                    this.httpRequest.beginPaymentSetup(billingProfile.billingProfileId, this.metaData.plan)
                    .subscribe(
                        res => {
                            if(this.metaData.plan === '14daytrial' || this.metaData.plan === 'free'){
                                this.redirectToDashboard();
                                return; 
                            }
                            const successRes = <SuccessApiResponse<UrlObj>> res;
                            if(this.redirectTo == 'dashboard') {
                                window.location.href = successRes.data.url;
                            } else {
                                this.openLinkAndCloseTab();
                            }
                        },
                        error => {
                            Swal.fire({
                                title: 'Error',
                                text: 'Failed to begin payment setup: '+ error.error.reason,
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: 'rgb(60,76,128)',
                                confirmButtonText: 'Ok',
                            }).then(result => {
                                //do nothing
                            });
                        }
                    );
                }
            },
            error => {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to add account billing profile: '+ error.error.reason,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                }).then(result => {
                    //do nothing
                });
            }
        );
    }

    returnPrettyModeName(mode: string) {
        switch (mode) {
            case "tl":
                return 'Truck Load';
                break;
            case "TL":
                return 'Truck Load';
                break;
            case "fm":
                return 'Final Mile';
                break;
            case "FM":
                return 'Final Mile';
                break;
            case "ltl":
                return 'LTL';
                break;
            case "LTL":
                return 'LTL';
                break;
            case "drayage":
                return 'Drayage';
                break;
            case "Drayage":
                return 'Drayage';
                break;
            case "DRAYAGE":
                return 'DRAYAGE';
                break;
            default:
                return 'Unknown Mode';
                break;
        }
    }

    selectCompanyName(event: EldCompanyName) {
        this.eldDeviceName = [];
        this.uniqueEldDeviceName = [];
        const deviceNames = new Set<string>();

        for(const eldCompanyName of this.eldCompanyName){
            if(event.companyName !== eldCompanyName.companyName){
                continue;
            }

            if(!deviceNames.has(eldCompanyName.deviceName)){
                this.uniqueEldDeviceName.push(eldCompanyName);
                deviceNames.add(eldCompanyName.deviceName);
            }

            this.eldDeviceName.push(eldCompanyName);
        }

        this.uniqueEldDeviceName.sort((a,b) => a.deviceName.localeCompare(b.deviceName));
    }

    selectDeviceName(event: EldCompanyName) {
        if(!event) {
            return;
        }
        
        this.uniqueEldModelName = [];
        const eldModelNames = new Set<string>();

        for (const eldCompanyName of this.eldCompanyName){
            if(event.deviceName !== eldCompanyName.deviceName){
                continue;
            }

            if(!eldModelNames.has(eldCompanyName.modelNumber)){
                this.uniqueEldModelName.push(eldCompanyName);
                eldModelNames.add(eldCompanyName.modelNumber);
            }
        }

        this.uniqueEldModelName.sort((a,b) => a.modelNumber.localeCompare(b.modelNumber));
    }

    selectFuelProviders(event: any) {
        this.selectedFuelProviders.push(event);
        this.fuelProviders = this.fuelProviders.filter((obj:any) => {
            return obj.techName !== event.techName;
        });
    }

    deleteContractSigner(fuelProvider: any) {
        this.selectedFuelProviders = this.selectedFuelProviders.filter((obj:any) => {
            return obj.techName !== fuelProvider.techName;
        });
        this.fuelProviders.unshift(fuelProvider);
        this.fuelProviders = [...this.fuelProviders]
        this.fuelProvidersForm.controls['name'].setValue('');
    }


    submitFuelProviders() {
        let requestObs:any = [];
        for(let x = 0 ; x < this.selectedFuelProviders.length; x++) {
            requestObs.push(this.httpRequest.createFuelProvider({name: this.selectedFuelProviders[x].techName}));
        }
        return forkJoin(requestObs)
    }

    saveData(reason: string) {
        let data = Object.assign({}, this.passwordForm.value, this.fmcsa);
        let payload = {
            email: this.passwordForm.controls['email'].value,
            code: this.passwordForm.controls['verificationCode'].value,
            type: this.passwordForm.controls['type'].value,
            reason: reason,
            data: JSON.stringify(data)
        }
        this.httpRequest.leadGeneration(payload).subscribe((data) => {
            if(reason == 'No MC Number') {
                this.nextLegalEntity();
            } else if(reason == 'No Dot Number') {
                this.nextFMCSA();
            }
        })
    }

    openLinkAndCloseTab() {
        this.isLoadingCall = false;
        this.isLoadingDashboard = false;
        const calendlyUrl = environment.calendly_url;
        Calendly.initPopupWidget({ url: calendlyUrl });
    }

    redirectToDashboard() {
        this.router.navigate(['/pages/dashboard']);
    }

    isDOTlookupNeeded(): boolean | null{
        const subtype = this.shipperCompanyInfo_legalInfoControls['subtype'].value;
        if(!subtype){
            return null;
        }
        const subTypeCode = Number(subtype.split(' ')[0]);
        if(
            subTypeCode !== 491 
            && subTypeCode !== 493
            && subTypeCode >= 480
            && subTypeCode <=499
        ){
            return true;
        }
        return false;
    }
}
