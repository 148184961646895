import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { TrailerModel } from 'src/app/core/services/models/models';
import { removeFromArray } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';

@Component({
  selector: 'app-vehicle-trailers-tab',
  templateUrl: './vehicle-trailers-tab.component.html',
  styleUrls: ['./vehicle-trailers-tab.component.scss']
})
export class VehicleTrailersTabComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  allVehicles: TrailerModel[] = [];
  searchTerm: string = '';

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
  ) {
    super();
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async (data: any) => {
        return this.loadData();
      })
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    this.allVehicles = await this.httpService.listTrailers().toPromise();
  }

  deleteVehicles(trailer: TrailerModel) {
    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + trailer.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.httpService.deleteTrailer(trailer.trailerId).subscribe((data) => {
          this.allVehicles = removeFromArray(this.allVehicles, (a) => a.trailerId === trailer.trailerId);
        });
      }
    });
    
  }
}
