import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../core/services/http-service';
import { GeneralMessageService } from '../services/general-message.service';

@Component({
  selector: 'app-oauth-redeem',
  templateUrl: './oauth-redeem.component.html',
  styleUrls: ['./oauth-redeem.component.scss']
})
export class OauthRedeemComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private generalMessageService: GeneralMessageService,
    private httpService: HttpService,
  ) { }

  ngOnInit(): void {
    const sessionState = sessionStorage.getItem("state");
    const sessionToken = sessionStorage.getItem("token");
    const paramCode = this.route.snapshot.queryParams['code'];
    const paramState = this.route.snapshot.queryParams['state'];
    const paramType = this.route.snapshot.paramMap.get('type');

    if (sessionState !== paramState || !sessionToken || !paramCode || !paramType) {
      // console.error('State does not match session', {
      //   sessionState,
      //   paramState,
      // });
      this.generalMessageService.icon = {
        containerCls: 'avatar-title bg-light text-danger display-5 rounded-circle',
        cls: 'ri-error-warning-line',
      };
      this.generalMessageService.text = {
        header: 'Error',
        message: 'State does not match the session.',
      }
      this.router.navigateByUrl('/general-message', {skipLocationChange: true});
      return;
    }

    this.httpService.redeemOauth({
      token: sessionToken,
      code: paramCode,
    }, paramType).subscribe(response => {
      console.log(`[OauthRedeemComponent] response`, response);
      this.generalMessageService.text = {
        header: 'Success',
        message: '',
      }
      this.router.navigateByUrl('/general-message', {skipLocationChange: true});
    }, error => {
      console.error(`[OauthRedeemComponent] error`, error);
      this.generalMessageService.icon = {
        containerCls: 'avatar-title bg-light text-danger display-5 rounded-circle',
        cls: 'ri-error-warning-line',
      };
      this.generalMessageService.text = {
        header: 'Error',
        message: error.error.reason,
      }
      this.router.navigateByUrl('/general-message', {skipLocationChange: true});
    });
  }

}
