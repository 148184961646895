<app-breadcrumbs [breadCrumbItems]="breadCrumbItems" (close)="reloadComponentCancel()" *ngIf="showCard"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item">
                        <h5>
                            <span class="verification-text">
                                <i class="ri-close-circle-fill align-middle me-2 unverified"></i> Verify Company Information <button type="button" class="btn btn-sm btn-primary start-btn" (click)="verifyInformation('companyInformation')">Start</button>
                            </span>
                        </h5>
                    </li>
                    <li class="list-group-item">
                        <h5>
                            <span class="verification-text">
                                <i class="ri-close-circle-fill align-middle me-2 unverified"></i> Verify Transportation Information <button type="button" class="btn btn-sm btn-primary start-btn" (click)="verifyInformation('transportationInformation')">Start</button>
                            </span>
                        </h5>
                    </li>
                    <li class="list-group-item">
                        <h5>
                            <span class="verification-text">
                                <i class="ri-close-circle-fill align-middle me-2 unverified"></i> Verify Billing Information <button type="button" class="btn btn-sm btn-primary start-btn" (click)="verifyInformation('billing')">Start</button>
                            </span>
                        </h5>
                    </li>
                    <li class="list-group-item">
                        <h5>
                            <span class="verification-text">
                                <i class="ri-close-circle-fill align-middle me-2 unverified"></i> Verify Company Email Address <button type="button" class="btn btn-sm btn-primary start-btn" (click)="verifyInformation('companyEmail')">Start</button>
                            </span>
                        </h5>
                    </li>
                    <li class="list-group-item">
                        <h5>
                            <span class="verification-text">
                                <i class="ri-close-circle-fill align-middle me-2 unverified"></i> Verify Company Phone Number <button type="button" class="btn btn-sm btn-primary start-btn" (click)="verifyInformation('companyPhone')">Start</button>
                            </span>
                        </h5>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>