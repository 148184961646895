import {Component, Input, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngbd-modal-intro-wizard',
  templateUrl: './intro-wizard-modal.component.html',
  styleUrls: ['./intro-wizard-modal.component.scss'],

})
export class NgbdModalIntroWizard implements OnInit{
    step = 1;   
    passwordForm!: FormGroup;
    submitted = false;
    isChecked = false;
    @ViewChild('scroll', { read: ElementRef }) public scroll!: ElementRef<any>;
    public maxScroll!: number;
    fieldTextType!: boolean;
    showErrorRead = false;
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private httpRequest: HttpService,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.initForms();
        const pHeight = document.getElementById("terms-of-service")!.clientHeight;
        this.maxScroll = document.getElementById("terms-of-service")!.scrollHeight - pHeight;
    }

    initForms() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            firstName: [''],
            lastName: [''],
            title: [''],
            phone: [''],
        })
    }

    get form() {
        return this.passwordForm.controls;
    }

    close() {
        this.activeModal?.close();
    }

    checkedValue(event: any){
        this.isChecked = event.currentTarget.checked;
    }

    getPasswordRequirmentErrors(): string[] {
        const pw: string = this.form['password'] ? this.form['password'].value : '';
        const passwordRequirmentErrors: string[] = [];
        if (pw.length < 10) {
          passwordRequirmentErrors.push('Must have at least 10 characters in length')
        }
        if (!/[a-z]/g.test(pw)) {
          passwordRequirmentErrors.push('Must have lowercase letter')
        }
        if (!/[A-Z]/g.test(pw)) {
          passwordRequirmentErrors.push('Must have uppercase letter')
        }
        if (!/[0-9]/g.test(pw)) {
          passwordRequirmentErrors.push('Must have number')
        }
        if (!/[!@#&()\-/$=<>?]/g.test(pw)) {
          passwordRequirmentErrors.push('Must have special character')
        }
        return passwordRequirmentErrors;
      }

    submit() {
        this.submitted = true;
        if(this.passwordForm.invalid || this.getPasswordRequirmentErrors().length) {
            return;
        } else {
            this.close();
        }
    }

    toggleFieldTextType() {
      this.fieldTextType = !this.fieldTextType;
    }

    showError() {
      console.log('clicked');
      this.showErrorRead = true;
    }


}