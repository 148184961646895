import { ZipCodeCounts } from "src/app/shared/mapbox-heatmap/mapbox-heatmap.component";
import { isValidZipCode } from "../../utils/zip-code-utils";
import Swal from 'sweetalert2';

export interface CsvHeatMap {
  'Delivery Zip': string;
  'Service Type': string;
}

export type ZipcodeCacheValue = {
  serviceTypes: {
    [key in string]: number;
  },
};

export const SERVICE_TYPE_ALL = 'All';

export class HeatmapHelper {
  mapTabs: string[] = [SERVICE_TYPE_ALL];
  selectedMapTab: string = SERVICE_TYPE_ALL;
  zipCodesCache: {
    [key in string]: ZipcodeCacheValue;
  } = {}; // key = zipcode, value if basic count and advanced count
  zipCodesCount: ZipCodeCounts = {
    counts: {},
    total: 0,
    max: 0
  };

  convertUploadedFile(event: any): Promise<void> {
    return new Promise((resolve, reject) => {
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(event.target);
      // if (target.files.length !== 1) {
      //   throw new Error('Cannot use multiple files');
      // }
      const reader: FileReader = new FileReader();
      // reader.readAsBinaryString(target.files[0]);
      const file = target.files[0].name;
  
      if (file) {
        reader.readAsText(target.files[0]);
        reader.onload = () => {
          const json = this.csvJSON(reader.result as string);
          this.updateZipCodesCache(json);
          this.setServiceType(this.selectedMapTab);
          return resolve();
        };
      } else {
        return reject('file not supported');
      }
    });
  }
  
  private csvJSON(csvText: string): CsvHeatMap[] {
    const lines: string[] = csvText.replace('\r', '').split('\n');
  
    const result: CsvHeatMap[] = [];
  
    const headers: string[] = lines[0].split(',');
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) {
        continue; // skip
      }
      let obj: any = {};
      const currentline = lines[i].split(',');
  
      // for (var j = 0; j < headers.length; j++) {
      //   obj[headers[j]] = currentline[j]?.trim();
      // }
  
      // result.push(obj);
  
      result.push({
        "Delivery Zip": currentline[0]?.trim(),
        "Service Type": currentline[1]?.trim(),
      });
    }
  
    return result;
  }

  setServiceType(serviceType: string){
    this.selectedMapTab = serviceType;
    this.setZipCodesCount();
    // this.filteredMapsDataSources = this.getMapsDataSource();
  }

  private updateZipCodesCache(data: CsvHeatMap[]): void {
    this.zipCodesCache = {};
    const mapTabs: Set<string> = new Set();
    let usableData = false;
    data.forEach(d => {
      try {
        const deliveryZip = d['Delivery Zip'];
        if (!isValidZipCode(deliveryZip)) {
          return;
        }
        const serviceType = d['Service Type'];
        mapTabs.add(serviceType);
        if (!this.zipCodesCache[deliveryZip]) {
            // const [longitude, latitude] = this.mapService.getZipCodeLngLat(deliveryZip); // this.csvLibrary.find(csv => csv.Zip === deliveryZip)
          this.zipCodesCache[deliveryZip] = {
            serviceTypes: {
              [serviceType]: 0
            },
            // lat: +zip.Latitude,
            // lng: +zip.Longitude,
          };
          if (!usableData) {
            usableData = true;
          }
        }
    
        const currentCount = this.zipCodesCache[deliveryZip].serviceTypes[serviceType] || 0;
        this.zipCodesCache[deliveryZip].serviceTypes[serviceType] = currentCount + 1;
      } catch (e) {
        console.error('[updateZipCodesCache]', e);  // continue to next data
      }
    });

    this.mapTabs = [SERVICE_TYPE_ALL, ...mapTabs];
    if (!this.mapTabs.includes(this.selectedMapTab)) {
      this.selectedMapTab = SERVICE_TYPE_ALL;
    }

    if (!usableData) {
      Swal.fire({
        title: 'Error',
        text: 'Uploaded file have no usable data',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
    }
  }

  private setZipCodesCount(): void {
    const tmpZipCodesCount: ZipCodeCounts = {
      counts: {},
      total: 0,
      max: 0,
    };
    for (const [key, value] of Object.entries(this.zipCodesCache)) {
      const count = this.getCount(value);
      if (count === 0) {
        continue; // skip this zip
      }
      tmpZipCodesCount.counts[key] = count;
      tmpZipCodesCount.total += count;
      if (count > tmpZipCodesCount.max) {
        tmpZipCodesCount.max = count;
      }
    }
    this.zipCodesCount = tmpZipCodesCount;
  }

  private getCount(entry: ZipcodeCacheValue): number {
    if (this.selectedMapTab === SERVICE_TYPE_ALL) {
      return Object.values(entry.serviceTypes).reduce((prev, curr) => prev + curr);
    }

    return entry.serviceTypes[this.selectedMapTab] || 0;
  }
}

