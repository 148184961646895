import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
// import { DecimalPipe } from '@angular/common';

import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

// Sweet Alert
import Swal from 'sweetalert2';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { PageRequest, TablePaginationHelper } from 'src/app/core/services/models/table-pagination.helper';
import { PaginatedResults } from 'src/app/core/services/models/models';
import { Account } from 'src/app/core/services/models/account.model';
import { getDateDisplay, isArrayEmpty } from 'src/app/core/utils/commons';
import { map } from 'rxjs/operators';
import { NgbdModalLoadBoardDetails } from '../modal/load-board-details/load-board-details-modal.component';
import { HttpClient } from '@angular/common/http';

export type TableData = {
    accountId: string,
    branchId: string,
    contactEmail: string,
    contactLink: string,
    contactName: string,
    contactPhone: string,
    description: string,
    duration: number,
    isDeleted: boolean,
    isPublic: boolean,
    loadId: string,
    modeId: string,
    modeName: string,
    nylasSchedulingPageId: string,
    routeId: string,
    routeName: string,
    serviceAreaId: string,
    serviceAreaName: string,
    startDate: string,
    title: string,
    type: string,
    zoneId: string,
    zoneName: string,
    creationTime: number
    routePostalCodesImageUrl?: string;
};

@Component({
  selector: 'app-carrier-load-board',
  templateUrl: './carrier-load-board.component.html',
  styleUrls: ['./carrier-load-board.component.scss']
})
export class CarrierLoadBoardComponent implements OnInit {
  currentUser: any;
  activeModal?: NgbModalRef;

  searchForm!: FormGroup;
  tableHelper: TablePaginationHelper<TableData>;
  currentCarrier?: TableData;
  loading = false;
  loadBoards: any = [];

  constructor(
    private auth: AuthService,
    private httpRequest: HttpService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.currentUser = this.auth.currentUserValue;
    this.tableHelper = new TablePaginationHelper(
      {
        loadRecords: (pageRequest) => {
          // const name = (this.searchForm.controls['name'].value === '') ? '' : this.searchForm.controls['name'].value;
          // const dotNumber = (this.searchForm.controls['dotNumber'].value === '') ? '' : this.searchForm.controls['dotNumber'].value;
          // const zipCodes = (this.searchForm.controls['zipCodes'].value === '') ? [] : this.searchForm.controls['zipCodes'].value;
          return this.fetchCarriers(pageRequest);
      }
      }
    );
    this.loadRecords();
  }

  initForms() {
    this.searchForm = this.formBuilder.group({
      name: [''],
      dotNumber: [''],
      zipCodes: [''],
      hasAuthority: [true],
    });
  }
  
  get formSearch() {
    return this.searchForm.controls;
  }

  fetchCarriers(
    pageRequest?: PageRequest,
  ): Promise<PaginatedResults<TableData>> {
    return this.httpRequest.paginatedGetRequest<any>(
      '/loads'
      , {
        ...pageRequest
      }
    ).pipe(
      map(
        res => {
          return {
            ...res,
            results: res.results.map(
              data => ({
                title: data.title,
                description: data.description,
                type: data.type,
                duration: data.duration,
                startDate: data.startDate,
                loadId: data.loadId,
                accountId: data.accountId,
                branchId: data.branchId,
                contactEmail: data.contactEmail,
                contactLink: data.contactLink,
                contactName: data.contactName,
                contactPhone: data.contactPhone,
                isDeleted: data.isDeleted,
                isPublic: data.isPublic,
                modeName: data.modeName,
                nylasSchedulingPageId: data.nylasSchedulingPageId,
                routeId: data.routeId,
                routeName: data.routeName,
                serviceAreaId: data.serviceAreaId,
                serviceAreaName: data.serviceAreaName,
                zoneId: data.zoneId,
                zoneName: data.zoneName,
                creationTime: data.creationTime,
                routePostalCodesImageUrl: data.routePostalCodesImageUrl
              }) as TableData
            )
          };
        }
      )
    ).toPromise()

  }

  loadRecords(): void {
    this.loading = true;
    this.tableHelper.loadRecords().then(
      () => {
        const records = this.tableHelper.searchResult;
        if(!isArrayEmpty(records)){
          this.currentCarrier = records[0];
        }
        this.loading = false;
      }
    );
  }

  openModalDetails(event: TableData) {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalLoadBoardDetails, { size: 'xl', centered: true, backdrop: true, keyboard : false });
    modalRef.componentInstance.loadData = event;
  }

  getTimestamp(date: string) {
    return Date.parse(date);
  }

  getDurationRange(tableData: TableData): string{
    const startDate = new Date(tableData.startDate);
    const endDate = new Date(tableData.startDate);
    endDate.setMonth(endDate.getMonth() + (tableData.duration ?? 0))

    return `${getDateDisplay(startDate)} - ${getDateDisplay(endDate)}`;
  }

}
