<div class="row">
    <div class="col-xl-3 col-lg-3 col-xxl-3">
        <div class="card">
            <div class="card-header">
                <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" name="radioGroup" id="flexRadioDefault1" [value]="2" [(ngModel)]="activeTab" (click)="activeTab = 2; loadRecords()">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Shippers
                    </label>
                </div>
                <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" name="radioGroup" id="flexRadioDefault2" [value]="3" [(ngModel)]="activeTab" (click)="activeTab = 3; loadRecords()">
                    <label class="form-check-label" for="flexRadioDefault2">
                      Brokers
                    </label>
                </div>
                <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" name="radioGroup" id="flexRadioDefault3" [value]="0" [(ngModel)]="activeTab" (click)="activeTab = 0; loadRecords()">
                    <label class="form-check-label" for="flexRadioDefault3">
                      Shippers and Brokers
                    </label>
                </div>
            </div>
            <div class="card-body border-bottom">
                <form [formGroup]="searchForm">
                    <div class="search-box search-box-sm w-100 mb-2">
                        <input type="text" class="form-control bg-light border-0" placeholder="Name" (keyup.enter)="loadRecords()" formControlName="name">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    <div class="search-box search-box-sm w-100 mb-2">
                        <input type="text" class="form-control bg-light border-0" placeholder="DOT Number" (keyup.enter)="loadRecords()" formControlName="dotNumber">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    <div class="search-box search-box-sm w-100 mb-2">
                        <input type="text" class="form-control bg-light border-0" (keyup.enter)="loadRecords()" placeholder="City" formControlName="city">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    <div class="search-box search-box-sm w-100 mb-2">
                        <input type="text" class="form-control bg-light border-0" (keyup.enter)="loadRecords()" placeholder="State" formControlName="state">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    <div class="search-box search-box-sm w-100 mb-2">
                        <app-zipcode-picker [name]="'data'" [customClass]="'custom form-control'" (keyup.enter)="loadRecords()" [control]="$any(form['zipCodes'])"></app-zipcode-picker>
                        <i *ngIf="!form['zipCodes'].value || form['zipCodes'].value.length === 0" class="ri-search-line search-icon"></i>
                    </div>
                    <div class="search-box search-box-sm w-100 mb-2">
                        <input type="number" class="form-control bg-light border-0" placeholder="Radius" (keyup.enter)="loadRecords()" formControlName="radius">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                    <button class="btn btn-md btn-primary mt-2 w-100" data-bs-toggle="modal" [disabled]="loading" type="button" (click)="loadRecords()"> Search <div *ngIf="loading" class="spinner-border inline-block loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </form>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-xxl-4">
        <div>
            <div class="card">
                <div class="card-body pt-2">
                    <div class="table-responsive table-card mb-1">
                        <table class="table align-middle table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Search Results </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="loading">
                                <tr class="loader-body">
                                    <td class="placeholder-glow name-td">
                                        <span class="placeholder col-6"></span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!loading && tableHelper.searchResult.length != 0">
                                <tr role="button" *ngFor="let res of tableHelper.searchResult; let i=index;" (click)="
                                    currentCarrier = res;
                                    activeNdx = i;
                                ">
                                    <td class="w-75">
                                        <span>
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div class="avatar-sm bg-light rounded p-1">
                                                        <img [src]="res.logoUrl" alt="" class="img-fluid d-block" (error)="handleError($event)">
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-14 mb-1">
                                                        <span class="text-dark">{{ res.legalName }}</span>
                                                    </h5>
                                                    <p class="text-muted mb-0" *ngIf="res.city && res.state">
                                                        <span>{{res.city}}, {{res.state}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </span>
                                    </td>
                                    <td>
                                        <button *ngIf="originalLoadBoard && res.accountId" class="btn btn-sm btn-primary" (click)="createProposal(res)"> Invite </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <app-table-pagination *ngIf="!loading" [tableHelper]="tableHelper"></app-table-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-xxl-5">
        <div class="card" id="company-view-detail">
            <div class="card-body text-center company-details">
                <div class="position-relative d-inline-block">
                    <div class="avatar-md">
                        <div class="avatar-title bg-light rounded">
                            <img [src]="currentCarrier?.logoUrl" alt="" class="avatar-lg object-cover" (error)="handleError($event)">
                        </div>
                    </div>
                </div>
                <h5 class="mt-3 mb-1">{{currentCarrier?.legalName}}</h5>
                <p class="text-muted" *ngIf="currentCarrier?.city && currentCarrier?.state">{{currentCarrier?.city}}, {{currentCarrier?.state}}</p>
                <p class="text-muted" *ngIf="currentCarrier?.phone">{{currentCarrier?.phone}}</p>
                <ul class="list-inline mb-0">
                    <li class="list-inline-item avatar-xs">
                        <a href="#" class="avatar-title bg-light text-muted fs-15 rounded" *ngIf="isTrial();" (click)="stopRedirect($event)">
                            <i class="ri-global-line"></i>
                        </a>
                        <a [href]="domain + '/pages/dedicated-load-board'" class="avatar-title bg-soft-success text-successfs-15 rounded" *ngIf="!isTrial();">
                            <i class="ri-global-line"></i>
                        </a>
                    </li>
                    <li class="list-inline-item avatar-xs">
                        <a [href]="'mailto:' + currentCarrier?.email" class="avatar-title bg-soft-danger text-danger fs-15 rounded">
                            <i class="ri-mail-line"></i>
                        </a>
                    </li>
                    <li class="list-inline-item avatar-xs">
                        <a href="#" class="avatar-title bg-light text-muted fs-15 rounded"  *ngIf="isTrial();" (click)="stopRedirect($event)">
                            <i class="ri-question-answer-line"></i>
                        </a>
                        <a [href]="domain + '/pages/messages'" class="avatar-title bg-soft-warning text-warning fs-15 rounded" *ngIf="!isTrial();">
                            <i class="ri-question-answer-line"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <h6 class="text-muted text-uppercase fw-semibold mb-3">Description</h6>
                <p class="text-muted mb-4">
                    {{currentCarrier?.description}}
                </p>
                <div class="table-responsive table-card">
                    <table class="table table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td class="fw-medium" scope="row">Account Type</td>
                                <td class="industry_type" *ngIf="currentCarrier?.type == 'shipper-account'">Shipper</td>
                                <td class="industry_type" *ngIf="currentCarrier?.type == 'carrier-account'">Carrier</td>
                                <td class="industry_type" *ngIf="currentCarrier?.type == 'broker-account'">Broker</td>
                                <td class="industry_type" *ngIf="currentCarrier?.type == 'driver-account'">Driver</td>
                                <td class="industry_type" *ngIf="currentCarrier?.type == 'helper-account'">Helper</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Type</td>
                                <td class="industry_type">{{currentCarrier?.subtype}}</td>
                            </tr>
                            <tr>
                            <tr>
                                <td class="fw-medium" scope="row" *ngIf="currentCarrier?.city && currentCarrier?.state">Location</td>
                                <td class="location" *ngIf="currentCarrier?.city && currentCarrier?.state">{{currentCarrier?.city}}, {{currentCarrier?.state}}</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Entity</td>
                                <td class="employee" *ngIf="currentCarrier?.legalEntity == 'sole-proprietor'">Sole Proprietor</td>
                                <td class="employee" *ngIf="currentCarrier?.legalEntity == 'llc'">LLC</td>
                                <td class="employee" *ngIf="currentCarrier?.legalEntity == 's-corp'">S Corporation</td>
                                <td class="employee" *ngIf="currentCarrier?.legalEntity == 'c-corp'">C Corporation</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Employees</td>
                                <td>
                                    {{currentCarrier?.numberOfEmployees}}
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Website</td>
                                <td>
                                    <a [href]="currentCarrier?.website" class="link-primary text-decoration-underline website">{{currentCarrier?.website}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Contact Email</td>
                                <td class="email">{{currentCarrier?.email}}</td>
                            </tr>
                            <tr>
                                <td class="fw-medium" scope="row">Since</td>
                                <td class="since">{{currentCarrier?.foundingYear}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>