<div class="justify-content-md-between align-items-md-center mx-0" *ngIf="(tableHelper?.total$ | async) as total">
    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
        Showing {{tableHelper.startIndex}} to {{tableHelper.endIndex}} of {{total}} entries
    </div>
    <div class="text-sm-right float-sm-end listjs-pagination">
        <ngb-pagination [collectionSize]="total" [(page)]="tableHelper.page" [pageSize]="tableHelper.pageSize"
            [maxSize]="10"
            (pageChange)="tableHelper.loadRecords($event)"
            [boundaryLinks]="true"
            [rotate]="true"
        >
        </ngb-pagination>
    </div>
</div>