import {ErrorHandler, Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExceptionsApiService } from '../services/exceptions-api.service';

const CLEAR_ERRORS_MS = 10000;   // how many ms before errors array is cleared, so same error can be logged again
const MAX_ERROR_COUNTER = 10;   // max number of times that the same error is sent

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
    errors: string[] = [];
    errorsCounter: {
        [k in string]: number
    } = {};

    constructor(
        private exceptionsApiService: ExceptionsApiService
    ) {
        super();
        setInterval(() => {
            this.errors = [];
        }, CLEAR_ERRORS_MS);
    }

    override handleError(error: any) {
        const errorObj = this.objectifyError(error);
        const errorStr = JSON.stringify(errorObj);
        if (!this.errorsCounter[errorStr]) {
            this.errorsCounter[errorStr] = 0;
        }
        
        if (this.errors.some(e => e === errorStr) || this.errorsCounter[errorStr] >= MAX_ERROR_COUNTER) {
            // error already logged previously
            super.handleError(error);
            return;
        }

        this.errorsCounter[errorStr]++; // only increase counter if we are sending to API
        this.errors.push(errorStr);

        if (!environment.production) {
            super.handleError(error);
            return;
        }

        return this.exceptionsApiService.postExceptions(errorObj).toPromise()
            .then(() => {
                try {
                    super.handleError(error);
                } catch (err) {
                    console.error('GlobalErrorHandler: error handling error 1', err);
                }
            })
            .catch(err => {
                console.error('GlobalErrorHandler: error handling error 2', err);
                super.handleError(error);
            });
    }

    private objectifyError(error: any): Record<string, unknown> {
        if (!error) {
            return this.addExtraData({});
        }

        if (error instanceof Error) {
            return this.addExtraData({
                stack: error.stack,
                message: error.stack ? undefined : error.message
            });
        }

        if (typeof error === 'object') {
            return error;
        }

        return this.addExtraData({
            message: error
        });
    }

    private addExtraData(error: Record<string, unknown>): Record<string, unknown> {
        error['url'] = window.location.href;
        return error;
    }
}