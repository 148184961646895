import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';
import { MonthlyPlanModel } from 'src/app/plans-and-pricing/pricing.model';



@Component({
  selector: 'app-carrier-new',
  templateUrl: './carrier-new.component.html',
  styleUrls: ['./carrier-new.component.scss']
})
export class CarrierNewComponent implements OnInit {
  signUpForm!: FormGroup;
  submitted = false;
  isLoading = false;
  isSuccess = false;
  isFailed = false;
  hasSelectedPlan = false;

  transpoModes: string[] = [];
  selectedRole = '';
  selectedPricingPlan: any;
  monthlyPlan: MonthlyPlanModel;

  colClass = 'col-6';
  righAlignClass = 'text-align-right';
  isMobile: boolean = false;
  
  constructor(
    private httpRequestService: HttpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateColClass();
  }

  ngOnInit(): void {
    this.initForm();
    this.route.queryParams.subscribe(params => {
      if(params['plan']) {
        this.selectedPricingPlan = params['plan'];
        this.signUpForm.controls['plan'].setValue(this.selectedPricingPlan);
        this.hasSelectedPlan = true;
      } else {
        this.hasSelectedPlan = false;
      }
    });
    this.updateColClass();
  }

  initForm() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.signUpForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: [''],
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      phone: ['', [Validators.required]],
      type: ['carrier', [Validators.required]],
      companyInformation: this.formBuilder.group({
        name: ['', [Validators.required]],
        transportationMode: ['', [Validators.required]],
      }),
      plan: ['']
    });
  }

  get f() {
    return this.signUpForm.controls;
  }

  get formCompanyInformation() {
    return (this.f['companyInformation'] as FormGroup).controls;
  }

  selectedPlan(event: any) {
    if(event) {
      this.monthlyPlan = event;
      this.selectedPricingPlan = event;
      this.hasSelectedPlan = true;
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl('/carrier?plan=' + this.selectedPricingPlan.url);
      this.signUpForm.controls['plan'].setValue(this.selectedPricingPlan.type);
    }
  }

  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  submitForm() {
    this.submitted = true;
    this.isLoading = true;
    this.isSuccess = false;
    this.isFailed = false;
    if(this.signUpForm.invalid || !this.transpoModes.length) {
      this.isLoading = false;
      return;
    } else {
      let metaData = this.signUpForm.value;
      delete metaData['email'];
      this.httpRequestService.verifyEmail(this.signUpForm.controls['email'].value, metaData, 'signup').subscribe((data: any) => {
        this.initForm();
        this.router.navigate(['/sign-up-success']);
        this.isLoading = false;
        this.submitted = false;
      }, error => {
        this.submitted = false;
        this.isFailed = true;
        this.isLoading = false;
      })
    }
  }

  selectPermission(): void {
    this.selectedRole = ''
    this.formCompanyInformation['transportationMode'].setValue(this.transpoModes);
  }

  updateColClass() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 993) {
      this.colClass = 'col-12';
      this.righAlignClass = '';
      this.isMobile = true;
    } else {
      this.colClass = 'col-6';
      this.righAlignClass = 'text-align-right';
      this.isMobile = false;
    }

  }
}
