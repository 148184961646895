import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, forkJoin, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse, TrailerTypeModel, TruckTypeModel } from 'src/app/core/services/models/models';
import { PrettyTechnicalName } from 'src/app/core/services/models/pretty-technical-name';
import { ServiceArea, ServiceAreaZone } from 'src/app/core/services/models/service-areas.model';
import { ServiceType } from 'src/app/core/services/models/service-type.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { Zone } from 'src/app/core/services/models/zone.model';
import Swal from 'sweetalert2';
import { ServiceTypeTruckTrailerCombo, ServiceTypeTruckTrailerComboUtil } from 'src/app/core/services/models/service-type-truck-trailer-combo.model';
import { CapacityGroup, CapacityGroupPayload } from 'src/app/core/services/models/capacity-group.model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DAYS, isArrayEmpty } from 'src/app/core/utils/commons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CapacityGroupFormDto } from 'src/app/shared/capacity-form/capacity-form.component';
import { Capacity, NgbdModalSaveCapacity } from '../../zones-and-routes/modals/save-capacity/modal-save-capacity.component';
import { CapacityRulePayload } from 'src/app/core/services/models/capacity-rule.model';

@Component({
  selector: 'app-save-capacity',
  templateUrl: './save-capacity.component.html',
  styleUrls: ['./save-capacity.component.scss']
})
export class SaveCapacityComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Capacities',
      url: '/pages/capacity'
    },
    {
      label: this.auth.currentAccountSelected.accountName!
    },
    {
      label: 'Add'
    }
  ];
  selectedServiceArea?: ServiceArea;
  selectedZone?: ServiceAreaZone;
  saveLoading = false;
  originalCapacityGroup?: CapacityGroup;
  capacityForm!: FormGroup;
  submitted = false;
  trailerTypeIdMap: {[key: string]: TrailerTypeModel} = {};
  serviceTypeId_truckTrailerCombosMap: {[key: string]: ServiceTypeTruckTrailerCombo[]} = {};
  id_truckTrailerCombosMap: {[key: string]: ServiceTypeTruckTrailerCombo} = {};
  truckTypeIdMap: {[key: string]: TruckTypeModel} = {};
  truckTrailerComboOptions: PrettyTechnicalName[] = [];
  serviceAreaOptions: ServiceArea[] = [];
  serviceTypeOptions: PrettyTechnicalName[] = [];
  serviceTypeIdMap: {[key: string]: ServiceType} = {};
  zoneOptions: ServiceAreaZone[] = [];
  modeOptions: Mode[] = [];
  postalCodeOptions: string[] = [];
  selectedServiceTypes: ServiceType[] = [];
  selectedServiceTypeIds: string[] = [];
  capacityModalClicked = false;

  activeModal?: NgbModalRef;
  capacityGroups: CapacityGroupFormDto[] = [];
  capDataDefault?: CapacityGroupFormDto;

  private subscriptions: Subscription[] = [];
  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  private selectedAccount: (Account | null);

  capacities: Capacity[] = [];

  constructor(
    private router: Router,
    private httpRequest: HttpService,
    private auth: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.loadBySelectedEntities();
        }
      )
    );
  }

  private loadBySelectedEntities(): void{

    this.httpRequest.getServiceAreas(
      this.selectedMode!.modeId!,
      this.selectedBranch?.branchId!
    ).subscribe(res => this.serviceAreaOptions = res.data);

    this.httpRequest.listServiceTypes(
      this.selectedMode!.modeId!
    ).subscribe(
      res => {
        const successRes = <SuccessApiResponse<ServiceType[]>>res;
        const serviceTypes = successRes.data;
        this.setServiceTypeMetas(serviceTypes);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service types: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private setServiceTypeMetas(serviceTypes: ServiceType[]){
    this.serviceTypeOptions = [];
    this.serviceTypeIdMap = serviceTypes.reduce(
      (map: {[key: string]: ServiceType} , serviceType)=> {
        this.serviceTypeOptions.push({
          prettyName: `${serviceType.name} (${serviceType.averageDeliveryTime} mins) - ${serviceType.serviceWindowName}`,
          technicalName: serviceType.serviceTypeId
        })
        map[serviceType.serviceTypeId] = serviceType;
        return map;
      }, 
      {}
    );
  }

  serviceAreaSelected(event: any) {
    this.serviceAreaIdSelected(event.value);
  }

  private serviceAreaIdSelected(serviceAreaId: string){
    this.selectedServiceArea = this.serviceAreaOptions.find(serviceArea => serviceArea.serviceAreaId === serviceAreaId);
    this.zoneOptions = this.selectedServiceArea?.zones || [];
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnInit(): void {
    this.originalCapacityGroup = (this.location.getState() as {[key: string]: any})['capacityGroup'];
    if(this.originalCapacityGroup){
      this.breadCrumbItems = [
        {
          label: 'Routes',
          url: '/pages/capacity'
        },
        {
          label: this.originalCapacityGroup.name
        },
        {
          label: 'Edit'
        }
      ];
      this.httpRequest.fetchCapacityRuleList(this.originalCapacityGroup.capacityGroupId).subscribe(
        res => {
          console.log('[fetchCapacityRuleList] res', JSON.stringify(res));
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to fetch capacity rules: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    }
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get capacityFormControls(): { [key: string]: AbstractControl } {
    return this.capacityForm.controls;
  }

  private async initForm(){
    this.setDefaultForm();

    if(this.originalCapacityGroup){
      const serviceTypeIds = this.originalCapacityGroup.serviceTypes ?? []
      const serviceAreaId = this.originalCapacityGroup.serviceAreaId;

      if(!serviceAreaId && isArrayEmpty(serviceTypeIds)){
        this.setDefaultForm();
        return;
      }

      if(serviceAreaId){
        this.httpRequest.getServiceArea(serviceAreaId).subscribe(
          res => {
            const successRes = <SuccessApiResponse<ServiceArea>> res;
            const serviceArea = successRes.data;
            this.zoneOptions = serviceArea.zones;
          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Failed to fetch service area: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      }

      if(!isArrayEmpty(serviceTypeIds)){
        const httpServiceTypeCalls = serviceTypeIds.map(
          serviceTypeId => this.httpRequest.getServiceType(serviceTypeId)
        );
        const httpComboCalls = serviceTypeIds.map(
          serviceTypeId => this.httpRequest.listServiceTypeTruckTrailerCombos(serviceTypeId)
        );

        forkJoin(
          {
            serviceTypes: forkJoin(httpServiceTypeCalls),
            comboTrailers: forkJoin(httpComboCalls)
          }
        ).subscribe(
          ({serviceTypes: serviceTypesRes, comboTrailers: comboTrailersRes}) => {
            const serviceTypes = serviceTypesRes.map(
              res => {
                const successRes = <SuccessApiResponse<ServiceType>> res;
                return successRes.data;
              }
            );
            this.setServiceTypeMetas(serviceTypes);
            this.serviceTypeMultipleSelected(serviceTypeIds);
            const serviceTypeTruckTrailerCombos = comboTrailersRes.flatMap(
              res => {
                const successRes = <SuccessApiResponse<ServiceTypeTruckTrailerCombo[]>> res;
                return successRes.data;
              }
            );

            const {truckTypeId, trailerTypeId} = this.originalCapacityGroup!;
  
            const truckTrailerComboId = serviceTypeTruckTrailerCombos.find(
              combo => combo.truckType === truckTypeId && combo.trailerType1 === trailerTypeId
            )?.serviceTypeTruckTrailerComboId;

            this.setDefaultForm(truckTrailerComboId);

          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Failed to load service types and/or combo trailers: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      }
    }
  }

  setDefaultForm(truckTrailerComboId = ''){
    this.selectedServiceTypeIds = this.originalCapacityGroup?.serviceTypes ?? [];
    this.postalCodeOptions = this.originalCapacityGroup?.postalCodes ?? [];
    this.capacityForm = this.formBuilder.group({
      name: [this.originalCapacityGroup?.name, [Validators.required]],
      isEnabled: [this.originalCapacityGroup?.isEnabled ?? true, [Validators.required]],
      serviceArea: [this.originalCapacityGroup?.serviceAreaId, [Validators.required]],
      zoneId: [this.originalCapacityGroup?.zoneId, [Validators.required]],
      truckTrailerCombo: [truckTrailerComboId,[Validators.required]]
    });
    if(this.originalCapacityGroup){
      this.serviceAreaIdSelected(this.originalCapacityGroup.serviceAreaId);
      this.zoneIdSelected(this.originalCapacityGroup.zoneId);
    }
  }

  zoneSelected(event: any){
    this.zoneIdSelected(event.value);
  }

  private zoneIdSelected(zoneId: string){
    this.httpRequest.getZonesViaId(zoneId).subscribe(
      res => {
        this.selectedZone = (res.data as Zone);
        this.modeOptions = (res.data as Zone)?.modes || [];
        this.postalCodeOptions = this.selectedZone?.postalCodes || [];
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch zones: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  serviceTypeChanged(){
    if(this.selectedServiceTypeIds.length < this.selectedServiceTypes.length){
      const unselectedServiceTypes = this.selectedServiceTypes.filter(
        serviceType => !this.selectedServiceTypeIds.includes(serviceType.serviceTypeId)
      );
      unselectedServiceTypes.forEach(
        unselected => this.unselectServiceType(unselected)
      );
    }else{
      const currentServiceTypeIds = this.selectedServiceTypes.map(
        serviceType => serviceType.serviceTypeId
      );
      const newServiceTypeIds = this.selectedServiceTypeIds.filter(
        id => !currentServiceTypeIds.includes(id)
      );
      this.serviceTypeMultipleSelected(newServiceTypeIds);
    }
  }

  serviceTypeMultipleSelected(serviceTypeIds: string[]){
    const serviceTypes = serviceTypeIds.map(serviceTypeId => this.serviceTypeIdMap[serviceTypeId]);
    serviceTypes.forEach(
      serviceType => this.selectedServiceTypes.push(serviceType)
    );

    const comboCalls = serviceTypeIds.map(
      serviceTypeId => this.httpRequest.listServiceTypeTruckTrailerCombos(serviceTypeId)
    );

    forkJoin(comboCalls).subscribe(
      combosRes => {
        const combos = combosRes.flatMap(
          res => {
            const successRes = <SuccessApiResponse<ServiceTypeTruckTrailerCombo[]>> res;
            return successRes.data;
          }
        );

        const serviceTypeId_combo = combos.reduce( 
          (acc, cur) => {
            if(!acc.has(cur.serviceTypeId)){
              acc.set(cur.serviceTypeId, []);
            }
            acc.get(cur.serviceTypeId)?.push(cur);
            return acc;
          },
          new Map<string, ServiceTypeTruckTrailerCombo[]>()
        );

        serviceTypeId_combo.forEach(
          (serviceTypeIdCombo, serviceTypeId)=>{
            this.serviceTypeId_truckTrailerCombosMap[serviceTypeId] = serviceTypeIdCombo;
          }
        );

        combos.forEach(
          combo => this.id_truckTrailerCombosMap[combo.serviceTypeTruckTrailerComboId] = combo
        );

        this.fetchTruckTypesAndTrailerTypes(combos);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch service type truck trailer combos: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    )
  }

  unselectServiceType(serviceType: ServiceType) {
    const {serviceTypeId} = serviceType;
    this.selectedServiceTypes = this.selectedServiceTypes.filter(
      current => current.serviceTypeId !== serviceTypeId
    );
    
    const truckTrailerCombos = this.serviceTypeId_truckTrailerCombosMap[serviceTypeId] ?? [];;
    const truckTrailerComboIds = new Set<string>(
      truckTrailerCombos.map(combo => combo.serviceTypeTruckTrailerComboId)
    );
    this.truckTrailerComboOptions = this.truckTrailerComboOptions
      .filter(option => !truckTrailerComboIds.has(option.technicalName));
  }

  private fetchTruckTypesAndTrailerTypes(truckTrailerCombos: ServiceTypeTruckTrailerCombo[]): void{
    const truckTypeIds = new Set<string>(
      truckTrailerCombos.filter(combo => combo.truckType).map(combo => combo.truckType)
    );
    const trailerTypeIds = new Set<string>(
      truckTrailerCombos.filter(combo => combo.trailerType1).map(combo => combo.trailerType1)
    );

    const truckTypeIdCalls = [...truckTypeIds].map(truckTypeId => this.httpRequest.getTruckType(truckTypeId));
    const trailerTypeIdCalls = [...trailerTypeIds].map(trailerTypeId => this.httpRequest.getTrailerType(trailerTypeId));

    forkJoin(
      {
        truckTypeResponses: truckTypeIdCalls.length === 0 ? of([]): forkJoin(truckTypeIdCalls),
        trailerTypeResponses: trailerTypeIdCalls.length === 0 ? of([]): forkJoin(trailerTypeIdCalls)
      }
    ).subscribe(
      ({truckTypeResponses, trailerTypeResponses}) => {
        truckTypeResponses.forEach(
          truckTypeResponse => {
            const successRes = <SuccessApiResponse<TruckTypeModel>> truckTypeResponse;
            const truckType = successRes.data;
            this.truckTypeIdMap[truckType.truckTypeId] = truckType;
          }
        );
        trailerTypeResponses.forEach(
          trailerTypeResponse => {
            const successRes = <SuccessApiResponse<TrailerTypeModel>> trailerTypeResponse;
            const trailerType = successRes.data;
            this.trailerTypeIdMap[trailerType.trailerTypeId] = trailerType;
          }
        );
        const existingComboOptionIds = new Set<string>(
          this.truckTrailerComboOptions.map(option => option.technicalName)
        );
        this.truckTrailerComboOptions = [
          ...this.truckTrailerComboOptions,
          ...truckTrailerCombos.filter(
            combo => !existingComboOptionIds.has(combo.serviceTypeTruckTrailerComboId)
          ).map(
            combo => ({
              prettyName: ServiceTypeTruckTrailerComboUtil.getPrettyNameWithCapacity(
                combo, this.truckTypeIdMap, this.trailerTypeIdMap
              ),
              technicalName: combo.serviceTypeTruckTrailerComboId
            })
          )
        ];
      }
    );
  }

  saveCapacity(){
    this.submitted = true;
    const {truckTrailerCombo} = this.capacityForm.value;
    const truckTrailerComboObj = this.id_truckTrailerCombosMap[truckTrailerCombo];

    const payload: CapacityGroupPayload = {
      ...this.capacityForm.value,
      branchId: this.selectedBranch?.branchId!,
      postalCodes: this.postalCodeOptions,
      serviceTypes: this.selectedServiceTypeIds,
      truckTypeId: truckTrailerComboObj.truckType,
      trailerTypeId: truckTrailerComboObj.trailerType1
    }

    let httpCall;

    if(this.originalCapacityGroup){
      httpCall = this.httpRequest.updateCapacitGroup(
        this.originalCapacityGroup.capacityGroupId, payload
      );
    }else{
      httpCall = this.httpRequest.createCapacitGroup(payload);
    }
    
    httpCall.subscribe(
      res => {
        const succcessRes = <SuccessApiResponse<CapacityGroup>> res;
        const capacityGroup = succcessRes.data;
        const capacityRulePaylods: CapacityRulePayload[] = this.capacities.flatMap(
          capacity => {
            return capacity.days?.map(
              day => ({
                ...capacity,
                day: DAYS.findIndex(cur => cur === day),
                startTime: capacity.startTime!,
                endTime: capacity.endTime!,
                units: Number(capacity.units!),
                totalVolume: Number(capacity.totalVolume!),
                totalWeight: Number(capacity.totalWeight!),
                stops: Number(capacity.stops!),
                capacityGroupId: capacityGroup.capacityGroupId,
              })
            ) ?? [];
          }
        );

        if(isArrayEmpty(capacityRulePaylods)){
          Swal.fire({
            title: 'Success',
            text: 'Successfully saved capacity.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.router.navigateByUrl('/pages/capacity');
          });
        }else{
          const httpCalls = capacityRulePaylods.map(
            payload => this.httpRequest.addCapacityRuleByGroupId(
              capacityGroup.capacityGroupId,
              payload
            )
          );

          forkJoin(httpCalls).subscribe(
            res => {
              Swal.fire({
                title: 'Success',
                text: 'Successfully saved capacity.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
              }).then((result) => {
                this.router.navigateByUrl('/pages/capacity');
              });
            },
            error => {
              Swal.fire({
                title: 'Error',
                text: 'Failed to save capacity rule: ' + error.error.reason,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
              }).then((result) => {
                //do nothing
              });
            }
          );
        }        
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to create capacity group: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  delete(){
    this.httpRequest.deleteCapacityGroup(this.originalCapacityGroup?.capacityGroupId!).subscribe(
      res => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted capacity group.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          this.router.navigateByUrl('/pages/capacity');
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete capacity group: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  openCapacityEditModal(): void {
    this.capacityModalClicked = true;
    const {truckTrailerCombo} = this.capacityForm.value;
    if(this.selectedServiceTypes.length === 0 || !truckTrailerCombo){
      return;
    }
    
    this.activeModal?.close();
    
    const modalRef = this.modalService.open(
      NgbdModalSaveCapacity, 
      {
        size: 'lg',
        centered: true,
        modalDialogClass: 'full-page-modal-container'
      }
    );
    
    modalRef.componentInstance.serviceTypes = [...this.selectedServiceTypes];
    modalRef.componentInstance.zipCodes = [...this.postalCodeOptions];
    modalRef.componentInstance.truckTrailerCombo = this.id_truckTrailerCombosMap[truckTrailerCombo];
    modalRef.componentInstance.onCapacities.subscribe(
      (capacities: Capacity[])  => {
        this.capacities = capacities
      }
    )
  }

}
