import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpService } from 'src/app/core/services/http-service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngbd-modal-custom-permission',
  templateUrl: './custom-permission.component.html',
  styleUrls: ['./custom-permission.component.scss'],

})
export class NgbdModalCustomPermission implements OnInit{
  permissionForm!: FormGroup;
  submitted = false;
  permissions: string[] = [];
  selectedRole = '';
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private cd: ChangeDetectorRef,
    private router: Router
   ) {}

  ngOnInit(): void {
    this.initForms();
  }

  close() {
    this.activeModal?.close();
  }

  initForms() {
    this.permissionForm = this.formBuilder.group({
      newRole: [false, [Validators.required]],
      roleName: ['', [Validators.required]],
      permissions: ['', [Validators.required]],
    });
  }

  get form() {
    return this.permissionForm.controls;
  }

  selectPermission(): void {
    this.selectedRole = ''
  }


}