import { NgModule }      from '@angular/core';
import { LineBreaksPipe } from './linebreak.pipe';

 @NgModule({
     imports:        [],
     declarations:   [LineBreaksPipe],
     exports:        [LineBreaksPipe],
 })

 export class LineBreaksModule {

   static forRoot() {
      return {
          ngModule: LineBreaksModule,
          providers: [],
      };
   }
 } 