/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { AccountsPayableModel } from '../models/accounts-payable.model';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from './gridjs-sortable.directive';
import { HttpService } from 'src/app/core/services/http-service';

interface SearchResult {
  accountsPayable: AccountsPayableModel[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(accountsPayable: AccountsPayableModel[], column: SortColumn, direction: string): AccountsPayableModel[] {
  if (direction === '' || column === '') {
    return accountsPayable;
  } else {
    return [...accountsPayable].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(accountsPayable: AccountsPayableModel, term: string, pipe: PipeTransform) {
  if (term == 'yes' || term == 'Yes') {
    return String(accountsPayable.isDefault).toLowerCase().includes('true');
  } else if (term == 'no' || term == 'No') {
    return String(accountsPayable.isDefault).toLowerCase().includes('false');
  } else {
    return accountsPayable.accountPayableId.toLowerCase().includes(term.toLowerCase())
      || accountsPayable.accountId.toLowerCase().includes(term.toLowerCase())
      || String(accountsPayable.isDefault).toLowerCase().includes(term.toLowerCase())
      || accountsPayable.name.toLowerCase().includes(term.toLowerCase())
      ;
  }
}

@Injectable({ providedIn: 'root' })
export class AccountsPayableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _accountsPayable$ = new BehaviorSubject<AccountsPayableModel[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    startIndex: 0,
    endIndex: 9,
    totalRecords: 0
  };

  private data: any;

  constructor(private pipe: DecimalPipe, private httpRequest: HttpService) {
    httpRequest.getAccountsPayable().subscribe((result: any) => {
      this.data = result.data;
      this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._accountsPayable$.next(result.accountsPayable);
        this._total$.next(result.total);
      });

      this._search$.next();
    });
  }

  get accountsPayable$() { return this._accountsPayable$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get startIndex() { return this._state.startIndex; }
  get endIndex() { return this._state.endIndex; }
  get totalRecords() { return this._state.totalRecords; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  set startIndex(startIndex: number) { this._set({ startIndex }); }
  set endIndex(endIndex: number) { this._set({ endIndex }); }
  set totalRecords(totalRecords: number) { this._set({ totalRecords }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let accountsPayable = sort(this.data, sortColumn, sortDirection);
    // 2. filter
    accountsPayable = accountsPayable.filter(accountsPayable => matches(accountsPayable, searchTerm, this.pipe));
    const total = accountsPayable.length;

    // 3. paginate
    this.totalRecords = accountsPayable.length;
    this._state.startIndex = (page - 1) * this.pageSize + 1;
    this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    accountsPayable = accountsPayable.slice(this._state.startIndex - 1, this._state.endIndex);
    return of({ accountsPayable, total });
  }

  updateTable() {
    this.httpRequest.getAccountsPayable().subscribe((result: any) => {
      this.data = result.data;
      this._search$.pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {
        this._accountsPayable$.next(result.accountsPayable);
        this._total$.next(result.total);
      });

      this._search$.next();
    });
  }
}
