import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { HttpService } from 'src/app/core/services/http-service';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-invitations',
  templateUrl: './user-invitations.component.html',
  styleUrls: ['./user-invitations.component.scss']
})
export class UserInvitationsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Invitations'}
  ];
  cols!: any[];
  loading!: boolean;
  lazy: false;
  files!: TreeNode[];
  selectedRow: any;
  data: any = [];

  constructor(
    private router: Router,
    private httpRequest: HttpService
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'name', header: 'Name', width: '33%' },
    ];

    this.getData();
  }

  getData() {
    this.httpRequest.getUserInvitations().subscribe((data) => {
      this.data = data.data;
      this.files = this.manipulateData(data.data);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }, error => {
      console.error('Error: ', error);
    });
  }

  manipulateData(dataFromAPI: any) {
    let result: any = [];
    for(let x = 0; x < dataFromAPI.length; x++){
        let data = {
            accountId: dataFromAPI[x].accountId,
            email: dataFromAPI[x].email,
            expiration: dataFromAPI[x].expiration,
            status: dataFromAPI[x].status,
            userId: dataFromAPI[x].userId,
            userInvitationId: dataFromAPI[x].userInvitationId
        }
        if(dataFromAPI[x].type == 'store' || dataFromAPI[x].type == 'remote-store' || dataFromAPI[x].type == '3pl-cross-dock') {
          result.push({data: data, leaf: true})
        } else {
          result.push({data: data, leaf: false})
        }
    }
    return result;
  }

  acceptInvitation(row: any): void {
    this.httpRequest.acceptUserInvitations(row.userInvitationId).subscribe((data) => {
      this.files = this.files.filter(f => f.data.userInvitationId !== row.userInvitationId);
      Swal.fire({
        title: 'Success',
        text: 'Invitation accepted.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }, error => {
      console.error('Error: ', error);
    });
  }

  declineInvitation(row: any): void {
    this.httpRequest.declineUserInvitations(row.userInvitationId).subscribe((data) => {
      this.files = this.files.filter(f => f.data.userInvitationId !== row.userInvitationId);
      Swal.fire({
        title: 'Success',
        text: 'Invitation declined.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }, error => {
      console.error('Error: ', error);
    });
  }

}
