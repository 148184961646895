import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { AccountType } from 'src/app/core/services/models/account.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Quick Start Links'
    }
  ];
  activeModal?: NgbModalRef;
  accountType: AccountType;
  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
  ) {
  }

  async ngOnInit() {
    this.accountType = this.auth.currentAccountSelected.accountType;
  }

}
