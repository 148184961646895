<div class="row" [ngClass]="{'mb-3': mainPage}">
    <div class="col-lg-5" *ngIf="mainPage">
        <div class="row">
            <div class="col-lg-3 label-form">
                <label for="commodityType" class="form-label">Billing Profile: <span class="text-danger">*</span>
                </label>
            </div>
            <div class="col-lg-9">
                <ng-select class="custom form-control" [(ngModel)]="billingProfileId" 
                    [items]="billingProfiles" 
                    bindLabel="name" bindValue="billingProfileId"
                    (change)="billingProfileIdChanged()"
                    [clearable]="false">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="col-lg-1">
        <button type="button" class="btn btn-primary refresh-btn" (click)="updateTable();">
            Refresh
        </button>
    </div>
    <div [ngClass]="{'col-lg-2': mainPage, 'col-lg-6': !mainPage}"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" class="small-width">Date</th>
            <th scope="col" class="invoice-width">Invoice Number</th>
            <th scope="col" class="status-header small-width">Status</th>
            <th scope="col" class="small-width align-right">Total</th>
            <th scope="col" style="padding-left: 5em;">Action</th>
        </tr>
    </thead>
    <tbody *ngIf="!tableLoading">
        <tr *ngFor="let invoice of ListJsList$ | async; let i = index">
            <td class="small-width">
                <ngb-highlight [result]="invoice.dateCreated" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="invoice-width">
                <ngb-highlight [result]="invoice.invoiceNumber" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td *ngIf="invoice.datePaid" class="small-width paid">
                <ngb-highlight [result]="invoice.status" [term]="service.searchTerm">
                </ngb-highlight>
            </td>
            <td *ngIf="!invoice.datePaid" class="small-width unpaid">
                <ngb-highlight [result]="invoice.status" [term]="service.searchTerm">
                </ngb-highlight>
            </td>
            <td class="small-width align-right">
                <ngb-highlight [result]="invoice.totalString" [term]="service.searchTerm">
                </ngb-highlight>
            </td>
            <td class="action-button">
                <a *ngIf="invoice.status !== 'Paid'"
                    href="{{invoice.providerUrl}}" target="_blank">
                    <button type="button" class="btn btn-primary view-button download-button pay-button">
                            Pay
                    </button>
                </a>
                <div [ngStyle]="{'margin-left': invoice.status === 'Paid' ? '14px': '0'}" 
                    class="btn-group" ngbDropdown>
                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Options</button>
                    <div class="dropdown-menu dropdownmenu-primary" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="downloadPDF(invoice, i)">
                            <span class="d-flex align-items-center">
                                <span class="flex-grow-1 me-2">
                                    Download
                                </span>
                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="downloading && i == selectedIndex">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </span>
                        </a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="openViewInvoiceModal(invoice, viewInvoice, i)">
                            <span class="d-flex align-items-center">
                                <span class="flex-grow-1 me-2" style="margin: 0px !important;">
                                    View
                                </span>
                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="loading && i == selectedIndexView">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </span>
                        </a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="resendEmail(invoice, i)">
                            <span class="d-flex align-items-center">
                                <span class="flex-grow-1 me-2" style="margin: 0px !important;">
                                    Email
                                </span>
                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="sendingEmail && i == selectedEmailIndex">
                                    <span class="visually-hidden">Loading...</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="tableLoading">
        <tr>
            <td class="placeholder-glow small-width">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow small-width">
                <span class="placeholder col-12"></span>
            </td>
            <td class="placeholder-glow small-width" style="text-align: center;">
                <span class="placeholder col-6"></span>
            </td>
            <td class="placeholder-glow small-width align-right">
                <span class="placeholder col-12"></span>
            </td>
            <td class="placeholder-glow" style="padding-left: 2.5em;">
                <span class="placeholder col-2" style="margin-right: 1em;"></span>
                <span class="placeholder col-2"></span>
            </td>
        </tr>
    </tbody>
</table> 
<div class="row justify-content-md-between align-items-md-center" *ngIf="!tableLoading && (total$ | async)!">
    <div class="col-sm-12 col-md-5">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries
        </div>
    </div>
    <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end gridjs-pagination">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
            </ngb-pagination>
        </div>
    </div>
</div>

<ng-template #viewInvoice role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Invoice Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <pdf-viewer
        [src]="pdfSrc"
        [render-text]="true"
        [original-size]="false"
        [show-all]="true"
        [stick-to-page]="true"
        style="width: 100%; height: 60em"
  ></pdf-viewer>
</ng-template>