import { Injectable } from '@angular/core';
import { AccountPlan, AccountPlanUtil } from '../models/account-plan.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() {  }

  getChildTabNumber(parentTab: string, childTab: string, plan: AccountPlan): number {
    if(
        AccountPlanUtil.isTrial(plan)
        && (
            (parentTab === 'company' && childTab === 'public-profile')
                || (parentTab === 'zones' && (childTab === 'rates' || childTab === 'routes'))
                || (parentTab === 'lanes' && (childTab === 'setup' || childTab === 'rates'))
                || (
                    parentTab === 'trading-relationships' 
                    && (
                        childTab === 'document-templates'
                            || childTab === 'contract-signers' 
                            || childTab === 'pending'
                            || childTab === 'document-groups'
                    )
                )
                || (parentTab === 'users' && (childTab === 'chat-requests') )
        )
    ){
        return 1;
    }
    if(parentTab === 'company') {
        switch(childTab) {
            case 'profile':
                return 1;
            case 'public-profile':
                return 2;
            case 'modes':
                return 3;
            case 'service-types':
                return 4;
            case 'accounts-payable':
                return 5;
            default:
                return 1;
        }
    } else if(parentTab === 'zones') {
        switch(childTab) {
            case 'service-areas':
                return 1;
            case 'zones':
                return 2;
            case 'rates':
                return 3;
            case 'routes':
                return 4;
            default:
                return 1;
        }
    } else if(parentTab === 'lanes') {
        switch(childTab) {
            case 'lanes':
                return 1;
            case 'setup':
                return 2;
            case 'rates':
                return 3;
            default:
                return 1;
        }
    } else if(parentTab === 'branches') {
        switch(childTab) {
            // case 'service-areas':
            //     return 1;
            // case 'zones':
            //     return 2;
            // case 'rates':
            //     return 3;
            default:
                return 1;
        }
    } else if(parentTab === 'trading-relationships') {
        switch(childTab) {
            case 'document-templates':
                return 1;
            case 'contract-signers':
                return 2;
            case 'pending':
                return 3;
            case 'document-groups':
                return 5;
            default:
                return 1;
        }
    } else if(parentTab === 'billing-and-invoicing') {
        switch(childTab) {
            case 'billing':
                return 1;
            case 'invoicing':
                return 2;
            case 'licenses':
                return 3;
            default:
                return 1;
        }
    } else if(parentTab === 'users') {
        switch(childTab) {
            case 'users':
                return 1;
            case 'roles':
                return 2;
            case 'chat-requests':
                return 3;
            case 'drivers':
                return 4;
            default:
                return 1;
        }
    } else if(parentTab === 'api-integrations') {
        switch(childTab) {
            // case 'service-areas':
            //     return 1;
            // case 'zones':
            //     return 2;
            // case 'rates':
            //     return 3;
            default:
                return 1;
        }
    } else {
        return 1;
    }
  }
}
