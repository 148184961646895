<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header p-3">
                <h5 class="modal-title" id="exampleModalLabel">{{originalRoute? 'Edit': 'Add'}} Route</h5>
            </div>
            <div class="card-body p-5">
                <form [formGroup]="routeForm">
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="name" class="form-label">Name: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="name" class="form-control" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && routeFormControls['name'].errors }" />
                            <div *ngIf="submitted && routeFormControls['name'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['name'].errors['required']">Enter name</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="mode" class="form-label">Mode: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 label-form">
                            <label class="form-label">{{selectedMode? selectedMode.name: ''}}</label>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="mainBranch" class="form-label">Main Branch: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 label-form">
                            <label class="form-label">{{selectedBranch? selectedBranch.name: ''}}</label>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="serviceArea" class="form-label">Service Area: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" name="serviceArea" [options]="serviceAreaOptions" optionLabel="name" optionValue="serviceAreaId" [autoDisplayFirst]="false" 
                                (onChange)="serviceAreaSelected($event)" [ngClass]="{ 'is-invalid': submitted && routeFormControls['serviceArea'].errors }" formControlName="serviceArea"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['serviceArea'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['serviceArea'].errors['required']">Select a service area</div>
                            </div>
                            <img *ngIf="selectedServiceArea?.postalCodesImageUrl"
                                class="d-block mt-3 postal-codes-image"
                                [src]="selectedServiceArea?.postalCodesImageUrl"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="zone" class="form-label">Zone: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" name="zone" [options]="zoneOptions" optionLabel="zoneName" optionValue="zoneId" [autoDisplayFirst]="false" 
                                (onChange)="zoneSelected($event)" [ngClass]="{ 'is-invalid': submitted && routeFormControls['zone'].errors }" formControlName="zone"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['zone'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['zone'].errors['required']">Select a zone</div>
                            </div>
                            <img *ngIf="selectedZone?.postalCodesImageUrl"
                                class="d-block mt-3 postal-codes-image"
                                [src]="selectedZone?.postalCodesImageUrl"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="postalCode" class="form-label">Zip Codes: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9" [ngClass]="{
                                'label-form': postalCodeOptions.length == 0, 
                                'pad-top-checkbox': postalCodeOptions.length != 0
                            }">
                            <p class="form-label" *ngIf="postalCodeOptions.length == 0">No zip codes available.</p>
                            <p class="form-label" *ngIf="postalCodeOptions.length != 0">{{ postalCodeOptions.join(', ') }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="serviceableBranches" class="form-label">Serviceable Branches: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9" [ngClass]="{
                                'label-form': serviceableBranchOptions.length == 0, 
                                'pad-top-checkbox': serviceableBranchOptions.length != 0}
                            ">
                            <p class="form-label" *ngIf="serviceableBranchOptions.length == 0">No serviceable branches available.</p>
                            <div *ngFor="let serviceableBranch of serviceableBranchOptions" class="mb-1">
                                <p-checkbox [name]="serviceableBranch.name!" [value]="serviceableBranch.branchId" [formControl]="$any(routeFormControls['serviceableBranches'])"></p-checkbox>
                                {{serviceableBranch.name}}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="btnradioHasHelper" class="form-label select-button-toggle">Has Helper: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" [value]="false" name="btnradioHasHelper" id="btnradiohasHelperFalse" formControlName="hasHelper">
                                <label class="btn btn-outline-primary mb-0" for="btnradiohasHelperFalse">No</label>
                                <input type="radio" class="btn-check" [value]="true" name="btnradioHasHelper" id="btnradiohasHelperTrue" formControlName="hasHelper">
                                <label class="btn btn-outline-primary mb-0" for="btnradiohasHelperTrue">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="btnradioOnSiteTruckParking" class="form-label select-button-toggle">On Site Truck Parking: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" name="btnradioOnSiteTruckParking" [value]="false" id="btnradioOnSiteTruckParkingFalse" checked formControlName="onSiteTruckParking">
                                <label class="btn btn-outline-primary mb-0" for="btnradioOnSiteTruckParkingFalse">No</label>
                                <input type="radio" class="btn-check" name="btnradioOnSiteTruckParking" [value]="true" id="btnradioOnSiteTruckParkingTrue" formControlName="onSiteTruckParking">
                                <label class="btn btn-outline-primary mb-0" for="btnradioOnSiteTruckParkingTrue">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="serviceType" class="form-label">Service Types: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <div *ngFor="let option of serviceTypeOptions" class="field-checkbox padding-top">
                                <p-checkbox name="serviceType" [value]="option.technicalName" [ngClass]="{'is-invalid': submitted && selectedServiceTypes.length === 0 }" [(ngModel)]="selectedServiceTypeIds" [ngModelOptions]="{standalone: true}" (onChange)="serviceTypeChanged()"></p-checkbox>
                                {{option.prettyName}}
                            </div>
                            <div *ngIf="submitted && selectedServiceTypes.length === 0" class="text-danger label-form" align="left"> Select service types </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="skillSet" class="form-label">Skill Set: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 row">
                            <ng-container *ngFor="let option of skillOptions; let i=index">
                                <div *ngIf="i!=0 && i%3===0" class="w-100"></div>
                                <div class="field-checkbox padding-top col-4">
                                    <p-checkbox name="skillSet" [value]="option.technicalName" [ngClass]="{'is-invalid': submitted && selectedSkills.length === 0 }" [(ngModel)]="selectedSkillIds" [ngModelOptions]="{standalone: true}" (onChange)="skillChanged()"></p-checkbox>
                                    {{option.prettyName}}
                                </div>
                            </ng-container>
                            <div *ngIf="submitted && selectedSkills.length === 0" class="text-danger label-form" align="left"> Select skill set </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="pickupStartTime" class="form-label">Pickup Start Time: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown name="pickupStartTime" [options]="pickUpStartTimeOptions" optionLabel="prettyName" optionValue="techName" [autoDisplayFirst]="false" formControlName="pickupStartTime" (onChange)="adjustTime()" [ngClass]="{ 'is-invalid': submitted && routeFormControls['pickupStartTime'].errors }"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['pickupStartTime'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['pickupStartTime'].errors['required']">Select Pickup Start Time</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="pickupEndTime" class="form-label">Pickup End Time: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown name="pickupEndTime" [options]="pickUpEndTimeOptions" optionLabel="prettyName" optionValue="techName" [autoDisplayFirst]="false" formControlName="pickupEndTime" (onChange)="adjustTime()" [ngClass]="{ 'is-invalid': submitted && routeFormControls['pickupEndTime'].errors }"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['pickupEndTime'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['pickupEndTime'].errors['required']">Select Pickup End Time</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="startTime" class="form-label">Route Start Time: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown name="startTime" [options]="routeStartTimeOptions" optionLabel="prettyName" optionValue="techName" [autoDisplayFirst]="false" formControlName="startTime" (onChange)="adjustTime()" [ngClass]="{ 'is-invalid': submitted && routeFormControls['startTime'].errors }"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['startTime'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['startTime'].errors['required']">Select Route Start Time</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="endTime" class="form-label">Route End Time: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown name="endTime" [options]="routeEndTimeOptions" optionLabel="prettyName" optionValue="techName" [autoDisplayFirst]="false" formControlName="endTime" (onChange)="adjustTime()" [ngClass]="{ 'is-invalid': submitted && routeFormControls['endTime'].errors }"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['endTime'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['endTime'].errors['required']">Select Route End Time</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label class="form-label">Average Load Out Time: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 label-form">
                            <p class="form-label" *ngIf="selectedServiceTypes.length > 0">
                                {{averageLoadOutTime | number:'1.2-2' }} minutes
                            </p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="vehicle" class="form-label">Vehicle: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <p-dropdown [scrollHeight]="'50vh'" [formControl]="$any(routeFormControls['truckTrailerCombo'])" name="truckTrailerCombo" [options]="truckTrailerComboOptions" optionLabel="prettyName" optionValue="technicalName" [autoDisplayFirst]="false" [ngClass]="{'is-invalid': submitted && routeFormControls['truckTrailerCombo'].errors }"></p-dropdown>
                            <div *ngIf="submitted && routeFormControls['truckTrailerCombo'].errors" class="invalid-feedback" align="left">
                                <div *ngIf="routeFormControls['truckTrailerCombo'].errors['required']">Select a vehicle</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-2 label-form text-align-right">
                            <label for="capacity" class="form-label">Capacity: <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9">
                            <button type="submit" class="btn btn-primary action-btn" (click)="openCapacityEditModal()"> Edit </button>
                            <div *ngIf="capacityModalClicked && routeFormControls['truckTrailerCombo'].errors" class="mt-1 text-danger" align="left">
                                <div *ngIf="routeFormControls['truckTrailerCombo'].errors['required']"> Please select a Vehicle </div>
                            </div>
                            <div *ngIf="capacityModalClicked && selectedServiceTypes.length === 0" class="mt-1 text-danger" align="left"> Please select a Service Type </div>
                            <div *ngIf="false" class="table-responsive table-card mt-3 mb-1" id="capacity-table">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Zip Codes</th>
                                            <th scope="col">Days</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="capacityGroups">
                                            <tr *ngFor="let capacityGroup of capacityGroups; let i = index;">
                                                <td (click)="openCapacity(capacity, capacityGroups[i])">{{capacityGroup.name}}</td>
                                                <td (click)="openCapacity(capacity, capacityGroups[i])">
                                                    <span>{{(capacityGroup.postalCodes | slice:0:2).join(', ')}}</span>
                                                    <span *ngIf="capacityGroup.postalCodes.length > 2" [title]="capacityGroup.postalCodes"> +{{capacityGroup.postalCodes.length - 2}} more </span>
                                                </td>
                                                <td (click)="openCapacity(capacity, capacityGroups[i])">
                                                    <ng-container *ngFor="let item of capacityGroup.capacityRules; let i = index">
                                                        <span>{{getDayPrettyName(item.day)}}</span>
                                                        <span *ngIf="capacityGroup.capacityRules.length > 1 && (capacityGroup.capacityRules.length - 1) != i">, </span>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <div class="delete">
                                                        <button class="btn btn-sm btn-danger" (click)="deleteCap(capacityGroup, i)"> Delete </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="d-flex gap-2">
                                                    <div class="add">
                                                        <button class="btn btn-sm btn-primary edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal" (click)="openCapacity(capacity);">Add</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !originalRoute}">
                    <span *ngIf="originalRoute" class="clickable-column me-auto d-flex align-items-center text-danger">
                        <div *ngIf="!saveLoading" (click)="deleteRoute()" class="inline-block"> Delete </div>
                        <div *ngIf="saveLoading" class="spinner-border inline-block save-loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    <div>
                        <button type="button" class="btn btn-light" routerLink="/pages/routes">
                            <div class="inline-block"> Cancel </div>
                        </button>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary action-btn" (click)="saveRoute()">
                            <div *ngIf="!saveLoading" class="inline-block"> Save </div>
                            <div *ngIf="saveLoading" class="spinner-border inline-block route-save-loading" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Capacity -->
<ng-template #capacity role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">Capacity</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click')"></button>
    </div>
    <div class="modal-body">
        <app-capacity-form (valueChange)='closeModal($event)' (formValue)="formValue($event)" [zipCodes]="routeForm.value.postalCodes" [defaultValue]="capDataDefault"></app-capacity-form>
    </div>
</ng-template>