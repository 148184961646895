<div class="row" *ngIf="!onlyTable">
    <div class="col-lg-3">
        <button
            type="button" 
            class="btn btn-primary add-btn" 
            data-bs-toggle="modal" 
            id="create-btn"
            data-bs-target="#showModal"
            (click)="openServiceAreaModal(serviceAreaContent)">
            <i class="ri-add-line align-bottom me-1"></i> Add Service Area
        </button>
    </div>
    <div class="col-lg-5"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." (input)="tt.filterGlobal(getEventValue($event), 'contains')">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<p-treeTable #tt [value]="files2" [columns]="cols2">
    <ng-template pTemplate="header" let-rowNode let-columns>
        <tr [ttRow]="rowNode">
            <th *ngFor="let col of columns">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <a *ngIf="rowData.type === 'ServiceArea'"
                    style="text-decoration: underline !important; color: #405189; cursor: pointer;" 
                    (click)="openModal(serviceAreaContent, zoneContent, rowData)"><!-- this comment is to preserve whitespace
                    -->{{rowData[col.field]}}
                </a>
                <img  *ngIf="rowData.type === 'ServiceArea' && rowData.serviceArea.postalCodesImageUrl"
                    class="d-block postal-codes-image"
                    [src]="rowData.serviceArea.postalCodesImageUrl"/>
                <a *ngIf="rowData.type === 'Zone'"
                    style="text-decoration: underline !important; color: #405189; cursor: pointer;" 
                    (click)="openModal(serviceAreaContent, zoneContent, rowData)"><!-- this comment is to preserve whitespace
                    -->{{rowData[col.field]}}
                </a>
                <img  *ngIf="rowData.type === 'Zone' && rowData.zone.postalCodesImageUrl"
                    class="d-block zone-postal-codes-image"
                    [src]="rowData.zone.postalCodesImageUrl"/>
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<!-- Service Area Modal -->
<ng-template #serviceAreaContent role="document" let-modal>
    <app-service-area-modal
        [activeTab]="activeTab"
        [modal]="modal"
        [serviceAreaAndActiveZoneId]="{
            serviceArea: serviceAreaZoneTreeNodeInModal?.serviceArea!,
            defaultZoneId: activeZoneId
        }"
        [refServiceAreas]="refServiceAreas"
    ></app-service-area-modal>
</ng-template>

<!-- Zone Modal -->
<ng-template #zoneContent role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{serviceAreaZoneTreeNodeInModal?.zone ? 'Edit': 'Add'}} Zone</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');"></button>
    </div>
    <form class="app-serice-areas-modal" (ngSubmit)="zoneSubmit()" [formGroup]="zoneForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="name" class="form-control" name="name" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && zoneFormControls['name'].errors }" />
                    <div class="invalid-feedback" align="left">
                        <div>Enter name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Modes: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <div class="field-checkbox" *ngFor="let mode of serviceAreaZoneTreeNodeInModal?.serviceArea?.modes"
                        [ngClass]="{ 'is-invalid': submitted && zoneFormControls['modes'].errors }">
                        <p-checkbox [name]="mode.name" [value]="mode.modeId" [formControl]="$any(zoneFormControls['modes'])"></p-checkbox>
                        {{mode.name}}
                    </div>
                    <div class="invalid-feedback">
                        Pick at least 1 mode
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Postal codes: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <div class="field-checkbox" *ngFor="let postalCode of serviceAreaZoneTreeNodeInModal?.serviceArea?.postalCodes"
                        [ngClass]="{ 'is-invalid': submitted && zoneFormControls['postalCodes'].errors }">
                        <p-checkbox [name]="postalCode" [value]="postalCode" [formControl]="$any(zoneFormControls['postalCodes'])"></p-checkbox>
                        {{postalCode}}
                    </div>
                    <div class="invalid-feedback">
                        Pick at least 1 postal code
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !serviceAreaZoneTreeNodeInModal?.zone}">
            <span *ngIf="serviceAreaZoneTreeNodeInModal?.zone"
                    class="clickable-column me-auto d-flex align-items-center text-danger">
                <div *ngIf="!saveLoading" class="inline-block" (click)="deleteZone()">
                    Delete  
                </div>
                <div *ngIf="saveLoading"
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary action-btn" id="add-btn">
                <div *ngIf="!saveLoading"   
                    class="inline-block">
                    Save  
                </div>
                <div *ngIf="saveLoading"  
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</ng-template>