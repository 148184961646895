export class GoogleMapsPlaceResultJson {
    opening_hours: OpeningHours;
    secondary_opening_hours: SecondaryOpeningHours[];

    constructor(placeResult: google.maps.places.PlaceResult){
        Object.assign(this, JSON.parse(JSON.stringify(placeResult)));
        console.log('[GoogleMapsPlaceResultJson] opening_hours', JSON.stringify(this.opening_hours));
        console.log('[GoogleMapsPlaceResultJson] secondary_opening_hours', JSON.stringify(this.secondary_opening_hours));
    }
}

export interface OpeningHours {
    periods: Period[]
    weekday_text: string[]
}

export interface Period {
    close: OpenClose
    open: OpenClose
}

export interface OpenClose {
    day: number
    time: string
    hours: number
    minutes: number
}

export interface SecondaryOpeningHours {
    periods: SecondaryPeriod[]
    type: string
    weekday_text: string[]
}

export interface SecondaryPeriod {
    close: SecondaryOpenClose
    open: SecondaryOpenClose
}

export interface SecondaryOpenClose {
    date: string
    day: number
    time: string
}